import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}


export class AnalisiContesto extends Component {



    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/AnalisiContesto')}>Analisi del contesto</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Analisi del contesto</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>L’Analisi del Contesto è la funzione che l’utente deve utilizzare per individuare il Profilo di Criticità dei
                                Servizi della  Azienda.</p>
                                <p>Per ogni servizio che deve essere preso in considerazione per la definizione del Contesto dell’Analisi del
                                Rischio, l’Utente deve definire le caratteristiche richieste e specifiche per l’individuazione del Profilo di
                                Criticità di ciascun Servizio con l’obiettivo di scoprire le potenziali vulnerabilità in ambito Information
                                Security.</p>
                                <p>L’insieme dei Profili di Criticità derivati dai Servizi considerati all’interno del Contesto contribuiscono a
                                determinare il Profilo di Criticità della Pubblica Amministrazione cui i Servizi afferiscono.</p>
                                <p>I servizi che non hanno profilo di criticità calcolato non sono presi in considerazione nelle fasi successive del
                                processo di Risk Management. Pertanto, l’utente deve verificare che tutte le informazioni richieste siano
                                state fornite e sia stato individuato il Profilo di Criticità prima di proseguire nella fase successiva.
                                </p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ListaServizi')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ELENCO SERVIZI</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Accesso alla lista dei servizi della PA, sincronizzata con l’anagrafica generale presente sul sistema “Servizi.gov.it”. Selezionando il servizio di interesse sarà possibile procedere con la compilazione del questionario di caratterizzazione dei relativi attributi di sicurezza cyber.
          <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/RiepilogoAnalisiContesto')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">RIEPILOGO DATI</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Il riepilogo dello stato attuale dell’analisi del contesto, fornisce informazioni circa il profilo di criticità dei servizi della Azienda ed il numero di servizi caratterizzati nei loro attributi di sicurezza cyber rispetto a quelli censiti sul sistema “Servizi.gov.it”, calcolati basandosi sui dati inseriti dall’utente in fase di analisi del contesto dei servizi della Azienda corrente.
          <hr />

                            </div>

                        </div></div>



                    <br /><br /><br /><br /><br /><br /><br />

                </div>


            </div>
        )
    }
}