import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';

class Errore extends Component {
    
  componentDidMount() {
    $(".u-background-95").attr({'style': 'position: absolute !important; bottom: 0 !important;width: 100% !important;'});
  }

  render() {
    //const { isAuthenticated } = this.props.auth;
    return (
    <div>
    <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

<div className="container cnsContainer">
	
<div className="row">

  <div className="col-lg-12"> 


	  
    <div className="modal-content">
      <div className="modal-headerRed ">
        <h2 className="modal-title testoRosso" id="exampleModalLabel"><i className="fa fa-warning"></i>
 AVVISO</h2>
 
      </div>
      <div className="modal-body">
      Accesso all'applicazione non riuscito. Riprova.</div>
      <div className="modal-footer justify-content-center">
        <a href={API_CONFIG.HomePath}>

			        <button type="button" className="btn btn-success btn-lg" data-bs-dismiss="modal">TORNA INDIETRO</button>

        </a>      </div>
    </div>
	  
	  
	  
	  

	</div>
	</div>	
  
  </div>    </div> </div>

    );
  }
}

export default Errore;
