import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import PropensionListOption from '../data/PropensionListOption';
import { PropensionOption } from '../Common/PropensionOption';
import { Util } from '../data/Util';
import Popup from 'reactjs-popup';
import $ from 'jquery';
import infoIcon from '../../public/images/info.png';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';


// component that renders a single servizioModificaTrattamento row
export class ServizioModificaTrattamentoRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
            showAccordion: ''
        };
        this.updateOpzione = this.updateOpzione.bind(this);
        this.setShowAccordion = this.setShowAccordion.bind(this);

    }

    setShowAccordion() {
        this.setState({ showAccordion: 'show' });
    }

    updateOpzione(MinacciaId, event) {
        this.setState({ showAccordion: 'show' });

        let val = event.target.value;
        this.props.updateOpzione(MinacciaId, parseInt(val, 10));

        var className = ".statoAccordionLink" + this.props.rowId;
        var accordion = "#collapseStato" + this.props.rowId;
        //console.log("ACCORDION", $(accordion));
        if ($(accordion).attr("class") === "collapse") {
            if ($(className).length > 0)
                $(className).get(0).click();
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    getClassName(index) {
        let className = '';
        let indexInt = parseInt(index, 10);
        switch (indexInt) {
            case 1:
                className = "col-lg-2 bgVerde";
                break;
            case 2:
                className = "col-lg-2 bgArancio";
                break;
            case 3:
                className = "col-lg-2 bgRosso";
                break;
            case 4:
                className = "col-lg-2 bgNero";
                break;
            default:
                className = "col-lg-2";
                break;
        }
        return className;
    }

    /**
     * 
     * @param {*} index 
     */

    toStringItem(index) {
        let indexInt = parseInt(index, 10);
        try {
            if (isNaN(indexInt) || index === null) {
                return '';
            } else {
                return IconImpatti[index].label

            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} RischioAttuale 
     * @param {*} RiskAppetite 
     * @param {*} OpzioneSelected 
     * @param {*} MinacciaId 
     */
    getComboBox(RischioAttuale, RiskAppetite, OpzioneSelected, MinacciaId) {
        var comboBox = null;
        var valueSelected = null;

        if (RischioAttuale <= RiskAppetite) {
            valueSelected = OpzioneSelected === null ? 2 : OpzioneSelected;
            comboBox = <select value={valueSelected}
                onChange={(e) => this.updateOpzione(MinacciaId, e)}
                disabled={this.props.readOnly}
            >
                <option value='2'> Accettare</option>
                <option value="1"> Mitigare</option>
            </select>;
        }
        else {
            valueSelected = OpzioneSelected === null ? 1 : OpzioneSelected;
            comboBox = <select value={valueSelected}
                onChange={(e) => this.updateOpzione(MinacciaId, e)}
                disabled={this.props.readOnly}
            >
                <option value="1"> Mitigare</option>
                <option value='2'> Accettare</option>
                <option value="3"> Trasferire</option>
            </select>
        }

        return comboBox;
    }

    getStatoOpzione() {
        if ((this.props.opzione.OpzioneTrattamento !== null &&
            this.props.opzione.OpzioneTrattamento !== this.props.opzione.OpzioneTrattamentoDefault) ||
            this.props.opzione.RiskAppetite !== this.props.opzione.RiskAppetiteDefault)
            return <a data-bs-toggle="collapse" className={"statoAccordionLink" + this.props.rowId} href={"#collapseStato" + this.props.rowId}>Modificato <i className="fa fa-chevron-down"></i></a>
        else
            return <span>Originale</span>
    }

    getAccordionStato() {
        // if(this.props.rowId === 3)
        // console.log("SHOW ACCORDION",this.state.showAccordion);

        if ((this.props.opzione.OpzioneTrattamento !== null &&
            this.props.opzione.OpzioneTrattamento !== this.props.opzione.OpzioneTrattamentoDefault) ||
            this.props.opzione.RiskAppetite !== this.props.opzione.RiskAppetiteDefault) {
            return <div id={"collapseStato" + this.props.rowId} className={"collapse " + this.state.showAccordion} data-parent="#accordion">
                <div className="row card-headerLight">
                    <div className="col-lg-4"><strong>Responsabile modifica: </strong>
                        {/* <input type="text"
                            className="form50"
                            onChange={(e) => this.props.updatePersona(this.props.opzione.MinacciaId, e)}
                            value={(this.props.opzione.PersonaIntervistato) ? this.props.opzione.PersonaIntervistato : ""}
                            onBlur={this.props.save}
                            disabled={this.props.readOnly}
                            size="100%" /> */}
                        <div className="col-lg-1" style={{ textAlign: "center" }}>
                            <Popup
                                trigger={<i className="fa fa-user-o" style={{ cursor: "pointer" }}></i>}
                                position="top left"
                                on="hover"
                            >
                                <div style={{ fontSize: "12px" }}>
                                    <div style={{ width: "100%", borderBottom: "1px solid gray", textAlign: "center", fontSize: "14px", fontWeight: "bold" }}>Utente </div>
                                    <div style={{ width: "100%", textAlign: "center", fontSize: "14px" }}>
                                        {this.props.opzione.PersonaIntervistato}
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    </div>
                    {/* <div className="col-lg-4"><strong>	Struttura organizzativa: </strong>

                        <input type="text"
                            className="form50"
                            onChange={(ev) => this.props.updateStruttura(this.props.opzione.MinacciaId, ev)}
                            value={(this.props.opzione.StrutturaIntervistato) ? this.props.opzione.StrutturaIntervistato : ""}
                            onBlur={this.props.save}
                            disabled={this.props.readOnly}
                            size="100%" />
                    </div> */}
                    <div className="col-lg-8"><strong>	Motivazione: </strong>
                        <br />
                        <input type="text"
                            className="form100"
                            onChange={(ev) => this.props.updateMotivazione(this.props.opzione.MinacciaId, ev)}
                            value={(this.props.opzione.Motivazione) ? this.props.opzione.Motivazione : ""}
                            onBlur={this.props.save}
                            disabled={this.props.readOnly}
                            size="100%" />
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        }
        else
            return null;
    }

    render() {
        let index = this.props.rowId;
        return (
            <div key={this.props.rowId}>
                <div className="row">
                    {/*1a colonna Minaccia */}
                    <div className="col-lg-4" key={"nome_minaccia" + index + this.props.opzione.MinacciaId}>
                        <a style={{ marginRight: "5px" }} data-bs-toggle="collapse" href={"#collapseInfo" + this.props.opzione.MinacciaId + "minaccia" + index}>
                            <span className="testoBlu" >
                                <img style={{ marginBottom: "3px" }} src={infoIcon} alt="iconInfo" />
                            </span>
                        </a>
                        <label style={{ display: "inline" }}>{this.props.opzione.Nome}</label>

                        <div id={"collapseInfo" + this.props.opzione.MinacciaId + "minaccia" + index} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                            {(this.props.opzione.DescrizioneEvento) ? Util.toStringInfoAggiuntive(this.props.opzione.DescrizioneEvento) : ""}
                        </div>
                        {/* <hr /> */}
                    </div>
                    {/*2a colonna RischioAttuale */}
                    <div className={this.getClassName(this.props.opzione.RischioAttuale)}>
                        <strong>{this.toStringItem(this.props.opzione.RischioAttuale)}</strong>
                    </div>
                    {/*3a colonna Propensione */}
                    <div className="col-lg-2">
                        <PropensionOption
                            setShowAccordion={this.setShowAccordion}
                            rowId={this.props.rowId}
                            value={this.props.opzione.RiskAppetite}
                            updateOption={this.props.updateRiskAppetite}
                            optionList={PropensionListOption}
                            item={this.props.opzione}
                            readOnly={this.props.readOnly}
                        //className={this.getClassName(this.props.opzione.RiskAppetite).split(" ")[1]}
                        />
                    </div>

                    {/*4a colonna Trattamento */}
                    <div className="col-lg-2">
                        {this.getComboBox(this.props.opzione.RischioAttuale,
                            this.props.opzione.RiskAppetite,
                            this.props.opzione.OpzioneTrattamento,
                            this.props.opzione.MinacciaId)}
                    </div>
                    {/*5a colonna Stato */}
                    <div className="col-lg-2 bgNero">
                        {this.getStatoOpzione()}
                    </div>
                </div>
                <hr />

                {this.getAccordionStato()}
            </div>
        )
    }
}