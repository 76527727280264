import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import { ListaServiziAnalisiRischioRow } from './ListaServiziAnalisiRischioRow';
import { API_CONFIG } from '../../public/js/apiServices';
import { Breadcrumb } from '../Common/Breadcrumb';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import LIVELLO from '../data/criticality';
import { SearchInput } from '../Common/SearchInput';
import { Util } from '../data/Util';
import { isNullOrUndefined } from 'util';
import { LegendaTable } from '../Common/LegendaTable';

const STATI = ["incompleto", "completo", "pubblicato"];
//STATI["-1"] = "Tutti";
/*
 STATI["1"] = "da compilare";
 STATI["2"] = "incompleto";
 STATI["3"] = "completato";
 STATI["4"] = "pubblicato";
 */



const LINK = [
    { nome: "", url: "/" },
];

export class ListaServiziAnalisiRischio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            rowsTable: [],
            selectedColumn: "",
            selectedCategory: sessionStorage.getItem('selectedCategory') !== null ? sessionStorage.getItem('selectedCategory') : '-1',
            selectedState: sessionStorage.getItem('selectedState') !== null ? sessionStorage.getItem('selectedState') : '-1',//valore dello stato settato di default a 'tutti'
            selectedTypology: sessionStorage.getItem('selectedTypology') !== null ? sessionStorage.getItem('selectedTypology') : '-1',
            search: sessionStorage.getItem('search') !== null ? sessionStorage.getItem('search') : '',
            verticale: sessionStorage.getItem('selectedVerticale') !== null ? parseInt(sessionStorage.getItem('selectedVerticale'), 10) : 0,
            searchAll: sessionStorage.getItem('searchAllAR') !== null ? sessionStorage.getItem('searchAllAR') : '',
            selectedTrasversal: sessionStorage.getItem('selectedTrasversal') !== null ? sessionStorage.getItem('selectedTrasversal') : "-1",
            categories: [],
            typologies: [],
            isAscName: true,
            activePage: 1,
            currentPageListServices: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: "Loading...",
            messageError: ""

        }
        this.toString = this.toString.bind(this);
        this.getListServices = this.getListServices.bind(this);
        this.setCategoriesTypologies = this.setCategoriesTypologies.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updateTypology = this.updateTypology.bind(this);
        this.updateServiceType = this.updateServiceType.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.updateRowsTable = this.updateRowsTable.bind(this);
        this.orderServicesList = this.orderServicesList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);
        this.areThereModifiedServices = this.areThereModifiedServices.bind(this);
    }

    componentDidMount() {
        try {


            console.log('token:', sessionStorage.getItem('token'))
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.assessmentcompositeGetServiziByCodiceIPA,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        /*
                        this.setState({ services: response.data });
                        this.setState({ rowsTable: response.data }, this.updateRowsTable);
                        */
                        this.setState({ services: this.getListServices(response.data) }, () => console.log('response.data:', this.state.services));
                        this.setState({ rowsTable: this.getListServices(response.data) }, this.updateRowsTable);
                        this.handlePageChange(1);
                        this.setState({ emptyTest: "Nessuna Applicazione con i criteri specificati" });
                        this.setCategoriesTypologies();
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });

            if (this.state.search.length !== 0) {
                $("#filter").get(0).click();
            }
            if ((sessionStorage.getItem('selectedCategory') !== null ||
                sessionStorage.getItem('selectedState') !== null ||
                sessionStorage.getItem('selectedTypology') !== null ||
                sessionStorage.getItem('search') !== null ||
                sessionStorage.getItem('searchAllAR') !== null ||
                sessionStorage.getItem('selectedTrasversal') !== null) &&
                this.props.riepilogo !== "true")
                $(".filtroAvanzato").get(0).click();
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} listServices 
     */
    getListServices(listServices) {
        let newListServices = []
        let analisi = "";
        try {
            for (let index = 0; index < listServices.length; index++) {
                const element = listServices[index];
                //if (element.StatoAssessmentId !== -1) {
                    //let analisi = (element.StatoAssessmentId < 3) ? STATI[0] : (element.StatoAssessmentId > 3) ? STATI[2] : STATI[1];
                    if (element.StatoAssessmentId < 3) {
                        analisi = STATI[0];
                    } else if (element.StatoAssessmentId > 3) {
                        analisi = STATI[2];
                    } else analisi = STATI[1]
                    element["Analisi"] = analisi;
                    newListServices.push(element);
                //}
            }
            return newListServices;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
    * 
    */
    setCategoriesTypologies() {
        $.ajax({
            //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie',
            url: API_CONFIG.getCategorieSottoCategorie,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
                this.setState({ typologies: response.data.SottoCategoriaServizio });
                this.setState({ categories: response.data.CategoriaServizio });
                //console.log('SottoCategoriaServizio:', this.state.typologies);
                //console.log('CategoriaServizio:', this.state.categories);
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }

    updateCategory(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedCategory', val);
        this.setState({ selectedCategory: val },
            this.updateRowsTable
        );
    }

    updateState(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedState', val);
        this.setState({ selectedState: val }, this.updateRowsTable);
    }

    updateTypology(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedTypology', val);
        this.setState({ selectedTypology: val }, this.updateRowsTable);
    }

    updateServiceType(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedTrasversal', val);
        this.setState({ selectedTrasversal: val }, this.updateRowsTable);
    }

    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('search', val);
        this.setState({ search: val }, this.updateRowsTable);
    }

    updateSearchAll(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllAR', val);
        this.setState({ searchAll: val }, this.updateRowsTable);
    }

    /**
      * 
      * @param {*} val 
      * @param {*} field 
    */
    updateRowsTable() {
        let services = this.state.services;
        //let verticale = this.state.verticale;
        //let trasversale = this.state.trasversale;
        let selectedTrasversal = this.state.selectedTrasversal;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedCategory = this.state.selectedCategory;
        let selectedState = this.state.selectedState;
        let selectedTypology = this.state.selectedTypology;

        try {
            var list = [];
            //if (searchAll === '' && search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1' && trasversale && verticale) {
            if (searchAll === '' && search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1' && selectedTrasversal === '-1') {
                list = services;
            } else {

                for (let index = 0; index < services.length; index++) {
                    const element = services[index];

                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente === null || element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente === null || element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.Descrizione === null || element.Descrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {
                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {
                            //debugger
                            //if (selectedState === '-1' || element.StatoAssessmentId === parseInt(selectedState, 10)) {
                            if (selectedState === '-1' || element.Analisi.toUpperCase().localeCompare(selectedState.toUpperCase()) === 0) {

                                if (selectedCategory === '-1' || Util.toStringList(element.Categorie).toUpperCase().indexOf(selectedCategory.toUpperCase()) > -1) {
                                    /*
                                    if ((trasversale && element.TipologiaServizio === "Trasversale" && !verticale) ||
                                    (verticale && element.TipologiaServizio === "Verticale" && !trasversale)) {
                                   */
                                    if (selectedTrasversal === '-1' || element.TipologiaServizio.toUpperCase().indexOf(selectedTrasversal.toUpperCase()) > -1) {

                                        if (selectedTypology === '-1' || Util.toStringList(element.Tipologie).toUpperCase().indexOf(selectedTypology.toUpperCase()) > -1) {
                                            list.push(element);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
            //console.log('list:', list);
            //this.setState({ rowsTable: list });
            this.setState({ rowsTable: list }, () => { this.handlePageChange(1) });

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
        * 
        */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let rowsTable = this.state.rowsTable;
        key = key.toLowerCase();
        this.setState({ selectedColumn: key });
        switch (key) {
            case 'nome':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });
                }
                else list = rowsTable.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                //this.setState({ isAscName: !asc });
                break;
            case 'data':
                if (asc) {
                    list = rowsTable.sort(function (a, b) {
                        return new Date(a.DataCalcoloRischioAttuale !== null ?
                            a.DataCalcoloRischioAttuale.split('T')[0] : a.DataCalcoloRischioAttuale).getTime() - new Date(b.DataCalcoloRischioAttuale !== null ? b.DataCalcoloRischioAttuale.split('T')[0] : b.DataCalcoloRischioAttuale).getTime();
                    });

                } else
                    list = rowsTable.sort(function (a, b) {
                        return new Date(b.DataCalcoloRischioAttuale !== null ?
                            b.DataCalcoloRischioAttuale.split('T')[0] : b.DataCalcoloRischioAttuale).getTime() - new Date(a.DataCalcoloRischioAttuale !== null ? a.DataCalcoloRischioAttuale.split('T')[0] : a.DataCalcoloRischioAttuale).getTime();
                    });
                //this.setState({ isAscName: !asc });
                break;
            case 'criticità':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Criticità - b.Criticità; });
                }
                else list = rowsTable.sort(function (a, b) { return b.Criticità - a.Criticità; });
                //this.setState({ isAscName: !asc });
                break;
            case 'stato':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Analisi.toUpperCase().localeCompare(b.Analisi.toUpperCase()); });
                }
                else list = rowsTable.sort(function (a, b) { return b.Analisi.toUpperCase().localeCompare(a.Analisi.toUpperCase()); });
                //this.setState({ isAscName: !asc });
                break;
            case 'rischio':
                if (asc) {
                    list = rowsTable.sort(function (a, b) {
                        //let levelA = ((a.RischioAggregato) && (a.RischioAggregato >= 0) && (a.RischioAggregato < LIVELLO.length)) ? LIVELLO[a.RischioAggregato].name : "";
                        //let levelB = ((b.RischioAggregato) && (b.RischioAggregato >= 0) && (b.RischioAggregato < LIVELLO.length)) ? LIVELLO[b.RischioAggregato].name : "";
                        let levelA = (!isNullOrUndefined(a.RischioAggregato) && (a.RischioAggregato >= 0) && (a.RischioAggregato < LIVELLO.length)) ? LIVELLO[a.RischioAggregato].name : "";
                        let levelB = (!isNullOrUndefined(b.RischioAggregato) && (b.RischioAggregato >= 0) && (b.RischioAggregato < LIVELLO.length)) ? LIVELLO[b.RischioAggregato].name : "";

                        return levelA.localeCompare(levelB);
                    });
                }
                else list = rowsTable.sort(function (a, b) {
                    let levelA = (!isNullOrUndefined(a.RischioAggregato) && (a.RischioAggregato >= 0) && (a.RischioAggregato < LIVELLO.length)) ? LIVELLO[a.RischioAggregato].name : "";
                    let levelB = (!isNullOrUndefined(b.RischioAggregato) && (b.RischioAggregato >= 0) && (b.RischioAggregato < LIVELLO.length)) ? LIVELLO[b.RischioAggregato].name : "";
                    return levelB.localeCompare(levelA);
                });
                //this.setState({ isAscName: !asc });
                break;
            default:
                list = rowsTable;
                break;
        }

        this.setState({ rowsTable: list }, () => { this.handlePageChange(this.state.activePage) });
        this.setState({ isAscName: !asc });
    }

    /**
    * 
    */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let rowsTable = this.state.rowsTable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (rowsTable[i])
                listing_table.push(rowsTable[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListServices: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.rowsTable.length / this.state.itemsCountPerPage);
    }

    /**
     * 
     */
    areThereModifiedServices() {
        let servicesList = this.state.services;
        let areThereModifiedServices = false;
        try {

            for (let index = 0; index < servicesList.length; index++) {
                let tsDataCalcoloRischioAttuale = new Date(servicesList[index].DataCalcoloRischioAttuale).getTime();
                let tsDataModifica = new Date(servicesList[index].DataModifica).getTime();
                if (tsDataModifica > tsDataCalcoloRischioAttuale) {
                    //debugger
                    return true;
                }

            }
            //console.log('areThereModifiedServices? ', areThereModifiedServices);
            return areThereModifiedServices;
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    render() {

        var rows = '';
        if (!this.state.currentPageListServices.length) {
            rows = <div className="row">
                {this.state.emptyTest}
            </div>
        } else {
            rows = this.state.currentPageListServices.map(function (field, i) {
                //debugger
                return (
                    <ListaServiziAnalisiRischioRow
                        key={i}
                        rowId={i}
                        service={field}
                        categoriesTostring={Util.toStringList(field.Categorie)}
                        typologiesTostring={Util.toStringList(field.Tipologie)}
                        //stato={STATI[field.StatoAssessmentId]}
                        stato={field.Analisi}
                    />
                );
            }.bind(this)
            );
        }
        /**
        * 
        */
        let stateOptions = Object.keys(STATI).map(function (key, i) {
            return (
                <option key={i} value={key}>{STATI[key]}</option>
            );
        });

        /**
        * 
        */
        let typeOption = this.state.typologies.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        let categoryOption = this.state.categories.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" onClick={() => history.push(API_CONFIG.HomePath)} href="javascript:void(0)">Home</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Compliance Self-Assessment</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Gap Analysis</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK}/> */}
                            <span className="titoloPagina">Elenco Applicazioni</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>L’elenco contiene l'anagrafica del Parco Applicativo della Pa. Selezionare l'Applicazione per visualizzare l'anagrafica di dettaglio
                                oppure accedere al questionario di Gap Analysis. <br/> E’ possibile utilizzare il campo ricerca per elencare le
                                sole applicazioni di proprio interesse.</p>
                        </div>
                        {/*<div className="col-lg-12">
                            <span className="paRight InfoAgg" hidden={!this.areThereModifiedServices()}><i className="fa fa-warning testoRosso"></i>servizio modificato</span>
                        </div>
                         <div className="col-lg-12">
                            <span className="paRight InfoAgg"><a data-bs-toggle="collapse" href="#collapseLegenda"><i className="fa fa-question testoBlu"></i> Legenda</a></span>
                            <div id="collapseLegenda" className="collapse" data-parent="#accordion">
                                <LegendaTable widthCol1={"20%"} widthCol2={"80%"} bgcolor="#ECECEC" cellPadding="10" />
                            </div>
                        </div> */}
                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-10"></div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>

                    <div className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-7 spazio30"></div>

                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} />

                            <div className="col-lg-2">
                                <a className="font16 filtroAvanzato" data-bs-toggle="collapse" href="#collapseRicerca"><i className="fa fa-filter"></i>    RICERCA AVANZATA</a>
                            </div>

                            <div id="collapseRicerca" className="col-lg-12 collapse font16" data-parent="#accordion">
                                <div className="spazio10">	</div>

                                <div className="row">

                                    <div className="col-lg-6">
                                        <strong> Ricerca libera:</strong> <br />
                                        <input className="form300"
                                            id="search"
                                            value={this.state.searchAll}
                                            type="text"
                                            onChange={this.updateSearchAll} />
                                        <div className="spazio10"></div>

                                    </div>

                                    {/*   </div>
                                <div className="row">
                                 */}
                                    <div className="col-lg-6">
                                        <strong> Stato dell'analisi:</strong><br />
                                        <select name="select4" id="select4" className="select300"
                                            value={this.state.selectedState}
                                            onChange={this.updateState}>
                                            <option value='-1'>Tutti</option>
                                            {/* {stateOptions} */}
                                            <option value={STATI[0]}>{STATI[0]}</option>
                                            <option value={STATI[1]}>{STATI[1]}</option>
                                            <option value={STATI[2]}>{STATI[2]}</option>
                                        </select>
                                    </div>

                                    {/*
                                    <div className="col-lg-6">
                                        <strong> Tipologia:</strong><br />
                                        <select name="select7" id="select7" className="select300"
                                            value={this.state.selectedTypology}
                                            onChange={this.updateTypology}>
                                            <option value='-1'>Tutte</option>
                                            {typeOption}
                                        </select>
                                        <div className="spazio10"></div>
                                    </div>
                                    
                                    
                                    <div className="col-lg-6">
                                        <strong> Categoria:</strong><br />
                                        <select name="select6" id="select6" className="select300"
                                            value={this.state.selectedCategory}
                                            onChange={this.updateCategory}>
                                            <option value='-1'>Tutte</option>
                                            {categoryOption}
                                        </select>
                                    </div>
                                */}

                                    {/* <div className="col-lg-6">
                                        <strong> Servizio Trasversale/Verticale:</strong><br />
                                        <select name="select8" id="select8" className="select300"
                                            value={this.state.selectedTrasversal}
                                            onChange={this.updateServiceType}>
                                            <option value='-1'>Tutti</option>
                                            <option value='Trasversale'>Trasversale</option>
                                            <option value='Verticale'>Verticale</option>
                                        </select>
                                    </div> */}
                                    {/*
                                    <div className="col-lg-6">
                                        <strong> <br />
                                            Servizio trasversale:</strong> &nbsp;
                                            <input type="radio"
                                            onChange={(e) => this.updateServiceType('trasversale', e)}
                                            checked={this.state.trasversale}
                                            id="RadioGroup1_0" />
                                        si &nbsp;&nbsp;
                                        <input type="radio"
                                            onChange={(e) => this.updateServiceType('verticale', e)}
                                            checked={this.state.verticale}
                                            id="RadioGroup1_1" />
                                        no
                                    </div>
                                    */}
                                </div>

                                <div className="spazio10"></div>
                                <hr />
                            </div>

                            <div className="col-lg-7 card-headerLeft nascondiCell">
                                <strong onClick={() => this.orderServicesList('nome')} style={{ cursor: 'pointer' }}>Applicazione
                            <i className={(this.state.selectedColumn.localeCompare("nome") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                            <div className="col-lg-2 card-header nascondiCell">
                                <strong onClick={() => this.orderServicesList('data')} style={{ cursor: 'pointer' }}>Data ultima Gap Analysis
                            <i className={(this.state.selectedColumn.localeCompare("data") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                            <div className="col-lg-1 card-header nascondiCell">
                                <strong onClick={() => this.orderServicesList('rischio')} style={{ cursor: 'pointer' }}>IUC
                            <i className={(this.state.selectedColumn.localeCompare("rischio") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                            {/* <div className="col-lg-1 card-header nascondiCell"><strong onClick={() => this.orderServicesList('criticità')} style={{ cursor: 'pointer' }}>Rischio</strong></div> */}

                            <div className="col-lg-1 card-header nascondiCell">
                                <strong onClick={() => this.orderServicesList('stato')} style={{ cursor: 'pointer' }}>Analisi
                            <i className={(this.state.selectedColumn.localeCompare("stato") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>

                            <div className="col-lg-1 card-header nascondiCell"><strong>Azioni</strong></div>

                        </div>

                        {rows}

                        <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.rowsTable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                </div>
                <br />
                <br />
                <br />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
            </div>
        )
    }
}