import React, { Component } from 'react';
import { API_CONFIG } from '../../public/js/apiServices';
import $ from 'jquery';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ListaServiziImpatti } from './ListaServiziImpatti';
import { ModalDialog } from '../Common/ModalDialog';
import infoIcon from '../../public/images/info.png';

let messageError = '';
// import Highcharts from 'highcharts/highstock'
// import HighchartsReact from 'highcharts-react-official'

const LINK = [
    { nome: "Valutazione impatti", url: "/ValutazioneImpatti" },
    { nome: "Riepilogo dati", url: "/RiepilogoImpatti" }
];

export class RiepilogoDatiImpatti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            TipologieList: [],
            TotaliServizi: {},
            Distribuzione: {}
        };
        this.createTipologieList = this.createTipologieList.bind(this);
        this.createTipologieListTop = this.createTipologieListTop.bind(this);
        this.getWidth = this.getWidth.bind(this);
        this.getImpatto = this.getImpatto.bind(this);
        this.getIndice = this.getIndice.bind(this);
        this.getTotServizi = this.getTotServizi.bind(this);
        this.createImpattiRID = this.createImpattiRID.bind(this);
        this.getTestoImpatto = this.getTestoImpatto.bind(this);

    }

    componentDidMount() {
        try {
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.impattocompositeRiepilogo,
                    type: 'POST',
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        console.log(response.data);
                        this.setState({ TotaliServizi: response.data.getTotaleServiziValutati[0] });
                        this.setState({ Distribuzione: response.data.getDistribuzioneImpatto[0] });
                        this.setState({ TipologieList: response.data.getTotaleTipologieValutate });
                        window.setGauge("gauge3", this.getIndice());
                        //window.setPyramidImpatti(this.getImpatto());                        
                        window.setGauge4liv("gauge4liv", this.getImpatto() / 4);
                        var totali = this.getTotServizi();
                        window.setGaugeImpatti(totali.perc);

                        var rid = this.createImpattiRID();
                        window.setGauge4liv("gaugeR", rid.impattoR / 4);
                        window.setGauge4liv("gaugeI", rid.impattoI / 4);
                        window.setGauge4liv("gaugeD", rid.impattoD / 4);
                    }.bind(this),
                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
                });

        } catch (error) {
            messageError = error;
            $('#btnError').get(0).click();
            return console.error(error);
        }
    }

    createImpattiRID() {
        var testoR, testoI, testoD = "";
        var classR, classI, classD = "";
        //var iconR, iconI, iconD;
        switch (this.state.TotaliServizi.PAImpattoR) {
            case 1:
                testoR = "BASSO"; classR = "testoVerde";
                // iconR = ICON[1].src;
                break;
            case 2:
                testoR = "MEDIO"; classR = "testoArancio";
                // iconR = ICON[2].src;
                break;
            case 3:
                testoR = "ALTO"; classR = "testoRosso";
                // iconR = ICON[3].src;
                break;
            case 4:
                testoR = "CRITICO"; classR = "testoNero";
                // iconR = ICON[4].src;
                break;
            default:
                break;
        }
        switch (this.state.TotaliServizi.PAImpattoI) {
            case 1:
                testoI = "BASSO"; classI = "testoVerde";
                // iconI = ICON[1].src;
                break;
            case 2:
                testoI = "MEDIO"; classI = "testoArancio";
                // iconI = ICON[2].src;
                break;
            case 3:
                testoI = "ALTO"; classI = "testoRosso";
                // iconI = ICON[3].src;
                break;
            case 4:
                testoI = "CRITICO"; classI = "testoNero";
                // iconI = ICON[4].src;
                break;
            default:
                break;
        }
        switch (this.state.TotaliServizi.PAImpattoD) {
            case 1:
                testoD = "BASSO"; classD = "testoVerde";
                // iconD = ICON[1].src;
                break;
            case 2:
                testoD = "MEDIO"; classD = "testoArancio";
                // iconD = ICON[2].src;
                break;
            case 3:
                testoD = "ALTO"; classD = "testoRosso";
                // iconD = ICON[3].src;
                break;
            case 4:
                testoD = "CRITICO"; classD = "testoNero";
                // iconD = ICON[4].src;
                break;
            default:
                break;
        }

        var response = {};
        response.impattoR = isNaN(this.state.TotaliServizi.PAImpattoR) ? 0.01 : this.state.TotaliServizi.PAImpattoR;
        response.testoR = testoR;
        response.classR = classR;

        response.impattoI = isNaN(this.state.TotaliServizi.PAImpattoI) ? 0.01 : this.state.TotaliServizi.PAImpattoI;
        response.testoI = testoI;
        response.classI = classI;

        response.impattoD = isNaN(this.state.TotaliServizi.PAImpattoD) ? 0.01 : this.state.TotaliServizi.PAImpattoD;
        response.testoD = testoD;
        response.classD = classD;

        return response;

    }

    getIndice() {
        var indice = 1;
        var value = 0.1;
        if (this.state.TipologieList.length > 0) {
            indice = 3;
            for (let index = 0; index < this.state.TipologieList.length; index++) {
                if (this.state.TipologieList[index].TotaleTipologieValutate >=
                    this.state.TipologieList[index].SogliaBassa) {
                    if (this.state.TipologieList[index].TotaleTipologieValutate <
                        this.state.TipologieList[index].SogliaMedia) {
                        indice = 2;
                    }
                }
                else {
                    indice = 1;
                    break;
                }
            }
        }


        switch (indice) {
            case 1:
                value = 0.1;
                break;
            case 2:
                value = 0.5;
                break;
            case 3:
                value = 0.9;
                break;
            default:
                break;
        }
        return value;
    }

    getTotServizi() {
        var response = {};

        var perc = this.state.TotaliServizi.TotaleValutati / this.state.TotaliServizi.Totale;
        if (isNaN(perc)) {
            perc = 0;
        }
        response.perc = perc.toFixed(2);
        response.tot = parseInt(this.state.TotaliServizi.Totale, 10);
        response.valutati = parseInt(this.state.TotaliServizi.TotaleValutati, 10);
        if (isNaN(response.valutati)) {
            response.valutati = 0;
        }
        if (isNaN(response.tot)) {
            response.tot = 0;
        }

        return response;

    }

    getImpatto() {
        var impatto = Math.round((this.state.TotaliServizi.PAImpattoR + this.state.TotaliServizi.PAImpattoI + this.state.TotaliServizi.PAImpattoD) / 3);

        return isNaN(impatto) ? 0 : impatto;
    }

    getTestoImpatto() {
        var impatto = Math.round((this.state.TotaliServizi.PAImpattoR + this.state.TotaliServizi.PAImpattoI + this.state.TotaliServizi.PAImpattoD) / 3);

        var impattoRow;
        switch (impatto) {
            case 1:
                impattoRow = <span className="testoVerde"> BASSO</span>
                break;
            case 2:
                impattoRow = <span className="testoArancio"> MEDIO</span>
                break;
            case 3:
                impattoRow = <span className="testoRosso"> ALTO</span>
                break;
            case 4:
                impattoRow = <span className="testoNero"> CRITICO</span>
                break;
            default:
                impattoRow = <div></div>
                break;
        }
        return impattoRow;
    }

    createTipologieListTop() {
        let listRows = [];
        var tipologieRows = [];
        var tipologieRowsHide = [];
        for (let index = 0; index < this.state.TipologieList.length; index++) {

            var testo = '';
            var classe = '';
            if (this.state.TipologieList[index].TotaleTipologieValutate < this.state.TipologieList[index].SogliaBassa) {
                testo = ' BASSO';
                classe = 'testoRosso';
            }
            else if (this.state.TipologieList[index].TotaleTipologieValutate < this.state.TipologieList[index].SogliaMedia) {
                testo = ' MEDIO';
                classe = 'testoArancio';
            }
            else {
                testo = ' ALTO';
                classe = 'testoVerde';
            }
            var divNome = <div key={"tipologiaNome" + index} className="col-lg-10 card-headerT">
                {this.state.TipologieList[index].Nome}
            </div>;
            var divTot = <div key={"tipologiaTot" + index} className="col-lg-1 card-headerTR">
                {this.state.TipologieList[index].TotaleTipologieValutate}/{this.state.TipologieList[index].SogliaMedia}	</div>;
            var divLink = <div key={"tipologiaLivello" + index} className="col-lg-1 card-headerT">
                <span className={classe}>{testo}</span>
            </div>;

            if (tipologieRows.length < 12) {
                tipologieRows.push(divNome);
                tipologieRows.push(divTot);
                tipologieRows.push(divLink);
            }
            else {
                tipologieRowsHide.push(divNome);
                tipologieRowsHide.push(divTot);
                tipologieRowsHide.push(divLink);
            }
        }

        listRows.push(<div className="row font16" key={"listOfRows"}>{tipologieRows}</div>)

        if (tipologieRows.length > 0) {
            if (tipologieRowsHide.length > 0) {
                listRows.push(<div key="hide" id="collapse1" className="InfoAgg giustifica collapse" data-parent="#accordion">
                  <div className="row font16">{tipologieRowsHide}</div>
                </div>);

                listRows.push(
                    <div className="row font16"  key="vediTuttiRow">
                <div key="vediTutti" className="col-lg-12 card-headerT">
                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse1">
                        <span className="if-collapsed">Vedi tutte <i className="paRight fa fa-angle-down faBold"></i></span>
                        <span className="if-not-collapsed">Riduci <i className="paRight fa fa-angle-up faBold"></i></span>	</a>
                        </div>
                        </div>);

            }
        }
        return listRows;
    }



    createTipologieList() {
        var tipologieRows = [];
        var tipologieRowsHide = [];
        for (let index = 0; index < this.state.TipologieList.length; index++) {

            var divNome = <div key={"tipologiaNome" + index} className="col-lg-11 card-headerT">
                {this.state.TipologieList[index].Nome}
            </div>;
            var divTot = <div key={"tipologiaTot" + index} className="col-lg-1 card-headerTR">
                {this.state.TipologieList[index].TotaleTipologieValutate}/{this.state.TipologieList[index].TotaleTipologie}	</div>;

            if (tipologieRows.length < 8) {
                tipologieRows.push(divNome);
                tipologieRows.push(divTot);
            }
            else {
                tipologieRowsHide.push(divNome);
                tipologieRowsHide.push(divTot);
            }
        }

        if (tipologieRows.length > 0) {
            if (tipologieRowsHide.length > 0) {
                tipologieRows.push(<div key="hide" id="collapse2" className="collapse col-lg-12 row" data-parent="#accordion">
                    {tipologieRowsHide}
                </div>);

                tipologieRows.push(<div key="vediTutti" className="col-lg-12 card-headerT">
                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse2">
                        <span className="if-collapsed">Vedi tutte <i className="paRight fa fa-angle-down faBold"></i></span>
                        <span className="if-not-collapsed">Riduci <i className="paRight fa fa-angle-up faBold"></i></span>	</a></div>);

            }
        }

        return tipologieRows;
    }


    getWidth(perc) {
        if (isNaN(perc)) {
            perc = 0;
        }

        return perc;
    }

    getPAName() {
        let decoded = this.props.auth.getAccessToken();
        var paName = "";
        if (decoded !== null) {
            let user = decoded.user;
            paName = user.NomePA;
        }
        return paName;
    }

    render() {
        var impattiRID = this.createImpattiRID();
        var totali = this.getTotServizi();
        // var gaugeColor = "#cc0000";
        var testoIndice = "BASSO";
        var coloreIndice = "testoRosso";
        var indice = this.getIndice();

        switch (indice) {
            case 0.5:
                // gaugeColor = "#ff9900";
                testoIndice = "MEDIO";
                coloreIndice = "testoArancio";
                break;
            case 0.9:
                testoIndice = "ALTO";
                // gaugeColor = "#0CEA04";
                coloreIndice = "testoVerde";
                break;
            default:
                break;
        }
        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
                <div className="container cnsContainer">
                    <div className="row">
                        <div className="col-lg-12">

                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Riepilogo dati per valutazione impatti</span>

                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12 giustifica">	
                    <p>La pagina espone un riepilogo della “Valutazione dell’impatto” sui Servizi della Azienda attraverso i seguenti indicatori: indice di completezza della valutazione di impatto della Azienda e di ogni singolo servizio, livello di impatto complessivo della Azienda con la distinzione per singola proprietà di sicurezza (Riservatezza, Integrità e Disponibilità), elenco dei Servizi Valutati rispetto ai servizi che sono stati oggetto di analisi del contesto ed infine il riepilogo dei servizi valutati rispetto alla tipologia di appartenenza.</p>
                    </div>
                    </div>	

                    <div className="linea0066cc"></div>
                    <div className="spazio10"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Completezza</h1><hr />
                        </div>
                        <div className="col-lg-4 marginRight">
                            <div><strong>Indice completezza</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoCompl"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>

                                <div id="collapseInfoCompl" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    L’indice di completezza indica se il numero di servizi valutati è sufficiente ad elaborare una analisi del rischio della Azienda sufficientemente accurata.
                                </div>
                            </div>

                            <div className="spazio20"></div>
                            <div className="gauge gauge--liveupdate" id="gauge3">
                                <div className="gauge__container">
                                    <div className="gauge__marker"></div>
                                    <div className="gauge__markerbis"></div>
                                    <div className="gauge__background"></div>
                                    <div className="gauge__center"></div>
                                    <div className="gauge__data" ></div>
                                    <div className="gauge__dataB"></div>
                                    <div className="gauge__dataC"></div>
                                    <div className="gauge__needle"></div>
                                </div>
                                <div className="gauge__labels mdl-typography__headline">
                                    <span className="gauge__label--low">BASSO</span>
                                    <span className="gauge__label--spacer">
                                        <span className={coloreIndice}> {testoIndice}</span>
                                    </span>
                                    <span className="gauge__label--high">ALTO</span>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div><strong>Indice di completezza per tipologia di servizio</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoTip"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>

                                <div id="collapseInfoTip" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Per ogni tipologia di servizio viene indicato il numero di servizi valutati rispetto al numero atteso per raggiungere un indice di completezza ALTO, Viene inoltre visualizzato l’indice di completezza per Ogni Tipologia. L’indice di completezza totale coincide con il peggior indice di completezza delle Tipologie.
                                </div>
                            </div>
                            <div className="spazio10"></div>
                           
                                {this.createTipologieListTop()}
                            
                        </div>

                    </div>
                    <div className="spazio10"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Impatto</h1><hr />

                        </div>
                        <div className="col-lg-4 marginRight">
                            <div><strong>Impatto complessivo</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoProfilo"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>
                                <div id="collapseInfoProfilo" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Impatto RID complessivo della  Azienda.
                                </div>
                            </div>

                            <div className="spazio20"></div>

                            <div className="gauge4liv gauge--liveupdate2" id="gauge4liv">
                                <div className="gauge__container4liv">
                                    <div className="gauge__marker4liv"></div>
                                    <div className="gauge__markerbis4liv"></div>
                                    <div className="gauge__markertris4liv"></div>
                                    <div className="gauge__background4liv"></div>
                                    <div className="gauge__center4liv"></div>
                                    <div className="gauge__data4liv"></div>
                                    <div className="gauge__data4livB"></div>
                                    <div className="gauge__data4livC"></div>
                                    <div className="gauge__data4livD"></div>
                                    <div className="gauge__needle4liv"></div>
                                </div>
                                <div className="gauge__labels4liv mdl-typography__headline4liv">
                                    <span className="gauge__label--low4liv">BASSO</span>
                                    <span className="gauge__label--spacer4liv">
                                        {this.getTestoImpatto()}
                                    </span>
                                    <span className="gauge__label--high4liv">CRITICO</span>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div><strong>Impatto per proprietà di sicurezza dell’informazione:</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoDistr"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>

                                <div id="collapseInfoDistr" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei singoli impatti per proprietà di informazione
                                </div>
                            </div>

                            <div className="spazio20"></div>

                            <div className="row">

                                <div className="col-lg-4">
                                    <div className="gauge4liv gauge--liveupdate2" id="gaugeR">
                                        <div className="gauge__container4liv">
                                            <div className="gauge__marker4liv"></div>
                                            <div className="gauge__markerbis4liv"></div>
                                            <div className="gauge__markertris4liv"></div>
                                            <div className="gauge__background4liv"></div>
                                            <div className="gauge__center4liv"></div>
                                            <div className="gauge__data4liv"></div>
                                            <div className="gauge__data4livB"></div>
                                            <div className="gauge__data4livC"></div>
                                            <div className="gauge__data4livD"></div>
                                            <div className="gauge__needle4liv"></div>
                                        </div>
                                        <div className="gauge__labels4liv mdl-typography__headline4liv">
                                            <span className="gauge__label--low4liv">BASSO</span>
                                            <span className="gauge__label--spacer4liv">
                                                <span className={impattiRID.classR}>{impattiRID.testoR}</span>
                                                <br />
                                                RISERVATEZZA
                        </span>
                                            <span className="gauge__label--high4liv">CRITICO</span>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="gauge4liv gauge--liveupdate2" id="gaugeI">
                                        <div className="gauge__container4liv">
                                            <div className="gauge__marker4liv"></div>
                                            <div className="gauge__markerbis4liv"></div>
                                            <div className="gauge__markertris4liv"></div>
                                            <div className="gauge__background4liv"></div>
                                            <div className="gauge__center4liv"></div>
                                            <div className="gauge__data4liv"></div>
                                            <div className="gauge__data4livB"></div>
                                            <div className="gauge__data4livC"></div>
                                            <div className="gauge__data4livD"></div>
                                            <div className="gauge__needle4liv"></div>
                                        </div>
                                        <div className="gauge__labels4liv mdl-typography__headline4liv">
                                            <span className="gauge__label--low4liv">BASSO</span>
                                            <span className="gauge__label--spacer4liv">
                                                <span className={impattiRID.classI}>{impattiRID.testoI}</span>
                                                <br />
                                                INTEGRITA'
                        </span>
                                            <span className="gauge__label--high4liv">CRITICO</span>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="gauge4liv gauge--liveupdate2" id="gaugeD">
                                        <div className="gauge__container4liv">
                                            <div className="gauge__marker4liv"></div>
                                            <div className="gauge__markerbis4liv"></div>
                                            <div className="gauge__markertris4liv"></div>
                                            <div className="gauge__background4liv"></div>
                                            <div className="gauge__center4liv"></div>
                                            <div className="gauge__data4liv"></div>
                                            <div className="gauge__data4livB"></div>
                                            <div className="gauge__data4livC"></div>
                                            <div className="gauge__data4livD"></div>
                                            <div className="gauge__needle4liv"></div>
                                        </div>
                                        <div className="gauge__labels4liv mdl-typography__headline4liv">
                                            <span className="gauge__label--low4liv">BASSO</span>
                                            <span className="gauge__label--spacer4liv">
                                                <span className={impattiRID.classD}>{impattiRID.testoD}</span>
                                                <br />
                                                DISPONIBILITA'
                                            </span>
                                            <span className="gauge__label--high4liv">CRITICO</span>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="spazio10"></div>

                    

                    <div className="row">
                    <div className="spazio20"></div>
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Servizi valutati</h1><hr />

                        </div>
                        <div className="col-lg-4 marginRight">
                            <div><strong>Riepilogo servizi valutati</strong>
                                <a data-bs-toggle="collapse" href="#collapse6"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>
                                <div id="collapse6" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Valutati rispetto al totale dei servizi caratterizzati sul sistema.</div>
                            </div>

                            <div className="spazio20"></div>
                            <div className="gaugeImpatti gauge--liveupdateImpatti" id="gaugeImpatti">
                                <div className="gauge__containerImpatti">
                                    <div className="gauge__backgroundImpatti"></div>
                                    <div className="gauge__centerImpatti"></div>
                                    <div className="gauge__dataImpatti"></div>
                                    <div className="gauge__dataImpattiB"></div>
                                    <div className="gauge__needleImpatti"></div>
                                </div>
                                <div className="gauge__labelsImpatti mdl-typography__headlineImpatti">
                                    <span className="gauge__label--lowImpatti">0</span>
                                    <span className="gauge__label--spacerImpatti">{totali.valutati} ({totali.perc * 100}%)</span>
                                    <span className="gauge__label--highImpatti">{totali.tot}</span>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div><strong>Riepilogo dei servizi valutati per Tipologia di servizio</strong>
                                <a data-bs-toggle="collapse" href="#collapse7"><span className=" testoBlu paRight"><img src={infoIcon} alt=""/></span></a>
                                <div id="collapse7" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi valutati rispetto ai servizi caratterizzati per ciascuna tipologia
                                </div>
                            </div>

                            <div className="spazio10"></div>
                            <div className="row font16">
                                {this.createTipologieList()}
                            </div>
                        </div>
                    </div>


                        <div className="spazio10"></div>
                    <div className="spazio20"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ListaServiziImpatti riepilogo="true" />
                        </div>
                    </div>
                   
                    
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
                </div>
            </div>

        )
    }
}