exports.API_CONFIG = {

  //SERVIZI COMPOSITE
  servizicompositeByIdUrl: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById",

  serviziSaveOrUpdateUrl: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate",

  servizioCompositeRiepilogo: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/riepilogo",

  servizioCompositeRiepilogoHome: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/riepilogoHome",

  getServiziByCodiceIPAUrl: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getServiziByCodiceIPA",

  getnuovoservizioinfo: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getnuovoservizioinfo",

  creazioneservizio: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/creazioneservizio",

  getKBQuestionsByTipo: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getKBQuestionsByTipo",

  getSelfAssessmentById: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getSelfAssessmentById",

  getImportDatiByNomeAsset: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getImportDatiByNomeAsset",

  getAllKBBusinessGoals: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllKBBusinessGoals",

  getAllKBAssessment: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllKBAssessment",

  getAllKBDomains: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllKBDomains",

  getAllBusinessGoals: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllBusinessGoals",

  getBusinessGoalById: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getBusinessGoalById",

  getBusinessGoalXPAById: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getBusinessGoalXPAById",

  insertBusinessGoalXPA: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/insertBusinessGoalXPA",

  updateBusinessGoalXPA: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/updateBusinessGoalXPA",

  updateSelfAssessment: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/updateSelfAssessment",

  insertUseCase: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/insertUseCase",

  insertCloudProviderCapabilities: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/insertCloudProviderCapabilities",

  updateUseCase: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/updateUseCase",

  updateCloudProviderCapabilities: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/updateCloudProviderCapabilities",

  getAllKBCommonUseCases: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllKBCommonUseCases",

  getAllKBCloudProviderCapabilities: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getAllKBCloudProviderCapabilities",

  insertKBDomainsXUseCases: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/insertKBDomainsXUseCases",

  deleteKBDomainsXUseCases: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/deleteKBDomainsXUseCases",

  getKBDomainsXUseCasesByUseCaseId: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getKBDomainsXUseCasesByUseCaseId",

  deleteUseCase: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/deleteUseCase",

  deleteCloudProviderCapabilities: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/deleteCloudProviderCapabilities",

  deleteBusinessGoalXPA: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/deleteBusinessGoalXPA",

  getUseCaseByCodiceIPA: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getUseCaseByCodiceIPA",

  getCloudProviderCapabilitiesByCodiceIPA: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCloudProviderCapabilitiesByCodiceIPA",

  getCategorieSottoCategorie: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie",

  getServiziAnagrafica: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getServiziAnagrafica",

  modificaServizio: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/modificaServizio",

  pubblicaServizio: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/pubblicaServizio",

  getRegistroApplicazione: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getRegistroApplicazione",

  saveRegistroApplicazione: "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveRegistroApplicazione",

  //IMPATTI COMPOSITE
  impattocompositeGetById: "https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/getById",

  impattocompositeSaveOrUpdate: "https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/saveOrUpdate",

  impattocompositeGetServiziByCodiceIPA: "https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/getServiziByCodiceIPA",

  impattocompositeRiepilogo: "https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/Riepilogo",

  impattocompositeRiepilogoHome: "https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/riepilogoHome",

  //Assessment COMPOSITE

  assessmentcompositeListAssessment: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/listAssessment",

  assessmentcompositeGetServiziByCodiceIPA: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getServiziByCodiceIPA",

  assessmentcompositeIniziaAssessment: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/iniziaAssessment",

  assessmentcompositeGetAssessmentQuestionario: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentQuestionario",

  assessmentcompositeSaveOrUpdate: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdate",

  assessmentcompositeRiepilogo: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/Riepilogo",

  assessmentcompositeRiepilogoHome: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/RiepilogoHome",

  listAssessmentTrattamento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/listAssessmentTrattamento",

  listAssessmentExecute: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/listAssessmentExecute",

  assessmentcompositeGetRiskAppetiteList: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getRiskAppetiteList",

  assessmentcompositeUpdateRiskAppetite: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/updateRiskAppetite",

  assessmentcompositeripristinaRiskAppetite: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/ripristinaRiskAppetite",

  assessmentcompositeGetOpzioniTrattamento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getOpzioniTrattamento",

  assessmentcompositesaveOrUpdateOpzioniTrattamento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateOpzioniTrattamento",

  cambiaStatoAssessment: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/cambiaStatoAssessment",

  azioniDiTrattamentoByAssessmentId: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentAzioniDiTrattamentoByAssessmentId",

  assessmentcompositeGetMinacce: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getMinacce",

  saveOrUpdateAzioniDiTrattamento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateAzioniDiTrattamento",

  insertAssessmentPTRAvanzamento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/insertAssessmentPTRAvanzamento",

  insertAssessmentMonitoraggioEvento: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/insertAssessmentMonitoraggioEvento",

  updateMonitoraggio: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/updateMonitoraggio",

  calcoloGradoDiImplementazioneAtteso: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/calcoloGradoDiImplementazioneAtteso",

  assessmentcompositeExecutiveSummary: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/executiveSummary",

  getMonitoraggioByAssessmentId: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getMonitoraggioByAssessmentId",

  getAssessmentServiziImport: "https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentServiziImport",

  importExcel:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/importExcel",

  importDati:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/importDati",

  exportExcel:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/exportExcel",

  getContestoNormativo:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getContestoNormativo",

  updateContestoNormativo:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/updateContestoNormativo",

  getAssessmentRoadmap:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentRoadmap",

  saveOrUpdateAssessmentRoadmap:"https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateAssessmentRoadmap",

  //Utente Composite
  getAllUsers: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/getAllUtente",

  getUserById: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/getUtenteRuoloByCodiceFiscale",

  updateUtente: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/updateUtente",

  updateAlertAggiornamentoLibreriaUtente: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/updateAlertAggiornamentoLibreriaUtente",

  insertUtente: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/insertUtente",

  deleteUtente: "https://cns.westeurope.cloudapp.azure.com/utentecompositeserv/api/utentecomposite/deleteUtente",

  //PA Composite
  paByPatternName: "https://cns.westeurope.cloudapp.azure.com/pacompositeserv/api/pacomposite/getPAByPatternNome",

  //APIGATEWAY
  loginSpidUrl: "https://cns.westeurope.cloudapp.azure.com/api/login?entityID=",

  loginSpidTest: "https://cns.westeurope.cloudapp.azure.com/api/login?entityID=ambientetestRemoto",

  logoutSpidUrl: "https://cns.westeurope.cloudapp.azure.com/api/logout",

  changePAUrl: "https://cns.westeurope.cloudapp.azure.com/api/changePAUserLogged",

  PAListUrl: "https://cns.westeurope.cloudapp.azure.com/api/getPAByCodiceFiscale",

  getUserInfo: "https://cns.westeurope.cloudapp.azure.com/api/getUserInfo",

  renewToken: "https://cns.westeurope.cloudapp.azure.com/api/renewToken",

  residualTimeToken: "https://cns.westeurope.cloudapp.azure.com/api/residualTimeToken",

  loginIAM: "https://cns.westeurope.cloudapp.azure.com/api/loginIAM",

  HomePath: "/Home"
};