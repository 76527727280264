import React, { Component } from 'react';

export class DatePicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDate: ""
        };
        this.updateDate = this.updateDate.bind(this);
        this.getOption = this.getOption.bind(this);
    }

    componentDidMount() {
        this.setState({ selectedDate: this.props.selectedDate });
    }

    updateDate(event) {
        let quarterDate = event.target.value;
        this.setState({ selectedDate: quarterDate },
            () => this.props.updateDataChiusura(this.state.selectedDate, this.props.action));
        
    }

    getOption()
    {
        var yearMin = new Date().getFullYear();
        var yearMax = yearMin + 5;
        var optionsList = [];
        var quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
        var yearNow = new Date().getFullYear();
        optionsList.push(<option key={"keyEmpty"} disabled value="-1"></option>);

        var found = false;

        for (var i = yearMin; i < yearMax; i++)         
        {
            var startQuarter = 1;
           
           
            if(i === yearNow)
                startQuarter = quarterNow;

            for (var j = startQuarter; j < 5; j++) 
            {
                var valueOption = j + "-" + i;
                var text = "Q"+j + " - " + i;

                if(valueOption === this.state.selectedDate)
                    found = true;

                optionsList.push(<option key={j + "key" + i} value={valueOption}>{text}</option>);
            }
        }

        if(!found && this.state.selectedDate != "-1" && (this.state.selectedDate))
        {
            var split = this.state.selectedDate.split("-");
            var year = parseInt(split[1],10);
            var quarter = split[0];
            var text = "Q"+quarter + " - " + year;
            var valueOption = quarter + "-" + year;
            var option = <option key={"notFoundKey"} disabled value={valueOption}>{text}</option>;
            if(year < yearNow || (quarter < quarterNow && year === yearNow))
                optionsList.splice(1,0,option);
            else
                optionsList.push(option);
        }
        return optionsList;
    }

    render() {
        return (
                 <select
                        disabled={this.props.isDisabled}
                        className={this.props.isDisabled ? "disabledBg" : this.props.className}
                        value={this.props.selectedDate}
                        onChange={this.updateDate}>
                        {this.getOption()}
                </select>

          
        );
    }
}