import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}


export class CensimentoServizi extends Component {



    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/CensimentoApplicazioni')}>Censimento delle Applicazioni</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Censimento delle Applicazioni</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                            <p>Il Censimento delle Applicazioni è la funzione che l’utente deve utilizzare per popolare il Catalogo delle Applicazioni che censisce il parco applicativo della PA. <br/>
    L’utente deve creare o aggiornare l’insieme delle Applicazioni inserendo tutte le informazioni richieste per ognuna di esse.
    Una volta creato e/o modificato il catalogo e completato l’inserimento delle informazioni richieste l’utente può procedere all'individuazione del contesto di Analisi. <br/>
    Prima di procedere all'individuazione del contesto di Analisi l’utente deve accertarsi che tutte le informazioni del contesto applicativo siano state censite correttamente nel sistema. </p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/NuovaApplicazione')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">NUOVA APPLICAZIONE</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Consente di censire/inserire nel sistema, una nuova Applicazione nel caso in cui non fosse presente nell'Anagrafica 'Parco applicativo PA'.
       
                                <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ListaApplicazioniCensimento')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ELENCO APPLICAZIONI</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Accesso alla lista delle Applicazioni della PA, sincronizzata con l’anagrafica 'Parco Applicativo PA'.
          <hr />

                            </div>

                        </div></div>



                    <br /><br /><br /><br /><br /><br /><br />

                </div>


            </div>
        )
    }
}