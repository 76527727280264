import React from 'react'
import $ from "jquery";
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { EsportaLibrary } from "./EsportaLibrary";
import { ImportaLibrary } from "./ImportaLibrary";
import { API_CONFIG } from '../../public/js/apiServices';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

const LINK = [
    { nome: "Contesto Normativo", url: "/ContestoNormativo" },
];

// const DOMINI = []
// DOMINI["Dominio del GDPR"] = { id: "GDPR", items: ['Data Privacy Accountability', 'Trasferimento dati personali', "Diritti per l'interessato", 'Misure di sicurezza', 'Condizioni per il trattamento', 'Privacy impact Assessment'] };
// DOMINI["Domini specifici a livello nazionale"] = {
//     id: "specifici", items: [
//         'D.lgs 101/2018',
//         'GDPR 2016/679',
//         "Linee guida gruppo di lavoro 'Art. 29'",
//         'D.lgs 196 / 03 Codice della privacy',
//         'Provvedimenti del Garante',
//         'D.lgs 33 / 2013 Trasparenza',
//         'D.lgs 82 / 2005 Codice Amministrazione Digitale e ss.mm.ii',
//         'Misure Minime ICT per la PA',
//         "'Art. 50 bis' del Codice Amministrazione Digitale e ss.mm.ii",
//         'IRAM 1 & 2',
//         'CCM 3.0.1',
//         'PCI DSS',
//         "AGID circolare n°5 del 30 / 11 / 2017 'All.B'",
//         'AGID Linee Guida per il Disaster Recovery delle PA',
//         'TIA 942 A'
//     ]
// };
// DOMINI["Best Pratices"] = {
//     id: "Best", items: [
//         'ISO 27001: Sistemi di gestione della sicurezza delle informazioni',
//         'ISO 27002 Controlli di sicurezza per le informazioni',
//         'ISO 31000 Gestione del rischio',
//         'ISO 29151: Protezione dei dati personali',
//         'ISO IEC 27017 Controlli di sicurezza per i servizi cloud',
//         'ISO IEC 27018 Controlli privacy per i servizi cloud.'
//     ]
// };

//const listaDomini = ["Dominio1", "Dominio2"];

export class ContestoNormativo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            successMessage: "",
            warningMessage: "",
            errorMessage: "",
            contestoNormativo: [],
            messageError: "",
            selectAll: false
        }
        this.getContestoCompliance = this.getContestoCompliance.bind(this);
        this.updateNorma = this.updateNorma.bind(this);
        this.updateDominio = this.updateDominio.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    saveChanges() {

            $.ajax({
                url: API_CONFIG.updateContestoNormativo,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(this.state.contestoNormativo),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    if (response.status.localeCompare('success') === 0) {
                        $('#btnSuccess').get(0).click();
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr, resp, text)
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                            $('#btnError').get(0).click();
                        }
                    }
                }.bind(this),
            });        
    }

    updateNorma(indexContesto, indexNorma)
    {

        var listContesto = this.state.contestoNormativo;

        if(listContesto[indexContesto].RiferimentoNormativo[indexNorma].Abilitato === "1")
            listContesto[indexContesto].RiferimentoNormativo[indexNorma].Abilitato = "0"
        else
            listContesto[indexContesto].RiferimentoNormativo[indexNorma].Abilitato = "1"

        this.setState({ contestoNormativo: listContesto });    
    }

    updateDominio(indexContesto, indexNorma, indexDominio)
    {

        var listContesto = this.state.contestoNormativo;

        var nomeDominio = listContesto[indexContesto].RiferimentoNormativo[indexNorma].CategoriaMisuraDiSicurezza[indexDominio].Nome;
        var abilitato = listContesto[indexContesto].RiferimentoNormativo[indexNorma].CategoriaMisuraDiSicurezza[indexDominio].Abilitato;

        abilitato = abilitato === "1" ? "0" : "1";

        for (let x = 0; x < listContesto.length; x++) {
            var norme = listContesto[x].RiferimentoNormativo;
            for (let y = 0; y < norme.length; y++) {
                let norma = norme[y];
                let index = norma.CategoriaMisuraDiSicurezza.findIndex(c => c.Nome === nomeDominio);
                
                if(index > -1)
                {
                    norma.Abilitato = abilitato;
                    norma.CategoriaMisuraDiSicurezza[index].Abilitato = abilitato;
                }            
            }
        }

        this.setState({ contestoNormativo: listContesto });    
    }

    componentDidMount() {
        try {
             this.serverRequest =
                $.ajax({
                    url: API_CONFIG.getContestoNormativo,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify({ Report: "0" }),
                    cache: false,
                    success: function (response) {
                        console.log('contestoNormativo:', response); 
                        this.setState({ contestoNormativo: response.data });                      
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });

        } catch (error) {
            return console.error(error);
        }
    }

    getDomini(domini, indexContesto, indexNorma) {

        let returnList = [];
        
        for (let x = 0; x < domini.length; x++) {
            let item = domini[x];
            let checkedItem = item.Abilitato == "1";
            returnList.push(
                    <div key={"destinatario" + x}>
                        <label>
                            {/* <input type="checkbox" 
                             checked={this.state.contestoNormativo[indexContesto].RiferimentoNormativo[indexNorma].CategoriaMisuraDiSicurezza[x].Abilitato === "1"} 
                             onChange={(e) => this.updateDominio(indexContesto, indexNorma, x)}
                            /> */}
                            {' ' + item.Nome}
                        </label>
                        {x != (domini.length-1) && <hr />}
                        
                    </div>
                );               

        }

        return <div className="card-body">
            <div className="row">
                <div className="col-lg-12">
                    {returnList}
                </div>
            </div>
        </div>
    }

    getNorme(norme, index) {

        let rightList = [];
        let leftList = [];
        
        for (let x = 0; x < norme.length; x++) {
            let item = norme[x];
            let checkedItem = item.Abilitato == "1";
            if ((x + 1) % 2 === 0)
            {
                rightList.push(
                    <div key={"destinatario" + x}>
                        <label>
                            <input type="checkbox" 
                            checked={this.state.selectAll || this.state.contestoNormativo[index].RiferimentoNormativo[x].Abilitato === "1"} 
                            onChange={(e) => this.updateNorma(index, x)}
                            />
                            {' ' + item.Nome}
                        </label>
                        <a className="card-link" data-bs-toggle="collapse" href={"#" + index + "collapseNorma" + x}>
                        <i className="iconRight fa fa-chevron-down"></i></a>
                        {x != (norme.length-1) && <hr />}
                        
                    </div>
                );
                rightList.push(<div key={index + "collapseNorma" + x} id={index + "collapseNorma" + x} className="collapse" data-parent="#accordion">
                {this.getDomini(item.CategoriaMisuraDiSicurezza, index, x)}
                </div>);
            }
            else {
                leftList.push(
                    <div key={"destinatario" + x}>
                        <label>
                            <input                                
                                type="checkbox" 
                                checked={this.state.selectAll || this.state.contestoNormativo[index].RiferimentoNormativo[x].Abilitato == "1"}
                                onChange={(e) => this.updateNorma(index, x)}
                                />
                            {' ' + item.Nome}
                        </label>
                        <a className="card-link" data-bs-toggle="collapse" href={"#" + index + "collapseNorma" + x}>
                        <i className="iconRight fa fa-chevron-down"></i></a>
                        {(x != (norme.length-1) && x != (norme.length-2)) && <hr />}
                       
                    </div>
                );
                leftList.push(<div key={index + "collapseNorma" + x} id={index + "collapseNorma" + x} className="collapse" data-parent="#accordion">
                 {this.getDomini(item.CategoriaMisuraDiSicurezza, index, x)}
                </div>);
            }
        }

        return <div className="card-body">
            <div className="row">
                <div className="col-lg-6">
                    {leftList}
                </div>
                <div className="col-lg-6">
                    {rightList}
                </div>
            </div>
        </div>
    }

    getContestoCompliance()
    {
        var contestoDivList = [];
        var count = 0;
        var listContesto = this.state.contestoNormativo;
        
        for (let x = 0; x < listContesto.length; x++) {
            var contesto = listContesto[x];
            contestoDivList.push(
                        <div className="row" key={"dominio" + count}>
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href={"#collapseGDPR" + count}>
                                    <i style={{ visibility: "visible" }} className="testoVerde fa fa-check"></i>
                                    &nbsp;{contesto.ContestoComplianceNome}<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>

                        </div>                       
            );

            contestoDivList.push(<div key={"dominioCollapse" + count} id={"collapseGDPR" + count} className="collapse" data-parent="#accordion">
                            {this.getNorme(contesto.RiferimentoNormativo, x)}
                        </div>);

            contestoDivList.push(<hr key={"dominioH" + count} />);

            count = count+1;
        }
        
        return contestoDivList;
    }


    render() {
        let modalSuccess = <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Contesto Normativo Aggiornato'} />;

        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";

        return (
            <div className={outerClass}>

                <div className={outerClassContainer}>

                    <div className="row">

                        <div className="col-lg-12">
                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Contesto Normativo</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Selezione fonti</span>
                        </div>
                    </div>
                   

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-9 card-headerInfo">
                                <p>
                                    Elenco delle fonti che è necessario selezionare per il contesto di riferimento specifico.
                                </p>
                            </div>
                            <div className="col-lg-3 card-headerRight">
                                <p>
                                    Seleziona tutto <input type="checkbox" onChange={e => {this.setState({selectAll : e.target.checked})}}></input>
                                </p>
                            </div>
                            <div className="linea0066cc"></div>
                        </div>
                    </div>
                    <div className="container cnsContainer">
                        {this.getContestoCompliance()}
                        <div className="row spazio20">

                            <div className="col-lg-4 ">	</div>
                            <div className="col-lg-4 ">
                                <button onClick={() => this.saveChanges()} className="btn btn-success btn-lg btn-block">Applica</button>
                            </div>
                            <div className="col-lg-4 ">	</div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    {modalSuccess}
                </div>
            </div>
        )
    }
}

//export default ImportaLibrary