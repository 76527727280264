import * as React from 'react';
import LIVELLO from '../data/criticality';

// component that renders a single minaccia row
export class MinacciaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emptyTest: '',
            isDisabledGradoImplementatione: false,
            isDisabledDataChiusura: false,
            //caratteristiche: this.props.caratteristiche
        };
        //this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
    }

    /**
        * 
        * @param {*} index 
        * @param {*} array 
        */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].text : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {
        var rowColor = (this.props.index + 1) % 2 === 0 ? "#f5f5f5" : "";
        return (

            <tr key={'sidebar' + this.props.index}>
                <td style={{ backgroundColor: rowColor }}><span className="card-headerLeft font16">
                    {this.props.minaccia.Nome}</span></td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getRisk(this.props.minaccia.RischioAttuale, LIVELLO).text + " font16"}>
                        {/* {LIVELLO[(this.props.minaccia.RischioAttuale) ? this.props.minaccia.RischioAttuale : ''].name.toLocaleUpperCase()} */}
                        {this.getRisk(this.props.minaccia.RischioAttuale, LIVELLO).name.toLocaleUpperCase()}
                    </span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getRisk(this.props.minaccia.RiskAppetite, LIVELLO).text + " font16"}>
                        {/* {LIVELLO[(this.props.minaccia.RiskAppetite) ? this.props.minaccia.RiskAppetite : ''].name.toLocaleUpperCase()} */}
                        {this.getRisk(this.props.minaccia.RiskAppetite, LIVELLO).name.toLocaleUpperCase()}
                    </span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getRisk(this.props.minaccia.RischioResiduo, LIVELLO).text + " font16"}>
                        {/* {LIVELLO[(this.props.minaccia.RiskAppetite) ? this.props.minaccia.RiskAppetite : ''].name.toLocaleUpperCase()} */}
                        {this.getRisk(this.props.minaccia.RischioResiduo, LIVELLO).name.toLocaleUpperCase()}
                    </span>
                </td>
            </tr>
        )
    }
}