import * as React from 'react';
import ICON from '../data/IconLevelImpatti';
import history from '../history';
import { Util } from '../data/Util';

/**
 * 
 * @param {*} path 
*/
function redirect(path, serviceId) {
    try {
        window.sessionStorage.setItem("serviceId", serviceId);
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

/**
 * 
 */
function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}

/**
 * 
 * @param {*} r Riservatezza
 * @param {*} i Integrità
 * @param {*} d Disponibilità
 */
function getRID(r, i, d) {

    if (r < 1)
        return <div className="col-lg-1 card-header"></div>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "bgVerde";
            nameR = "B";
            break;
        case 2:
            classR = "bgArancio";
            nameR = "M";
            break;
        case 3:
            classR = "bgRosso";
            nameR = "A";
            break;
        case 4:
            classR = "bgNero";
            nameR = "C";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "bgVerde";
            nameI = "B";
            break;
        case 2:
            classI = "bgArancio";
            nameI = "M";
            break;
        case 3:
            classI = "bgRosso";
            nameI = "A";
            break;
        case 4:
            classI = "bgNero";
            nameI = "C";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "bgVerde";
            nameD = "B";
            break;
        case 2:
            classD = "bgArancio";
            nameD = "M";
            break;
        case 3:
            classD = "bgRosso";
            nameD = "A";
            break;
        case 4:
            classD = "bgNero";
            nameD = "C";
            break;
        default:
            break;
    }

    return (<div className="col-lg-1 card-header">
        <span className={classR}>{nameR}</span> | <span className={classI}>{nameI}</span> | <span className={classD}>{nameD}</span>
    </div>);
}

function getRIDAccordion(r, i, d) {

    if (r < 1)
        return <p><strong>Impatto R-I-D:</strong><br /></p>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "testoVerde h2";
            nameR = "BASSO";
            break;
        case 2:
            classR = "testoArancio h2";
            nameR = "MEDIO";
            break;
        case 3:
            classR = "testoRosso h2";
            nameR = "ALTO";
            break;
        case 4:
            classR = "testoNero h2";
            nameR = "CRITICO";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "testoVerde h2";
            nameI = "BASSO";
            break;
        case 2:
            classI = "testoArancio h2";
            nameI = "MEDIO";
            break;
        case 3:
            classI = "testoRosso h2";
            nameI = "ALTO";
            break;
        case 4:
            classI = "testoNero h2";
            nameI = "CRITICO";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "testoVerde h2";
            nameD = "BASSO";
            break;
        case 2:
            classD = "testoArancio h2";
            nameD = "MEDIO";
            break;
        case 3:
            classD = "testoRosso h2";
            nameD = "ALTO";
            break;
        case 4:
            classD = "testoNero h2";
            nameD = "CRITICO";
            break;
        default:
            break;
    }

    return (
        <p>
            <strong>Impatto R-I-D:</strong><br />
            Riservatezza: <img src={ICON[r].src} width="20" height="20" alt="" />
            <span className={classR}>
                <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Integrità: <img src={ICON[i].src} width="20" height="20" alt="" />
            <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Disponibilità: <img src={ICON[d].src} width="20" height="20" alt="" />
            <span className={classD}>    <strong> {nameD}</strong></span>
        </p>
    );
}

// component that renders a single service
export class ServiceRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category
        };
        this.changeAppMode = this.changeAppMode.bind(this);
        this.toString = this.toString.bind(this);
        this.isHidden = this.isHidden.bind(this);
    }

    changeAppMode(event) {
        console.log('value', event.target.value);
        this.setState({ value: parseInt(event.target.value, 10) });
        //alert('this.props.value',this.state.value);
        /*
        */
    }

    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            toString += list[index].Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    /**
    * 
    * @param {*} date 
    */
    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    isHidden() {
        let statoCaratterizzazione = this.props.service.StatoCaratterizzazione;
        if (statoCaratterizzazione.toLocaleUpperCase().indexOf("DA_VALUTARE") === 0)
            return { eye: 'hidden', pencil: 'compila' };
        else return { eye: 'visible', pencil: 'modifica' };
    }

    /**
             * 
             * @param {*} index 
             * @param {*} array 
             */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {

        /*
        let riservatezza = this.props.service.ImpattoR < 1 ? '' : LIVELLO[this.props.service.ImpattoR].name;
        let integrità = this.props.service.ImpattoI < 1 ? '' : LIVELLO[this.props.service.ImpattoI].name;
        let disponibilità = this.props.service.ImpattoD < 1 ? '' : LIVELLO[this.props.service.ImpattoD].name;
        */
        let isHidden = this.isHidden().eye;
        let criticità = this.props.service.Criticità;
        //let iconSrc=ICON[this.props.service.Criticità].src;
        //let iconLabel =ICON[this.props.service.Criticità].label
        let iconSrc = this.getRisk(criticità, ICON).src;
        let iconLabel = this.getRisk(criticità, ICON).label;
        let isServiceChanged = (new Date(this.props.service.DataUpdateImpatto).getTime() >= new Date(this.props.service.DataCompilazioneImpatto).getTime());

        var tooltip = this.props.service.ImpattoR === -2 ? "COMPILA" : "MODIFICA";
        let profiloCriticità = <p><strong>Profilo di criticità del servizio</strong></p>;
        if (this.props.service.Criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità del servizio</strong>&nbsp;&nbsp;
                <img src={iconSrc} width="20" height="20" alt="" />

                <span className={getClassName(this.props.service.Criticità)}>
                    <strong>{" " + iconLabel}</strong></span>
            </p>;
        }
        return (
            this.props.riepilogo !== "true" ?
                <div>
                    <div className="row">
                        {/*1a colonna 'Aggiornato'*/}
                        <div className="col-lg-8 card-headerT">
                            <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                                {this.props.service.Nome}<i className="iconRight fa fa-chevron-down"></i>
                            </a>
                            &nbsp; &nbsp;<i hidden={isServiceChanged} className="fa fa-warning testoRosso"></i>
                        </div>
                        {/*2a colonna 'Aggiornato'*/}
                        <div className="col-lg-1 card-header">{this.toStringDate(this.props.service.DataUpdateImpatto).split(" ")[0]}</div>
                        {/*3a colonna 'R | I | D'*/}
                        {getRID(this.props.service.ImpattoR, this.props.service.ImpattoI, this.props.service.ImpattoD)}
                        {/*4a colonna 'Analisi'*/}
                        <div className="col-lg-1 card-header">{this.props.stato}</div>
                        {/*5a colonna 'Azioni'*/}
                        <div className="col-lg-1 card-header ">

                            <a style={{ visibility: isHidden }} onClick={() => redirect('ValutazioneImpattiVisualizza', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-eye" title="VEDI"></i>
                            </a>&nbsp;
                    <a onClick={() => redirect('ValutazioneImpattiModifica', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-pencil" title={tooltip}></i>
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                        <div className="row  card-headerLight">
                            <div className="col-lg-6"><br />
                                <strong>Referente</strong><br />
                                {(this.props.service.Referente) ? this.props.service.Referente : "-"}<hr /></div>
                            <div className="col-lg-6"><br />
                                <strong>Struttura</strong><br />
                                {(this.props.service.StrutturaReferente) ? this.props.service.StrutturaReferente : "-"}<hr />
                            </div>
                            <div className="col-lg-6">
                                <strong>Categorie</strong><br />
                                {(this.props.categoriesTostring) ? this.props.categoriesTostring : "-"}<hr />
                            </div>
                            <div className="col-lg-6">
                                <strong>Tipologie</strong><br />
                                {(this.props.typologiesTostring) ? this.props.typologiesTostring : "-"}<hr />
                            </div>
                            <div className="col-lg-12">
                                <p><strong>Descrizione</strong><br />
                                    {(this.props.service.Descrizione) ? this.props.service.Descrizione : "-"}</p><hr />
                            </div>
                            <div className="col-lg-12 ">
                                {profiloCriticità}
                                <hr />
                            </div>
                            <div className="col-lg-12 ">

                                {getRIDAccordion(this.props.service.ImpattoR, this.props.service.ImpattoI, this.props.service.ImpattoD)}
                                <hr />
                            </div>
                            <div className="col-lg-6">
                                <strong>Data Aggiornato Impatto R-I-D</strong><br />
                                {this.toStringDate(this.props.service.DataUpdateImpatto)}
                                <hr />
                            </div>
                            <div className="col-lg-6">
                                <strong>Data di ultima modifica dei dati</strong><br />
                                {this.toStringDate(this.props.service.DataCompilazioneImpatto)}
                                <hr />
                            </div>
                        </div>
                    </div>
                </div >
                :
                <div>
                    <div className="row">
                        <div className="col-lg-9 card-headerT">
                            <a onClick={() => redirect('ValutazioneImpattiModifica', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                {this.props.service.Nome}</a></div>


                        <div className="col-lg-1 card-header">{this.toStringDate(this.props.service.DataUpdateImpatto).split(" ")[0]}</div>

                        {getRID(this.props.service.ImpattoR, this.props.service.ImpattoI, this.props.service.ImpattoD)}

                        <div className="col-lg-1 card-header ">
                            <a onClick={() => redirect('ValutazioneImpattiModifica', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-eye" title="VEDI"></i>
                            </a>
                        </div>
                    </div>
                </div>
        );
    }
};