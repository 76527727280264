import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import ApiCalls from '../utils/apiCalls';
/**
 * 
 * @param {*} array 
 */
function toString(array) {
    let toString = '';
    try {
        for (let index = 0; index < array.length; index++) {
            const element = array[index].Nome;
            toString += element + ' | ';
        }
        return toString.substring(0, toString.length - 2);
    } catch (error) {
        console.error(error);
        return null
    }
}

const LINK = [
    { nome: "Registro Applicazioni", url: "/RegistroApplicazioniElenco" },
    { nome: "Modifica Registro", url: "/RegistroApplicazioniModifica" }
];

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}


export class RegistroApplicazioniModifica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            referente: '',
            struttura: '',
            serviceId: window.sessionStorage.getItem('serviceId'),
            serviceName: '',
            descrizione: '',
            categorieToString: '',
            tipologieToString: '',
            messageWarning: '',
            messageError: '',
            updateDate: false,
            dataAggiornamento: '',
            dataCalcoloCriticità: '',
            datiAsset:[],
        }

        this.update = this.update.bind(this);
        this.save = this.save.bind(this);

    }

    componentDidMount() {
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getRegistroApplicazione,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ ServizioId: this.state.serviceId }),
                cache: false,
                success: function (response) {

                    console.log(response.data);
                    this.setState({ data: response.data });
                   
                   

                    $.ajax({
                        url: API_CONFIG.servizicompositeByIdUrl,
                        type: "POST",
                        contentType: 'application/json',
                        headers: { 'token': sessionStorage.getItem('token') },
                        data: JSON.stringify({ ServizioId: this.state.serviceId }),//data:"{ \"ServizioId\": \"S2\"}",
                        cache: false,
                        success: function (response) {
                            this.setState({ serviceName: response.data[0].Nome });
                            this.setState({ descrizione: response.data[0].Descrizione });

                            var applicazione = this.state.data;
                            var indexNome = applicazione.RegistroApplicazione.findIndex(r=> r.Nome.indexOf('Nome') > -1);
                           
                            applicazione.RegistroApplicazione[indexNome].ValoreSelezionato = response.data[0].Nome;
        
                            var indexDesc = applicazione.RegistroApplicazione.findIndex(r=> r.Nome.indexOf('Descrizione') > -1);
                            applicazione.RegistroApplicazione[indexDesc].ValoreSelezionato = response.data[0].Descrizione;

                            console.log("registro", applicazione.RegistroApplicazione);
                            this.setState({ data: applicazione });

                            ApiCalls.getImportDatiByNomeAsset(response.data[0].Nome).
                            then(res => this.setState({datiAsset : res})).
                            catch(error =>{
                                messageError = error;
                                $('#btnError').get(0).click();
                                return console.error(error);
                            });
                        }.bind(this),
        
                        error: function (xhr, resp, text) {
                            let messageError = "Si è verificato un errore ";
                            console.error(xhr);
                            //Token JWT Invalid
                            if (xhr.responseJSON) {
                                if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                                    sessionStorage.removeItem("userInfo");
                                    sessionStorage.removeItem("lastActivityDate");
                                    location.replace(API_CONFIG.HomePath);
                                } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                            }
                            else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                        }.bind(this),
                    });
                    
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("userInfo");
                            sessionStorage.removeItem("lastActivityDate");
                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());

                }.bind(this),
            });

            
            
    }

    componentWillUnmount() {
       
    }

    update(id, valore) {
       
        var _data = this.state.data;

        for (let i = 0; i < _data.RegistroApplicazione.length; i++) {
            if (_data.RegistroApplicazione[i].RegistroId === id) {
                _data.RegistroApplicazione[i].ValoreSelezionato = valore;
                break;
            }
        }

        console.log("_data", _data);
       
        this.setState({ data: _data });
    }

    

    /**
    * handle save button clicked
    */
    save(e) {
        $.ajax({
            url: API_CONFIG.saveRegistroApplicazione,
            type: "POST",
            contentType: 'application/json',
            headers: {'token': sessionStorage.getItem('token') },
            data: JSON.stringify(this.state.data),
            cache: false,
            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response) {
                redirect("/RegistroApplicazioniElenco");
            },
            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");
                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data },
                            () => $('#btnError').get(0).click()
                        );
                    }
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });       
    }   

    render() {

        var rows = '';
        if (!this.state.data.RegistroApplicazione) {
            rows = <div className="row">
                 <div className="col-lg-12">Loading...</div></div>
        } else {
           
            rows = [];

            for (let x = 0; x < this.state.data.RegistroApplicazione.length; x++) {

                let item = this.state.data.RegistroApplicazione[x];

                if(item.Tipo === 'Menu')
                {
                    let options = [];
                    for (let y = 0; y < item.Valori.length; y++) {
                        options.push( <option  key={y + "o" + x} value={item.Valori[y]}>{item.Valori[y]}</option>);
                    }

                    let valore = item.ValoreSelezionato === null ? '-1' : item.ValoreSelezionato;

                    rows.push(
                        <div className="row" key={"ra" + x}>
                                <div className="col-lg-4">
                                <br/>
                                {item.Nome}
                                </div>
                                <div className="col-lg-3">
                                <br/>
                                <select style={{width:"100%"}} 
                                value={valore}
                                onChange={(e) => this.update(item.RegistroId, e.target.value)}>
                                
                                    <option value='-1'></option>
                                    {options}
                                </select>
                                </div>
                        </div>
                    );
                }
                else if(item.Tipo === 'Intervallo')
                {
                    let min = parseInt(item.Valori[0], 10);
                    let max = parseInt(item.Valori[1], 10);
                    let valore = item.ValoreSelezionato === null ? '' : item.ValoreSelezionato;
                    rows.push(
                        <div className="row"  key={"ra" + x}>
                                <div className="col-lg-4">
                                <br/>
                                {item.Nome}
                                </div>
                                <div className="col-lg-3">
                                <br/>
                                    <input type="number" min={min} max={max} style={{padding: 0, border: "1px solid #aaa", backgroundColor: "white", fontSize: "18px", width:"100%"}}
                                    onChange={(e) => this.update(item.RegistroId, e.target.value)} value={valore} ></input>
                                </div>
                        </div>
                    );
                }
                else
                {
                    let valore = '';
                    let disabled = false;
                    //if(item.ValoreSelezionato === null)
                    //{
                        if(item.Nome.indexOf('Nome') > -1)
                        {
                            //valore = this.state.serviceName;
                            disabled = true;
                        }
                        else if(item.Nome.indexOf('Descrizione') > -1)
                        {
                            //valore = this.state.descrizione;
                            disabled = true;
                        }                    
                        
                    valore = item.ValoreSelezionato !== null ? item.ValoreSelezionato : '';
                        

                    rows.push(
                        <div className="row" key={"ra" + x}>
                                <div className="col-lg-4">
                                    <br/>
                                {item.Nome}
                                </div>
                                <div className="col-lg-3">
                                <br/>
                                    <input disabled={disabled} type="text" style={{padding: 0, border: "1px solid #aaa", backgroundColor: "white", fontSize: "18px"}} value={valore} 
                                    onChange={(e) => this.update(item.RegistroId, e.target.value)}></input>
                                </div>                                
                        </div>
                    );
                }

            }

        }

        let modalSuccess = <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Analisi del Rischio creata!'} />;
        let modalNoAction = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={this.state.messageWarning} />;
        let modalAction = <ModalDialog idTarget={'warningAction'} idBtn={'btnCheckImpatto'} handle={this.save} textModal={this.state.messageWarning} />;
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Registro Applicazione: {this.state.serviceName} </span>&nbsp;

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 giustifica">
                            <p>Elenco delle caratteristiche di sicurezza che l’utente è chiamato a compilare. <br />
                               </p>
                        </div>
                        <div className="col-lg-2">
                                   
                                    <a
                                    hidden={this.state.datiAsset.filter(da => da.Tipo=='Report DPIA').length==0}
                                    download={this.state.datiAsset.filter(da => da.Tipo=='Report DPIA').length > 0 ? 
                                        this.state.datiAsset.filter(da => da.Tipo=='Report DPIA')[0].NomeAsset+'.pdf' : ''}
                                    href={this.state.datiAsset.filter(da => da.Tipo=='Report DPIA').length > 0 ? 
                                        "data:application/pdf;base64,"+this.state.datiAsset.filter(da => da.Tipo=='Report DPIA')[0].Dati : '#'}
                                    className="btn btn-successOR btn-lg w-100">Report DPIA</a>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container">

                        {rows}
                        <br/>
                        <br/>
                        <br/>

                        <div className="row spazio20">

                        <div className="col-lg-5 ">	</div>
                        <div className="col-lg-2 ">
                            <button onClick={() => this.save()} className="btn btn-success btn-lg btn-block">Salva</button>
                        </div>
                        <div className="col-lg-5 ">	</div>
                        </div>
                    </div>
                </div>
                <br />
                {/*idTarget = type modal */}
                {modalSuccess}
                {modalAction}
                {modalNoAction}
                {modalError}
            </div>
        )
    }
}