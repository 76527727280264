import * as React from 'react';
import Months from '../data/Months';
import LIVELLO from "./criticality";

export var Util = new function () {
    this.type = "IPhone SE";
    this.color = "Black";
    this.getInfo = function () {
        return this.color + ' ' + this.type + ' apple';
    };

    /**
    * 
    */
    this.getUsersFromAccessToken = function () {
        try {
            const accessToken = sessionStorage.getItem('userInfo');
            if (accessToken) {
                return JSON.parse(accessToken).user;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
    * 
    */
    this.toStringList = function (list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
    * 
    * @param {*} date : prende in input una stringa in formata data "2018-06-19T14:15:24.808Z" e restituisce un array con 4 stringhe
       "day" <= 19, "monthStr" <= "Giugno", "month" = "06", "year" <= "2018";
       dateArray["month"]
    */
    this.toStringDate = function (date) {
        /*
        if (isNullOrUndefined(date)) {
            return "--/--/----";
        }*/
        let dateArray = {
            "day": "--",
            "monthStr": "--",
            "month": "--",
            "year": "----",
            "hours": "--",
            "minutes": "--",
            "seconds": "--"
        };
        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let day = dateParse.getDate();
                dateArray.day = (day > 9) ? day : "0" + day;
                dateArray.monthStr = Months[dateParse.getMonth()];
                let month = dateParse.getMonth() + 1;
                dateArray.month = (month > 9) ? month : "0" + month;
                dateArray.year = dateParse.getFullYear();
                dateArray.hours = (dateParse.getHours() > 9) ? dateParse.getHours() : "0" + dateParse.getHours();
                dateArray.minutes = (dateParse.getMinutes() > 9) ? dateParse.getMinutes() : "0" + dateParse.getMinutes();
                dateArray.seconds = (dateParse.getSeconds() > 9) ? dateParse.getSeconds() : "0" + dateParse.getSeconds();
                return dateArray;
            }
            else return dateArray;
        } catch (error) {
            console.error(error);
            return dateArray;
        }
    }

    /**
     * @param {*} lista dei servizi da modificare
     * - Modifica la lista in input nel caso  di: 
     * - Analisi del contesto gli stati “incompleto” e “compilare” collassano nello stato “incompleto”
     * - Valutazione impatti gli stati “incompleto” e “da valutare” collassano nello stato “incompleto”
     * - Analisi del rischio gli stati “incompleto” e “da compilare” collassano nello stato “incompleto”
     */
    this.getServices = function (array, context) {
        let servicesList = []
        try {

            for (let index = 0; index < array.length; index++) {
                switch (context.toUpperCase()) {
                    case "CARATTERIZZARE":
                        if (array[index].StatoCaratterizzazione.toUpperCase().indexOf(context.toUpperCase()) > 0) {
                            array[index].StatoCaratterizzazione = "PARZIALMENTE_CARATTERIZZATO".toUpperCase();
                        }

                        break;
                    case "VALUTARE":
                        if (array[index].StatoCaratterizzazione.toUpperCase().indexOf(context.toUpperCase()) > 0) {
                            array[index].StatoCaratterizzazione = "PARZIALMENTE_VALUTATO".toUpperCase();
                        }

                        break;
                    case "COMPILARE":
                        if (array[index].StatoAssessmentId === 1) {
                            array[index].StatoAssessmentId = "2";
                        }
                    default:
                        break;
                }
                servicesList.push(array[index]);
            }
            return servicesList;
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    this.dateParseGetMonth = function (date) {
        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                return dateParse.getMonth() + 1;
            }
            else return "";
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    /**
     * 
     */
    this.dateParseGetYear = function (date) {
        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                return dateParse.getFullYear();
            }
            else return "";
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    /**
    * 
    */
    this.toStringInfoAggiuntive = function (infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
        return toString;
    }

    /**
    * 
    */
    this.toStringCriticità = function (criticità) {
        let livello = [];
        try {
            return [
                (criticità > 0) && (criticità < LIVELLO.length) ? LIVELLO[criticità].name.toUpperCase() : "",
                (criticità > 0) && (criticità < LIVELLO.length) ? LIVELLO[criticità].text : ""
            ];


        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }
    /**
       * 
       * @param {*} index 
       * @param {*} array 
       */
    this.getRisk = function (index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let className = ((index) && (index >= 0) && (index < array.length)) ? array[index].text : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                className: className,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }
}
//module.exports = apple