import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import { isNullOrUndefined } from 'util';

import bassoIcon from "../../public/images/basso.png";
import medioIcon from "../../public/images/medio.png";
import altoIcon from "../../public/images/alto.png";
import criticoIcon from "../../public/images/critico.png";


// component that renders a single this.props.minaccia row
export class MinacciaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emptyTest: '',
            isDisabledGradoImplementatione: false,
            isDisabledDataChiusura: false,
            //caratteristiche: this.props.caratteristiche
        };
        this.updateRischioDerivatoList = this.updateRischioDerivatoList.bind(this);
    }

    /**
     * 
     */
    getClassName(index) {
        let className = 'col-lg-1';
        let indexInt = parseInt(index, 10);
        switch (indexInt) {
            case 1:
                className = "col-lg-1 bgVerde";
                break;
            case 2:
                className = "col-lg-1 bgArancio";
                break;
            case 3:
                className = "col-lg-1 bgRosso";
                break;
            case 4:
                className = "col-lg-1 bgNero";
                break;
            default:
                className = "col-lg-2";
                break;
        }
        return className;
    }

    /**
     * 
     */
    getSrcImgIcon(maxRisk) {
        let pathSrc = '';
        let maxRiskInt = parseInt(maxRisk, 10);
        switch (maxRiskInt) {
            case 1:
                pathSrc = bassoIcon
                break;
            case 2:
                pathSrc = medioIcon
                break;
            case 3:
                pathSrc = altoIcon
                break;
            case 4:
                pathSrc = criticoIcon
                break;
            default:
                //pathSrc = "col-lg-1";
                break;
        }
        return pathSrc;
    }

    /**
        * 
        * @param {*} index 
        */
    toStringItem(index) {
        //let indexInt = parseInt(index, 10);
        try {
            return (!isNullOrUndefined(index) && (index > 0) && (index < IconImpatti.length)) ? IconImpatti[index].label.toUpperCase() : "";

        } catch (error) {
            console.error(error);
            return "";
        }
    }

    /**
     * 
     */
    toStringOpzione(index) {
        let name = '';
        let opzione = parseInt(index, 10);
        switch (opzione) {
            case 1:
                name = "Mitigare";
                break;
            case 2:
                name = "Accettare";
                break;
            case 3:
                name = "Trasferire";
                break;
            default://
                break;
        }
        return name.toUpperCase();
    }

    /**
     * 
     */
    updateRischioDerivatoList() {
        this.props.updateListAssessmentRischioDerivato(this.props.minaccia.ListaAssessmentRischioDerivato);
        //console.log("this.props.minaccia.NomeMinaccia", this.props.minaccia.NomeMinaccia);
    }
    render() {
        let isCursorPointer = (this.toStringItem(this.props.minaccia.RischioComplessivo) !== "") ? "pointer" : "";
        var rowColor = (this.props.index) % 2 === 0 ? "#f5f5f5" : "";
        return (

            <tr key={'sidebar' + this.props.index}>
                <td className="">{this.props.minaccia.NomeMinaccia}</td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.RID)}>{this.toStringItem(this.props.minaccia.RID)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.Vulnerabilità)}>{this.toStringItem(this.props.minaccia.Vulnerabilità)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.TotaleLivelloEsposizione)}>{this.toStringItem(this.props.minaccia.TotaleLivelloEsposizione)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.Probabilità)}>{this.toStringItem(this.props.minaccia.Probabilità)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.RischioAttuale)}>{this.toStringItem(this.props.minaccia.RischioAttuale)} </span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(this.props.minaccia.RiskAppetite)}>{this.toStringItem(this.props.minaccia.RiskAppetite)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span className={this.getClassName(-1)}>{this.toStringOpzione(this.props.minaccia.OpzioneTrattamento)}</span>
                </td>
                <td style={{ backgroundColor: rowColor, textAlign: "center" }}>
                    <span
                        //className="testoArancio font16 under"
                        className={this.getClassName(this.props.minaccia.RischioComplessivo) + " under"}
                        style={{ cursor: isCursorPointer }}>
                        <a data-bs-toggle="modal"
                            onClick={this.updateRischioDerivatoList}
                            data-bs-target="#sidebar-left">{this.toStringItem(this.props.minaccia.RischioComplessivo)}</a>
                    </span>
                </td>
            </tr>
        )
    }
}