import React from 'react';
import { ReportStampaRow } from './ReportStampaRow';
import GRADE from '../data/GradoDiImplementazione';
export class ReportStampa extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.getRischio = this.getRischio.bind(this);
        this.getIUC = this.getIUC.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.getSumOfAnswersProvided = this.getSumOfAnswersProvided.bind(this);
        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.getGradoImplementazioneDomini = this.getGradoImplementazioneDomini.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.distribuzioneTrattamento !== undefined) {
            if (props.PA === "true") {
                var rischio = props.rischi.RischioAttuale / 4;
                if (isNaN(rischio) || rischio === 0) {
                    rischio = 0.01;
                }
                window.setGauge4liv("gauge4livPrint", rischio);
            }

            let sum = 0;
            let distribuzioneAssessment = props.distribuzioneAssessment;

            for (const key in distribuzioneAssessment) {
                if (distribuzioneAssessment.hasOwnProperty(key)) {
                    if (key !== 'Totale' && key !== 'TotaleNonRisposto') {
                        const element = parseInt(distribuzioneAssessment[key], 10);
                        //console.log('key:',key,' value:',element);
                        sum += element;
                    }
                }
            }

            var perc = sum / props.distribuzioneAssessment.Totale;
            if (isNaN(perc)) { perc = 0; };

            //window.setGaugePrint(perc === 1 ? 0.99 : perc === 0 ? 0.01 : perc);
        }

        return null;
    }

    componentDidMount() {

        try {


        } catch (error) {
            console.error("ERROR STAMPA", error);
        }
    }

    getSumOfAnswersProvided() {
        let sum = 0;
        let distribuzioneAssessment = this.props.distribuzioneAssessment;
        try {
            for (const key in distribuzioneAssessment) {
                if (distribuzioneAssessment.hasOwnProperty(key)) {
                    if (key !== 'Totale' && key !== 'TotaleNonRisposto') {
                        const element = parseInt(distribuzioneAssessment[key], 10);
                        //console.log('key:',key,' value:',element);
                        sum += element;
                    }
                }
            }
            return sum;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    calculatePercentage(totale) {
        try {

            return Math.round((totale / this.props.distribuzioneAssessment.Totale) * 100);

        } catch (error) {
            console.error(error);
            return null
        }

    }

    getAccessToken() {
        return this.props.auth.getAccessToken();
    }

    getRischio() {
        var rischio = this.props.rischi.RischioAttuale;
        var res = {};
        switch (rischio) {
            case 1:
                res.name = "BASSO";
                res.class = "testoVerde";
                break;
            case 2:
                res.name = "MEDIO";
                res.class = "testoArancio";
                break;
            case 3:
                res.name = "ALTO";
                res.class = "testoRosso";
                break;
            case 4:
                res.name = "CRITICO";
                res.class = "testoNero";
                break;

            default:
                res.name = "";
                res.class = "";
                break;
        }

        return res;
    }

    getIUC() {
        var data = {
            labels: [
                "IUC: 0%",
                "no"
            ],

            datasets: [
                {
                    data: [100, 0],
                    backgroundColor: [
                        "#CCCCCC",
                    ]
                }]
        };
        
        if (this.state.rischi !== undefined) {

            if (this.state.rischi.RischioAggregato !== 0) {
                data = {
                    labels: [
                        "IUC: " + this.state.rischi.RischioAggregato +"%",
                        "no"
                    ],

                    datasets: [
                        {
                            data: [this.state.rischi.RischioAggregato, 100-this.state.rischi.RischioAggregato],
                            backgroundColor: [
                                "#28a745",
                                "#CC0000"
                            ]
                        }]
                };
            }
        }
        return data;
    }

    getGradoImplementazioneDomini() {
        let rows = [];
        for (let index = 0; index < this.props.distribuzioneDominio.length; index++) {
            for (let y = 0; y < this.props.distribuzioneDominio[index].length; y++) {
                let element = this.props.distribuzioneDominio[index][y];

                let row = <tr key={index + 'gdiDominio' + y}>
                    <td style={{ width: "40%" }}>{element.Nome}</td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                        <span >{element.TotaleRisposto}</span>
                    </td>
                    <td style={{ textAlign: "center", width: "15%" }}>
                        <span >{(element.Implementazione) ? GRADE[Math.round(element.Implementazione)] : GRADE[-1]}
                        </span>
                    </td>
                    <td style={{ textAlign: "center", width: "15%" }}>
                        <span >{(element.Vulnerabilità) ? GRADE[element.Vulnerabilità] : GRADE[-1]}
                        </span>
                    </td>
                </tr>;

                rows.push(row);
            }
        }
        var res = <table style={{ width: "100%" }}>
            <tbody>
                <tr >
                    <td style={{ width: "40%" }}><span className=" font16 "><strong>Dominio</strong></span></td>
                    <td style={{ textAlign: "center", width: "10%" }} ><span className=" font16"><strong>Misure</strong></span></td>
                    <td style={{ textAlign: "center", width: "15%" }} ><strong>Implementazione</strong></td>
                    <td style={{ textAlign: "center", width: "15%" }} ><strong>Vulnerabilità</strong></td>
                </tr>
                {rows}
            </tbody>
        </table>;
        return res;
    }

    getClassName(index) {
        let className = 'h2';
        let indexInt = parseInt(index, 10);
        switch (indexInt) {
            case 1:
                className = "testoVerde h2";
                break;
            case 2:
                className = "testoArancio h2";
                break;
            case 3:
                className = "testoRosso h2";
                break;
            case 4:
                className = "testoNero h2";
                break;
            default:
                className = "h2";
                break;
        }
        return className;
    }

    getStyleColor(index) {
        let color = "";
        let indexInt = parseInt(index, 10);
        switch (indexInt) {
            case 1://green
                color = "#28a745";
                break;
            case 2://orange
                color = "#FF7E00";
                break;
            case 3://red
                color = "#cc0000";
                break;
            case 4://black
                color = "#212529";
                break;
            default:
                //color = "col-lg-2";
                break;
        }
        return color;
    }

    getConformità(gdi) {
        var res = 0;
        try {
            switch (gdi) {
                case 1:
                    res = 1;
                    break;
                case 2:
                    res = 3;
                    break;
                case 3:
                    res = 3;
                    break;
                case 4:
                    res = 2;
                    break;
                case 5:
                    res = 1;
                    break;
                case 6:
                    res = 1;
                    break;

                default:
                    res = 0;
                    break;
            }

        } catch (error) {
            console.error(error);
            res= 0;
        }
        return res;
    }

    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
            if(infoAggiuntive != null && infoAggiuntive != undefined)
            {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
    }
        return toString;
    }

    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '-' + monthStr + '-' + year;
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    render() {

        let percentage = this.calculatePercentage(this.getSumOfAnswersProvided());

        let remediationRows = [];
        let arrayRemediation = this.props.listaRemediation;
        let tot = arrayRemediation.length;
        for (let index = 0; index < arrayRemediation.length; index++) {
            if (!arrayRemediation[index]) {
                remediationRows[index] = <div className="col-lg-4">Loading...</div>
            } else if(arrayRemediation[index].GradoDiImplementazione > 1 && arrayRemediation[index].GradoDiImplementazione < 6){
                
                var rilevanza = arrayRemediation[index].Rilevanza;
                var conformità = this.getConformità(arrayRemediation[index].GradoDiImplementazione);
                var id = arrayRemediation[index].AssessmentAzioniDiTrattamentoId;
                var testoRilevanza = rilevanza ==1 ? "BASSA" : rilevanza == 2  ? "MEDIA" : "ALTA";
                var testoConformità = conformità ==1 ? "ALTA" : conformità == 2  ? "MEDIA" : "BASSA";
                
                // remediationRows.push(<div className="row" key={id + "remediation1" + (index)}>

                // <div className="col-lg-2">Requisito N° {index+1}</div>
                // <div className="col-lg-2">
                //     <strong>Rilevanza </strong>
                //      <span className={this.getClassName(arrayRemediation[index].Rilevanza)}>
                //          &nbsp;&nbsp;{testoRilevanza}</span>
                // </div>
                // <div className="col-lg-2">
                //     <strong>Conformità </strong>
                //         <span className={this.getClassName(conformità)}>
                //             &nbsp;&nbsp;{testoConformità}</span>
                //     </div>
                // <div className="col-lg-6"></div>
                // <div className="col-lg-12"><hr/></div>
                // <div className="col-lg-12">
                //     <p style={{color: '#0066cc'}}>
                //         {arrayRemediation[index].SottoCategoria}
                //         <br />
                //     </p>
                // </div>
                // <div className="col-lg-12">
                //     <p>
                //         {this.toStringInfoAggiuntive(arrayRemediation[index].Azione)}
                //     </p>
                // </div>
                // <div className="col-lg-12"><br></br></div>                
                // </div>);
                remediationRows.push(
                <table key={id + "remediation1" + (index)} style={{ width: "100%",fontSize: "16px", fontFamily: "Calibri", textAlign:"justify" }}>
                    <tbody>
                        <tr >
                            <td style={{ width: "20%" }}>
                                <span style={{color:"#0066cc"}}><strong>Requisito N° {index+1}/{tot}</strong></span></td>
                            <td style={{ textAlign: "left", width: "25%" }} >
                                <strong>Rilevanza </strong>
                                <span style={{color: this.getStyleColor(arrayRemediation[index].Rilevanza)}}>
                                &nbsp;&nbsp;{testoRilevanza}</span></td>
                            <td style={{ textAlign: "left", width: "25%" }} >
                            <strong>Conformità </strong>
                                  <span style={{color: this.getStyleColor(conformità)}}>
                                     &nbsp;&nbsp;{testoConformità}</span>
                                </td>
                            <td style={{ textAlign: "center", width: "30%" }} ></td>
                        </tr>   
                        <tr>
                            <td style={{ width: "100%" }} colSpan={4} ><hr/></td>
                        </tr>
                        <tr>
                            <td style={{ width: "100%" }} colSpan={4} >
                            <p style={{color: '#0066cc', fontStyle: 'italic'}}>
                                {arrayRemediation[index].SottoCategoria}
                            <br />
                         </p>
                            </td>
                        </tr> 
                        <tr>
                            <td style={{ width: "100%" }} colSpan={4} >
                            <p style={{textAlign: 'justify'}}>
                                    {this.toStringInfoAggiuntive(arrayRemediation[index].Azione)}
                                 </p>
                            </td>
                        </tr> 
                        <tr>
                            <td style={{ width: "100%" }} colSpan={4} >
                            <p style={{color: '#0066cc', fontStyle: 'italic'}}>
                                Riferimento Normativo
                            <br />
                         </p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: "100%" }} colSpan={4} >
                            <p style={{textAlign: 'justify'}}>
                                    {this.toStringInfoAggiuntive(arrayRemediation[index].RifNormativo)}
                                    <br />
                                 </p>
                            </td>
                        </tr>                      
                    </tbody>
                </table>);
                 remediationRows.push(<div key={id + "remediation2" + (index)} style={{ paddingTop: "20px" }}></div>);
            }
        }



        var titolo = sessionStorage.getItem("serviceName");
        

        var currentDate = this.toStringDate(new Date());

        var contestoNormativo = this.props.contestoNormativo;
        var norme = [];
        var standard = [];
        for (let i = 0; i < contestoNormativo.length; i++) {
            var item = contestoNormativo[i];
            for (let j = 0; j < item.RiferimentoNormativo.length; j++) {
                var subItem = item.RiferimentoNormativo[j];
                if(item.ContestoComplianceNome.startsWith("Best"))
                {   
                    standard.push(<li key={i + "s" + j}>{subItem.Nome}<br/></li>);
                }
                else if(item.ContestoComplianceNome.startsWith("Domini"))
                {
                    norme.push(<li key={i + "n" + j}>{subItem.Nome}<br/></li>);
                }
            }
        }

        //console.log("norme", norme.length);
        //console.log("standard", standard.length);

        return (


            <div id="main" style={{ fontFamily: 'Titillium Web, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif' }}>

                <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                    <div className="container cnsContainer">


                        <div className="row">
                            <div style={{ fontSize: "32px", fontFamily: "Cambria", width : "100%", height : "100%", textAlign:"center" }}>
                                    <div style={{margin: "0",fontSize: "20px", position: "absolute", top: "1%", left:"23%", width:"55%",textAlign:"center"}}>
                                    {titolo}
                                    </div>
                                    <div style={{margin: "0", position: "absolute", top: "40%", left:"23%", width:"55%",textAlign:"center"}}>
                                            Analisi degli Scostamenti<br></br>
                                            e<br></br>
                                            Relativo Piano di Rientro
                                    </div>
                                    <div style={{margin: "0",fontSize: "16px", position: "absolute", bottom: "1%", left:"22%", width:"55%",textAlign:"center"}}>
                                            {currentDate}
                                    </div>
                            </div>
                        </div>                        
                        <div style={{ width : "100%", height : "100%", marginTop:"15px", fontFamily: "Calibri",fontSize: "18px", }}>
                        <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>SOMMARIO</span><br/><br/>
                        {/* <ol style={{listStyleType:"none", paddingLeft: '40px'}}>
                            <li style={{marginTop:"15px",marginLeft:"0px"}}>1 Valutazione e analisi dello scostamento (Gap analysis)</li>
                            <li style={{marginTop:"15px"}}>2 Mappatura delle attività di trattamento dei dati personali</li>
                            <li style={{marginTop:"15px"}}>3 Descrizione del Report e modalità di utilizzo</li>
                            <li style={{marginTop:"15px"}}>4 Metodologia di valutazione della conformità</li>
                            <li style={{marginTop:"15px"}}>5 Struttura del documento</li>
                            <li style={{marginTop:"15px"}}>6 Risultato dell'analisi</li>
                            <li style={{marginTop:"15px"}}>7 Piano di rientro</li>
                        </ol> */}
                        <span style={{marginTop:"15px"}}>1 &nbsp;&nbsp;&nbsp;Valutazione e analisi dello scostamento (Gap analysis)</span><br/><br/>
                            <span >2 &nbsp;&nbsp;&nbsp;Mappatura delle attività di trattamento dei dati personali</span><br/><br/>
                            <span >3 &nbsp;&nbsp;&nbsp;Descrizione del Report e modalità di utilizzo</span><br/><br/>
                            <span >4 &nbsp;&nbsp;&nbsp;Metodologia di valutazione della conformità</span><br/><br/>
                            <span >5 &nbsp;&nbsp;&nbsp;Struttura del documento</span><br/><br/>
                            <span >6 &nbsp;&nbsp;&nbsp;Risultato dell'analisi</span><br/><br/>
                            <span >7 &nbsp;&nbsp;&nbsp;Piano di rientro</span><br/><br/>
                        </div>
                        <div style={{ width : "100%", fontSize: "16px", fontFamily: "Calibri", textAlign:"justify", marginTop:"10px",  }}>
                           
                            <p>
                            <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>1 Valutazione e analisi dello scostamento (Gap analysis)</span><br/>
                            L’audit è un processo sistematico e documentato per ottenere evidenze e valutarle con oggettività, al fine
                            di stabilire in quale misura i criteri siano stati soddisfatti. L’esecuzione di audit è prescritta in vari articoli dal
                            GDPR, in quanto si richiede che venga valutata adeguatamente e regolarmente l’efficacia delle misure
                            tecniche ed organizzative adottate a tutela della sicurezza delle informazioni.<br/><br/>
                            Le evidenze raccolte consentiranno di determinare lo stato di conformità alla baseline normativa in materia
                            privacy, permettendo altresì di evidenziarne gli scostamenti e gli eventuali gap da colmare.
                            </p>
                            <p>
                            <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>2 Obiettivo del documento</span><br/>
                            Obiettivo del presente documento (di seguito, 'il documento') è quello di valutare lo stato &lt;&lt;AS-IS&gt;&gt; di
                            conformita dell'organizzazione, ovvero di fornire evidenza del livello di conformità rispetto ai requisiti
                            previsti dal Regolamento Europeo sulla protezione dei dati, nonché l'identificazione delle azioni correttive
                            necessarie a colmare le inadempienze riscontrate. Il documento si basa sulle informazioni raccolte,
                            attraverso il questionario di Self-Assessment, e successivamente elaborate sulla base di una baseline di
                            controlli derivante dall'analisi della normativa vigente (in primis il GDPR) e dalle principali best practices in
                            ambito sicurezza e privacy.<br/><br/>
                            L'attività di Gap Analysis ha avuto inizio in data  {this.toStringDate(this.props.rischi.DataCreazione)} e si è conclusa il {this.toStringDate(this.props.rischi.DataPubblicazione)}.
                            </p>
                            <p>
                            <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>3 Disposizioni normative di riferimento</span><br/>
                            Il documento è da intendersi come piano complessivo di rientro per colmare il gap normativo riscontrato,
                            ed è frutto dell’analisi ed interpretazione da parte della società Leonardo SpA sulla base di un complesso di
                            disposizioni normative tra cui il GDPR, avente come scopo, quello di individuare quei requisiti (di seguito, 'i
                            requisiti') che devono essere rispettati nel trattamento di dati personali e la cui implementazione risulta
                            parzialmente o totalmente inadeguata. I requisiti così individuati sono associati alle disposizioni delle
                            seguenti fonti normative:<br/>
                              <ul>   
                                  {norme}                 
                                {/* <li>D.lgs 101/2018<br/></li>
                                <li>GDPR 2016/679<br/></li>
                                <li>Linee guida gruppo di lavoro 'Art. 29'<br/></li>
                                <li>D.lgs 196/03 Codice della privacy<br/></li>
                                <li>Provvedimenti del Garante<br/></li>
                                <li>D. lgs 33/2013 Trasparenza<br/></li>
                                <li>D. lgs 82/2005 Codice Amministrazione Digitale e ss.mm.ii<br/></li>
                                <li>Misure Minime ICT per la PA<br/></li>
                                <li>'Art. 50 bis' del Codice Amministrazione Digitale e ss.mm.ii<br/></li>
                                <li>IRAM 1 & 2<br/></li>
                                <li>CCM 3.0.1<br/></li>
                                <li>PCI DSS<br/></li>
                                <li>AGID circolare n°5 del 30/11/2017 'All.B'<br/></li>
                                <li>AGID Linee Guida per il Disaster Recovery delle PA<br/></li>
                                <li>TIA 942 A</li> */}
                            </ul>                                                          
                                e sulle seguenti norme o standard ad adozione volontaria, la cui autorevolezza e attestazione internazionali
                                conferisce ulteriore valenza ai risultati:<br></br>
                                <ul>
                                    {standard}                                    
                                  {/* <li> ISO 27001: Sistemi di gestione della sicurezza delle informazioni<br/></li>
                                  <li> ISO 27002 Controlli di sicurezza per le informazioni<br/></li>
                                  <li> ISO 31000 Gestione del rischio<br/></li>
                                  <li> ISO 29151: Protezione dei dati personali<br/></li>
                                  <li> ISO IEC 27017 Controlli di sicurezza per i servizi cloud<br/></li> */}
                                </ul>
                                Le fonti, vengono riportate per ciascuno requisito, nel paragrafo denominato <strong>'Riferimento normativo'</strong>.
                            </p>
                        </div>

                        <div style={{ width : "100%",  fontSize: "16px", fontFamily: "Calibri", textAlign:"justify", marginTop:"20px",  }}>
                            <p>                               
                            <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>4 Approccio metodologico per la valutazione della conformità</span><br/>
                            La metodologia di valutazione che è stata seguita insiste sulla definizione del perimetro dell’audit stesso e si
                            sviluppa attraverso le seguenti fasi:<br/>
                            <ul>
                                <li>Assessment: raccolta delle informazioni del contesto applicativo attraverso il questionario di self-
                                    Assessment;</li>
                                <li>Gap Analysis: aggregazione delle informazioni raccolte ed elaborazione di quest’ultime sulla base di
                                    specifici controlli capaci di misurare il grado di adeguatezza e conformità rispetto al complesso di
                                    disposizioni normative di riferimento;</li>
                                <li>
                                    Remediation Plan: produzione del presente documento, riportante il risultato dell’analisi di
                                    conformità, la cui struttura viene in seguito illustrata.
                                </li>
                            </ul>
                            La fase di <strong>'Gap Analysis'</strong> si articola su due azioni: analisi e classificazione delle informazioni raccolte per
                            determinare quali misure implementa l'applicazione per garantire la riservatezza, l'integrità e la
                            disponibilità dei dati trattati; misura dello scostamento rispetto al complesso di disposizioni normative di
                            riferimento attraverso la determinazione dell'Indice Unico di conformità (di seguito <strong>IUC</strong>).<br/><br/>

                            Lo <strong>IUC</strong> descrive il grado di aderenza al complesso normativo di riferimento, ovvero all'insieme di controlli su
                            cui si basa l'analisi in oggetto. Tale indice viene, espresso in percentuale e rappresenta il livello generale di
                            conformità. Nella determinazione di tale valore, viene innanzitutto calcolato il valore dello scostamento
                            complessivo rilevato (di seguito <strong>GAP</strong>), ottenuto dalla sommatoria, per ciascun controllo, del prodotto tra il
                            grado di conformità e la rilevanza del controllo stesso, espressi entrambi nella matrice del cluster dei pesi
                            (vedi figura a seguire):
                            </p>
                            
                            <table style={{ width: "90%", textAlign: "center", fontSize: "11px",marginLeft: "5%", border:"1px solid black", borderSpacing:"0", cellSpacing: "0", borderCollapse:"collapse", fontWeight:"bold" }}>
                            <tbody>
                                    <tr> 
                                        <td colSpan="9" style={{backgroundColor:"#538DD7", color:"#FFF"}}>CLUSTER DEI PESI</td>                                        
                                    </tr>
                                    <tr>
                                        <td rowSpan="3" colSpan="3" style={{backgroundColor:"#538DD7"}}></td>
                                        <td colSpan="6" style={{backgroundColor:"#002060", color:"#FFF",border:"1px solid black",}}>GRADO DI CONFORMITA' AL CONTROLLO</td>
                                    </tr>
                                    <tr style={{backgroundColor:"#002060", color:"#FFF",border:"1px solid black",}}>    
                                                                             
                                        <td>Completo/SI</td>
                                        <td>Adeguato</td>
                                        <td>Migliorabile</td>
                                        <td>Assente/NO</td>
                                        <td>Non so rispondere</td>
                                        <td>Non applicabile</td>                                        
                                    </tr>
                                    <tr style={{backgroundColor:"#002060", color:"#FFF"}}>  
                                                                          
                                        <td  style={{border:"1px solid black"}}>0</td>
                                        <td  style={{border:"1px solid black"}}>1</td>
                                        <td  style={{border:"1px solid black"}}>2</td>
                                        <td  style={{border:"1px solid black"}}>3</td>
                                        <td  style={{border:"1px solid black"}}>3</td>
                                        <td  style={{border:"1px solid black"}}>0</td>                                        
                                    </tr>
                                    <tr>   
                                        <td rowSpan="3" style={{width:"14%", border:"1px solid black", backgroundColor:"#002060", color:"#FFF"}}>RILEVANZA DEL CONTROLLO</td>
                                        <td style={{width:"14%", border:"1px solid black", backgroundColor:"#002060", color:"#FFF"}}>Sanzione GDPR 4%</td>
                                        <td  style={{border:"1px solid black", color:"#FFF", backgroundColor:"#002060"}}>3</td>                               
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>3</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>6</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>9</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>9</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>                                        
                                    </tr>
                                    <tr>    
                                        <td style={{width:"14%", border:"1px solid black", backgroundColor:"#002060", color:"#FFF"}}>Sanzione GDPR 2%</td>
                                        <td  style={{border:"1px solid black", color:"#FFF", backgroundColor:"#002060"}}>2</td>                               
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>2</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>4</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>6</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>6</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>                                
                                    </tr>
                                    <tr>      
                                        <td style={{width:"14%", border:"1px solid black", color:"#FFF", backgroundColor:"#002060"}}>Misure Minime ICT PA e altre Normative</td>
                                        <td  style={{border:"1px solid black", color:"#FFF", backgroundColor:"#002060"}}>1</td>                               
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>1</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>2</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>3</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>3</td>
                                        <td style={{border:"1px solid black", color:"#cc0000"}}>0</td>                                        
                                    </tr>
                                    </tbody>
                            </table>
                            <table style={{width:"50%", marginLeft:"40%", fontSize: "11px", color:"#0066cc", marginTop:"5px"}}><tbody><tr><td>Figura 1 - matrice del cluster dei pesi</td></tr></tbody></table>
                            <p>
                               ovvero:<br></br>
                               
                               <p style={{height:"50px", lineHeight:"50px", marginLeft:"28%"}}>
                                    GAP=<span style={{position: "relative", fontSize: "2.5em", display: "inline-block", lineHeight: ".7em", verticalAlign:"middle"}}>&Sigma;</span>
                                    <span style={{position: "relative", fontSize: "0.7em", display: "inline-block", lineHeight: ".5em", verticalAlign:"sub"}}>controllo(i)&nbsp;&nbsp;</span>
                                    Grado di conformità X Rilevanza
                                </p>
                                <br/>
                                Da questo viene determinato l'indice di scostamento rilevato (di seguito ISR) che rappresenta il rapporto
                                tra il valore di GAP e il valore di scostamento massimo (SM), valore di soglia massima del GAP ottenibile in
                                caso di totale assensa di conformità<br/>
                                <span style={{marginLeft:"45%"}}><strong>ISR = GAP / SM</strong></span><br/>
                                La misura espressa dall'indice IUC viene calcolata come valore complementare dell'indice di scostamento
                                rilevato<br/>
                                <span style={{marginLeft:"47%"}}><strong>IUC = 1 - ISR</strong></span>
                            </p>
                            
                        </div>
                        <div style={{ width : "100%",  fontSize: "16px", fontFamily: "Calibri", textAlign:"justify", marginTop:"10px",  }}>
                           
                           <p>
                           <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>5 Struttura del documento</span><br/>
                           Il presente documento pone in evidenza il grado complessivo di conformità rilevato ed elenca i requisiti
                            individuati partendo da quelli con livello di non conformità e rilevanza più elevato. In dettaglio, segue la
                            descrizione delle parti che costituiscono il documento stesso:<br/>
                           
                           <ul>
                               <li>l’<strong>Indice Unico di Conformità</strong> (di seguito, <strong>'IUC'</strong>) determinato come sopra descritto.</li>
                               <li>per ciascun requisito:<br/>
                                    <ul>
                                        <li>il progressivo numerico, indicato nel documento come <strong>'Requisito N°'</strong>;</li>
                                        <li>l’indice di rilevanza (di seguito, <strong>'IR'</strong>), indicato come <strong>'Rilevanza'</strong> nel documento, ovvero esprime
                                            l'importanza del requisito stesso rispetto alla totalità dei requisiti previsti sulla base della
                                            sanzione che la normativa prevede per gli articoli di legge ad esso associati. Tale indice può
                                            assumere i valori <strong style={{color:"#28a745"}}>'BASSA'</strong>, <strong style={{color:"#FF7E00"}}>'MEDIA'</strong> e <strong style={{color:"#cc0000"}}>'ALTA'</strong> (vedi figura seguente);
                                            <br></br><br></br>
                                            <table border="1" style={{ width: "45%", textAlign: "center", fontSize: "11px",marginLeft: "5%", borderCollapse:"collapse", fontWeight:"bold", marginLeft:"27%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={3} align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>RILEVANZA DEL CONTROLLO</td>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>Sanzione GDPR 4%</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#cc0000'}}>ALTA</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>Sanzione GDPR 2%</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#FF7E00'}}>MEDIA</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>Misure Minime ICT PA e altre Normative</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#28a745'}}>BASSA</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br></br>
                                            </li>
                                        
                                        <li>il livello di conformità, per ciascun requisito è indicato come 'Conformità' nel documento. Tale
                                            livello è determinato sulla base della risposta fornita al controllo, come descritto nella figura
                                            seguente;
                                            <br></br>
                                            <br></br>
                                            <table border="1" style={{ width: "50%", textAlign: "center", fontSize: "11px",marginLeft: "5%", borderCollapse:"collapse", fontWeight:"bold", marginLeft:"25%" }}>
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>
                                                            RISPOSTA
                                                        </td>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#002060', color:'#FFF'}}>
                                                            LIVELLO DI CONFORMITA' DEL REQUISITO
                                                        </td>
                                                    </tr>
                                                    <tr>                                                        
                                                        <td align="center" valign="middle" >Non so rispondere</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#cc0000'}}>BASSO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Assente/NO</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#cc0000'}}>BASSO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Migliorabile</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#FF7E00'}}>MEDIO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Adeguato</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#28a745'}}>ALTO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Completo</td>
                                                        <td align="center" valign="middle"><strong style={{color:'#28a745'}}>ALTO</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br></br>
                                        </li>
                                        <li>
                                        la descrizione del requisito, indicata nel documento come <strong>'Descrizione del requisito'</strong>;
                                        </li>
                                        <li>i riferimenti normativi a cui lo stesso afferisce, indicati nel documento come <strong>'Riferimento
                                            normativo'</strong>;</li>
                                        {/* <li>lì dove previsto:<br/>
                                            <ul>
                                                <li>i requisiti tecnologici aggiuntivi ad esso associati, indicati nel documento come
                                                <strong>'Requisiti tecnologici associati'</strong>;</li>
                                                <li>le indicazioni specifiche aggiuntive per parziale conformità riscontrata, indicate nel
                                                documento come <strong>'Requisiti aggiuntivi per parziale conformità'</strong>;</li>
                                                <li>le indicazioni specifiche aggiuntive per totale assenza di conformità riscontrata, indicate
                                                    nel documento come <strong>'Requisiti aggiuntivi per totale assenza di conformità'</strong>;</li>
                                                <li>le azioni necessarie raccomandate aggiuntive per lo specifico requisito, indicate nel
                                                    documento come <strong>'Azioni necessarie raccomandate'</strong>;</li>
                                                <li>le azioni aggiuntive consigliate per lo specifico requisito, indicate nel documento come
                                                <strong>'Azioni consigliate'</strong>;</li>
                                                <li>le indicazioni aggiuntive necessarie per raggiungere il massimo livello di conformità del
                                                    requisito stesso, indicate nel documento come  <strong>'Best in class'</strong>;</li>
                                            </ul>
                                        </li>
                                        <li>le sanzioni previste in caso di violazione del requisito stesso, indicate nel documento come
                                            <strong>'Rischio specifico'</strong>.</li> */}
                                    </ul>
                               </li>
                           </ul>
                           </p>
                        </div>
                        <div style={{ width : "100%", fontSize: "16px", fontFamily: "Calibri", textAlign:"justify", marginTop:"10px",  }}>
                            <p>
                            <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>6 Risultato dell'analisi</span>
                            <br/>
                            <br/>
                            <strong>Indice Unico di Conformità:</strong> {this.props.rischi.RischioAggregato + "%"}
                            <br/>
                            </p>
                            <br/>
                            <table style={{ width: "100%" }}>
                            <tbody>
                                <tr >
                                    <td style={{ width: "25%" }}>                                       
                                    </td>
                                    <td style={{ width: "50%" }}>
                                        <canvas id="canvas3" ></canvas>
                                    </td>
                                    <td style={{ width: "25%" }}>
                                       
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                        L'organizzazione esibisce un livello di conformità non sufficiente, in virtù dell’Indice Unico di Conformità
                        (IUC) ottenuto, che è del 30% che sta ad indicare che, le misure di sicurezza attualmente previste, non
                        garantiscono la conformità. Tuttavia, questa valutazione sintetica non può essere esaustivamente
                        interpretata senza piena contezza della totalità delle informazioni.
                        <br/>
                        <br/>
                        </p>
                        <p>                        
                           <span style={{color:"#0066cc", fontFamily: "CalibriLight", fontSize: "20px"}}>7 Azioni di rientro</span><br/>
                           Si riportano le azioni di rientro raccomandate e suggerite, necessarie per raggiungere la conformità alla
                            complesso di disposizioni normative di riferimento.<br/>
                           
                        </p>
                        </div>   
                        
                        <div style={{ paddingTop: "20px" }}></div>
                        {remediationRows}                       

                    </div>
                    <br />
                    <br />
                    <br />

                </div>
            </div>
        )
    }
}

