import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { API_CONFIG } from '../../public/js/apiServices';
import { MisuraDiSicurezzaRow } from './MisuraDiSicurezzaRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { LegendaTable } from '../Common/LegendaTable';
import { toStringStatoAssessmentId } from "../data/StatoAssessmentId";
import LIVELLO from "../data/criticality";
import { Util } from "../data/Util";
import { AccordionInfo } from './AccordionInfo';

/**
 * 
 * @param {*} dataCreazione 
 */
function toStringDate(dataCreazione) {
    try {
        let dataSplit = dataCreazione.split('T')[0].split('-');
        let year = dataSplit[0];
        let month = dataSplit[1];
        let day = dataSplit[2];
        return [day + '-' + month + '-' + year, dataCreazione.split('T')[1].split('.')[0]];

    } catch (error) {
        console.error(error);
        return null;
    }
}

function getStatoAssessmentId(statoAssessment) {
    let statoAssessmentInt = undefined;
    switch (statoAssessment) {
        case "CONSOLIDATO":
            statoAssessmentInt = 9;
            break;
        case "COMPLETATO":
            statoAssessmentInt = 3;
            break;
        case "DA_COMPLETARE":
            statoAssessmentInt = 2;
            break;
        default:
            break;
    }
    return statoAssessmentInt
}

const LINK = [
    { nome: "Analisi del rischio", url: "/AnalisiRischio" },
    { nome: "Analisi per PA", url: "/QuestionarioPA" },
];

const DonutChart = createClass({
    propTypes: {
        value: PropTypes.number,        // value the chart should show
        valuelabel: PropTypes.string,
        indicator: PropTypes.string, // label for the chart
        size: PropTypes.number,         // diameter of chart
        strokewidth: PropTypes.number   // width of chart line
    },
    getDefaultProps() {
        return {
            value: 0,
            valuelabel: '',
            size: 116,
            strokewidth: 7
        };
    },


    render() {

        const halfsize = (this.props.size * 0.5);
        const radius = halfsize - (this.props.strokewidth * 0.5);
        const circumference = 2 * Math.PI * radius;
        const strokeval = ((this.props.value * circumference) / 100);
        const dashval = (strokeval + ' ' + circumference);

        const trackstyle = { strokeWidth: this.props.strokewidth };
        const indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval }
        const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
        var chartValue = isNaN(this.props.value) ? 0 : this.props.value;

        return (
            <svg width={this.props.size} height={this.props.size} className={"donutchart"}>
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className={this.props.indicator} />
                <text className="donutchart-text" x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }} >
                    <tspan className="donutchart-text-val">{chartValue}</tspan>
                    <tspan className="donutchart-text-percent">%</tspan>
                    <tspan className="donutchart-text-label" x={halfsize} y={halfsize + 10}>{this.props.valuelabel}</tspan>
                </text>
            </svg>
        );
    }
})

export class QuestionarioPA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            criticità: ["", ""],
            dataCalcoloRischioAttuale: "",
            dataRischioAttuale: "",
            dataModifica: "",
            dataPubblicazione: "",
            impattoD: ["", ""],
            impattoI: ["", ""],
            impattoR: ["", ""],
            statoAssessmentId: "",
            nomePA: "",
            listAssessment: [],
            selectedAssessment: undefined,
            assessmentQuestionario: [],
            readOnly: "",
            textModal: "",
            textPubblica: "",
            textCopiaRisposte: "",
            messageError: "",
            messageChoice: "",
            messageWarning: "",
            messageWarningNewAssessment: "",
            messageWarningPubblic: "",
            isUpdated: false
        }

        //change state
        this.getAssessmentQuestionario = this.getAssessmentQuestionario.bind(this);
        this.changeStateAssessment = this.changeStateAssessment.bind(this);
        this.callChangeStateAssessment = this.callChangeStateAssessment.bind(this);
        this.getCountsOfAnswers = this.getCountsOfAnswers.bind(this);
        this.getSumCounterWithAnswer = this.getSumCounterWithAnswer.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);

        this.isServiceUpdated = this.isServiceUpdated.bind(this);
        this.isValidate = this.isValidate.bind(this);

        this.iniziaAssessment = this.iniziaAssessment.bind(this);
        this.nuovaAnalisi = this.nuovaAnalisi.bind(this);
        this.dialogCopiaRisposte = this.dialogCopiaRisposte.bind(this);
        this.save = this.save.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        //UPDATE STATE
        this.updateGrade = this.updateGrade.bind(this);
        this.updateAssessmentId = this.updateAssessmentId.bind(this);
        this.updateNote = this.updateNote.bind(this);
        this.setInfo = this.setInfo.bind(this);
        this.redirectReport = this.redirectReport.bind(this);
    }

    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        this.getAssessmentQuestionario();
    }

    /**
     * 
     */
    getAssessmentQuestionario() {
        let decoded = this.getAccessToken();
        if (decoded !== null) {
            let user = decoded.user;
            this.setState({ nomePA: user.NomePA });
        }

        this.serverRequest =
            $.ajax({
                url: API_CONFIG.assessmentcompositeListAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    console.log('listAssessment:', response);
                    if (response.data.length > 0) {
                        this.setState({ listAssessment: response.data });

                        this.setState({ selectedAssessment: this.state.listAssessment[0] },
                            () => this.setState({ readOnly: (this.state.listAssessment[0].StatoAssessmentId > 3) }),
                            () => this.setState({ isUpdated: !this.isServiceUpdated() })
                        );
                        //let statoAssessmentInt = getStatoAssessmentId(this.state.listAssessment[0].StatoAssessmentId);
                        //this.setState({ readOnly: (this.state.listAssessment[0].StatoAssessmentId > 3) });
                        $.ajax({
                            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                            type: "POST",
                            contentType: 'application/json',
                            headers: { 'token': sessionStorage.getItem('token') },
                            data: "{ \"AssessmentId\":" + this.state.selectedAssessment.AssessmentId + "}",
                            cache: false,
                            success: function (response) {
                                console.log('response:', response);
                                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                                this.setInfo(response.data.Informazioni[0]);
                                if ($(".statoCompilazione").length > 0)
                                    $(".statoCompilazione").get(0).click();

                            }.bind(this),

                            error: function (xhr, resp, text) {
                                let messageError = "Si è verificato un errore ";
                                console.error(xhr);
                                //Token JWT Invalid
                                if (xhr.responseJSON) {
                                    if (xhr.responseJSON.data === -1) {
                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                        location.replace(API_CONFIG.HomePath);
                                    } else {
                                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                        $('#btnError').get(0).click();
                                    }
                                }
                                else {

                                    this.setState({ messageError: messageError + "." });
                                    $('#btnError').get(0).click();
                                }
                            }.bind(this),
                        });

                    }

                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }


    /**
     * 
     * @param {*} dataInformazioni :response.data.Informazioni
     */
    setInfo(dataInformazioni) {
        try {
            this.setState({ criticità: Util.toStringCriticità(dataInformazioni.Criticità) });
            //
            let dataCalcoloRischioAttuale = Util.toStringDate(dataInformazioni.DataCalcoloRischioAttuale);
            dataCalcoloRischioAttuale = dataCalcoloRischioAttuale["day"] + "/" + dataCalcoloRischioAttuale["month"] + "/" + dataCalcoloRischioAttuale["year"] + " " + dataCalcoloRischioAttuale["hours"] + ":" + dataCalcoloRischioAttuale["minutes"] + ":" + dataCalcoloRischioAttuale["seconds"];
            //
            this.setState({ dataRischioAttuale: dataCalcoloRischioAttuale });
            //
            let dataPubblicazione = Util.toStringDate(dataInformazioni.DataPubblicazione);
            dataPubblicazione = dataPubblicazione["day"] + "/" + dataPubblicazione["month"] + "/" + dataPubblicazione["year"] + " " + dataPubblicazione["hours"] + ":" + dataPubblicazione["minutes"] + ":" + dataPubblicazione["seconds"];
            this.setState({ dataPubblicazione: dataPubblicazione });
            //
            let dataModifica = Util.toStringDate(dataInformazioni.DataModifica)
            dataModifica = dataModifica["day"] + "/" + dataModifica["month"] + "/" + dataModifica["year"] + " " + dataModifica["hours"] + ":" + dataModifica["minutes"] + ":" + dataModifica["seconds"];
            this.setState({ dataModifica: dataModifica });
            this.setState({ impattoD: Util.toStringCriticità(dataInformazioni.ImpattoD) });
            this.setState({ impattoI: Util.toStringCriticità(dataInformazioni.ImpattoI) });
            this.setState({ impattoR: Util.toStringCriticità(dataInformazioni.ImpattoR) });
            this.setState({ statoAssessmentId: toStringStatoAssessmentId(dataInformazioni.StatoAssessmentId) });
            //debugger
        } catch (error) {
            return console.error(error);
        }
    }

    redirectReport() {
        history.push('/RiepilogoDatiAnalisiRischio');
    }

    getAccessToken() {
        return this.props.auth.getAccessToken();
    }
    /**
     * 
     * @param {*} grade 
     * @param {*} categoriaId 
     * @param {*} misuraDiSicurezzaId 
     */
    updateGrade(grade, categoriaId, misuraDiSicurezzaId) {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        try {
            for (let i = 0; i < assessmentQuestionario.length; i++) {
                if (assessmentQuestionario[i].CategoriaMisuraDiSicurezzaId === categoriaId) {
                    for (let j = 0; j < assessmentQuestionario[i].items.length; j++) {
                        const misuraDiSicurezza = assessmentQuestionario[i].items[j];
                        if (misuraDiSicurezza.MisuraDiSicurezzaId === misuraDiSicurezzaId) {
                            misuraDiSicurezza.GradoDiImplementazione = grade;
                            //this.setState({ assessmentQuestionario: assessmentQuestionario });
                            this.setState({ assessmentQuestionario: assessmentQuestionario }, () => this.saveDraft());
                            //console.log('updateGarde', this.state.assessmentQuestionario);
                        }
                    }
                }
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} noteText 
     * @param {*} categoriaId 
     * @param {*} misuraDiSicurezzaId 
     */
    updateNote(noteText, categoriaId, misuraDiSicurezzaId) {
        //let par = { note: noteText, categoriaId: categoriaId, misuraDiSicurezzaId: misuraDiSicurezzaId };
        //console.log("updateNote", par);
        let assessmentQuestionario = this.state.assessmentQuestionario;
        try {
            for (let i = 0; i < assessmentQuestionario.length; i++) {
                if (assessmentQuestionario[i].CategoriaMisuraDiSicurezzaId === categoriaId) {
                    for (let j = 0; j < assessmentQuestionario[i].items.length; j++) {
                        const misuraDiSicurezza = assessmentQuestionario[i].items[j];
                        if (misuraDiSicurezza.MisuraDiSicurezzaId === misuraDiSicurezzaId) {
                            misuraDiSicurezza.Nota = noteText;
                            this.setState({ assessmentQuestionario: assessmentQuestionario });
                            //this.setState({ assessmentQuestionario: assessmentQuestionario }, () => this.saveDraft());
                            //console.log('misuraDiSicurezza', misuraDiSicurezza);
                        }
                    }
                }
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getCountsOfAnswers() {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        let tot = 0;
        let totNonRisposto = [];
        let totAdeguato = [];
        let totAssente = [];
        let totCompleto = [];
        //countsOfAnswers['TotaleImplementate'] = 0;
        let totMigliorabile = [];
        let totNonApplicabile = [];
        let totNonSoRispondere = [];
        let counts = [];

        for (let index = 0; index < assessmentQuestionario.length; index++) {
            const element = assessmentQuestionario[index];
            tot += element.items.length;
            for (let j = 0; j < element.items.length; j++) {
                const item = element.items[j];

                switch (item.GradoDiImplementazione) {

                    case -2://Non so rispondere
                        totNonSoRispondere.push(item);
                        break;
                    case -1://Non Risposto
                        totNonRisposto.push(item);
                        break;
                    case 0://Non Applicabile
                        totNonApplicabile.push(item);
                        break;
                    case 1://Assente
                        totAssente.push(item);
                        break;
                    case 2://Migliorabile
                        totMigliorabile.push(item);
                        break;
                    case 3://Adeguato
                        totAdeguato.push(item);
                        break;
                    case 4://Completo
                        totCompleto.push(item);
                        break;
                    case 5://Non so rispondere
                        //totNonSoRispondere.push(item);
                        break;
                    default://
                        break;
                }
            }
        }
        counts['tot'] = tot;
        counts['totNonRisposto'] = totNonRisposto.length;
        counts['totNonApplicabile'] = totNonApplicabile.length;
        counts['totAssente'] = totAssente.length;
        counts['totMigliorabile'] = totMigliorabile.length;
        counts['totAdeguato'] = totAdeguato.length;
        counts['totCompleto'] = totCompleto.length;
        counts['totNonSoRispondere'] = totNonSoRispondere.length;
        return counts;
    }

    /**
     * 
     */
    getSumCounterWithAnswer() {
        let counts = this.getCountsOfAnswers();
        let sumCounterWithAnswer = 0;
        try {
            for (const key in counts) {
                if (counts.hasOwnProperty(key)) {
                    if (key !== 'tot' && key !== 'totNonRisposto') {
                        sumCounterWithAnswer += counts[key];
                    }
                }
            }
            return sumCounterWithAnswer;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} event 
     */
    updateAssessmentId(event) {
        let index = parseInt(event.target.value, 10);
        let listAssessment = this.state.listAssessment;
        let assessment = {
            id: listAssessment[index].AssessmentId,
            dataCreazione: listAssessment[index].DataCreazione
        }
        this.setState({ selectedAssessment: listAssessment[index] });
        $.ajax({
            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: "{ \"AssessmentId\":" + listAssessment[index].AssessmentId + "}",
            cache: false,
            success: function (response) {
                console.log('AssessmentQuestionario:', response);
                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                this.setInfo(response.data.Informazioni[0]);
                let statoAssessmentInt = getStatoAssessmentId(listAssessment[index].StatoAssessmentId);
                this.setState({ readOnly: (listAssessment[index].StatoAssessmentId > 3) });
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
            }.bind(this),
        });
    }


    isValidate() {
        try {
            let counter = [];
            let categorieCaratteristica = this.state.serviziComposite[0].CategorieCaratteristica;
            const categorieCaratteristicaLength = categorieCaratteristica.length;
            for (let index = 0; index < categorieCaratteristica.length; index++) {
                const caratteristiche = categorieCaratteristica[index].Caratteristica;
                for (let j = 0; j < caratteristiche.length; j++) {
                    const element = caratteristiche[j];
                    if (element.Checked) {
                        counter.push(element);
                        break;
                    }
                }

            }
            return counter.length === categorieCaratteristicaLength;
        } catch (error) {
            console.error(error);
            return false;
        }
    }



    /**
    * handle save button clicked
    */
    save(e) {
        // data in the form
        if (this.getSumCounterWithAnswer() === this.getCountsOfAnswers()['tot']) {
            let assessmentQuestionario = this.state.assessmentQuestionario;
            var form_data = { 'Bozza': undefined, assessmentQuestionario };
            console.log('form_data', JSON.stringify(form_data));// submit form data to api
            $.ajax({
                //url:'http://192.168.1.17:4001/api/serviziocomposite/saveOrUpdate',
                url: API_CONFIG.assessmentcompositeSaveOrUpdate,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(form_data),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                        this.setState({ messageSuccess: 'Calcolo del rischio effettuato con successo!' })
                        $('#btnSuccessAction').get(0).click();
                        this.state.selectedAssessment.StatoAssessmentId = 3;
                        this.setState({ isUpdated: false });
                        let dataCalcoloRischioAttuale = Util.toStringDate(new Date())
                        this.setState({ dataCalcoloRischioAttuale: dataCalcoloRischioAttuale["day"] + "/" + dataCalcoloRischioAttuale["month"] + "/" + dataCalcoloRischioAttuale["year"] });
                        //alert(response.status);
                    }
                    else {
                        //alert(response.status);
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
        } else {
            this.setState({ messageWarning: "Prima di effettuare il Calcolo compilare tutte le risposte!" });
            $('#btnCalcolo').get(0).click();
            //alert('Prima di effettuare il Calcolo compilare tutte le risposte!');
            return;
        }

        e.preventDefault();
    }

    /**
     * 
     */
    saveDraft() {
        // data in the form
        let assessmentQuestionario = this.state.assessmentQuestionario;
        //assessmentQuestionario[0]['Bozza'] = 'string';
        //assessmentQuestionario['Bozza'] = 'string';
        // data in the form
        var form_data = { 'Bozza': 'string', assessmentQuestionario };
        console.log('saveDraft', JSON.stringify(form_data));
        // submit form data to api
        $.ajax({
            //url:'http://192.168.1.17:4001/api/serviziocomposite/saveOrUpdate',
            url: API_CONFIG.assessmentcompositeSaveOrUpdate,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            //data: JSON.stringify(form_data),
            data: JSON.stringify(form_data),
            cache: false,
            success: function (response) {
                console.log(response);
                if (response.status === 'success') {
                    //console.log(response);
                    this.state.selectedAssessment.StatoAssessmentId = 2;
                    this.setState({ isUpdated: true });
                    let dataModifica = Util.toStringDate(new Date())
                    this.setState({ dataModifica: dataModifica["day"] + "/" + dataModifica["month"] + "/" + dataModifica["year"] });
                }
                else {
                    alert('Errore durante il salvataggio!')
                }
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
            }.bind(this),
        });
    }

    /**
     * 
     * @param {*} values 
     */
    calculate(values) {
        var sum = 0;
        var count = 0;
        var order = [];

        for (var i = 0; i < values.length; i++) {
            sum += parseInt(values[i], 10);
            //intVal.push (parseInt(values[i]));
            //decimalVal.push (values[i] - parseInt(values[i]));
            order[i] = i
        }
        var diff = 100 - sum;
        //decimalVal.sort(function(a, b){return b-a});
        order.sort(function (a, b) {
            var dVala = values[a] - parseInt(values[a], 10);
            var dValb = values[b] - parseInt(values[b], 10);
            return dValb - dVala
        });

        values.sort(function (a, b) {
            var dVala = a - parseInt(a, 10);
            var dValb = b - parseInt(b, 10);
            return dValb - dVala
        });
        for (var j = 0; j < values.length; j++) {
            count = j;
            if (count < diff) {
                values[j] = parseInt(values[j], 10) + 1;
            } else {
                values[j] = parseInt(values[j], 10);
            }
        }
        var unsorted = [];
        for (var k = 0; k < values.length; k++) {
            //values[order[i]] = data_x[i];
            unsorted[order[k]] = values[k];
        }
        return unsorted;
    }

    /**
     * 
     */
    nuovaAnalisi(event) {
        if (this.state.listAssessment.length > 0) {
            this.setState({ messageWarningNewAssessment: "Stai creando una nuova analisi: l'eventuale analisi precedente verrà chiusa" })
            $('#btnIniziaAssessment').get(0).click();
        }
        else {
            this.iniziaAssessment("No");
        }
    }



    /**
     * 
     */
    dialogCopiaRisposte(event) {
        if (this.state.listAssessment.length > 0) {
            this.setState({ messageChoice: "Importare i Gradi di Implementazione dall'analisi precedente?" })
            $('#btnChoice').get(0).click();
        } else {
            this.iniziaAssessment();
        }
    }

    /**
     * 
     */
    iniziaAssessment(copiaRisposte) {
        var importRisposte = copiaRisposte === "Yes" ? '1' : '0';
        $.ajax({
            url: API_CONFIG.assessmentcompositeIniziaAssessment,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: "{ \"ImportRisposte\":" + "\"" + importRisposte + "\" }",
            cache: false,
            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response) {
                //console.log('AssessmentQuestionario:', response);
                if (response.status === 'success') {
                    this.serverRequest =
                        $.ajax({
                            url: API_CONFIG.assessmentcompositeListAssessment,
                            type: "POST",
                            contentType: 'application/json',
                            headers: { 'token': sessionStorage.getItem('token') },
                            cache: false,
                            success: function (response) {
                                console.log('listAssessment:', response);
                                if (response.data.length > 0) {
                                    this.setState({ listAssessment: response.data });
                                    this.setState({ selectedAssessment: this.state.listAssessment[0] });
                                    let statoAssessmentInt = getStatoAssessmentId(this.state.listAssessment[0].StatoAssessmentId);
                                    this.setState({ readOnly: (this.state.listAssessment[0].StatoAssessmentId > 3) });
                                    $.ajax({
                                        url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                                        type: "POST",
                                        contentType: 'application/json',
                                        headers: { 'token': sessionStorage.getItem('token') },
                                        data: "{ \"AssessmentId\":" + this.state.selectedAssessment.AssessmentId + "}",
                                        cache: false,
                                        success: function (response) {
                                            console.log('AssessmentQuestionario:', response);
                                            this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                                            this.setInfo(response.data.Informazioni[0]);
                                            this.setState({ messageSuccess: "Questionario di analisi del rischio creato" });
                                            $('#btnSuccess').get(0).click();
                                        }.bind(this),

                                        error: function (xhr, resp, text) {
                                            let messageError = "Si è verificato un errore ";
                                            console.error(xhr);
                                            //Token JWT Invalid
                                            if (xhr.responseJSON) {
                                                if (xhr.responseJSON.data === -1) {
                                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                                    location.replace(API_CONFIG.HomePath);
                                                } else {
                                                    this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                                    $('#btnError').get(0).click();
                                                }
                                            }
                                            else {

                                                this.setState({ messageError: messageError + "." });
                                                $('#btnError').get(0).click();
                                            }
                                        }.bind(this),
                                    });
                                }

                            }.bind(this),

                            error: function (xhr, resp, text) {
                                let messageError = "Si è verificato un errore ";
                                console.error(xhr);
                                //Token JWT Invalid
                                if (xhr.responseJSON) {
                                    if (xhr.responseJSON.data === -1) {
                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                        location.replace(API_CONFIG.HomePath);
                                    } else {
                                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                        $('#btnError').get(0).click();
                                    }
                                }
                                else {

                                    this.setState({ messageError: messageError + "." });
                                    $('#btnError').get(0).click();
                                }
                            }.bind(this),
                        });
                    //alert('L'Analisi del Rischio è stata creata');
                    //$('#btnSuccess').get(0).click();
                } else {
                    //
                }
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
                $('#loader').hide();
                $('#modal').hide();
            }.bind(this),
        });
    }

    callChangeStateAssessment() {
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.cambiaStatoAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: "{ \"AssessmentId\": \"" + this.state.selectedAssessment.AssessmentId + "\"," + " \"Stato\":" + "\"" + parseInt(this.state.selectedAssessment.StatoAssessmentId + 1) + "\" }",
                cache: false,
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                        this.setState({ messageSuccess: "Operazione effettuata con successo!" });
                        $('#btnSuccess').get(0).click();
                        this.setState({ readOnly: true });
                        this.getAssessmentQuestionario();
                    }
                    else {
                        //alert(response.status);
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    changeStateAssessment() {
        try {

            if (this.state.selectedAssessment.StatoAssessmentId) {
                let statoAssessmentInt = this.state.selectedAssessment.StatoAssessmentId;
                if (statoAssessmentInt === 3) {
                    this.setState({ messageWarningPubblic: "Dopo il consolidamento non si potrà più ricalcolare il Rischio Attuale. Procedere?" })
                    $('#btnPubblica').get(0).click();
                } else {
                    this.setState({ messageWarning: "Per procedere al consolidamento è necessario effettuare il Calcolo del Rischio." });
                    $('#btnCalcolo').get(0).click();
                    //console.log("lo stato dell'assessment deve essere completato");
                    return;
                }
            } else {
                return console.error('this.state.selectedAssessment.StatoAssessmentId is null or undefined');
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
         * 
         */
    isServiceUpdated() {
        try {
            let tsDataAggiornamento = new Date(this.state.selectedAssessment.DataModifica).getTime();
            let tsDataCalcoloRischioAttuale = new Date(this.state.selectedAssessment.DataCalcoloRischioAttuale).getTime();

            return tsDataAggiornamento > tsDataCalcoloRischioAttuale;
        } catch (error) {
            return false;
        }
    }

    /**
     * 
     */
    toStringCriticità(criticità) {
        try {
            return {
                nome: (criticità > 0) && (criticità < LIVELLO.length) ? LIVELLO[criticità].name.toUpperCase() : "",
                text: (criticità > 0) && (criticità < LIVELLO.length) ? LIVELLO[criticità].text : ""
            }

        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    render() {
        let isUpdated = {};
        if (this.state.selectedAssessment) {
            isUpdated = {
                modifica: (this.state.selectedAssessment.DataModifica) ? this.state.selectedAssessment.DataModifica : "",
                rischioAttuale: (this.state.selectedAssessment.DataCalcoloRischioAttuale) ? this.state.selectedAssessment.DataCalcoloRischioAttuale : "",
                isServiceUpdated: this.isServiceUpdated()
            }
        }

        const COUNTS = this.getCountsOfAnswers();
        var values = [];
        //values[0] = COUNTS['Totale'] / COUNTS['Totale'] * 100;
        //values[3] = COUNTS['TotaleImplementate'] / COUNTS['Totale'] * 100;
        values[0] = COUNTS['totNonApplicabile'] / COUNTS['tot'] * 100;
        values[1] = COUNTS['totAssente'] / COUNTS['tot'] * 100;
        values[2] = COUNTS['totMigliorabile'] / COUNTS['tot'] * 100;
        values[3] = COUNTS['totAdeguato'] / COUNTS['tot'] * 100;
        values[4] = COUNTS['totCompleto'] / COUNTS['tot'] * 100;
        values[5] = COUNTS['totNonSoRispondere'] / COUNTS['tot'] * 100;
        values[6] = COUNTS['totNonRisposto'] / COUNTS['tot'] * 100;

        values[7] = ((
            COUNTS['tot'] -
            COUNTS['totAdeguato'] -
            COUNTS['totAssente'] -
            COUNTS['totCompleto'] -
            //COUNTS['TotaleImplementate'] -
            COUNTS['totMigliorabile'] -
            COUNTS['totNonApplicabile'] -
            COUNTS['totNonRisposto'] -
            COUNTS['totNonSoRispondere']) /
            COUNTS['tot']) * 100;

        const RISPOSTE = this.calculate(values);

        const answer = RISPOSTE[0] + RISPOSTE[1] + RISPOSTE[2] + RISPOSTE[3] + RISPOSTE[4] + RISPOSTE[5];
        const SENZA_RISPOSTA = 100 - answer;

        var rows = '';
        if (this.state.assessmentQuestionario.length === 0) {
            rows = <div className="row">Loading...</div>
        } else {
            rows = this.state.assessmentQuestionario.map(function (assessment, i) {
                return (
                    <MisuraDiSicurezzaRow
                        key={i}
                        rowId={i}
                        categoriaId={assessment.CategoriaMisuraDiSicurezzaId}
                        misuraDiSicurezzaNome={assessment.CategoriaMisuraDiSicurezzaNome}
                        items={assessment.items}
                        updateGrade={this.updateGrade}
                        readOnly={this.state.readOnly}
                        saveDraft={this.saveDraft}
                        updateNote={this.updateNote}
                    />
                );
            }.bind(this)
            );
        }

        let testo = '';
        let container = '';
        let dataAggiornamentoContesto = '';
        if (this.state.selectedAssessment) {
            dataCreazione = toStringDate(this.state.selectedAssessment.DataCreazione);
            dataAggiornamentoContesto = toStringDate(this.state.selectedAssessment.DataAggiornamentoContesto);
        }
        if (this.state.selectedAssessment === undefined) {
            container = <div>Nessuna Analisi del Rischio Selezionata</div>
        } else if (this.state.selectedAssessment !== undefined) {
            testo = "Analisi del rischio effettuata a partire dall'Analisi del contesto e dalla Valutazione degli impatti del " + dataAggiornamentoContesto[0] + ' ' + dataAggiornamentoContesto[1] + ". Per effettuare il calcolo del rischio rispondere a tutte le domande del Questionario";

            container =
                <div>
                    <AccordionInfo
                        statoAssessmentId={this.state.statoAssessmentId}
                        criticità={this.state.criticità}
                        impattoR={this.state.impattoR}
                        impattoI={this.state.impattoI}
                        impattoD={this.state.impattoD}
                        dataCalcoloRischioAttuale={this.state.dataRischioAttuale}
                        dataPubblicazione={this.state.dataPubblicazione}
                        dataModifica={this.state.dataModifica}
                        criticitàLabel={"Profilo di criticità complessivo"}
                        impattoLabel={"Impatto per proprietà di informazione"}
                    />
                    <div className="row">
                        <div className="col-lg-6 card-headerInfo">
                            <a className="card-linkInfo marginLeft statoCompilazione" data-bs-toggle="collapse" href="#collapse">
                                Stato compilazione</a>
                        </div>
                        <div className="col-lg-5 card-header text-right ">
                            {this.getSumCounterWithAnswer() + '/' + this.getCountsOfAnswers()['tot']}</div>
                        <div className="col-lg-1 card-headerInfo">
                            <a className="card-linkInfo marginLeft" data-bs-toggle="collapse" href="#collapse">
                                <i className="iconRight fa fa-chevron-down"></i>
                            </a>
                        </div>

                    </div>
                    <div id="collapse" className="collapse marginLeft" data-parent="#accordion">
                        <div className="row spazio10">

                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	COMPLETO<br />
                                <DonutChart value={RISPOSTE[4]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">{RISPOSTE[4] + '% - ' + this.getCountsOfAnswers()['totCompleto'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ADEGUATO<br />
                                <DonutChart value={RISPOSTE[3]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[3] + '% - ' + this.getCountsOfAnswers()['totAdeguato'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	MIGLIORABILE<br />
                                <DonutChart value={RISPOSTE[2]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[2] + '% - ' + this.getCountsOfAnswers()['totMigliorabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ASSENTE<br />
                                <DonutChart value={RISPOSTE[1]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[1] + '% - ' + this.getCountsOfAnswers()['totAssente'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON APPLICABILE<br />
                                <DonutChart value={RISPOSTE[0]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[0] + '% - ' + this.getCountsOfAnswers()['totNonApplicabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON SO<br />
                                <DonutChart value={RISPOSTE[5]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[5] + '% - ' + this.getCountsOfAnswers()['totNonSoRispondere'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="spazio10"></div>
                    {this.state.selectedAssessment !== undefined && rows}
                    {this.state.selectedAssessment !== undefined &&
                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                            <div className="row spazio20">
                                <div className="col-lg-3">	</div>
                                {/*
                                <div className="col-lg-4">
                                    <button type="button"
                                        onClick={this.save}
                                        hidden={this.state.readOnly}
                                        className="btn btn-success btn-lg btn-block">Calcola Rischio Attuale</button>
                                    <button type="button" hidden id="calcoloModal"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                </div>
                                */}
                                <div className="col-lg-3">
                                    <button type="button"
                                        onClick={this.save}
                                        hidden={this.state.readOnly}
                                        className="btn btn-success btn-lg btn-block">Calcola Rischio Attuale</button>
                                    <button type="button" hidden id="calcoloModal"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                </div>
                                <div className="col-lg-3">
                                    <button type="button"
                                        onClick={this.changeStateAssessment}
                                        hidden={this.state.readOnly}
                                        className="btn btn-success btn-lg btn-block"
                                    >CONSOLIDA</button>
                                </div>
                                <div className="col-lg-3"> </div>
                            </div>

                            <div className="col-lg-4">	</div>

                        </div>
                    }
                </div>
        }

        let assessmentOption = this.state.listAssessment.map(function (item, i) {
            var data = toStringDate(item.DataCreazione);
            return (

                //<option key={i} value={item.AssessmentId}>{toStringDate(item.DataCreazione)}</option>
                <option key={i} value={i}>{data[0] + ' ' + data[1]}</option>
            );
        });

        //this.setState({textModal: "Stai creando una nuova analisi: l'eventuale analisi precedente verrà chiusa" });
        //this.setState({textPubblica : "Dopo la pubblicazione non si potrà più ricalcolare il Rischio Attuale. Procedere?"});
        //this.setState({textCopiaRisposte : "Importare i Gradi di Implementazione dall'analisi precedente?"});
        let isServiceUpdated = (this.isServiceUpdated() || this.state.isUpdated);
        let dataCreazione = '';
        if (this.state.selectedAssessment)
            dataCreazione = toStringDate(this.state.selectedAssessment.DataCreazione);
        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top" >


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-8">
                            {/*JSON.stringify(isUpdated)*/}
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Analisi del rischio per Azienda - {this.state.nomePA}</span>
                            &nbsp;<i hidden={!isServiceUpdated} className="fa fa-warning testoRosso"></i>
                        </div>

                        <div className="col-lg-2"></div>

                        <div className="col-lg-2">
                            <span>STORICO ANALISI</span>
                            <select className="form100"
                                defaultValue={-1}
                                onChange={this.updateAssessmentId}>
                                {assessmentOption}
                            </select>

                            {/* { this.state.selectedAssessment !== undefined && <span>
                            Data Creazione<br />{dataCreazione[0]}<br />{dataCreazione[1]}</span>} */}
                            <div className="spazio10"></div>
                            <a>
                                <button type="button"
                                    data-bs-toggle="modal" data-bs-target="#warning" onClick={() => this.nuovaAnalisi()}
                                    className="btn btn-warning btn-lg btn-block">NUOVA ANALISI</button>
                            </a>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco delle misure di sicurezza a cui l’utente deve dare un riscontro. Per ogni domanda è possibile ottenere un ulteriore approfondimento selezionando la domanda stessa. Le modifiche ai dati vengono salvate automaticamente, per procedere con il calcolo del rischio attuale è necessario premere il tasto “<strong>Calcola Rischio Attuale</strong>”.</p>
                            <p> {testo}</p>
                            <span className="paRight InfoAgg">* selezionare almeno una caratteristica&nbsp; &nbsp;
                            <i hidden={!isServiceUpdated} className="fa fa-warning testoRosso"></i>
                                {isServiceUpdated && " Analisi modificata"} </span>
                            <br />
                            <span className="paRight InfoAgg"><a data-bs-toggle="collapse" href="#collapseLegenda"><i className="fa fa-question testoBlu"></i> Legenda</a></span>
                            <div id="collapseLegenda" className="collapse" data-parent="#accordion">
                                <LegendaTable widthCol1={"20%"} widthCol2={"80%"} bgcolor="#ECECEC" cellPadding="10" />
                            </div>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>
                    {container}

                </div>

                <br />
                <br />
                <br />
                <ModalDialog idTarget={'successAction'} idBtn={'btnSuccessAction'} textModal={this.state.messageSuccess} handleOK={this.redirectReport} />
                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.messageSuccess} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnIniziaAssessment'} handle={this.dialogCopiaRisposte} textModal={this.state.messageWarningNewAssessment} />
                <ModalDialog idTarget={'warningAction2'} idBtn={'btnPubblica'} handle={this.callChangeStateAssessment} textModal={this.state.messageWarningPubblic} />
                <ModalDialog idTarget={'choice'} idBtn={'btnChoice'} handle={this.iniziaAssessment} textModal={this.state.messageChoice} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={this.state.messageWarning} />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
            </div >
        )
    }
}