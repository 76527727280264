import jwtDecode from 'jwt-decode';
import { API_CONFIG } from '../../public/js/apiServices';
import $ from 'jquery';


export default class Auth {

    //userProfile = this.setAccessToken();

    constructor() {
        this.logout = this.logout.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.setAccessToken = this.setAccessToken.bind(this);
    }


    /**
     * 
     */
    setAccessToken() {
        try {

            if (location.href.indexOf('?token=') !== -1) {
                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");


                var accessToken = location.href.split('?token=')[1];

                                
                //console.log("TEST TOKEN",accessToken);
                //console.log("TEST TOKEN DECODED",jwtDecode(accessToken));
                
                //Get session token
                $.ajax({
                    url: API_CONFIG.renewToken,
                    type: 'POST',
                    headers: { 'token': accessToken },
                    cache: false,
                    success: function (response) {
                        sessionStorage.setItem('token', response.data);

                        //Get decoded token
                        $.ajax({
                            url: API_CONFIG.getUserInfo,
                            type: 'POST',
                            headers: { 'token': response.data },
                            cache: false,
                            success: function (response) {
                                sessionStorage.setItem('userInfo', JSON.stringify(response.data));
                                
                                location.replace(API_CONFIG.HomePath);

                            }.bind(this),
                            error: function (xhr, resp, text) {
                                //Token JWT Invalid
                                if (xhr.responseJSON.data === -1) {
                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                    location.replace(API_CONFIG.HomePath);
                                }
                            }
                        });
                    }.bind(this),
                    error: function (xhr, resp, text) {
                        //Token JWT Invalid
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        }
                    }
                });

            } else {
            }
           
        } catch (error) {
            location.replace(API_CONFIG.HomePath);
            return console.error(error);
        }
    }

    renewToken() {
        try {

                //Get session token
                $.ajax({
                    url: API_CONFIG.renewToken,
                    type: 'POST',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        sessionStorage.setItem('token', response.data);

                        //Get decoded token
                        $.ajax({
                            url: API_CONFIG.getUserInfo,
                            type: 'POST',
                            headers: { 'token': response.data },
                            cache: false,
                            success: function (response) {
                                sessionStorage.setItem('userInfo', JSON.stringify(response.data));
                                //location.replace(API_CONFIG.HomePath);
                            }.bind(this),
                            error: function (xhr, resp, text) {
                                //Token JWT Invalid
                                if (xhr.responseJSON.data === -1) {
                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                    location.replace(API_CONFIG.HomePath);
                                }
                            }
                        });
                    }.bind(this),
                    error: function (xhr, resp, text) {
                        //Token JWT Invalid
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        }
                    }
                });

             
           
        } catch (error) {
            location.replace(API_CONFIG.HomePath);
            return console.error(error);
        }
    }

    setToken(token) {
        try {
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

            //let decoded = jwtDecode(token);
            sessionStorage.setItem('token', token);
            
            //Get decoded token
            $.ajax({
                url: API_CONFIG.getUserInfo,
                type: 'POST',
                headers: { 'token': token },
                cache: false,
                success: function (response) {
                    var userInfo = JSON.stringify(response.data);
                    sessionStorage.setItem('userInfo', userInfo);
                    //console.log("USERINFO", JSON.parse(userInfo));
                    location.replace(API_CONFIG.HomePath);

                }.bind(this),
                error: function (xhr, resp, text) {
                    //Token JWT Invalid
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    }
                }
            });

           

        } catch (error) {
            location.replace(API_CONFIG.HomePath);
        }
    }

    getAccessToken() {
        try {
            const accessToken = sessionStorage.getItem('userInfo');
            if (accessToken) {
                return JSON.parse(accessToken);
            }
            return null;
        } catch (error) {
            console.error(error);
            location.replace(API_CONFIG.HomePath);
        }
    }

    getEncryptedToken() {
        try {
            const accessToken = sessionStorage.getItem('token');
            if (accessToken) {
                return accessToken;
            }
            return null;
        } catch (error) {
            console.error(error);
            location.replace(API_CONFIG.HomePath);
        }
    }

    /**
     * error data.detail.code == -1 || data.detail.code == -2 
     */
    logout() {
                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

        location.replace(API_CONFIG.logoutSpidUrl);
    }

    hasFunction(funzione) {
        try {
            const token = sessionStorage.getItem('userInfo');
            if (token !== null) {
                //console.log("USERINFO", JSON.parse(token));
                let funzioni = JSON.parse(token).user.Funzioni;
                return funzioni.indexOf(funzione) > -1;
            }
            return false;
        } catch (error) {
            console.error(error);
            return false;
        }
    }


    isAuthenticated() {
        try {

            let token = jwtDecode(sessionStorage.getItem('token'));
            if (token !== null) {
                let exp = token.exp;
                let expiresAt = new Date(parseInt(exp, 10) * 1000);
                
                return new Date().getTime() < expiresAt.getTime();
                //return new Date(new Date().getTime() + 59 * 60000).getTime() < expiresAt.getTime();
            }
            return false;
        } catch (error) {
            //console.error(error);
            return false;
        }
    }

    getExpiresAt() {
        try {
            let token = jwtDecode(sessionStorage.getItem('token'));
            if (token !== null) {
                let exp = token.exp;
                let expiresAt = new Date(parseInt(exp, 10) * 1000);
                
                return expiresAt;                
            }
            return null;
        } catch (error) {            
            return null;
        }
    }
}
