import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import { ServiceRow } from './ServiceRow';
import { API_CONFIG } from '../../public/js/apiServices';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import { SearchInput } from '../Common/SearchInput';
import { Util } from "../data/Util";

import infoIcon from '../../public/images/info.png';

/*import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "", url: "/" }
];*/

var STATI = [];
/*
STATI[-1] = { option: "TUTTE", statoCaratterizzazione: "-1" };
STATI[0] = { option: "COMPLETATO", statoCaratterizzazione: "CARATTERIZZATO" };
STATI[1] = { option: "INCOMPLETO", statoCaratterizzazione: "PARZIALMENTE_CARATTERIZZATO" };
STATI[2] = { option: "DA COMPILARE", statoCaratterizzazione: "DA_CARATTERIZZARE" };
*/
STATI["-1"] = "Tutti";
STATI["VALUTATO"] = "completo";
STATI["PARZIALMENTE_VALUTATO"] = "incompleto";
STATI["DA_VALUTARE"] = "da valutare";

/**
* 
* @param {*} RID[0]<= Riservatezza,RID[1]<= Integrità, RID[2]<= Disponibilità

*/
function getRID(RID) {
    let arrayRID = [];
    try {

        if (RID[0] < 1)
            return "";

        //var nameR = ""; var classR = "";
        for (let index = 0; index < RID.length; index++) {
            switch (RID[index]) {
                case 1:
                    // nameR = "B"; classR = "bgVerde";
                    arrayRID.push("B");
                    break;
                case 2:
                    // nameR = "M";classR = "bgArancio";
                    arrayRID.push("M");

                    break;
                case 3:
                    // nameR = "A";classR = "bgRosso";
                    arrayRID.push("A");
                    break;
                case 4:
                    // nameR = "C";classR = "bgNero";
                    arrayRID.push("C");
                    break;
                default:
                    arrayRID.push("");
                    break;
            }
        }
        //console.log("RID:", arrayRID.toString());
        return arrayRID.toString();
    } catch (error) {
        console.error(error);
        return "";
    }
}


export class ListaServiziImpatti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            rowsTable: [],
            selectedColumn: "",
            selectedCategory: sessionStorage.getItem('selectedCategoryVI') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedCategoryVI') : '-1',
            selectedState: sessionStorage.getItem('selectedStateVI') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedStateVI') : this.props.riepilogo === "true" ? "VALUTATO" : '-1',
            selectedTypology: sessionStorage.getItem('selectedTypologyVI') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedTypologyVI') : '-1',
            search: sessionStorage.getItem('searchVI') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchVI') : '',
            searchAll: sessionStorage.getItem('searchAllVI') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchAllVI') : '',
            categories: [],
            typologies: [],
            isAscName: true,
            activePage: 1,
            currentPageListServices: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...',
            messageError: ''
        }
        this.areThereModifiedServices = this.areThereModifiedServices.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);
        this.orderServicesList = this.orderServicesList.bind(this);
        this.setCategoriesTypologies = this.setCategoriesTypologies.bind(this);
        this.toString = this.toString.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updateTypology = this.updateTypology.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.updateRowsTable = this.updateRowsTable.bind(this);


    }

    componentDidMount() {
        if (this.props.riepilogo === "true")
            this.setState({ selectedState: "VALUTATO" });
        try {


            //console.log('token:', sessionStorage.getItem('token'))
            this.serverRequest =
                $.ajax({
                    //url:'https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/getServiziByCodiceIPA',
                    url: API_CONFIG.impattocompositeGetServiziByCodiceIPA,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        console.log('response:', response);
                        this.setState({ services: Util.getServices(response.data, "VALUTARE") });
                        this.setState({ rowsTable: Util.getServices(response.data, "VALUTARE") }, this.updateRowsTable);
                        this.setCategoriesTypologies();
                        this.setState({ emptyTest: "Nessun Servizio con i criteri specificati" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });

            if ((sessionStorage.getItem('selectedCategoryVI') !== null ||
                sessionStorage.getItem('selectedStateVI') !== null ||
                sessionStorage.getItem('selectedTypologyVI') !== null ||
                sessionStorage.getItem('searchVI') !== null ||
                sessionStorage.getItem('searchAllVI') !== null) &&
                this.props.riepilogo !== "true")
                $("#ricerca").get(0).click();
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    setCategoriesTypologies() {
        $.ajax({
            //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie',
            url: API_CONFIG.getCategorieSottoCategorie,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
                //console.log('response:', response);
                this.setState({ typologies: response.data.SottoCategoriaServizio });
                this.setState({ categories: response.data.CategoriaServizio });
                console.log('SottoCategoriaServizio:', this.state.typologies);
                console.log('CategoriaServizio:', this.state.categories);
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }

    updateCategory(e) {
        console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedCategoryVI', val);
        this.setState({ selectedCategory: val },
            this.updateRowsTable
        );
    }

    updateState(e) {
        console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedStateVI', val);
        this.setState({ selectedState: val },
            this.updateRowsTable
        );
    }

    updateTypology(e) {
        console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedTypologyVI', val);
        this.setState({ selectedTypology: val },
            this.updateRowsTable
        );
    }

    updateSearch(e) {
        console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchVI', val);
        this.setState({ search: val },
            this.updateRowsTable
        );
    }

    updateSearchAll(e) {
        console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllVI', val);
        this.setState({ searchAll: val },
            this.updateRowsTable
        );
    }


    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            toString += element.Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    /**
      * 
      * @param {*} val 
      * @param {*} field 
    */
    updateRowsTable() {
        //console.log('updateRowsTable');
        let services = this.state.services;
        //let rowsTable = this.state.rowsTable;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedCategory = this.state.selectedCategory;
        let selectedState = this.state.selectedState;
        let selectedTypology = this.state.selectedTypology;
        //debugger
        try {
            var list = [];
            if (searchAll === '' && search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1') {
                list = services;
            } else {

                for (let index = 0; index < services.length; index++) {
                    const element = services[index];
                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente === null || element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente === null || element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.Descrizione === null || element.Descrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {
                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                            if (selectedState === '-1' || element.StatoCaratterizzazione.toUpperCase().indexOf(selectedState.toUpperCase()) === 0) {

                                if (selectedCategory === '-1' || this.toString(element.Categorie).toUpperCase().indexOf(selectedCategory.toUpperCase()) > -1) {

                                    if (selectedTypology === '-1' || this.toString(element.Tipologie).toUpperCase().indexOf(selectedTypology.toUpperCase()) > -1) {
                                        list.push(element);
                                    }
                                }
                            }
                        }
                    }

                }
            }
            //console.log('list:', list);
            //this.setState({ rowsTable: list });
            this.setState({ rowsTable: list }, () => { this.handlePageChange(1) });
        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} key chiave di ordinamento
     */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let rowsTable = this.state.rowsTable;
        key = key.toLowerCase();
        this.setState({ selectedColumn: key });
        try {
            switch (key) {
                case 'nome':
                    if (asc) {
                        list = rowsTable.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });
                    }
                    else list = rowsTable.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                    this.setState({ isAscName: !asc });
                    break;
                case 'data':
                    if (asc) {
                        list = rowsTable.sort(function (a, b) {
                            return new Date(a.DataUpdateImpatto !== null ?
                                a.DataUpdateImpatto.split('T')[0] : a.DataUpdateImpatto).getTime() - new Date(b.DataUpdateImpatto !== null ? b.DataUpdateImpatto.split('T')[0] : b.DataUpdateImpatto).getTime();
                        });

                    } else list = rowsTable.sort(function (a, b) {
                        return new Date(b.DataUpdateImpatto !== null ?
                            b.DataUpdateImpatto.split('T')[0] : b.DataUpdateImpatto).getTime() - new Date(a.DataUpdateImpatto !== null ? a.DataUpdateImpatto.split('T')[0] : a.DataUpdateImpatto).getTime();
                    });
                    this.setState({ isAscName: !asc });
                    break;
                case 'dataaggiornamento':
                    if (asc) {
                        list = rowsTable.sort(function (a, b) {
                            return new Date(a.DataAggiornamento !== null ?
                                a.DataAggiornamento.split('T')[0] : a.DataAggiornamento).getTime() - new Date(b.DataAggiornamento !== null ? b.DataAggiornamento.split('T')[0] : b.DataAggiornamento).getTime();
                        });

                    } else list = rowsTable.sort(function (a, b) {
                        return new Date(b.DataAggiornamento !== null ?
                            b.DataAggiornamento.split('T')[0] : b.DataAggiornamento).getTime() - new Date(a.DataAggiornamento !== null ? a.DataAggiornamento.split('T')[0] : a.DataAggiornamento).getTime();
                    });
                    this.setState({ isAscName: !asc });
                    break;
                case 'impatto':

                    if (asc) {
                        list = rowsTable.sort(function (a, b) {
                            //console.log("a.getRID:",getRID(a.ImpattoR, a.ImpattoI, a.ImpattoD),"b.getRID()",getRID(b.ImpattoR, b.ImpattoI, b.ImpattoD))
                            return getRID([a.ImpattoR, a.ImpattoI, a.ImpattoD]).localeCompare(getRID([b.ImpattoR, b.ImpattoI, b.ImpattoD]));
                        });
                    }
                    else list = rowsTable.sort(function (a, b) {
                        //console.log("a.getRID:",getRID(a.ImpattoR, a.ImpattoI, a.ImpattoD),"b.getRID()",getRID(b.ImpattoR, b.ImpattoI, b.ImpattoD))
                        return getRID([b.ImpattoR, b.ImpattoI, b.ImpattoD]).localeCompare(getRID([a.ImpattoR, a.ImpattoI, a.ImpattoD]));
                    });
                    this.setState({ isAscName: !asc });
                    break;
                case 'impatto_':
                    if (asc) {
                        list = rowsTable.sort(function (a, b) {
                            return Math.max(a.ImpattoR, a.ImpattoI, a.ImpattoD) - Math.max(b.ImpattoR, b.ImpattoI, b.ImpattoD);
                        });
                    }
                    else list = rowsTable.sort(function (a, b) {
                        return Math.max(b.ImpattoR, b.ImpattoI, b.ImpattoD) - Math.max(a.ImpattoR, a.ImpattoI, a.ImpattoD);
                    });
                    this.setState({ isAscName: !asc });
                    break;
                case 'stato':
                    if (asc) {
                        list = rowsTable.sort(function (a, b) { return a.StatoCaratterizzazione.localeCompare(b.StatoCaratterizzazione); });
                    }
                    else list = rowsTable.sort(function (a, b) { return b.StatoCaratterizzazione.localeCompare(a.StatoCaratterizzazione); });
                    this.setState({ isAscName: !asc });
                    break;
                default:
                    list = rowsTable;
                    break;
            }

            this.setState({ rowsTable: list }, () => { this.handlePageChange(this.state.activePage) });

        } catch (error) {
            console.error(error);
        }
    }

    /**
    * 
    */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let rowsTable = this.state.rowsTable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (rowsTable[i])
                listing_table.push(rowsTable[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListServices: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.rowsTable.length / this.state.itemsCountPerPage);
    }

    /**
     * 
     */

    areThereModifiedServices() {
        let servicesList = this.state.services;
        let areThereModifiedServices = false;
        try {

            for (let index = 0; index < servicesList.length; index++) {
                let tsCompilazioneImpatto = new Date(servicesList[index].DataCompilazioneImpatto).getTime();
                let tsUpdateImpatto = new Date(servicesList[index].DataUpdateImpatto).getTime();
                if (tsCompilazioneImpatto > tsUpdateImpatto) {
                    //debugger
                    return true;
                }

            }
            //console.log('areThereModifiedServices? ', areThereModifiedServices);
            return areThereModifiedServices;
        } catch (error) {
            console.error(error);
            return;
        }
    }



    render() {
        var rows = '';
        if (!this.state.currentPageListServices.length) {
            rows = <div className="row">
                {this.state.emptyTest}
            </div>
        } else {
            rows = this.state.currentPageListServices.map(function (field, i) {

                return (
                    <ServiceRow
                        key={i}
                        rowId={i}
                        service={field}
                        categoriesTostring={this.toString(field.Categorie)}
                        typologiesTostring={this.toString(field.Tipologie)}
                        stato={STATI[field.StatoCaratterizzazione]}
                        riepilogo={this.props.riepilogo}
                    />
                );
            }.bind(this)
            );
        }
        /*
        var stateOptions = Object.keys(STATI).map(function (key, i) {
            return (
                <option key={i} value={key}>{STATI[key]}</option>
                );
            });
        */

        /**
        * 
        */
        var typeOption = this.state.typologies.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        var categoryOption = this.state.categories.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";
        var headerClass = this.props.riepilogo === "true" ? "col-lg-9 card-headerLeft nascondiCell" : "col-lg-8 card-headerLeft nascondiCell";
        var classSpazio = this.props.riepilogo === "true" ? "font16 spazio10" : "";

        return (
            <div className={outerClass}>

                <div className={outerClassContainer}>

                    <div hidden={this.props.riepilogo === "true"} className="row">
                        <div className="col-lg-12">

                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/ValutazioneImpatti')}>Valutazione impatti</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Elenco servizi</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Elenco servizi per valutazione impatti</span>
                        </div>
                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="row">
                        <div className="col-lg-12 giustifica">
                            <p>L’elenco contiene i servizi di cui deve essere eseguita la valutazione degli impatti e di cui è già stata eseguita l’analisi del contesto. Selezionare un servizio per ottenere il dettaglio anagrafico con evidenza dell’indicatore del suo impatto R-I-D (Riservatezza, Integrità e Disponibilità), oppure accedere al questionario per completare l’operazione di valutazione.  E’ possibile operare sui filtri di selezione per elencare i soli servizi di proprio interesse. Per agire in modo più mirato è possibile fare riferimento alla sezione “Filtri avanzati”
                            </p>
                            <div className="col-lg-12">
                                <span hidden={!this.areThereModifiedServices()} className="paRight InfoAgg"><i className="fa fa-warning testoRosso"></i> servizio modificato</span>
                            </div>
                        </div>
                    </div>
                    <div hidden={this.props.riepilogo === "true"} className="linea0066cc"></div>
                    <div hidden={this.props.riepilogo === "true"} className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-10"></div>
                            <div className="col-lg-2"> </div>
                        </div>

                    </div>

                    <div className="container cnsContainer">

                        <div className="row">

                            {this.props.riepilogo === "true" && <div className="col-lg-8 spazio30"><div><strong>Elenco servizi valutati:</strong>
                                <a data-bs-toggle="collapse" href="#collapse10"><span className=" testoBlu paRight">
                                    <img src={infoIcon} alt="" /></span></a>
                                <div id="collapse10" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Valutati rispetto al
                            totale dei servizi caratterizzati.</div>
                            </div>	</div>}
                            {this.props.riepilogo !== "true" && <div className="col-lg-8 spazio30"></div>}

                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} />

                            <div className="col-lg-2">

                                <a id='ricerca' className="font16" data-bs-toggle="collapse" href="#collapseRicerca"><i className="fa fa-filter"></i> RICERCA AVANZATA</a>
                            </div>

                            <div id="collapseRicerca" className="col-lg-12 collapse font16" data-parent="#accordion">
                                <div className="spazio10">	</div>

                                <div className="row">

                                    <div className="col-lg-6">
                                        <strong> Ricerca libera:</strong> <br />
                                        <input className="form300" id="search"
                                            value={this.state.searchAll}
                                            type="text"
                                            onChange={this.updateSearchAll} />
                                        <div className="spazio10"></div>

                                    </div>

                                    <div className="col-lg-6">
                                        <strong> Stato dell'analisi:</strong><br />
                                        <select name="select4" id="select4" className="select300"
                                            value={this.state.selectedState}
                                            onChange={this.updateState}>
                                            {/* {stateOptions} */}
                                            <option value='-1'>Tutte</option>
                                            <option value='PARZIALMENTE_VALUTATO'>incompleto</option>
                                            <option value='VALUTATO'>completo</option>
                                        </select>
                                    </div>

                                    <div className="col-lg-6">
                                        <strong> Tipologia:</strong><br />
                                        <select name="select7" id="select7" className="select300"
                                            value={this.state.selectedTypology}
                                            onChange={this.updateTypology}>
                                            <option value='-1'>Tutte</option>
                                            {typeOption}
                                        </select>
                                    </div>

                                    <div className="col-lg-6">
                                        <strong> Categoria:</strong><br />
                                        <select name="select6" id="select6" className="select300"
                                            value={this.state.selectedCategory}
                                            onChange={this.updateCategory}>
                                            <option value='-1'>Tutte</option>
                                            {categoryOption}
                                        </select>
                                    </div>
                                </div>

                                <div className="spazio10"></div>
                                <hr />

                            </div>

                            <div className={headerClass}><strong onClick={() => this.orderServicesList('nome')} style={{ cursor: 'pointer' }}> Servizio
                            <i className={(this.state.selectedColumn.localeCompare("nome") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </strong></div>
                            <div className="col-pixel-width-100 card-headerCenter nascondiCell">
                                <strong onClick={() => this.orderServicesList('data')} style={{ cursor: 'pointer' }}> Aggiornato
                            <i className={(this.state.selectedColumn.localeCompare("data") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                            <div className="col-lg-1 card-headerCenter nascondiCell">
                                {/* <strong onClick={() => this.orderServicesList('impatto')} style={{ cursor: 'pointer' }}>R | I | D
                            <i className={(this.state.selectedColumn.localeCompare("impatto") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong> */}
                                <strong>R | I | D</strong>
                                </div>

                            {this.props.riepilogo !== "true" && <div className="col-lg-1 card-headerCenter nascondiCell">
                                <strong onClick={() => this.orderServicesList('stato')} style={{ cursor: 'pointer' }}> Analisi
                                <i className={(this.state.selectedColumn.localeCompare("stato") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong>
                            </div>}

                            <div className="col-pixel-width-100 card-headerCenter nascondiCell"><strong> Azioni</strong></div>

                        </div>

                        <span className="lineaGrigiaT0"></span>
                        <div className={classSpazio}>
                            {rows}
                        </div>

                        <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.rowsTable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                </div>
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                <br />
                <br />
                <br />
            </div>
        )
    }
}