import * as React from 'react';
import { Util } from "../data/Util";


// component that renders a single user
export class UserComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category
        };
        this.toString = this.toString.bind(this);
    }

    componentDidMount() { console.log("UserComponent", this.props) }

    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            toString += list[index].Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    /**
             * 
             * @param {*} rischioAggregato 
             * @param {*} array 
             */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.user.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {

        const isUpdate = this.props.isUpdate;

        return (

            <div className="col-lg-12" >

                {isUpdate ? (
                    <div className="row card-headerLight">
                        <div className="col-lg-12"><strong>Codice Fiscale utente</strong>
                            <input className="form100" type="text" id={'code'}
                                value={this.props.userCode}
                                disabled={true}
                            />
                        </div>
                        <br /><br />
                        <div className="col-lg-6">
                            <br />
                            <strong>Nome utente *</strong>
                            <input type="text" className="form100" id={"name"}
                                onChange={(e) => this.props.updateUserInfo(e.target.value, "name")}
                                value={this.props.name}
                                placeholder={'Nome utente'}
                            />
                        </div>
                        <div className="col-lg-6">
                            <br />
                            <strong>Cognome utente *</strong>
                            <input type="text" className="form100" id={"surname"}
                                onChange={(e) => this.props.updateUserInfo(e.target.value, "surname")}
                                value={this.props.surname}
                                placeholder={"Cognome utente"}
                            />
                        </div>                       
                        <br /><br />
                    </div>
                ) : (
                        <div className="row card-headerLight">
                            <div className="col-lg-12"><strong>Codice Fiscale utente *</strong>
                                <br /><br />
                                <input className="form100" type="text"
                                    id={'code'}
                                    onChange={(e) => this.props.updateUserInfo(e.target.value, "code")}
                                    placeholder={'Codice fiscale'}
                                />
                            </div>
                            <div className="col-lg-6">
                                <br />
                                <strong>Nome utente *</strong>
                                <input type="text" className="form100"
                                    onChange={(e) => this.props.updateUserInfo(e.target.value, "name")}
                                    value={this.state.userName}
                                    placeholder={'Nome utente'}
                                />
                            </div>
                            <div className="col-lg-6">
                                <br />
                                <strong>Cognome utente *</strong>
                                <input type="text" className="form100"
                                    onChange={(e) => this.props.updateUserInfo(e.target.value, "surname")}
                                    value={this.state.userSurname}
                                    placeholder={"Cognome utente"}
                                />
                            </div>
                            <br />
                            <br />                           
                        </div>
                    )
                }
            </div>
        )
    }
};