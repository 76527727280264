import React, { Component } from 'react';
import history from '../history';
import HeaderBanner from './HeaderBanner'
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { slide as Menu } from 'react-burger-menu'

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userLastname: '',
      nomePA: '',
      user: {},
      isVisible: 'hidden',
      sidebarOpen: false
    };

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);
    this.redirectMenu = this.redirectMenu.bind(this);
    this.handleCloseSidebar = this.handleCloseSidebar.bind(this);
    this.handleOpenSidebar = this.handleOpenSidebar.bind(this);
  }

  componentDidMount() {
  }

  handleOpenSidebar(){
    this.setState({sidebarOpen: !this.state.sidebarOpen});
  }

  handleCloseSidebar(){
    this.setState({sidebarOpen: false});
  }
  /**
   * 
   */
  isAuthenticated() {
    return this.props.auth.isAuthenticated();
  }

  hasFunction(funzione) {
    return this.props.auth.hasFunction(funzione);
  }

  logout() {
    this.props.auth.logout();
  }

  redirectMenu(path) {
    try {
      if (path.indexOf('/') === 0) {
        history.push(path);
      } else if (path.indexOf('/') === -1) {
        history.push('/' + path);
      }
    } catch (error) {
      return console.error(error)
    }
  }

  render() {

    //stile menù
    let voceMenu = {
      fontSize: '0.9em',
      marginLeft: '5px',
    }
    let styles = {

      bmBurgerButton: {
        position: 'fixed',
        width: '36px',
        height: '30px',
        top: '12px',
        left: '1px'
      },
      bmBurgerBars: {
        background: '#1A202E'
      },
      bmBurgerBarsHover: {
        background: '#1A202E'
      },
      bmCrossButton: {
        height: '24px',
        width: '24px'
      },
      bmCross: {
        background: '#bdc3c7'
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%'
      },
      bmMenu: {
        background: '#1A202E',
        padding: '3em 1em 0.5em 0em',
        fontSize: '1.8em'
      },
      bmMorphShape: {
        fill: '#373a47'
      },
      bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        fontSize: '1em'
      },
      bmItem: {
        cursor: 'pointer',
        display: 'inline-block',
        paddingTop: '1em',
        width: '100%',
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
      }
    }

    var colorHome = "#FFF";
    if (!this.isAuthenticated()) {
      $("#menuTool").attr({ 'style': 'display:none !important;' });

    }
    else {
      colorHome = "#ffc107";
    }
    return (
      <div>

        <header className="Header  u-hiddenPrint">

          <HeaderBanner auth={this.props.auth} />

          <Menu styles={styles}
            onClose={this.handleCloseSidebar}
            onOpen={this.handleOpenSidebar}
            isOpen={this.state.sidebarOpen}>

              <a className="menu-item" onClick={() => history.push(API_CONFIG.HomePath)}>
                <i className="fa fa-home"></i>&nbsp;
                Home</a>
            
            {
              this.isAuthenticated() &&
              this.hasFunction("AR_Visualizza") &&
              <div className="menu-item" data-bs-toggle="collapse" href="#collapseRegistroApplicazioni">
                <a><i className="fa fa-caret-down"></i>&nbsp;Registro Applicazioni</a>
                {this.hasFunction("AR_Visualizza") &&
                  <div className="col-lg-12"><span className="collapse" id="collapseRegistroApplicazioni">
                    <a style={voceMenu} href="#" onClick={e => {this.handleCloseSidebar(); history.push("/RegistroApplicazioniElenco")}}>Visualizza Registro</a>
                  </span></div>
                }
              </div>
            }

            {
              this.isAuthenticated() &&
              this.hasFunction("AR_Visualizza") &&
              <div className="menu-item" data-bs-toggle="collapse" href="#collapseCompliance">
                <a><i className="fa fa-caret-down"></i>&nbsp;Compliance Self-Assessment</a>
                {this.hasFunction("AR_Visualizza") &&
                  <div className="col-lg-12"><span className="collapse" id="collapseCompliance">
                    <a style={voceMenu} href="#" onClick={e => {this.handleCloseSidebar(); history.push("/ListaApplicazioniGapAnalysis")}}>Analisi per Applicazione</a>
                  </span></div>
                }
              </div>
            }

            {
              this.isAuthenticated() &&
              this.hasFunction("AR_Visualizza") &&
              <div className="menu-item" data-bs-toggle="collapse" href="#collapseRemediation">
                <a><i className="fa fa-caret-down"></i>&nbsp;Remediation Plans</a>
                {this.hasFunction("AR_Visualizza") &&
                  <div className="col-lg-12" className="collapse" id="collapseRemediation">
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ElencoApplicazioniPlanning")}}>Planning</a>
                    </div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ElencoApplicazioniExecute")}}>Execute</a>
                    </div>
                </div>
                }
              </div>
            }

            {
              this.isAuthenticated() &&
              this.hasFunction("AR_Visualizza") &&
              <div className="menu-item" data-bs-toggle="collapse" href="#collapseReporting">
                <a><i className="fa fa-caret-down"></i>&nbsp;Reporting & Monitoring</a>
                {this.hasFunction("AR_Visualizza") &&
                  <div className="col-lg-12" className="collapse" id="collapseReporting">
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ElencoApplicazioniReport")}}>Risultati per Applicazione</a>
                    </div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/MonitoraggioGAPAnalysis")}}>Monitoraggio Gap Analysis</a>
                    </div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ElencoApplicazioniMonitoring")}}>Monitoraggio Remediation Plan</a>
                    </div>
                  </div>
                }
              </div>
            }

            {
              this.isAuthenticated() &&
              (this.hasFunction("GU_Modifica") || this.hasFunction("GL_Modifica") || this.hasFunction("AC_Visualizza") || this.hasFunction("CN_Modifica")) &&
              <div className="menu-item" data-bs-toggle="collapse" href="#collapseConfigurazione">
                <a><i className="fa fa-caret-down"></i>&nbsp;Configurazione</a>

                <div className="col-lg-12" className="collapse" id="collapseConfigurazione">
                {this.hasFunction("GU_Modifica") &&
                    <div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/GestioneUtenti")}}>Gestione Utenti</a>
                    </div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/UtentiElenco")}}>Elenco Utenti</a>
                    </div></div>
                }

                {this.hasFunction("AC_Visualizza") &&
                    <div>
                    <br/>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ListaApplicazioniCensimento")}}>Elenco applicazioni</a>
                    </div>
                    {this.hasFunction("AC_NuovoServizio") && 
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/NuovaApplicazione")}}>Nuova Applicazione</a>
                    </div>}</div>
                }

                <br/>
                {this.hasFunction("CN_Modifica") &&
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ContestoNormativo")}}>Contesto Normativo</a>
                    </div>}

                    {this.hasFunction("GL_Modifica") && 
                    <div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/AggiornaLibrary")}}>Aggiornamento Library</a>
                    </div>
                    <div className="col-lg-12">
                      <a style={voceMenu} href="#" onClick={ e => {this.handleCloseSidebar(); history.push("/ImportaDati")}}>Importazione Dati</a>
                    </div></div>
                    }
                </div>
              </div>
            }

          </Menu>

          {
          <div className="Header-navbar ">
            <div className="row u-layout-wide Grid Grid--alignMiddle u-layoutCenter">
              <div className='col-lg'><h1>
                Data Source & Regulation Assessment Tool
              </h1>
              </div>
              <h3><p>Tool di valutazione del livello di conformità (sicurezza & privacy)</p></h3>
            </div>
          </div>}

        </header>
      </div>
    );
  }
}

