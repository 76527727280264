const axios = require('axios');
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';

export default class ApiCalls {

    //check token iam
    static async loginIam(iamToken) {
        let response;
        try {
            response = await axios.post(API_CONFIG.loginIAM,
                { token: iamToken },
                { headers: {} });

            return response.data;
        }
        catch (error) {
            throw error;
        }
    }

    static async getAssessmentcompositeRiepilogoHome() {

        let response;
        try {
            response = await axios.post(API_CONFIG.assessmentcompositeRiepilogoHome,
                {},
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data;
        }
        catch (error) {
            throw error;
        }
    }

    static async getListAssessmentTrattamento() {

        let response;
        try {
            response = await axios.post(API_CONFIG.listAssessmentTrattamento,
                {},
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data;
        }
        catch (error) {
            throw error;
        }
    }

    static async getImportDatiByNomeAsset(NomeAsset) {

        let response;
        let bodyParameters = {
            NomeAsset: NomeAsset
        };

        try {
            response = await axios.post(API_CONFIG.getImportDatiByNomeAsset,
                bodyParameters,
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data
        }
        catch (error) {
            throw error;
        }
    }

    static async getSelfAssessmentById(AssessmentId) {

        let response;
        let bodyParameters = {
            AssessmentId: AssessmentId
        };

        try {
            response = await axios.post(API_CONFIG.getSelfAssessmentById,
                bodyParameters,
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data
        }
        catch (error) {
            throw error;
        }
    }


    static async getUtenteByCodiceFiscale(cf) {
        let response;
        try {
            response = await axios.post(API_CONFIG.getUserById,
                { CodiceFiscale: cf },
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data
        }
        catch (error) {
            throw error;
        }
    }

    static async updateAlertAggiornamentoLibreriaUtente() {
        let response;
        try {
            response = await axios.post(API_CONFIG.updateAlertAggiornamentoLibreriaUtente,
                {},
                { headers: { token: sessionStorage.getItem('token') } });

            return response.data.data
        }
        catch (error) {
            throw error;
        }
    }

    static redirect(path, state) {
        console.log(path)
        try {
            if (path !== undefined) {
                if (path.indexOf('/') === 0)
                    history.push({ pathname: path, state: state });
                else if (path.indexOf('/') === -1) {
                    history.push({ pathname: '/' + path, state: state });
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }

}