import * as React from 'react';
// component that renders a single user
//window.ProductRow = React.createClass({
export class TemaRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /*
            defaultChecked0: this.props.field[0].value,
            defaultChecked1: (this.props.length > 1) ? this.props.field[1].value : '',
            defaultChecked2: (this.props.length > 2) ? this.props.field[2].value : '',
            defaultChecked3: (this.props.length > 3) ? this.props.field[3].value : '',
            */
        };
        this.changeAppMode = this.changeAppMode.bind(this);
        this.updateTema = this.updateTema.bind(this);
    }

    changeAppMode(event) {
        //console.log('value', event.target.value);
        this.setState({ value: parseInt(event.target.value, 10) });
    }

    updateTema(event, index) {
        //debugger
        //console.log('event:', event);
        this.props.updateTema(this.props.rowId, index);
    }

    render() {

        //var isHidden = (this.props.field1.value.length === 0 && this.props.field1.nome.length === 0) ? 'none' : '';
        var id = 'checkbox' + this.props.rowId + '_';
        let services = [];
        let element = ''
        for (let index = 0; index < this.props.field.length; index++) {
            if (this.props.readOnly) {
                if (this.props.field[index].value) {
                    element = <span key={this.props.rowId + index}>< i className=" fa fa-check-square-o" ></i><strong>{" " + this.props.field[index].Nome}</strong><br /></span>;
                } else {
                    element = <span className=" testoGrigio" key={this.props.rowId + index}>{this.props.field[index].Nome}<br /></span>;
                }
                services.push(element);

            } else {
                element = <label key={this.props.rowId + index}>
                    <input
                        id={id + index}
                        type='checkbox'
                        //defaultChecked={this.props.field[index].value}
                        checked={this.props.field[index].value}
                        onChange={(e) => this.updateTema(e, index)} />
                    {" " + this.props.field[index].Nome}</label>
                services.push(element);
                services.push(<br key={'br' + this.props.rowId + index} />);
            }
            services.push(<hr key={'hr' + this.props.rowId + index} />);
        }
        return (
            <div className="col-lg-6">
                <div>
                    {services}
                </div>
            </div>
        );
    }
};