import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';
/*import { Breadcrumb } from '../Common/Breadcrumb';


const LINK = [
    { nome: "", url: "/" }
];*/

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

export class ValutazioneImpatti extends Component {

    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/ValutazioneImpatti')}>Valutazione impatti</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Valutazione impatti</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>Consente di individuare i possibili impatti (danni di tipo economico, operativo, reputazionale e legale) che il  realizzarsi di una minaccia potrebbe comportare per la  Azienda. <br />
                                    Viene valutato il livello di danno che potrebbe derivare dalla perdita dei riservatezza, integrità e disponibilità delle informazioni gestite all’interno del Servizio. </p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ListaServiziImpatti')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ELENCO SERVIZI</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Accesso alla lista dei servizi della Azienda corrente per i quali si è provveduto precedentemente ad effettuarne l’analisi del contesto per i suoi attributi di sicurezza Cyber. <br />
                                Selezionando il servizio di interesse sarà possibile procedere con la compilazione del questionario di valutazione degli impatti R-I-D.
  <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/RiepilogoImpatti')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">RIEPILOGO DATI</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Il riepilogo dello stato attuale della valutazione impatti, fornisce informazioni circa l’impatto R-I-D (Riservatezza, Integrità, Disponibilità) generale e dello stato di compilazione dei questionari, calcolati basandosi sui dati inseriti dall’utente in fase di valutazione degli impatti R-I-D dei singoli servizi della Azienda corrente.
  <hr />

                            </div>


                        </div></div>



                    <br /><br /><br /><br /><br /><br /><br />







                </div>







            </div>
        )
    }
}