import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { ServizioRow } from './ServizioRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import LIVELLO from '../data/criticality';
import { SearchInput } from '../Common/SearchInput';
import { isNullOrUndefined } from 'util'; 

let messageError = '';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

const LINK = [
    { nome: "", url: "/" },
];

export class ElencoServiziReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listAssessmentTrattamento: [],
            listAssessmentFilterable: [],
            selectedTypeAssessment: 'Servizio',
            search: sessionStorage.getItem('searchRep') !== null ? sessionStorage.getItem('searchRep') : '',
            searchAll: sessionStorage.getItem('searchAllRep') !== null ? sessionStorage.getItem('searchAllRep') : '',
            selectedDateStart: '',
            selectedDateEnd: '',
            //selectedDateStart: sessionStorage.getItem('dateStart') !== null ? sessionStorage.getItem('dateStart') : '',
            //selectedDateEnd: sessionStorage.getItem('dateEnd') !== null ? sessionStorage.getItem('dateEnd') : '',
            isAscName: false,
            activePage: 1,
            currentPageListAssessment: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...'
        };
        this.getlistAssessmentTrattamento = this.getlistAssessmentTrattamento.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        //
        this.numPages = this.numPages.bind(this);
        //ORDER
        this.orderServicesList = this.orderServicesList.bind(this);
        //UPDATE
        this.updateListAssessmentTrattamento = this.updateListAssessmentTrattamento.bind(this);
        this.updateTypeAssessment = this.updateTypeAssessment.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.updateDateStart = this.updateDateStart.bind(this);
        this.updateDateEnd = this.updateDateEnd.bind(this);
        //TOSTRNG
        this.toString = this.toString.bind(this);
    }

    componentDidMount() {
        try {


            this.serverRequest =
                $.ajax({
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/listAssessmentTrattamento',
                    url: API_CONFIG.listAssessmentTrattamento,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        console.log('response:', response);
                        this.setState({ data: response.data });
                        /*
                        this.setState({ listAssessmentTrattamento: response.data });
                        this.setState({ listAssessmentFilterable: response.data }, this.updateListAssessmentTrattamento);
                        */
                        this.setState({ listAssessmentTrattamento: this.getlistAssessmentTrattamento(response.data) });
                        this.setState({ listAssessmentFilterable: this.getlistAssessmentTrattamento(response.data) }, this.updateListAssessmentTrattamento);

                        this.setState({ emptyTest: "Nessuna Applicazione con i criteri specificati" });
                        //this.updateListAssessmentTrattamento();
                        this.handlePageChange(1);
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } 
                            else
                             this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });

            if (sessionStorage.getItem('typeAssessmentRep') !== null ||
                sessionStorage.getItem('dateStartRep') !== null ||
                sessionStorage.getItem('dateEndRep') !== null ||
                sessionStorage.getItem('searchRep') !== null ||
                sessionStorage.getItem('searchAllRep') !== null)
                $("#ricerca").get(0).click();
        } catch (error) {
            return console.error(error);
        }
    }

    /**
    * 
    */
    getlistAssessmentTrattamento(listAssessmentTrattamento) {
        let newListAssessmentTrattamento = []
        try {

            for (let index = 0; index < listAssessmentTrattamento.length; index++) {
                const element = listAssessmentTrattamento[index];
                if (element.StatoAssessmentId !== -1) {
                    //if (element.Referente)
                        //if (element.ServizioDescrizione)
                            //if (element.StrutturaReferente)
                                newListAssessmentTrattamento.push(element)
                }
            }
            return newListAssessmentTrattamento;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
    * 
    */
    updateListAssessmentTrattamento() {
        let listAssessment = this.state.listAssessmentTrattamento;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedTypeAssessment = this.state.selectedTypeAssessment;
        let selectedDateStart = this.state.selectedDateStart;
        let selectedDateEnd = this.state.selectedDateEnd;
        const dateStart = new Date(selectedDateStart).getTime();
        const dateEnd = new Date(selectedDateEnd).getTime();
        try {
            var list = [];
            if (search === '' && selectedTypeAssessment === '-1' && selectedDateStart === '' && selectedDateEnd === '') {
                list = listAssessment;
            } else {

                for (let index = 0; index < listAssessment.length; index++) {
                    const element = listAssessment[index];

                    element.Referente = element.Referente === null ? '' : element.Referente;
                    element.StrutturaReferente = element.StrutturaReferente === null ? '' : element.StrutturaReferente;
                    element.ServizioDescrizione = element.ServizioDescrizione === null ? '' : element.ServizioDescrizione;

                    let dataCreazione = new Date(element.DataCreazione.split('T')[0]);
                    //console.log('assessment', element);
                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente !== null && element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente !== null && element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.ServizioDescrizione !== null && element.ServizioDescrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {

                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                            if (selectedDateStart === '' || selectedDateEnd === '' || dateStart <= dataCreazione.getTime() && dataCreazione.getTime() <= dateEnd) {
                                if (selectedTypeAssessment === '-1' || ASSESSMENT_TYPE[element.TipoAssessment].toUpperCase().indexOf(selectedTypeAssessment.toUpperCase()) > -1) {
                                    list.push(element);
                                }
                            }
                        }
                    }
                }
            }
            //this.setState({ listAssessmentFilterable: list });
            this.setState({ listAssessmentFilterable: list }, () => { this.handlePageChange(1) });

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * @param {*} event 
     */
    updateTypeAssessment(event) {
        let type = event.target.value;
        sessionStorage.setItem('typeAssessmentRep', type);
        this.setState({ selectedTypeAssessment: type }, this.updateListAssessmentTrattamento);
    }

    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchRep', val);
        this.setState({ search: val }, this.updateListAssessmentTrattamento);
    }

    /**
         * 
         */
    updateSearchAll(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllRep', val);
        this.setState({ searchAll: val }, this.updateListAssessmentTrattamento);
    }

    updateDateStart(e) {
        //console.log(e.target.value);//2018-08-01
        let date = e.target.value;
        var dateParse = new Date(date);
        sessionStorage.setItem('dateStartRep', date);
        this.setState({ selectedDateStart: date }, this.updateListAssessmentTrattamento);
    }

    updateDateEnd(e) {
        //console.log(e.target.value);//2018-08-31
        let date = e.target.value;
        var dateParse = new Date(date);
        sessionStorage.setItem('dateEndRep', date);
        this.setState({ selectedDateEnd: date }, this.updateListAssessmentTrattamento);
    }

    /**
    * 
    * @param {*} list 
    */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
    * 
    */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let listAssessmentFilterable = this.state.listAssessmentFilterable;
        switch (key) {
            case 'nome':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });
                }
                else list = listAssessmentFilterable.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                //this.setState({ isAscName: !asc });
                break;
            case 'data':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) {
                        return new Date(a.DataCreazione !== null ? a.DataCreazione.split('T')[0] : a.DataCreazione).getTime() - new Date(b.DataCreazione !== null ? b.DataCreazione.split('T')[0] : b.DataCreazione).getTime();
                    });

                } else
                    list = listAssessmentFilterable.sort(function (a, b) {
                        return new Date(b.DataCreazione !== null ? b.DataCreazione.split('T')[0] : b.DataCreazione).getTime() - new Date(a.DataAggiornamento !== null ? a.DataCreazione.split('T')[0] : a.DataCreazione).getTime();
                    });//this.setState({ isAscName: !asc });
                break;
            case 'criticità':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) { return a.Criticità - b.Criticità; });
                }
                else list = listAssessmentFilterable.sort(function (a, b) { return b.Criticità - a.Criticità; });
                //this.setState({ isAscName: !asc });
                break;
            case 'stato':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) { return a.StatoAssessmentId.localeCompare(b.StatoAssessmentId); });
                }
                else list = listAssessmentFilterable.sort(function (a, b) { return b.StatoAssessmentI.localeCompare(a.StatoAssessmentId); });
                //this.setState({ isAscName: !asc });
                break;
            case 'rischio':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) {
                        let levelA = (!isNullOrUndefined(a.RischioAggregato) && (a.RischioAggregato >= 0) && (a.RischioAggregato < LIVELLO.length)) ? LIVELLO[a.RischioAggregato].name : "";
                        let levelB = (!isNullOrUndefined(b.RischioAggregato) && (b.RischioAggregato >= 0) && (b.RischioAggregato < LIVELLO.length)) ? LIVELLO[b.RischioAggregato].name : "";
                        return levelA.localeCompare(levelB);
                    });
                }
                else list = listAssessmentFilterable.sort(function (a, b) {
                    let levelA = (!isNullOrUndefined(a.RischioAggregato) && (a.RischioAggregato >= 0) && (a.RischioAggregato < LIVELLO.length)) ? LIVELLO[a.RischioAggregato].name : "";
                    let levelB = (!isNullOrUndefined(b.RischioAggregato) && (b.RischioAggregato >= 0) && (b.RischioAggregato < LIVELLO.length)) ? LIVELLO[b.RischioAggregato].name : "";
                    return levelB.localeCompare(levelA);
                });
                break;
            default:
                list = listAssessmentFilterable;
                break;
        }

        this.setState({ listAssessmentFilterable: list }, () => { this.handlePageChange(this.state.activePage) });
        this.setState({ isAscName: !asc });
    }

    /**
          * 
          */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let listAssessment = this.state.listAssessmentFilterable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (listAssessment[i])
                listing_table.push(listAssessment[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListAssessment: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.listAssessmentTrattamento.length / this.state.itemsCountPerPage);
    }

    render() {

        let rows = '';
        if (!this.state.currentPageListAssessment.length) {
            rows = <div className="row"> {this.state.emptyTest}</div>
        } else {
            rows = this.state.currentPageListAssessment.map(function (remedy, i) {
                return (
                    <ServizioRow
                        key={i}
                        rowId={i}
                        readOnly={true}
                        remedy={remedy}
                        categoriesTostring={this.toString(remedy.Categorie)}
                        typologiesTostring={this.toString(remedy.Tipologie)}
                    />
                );
            }.bind(this)
            );
        }

        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} >Reporting e Monitoring</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Risultati per Applicazione</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Risultati per Applicazione</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>
                            Monitoraggio del livello &lt;&lt;AS-IS&gt;&gt; di compliance delle applicazioni. <br/>
                            La pagina fornisce l’elenco delle applicazioni la cui ‘Analisi’ si trova nello stato ‘pubblicato’ o ‘completo’ (applicazioni per le quali è stato eseguito, almeno una volta, il processo di Gap Analysis). Selezionare una applicazione per ottenere il dettaglio sugli indicatori di profilo di criticità dell’applicazione, oppure accedere alla pagina della gap analysis per quella applicazione. E’ possibile operare sui filtri di  selezione per elencare le sole analisi di proprio interesse.

                            </p>
                        </div>

                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-10"></div>

                            <div className="col-lg-2">

                            </div>

                        </div>
                    </div>

                    <div className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-7 spazio30"></div>

                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} />

                            <div className="col-lg-2">
                                <a id='ricerca' className="font16" data-bs-toggle="collapse" href="#collapseRicerca"><i className="fa fa-filter"></i>    RICERCA AVANZATA</a>
                            </div>

                            <div id="collapseRicerca" className="col-lg-12 collapse font16" data-parent="#accordion">
                                <div className="spazio10">	</div>

                                <div className="row">

                                    <div className="col-lg-6">
                                        <strong> Ricerca libera:</strong> <br />
                                        <input className="form300"
                                            type="text"
                                            onChange={this.updateSearchAll}
                                            value={this.state.searchAll}
                                        />
                                        <div className="spazio10"></div>

                                    </div>
                                    <div className="col-lg-6">
                                        {/*
                                        <strong> Tipologia:</strong><br />
                                        <select name="select7" id="select7" className="select300">
                                            <option value="Tutte">Tutte</option>
                                            <option value="Servizio">Servizio</option>
                                            <option value="Pubblica">Pubblica amministrazione</option>
                                        </select>
                                    */}
                                    </div>


                                    <div className="col-lg-6">
                                        <strong> Filtra per data:</strong> <br />
                                        da <input type="date" value={this.state.selectedDateStart} onChange={this.updateDateStart} />
                                        a <input type="date" value={this.state.selectedDateEnd} onChange={this.updateDateEnd} />
                                    </div>
                                </div>

                                <div className="spazio10"></div>
                                <hr />

                            </div>

                            <div className="col-lg-8 card-headerLeft nascondiCell"><strong onClick={() => this.orderServicesList('nome')} style={{ cursor: 'pointer' }}>Applicazione</strong></div>
                            <div className="col-lg-2 card-header nascondiCell"><strong onClick={() => this.orderServicesList('data')} style={{ cursor: 'pointer' }}>Data creazione Gap Analysis</strong></div>

                            <div className="col-lg-1 card-header nascondiCell "><strong onClick={() => this.orderServicesList('rischio')} style={{ cursor: 'pointer' }}>IUC</strong></div>

                            <div className="col-lg-1 card-header nascondiCell"><strong> Azioni</strong></div>



                        </div>
                        {rows}

                        <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.listAssessmentFilterable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                    <br />
                    <br />
                    <br />
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
                </div>
            </div >
        )
    }
}
