import React, { Component } from 'react';

export class ChoiceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //domainsImportList: this.props.domainsImportList,
            //domainsImportList: [],
            toggle: false,

        }

        this.toggle = this.toggle.bind(this);
        this.onClick = this.onClick.bind(this);
        this.updateAssessmentImport = this.updateAssessmentImport.bind(this);
        this.updateDomainImport = this.updateDomainImport.bind(this);
    }

    componentDidMount() { /*console.log("props", this.props);*/ }

    toggle(event) {
        this.setState(prevState => ({
            toggle: !prevState.toggle
        }));
    }

    /**
     * 
     */
    getDomains() {
        try {
            let index = this.props.assessmentImportList.findIndex(a => a.AssessmentId === this.props.assessmentImportSelected);
            let domainImport = [];
            if (index !== -1) {
                domainImport = this.props.assessmentImportList[index].domini;
            }
            return domainImport;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    onClick(event) {
        alert("reset");
    }

    /**
     * 
     */
    updateAssessmentImport(event) {
        this.props.updateAssessmentImport(parseInt(event.target.value, 10));
    }

    /**
     * 
     * @param {*} event 
     */
    updateDomainImport(event) {
        let id = parseInt(event.target.value, 10);
        let domain;
        if (id === -1) {
            this.props.updateDomainImport(id, event.target.checked)
        } else {
            let domainIndex = this.props.domainsImportList.findIndex(d => d.CategoriaMisuraDiSicurezzaId === id);
            if (domainIndex !== -1) {
                domain = this.props.domainsImportList[domainIndex];
                //domain = this.state.domainsImportList[domainIndex]=event.target.checked;
                this.props.updateDomainImport(domain, event.target.checked)
            }
        }
    }

    /**
     * 
     */
    _updateDomainImport(event) {
        let id = parseInt(event.target.value, 10);
        let domain;
        if (id === -1) {
            this.props.updateDomainImport(id, event.target.checked)
        } else {
            let index = this.props.assessmentImportList.findIndex(a => a.AssessmentId === this.props.assessmentImportSelected);
            let domainIndex = this.props.assessmentImportList[index].domini.findIndex(d => d.CategoriaMisuraDiSicurezzaId === id);
            if (index !== -1) {
                if (domainIndex !== -1) {
                    domain = this.props.assessmentImportList[index].domini[domainIndex];
                    //domain = this.state.domainsImportList[domainIndex]=event.target.checked;
                    this.props.updateDomainImport(domain, event.target.checked)
                }
            }
        }
    }

    render() {

        let text = (this.props.domainsImportList.length > 0) ? "Selezionare i Domini di interesse:" : "Nessun dominio da selezionare";

        let indexOfHimself = this.props.indexOfHimself;
        let options = this.props.assessmentImportList.map(function (assessment, index) {
            let assessmentName = assessment.Nome;
            if (index === indexOfHimself) {
                assessmentName += " (questo servizio)";
            }
            return (
                <option value={assessment.AssessmentId} key={index}>{assessmentName}</option>);
        });

        let dropdownItem = this.props.domainsImportList.map(function (domain, index) {
            //console.log('dropdownItem', domain);
            return (
                // <a className="dropdown-item form100" style={{ fontSize: "large" }}>
                <div key={"dropdownItem" + index}>
                    <a className=" form100" style={{ fontSize: "large", border: "none" }}>
                        <label>
                            <input type="checkbox"
                                value={domain.CategoriaMisuraDiSicurezzaId}
                                onChange={(e) => this.updateDomainImport(e)}
                                checked={domain.isChecked} />
                            &nbsp;&nbsp;{domain.Nome}
                        </label>
                    </a>
                    <br />
                </div>
            );
        }.bind(this)
        );

        var modal = [];
        modal.push(
            <div className="modal fade" id="modal1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" key="modal1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header ">
                            <h2 className="modal-title testoArancio" id="exampleModalLabel">
                                {/* <i className="fa fa-warning"></i> */}
                                {"Importazione risposte Questionario"}</h2>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={() => this.props.domainsImportReset()}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span hidden={this.props.isImport}> Si desidera importare le risposte da una precedente GAP Analisys?</span>
                            <br />
                            <div hidden={!this.props.isImport}>
                                Selezionare l'Applicazione:
                            <select className={'form100'}
                                    value={this.props.assessmentImportSelected}
                                    onChange={this.updateAssessmentImport}>
                                    {options}
                                </select>

                                <br />
                                <br />
                                {text}
                                <br />
                                {
                                    (this.props.domainsImportList.length > 0)
                                        ?
                                        (<div className="scrollable-menu form100" style={{ border: "none" }} aria-labelledby="dropdownMenuButton">
                                            <a className=" form100" style={{ fontSize: "large", border: "none" }}>
                                                <label>
                                                    <input type="checkbox"
                                                        value={-1}
                                                        onChange={(e) => this.updateDomainImport(e)}
                                                        checked={this.props.selectAlls} />
                                                    &nbsp;&nbsp;Seleziona tutti
                                                </label>
                                            </a>
                                            <br />
                                            {dropdownItem}
                                        </div>
                                        ) : (<div />)
                                }

                                <br />

                            </div>
                        </div>
                        <div hidden={this.props.isImport} className="modal-footer justify-content-center">
                            <a href="#">
                                <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal" onClick={() => this.props.iniziaAssessment(false)}>NO</button>{" "}
                                <button type="button" className="btn btn-successOR btn-lg" onClick={() => this.props.updateIsImport(true)}>SI</button>
                            </a>
                        </div>
                        {/* <div hidden={!this.props.isImport}>
                            Nel caso di importazione dati dallo stesso servizio, verrà ereditato anche l'eventuale piano di trattamento
                           </div> */}
                        <div hidden={!this.props.isImport} className="modal-footer justify-content-center">
                            <a href="#">
                                <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal" onClick={() => this.props.updateIsImport(false)}>ANNULLA</button>{" "}
                                <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={() => this.props.iniziaAssessment(true)}>CONFERMA</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <div>
                <a>
                    <button type="button" hidden id='btnHidden'
                        data-bs-toggle="modal" data-bs-target="#modal1"
                        className="btn btn-warning btn-lg btn-block"></button>
                </a>
                {modal}
            </div>
        );
    }
}

