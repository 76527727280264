import * as React from 'react';
import { RiskAppetiteRow } from './RiskAppetiteRow';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';

let messageError = '';

const LINK = [
    { nome: "", url: "/" }
];


export class RiskAppetite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            data: undefined,
            pa: this.props.PA !== undefined ? "true" : ""
        };
        this.updateRiskAppetite = this.updateRiskAppetite.bind(this);
        this.save = this.save.bind(this);
        this.ripristina = this.ripristina.bind(this);
        //this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
    }

    updateRiskAppetite(tipologiaId, categoriaId, value) {
        let data = this.state.data;
        for (let index = 0; index < data.RiskAppetiteList.length; index++) {
            if (data.RiskAppetiteList[index].TipologiaImpattoId === tipologiaId &&
                data.RiskAppetiteList[index].CategoriaServizioId === categoriaId) {
                data.RiskAppetiteList[index].RiskAppetite = value;
            }
        }
        this.setState({ data: data });

    }

    componentDidMount() {

        this.serverRequest =
            $.ajax({
                //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getRiskAppetiteList',
                url: API_CONFIG.assessmentcompositeGetRiskAppetiteList,
                type: "POST",
                contentType: 'application/json',
                //data: JSON.stringify({ AssessmentId: 74 }),
                data: JSON.stringify({ AssessmentId: sessionStorage.getItem('assessmentId') }),
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    console.log("response", response);
                    this.setState({ data: response.data });
                    //this.setState({ nome: response.data.Nome });
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
    }

    save(e) {
        var pa = this.state.pa;
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/updateRiskAppetite',
            url: API_CONFIG.assessmentcompositeUpdateRiskAppetite,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            //data: JSON.stringify(form_data),
            //data: JSON.stringify(this.state.opzioni),
            data: JSON.stringify(this.state.data),
            cache: false,
            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response) {
                console.log(response);
                if (response.status === 'success') {
                    //location.reload();
                    let path = pa === "true" ? '/PAModificaTrattamento' : '/ServizioModificaTrattamento';
                    history.push(path);
                }
                else {
                    alert(response.status);
                }
            },
            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
        });

        e.preventDefault();
    }

    ripristina(e) {
        var pa = this.state.pa;
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/ripristinaRiskAppetite',
            url: API_CONFIG.assessmentcompositeripristinaRiskAppetite,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: JSON.stringify({ AssessmentId: sessionStorage.getItem('assessmentId') }),
            cache: false,
            success: function (response) {
                if (response.status === 'success') {
                    //location.reload();
                    let path = pa === "true" ? '/PAModificaTrattamento' : '/ServizioModificaTrattamento';
                    history.push(path);
                    //alert(response.status);
                }
                else {
                    //TODO
                }
            },
            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
        });

        e.preventDefault();
    }

    render() {

        var rows = '';
        if (this.state.data === undefined) {
            rows = <div className="row">Loading...</div>
        } else {
            //console.log("data", this.state.data);
            rows = [];
            for (let index = 0; index < this.state.data.RiskAppetiteList.length; index = index + 4) {
                //debugger
                var row =
                    <RiskAppetiteRow
                        key={index}
                        rowId={index}
                        riskappetite1={this.state.data.RiskAppetiteList[index]}
                        riskappetite2={this.state.data.RiskAppetiteList[index + 1]}
                        riskappetite3={this.state.data.RiskAppetiteList[index + 2]}
                        riskappetite4={this.state.data.RiskAppetiteList[index + 3]}
                        updateRiskAppetite={this.updateRiskAppetite}
                    />

                rows.push(row);
            }
        }
        let path = this.props.PA === "true" ? '/PAModificaTrattamento' : '/ServizioModificaTrattamento';
        console.log("path", path);
        return (


            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/TrattamentoRischio')}>Trattamento del rischio </a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(path)}>Opzioni di trattamento</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50">Modifica propensione al rischio</a></li>

                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Modifica propensione -  {sessionStorage.getItem('nome')} </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Modifica delle impostazioni predefinite riguardante la propensione al rischio. I valori vengono memorizzati definitivamente alla pressione del tasto “<strong>Pubblica</strong>”. Se si ritiene necessario tornare ai valori di default, premere il tasto “<strong>Ripristina</strong>”.</p>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-4">
                                <strong>Categoria servizio</strong></div>
                            <div className="col-lg-2">
                                <strong>Economico/Finanziario</strong></div>
                            <div className="col-lg-2">        <strong>Reputazionale</strong>
                            </div>
                            <div className="col-lg-2"><strong>        Legale/compliance
                        </strong></div>
                            <div className="col-lg-2">        <strong>Operativo</strong>
                            </div>
                        </div>
                        <hr />

                        {rows}

                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                            <div className="row spazio20">
                                <div className="col-lg-3">	</div>

                                <div className="col-lg-3">
                                    <button type="button" onClick={this.ripristina} className="btn btn-warning btn-lg btn-block">RIPRISTINA</button>
                                </div>
                                <div className="col-lg-3">
                                    <button type="button" onClick={this.save} className="btn btn-success btn-lg btn-block">PUBBLICA</button>
                                </div>
                                <div className="col-lg-3">	</div>
                            </div>	</div>
                    </div>
                </div>
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
            </div>
        )
    }
}