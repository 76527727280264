import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import ICON from '../data/IconLevel';
import history from '../history';
import LIVELLO from '../data/criticality';
import { Util } from '../data/Util';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';



function getRIDAccordion(r, i, d) {
    //console.log("impatto i", r);
    if (r < 1 || isNaN(r))
        return <p><strong>Impatto R-I-D:</strong><br /></p>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "testoVerde h2";
            nameR = "BASSO";
            break;
        case 2:
            classR = "testoArancio h2";
            nameR = "MEDIO";
            break;
        case 3:
            classR = "testoRosso h2";
            nameR = "ALTO";
            break;
        case 4:
            classR = "testoNero h2";
            nameR = "CRITICO";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "testoVerde h2";
            nameI = "BASSO";
            break;
        case 2:
            classI = "testoArancio h2";
            nameI = "MEDIO";
            break;
        case 3:
            classI = "testoRosso h2";
            nameI = "ALTO";
            break;
        case 4:
            classI = "testoNero h2";
            nameI = "CRITICO";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "testoVerde h2";
            nameD = "BASSO";
            break;
        case 2:
            classD = "testoArancio h2";
            nameD = "MEDIO";
            break;
        case 3:
            classD = "testoRosso h2";
            nameD = "ALTO";
            break;
        case 4:
            classD = "testoNero h2";
            nameD = "CRITICO";
            break;
        default:
            break;
    }

    return (
        <p>
            <strong>Impatto R-I-D:</strong><br />
            Riservatezza: <img src={IconImpatti[r].src} width="20" height="20" alt="" />
            <span className={classR}>
                <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Integrità: <img src={IconImpatti[i].src} width="20" height="20" alt="" />
            <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Disponibilità: <img src={IconImpatti[d].src} width="20" height="20" alt="" />
            <span className={classD}>    <strong> {nameD}</strong></span>
        </p>
    );
}

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}


// component that renders a single caratteristica row
export class MonitoraggioRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        //SET
        this.setNomePA = this.setNomePA.bind(this);
        this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
        this.impattoToString = this.impattoToString.bind(this);
        this.getImpattoClassName = this.getImpattoClassName.bind(this);
        this.getMaxRischioAttualeMinaccie = this.getMaxRischioAttualeMinaccie.bind(this);
        this.redirect = this.redirect.bind(this);
        this.getRischioAggregato = this.getRischioAggregato.bind(this);
    }

    /**
            * 
            * @param {*} nomePA 
            */
    setNomePA(nomePA) {
        try {
            if (nomePA) {

                this.setState({ nomePA: nomePA });
            } else {
                let user = Util.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA });
            }
            sessionStorage.setItem('nomePA', this.state.nomePA);
            //return console.log('nomePA',this.state.nomePA);
        } catch (error) {
            return console.error(error)
        }
    }

    /**
   * 
   */
    getMaxRischioAttualeMinaccie() {
        //console.log('rischioAttuale', this.props.remedy.RischioAttuale);
        let rischioAggregato = this.props.remedy.RischioAggregato;
        try {
            // let risk = this.props.remedy.RischioAggregato === null ? '' : LIVELLO[this.props.service.RischioAggregato].name;
            let risk = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[rischioAggregato].name : "";

            return risk;
        } catch (error) {
            //console.error(error);
            return '';
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    impattoToString(indexOfImpact) {
        try {
            let index = parseInt(indexOfImpact, 10);
            return IconImpatti[index].label;

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    getRischioAggregato() {
        //console.log('rischioAttuale', this.props.service.RischioAttuale);
        let risk = '';
        let iconSrc = "";
        let rischioAggregato = this.props.remedy.RischioAggregato;
        let className = '';
        try {
            //risk = LIVELLO[(this.props.remedy.RischioAggregato) ? this.props.remedy.RischioAggregato : ''].name;
            //iconSrc=IconImpatti[this.props.remedy.RischioAggregato].src;
            risk = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[rischioAggregato].name : "";
            iconSrc = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < IconImpatti.length)) ? IconImpatti[rischioAggregato].src : "";

            switch (this.props.remedy.RischioAggregato) {
                case 1:
                    className = "testoVerde h2";
                    break;
                case 2:
                    className = "testoArancio h2";
                    break;
                case 3:
                    className = "testoRosso h2";
                    break;
                default:
                    className = 'testoNero h2';
                    break;
            }
            return <div><img src={iconSrc} width="20" height="20" alt="" />
                <span className={className}><strong> {risk}</strong></span></div>;
        } catch (error) {
            console.error(error);
            return <div className='testoNero h2'><strong></strong></div>;
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    getImpattoClassName(indexOfImpact) {
        let className = '';
        //debugger
        try {
            let index = parseInt(indexOfImpact, 10);
            switch (index) {
                case 1:
                    className = 'testoVerde';
                    break;
                case 2:
                    className = 'testoArancio';
                    break;
                case 3:
                    className = 'testoRosso';
                    break;
                case 4:
                    className = 'testoNero';
                    break;
                default:
                    className = '';
                    break;
            }
            return className;
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }    /**
     * 
     * @param {*} rows 
     */
    addLineaGrigiaT(rows) {
        try {

            let listRowsNew = [];
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                listRowsNew.push(element);
                listRowsNew.push(<div className='lineaGrigiaT'></div>);
            }
            return listRowsNew;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} path 
     */
    redirect(path) {
        try {
            //window.sessionStorage.setItem("assessmentId", this.state.assessmentId);
            //window.sessionStorage.setItem("serviceName", this.state.serviceName);
            let state = {};
            if (this.props.PA) {
                state = {
                    assessmentId: this.props.remedy.AssessmentId,
                    isPA: this.props.PA,
                    nomePA: this.props.nomePA
                };
            } else {
                state = {
                    assessmentId: this.props.remedy.AssessmentId,
                    isPA: false,
                    serviceName: this.props.remedy.Nome
                };
            }
            if (path) {
                if (path.indexOf('/') === 0)
                    history.push({
                        pathname: path,
                        state: state
                    }
                    );
                else if (path.indexOf('/') === -1) {
                    history.push({
                        pathname: '/' + path,
                        state: state
                    }
                    );
                }
            }
            console.log("state", state);
            //alert(JSON.stringify(state));
        } catch (error) {
            return console.error(error)
        }
    }


    /**
             * 
             * @param {*} index 
             * @param {*} array 
             */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {
        let path = "MonitoraggioServizio";
        let profiloCriticità = <p><strong>Profilo di criticità del servizio</strong></p>;
        if (this.props.remedy.Criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità del servizio</strong>&nbsp;&nbsp;
                                    <img src={this.getRisk(this.props.remedy.Criticità, ICON).src} width="20" height="20" alt="" />

                <span className={getClassName(this.props.remedy.Criticità)}>
                    <strong>{" " + this.getRisk(this.props.remedy.Criticità, ICON).label}</strong></span>
            </p>;
        }

        console.log("props", this.props);

        return (
            <div>
                <div className="row">
                    <div className="col-lg-9 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            {this.props.remedy.Nome}<i className="iconRight fa fa-chevron-down"></i> </a>
                    </div>

                    {/* 1a colonna Creato*/}
                    <div className="col-lg-1 card-header">
                        {this.toStringDate(this.props.remedy.DataCreazione)}
                    </div>

                    {/*
                    <div className={getClassNameAccordion(this.props.remedy.Criticità)}>
                        <strong>{this.props.remedy.Criticità > -1 &&
                            IconImpatti[this.props.remedy.Criticità].label}</strong>
                    </div>
                    */}
                    {/* 2a colonna Stato*/}
                    <div className={"col-lg-1 card-header"}>
                        {this.props.stateAssessment}
                    </div>
                    {/* 3a colonna Azioni*/}
                    <div className="col-lg-1 card-header">
                        <a href="javascript:void(0);" onClick={() => this.redirect(path)}><i className="fa fa-pencil" title="MODIFICA"></i></a>

                    </div>


                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row  card-headerLight">
                        <div className="col-lg-6"><br />
                            <strong>Referente</strong><br />
                            {(this.props.remedy.Referente) ? this.props.remedy.Referente : "-"}<hr />
                        </div>
                        <div className="col-lg-6"><br />
                            <strong>Struttura</strong><br />
                            {(this.props.remedy.StrutturaReferente) ? this.props.remedy.StrutturaReferente : "-"}<hr />
                        </div>
                        {/*
                        <div className="col-lg-6"><hr />
                            <strong>Categorie</strong><br />
                            {this.props.categoriesTostring}
                        </div>
                        <div className="col-lg-6"><hr />
                            <strong>Tipologie</strong><br />
                            {this.props.typologiesTostring}
                        </div>
                        */}
                        <div className="col-lg-12">
                            <strong>Descrizione</strong><br />
                            {(this.props.remedy.ServizioDescrizione) ? this.props.remedy.ServizioDescrizione : "-"}<hr />
                        </div>
                        <div className="col-lg-12 ">
                            {profiloCriticità}<hr />
                        </div>
                        <div className="col-lg-12 ">

                            {getRIDAccordion(this.props.remedy.ImpattoR, this.props.remedy.ImpattoI, this.props.remedy.ImpattoD)}<hr />

                        </div>
                        <div className="col-lg-12">
                            <strong>Rischio Aggregato</strong><br />
                            {(this.props.remedy.RischioAggregato) ? this.getRischioAggregato() : "-"}<hr />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}