import React, { Component } from 'react';
import $ from 'jquery';
import { ServiceRow } from './ServiceRow';
import { API_CONFIG } from '../../public/js/apiServices';
import { Breadcrumb } from '../Common/Breadcrumb';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import { SearchInput } from '../Common/SearchInput';
import { Util } from "../data/Util";

var STATI = [];
/*
STATI[-1] = { option: "TUTTE", statoCaratterizzazione: "-1" };
STATI[0] = { option: "COMPLETATO", statoCaratterizzazione: "CARATTERIZZATO" };
STATI[1] = { option: "INCOMPLETO", statoCaratterizzazione: "PARZIALMENTE_CARATTERIZZATO" };
STATI[2] = { option: "DA COMPILARE", statoCaratterizzazione: "DA_CARATTERIZZARE" };
*/
STATI["-1"] = "Tutti";
STATI["CARATTERIZZATO"] = "completo";
STATI["PARZIALMENTE_CARATTERIZZATO"] = "incompleto";
STATI["DA_CARATTERIZZARE"] = "compilare";


function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

export class ListaApplicazioni extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            filteredServices: [],
            rowsTable: [],
            selectedCategory: sessionStorage.getItem('selectedCategoryAC') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedCategoryAC') : '-1',
            selectedState: sessionStorage.getItem('selectedStateAC') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedStateAC') : this.props.riepilogo === "true" ? "CARATTERIZZATO" : '-1',
            selectedTypology: sessionStorage.getItem('selectedTypologyAC') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedTypologyAC') : '-1',
            search: sessionStorage.getItem('searchAC') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchAC') : '',
            searchAll: sessionStorage.getItem('searchAllAC') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchAllAC') : '',
            categories: [],
            typologies: [],
            isAscName: false,
            activePage: 1,
            currentPageListServices: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...',
            selectedColumn: "",
            messageError: ""
            /*
             selectedCategory: '-1',
             selectedState: '-1',
             selectedTypology: '-1',
             search: '',
             */
        }
        this.areThereModifiedServices = this.areThereModifiedServices.bind(this);

        //GET
        this.setCategoriesTypologies = this.setCategoriesTypologies.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updateTypology = this.updateTypology.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.toString = this.toString.bind(this);
        this.updateRowsTable = this.updateRowsTable.bind(this);
        this.orderServicesList = this.orderServicesList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);


    }

    componentDidMount() {
        if (this.props.riepilogo === "true")
            this.setState({ selectedState: "CARATTERIZZATO" });

        //console.log('token:', sessionStorage.getItem('token'))
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getServiziByCodiceIPAUrl,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    console.log('response:', response);
                    this.setState({ services: response.data.sort(function (a, b) { return a.Nome.localeCompare(b.Nome) })}, this.updateRowsTable);
                    //this.setState({ rowsTable: Util.getServices(response.data, "CARATTERIZZARE") }, this.updateRowsTable);
                    //this.handlePageChange(1);
                    //this.setCategoriesTypologies();
                    this.setState({ emptyTest: "Nessuna applicazione con i criteri specificati" });
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });

        if ((sessionStorage.getItem('selectedCategoryAC') !== null ||
            sessionStorage.getItem('selectedStateAC') !== null ||
            sessionStorage.getItem('selectedTypologyAC') !== null ||
            sessionStorage.getItem('searchAllAC') !== null) &&
            this.props.riepilogo !== "true")
            $("#ricerca").get(0).click();
    }

    /**
     * 
     */
    setCategoriesTypologies() {
        $.ajax({
            //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie',
            url: API_CONFIG.getCategorieSottoCategorie,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
                //console.log('response:', response);
                this.setState({ typologies: response.data.SottoCategoriaServizio });
                this.setState({ categories: response.data.CategoriaServizio });
                //console.log('SottoCategoriaServizio:', this.state.typologies);
                //console.log('CategoriaServizio:', this.state.categories);
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }

    updateCategory(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedCategoryAC', val);
        this.setState({ selectedCategory: val }, this.updateRowsTable);
        //this.setState({ selectedCategory: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateState(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedStateAC', val);
        this.setState({ selectedState: val }, this.updateRowsTable);
        //this.setState({ selectedState: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateTypology(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedTypologyAC', val);
        this.setState({ selectedTypology: val }, this.updateRowsTable);
        //this.setState({ selectedTypology: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAC', val);
        this.setState({ search: val }, this.updateRowsTable);
        //this.setState({ search: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateSearchAll(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllAC', val);
        this.setState({ searchAll: val }, this.updateRowsTable);
        //this.setState({ searchAll: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }


    /**
     * 
     */
    toString(list) {
        var toString = '';
        if ((list.length) || list.length !== 0) {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)
        } else return "-";
    }

    /**
      * 
      * @param {*} val 
      * @param {*} field 
    */
    updateRowsTable() {
        let services = this.state.services;
        //let rowsTable = this.state.rowsTable;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedCategory = this.state.selectedCategory;
        let selectedState = this.state.selectedState;
        let selectedTypology = this.state.selectedTypology;

        try {
            var list = [];
            if (searchAll === '' && search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1') {
                list = services;
            } else {

                for (let index = 0; index < services.length; index++) {
                    const element = services[index];
                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente !== null && element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente !== null && element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.Descrizione !== null && element.Descrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {

                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                            if (selectedState === '-1' || element.StatoCaratterizzazione.toUpperCase().indexOf(selectedState.toUpperCase()) === 0) {

                                if (selectedCategory === '-1' || this.toString(element.Categorie).toUpperCase().indexOf(selectedCategory.toUpperCase()) > -1) {

                                    if (selectedTypology === '-1' || this.toString(element.Tipologie).toUpperCase().indexOf(selectedTypology.toUpperCase()) > -1) {
                                        list.push(element);
                                    }
                                }
                            }
                        }
                    }

                }
            }
            console.log('list:', list);
            //this.setState({ rowsTable: list });
            this.setState({ filteredServices: list });

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * 
     */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let rowsTable = this.state.filteredServices;
        key = key.toLowerCase();
        this.setState({ selectedColumn: key });
        switch (key) {
            case 'nome':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });

                } else list = rowsTable.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                this.setState({ isAscName: !asc });
                break;
            case 'data':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return new Date(a.DateUpdateRegistro).getTime() - new Date(b.DateUpdateRegistro).getTime(); });

                } else list = rowsTable.sort(function (a, b) { return new Date(b.DateUpdateRegistro).getTime() - new Date(a.DateUpdateRegistro).getTime(); });
                this.setState({ isAscName: !asc });
                break;           
            default:
                list = rowsTable;
                break;
        }
        this.setState({ filteredServices: list });
    }

    /**
     * 
     */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let rowsTable = this.state.rowsTable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (rowsTable[i])
                listing_table.push(rowsTable[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListServices: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.rowsTable.length / this.state.itemsCountPerPage);
    }

    areThereModifiedServices() {
        let servicesList = this.state.services;
        let areThereModifiedServices = false;
        try {

            for (let index = 0; index < servicesList.length; index++) {
                let tsCompilazioneImpatto = new Date(servicesList[index].DataCompilazioneContesto).getTime();
                let tsCalcoloImpatto = new Date(servicesList[index].DataUpdateProfiloDiCriticità).getTime();
                if (tsCompilazioneImpatto > tsCalcoloImpatto) {
                    //debugger
                    return true;
                }

            }
            //console.log('areThereModifiedServices? ', areThereModifiedServices);
            return areThereModifiedServices;
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    render() {

        var rows = '';
        if (!this.state.filteredServices.length) {
            rows = <div className="row">
                  <div className="col-lg-12">
                <br></br>
                {this.state.emptyTest}
                </div>
            </div>
        } else {
            rows = this.state.filteredServices.map(function (field, i) {
                return (
                    <ServiceRow
                        key={i}
                        rowId={i}
                        service={field}
                        categoriesTostring={this.toString(field.Categorie)}
                        typologiesTostring={this.toString(field.Tipologie)}
                        stato={STATI[field.StatoCaratterizzazione]}
                        riepilogo={this.props.riepilogo}
                    />
                );
            }.bind(this)
            );
        }
        
        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";
        var classSpazio = this.props.riepilogo === "true" ? "font16 spazio10" : "";


        return (

            <div className={outerClass}>


                <div className={outerClassContainer}>


                    <div hidden={this.props.riepilogo === "true"} className="row">

                        <div className="col-lg-12">
                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Registro Applicazioni</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Registro Applicazioni</span>
                        </div>
                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Quest'area funzionale ha l'obiettivo di fornire un quadro dettagliato, di sintesi, delle applicazioni dal punto di vista dei dati trattati/elaborati e delle funzionalità ritenute critiche per le finalità specificatamente individuate dal GDPR.
                            </p>

                        </div>

                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="linea0066cc"></div>
                    <div hidden={this.props.riepilogo === "true"} className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-10"></div>

                            <div className="col-lg-2">

                            </div>
                        </div>
                    </div>


                    <div className="container cnsContainer">
                        <div className="row">
                            {this.props.riepilogo !== "true" && <div className="col-lg-9 spazio30"></div>}

                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} />

                            <div style={{ paddingTop: "10px" }} className="col-lg-3 card-headerLeft nascondiCell">
                                <strong onClick={() => this.orderServicesList('nome')} style={{ cursor: 'pointer' }}>Nome
                            <i className={(this.state.selectedColumn.localeCompare("nome") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                                <div style={{ paddingTop: "10px" }} className="col-lg-2 card-headerLeft nascondiCell">
                                <strong>Descrizione
                                </strong></div>
                                <div style={{ paddingTop: "10px" }} className="col-lg-1 card-header nascondiCell">
                                <strong>Tipologia
                                </strong></div>                               
                                <div style={{ paddingTop: "10px" }} className="col-lg-1 card-header nascondiCell">
                                <strong>Utilizzatori
                                </strong></div>
                                <div style={{ paddingTop: "10px" }} className="col-lg-1 card-header nascondiCell">
                                <strong>Trattamento dati personali
                                </strong></div>
                                <div style={{ paddingTop: "10px" }} className="col-lg-1 card-header nascondiCell">
                                <strong>Trattamento dati sensibili
                                </strong></div>
                                <div style={{ paddingTop: "10px" }} className="col-lg-1 card-header nascondiCell">
                                <strong>Numerosità dati trattati
                                </strong></div>
                            <div className="col-lg-1 card-header nascondiCell">
                                <strong onClick={() => this.orderServicesList('data')} style={{ cursor: 'pointer' }}>Aggiornato
                            <i className={(this.state.selectedColumn.localeCompare("dataUpdateCriticità".toLowerCase()) === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>
                            <div className="col-lg-1 card-header nascondiCell"><strong> Modifica</strong></div>

                        </div>


                        <span className="lineaGrigiaT0"></span>
                        <div className={classSpazio}>
                            {rows}
                        </div>

                        {/* <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.rowsTable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        /> */}
                    </div>
                    <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Analisi del Rischio creata!'} />
                    <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={'Prima di effettuare il Calcolo compilare tutte le risposte!'} />
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                    <br />
                    <br />
                    <br />
                </div>
            </div>

        )
    }
}