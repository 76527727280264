import * as React from 'react';


// component that renders a single user
export class SearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category,
            placeholder: (this.props.placeholder) ? this.props.placeholder : 'CERCA APPLICAZIONE...'
        };
        this.changeAppMode = this.changeAppMode.bind(this);
    }

    changeAppMode(event) {
        console.log('value', event.target.value);
        this.setState({ value: parseInt(event.target.value, 10) });
        //alert('this.props.value',this.state.value);
        /*
        */
    }

    render() {

        return (

            <div className="col-lg-3">
                <div className="input-group">
                    <input className="form-control py-2 border-right-0 font16"
                        type="search"
                        id="example-search-input"
                        value={this.props.value}
                        onChange={this.props.updateSearch}
                        placeholder={this.state.placeholder}
                    />
                </div>
            </div>
        )

    }
}