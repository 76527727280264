import * as React from 'react';
import { MinacciaRowPrint } from './MinacciaRowPrint';

import bassoIcon from "../../public/images/basso.png";
import medioIcon from "../../public/images/medio.png";
import altoIcon from "../../public/images/alto.png";
import criticoIcon from "../../public/images/critico.png";

let SELECT = []

SELECT[-1] = '';
SELECT[1] = 'Non Applicabile';
SELECT[2] = 'Non so rispondere';
SELECT[3] = 'Assente';
SELECT[4] = 'Migliorabile';
SELECT[5] = 'Adeguato';
SELECT[6] = 'Completo';

    /**
     * 
     */
     function getSrcImgIcon(maxRisk) {
        let pathSrc = '';
        let maxRiskInt = parseInt(maxRisk, 10);
        switch (maxRiskInt) {
            case 1:
                pathSrc = bassoIcon
                break;
            case 2:
                pathSrc = medioIcon
                break;
            case 3:
                pathSrc = altoIcon
                break;
            case 4:
                pathSrc = criticoIcon
                break;
            default:
                //pathSrc = "col-lg-1";
                break;
        }
        return pathSrc;
    }

// component that renders a single caratteristica row
export class ReportStampaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.getMaxOfRisk = this.getMaxOfRisk.bind(this);
        //this.toStringItem=this.toStringItem.bind(this);
    }

    /**
     * calcola il rischio massimo della lista
     */
    getMaxOfRisk(tipologia) {
        let itemsList = tipologia.ListaMinacce;
        let max = itemsList[0].RischioAttuale;
        try {
            for (let index = 0; index < itemsList.length; index++) {
                const element = itemsList[index];
                if (element.RischioAttuale > max) {
                    max = element.RischioAttuale;
                }
            }
            return max;
        } catch (error) {
            console.error(error);
            return null;
        }
    }



    render() {
        var rows = [];
        if (!this.props.ListaTipologie.length) {
            rows = <div className="row">
                Loading...
        </div>
        } else {

            for (let index = 0; index < this.props.ListaTipologie.length; index++) {
                const tipologia = this.props.ListaTipologie[index];
                rows.push(
                    <div key={"Tipologia" + index} className="col-lg-12 card-headerT ">
                        <h3 ref={(el) => {if (el) {el.style.setProperty('color', '#0066cc', 'important');}}}>
                            <img src={getSrcImgIcon(this.getMaxOfRisk(tipologia))} width="15" height="15" alt="" />
                            {" " + tipologia.TipologiaEvento}
                            </h3>
                    </div>
                );

                /*
                var listaMinacce = [];
                
                for (let x = 0; x < this.props.ListaTipologie[index].ListaMinacce.length; x++) {
                    const minaccia = this.props.ListaTipologie[index].ListaMinacce[x];

                    listaMinacce.push(<div key={index + "minaccia" + x} className="row">
                        <div className="col-lg-4">{minaccia.NomeMinaccia}</div>
                        <div className={getClassName(minaccia.RID)}>{toStringItem(minaccia.RID)}</div>
                        <div className={getClassName(minaccia.Vulnerabilità)}>{toStringItem(minaccia.Vulnerabilità)}</div>
                        <div className={getClassName(minaccia.TotaleLivelloEsposizione)}>{toStringItem(minaccia.TotaleLivelloEsposizione)}</div>
                        <div className={getClassName(minaccia.Probabilità)}>{toStringItem(minaccia.Probabilità)}</div>
                        <div className={getClassName(minaccia.RischioAttuale)}>{toStringItem(minaccia.RischioAttuale)} </div>
                        <div className={getClassName(minaccia.RiskAppetite)}>{toStringItem(minaccia.RiskAppetite)}</div>
                        <div className={getClassName(-1)}>{toStringOpzione(minaccia.OpzioneTrattamento)}</div>
                        </div>);
                }
                
                rows.push(
                    <div key={this.props.rowId + "TipologiaCollapse" + index} id={this.props.rowId + "TipologiaCollapse" + index} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row">
                            <div className="col-lg-4"><strong>Minaccia</strong></div>
                            <div className="col-lg-1"><strong>RID</strong></div>
                            <div className="col-lg-1"><strong>Vulnerabilità</strong></div>
                            <div className="col-lg-1"><strong>Esposizione</strong></div>
                            <div className="col-lg-1"><strong>Probabilità</strong></div>
                            <div className="col-lg-1"><strong>Rischio </strong></div>
                            <div className="col-lg-1"><strong>Propensione</strong></div>
                            <div className="col-lg-2"><strong>Trattamento</strong></div>
                            </div>
                            {listaMinacce}
                            <hr />
                            </div>
                            );
                */

                let minacciaRows = this.props.ListaTipologie[index].ListaMinacce.map(function (minaccia, x) {
                    //const minaccia = this.props.ListaTipologie[index].ListaMinacce[x];
                    return (
                        <MinacciaRowPrint
                            minaccia={minaccia}
                            key={x}
                            index={x} />
                    );
                });

                rows.push(
                    <div key={this.props.rowId + "TipologiaCollapse" + index}  className="col-lg-12">
                        <table style={{width:"100%"}}>
                            <tbody>
                                <tr >
                                    <td style={{ width:"28%" }}><span className=" font16 "><strong>Minaccia</strong></span></td>
                                    <td style={{ textAlign: "center",width:"9%" }} ><span className=" font16"><strong>Impatto R-I-D</strong></span></td>
                                    <td style={{ textAlign: "center",width:"9%" }} ><strong>Vulnerabilità</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }} ><strong>Esposizione alla minaccia</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }}><strong>Probabilità di accadimento</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }}><strong>Rischio Attuale</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }}><strong>Propensione al rischio</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }} ><strong>Opzioni di trattamento</strong></td>
                                    <td style={{ textAlign: "center",width:"9%" }} ><strong>Rischio derivato</strong></td>
                                </tr>
                                {minacciaRows}
                            </tbody>
                        </table>
                        <div className="spazio20"></div>
                    </div>
                );

            }
        }

        var divList = [];

        divList.push(<div key={"divCategoria" + this.props.rowId} className="col-lg-12"><div className="spazio10"></div>
            <h3>
                {this.props.NomeCategoria}               
            </h3>
        </div>);
        //divList.push(<div key={"divSpazio"+this.props.rowId} className="spazio20"></div>);
        divList.push(<div key={"divTipologia" + this.props.rowId} className="col-lg-12">
            {rows}
        </div>);

        return (
            <div className="row">
                {divList}
            </div>

            // <div>
            //     <div className="row">
            //         <div className="col-lg-6 card-headerT">
            //             <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
            //                 <img src={getSrcImgIcon(this.getMaxOfRisk())} width="20" height="20" alt="" />
            //                 {" " + this.props.NomeCategoria.toLocaleUpperCase()}<i className="iconRight fa fa-chevron-down"></i></a>
            //         </div>
            //     </div>
            //     <hr />
            //     <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
            //         <div className="row">
            //             <div className="col-lg-3"><strong>Tipologia</strong></div>
            //             <div className="col-lg-2"><strong>Minaccia</strong></div>
            //             <div className="col-lg-1"><strong>RID</strong></div>
            //             <div className="col-lg-1"><strong>Vulnerabilità</strong></div>
            //             <div className="col-lg-1"><strong>Esposizione</strong></div>
            //             <div className="col-lg-1"><strong>Probabilità</strong></div>
            //             <div className="col-lg-1"><strong>Rischio </strong></div>
            //             <div className="col-lg-1"><strong>Propensione</strong></div>
            //             <div className="col-lg-1"><strong>Trattamento</strong></div>
            //         </div>
            //         <hr />
            //         {getRowsWithHr(rows)}
            //     </div>
            // </div>

        );
    }
};