import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import { API_CONFIG } from '../../public/js/apiServices';
import { AzioniTrattamentoRischioResiduoRow } from './AzioniTrattamentoRischioResiduoRow';
import { MinacciaRow } from './MinacciaRow';
import { ModalDialog } from '../Common/ModalDialog';
import { ThreatSelect } from './ThreatSelect';
/*
import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "Trattamento", url: "/Trattamento" },
    { nome: "Trattamento del rischio", url: "/TrattamentoRischio" },
    { nome: "Azioni di trattamento per servizio", url: "#" }
];
*/
let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

export class AzioniTrattamentoRischioResiduo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actionList: [],
            listAssessmentFilterable: [],
            mergeList: [],
            listAllMenace: [],
            minacceList: [],
            opzioni: [],
            serviceName: (props.location.state.serviceName) ? props.location.state.serviceName : "",
            nomePA: (props.location.state.nomePA) ? props.location.state.nomePA : "",
            selectedMenace: "",
            isCheckedRow: [],
            emptyTest: "Loading...",
            messageWarning: '',
            messageSuccess: '',
            messageError: '',
            statoAssessment: "",
            assessmentId: (props.location.state.assessmentId) ? props.location.state.assessmentId : "",
            isPA: props.location.state.isPA
        };
        //GET
        this.getAzioniDiTrattamentoByAssessmentId = this.getAzioniDiTrattamentoByAssessmentId.bind(this);
        this.getMinacce = this.getMinacce.bind(this);
        //MERGE
        this.mergeMinacceToAzionidiTrattamento = this.mergeMinacceToAzionidiTrattamento.bind(this);
        //UPDATE
        this.updateMenace = this.updateMenace.bind(this);
        this.updateRows = this.updateRows.bind(this);
        this.updateDataChiusura = this.updateDataChiusura.bind(this);
        this.updateEffectiveAction = this.updateEffectiveAction.bind(this);
        this.updateGrade = this.updateGrade.bind(this);
        this.updadateCheckedRow = this.updadateCheckedRow.bind(this);
        //SET
        this.setGradoAttesoDefault = this.setGradoAttesoDefault.bind(this);
        this.setNomePA = this.setNomePA.bind(this);
        //SAVE
        this.save = this.save.bind(this);
        //toString
        this.toString = this.toString.bind(this);
        /** */
        this.redirect = this.redirect.bind(this);
        this.changeStateAssessment = this.changeStateAssessment.bind(this);
        this.canChangeStateAssessment = this.canChangeStateAssessment.bind(this);
        this.opzioniComplete = this.opzioniComplete.bind(this);
    }

    /**
     * 
     */
    opzioniComplete() {
        var lista = this.state.opzioni;
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if ((element.OpzioneTrattamento !== null && element.OpzioneTrattamento !== element.OpzioneTrattamentoDefault) &&
                (element.PersonaIntervistato === null || element.StrutturaIntervistato === null || element.Motivazione === null)) {
                return false;
            }
        }

        return true;
    }

    /**
     * 
     */
    canChangeStateAssessment() {
        var lista = this.state.actionList;
        var pianificate = 0;

        var response = {};
        response.canChange = true;

        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];

            if (!(element.GradoDiImplementazioneAtteso !== null && element.AzioneEffettiva !== ""
                && (element.DataChiusura || element.AzioneEffettiva === "empty")))
                response.canChange = false;

            if (element.AzioneEffettiva != "empty")
                pianificate = pianificate + 1;
        }

        response.pianificate = pianificate;
        console.log("canChangeStateAssessment", response);
        return response;
    }

    setGradoAttesoDefault() {
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateOpzioniTrattamento',
            url: API_CONFIG.calcoloGradoDiImplementazioneAtteso,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: JSON.stringify({ AssessmentId: this.state.assessmentId }),
            cache: false,

            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response) {
                if (response.status.localeCompare('success') === 0) {
                    $('#btnSuccess').get(0).click();
                    this.callAzioni();
                }
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
            }.bind(this),
        });

    }

    setGradoDialog() {
        $('#btnSetGrado').get(0).click();
    }

    /**
     * 
     */
    changeStateAssessment() {
        try {
            //let index = this.state.isCheckedRow.findIndex(function (obj) { return obj === false; });
            var response = this.canChangeStateAssessment();
            if (response.canChange && response.pianificate > 0) {
                if (this.opzioniComplete()) {
                    this.serverRequest =
                        $.ajax({
                            url: API_CONFIG.cambiaStatoAssessment,
                            type: "POST",
                            contentType: 'application/json',
                            headers: { 'token': sessionStorage.getItem('token') },
                            data: "{ \"AssessmentId\": \"" + this.state.assessmentId + "\"," + " \"Stato\":\"6\" }",
                            cache: false,
                            beforeSend: function () {
                                $('#loader').show();
                                $('#modal').show();
                            },
                            complete: function () {
                                $('#loader').hide();
                                $('#modal').hide();
                            },
                            success: function (response) {
                                //console.log(response);
                                if (response.status === 'success') {
                                    this.setState({ messageSuccess: "Pubblicazione effettuata con successo!" });
                                    $('#btnSuccess').get(0).click();
                                    this.setState({ statoAssessment: 6 });
                                    //location.reload();
                                    //history.push('/ListaServizi');
                                    //alert(response.status);
                                }
                                else {
                                    //alert(response.status);
                                }
                            }.bind(this),

                            error: function (xhr, resp, text) {
                                let messageError = "Si è verificato un errore ";
                                console.error(xhr);
                                //Token JWT Invalid
                                if (xhr.responseJSON) {
                                    if (xhr.responseJSON.data === -1) {
                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                        location.replace(API_CONFIG.HomePath);
                                    } else {
                                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                        $('#btnError').get(0).click();
                                    }
                                }
                                else {

                                    this.setState({ messageError: messageError + "." });
                                    $('#btnError').get(0).click();
                                }
                            }.bind(this),
                        });
                }
                else {
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario completare i campi Responsabile della modifica, Struttura organizzativa e Motivazione delle Opzioni di Trattamento modificate" });
                    $('#btnValidate').get(0).click();
                }
            }
            else {
                if (response.pianificate > 0)
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario completare tutte le azioni di trattamento" });
                else
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario pianificare almeno un'azione di trattamento" });
                $('#btnValidate').get(0).click();
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} e 
     */
    updateMenace(e) {
        let val = e.target.value;
        sessionStorage.setItem('selectedMenace', val);
        this.setState({ selectedMenace: val }, () => this.updateRows());
    }

    /**
      * 
    */
    updateRows() {
        let listAssessment = this.state.actionList;
        let selectedMenace = this.state.selectedMenace;
        var obj = { "length": listAssessment.length, "selectedMenace": selectedMenace }
        //alert(JSON.stringify(obj));
        try {

            var list = [];
            if (selectedMenace.localeCompare("-1") === 0) {
                //&& search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1'
                list = listAssessment;
                //debugger
            } else {

                for (let index = 0; index < listAssessment.length; index++) {
                    for (let j = 0; j < listAssessment[index].Minacce.length; j++) {
                        const action = listAssessment[index].Minacce[j];
                        if (action.NomeMinaccia.toUpperCase().localeCompare(selectedMenace.toUpperCase()) === 0) {
                            list.push(listAssessment[index]);
                        }
                    }
                }
            }
            this.setState({ listAssessmentFilterable: list });
            //alert(list.length);

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * 
     */
    componentDidMount() {
        console.log("props", this.props);
        this.setNomePA(sessionStorage.getItem("nomePA"));
        this.getMinacce();
    }


    /**
     * 
     */
    getAzioniDiTrattamentoByAssessmentId() {
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentAzioniDiTrattamentoByAssessmentId',
                    url: API_CONFIG.azioniDiTrattamentoByAssessmentId,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                    //data: "{ \"AssessmentId\": 51}",
                    cache: false,
                    success: function (response) {
                        //console.log('response:', response);
                        this.setState({ serviceName: response.data.NomeServizio });
                        if (response.data.listaAzioni.length > 0) {
                            if (response.data.StatoAssessmentId < 4) {
                                this.setState({ emptyTest: "Nessuna Analisi del Rischio Pubblicata oppure non è stata selezionata nessuna minaccia da mitigare" });
                            }
                            else {
                                this.setState({ actionList: response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }) },
                                    //() => this.updateRows);
                                    () => this.mergeMinacceToAzionidiTrattamento());
                                console.log("actionList", this.state.actionList);
                                let unique_listMenace = this.getListAllMenace(response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }));
                                this.setState({ listAllMenace: unique_listMenace });
                                this.setState({ statoAssessment: response.data.StatoAssessmentId });
                                this.setState({ assessmentId: response.data.AssessmentId });

                                this.canChangeStateAssessment();

                                this.serverRequest =
                                    $.ajax({
                                        url: API_CONFIG.assessmentcompositeGetOpzioniTrattamento,
                                        type: "POST",
                                        contentType: 'application/json',
                                        data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                                        headers: { 'token': sessionStorage.getItem('token') },
                                        cache: false,
                                        success: function (response) {
                                            this.setState({ opzioni: response.data.OpzioniTrattamento.data });
                                        }.bind(this)
                                    });
                            }
                        }
                        else
                            this.setState({ emptyTest: "Nessuna Analisi del Rischio Pubblicata oppure non è stata selezionata nessuna minaccia da mitigare" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    mergeMinacceToAzionidiTrattamento() {
        let finalList = [];
        let minacceList = this.state.minacceList;
        let actionList = this.state.actionList;
        try {
            for (let i = 0; i < minacceList.length; i++) {
                let actions = [];
                let threat = {};
                for (let j = 0; j < actionList.length; j++) {
                    const minacce = actionList[j].Minacce;
                    let index = minacce.findIndex(function (obj) { return obj.NomeMinaccia.localeCompare(minacceList[i].Nome) === 0; });
                    if (index > -1) {
                        actions.push(actionList[j]);
                        threat = minacceList[i];
                    }
                }
                if(actions.length > 0)
                    finalList.push({ threat: threat, actions: actions });
            }
            console.log("merge", finalList);
            this.setState({ mergeList: finalList })
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} actionList : response.data.actionList
     * return the list of all Minacce without duplicate
     */
    getListAllMenace(actionList) {
        let unique_listMenace = [];
        try {
            for (let index = 0; index < actionList.length; index++) {
                for (let j = 0; j < actionList[index].Minacce.length; j++) {
                    const element = actionList[index].Minacce[j].NomeMinaccia;
                    if (!unique_listMenace.includes(element)) {
                        unique_listMenace.push(element)
                    }
                }
            }
            return unique_listMenace;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
    * 
    */
    getMinacce(e) {
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getMinacce',
                    url: API_CONFIG.assessmentcompositeGetMinacce,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify({ AssessmentId: this.props.location.state.assessmentId}),
                    //data: "{ \"AssessmentId\": 51}",
                    cache: false,
                    success: function (response) {
                        //console.log('assessmentcompositeGetMinacce:', response);
                        this.setState({ minacceList: response.data }, () => this.getAzioniDiTrattamentoByAssessmentId());
                        console.log("minacce", response.data);
                        //this.setState({ emptyTest: "Nessuna minaccia presente" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    updateEffectiveAction(val, action) {
        let actionList = this.state.actionList;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        for (let index = 0; index < actionList.length; index++) {
            if (actionList[index].AssessmentAzioniDiTrattamentoId === actionId) {
                actionList[index].AzioneEffettiva = val;
                console.log(' actionList[' + actionId + '].AzioneEffettiva', actionList[index].AzioneEffettiva);
            }
        }
        this.setState({ actionList: actionList });
        console.log("actionList", actionList);
    }

    /**
     * 
     * @param {*} month 
     * @param {*} year 
     * @param {*} action 
     */
    updateDataChiusura(quarterDate, action) {
        var split = quarterDate.split("-");
        var year = split[1];
        var quarter = split[0];
        let month = (parseInt(quarter, 10) * 3) - 1;
        console.log("updateDataChiusura", { month: month, quarter: quarter, year: year });
        let actionList = this.state.actionList;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        var dataChiusura = this.getDataChiusura(quarter, year);
        try {
            if (dataChiusura != -1) {

                for (let index = 0; index < actionList.length; index++) {
                    if (actionList[index].AssessmentAzioniDiTrattamentoId === actionId) {
                        actionList[index].DataChiusura = dataChiusura;
                    }
                }
                this.setState({ actionList: actionList }, () => this.save());
                console.log("actionList", actionList);
            } else {
                this.setState({ messageWarning: "Il trimestre selezionato è minore di quello corrente." });
                $('#btnValidate').get(0).click();
                return;
            }
        } catch (error) {
            return console.error(error);
        }

    }

    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * 
     */
    updateGrade(val, action, azioneEffettiva) {
        //console.log('azioneEffettiva', azioneEffettiva);
        let actionList = this.state.actionList;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        for (let index = 0; index < actionList.length; index++) {
            if (actionList[index].AssessmentAzioniDiTrattamentoId === actionId) {
                actionList[index].GradoDiImplementazioneAtteso = val;

                if (azioneEffettiva !== undefined)
                    actionList[index].AzioneEffettiva = azioneEffettiva;
                //else if (azioneEffettiva == null || azioneEffettiva)
                //    actionList[index].AzioneEffettiva = "";
                if (azioneEffettiva === "empty")
                    actionList[index].DataChiusura = null;
            }
        }
        this.setState({ actionList: actionList }, () => this.save());
        //console.log("actionList", actionList);
    }

    /**
     * 
     */
    updadateCheckedRow(rowId, isCheck) {
        console.log("rowId", rowId);
        console.log("isCheck", isCheck);
        try {
            let isCheckedRow = this.state.isCheckedRow;
            isCheckedRow[rowId] = isCheck;
            this.setState({ isCheckedRow: isCheckedRow });
        } catch (error) {
            return console.error(error);
        }

    }

    /**
    * 
    * @param {*} nomePA 
    */
    setNomePA(nomePA) {
        try {
            if (nomePA) {
                this.setState({ nomePA: nomePA });
            } else {
                let user = this.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA });
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getUsersFromAccessToken() {
        try {

            const accessToken = sessionStorage.getItem('token');
            //console.log("getAccessToken");

            if (accessToken) {
                let decoded = jwtDecode(accessToken);
                console.log("user", decoded.user);
                return decoded.user;
            }

            return null;

        } catch (error) {
            //location.replace(API_CONFIG.HomePath);
            return console.error(error);
        }
    }

    /**
     * 
     */
    save() {
        let data = { 'AzioniDiTrattamento': this.state.actionList }
        console.log('save', data);
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateAzioniDiTrattamento',
                    url: API_CONFIG.saveOrUpdateAzioniDiTrattamento,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify(data),
                    cache: false,
                    success: function (response) {
                        //if (response.status === 'success')
                        //   location.reload();
                        //this.setState({ emptyTest: "Nessun Servizio con i criteri specificati" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} quarter 
     * @param {*} year 
     */
    canUpdateDataChiusura(quarter, year) {
        let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
        return !((quarter < quarterNow) && (year === new Date().getFullYear()));
    }

    getDataChiusura(quarter, year) {
        var dataChiusura;


        if (quarter === -1 && year === -1) {//Non valorizzati entrambi
            dataChiusura = undefined;
        }
        else if (quarter === -1 && year !== -1) {//Quarter non valorizzato, metto quarter corrente se year=yearNow altrimenti primo quarter
            let _quarter = 1;
            let yearNow = new Date().getFullYear();
            if (year === yearNow)
                _quarter = Math.ceil((new Date().getMonth() + 1) / 3);

            let month = (parseInt(_quarter, 10) * 3) - 1;

            dataChiusura = new Date(Date.UTC(year, month, 2));
        }
        else if (quarter !== -1 && year === -1) {//Year non valorizzato, metto anno corrente se quarter>=quarterNow altrimenti anno successivo
            let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
            let month = (parseInt(quarter, 10) * 3) - 1;
            let _year = new Date().getFullYear();
            if (quarter < quarterNow)
                _year = _year + 1;

            dataChiusura = new Date(Date.UTC(_year, month, 2));
        }
        else {//Quarter e year valorizzati, eseguo la validazione
            let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
            if (quarter < quarterNow && year === new Date().getFullYear())
                dataChiusura = -1;
            else {
                let month = (parseInt(quarter, 10) * 3) - 1;
                dataChiusura = new Date(Date.UTC(year, month, 2));
            }
        }
        return dataChiusura;
    }

    /**
     * 
     */
    redirect() {
        let path="";
        try {
            //window.sessionStorage.setItem("assessmentId", this.state.assessmentId);
            //window.sessionStorage.setItem("serviceName", this.state.serviceName);
            let state = {};
            if (this.state.isPA) {
                path="AzioniTrattamentoPA";
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA:this.state.isPA,
                    namePA: this.state.nomePA
                };
            } else {
                path="AzioniTrattamentoServizioModifica";
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA:this.state.isPA,
                    serviceName: this.state.serviceName
                };
            }
            if (path) {
                if (path.indexOf('/') === 0)
                    history.push({
                        pathname: path,
                        state: state
                    }
                    );
                else if (path.indexOf('/') === -1) {
                    history.push({
                        pathname: '/' + path,
                        state: state
                    }
                    );
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }

    render() {

        let rows = [];
        if (!this.state.mergeList.length) {
            rows = <div className="row"> {this.state.emptyTest}</div>
        } else {
            //let rowsTemp = this.state.listAssessmentFilterable.map(function (action, i) {
            let rowsTemp = this.state.mergeList.map(function (item, i) {
                let radioGroup = {
                    isCheckConsigliata: false,
                    isCheckEffettiva: false,
                    isCheckNessuna: false,
                    isHiddenGrade: true,
                    isDisabledDataChiusura: false
                }
                for (let index = 0; index < item.actions.length; index++) {
                    let action = item.actions[index];
                    //debugger
                    let isFirtTime = (action.GradoDiImplementazioneAtteso === null);
                    if (!isFirtTime) {
                        //caso di “Azione consigliata”
                        if (action.AzioneEffettiva === null) {
                            radioGroup.isCheckConsigliata = true;
                            //disabilitare la combobox del grado di implementazione
                            radioGroup.isHiddenGrade = true;
                            //abilitare la data di chiusura prevista
                            radioGroup.isDisabledDataChiusura = false;
                        }    //caso di “Nessuna azione” 
                        else if (action.AzioneEffettiva.localeCompare("empty") === 0) {
                            radioGroup.isCheckNessuna = true;
                            //disabilitare il grado di implementazione atteso
                            radioGroup.isHiddenGrade = true;
                            //imposta lo stesso valore del grado di implementazione originale
                            //action.GradoDiImplementazioneAtteso = action.GradoDiImplementazione;
                            //disabilitare la data di chiusura prevista
                            radioGroup.isDisabledDataChiusura = true;
                        } else {//Caso di “Azione effettiva”
                            radioGroup.isCheckEffettiva = true;
                            //abilitare il grado di implementazione atteso
                            radioGroup.isHiddenGrade = false;
                            //abilitare la data di chiusura prevista
                            radioGroup.isDisabledDataChiusura = false;
                        }
                    }
                }
                return (
                    <AzioniTrattamentoRischioResiduoRow
                        key={i}
                        index={i}
                        //id={action[].AssessmentAzioniDiTrattamentoId}
                        //id={user.id}
                        threat={item.threat}
                        action={item.actions}
                        //radioGroup={radioGroup}
                        updateGrade={this.updateGrade}
                        updateDataChiusura={this.updateDataChiusura}
                        updadateCheckedRow={this.updadateCheckedRow}
                        updateEffectiveAction={this.updateEffectiveAction}
                        updateAzioneConsigliata={this.updateAzioneConsigliata}
                        save={this.save}
                        readOnly={true}
                    />
                );


            }.bind(this)
            );

            for (let index = 0; index < rowsTemp.length; index++) {
                const element = rowsTemp[index];

                rows.push(element);
                rows.push(<hr key={"hrAzioni" + index}></hr>);

            }
        }

        let minaccieRows = [];
        if (this.state.minacceList.length === 0) {
            minaccieRows = <tr><td>{this.state.emptyTest}</td></tr>
        } else {
            minaccieRows = this.state.minacceList.map(function (minaccia, i) {
                return (
                    <MinacciaRow
                        key={i}
                        index={i}
                        minaccia={minaccia}
                    />
                );
            }.bind(this)
            );
        }

        var pathOpzioni = this.props.PA === "true" ? "/PAModificaTrattamento" : "/ServizioModificaTrattamento";

        var testoBreadcrumb = this.props.PA === "true" ? "Azioni di trattamento per PA" : "Azioni di trattamento per servizio";
        
        return (
            <div>
                {/* <!-- INIZIO PAGINA REACT--> */}
                <div id="main">

                    <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                        <div className="container cnsContainer">


                            <div className="row">

                                <div className="col-lg-12">
                                    <nav className="" aria-label="sei qui:" role="navigation">
                                        <ul className="Breadcrumb">
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/TrattamentoRischio')}>Trattamento del rischio</a></li>
                                            <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Azioni di trattamento rischio residuo</a></li>
                                        </ul>
                                    </nav>
                                    <span className="titoloPagina">Azioni di trattamento: {(this.state.isPA) ? this.state.nomePA : this.state.serviceName} - Rischio residuo</span></div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 giustifica">
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati </p>
                                </div>


                            </div>



                            <div className="linea0066cc"></div>
                            <div className="container cnsContainer">

                                <div className="row">
                                    <div className="col-lg-10"></div>

                                    <div className="col-lg-2">

                                    </div>



                                </div>
                            </div>


                            <div className="container cnsContainer">

                                <div className="row">
                                    <div className="col-lg-9 card-headerLeft nascondiCell"><strong> Minacce</strong></div>
                                    <div className="col-lg-1 card-headerCenter nascondiCell"><strong> Rischio attuale</strong></div>
                                    <div className="col-lg-1 card-headerCenter nascondiCell"><strong>Propensione</strong></div>
                                    <div className="col-lg-1 card-headerCenter nascondiCell "><strong> Rischio residuo</strong></div>
                                </div>

                                <span className="lineaGrigiaT"></span>
                                {rows}

                                <div className="row spazio10"> </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div style={{ position: "fixed", bottom: "0px", left: "0px", width: "100%", background: "rgba(255,255,255,.7)" }}>
                    <div className="row spazio20">


                        <div className="col-lg-4">
                        </div>

                        <div className="col-lg-3">
                            <a href="javascript:void(0)">

                                <button type="button"
                                    onClick={() => this.redirect()}
                                    //onClick={() => history.goBack()}
                                    className="btn btn-warning btn-lg btn-block">
                                    <i className="fa fa-angle-double-left"></i> AZIONI DI TRATTAMENTO
		                        </button>
                            </a>    </div>

                        <div className="col-lg-4">

                        </div>

                    </div>
                </div>
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
            </div >
        )
    }
}
