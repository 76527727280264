import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';
import $ from 'jquery';
import { Pie } from 'react-chartjs-2';
import { RiepilogoDatiRow } from './RiepilogoDatiRow';
import { ModalDialog } from '../Common/ModalDialog';
import { ReportStampa } from './ReportStampa';
import { GradoImplementazioneDominiRow } from "./GradoImplementazioneDominiRow";
import ReactToPrint from "react-to-print";
import { isNullOrUndefined } from 'util';
import PageStyle from '../data/PageStyle';
import ApiCalls from '../utils/apiCalls';
import infoIcon from '../../public/images/info.png';
import lodash from 'lodash';

let messageError = '';

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}

/**
 * 
 */
function splitIntoSubArray(arr, count) {
    var newArray = [];
    while (arr.length > 0) {
        newArray.push(arr.splice(0, count));
    }
    return newArray;
}

const LINK = [
    { nome: "", url: "/" },
];

export class RiepilogoDatiAnalisiRischio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ListaCategorieMinaccia: [],
            TipologieList: [],
            TotaliServizi: {},
            distribuzioneAssessment: [],
            distribuzioneDominio: [],
            distribuzioneDominioList: [],
            listaRemediation: [],
            contestoComplianceGap: [],
            dataRadarChart: {},
            optionRadarChart: {},
            distribuzioneTrattamento: undefined,
            rischi: {},
            listAssessmentRischioDerivato: [],
            abilitaStampa: false,
            statoAssessmentId: 0,
            contestoNormativo: [],
            //pdf importato
            datiAsset: [],
            pageNumber: 1,
            setNumPages: null
        };
        //this.createTipologieList = this.createTipologieList.bind(this);
        //this.createChart = this.createChart.bind(this);
        this.getRischio = this.getRischio.bind(this);
        this.getConformità = this.getConformità.bind(this);
        this.concatArray = this.concatArray.bind(this);
        this.getRisposte = this.getRisposte.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.getRadarChartData = this.getRadarChartData.bind(this);
        this.getIUC = this.getIUC.bind(this);
        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.getSumOfAnswersProvided = this.getSumOfAnswersProvided.bind(this);
        this.getGradoImplementazioneDomini = this.getGradoImplementazioneDomini.bind(this);
        this.updateListAssessmentRischioDerivato = this.updateListAssessmentRischioDerivato.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.openPdfPage = this.openPdfPage.bind(this);
        this.getRiskAnalysisSection = this.getRiskAnalysisSection.bind(this);
        this.getAzioniTrattamentoSection = this.getAzioniTrattamentoSection.bind(this);
    }

    componentDidMount() {
        try {

            let decoded = this.getAccessToken();
            if (decoded !== null) {
                let user = decoded.user;
                this.setState({ nomePA: user.NomePA });
            }

            var args;
            if (sessionStorage.getItem('riepilogoAssessmentId') != null) {
                args = JSON.stringify({ ServizioId: sessionStorage.getItem('servizioId'), AssessmentId: parseInt(sessionStorage.getItem('riepilogoAssessmentId'), 10) });
            }
            else
                args = JSON.stringify({ ServizioId: sessionStorage.getItem('servizioId') });

            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.assessmentcompositeRiepilogo,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: args,

                    cache: false,
                    success: function (response) {
                        console.log('assessmentcompositeRiepilogo:', response);
                        this.setState({ statoAssessmentId: response.data.StatoId });
                        //debugger
                        this.setState({ ListaCategorieMinaccia: response.data.ListaCategorieMinaccia });
                        this.setState({ distribuzioneAssessment: response.data.getDistribuzioneAssessment.data[0] });
                        this.setState({ distribuzioneTrattamento: response.data.getDistribuzioneTrattamento.data[0] });
                        this.setState({ rischi: response.data.getRischiByAssessmentId[0] });
                        this.setState({ listaRemediation: response.data.ListaRemediation });
                        this.setState({ contestoComplianceGap: response.data.getContestoComplianceGap.data });
                        //this.setState({ distribuzioneDominioList: response.data.getDistribuzioneDominio.data });
                        console.log("contestoComplianceGap:", response.data.getContestoComplianceGap);
                        this.setState({ dataRadarChart: this.getRadarChartData(response.data.getDistribuzioneDominio.data).data });
                        this.setState({ optionRadarChart: this.getRadarChartData(response.data.getDistribuzioneDominio.data).options });
                        let count = Math.ceil(response.data.getDistribuzioneDominio.data.length / 2);
                        this.setState({ distribuzioneDominio: splitIntoSubArray(response.data.getDistribuzioneDominio.data, count) },
                            () => this.concatArray(this.state.distribuzioneDominio[0], this.state.distribuzioneDominio[1])
                        );
                        if (this.props.PA === "true") {
                            var rischio = this.state.rischi.RischioAttuale / 4;
                            if (isNaN(rischio) || rischio === 0) {
                                rischio = 0.01;
                            }
                            window.setGauge4liv("gauge4liv", rischio);
                        }

                        var perc = this.getSumOfAnswersProvided() / this.state.distribuzioneAssessment.Totale;
                        if (isNaN(perc)) {
                            perc = 0;
                        }
                        window.setGaugeImpatti(perc === 1 ? 0.99 : perc === 0 ? 0.01 : perc);



                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                                sessionStorage.removeItem("userInfo");
                                sessionStorage.removeItem("lastActivityDate");
                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });
        } catch (error) {
            messageError = error;
            $('#btnError').get(0).click();
            return console.error(error);
        }

        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getContestoNormativo,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ Report: "1" }),
                cache: false,
                success: function (response) {
                    console.log('contestoNormativo:', response);
                    this.setState({ contestoNormativo: response.data });
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                            sessionStorage.removeItem("userInfo");
                            sessionStorage.removeItem("lastActivityDate");
                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });


        ApiCalls.getImportDatiByNomeAsset(sessionStorage.getItem("serviceName")).
            then(res => this.setState({ datiAsset: res })).
            catch(error => {
                messageError = error;
                $('#btnError').get(0).click();
                return console.error(error);
            });
    }

    getRiskAnalysisSection() {

        let riskAssessment = this.state.datiAsset.filter(da => da.Tipo == 'Risk Assessment');
        let section = [];

        if (riskAssessment.length > 0) {
            let riskAssessments = lodash.groupBy(JSON.parse(riskAssessment[0].Dati), 'Dominio sicurezza')

            for (let risk in riskAssessments) {
                section.push(<div className='row'><div className="col-lg-1"></div>
                    <div className="col-lg-11" data-bs-toggle="collapse" href={"#" + risk.replaceAll(" ", "")}>
                        <a className='card-link' href='#'>{risk}</a>

                        <div className='spazio10 collapse' id={risk.replaceAll(" ", "")}>
                            <div className='row'>
                                <div className="col-lg-3 card-headerLeft"><strong>Dominio sicurezza</strong></div>
                                <div className="col-lg-7 card-headerLeft"><strong>Testo controllo</strong></div>
                                <div className="col-lg-2 card-header"><strong>Grado Implementazione</strong></div>
                            </div>
                            {riskAssessments[risk].map(el => {
                                return <div className='row'>
                                    <div className="col-lg-3 card-headerLeft">{el["Dominio sicurezza"]}</div>
                                    <div className="col-lg-7 card-headerLeft">{el["Testo controllo"]}</div>
                                    <div className="col-lg-2 card-header">{el["Grado Implementazione"]}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>)
            }
        }
        return section;
    }

    getAzioniTrattamentoSection() {

        let azioniTrattamento = this.state.datiAsset.filter(da => da.Tipo == 'Azioni di trattamento');
        return azioniTrattamento.length > 0
            ? <div>
                <div className="col-lg-12" data-bs-toggle="collapse" href="#azioniTrattamento"><a className='card-link' href='#'>Azioni di Trattamento</a></div>
                <div className="collapse" id="azioniTrattamento">
                    <div className='row'>
                        <div className="col-lg-2 card-headerLeft"><strong>Sotto-categoria misura di sicurezza</strong></div>
                        <div className="col-lg-5 card-headerLeft"><strong>Misura di sicurezza</strong></div>
                        <div className="col-lg-3 card-headerLeft"><strong>Azione di trattamento</strong></div>
                        <div className="col-lg-1 card-header"><strong>Grado di implementazione</strong></div>
                        <div className="col-lg-1 card-header"><strong>Grado di implementazione atteso</strong></div>
                    </div>
                    {
                        JSON.parse(azioniTrattamento[0].Dati).map(azioni => {
                            return <div className='row spazio10'>
                                <div className="col-lg-2 card-headerLeft">{azioni["Sotto-categoria misura di sicurezza"]}</div>
                                <div className="col-lg-5 card-headerLeft">{azioni["Misura di sicurezza"]}</div>
                                <div className="col-lg-3 card-headerLeft">{azioni["Azione di trattamento"]}</div>
                                <div className="col-lg-1 card-header">{azioni["Grado di implementazione"]}</div>
                                <div className="col-lg-1 card-header">{azioni["Grado di implementazione atteso"]}</div>
                            </div>
                        })
                    }
                </div>
            </div>
            :
            <br />
    }
    /**
     * 
     * @param {*} list0 
     * @param {*} list1 
     */
    concatArray(list0, list1) {
        try {
            if (isNullOrUndefined(list0) && !isNullOrUndefined(list1)) {
                list0 = [];
            }
            if (!isNullOrUndefined(list0) && isNullOrUndefined(list1)) {
                list1 = [];
            }
            this.setState({ distribuzioneDominioList: list0.concat(list1) });

        } catch (error) {
            return console.error(error);
        }
    }

    print() {
        window.print();
    }

    getAccessToken() {
        return this.props.auth.getAccessToken();
    }

    getRischio() {
        var res = {};
        try {
            var rischio = this.state.rischi.RischioAttuale;
            switch (rischio) {
                case 1:
                    res.name = "BASSO";
                    res.class = "testoVerde";
                    break;
                case 2:
                    res.name = "MEDIO";
                    res.class = "testoArancio";
                    break;
                case 3:
                    res.name = "ALTO";
                    res.class = "testoRosso";
                    break;
                case 4:
                    res.name = "CRITICO";
                    res.class = "testoNero";
                    break;

                default:
                    res.name = "";
                    res.class = "";
                    break;
            }

        } catch (error) {
            console.error(error);
            res.name = "";
            res.class = "";
        }
        return res;
    }

    getConformità(gdi) {
        var res = 0;
        try {
            switch (gdi) {
                case 1:
                    res = 1;
                    break;
                case 2:
                    res = 3;
                    break;
                case 3:
                    res = 3;
                    break;
                case 4:
                    res = 2;
                    break;
                case 5:
                    res = 1;
                    break;
                case 6:
                    res = 1;
                    break;

                default:
                    res = 0;
                    break;
            }

        } catch (error) {
            console.error(error);
            res = 0;
        }
        return res;
    }

    /**
     * 
     */
    calculatePercentage(totale) {
        try {

            return Math.round((totale / this.state.distribuzioneAssessment.Totale) * 100);

        } catch (error) {
            console.error(error);
            return null
        }

    }

    /**
     * 
     */
    getSumOfAnswersProvided() {
        let sum = 0;
        let distribuzioneAssessment = this.state.distribuzioneAssessment;
        try {
            for (const key in distribuzioneAssessment) {
                if (distribuzioneAssessment.hasOwnProperty(key)) {
                    if (key !== 'Totale' && key !== 'TotaleNonRisposto') {
                        const element = parseInt(distribuzioneAssessment[key], 10);
                        //console.log('key:',key,' value:',element);
                        sum += element;
                    }
                }
            }
            return sum;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getIUC() {
        var data = {
            labels: [
                "CONFORMITA': 0%",
                "NON CONFORMITA': 0%"
            ],

            datasets: [
                {
                    data: [100, 0],
                    backgroundColor: [
                        "#CCCCCC",
                    ]
                }]
        };

        if (this.state.rischi !== undefined) {

            if (this.state.rischi.RischioAggregato !== 0) {
                data = {
                    labels: [
                        "CONFORMITA': " + this.state.rischi.RischioAggregato + "%",
                        "NON CONFORMITA': " + (100 - this.state.rischi.RischioAggregato) + "%",
                    ],

                    datasets: [
                        {
                            data: [this.state.rischi.RischioAggregato, 100 - this.state.rischi.RischioAggregato],
                            backgroundColor: [
                                "#28a745",
                                "#CC0000"
                            ]
                        }]
                };
            }
        }
        return data;
    }

    getContestoComplianceIUC(contestoCompliance) {
        var data = {
            labels: [
                "CONFORMITA': 0%",
                "NON CONFORMITA': 0%"
            ],

            datasets: [
                {
                    data: [100, 0],
                    backgroundColor: [
                        "#CCCCCC",
                    ]
                }]
        };


        data = {
            labels: [
                "CONFORMITA': " + contestoCompliance.iuc + "%",
                "NON CONFORMITA': " + (100 - contestoCompliance.iuc) + "%",
            ],

            datasets: [
                {
                    data: [contestoCompliance.iuc, 100 - contestoCompliance.iuc],
                    backgroundColor: [
                        "#28a745",
                        "#CC0000"
                    ]
                }]
        };

        return data;
    }


    getRisposte() {
        var data = {
            labels: [
                "Completo",
                "Adeguato",
                "Migliorabile",
                "Assente",
                "Non so",
                "Non applicabile"
            ],

            datasets: [
                {
                    data: [0, 0, 0, 0, 0, 0],
                    backgroundColor: [
                        "#0066cc",
                        "#6384FF",
                        "#8463FF",
                        "#cc0000",
                        "#ffcc00",
                        "#63FF84"
                    ]
                }]
        };
        if (this.state.distribuzioneAssessment !== undefined) {
            var Completo = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleCompleto);
            var Adeguato = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleAdeguato);
            var Migliorabile = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleMigliorabile);
            var Assente = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleAssente);
            var NonApplicabile = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleNonApplicabile);
            var NonSo = this.calculatePercentage(this.state.distribuzioneAssessment.TotaleNonSoRispondere);

            data = {
                labels: [
                    "Completo",
                    "Adeguato",
                    "Migliorabile",
                    "Assente",
                    "Non so",
                    "Non applicabile"

                ],

                datasets: [
                    {
                        data: [Completo, Adeguato, Migliorabile, Assente, NonSo, NonApplicabile],
                        backgroundColor: [
                            "#0066cc",
                            "#6384FF",
                            "#8463FF",
                            "#cc0000",
                            "#ffcc00",
                            "#63FF84"
                        ]
                    }]
            };
        }
        return data;
    }


    /**
     * 
     */
    getGradoImplementazioneDomini(distribuzioneDominioList) {
        //console.log("concat_state", this.state.distribuzioneDominioList);
        //console.log("concat_par", distribuzioneDominioList);
        return (

            <GradoImplementazioneDominiRow
                key={this.state.distribuzioneDominioList.CategoriaMisuraDiSicurezzaId}
                distribuzioneDominio={this.state.distribuzioneDominioList}
            />
        )
    }

    /**
     * 
     */
    getRadarChartData(distribuzioneDominioList) {

        let distribuzioneDominioNames = [];
        let distribuzioneDominioImplement = [];
        for (let index = 0; index < distribuzioneDominioList.length; index++) {
            distribuzioneDominioNames.push(distribuzioneDominioList[index].Nome);
            distribuzioneDominioImplement.push(parseFloat(distribuzioneDominioList[index].Implementazione));

        }

        var options_ = {
            tooltips: {
                mode: 'label'
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
            },
            responsive: true,
            maintainAspectRatio: false,

            scale: {
                pointLabels: {
                    fontStyle: "bold"
                },
                ticks: {
                    beginAtZero: true,
                    max: 4,
                    stepSize: 1
                }
            },
            animation: {
                onComplete: function (animation) {
                    var chartRadar = $("#radarChart").get(0);
                    var canvasRadar = $("#canvasRadar").get(0);

                    var ctx = chartRadar.getContext('2d');

                    var w = chartRadar.width,
                        h = chartRadar.height,
                        pix = { x: [], y: [] },
                        imageData = ctx.getImageData(0, 0, chartRadar.width, chartRadar.height),
                        x, y, index;

                    for (y = 0; y < h; y++) {
                        for (x = 0; x < w; x++) {
                            index = (y * w + x) * 4;
                            if (imageData.data[index + 3] > 0) {
                                pix.x.push(x);
                                pix.y.push(y);
                            }
                        }
                    }
                    pix.x.sort(function (a, b) { return a - b });
                    pix.y.sort(function (a, b) { return a - b });
                    var n = pix.x.length - 1;

                    w = pix.x[n] - pix.x[0];
                    h = pix.y[n] - pix.y[0];
                    var cut = ctx.getImageData(pix.x[0], pix.y[0], w + 1, h + 1);

                    canvasRadar.width = w + 1;
                    canvasRadar.height = h + 1;

                    var ctx1 = canvasRadar.getContext('2d');
                    ctx1.putImageData(cut, 0, 0);
                    var dataURL = canvasRadar.toDataURL();

                    var img = $('<img></img>');
                    img.attr("src", dataURL);
                    $("#canvasRadar").replaceWith(img);
                }
            }

        };

        var data_ = {
            //labels: ["Gestione Accessi Logici", "Gestione Asset", "Gestione Continuità Operativa", "Gestione Dispositivi Mobili", "Gestione Incidenti di Sicurezza delle Informazioni", "Gestione Minacce e Vulnerabilità", "Gestione Privacy", "Gestione Rete", "Gestione Risorse Umane", "Gestione Sistemistica"],
            labels: distribuzioneDominioNames,
            datasets: [
                {
                    label: "",
                    backgroundColor: "rgba(179,181,198,0.2)",
                    borderColor: "#28a745",
                    pointBackgroundColor: "rgba(179,181,198,1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(179,181,198,1)",
                    //data: [0, 5, 9, 8, 6, 5, 4, 2, 3, 5]
                    data: distribuzioneDominioImplement
                }
            ]
        };
        //console.log("names", distribuzioneDominioNames);
        //console.log("val", distribuzioneDominioImplement);
        return { data: data_, options: options_ }
        //return <Radar id="myChart" data={data} style={{ width: "70%", height: "70%" }} options={options} />;

    }

    /**
     * 
     */
    updateListAssessmentRischioDerivato(list) {
        this.setState({ listAssessmentRischioDerivato: list });
        console.log("updateRischioDerivatoList", list);
    }

    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];

        if (infoAggiuntive != null && infoAggiuntive != undefined) {
            //infoAggiuntive.split('\n').map((item, key) => {
            let array = infoAggiuntive.split('\n');
            for (let key = 0; key < array.length; key++) {
                const item = array[key];


                var indices = [];
                var element = '<b>';
                var idx = item.indexOf(element);
                while (idx != -1) {
                    indices.push(idx);
                    idx = item.indexOf(element, idx + 1);
                }

                var indexStart = item.indexOf('<b>');
                var indexEnd = item.indexOf('</b>');

                if (indices.length > 0) {
                    var stringList = [];

                    for (var x = 0; x < indices.length; x++) {

                        var indexStart = indices[x];
                        var indexEnd = item.indexOf('</b>', indices[x]);
                        var testoPrima = '';

                        if (x === 0) {
                            testoPrima = item.substring(0, indexStart);
                        }

                        var indiceTestoDopo = item.length;
                        if (indices[x + 1] !== undefined) {
                            indiceTestoDopo = indices[x + 1];
                        }

                        var testoBold = item.substring(indices[x] + 3, indexEnd);
                        var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                        var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                        stringList.push(element);
                    }

                    toString.push(<span key={key}>{stringList}<br /></span>);
                    //return <span key={key}>{stringList}<br /></span>;

                    //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                }
                else {
                    toString.push(<span key={key}>{item}<br /></span>);
                    //return <span key={key}>{item}<br /></span>;
                }
            }
        }
        return toString;
    }

    onDocumentLoadSuccess({ numPages }) {
        console.log("numPages", numPages);
        this.setState({ setNumPages: numPages });
    }

    openPdfPage() {
        let base64URL = this.state.datiAsset.filter(da => da.Tipo == 'Report Risk Assessment').length > 0 ?
            "data:application/pdf;base64," + this.state.datiAsset.filter(da => da.Tipo == 'Report Risk Assessment')[0].Dati : '#';
        let win = window.open();
        win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    render() {

        console.log("render");
        /*
        var dataPDF;
        if (this.state.datiAsset.length > 0)
            dataPDF = 'data:application/pdf;base64,' + this.state.datiAsset.filter(da => da.Tipo == 'Report Risk Assessment')[0].Dati;
        */

        var opt2 = {
            legend: {
                display: true,
                position: 'right',
                align: 'center',
                labels: {
                    fontStyle: 'bold',
                    // filter: function(item, chart) {
                    //     // Logic to remove a particular legend item goes here
                    //     return !item.text.includes('no');
                    // }
                }

            },

            animation: {
                onComplete: function (animation) {
                    var pie3 = $("#pie3");
                    var canvasPrint3 = $("#canvas3");
                    var dataURL3 = pie3.get(0).toDataURL();

                    var img2 = $('<img style="width:400px; height:200px"></img>');
                    img2.attr("src", dataURL3);
                    canvasPrint3.replaceWith(img2);
                    //$("#stampaBtn").removeAttr("disabled");
                    this.setState({ abilitaStampa: (this.state.statoAssessmentId > 3) });
                    //window.getImage();
                }.bind(this)
            },
            tooltips: {
                enabled: false
            }
        }
        //console.log("dataRAdar",data);
        var rows = [];
        if (this.state.ListaCategorieMinaccia.length === 0) {
            rows = <div className="row"></div>
        } else {
            for (let i = 0; i < this.state.ListaCategorieMinaccia.length; i++) {
                const element = this.state.ListaCategorieMinaccia[i];
                rows.push(
                    <RiepilogoDatiRow
                        key={i}
                        rowId={i}
                        updateGrade={this.updateGrade}
                        NomeCategoria={element.NomeCategoria}
                        ListaTipologie={element.ListaTipologie}
                        updateListAssessmentRischioDerivato={this.updateListAssessmentRischioDerivato}
                    />);
                //rows.push(<div key={"spazio"+i} className="spazio20"></div>);
            }

        }

        var contestoComplianceRows = [];

        if (this.state.contestoComplianceGap.length != 0) {

            for (let i = 0; i < this.state.contestoComplianceGap.length; i++) {
                const element = this.state.contestoComplianceGap[i];



                contestoComplianceRows.push(
                    <div className="row" key={"key" + element.ContestoComplianceId}>
                        <div className="col-lg-10">
                            <a className="card-link " data-bs-toggle="collapse" href={"#collapse" + element.ContestoComplianceId}>
                                {element.Nome}</a>
                            <i className="iconRight fa fa-chevron-down"></i>
                        </div>
                    </div>);

                let remediationRows = [];
                let arrayRemediation = this.state.listaRemediation;
                let tot = arrayRemediation.length;
                for (let index = 0; index < arrayRemediation.length; index++) {
                    if (arrayRemediation[index] && (arrayRemediation[index].ContestoComplianceId == element.ContestoComplianceId) && arrayRemediation[index].GradoDiImplementazione > 1 && arrayRemediation[index].GradoDiImplementazione < 6) {

                        var rilevanza = arrayRemediation[index].Rilevanza;
                        var conformità = this.getConformità(arrayRemediation[index].GradoDiImplementazione);
                        var id = arrayRemediation[index].AssessmentAzioniDiTrattamentoId;
                        var testoRilevanza = rilevanza == 1 ? "BASSA" : rilevanza == 2 ? "MEDIA" : "ALTA";
                        var testoConformità = conformità == 1 ? "ALTA" : conformità == 2 ? "MEDIA" : "BASSA";

                        remediationRows.push(<div className="row" key={id + "remediation1" + (index)}>

                            <div className="col-lg-2"><strong>Requisito N° {index + 1}/{tot}</strong></div>
                            <div className="col-lg-2">
                                <strong>Rilevanza </strong>
                                <span className={getClassName(arrayRemediation[index].Rilevanza)}>
                                    &nbsp;&nbsp;{testoRilevanza}</span>
                            </div>
                            <div className="col-lg-2">
                                <strong>Conformità </strong>
                                <span className={getClassName(conformità)}>
                                    &nbsp;&nbsp;{testoConformità}</span>
                            </div>
                            <div className="col-lg-6"></div>
                            <div className="col-lg-12"><hr /></div>
                            <div className="col-lg-12">
                                <p style={{ color: '#0066cc' }}>
                                    {arrayRemediation[index].SottoCategoria}
                                    <br />
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <p style={{ textAlign: 'justify' }}>
                                    {this.toStringInfoAggiuntive(arrayRemediation[index].Azione)}
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <p style={{ color: '#0066cc' }}>
                                    Riferimento Normativo
                                    <br />
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <p style={{ textAlign: 'justify' }}>
                                    {this.toStringInfoAggiuntive(arrayRemediation[index].RifNormativo)}
                                </p>
                            </div>
                            <div className="col-lg-12"><br></br></div>
                        </div>);
                    }
                }
                // <hr />
                contestoComplianceRows.push(<div id={"collapse" + element.ContestoComplianceId} className="collapse marginLeft" data-parent="#accordion">
                    <div className="row">

                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">

                            <Pie data={this.getContestoComplianceIUC(element)} width={200} height={100} options={opt2} />
                        </div>
                        <div className="col-lg-4"></div>

                        <div className="col-lg-12 giustifica" style={{ marginTop: "20px" }}>
                            Si riportano le azioni di rientro raccomandate e suggerite, necessarie per raggiungere la conformità.
                        </div>
                    </div>
                    <div className="spazio10"></div>
                    {remediationRows}
                    <hr />
                </div>);
            }

        }



        var opt = { legend: { display: true, position: 'bottom' } }

        let percentage = this.calculatePercentage(this.getSumOfAnswersProvided());

        var oilData = {
            labels: [
                "RISPOSTE FORNITE " + percentage + "% - " + this.getSumOfAnswersProvided() + "/" + this.state.distribuzioneAssessment.Totale,
                "NON RISPOSTO " + (100 - percentage) + "% - " + (this.state.distribuzioneAssessment.Totale - this.getSumOfAnswersProvided()) + "/" + this.state.distribuzioneAssessment.Totale,
            ],

            datasets: [
                {
                    data: [percentage, 100 - percentage],
                    backgroundColor: [
                        "#28a745",
                        "#ccc"
                    ]
                }]
        };

        var opt1 = {
            legend: { display: true, position: 'right', onClick: function (event, legendItem) { } },
            animation: {
                onComplete: function (animation) {
                    // var pie2 = $("#pie2");
                    // var canvasPrint2 = $("#canvas2");
                    // var dataURL2 = pie2.get(0).toDataURL();

                    // var img = $('<img style="width:200px; height:100px"></img>');
                    // img.attr("src", dataURL2);
                    // canvasPrint2.replaceWith(img);
                }
            }
        }


        var optLine = {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 100
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'IUC',
                        fontStyle: "bold"
                    }
                }],
                xAxes: [{
                    type: 'category'
                }]
            }
        }

        var dataLine = {
            datasets: [{
                fill: false,
                lineTension: 0,
                backgroundColor: '#FFF',
                borderColor: '#0066cc',
                data: [20, 50, 50, 95]
            }],
            labels: ['10/01/2020 10:55', '15/01/2020 14:00', '20/01/2020 14:00', '21/01/2020 09:41']
        }
        //console.log('distribuzioneDominio', this.state.distribuzioneDominio);
        let distribuzioneDominioRows = [];
        let array = this.state.distribuzioneDominio;
        for (let index = 0; index < array.length; index++) {
            if (!this.state.distribuzioneDominio[index]) {
                distribuzioneDominioRows[index] = <div className="col-lg-4">Loading...</div>
            } else {
                distribuzioneDominioRows[index] = this.state.distribuzioneDominio[index].map(function (item, i) {
                    //console.log('distribuzioneDominio',item);
                    return (
                        <div key={item.CategoriaMisuraDiSicurezzaId}>{item.Nome} <span className="float-right">{item.TotaleRisposto + '/' + item.Totale}</span><br /></div>
                    );
                }.bind(this)
                );
            }
        }


        var titolo;
        var graficoRischio = '';
        var titoloGrafico1 = 'Stato compilazione questionario';
        var titoloGrafico2 = 'Distribuzione risposte questionario';
        var testoBreadcrumb = 'Risultati GAP Analysis';

        if (this.props.PA === "true") {
            titoloGrafico1 = 'Rischio complessivo';
            titoloGrafico2 = 'Stato compilazione questionario';
            testoBreadcrumb = 'Risultati analisi per PA';

            titolo = "Risultati analisi del rischio per Azienda - " + this.state.nomePA;

            graficoRischio = <div className="gauge4liv gauge--liveupdate2" id="gauge4liv">
                <div className="gauge__container4liv">
                    <div className="gauge__marker4liv"></div>
                    <div className="gauge__markerbis4liv"></div>
                    <div className="gauge__markertris4liv"></div>
                    <div className="gauge__background4liv"></div>
                    <div className="gauge__center4liv"></div>
                    <div className="gauge__data4liv"></div>
                    <div className="gauge__data4livB"></div>
                    <div className="gauge__data4livC"></div>
                    <div className="gauge__data4livD"></div>
                    <div className="gauge__needle4liv"></div>
                </div>
                <div className="gauge__labels4liv mdl-typography__headline4liv">
                    <span className="gauge__label--low4liv">BASSO</span>
                    <span className="gauge__label--spacer4liv">
                        <span className={this.getRischio().class}>{this.getRischio().name}</span>
                    </span>
                    <span className="gauge__label--high4liv">CRITICO</span>
                </div>
            </div>;

        }
        else {
            titolo = "Risultati Applicazione: " + sessionStorage.getItem("serviceName");
        }

        var graficoCompilazione = <div className="gaugeImpatti gauge--liveupdateImpatti" id="gaugeImpatti">
            <div className="gauge__containerImpatti">
                <div className="gauge__backgroundImpatti"></div>
                <div className="gauge__centerImpatti"></div>
                <div className="gauge__dataImpatti"></div>
                <div className="gauge__dataImpattiB"></div>
                <div className="gauge__needleImpatti"></div>
            </div>
            <div className="gauge__labelsImpatti mdl-typography__headlineImpatti">
                <span className="gauge__label--lowImpatti">0</span>
                <span className="gauge__label--spacerImpatti">{this.getSumOfAnswersProvided()} ({percentage}%)</span>
                <span className="gauge__label--highImpatti">{this.state.distribuzioneAssessment.Totale}</span>
            </div>

        </div>;

        return (


            <div id="main">

                <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                    <div className="container">


                        <div className="row">
                            <div className="col-lg-12">

                                <nav className="" aria-label="sei qui:" role="navigation">
                                    <ul className="Breadcrumb">
                                        <li className="Breadcrumb-item"><a className="u-color-50" href='javascript:void(0)' onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                        <li className="Breadcrumb-item"><a className="u-color-50" href='javascript:void(0)'>Reporting e Monitoring</a></li>
                                        <li className="Breadcrumb-item"><a className="u-color-50" href="#">Risultati per Applicazione</a></li>
                                    </ul>
                                </nav>
                                {/* <Breadcrumb links={LINK} /> */}

                            </div>
                            <div className="col-lg-12 card-headerInfo">
                                <span className="titoloPagina">{titolo}</span>
                            </div>
                            <div className="col-lg-12  spazio10"></div>
                            <div className="col-lg-12">
                                <nav className="nav nav-tabs" id="navResults" role="tablist">
                                    <a className="nav-item nav-link active" id="nav-gap-tab" data-bs-toggle="tab" href="#nav-gap" role="tab" aria-controls="gap" aria-expanded="true">Gap Analysis</a>

                                    <a className="nav-item nav-link" id="nav-risk-tab" data-bs-toggle="tab" href="#nav-risk" role="tab" aria-controls="risk" aria-expanded="false">Risk Analysis</a>

                                    {/* <a className="nav-item nav-link"  id="nav-dpia-tab" data-bs-toggle="tab" href="#nav-dpia" role="tab" aria-controls="dpia" aria-expanded="true">DPIA</a> */}
                                </nav>
                                <div className="tab-content" id="nav-tabContent">

                                    <div className="tab-pane fade active show" id="nav-gap" role="tabpanel" aria-labelledby="nav-gap-tab" aria-expanded="true">
                                        <div className="row">

                                            <div className="col-lg-10 giustifica">
                                                <p>La pagina espone il riepilogo dei risultati della Gap Analysis, attraverso i seguenti indicatori: 1) Stato compilazione questionario; 2) Distribuzione delle risposte per dominio di sicurezza, che fornisce il dettaglio delle risposte fornite per singolo dominio; 3) Indice Unico di Conformità dell’applicazione; 4) dettaglio del Piano delle Remediation.</p>
                                            </div>
                                            <div className="col-lg-2 card-headerInfo">
                                                <ReactToPrint
                                                    trigger={() => <button type="button" disabled={!this.state.abilitaStampa} id="stampaBtn" className="btn btn-warning btn-lg btn-block">STAMPA REPORT</button>}
                                                    content={() => this.componentRef} bodyClass="printTest" copyStyles={false}
                                                    pageStyle={PageStyle}
                                                />
                                                <div style={{ display: 'none' }}>

                                                    <ReportStampa
                                                        PA={this.props.PA}
                                                        ref={el => (this.componentRef = el)}
                                                        ListaCategorieMinaccia={this.state.ListaCategorieMinaccia}
                                                        distribuzioneAssessment={this.state.distribuzioneAssessment}
                                                        distribuzioneTrattamento={this.state.distribuzioneTrattamento}
                                                        rischi={this.state.rischi}
                                                        distribuzioneDominio={this.state.distribuzioneDominio}
                                                        nomePA={this.state.nomePA}
                                                        listaRemediation={this.state.listaRemediation}
                                                        contestoNormativo={this.state.contestoNormativo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="linea0066cc"></div>



                                            <div className="spazio10"></div>

                                            <div className="col-lg-8"><h1 className="testoBlu" style={{ color: "white" }}>Compilazione questionario</h1><hr />
                                            </div>

                                            <div className="col-lg-4"><h1 className="testoBlu" style={{ color: "white" }}>Indice Unico Conformità</h1><hr />
                                            </div>
                                            <div className="col-lg-4">
                                                <span className=" testoBlu paRight"><img src={infoIcon} alt="" /></span>
                                                <strong>{" " + titoloGrafico1}</strong>
                                                <a data-bs-toggle="collapse" href="#collapse6"> </a>
                                                <div className="spazio20"></div>

                                                {this.props.PA === "true" && graficoRischio}
                                                {this.props.PA !== "true" && graficoCompilazione}

                                                <br />

                                            </div>
                                            <div className="col-lg-4">

                                                <span className=" testoBlu paRight"><img src={infoIcon} alt="" /></span> <strong>{" " + titoloGrafico2}</strong>
                                                <a data-bs-toggle="collapse" href="#collapse6"> </a>
                                                <div className="spazio20"></div>

                                                {this.props.PA !== "true" && <Pie id="pie2" data={this.getRisposte()} width={200} height={100} options={opt1} />}
                                                {this.props.PA === "true" && graficoCompilazione}
                                            </div>

                                            <div className="col-lg-4">
                                                <span className=" testoBlu paRight" ><img src={infoIcon} alt="" /></span>		 <strong style={{ color: "#fff" }}>- </strong>
                                                <a data-bs-toggle="collapse" href="#collapse6"> </a>
                                                <div className="spazio20"></div>
                                                <Pie id="pie3" data={this.getIUC()} width={200} height={100} options={opt2} />
                                            </div>



                                        </div>




                                        <div className="spazio10"></div>



                                        <div className="row">

                                            <div className="col-lg-12">
                                                <h1 >Azioni di rientro per normativa</h1>  <hr />
                                            </div>

                                            {/* <div className="col-lg-12 giustifica">	                           
                                                        <p>Si riportano le azioni di rientro raccomandate e suggerite, necessarie per raggiungere la conformità al complesso di disposizioni normative di riferimento.</p>
                                                        </div> */}

                                        </div>
                                        {contestoComplianceRows}
                                        <div className="spazio10"></div>


                                    </div>
                                    <div className="tab-pane fade" id="nav-risk" role="tabpanel" aria-labelledby="nav-risk-tab" aria-expanded="true">
                                        <div className="row">
                                            <div className="col-lg-9"></div>
                                            <div className="col-lg-3">

                                                <a
                                                    hidden={this.state.datiAsset.filter(da => da.Tipo == 'Report Risk Assessment').length == 0}
                                                    onClick={() => this.openPdfPage()}
                                                    className="btn btn-successOR btn-lg">Scarica Report Analisi Rischio</a>
                                            </div>
                                        </div>

                                        <div className="col-lg-12" data-bs-toggle="collapse" href="#riskAssessment"><a className='card-link' target='_blank' href='https://crmt.westeurope.cloudapp.azure.com'>Risk Assessment</a></div>
                                        <div className="collapse" id="riskAssessment">{this.getRiskAnalysisSection()}</div>

                                        {this.getAzioniTrattamentoSection()}


                                        {/*
                                        <div
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                                height: '1000px',
                                                overflow: 'auto',
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Document file={dataPDF} onLoadSuccess={this.onDocumentLoadSuccess}  >
                                                {Array.from(new Array(this.state.setNumPages), (el, index) => (
                                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1} />
                                                ))}

                                            </Document>
                                                </div>*/}
                                    </div>
                                    {/* <div className="tab-pane fade" id="nav-dpia" role="tabpanel" aria-labelledby="nav-dpia-tab" aria-expanded="true"> 
                        </div> */}


                                </div>
                            </div>

                        </div>




                    </div>
                    <br />
                    <br />
                    <br />
                    {/* <RischioDerivatoPerServizioTable listAssessmentRischioDerivato={this.state.listAssessmentRischioDerivato} /> */}
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
                </div>
            </div>
        )
    }
}

