import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';
import { ValutazioneImpattiRow } from './ValutazioneImpattiRow';
import { ModalDialog } from '../Common/ModalDialog';
/*import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "", url: "/" }
];*/

/**
 * 
 * @param {*} array 
 */
function toString(array) {
    let toString = '';
    try {
        for (let index = 0; index < array.length; index++) {
            const element = array[index].Nome;
            toString += element + ' | ';
        }
        return toString.substring(0, toString.length - 2);
    } catch (error) {
        console.error(error);
        return '';
    }
}

/*
[
  {
    "Bozza": "string",
    "NotaR": "string",
    "NotaI": "string",
    "NotaD": "string",
    "listImpattoDettaglio": [
      [
        {
          "NomeServizio": "string",
          "NomeTipologiaImpatto": "string",
          "TipologiaImpattoId": 0,
          "ImpattoR": 0,
          "ImpattoI": 0,
          "ImpattoD": 0
        }
      ]
    ]
  }
]
*/

export class ValutazioneImpattiModifica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listImpattoDettaglio: [],
            tableHeaders: ['', '', '', ''],
            serviceId: window.sessionStorage.getItem('serviceId'),
            //serviceId: 'Test01',
            serviceName: '',
            descrizione: '',
            notaR: '',
            notaI: '',
            notaD: '',
            categorieToString: '',
            tipologieToString: '',
            messageError: '',
            messageWarning: '',
            updateDate: false,
            dataAggiornamento: '',
            dataCalcoloImpatto: ''
        }
        //check
        this.checkCalcoloImpatto = this.checkCalcoloImpatto.bind(this);
        //GET
        this.getListImpattoDettaglio = this.getListImpattoDettaglio.bind(this);
        //VALIDATE
        this.isValidate = this.isValidate.bind(this);
        //UPDATE
        this.updateImpattoR = this.updateImpattoR.bind(this);
        this.updateImpattoI = this.updateImpattoI.bind(this);
        this.updateImpattoD = this.updateImpattoD.bind(this);
        this.updateNotaR = this.updateNotaR.bind(this);
        this.updateNotaI = this.updateNotaI.bind(this)
        this.updateNotaD = this.updateNotaD.bind(this)
        //SAVE
        this.saveDraft = this.saveDraft.bind(this);
        this.save = this.save.bind(this);
        this.isUpdated = this.isUpdated.bind(this);
    }

    // on mount, get all categories and store them in this component's state
    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        console.log(sessionStorage.getItem('token'));
        // submit form data to api
        //curl -X POST "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \"ServizioId\": \"S2\"}"
        this.serverRequest =
            $.ajax({
                //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById',
                url: API_CONFIG.impattocompositeGetById,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ ServizioId: this.state.serviceId }),//data:"{ \"ServizioId\": \"S2\"}",
                cache: false,
                success: function (response) {
                    console.log('response', response.data);
                    this.setState({ data: response.data });
                    this.setState({ listImpattoDettaglio: this.getListImpattoDettaglio(response.data.listImpattoDettaglio) });
                    this.setState({ tableHeaders: this.getTableHeaders(response.data.listImpattoDettaglio) })
                    this.setState({ referente: response.data.Referente });
                    this.setState({ struttura: response.data.StrutturaReferente });
                    this.setState({ serviceName: response.data.NomeServizio });
                    this.setState({ descrizione: response.data.Descrizione });
                    this.setState({ dataAggiornamento: response.data.DataCompilazioneImpatto });
                    this.setState({ dataCalcoloImpatto: response.data.DataUpdateImpatto });
                    this.setState({ categorieToString: toString(response.data.Categorie) });
                    this.setState({ tipologieToString: toString(response.data.Tipologie) });
                    this.setState({ notaR: response.data.NotaR });
                    this.setState({ notaI: response.data.NotaI });
                    this.setState({ notaD: response.data.NotaD });
                    /*
                    this.setState({servicesPrimary:this.getServiziComposite(response.data)[1]});
                    this.setState({servicesSecondary:this.getServiziComposite(response.data)[0]});
                  */
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
    }

    componentWillUnmount() {
        //TODO
    }
    /**
     * Rimappa la lista degli impatti in modo tale che le colonne siano all'ineate correttamente all'header della tabella
     * @param {*} list 
     */
    getListImpattoDettaglio(array) {
        let listImpattoDettaglio = [];
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            switch (element.TipologiaImpattoId) {
                case 1://Economico / Finanziario
                    listImpattoDettaglio[0] = element;
                    break;
                case 2://Operativo
                    listImpattoDettaglio[3] = element;

                    break;
                case 3://Reputazionale
                    listImpattoDettaglio[1] = element;

                    break;
                case 4://Legale / Compliance
                    listImpattoDettaglio[2] = element;

                    break;
                default:
                    break;
            }
        }
        return listImpattoDettaglio;
    }

    isUpdated() {
        try {

            let tsDataAggiornamento = new Date(this.state.dataAggiornamento).getTime();
            let tsDataCalcoloImpatto = new Date(this.state.dataCalcoloImpatto).getTime();

            return tsDataAggiornamento > tsDataCalcoloImpatto;
        } catch (error) {
            return false;
        }
    }

    /**
     * get the array with NomeTipologiaImpatto for the table headers
     * @param {*} array 
     */
    getTableHeaders(array) {
        let tableHeaders = [];
        try {
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                switch (element.TipologiaImpattoId) {
                    case 1://Economico / Finanziario
                        tableHeaders[0] = element.NomeTipologiaImpatto.split('/')[0].trim();
                        break;
                    case 2://Operativo
                        tableHeaders[3] = element.NomeTipologiaImpatto;
                        break;
                    case 3://Reputazionale
                        tableHeaders[1] = element.NomeTipologiaImpatto;

                        break;
                    case 4://Legale / Compliance
                        tableHeaders[2] = element.NomeTipologiaImpatto.replace(/ /g, '');

                        break;
                    default:
                        break;
                }
            }
            return tableHeaders;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} tipologiaImpattoId 
     * @param {*} value 
     */
    updateImpattoR(tipologiaImpattoId, value, impatto) {
        let listImpattoDettaglio = this.state.listImpattoDettaglio;
        //console.log('updateImpattoR', impatto);
        for (let index = 0; index < listImpattoDettaglio.length; index++) {
            if (listImpattoDettaglio[index].TipologiaImpattoId === tipologiaImpattoId) {
                listImpattoDettaglio[index].ImpattoR = value;
            } else {

            }
        }
        this.setState({ listImpattoDettaglio: listImpattoDettaglio }, () => this.saveDraft());
    }

    /**
     * 
     * @param {*} tipologiaImpattoId 
     * @param {*} value 
     */
    updateImpattoI(tipologiaImpattoId, value, impatto) {
        let listImpattoDettaglio = this.state.listImpattoDettaglio;
        //console.log('updateImpattoI', impatto);
        for (let index = 0; index < listImpattoDettaglio.length; index++) {
            if (listImpattoDettaglio[index].TipologiaImpattoId === tipologiaImpattoId) {
                listImpattoDettaglio[index].ImpattoI = value;
            } else {

            }
        }
        this.setState({ listImpattoDettaglio: listImpattoDettaglio }, () => this.saveDraft());
    }


    /**
     * 
     * @param {*} tipologiaImpattoId 
     * @param {*} value 
     */
    updateImpattoD(tipologiaImpattoId, value, impatto) {
        let listImpattoDettaglio = this.state.listImpattoDettaglio;
        //console.log('updateImpattoD', impatto);
        for (let index = 0; index < listImpattoDettaglio.length; index++) {
            if (listImpattoDettaglio[index].TipologiaImpattoId === tipologiaImpattoId) {
                listImpattoDettaglio[index].ImpattoD = value;
            } else {

            }
        }
        this.setState({ listImpattoDettaglio: listImpattoDettaglio }, () => this.saveDraft());
    }

    updateNotaR(event) {
        let val = event.target.value;
        this.setState({ notaR: val });
    }

    updateNotaI(event) {
        let val = event.target.value;
        this.setState({ notaI: val });
    }

    updateNotaD(event) {
        let val = event.target.value;
        this.setState({ notaD: val });
    }

    isValidate() {
        try {
            var array = this.state.data.listImpattoDettaglio;
            for (let index = 0; index < array.length; index++) {
                let impattoR = array[index].ImpattoR;
                let impattoI = array[index].ImpattoI;
                let impattoD = array[index].ImpattoD;
                if (impattoR === -1 || impattoD === -1 || impattoI === -1) {
                    return false;
                }

            }
            return true
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    isChechkNote() {
        let notaD = this.state.notaD;
        let notaI = this.state.notaI;
        let notaR = this.state.notaR;
        if (notaD.length === 0 || notaI.length === 0 || notaR.length === 0)
            return 'hidden';
        else return 'visible';
    }

    /**
    * handle save button clicked
    */
    save(e) {
        // data in the form
        const { data } = this.state;
        data['Bozza'] = undefined;

        data.NotaR = this.state.notaR;
        data.NotaI = this.state.notaI;
        data.NotaD = this.state.notaD;

        data.listImpattoDettaglio = this.state.listImpattoDettaglio;
        this.setState({ data: data });

        console.log('save', JSON.stringify(this.state.data));



        if (this.isValidate()) {
            // submit form data to api
            $.ajax({
                //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/impattocomposite/saveOrUpdate',
                url: API_CONFIG.impattocompositeSaveOrUpdate,
                type: "POST",
                contentType: 'application/json',
                headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(data),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                        history.push('/ListaServiziImpatti');
                        //alert(response.status);
                    }
                    else {
                        //TODO
                    }
                },

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });

        } else {
            this.setState({ messageWarning: 'Prima di effettuare il Calcolo compilare tutte le Sezioni' });
            $('#btnValidate').get(0).click();
        }

        e.preventDefault();
    }

    saveDraft() {
        // data in the form
        let data = this.state.data;
        data.NotaR = this.state.notaR;
        data.NotaI = this.state.notaI;
        data.NotaD = this.state.notaD;
        data['Bozza'] = 'string';

        console.log('saveDraft', data);
        // submit form data to api
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/impattocompositeserv/api/impattocomposite/saveOrUpdate',
            url: API_CONFIG.impattocompositeSaveOrUpdate,
            type: "POST",
            contentType: 'application/json',
            headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
            data: JSON.stringify(data),
            cache: false,
            success: function (response) {
                if (response.status === 'success') {
                    //console.log(response);
                }
                else {
                    console.log(response);
                    this.setState({ messageError: 'Errore durante il salvataggio!' });
                    $('#btnError').get(0).click();
                }
            },

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
        });

        //e.preventDefault();
    }

    /**
     * 
     */
    checkCalcoloImpatto() {
        let msg = "Proseguendo con l'operazione le eventuali analisi del rischio attuali potrebbero essere NON coerenti con le modifiche apportate";
        this.setState({ messageWarning: msg });
        $('#btnCheckCalcoloImpatto').get(0).click();

    }

    render() {

        var rows = '';
        if (!this.state.listImpattoDettaglio.length) {
            rows = <div className="row">Loading...</div>
        } else {
            //console.log('serviceComposite:',this.state.serviziComposite);
            rows = this.state.listImpattoDettaglio.map(function (impatto, i) {
                return (
                    <ValutazioneImpattiRow
                        key={i}
                        rowId={i}
                        listImpatto={this.state.listImpattoDettaglio}
                        impatto={impatto}
                        updateImpattoR={this.updateImpattoR}
                        updateImpattoI={this.updateImpattoI}
                        updateImpattoD={this.updateImpattoD}
                    />
                );
            }.bind(this)
            );
        }

        let InfoIconHidden = (!((this.state.referente != null && this.state.referente.length > 0)
            && (this.state.struttura != null && this.state.struttura.length > 0))) ? "hidden" : "visible";

        let modalWaringAction = <ModalDialog idTarget={'warningAction'} idBtn={'btnCheckCalcoloImpatto'} handle={this.save} textModal={this.state.messageWarning} />
        let modalWaringNoAction = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.messageWarning} />
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" href="javascript:void(0)" onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" href="javascript:void(0)" onClick={() => history.push('/ValutazioneImpatti')}>Valutazione impatti</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)" onClick={() => history.push('/ListaServiziImpatti')}>Elenco servizi</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Modifica servizio</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Modifica Valutazione Impatti del Servizio: {this.state.serviceName}</span>&nbsp;<i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco delle proprietà di informazione (R-I-D) che l’utente è chiamato a compilare per i relativi criteri di impatto. Le modifiche ai dati vengono salvate automaticamente, per procedere con il <strong>calcolo dell’impatto R-I-D</strong> è necessario premere il tasto “<strong>Calcola Impatto R-I-D</strong>”.</p>

                            <span className="paRight InfoAgg">* campo obbligatorio&nbsp; &nbsp;  <i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i> {this.isUpdated() && "servizio modificato"} </span>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                            <div className="row">
                                <div className="col-lg-12 card-headerInfo">
                                    <a className="card-linkInfo testoNero" data-bs-toggle="collapse" href="#collapseInfo">
                                        <i className="testoVerde fa fa-check" style={{ visibility: InfoIconHidden }}
                                        ></i>{" "}Informazioni generali
                                    <i className="iconRight fa fa-chevron-down"></i>
                                    </a>
                                </div>
                            </div>

                        <hr />

                        <div id="collapseInfo" className="col-lg-12 collapse" data-parent="#accordion">
                            <div className="row  card-headerLight">
                                <div className="col-lg-6">
                                    <strong>Referente:</strong> <br />
                                    {this.state.referente}
                                    <i hidden className="fa fa-pencil font12"></i>
                                </div>
                                <div className="col-lg-6">
                                    <strong> Struttura:</strong> <br />
                                    {this.state.struttura}
                                    <i hidden className="fa fa-pencil font12"></i>
                                </div>
                                <div className="col-lg-6"><hr />
                                    <strong>Categorie</strong><br />
                                    {this.state.categorieToString}
                                </div>
                                <div className="col-lg-6"><hr />
                                    <strong>Tipologie</strong><br />
                                    {this.state.tipologieToString}
                                </div>
                                <div className="col-lg-12 ">
                                    <hr />  <p><strong>Descrizione</strong><br />
                                        {this.state.descrizione}</p>
                                </div>
                                <div className="col-lg-12 ">
                                    <div className="">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {rows}
                        <div className="row">
                            <div className="col-lg-12">
                                <a className="card-linkInfo" data-bs-toggle="collapse" href="#collapseNote">
                                    <i style={{ visibility: this.isChechkNote() }}
                                        className="testoVerde fa fa-check"></i>{" "}Note
                                    <i className="iconRight fa fa-chevron-down"></i>
                                </a>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div id="collapseNote" className="col-lg-12 collapse marginLeft" data-parent="#accordion">
                            <div className="row  card-headerLight">
                                <div className="col-lg-2 spazio10">
                                    <strong>Riservatezza</strong>
                                </div>
                                <div className="col-lg-10 spazio10">
                                    <input type="text"
                                        onChange={this.updateNotaR}
                                        className="form100"
                                        value={this.state.notaR}
                                        onBlur={this.saveDraft}
                                        placeholder="Inserisci nota" />
                                </div>

                                <div className="col-lg-2 spazio10">
                                    <strong>Integrità</strong>
                                </div>
                                <div className="col-lg-10 spazio10">
                                    <input type="text"
                                        onChange={this.updateNotaI}
                                        className="form100"
                                        value={this.state.notaI}
                                        onBlur={this.saveDraft}
                                        placeholder="Inserisci nota" />
                                </div>

                                <div className="col-lg-2 spazio10">
                                    <strong>Disponibilità</strong>
                                </div>
                                <div className="col-lg-10 spazio10">
                                    <input type="text"
                                        onChange={this.updateNotaD}
                                        className="form100"
                                        value={this.state.notaD}
                                        onBlur={this.saveDraft}
                                        placeholder="Inserisci nota" />
                                </div>

                            </div>

                        </div>

                        <hr />
                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                            <div className="row spazio20">
                                <div className="col-lg-4 ">	</div>

                                <div className="col-lg-4">
                                    <button type="button" onClick={this.checkCalcoloImpatto} className="btn btn-success btn-lg btn-block">Calcola Impatto R-I-D</button>
                                </div>
                                <div className="col-lg-4">	</div>

                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        {modalWaringAction}
                        {modalWaringNoAction}
                        {modalError}
                    </div>

                </div>

            </div>
        )
    }
}