import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import { API_CONFIG } from '../../public/js/apiServices';
import { MonitoraggioServizioRow } from './MonitoraggioServizioRow';
import { ModalDialog } from '../Common/ModalDialog';
import { Util } from '../data/Util';

/*
import { ModalDialog } from '../Common/ModalDialog';
import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "Trattamento", url: "/Trattamento" },
    { nome: "Trattamento del rischio", url: "/TrattamentoRischio" },
    { nome: "Azioni di trattamento per servizio", url: "#" }
];
*/

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

export class MonitoraggioServizio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //actionList: [],
            assessmentMonitoraggioList: [],
            minaccieList: [],
            serviceName: (props.location.state) ? props.location.state.serviceName : "",
            nomePA: "",
            assessmentId: (props.location.state) ? props.location.state.assessmentId : "",
            isPA: this.props.PA,
            isCheckedRow: [],
            emptyTest: "Loading...",
            modelType: "",
            messageWarning: "",
            messageError: "",
            quarterNow: "",
            yearNow: "",
            quarterToString: "",
            quarterList: []
        };
        this.toString = this.toString.bind(this);
        //GET
        this.getAzioniDiTrattamentoByAssessmentId = this.getAzioniDiTrattamentoByAssessmentId.bind(this);
        this.getMonitoraggioByAssessmentId = this.getMonitoraggioByAssessmentId.bind(this);
        //SET
        this.setNomePA = this.setNomePA.bind(this);
        this.setMessageWarning = this.setMessageWarning.bind(this);

        //SAVE
        this.changeQuarter = this.changeQuarter.bind(this);
        this.updateAssessmentMonitoraggioState = this.updateAssessmentMonitoraggioState.bind(this);
        this.updateMonitoraggio = this.updateMonitoraggio.bind(this);
    }

    componentDidMount() {
        this.setNomePA();
        this.getMonitoraggioByAssessmentId()
    }



    getMonitoraggioByAssessmentId() {
        const DATE = new Date();
        this.setState({ quarterNow: this.getQuarterDate(DATE).quarter });
        this.setState({
            yearNow: DATE.getFullYear()
        }, () => this.setState({ quarterList: this.getQuarterList(this.state.quarterNow, this.state.yearNow).quarterList }));
        this.setState({
            quarterToString: this.getQuarterDate(DATE).quarterToString
        }, () => console.log("quarterToString", this.state.quarterToString));

        //this.setState({ assessmentId: sessionStorage.getItem("assessmentId") }, () => this.getAzioniDiTrattamentoByAssessmentId());
        /** */
        this.serverRequest =
            $.ajax({
                type: 'POST',
                //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getMonitoraggioByAssessmentId',
                url: API_CONFIG.getMonitoraggioByAssessmentId,
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ AssessmentId: (this.props.PA) ? "" : this.state.assessmentId }),
                //data: JSON.stringify({ AssessmentId: "" }),
                cache: false,
                success: function (response) {
                    console.log('getMonitoraggioByAssessmentId:', response.data.AssessmentMonitoraggio);
                    this.setNomePA(response.data.NomeServizio);
                    if (response.data.AssessmentMonitoraggio.length > 0) {
                        this.setState({ assessmentMonitoraggioList: response.data.AssessmentMonitoraggio.sort(function (a, b) { return a.SottoCategoria.localeCompare(b.SottoCategoria) }) });
                        if (response.data.StatoAssessmentId < 6) {
                            this.setState({ emptyTest: "Nessun dato da visualizzare. Eseguire la pubblicazione del Piano di Trattamento" });
                        }
                        else {
                            //this.setState({ actionList: this.getAzioni(response.data.listaAzioni.sort(function (a, b) { return a.SottoCategoria.localeCompare(b.SottoCategoria); })) });
                            this.setState({ emptyTest: "Nessun Azione da visualizzare." });
                        }
                    }
                    else
                        this.setState({ emptyTest: "Nessun dato da visualizzare. Eseguire la pubblicazione del Piano di Trattamento" });

                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace('/Home');
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    getAzioniDiTrattamentoByAssessmentId() {
        this.serverRequest =
            $.ajax({
                type: 'POST',
                //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentAzioniDiTrattamentoByAssessmentId',
                url: API_CONFIG.azioniDiTrattamentoByAssessmentId,
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ AssessmentId: this.props.PA ? "" : sessionStorage.getItem('assessmentId') }),
                cache: false,
                success: function (response) {
                    console.log('actionList:', response);
                    this.setNomePA(response.data.NomeServizio);
                    if (response.data.listaAzioni.length > 0) {
                        if (response.data.StatoAssessmentId < 6) {
                            this.setState({ emptyTest: "Nessun dato da visualizzare. Eseguire la pubblicazione del Piano di Trattamento" });
                        }
                        else {
                            this.setState({ actionList: this.getAzioni(response.data.listaAzioni.sort(function (a, b) { return a.SottoCategoria.localeCompare(b.SottoCategoria); })) });
                            this.setState({ emptyTest: "Nessun Azione da visualizzare." });
                        }
                    }
                    else
                        this.setState({ emptyTest: "Nessun dato da visualizzare. Eseguire la pubblicazione del Piano di Trattamento" });

                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace('/Home');
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }


    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * @param assessmentAzioniDiTrattamentoId
     * @param stato
     * @param descrizione
     */
    updateMonitoraggio(assessmentAzioniDiTrattamentoId, stato, descrizione) {

        let dataModel = {
            "AssessmentMonitoraggioId": assessmentAzioniDiTrattamentoId,
            "Stato": stato,
            "Descrizione": descrizione
        }

        try {
            if (descrizione.length != 0) {
                this.serverRequest =
                    $.ajax({
                        type: 'POST',
                        url: API_CONFIG.updateMonitoraggio,
                        contentType: 'application/json',
                        headers: { 'token': sessionStorage.getItem('token') },
                        data: JSON.stringify(dataModel),
                        cache: false,
                        success: function (response) {
                            if (response.status === 'success') {
                                this.setState({ textareaValue: "" });
                                console.log('updateMonitoraggio : ', response.status);
                                this.getMonitoraggioByAssessmentId();
                                //alert(JSON.stringify(dataModel));
                                //location.reload();
                            }
                            //alert(response.status);
                        }.bind(this),

                        error: function (xhr, resp, text) {
                            let messageError = "Si è verificato un errore ";
                            console.error(xhr);
                            //Token JWT Invalid
                            if (xhr.responseJSON) {
                                if (xhr.responseJSON.data === -1) {
                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                    location.replace('/Home');
                                } else {
                                    this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                    $('#btnError').get(0).click();
                                }
                            }
                            else {

                                this.setState({ messageError: messageError + "." });
                                $('#btnError').get(0).click();
                            }
                        }.bind(this),
                    });
            }

        } catch (error) {
            return console.error(error);
        }
    }

    /**
    * 
    * @param {*} nomePA 
    */
    setNomePA(nomePA) {

        try {
            if (nomePA) {
                this.setState({ nomePA: nomePA });
            } else {
                var user = Util.getUsersFromAccessToken();
                //return console.log('nomePA',this.state.nomePA);
                this.setState({ nomePA: user.NomePA });
            }
            sessionStorage.setItem('nomePA', this.state.nomePA);
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getUsersFromAccessToken() {
        try {

            const accessToken = sessionStorage.getItem('token');
            //console.log("getAccessToken");

            if (accessToken) {
                let decoded = jwtDecode(accessToken);
                console.log("user", decoded.user);
                return decoded.user;
            }

            return null;

        } catch (error) {
            //location.replace("/Home");
            return console.error(error);
        }
    }



    /**
    * 
    * @param {*} quarter 
    * @param {*} year 
    */
    canUpdateDataChiusura(quarter, year) {
        let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
        return ((quarter >= quarterNow) && (year >= new Date().getFullYear()));
    }

    /**
     * 
     * @param {*} date 
     */
    getQuarterDate(date) {
        if (date) {
            var quarter = Math.ceil((new Date(date).getMonth() + 1) / 3);
            var year = new Date(date).getFullYear();
            var yearStart = (quarter > 0 && quarter < 2) ? year - 1 : year;
            //console.log("quarterTostring", "Q" + quarter + " - " + year);
            return {
                quarterToString: "Q" + quarter + " - " + year,
                quarter: quarter,
                yearStart: yearStart
            };
        }
        else
            return "-1";
    }

    /**
     * 
     */
    getQuarterStart(quarterNow) {
        let quarterStart = (4 % 3) + quarterNow;
        return (quarterStart > 4) ? quarterStart % 4 : quarterStart;
    }

    /**
      * 
      * @param {*} quarterNow 
      * @param {*} yearNow 
      */
    getQuarterList(quarterNow, yearNow) {
        //console.log("quarterNow","Q"+quarterNow+" - "+ yearNow );
        var optionsList = [];
        var quarterList = [];
        //quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
        var quarterStart = this.getQuarterStart(quarterNow);
        //console.log("quarterStart", quarterStart);
        var yearStart = (quarterNow > 0 && quarterNow < 4) ? yearNow - 1 : yearNow;
        var currentQuarter = quarterStart;
        var currentYear = yearStart;
        const DATE = new Date();
        let quarterToString = this.getQuarterDate(DATE).quarterToString;
        let current = "Q" + parseInt(currentQuarter,10) + " - " + currentYear;
        let tdStyle = (current.localeCompare(quarterToString) === 0)
            ? "sep2 sepLeft2 bgGrigio" : "sep";

        let tdElement = <td width="10%" className={tdStyle} style={{ textAlign: "center" }}
            key={"tdKey"}>{"Q" + parseInt(currentQuarter,10) + " - " + currentYear}</td>;
        optionsList.push(tdElement);
        quarterList.push("Q" + parseInt(currentQuarter,10) + " - " + currentYear);
        let numCol = 8;
        for (var j = 0; j < numCol; j++) {

            currentQuarter = currentQuarter + 1;

            if (currentQuarter > 4) {
                currentQuarter = 1;
                currentYear++;
            }

            current = "Q" + parseInt(currentQuarter) + " - " + currentYear;

            tdStyle = (current.localeCompare(quarterToString) === 0) ? "sep2 sepLeft2 bgGrigio" : "sep";

            let tdElement = <td
                width="10%" className={tdStyle} style={{ textAlign: "center" }}
                key={"tdKey" + j}>{current}</td>;
            optionsList.push(tdElement);
            quarterList.push(current);
        }
        return { tdElements: optionsList, quarterList: quarterList };
    }

    /**
     * 
     */
    changeQuarter(arrow) {
        let quarterNow = this.state.quarterNow;
        let yearNow = this.state.yearNow;
        switch (arrow) {
            case "left":
                quarterNow--;
                break;
            case "right":
                quarterNow++;
                break;
            default://
                break;
        }

        if (quarterNow > 4)
            yearNow++;
        if (quarterNow < 1)
            yearNow--;
        let quarterNew = (Math.abs(quarterNow % 4) === 0) ? 4 : Math.abs(quarterNow % 4);
        this.setState({ quarterNow: quarterNew });
        this.setState({ yearNow: yearNow });
        this.setState({ quarterList: this.getQuarterList(quarterNew, yearNow).quarterList });
        this.setState({ quarterToString: "Q" + quarterNew + " - " + yearNow });
    }
    /**
     * 
     * @param {*} val 
     * @param {*} action 
     */
    updateAssessmentMonitoraggioState(val, action) {
        let assessmentMonitoraggioList = this.state.assessmentMonitoraggioList;
        let actionId = action.AssessmentMonitoraggioId;
        for (let index = 0; index < assessmentMonitoraggioList.length; index++) {
            if (assessmentMonitoraggioList[index].AssessmentMonitoraggioId === actionId) {
                assessmentMonitoraggioList[index].Stato = val;
            }
        }
        this.setState({ assessmentMonitoraggioList: assessmentMonitoraggioList });
        console.log("updateAssessmentMonitoraggioState", val, action);
        //alert(JSON.stringify({val:val,action:action}));
    }

    /**
     * 
     */
    setMessageWarning(textModal) {
        this.setState({ messageWarning: textModal });
        $('#btnWarning').get(0).click();
    }

    render() {

        let rows = '';
        if (!this.state.assessmentMonitoraggioList.length) {
            rows = <div className="row"> <div className="col-lg-12 ">{this.state.emptyTest}</div></div>
        } else {
            rows = this.state.assessmentMonitoraggioList.map(function (assessment, i) {
                return (
                    <MonitoraggioServizioRow
                        key={i}
                        index={i}
                        assessment={assessment}
                        quarterList={this.state.quarterList}
                        quarterToString={this.state.quarterToString}
                        updateActionState={this.updateActionState}
                        id={assessment.AssessmentMonitoraggioId}
                        quarterNow={this.getQuarterDate(new Date()).quarterToString}
                        getMonitoraggioByAssessmentId={this.getMonitoraggioByAssessmentId}
                        setMessageWarning={this.setMessageWarning}
                        updateAssessmentMonitoraggioState={this.updateAssessmentMonitoraggioState}
                        insertAssessmentMonitoraggioEvento={this.insertAssessmentMonitoraggioEvento}
                    />
                );
            }.bind(this)
            );
        }

        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />

        var testoBreadcrumb = this.props.PA ? "Monitoraggio per PA" : "Monitoraggio per servizio";

        return (
            <div id="main">

                <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                    <div className="container cnsContainer">


                        <div className="row">

                            <div className="col-lg-12">
                                <nav className="" aria-label="sei qui:" role="navigation">
                                    <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/Monitoraggio')}>Monitoraggio</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">{testoBreadcrumb}</a></li>
                                    </ul>
                                </nav>
                                <span className="titoloPagina">Monitoraggio: {(this.props.PA) ? this.state.nomePA : this.state.serviceName}</span></div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 giustifica ">
                                {!this.props.PA  && <p>La pagina espone il Piano di trattamento del Rischio del singolo Servizio e gli strumenti per realizzarne il monitoraggio. Il Piano di Trattamento è costituito da Azioni di Trattamento caratterizzate da un periodo di realizzazione con una data di inizio attività ed una data di fine attività ed una serie di strumenti per poter supervisionare lo stato di avanzamento ed inserire elementi che possono modificare lo stato di avanzamento fino alla sua conclusione.<br /></p>}
                                {this.props.PA  && <p>La pagina espone il Piano di trattamento del Rischio della Azienda e gli strumenti per realizzarne il monitoraggio. Il Piano di Trattamento è costituito da Azioni di Trattamento caratterizzate da un periodo di realizzazione con una data di inizio attività ed una data di fine attività ed una serie di strumenti per poter supervisionare lo stato di avanzamento ed inserire elementi che possono modificare lo stato di avanzamento fino alla sua conclusione.</p>}
                                <div className="row spazio10"> </div>
                            </div>


                            <div className="col-lg-12 ">

                                <span className=" InfoAgg"><strong>Legenda simboli: &nbsp;</strong> <i className="fa fa-map-pin testoBlu"></i> Eventi utente presenti &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-clock-o testoBluScuro"></i> Variazione data termine &nbsp;&nbsp;&nbsp;&nbsp;<i className="testoVerde fa fa-check"></i> Azione di trattamento conclusa &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-ban testoRosso"></i> Azione di trattamento sospesa
<br />
                                    <strong>Legenda colori: &nbsp;</strong> <i className="fa fa-square testoArancio"></i> Ultimo trimestre azione di trattamento &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-square testoRosso"></i> Data termine superata &nbsp;&nbsp;&nbsp;&nbsp;
</span>

                            </div>


                        </div>

                        <div className="linea0066cc"></div>
                        <div className="container cnsContainer">

                            <div className="row">
                                <div className="col-lg-10"></div>

                                <div className="col-lg-2">

                                </div>

                            </div>
                        </div>

                        <div className="container cnsContainer">

                            <div className="row">
                                <div className="col-lg-3 card-headerLeft nascondiCell "><strong> Azioni di trattamento</strong></div>
                                <div className="col-lg-1 font12" style={{ textAlign: "right", cursor: "pointer", padding: ".5em .7em" }} onClick={() => this.changeQuarter("left")}>
                                    <i className=" fa fa-chevron-left"></i> indietro
                                </div>
                                <div className="col-lg-8">
                                    <table width="100%" className="font12 nascondiCell Tablejs-TableResponsive tablesaw tablesaw-stack">
                                        <tbody>
                                            <tr>
                                                {this.getQuarterList(this.state.quarterNow, this.state.yearNow).tdElements}
                                                <td width="10%" style={{ textAlign: "right", cursor: "pointer" }}
                                                    onClick={() => this.changeQuarter("right")}>avanti <i className=" fa fa-chevron-right"></i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <span className="lineaGrigiaT"></span>
                            {rows}

                        </div>

                        <div className="row spazio10"> </div>

                    </div>

                    <br />
                    <br />
                    <br />
                </div>
                {<ModalDialog idTarget={'warningNoAction'} idBtn={'btnWarning'} textModal={this.state.messageWarning} />}
                {modalError}
            </div>
        )
    }
}