import React from 'react'
import $ from "jquery";
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { EsportaLibrary } from "./EsportaLibrary";
import { ImportaLibrary } from "./ImportaLibrary";
import { API_CONFIG } from '../../public/js/apiServices';

const LINK = [
    { nome: "Aggiornamento libreria metodologica", url: "/AggiornaLibrary" },
];

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

export class AggiornaLibrary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            successMessage: "",
            warningMessage: "",
            errorMessage: ""
        }
        this.showModal = this.showModal.bind(this);
    }

    /**
     * 
     * @param {*} text of the message into the modal
     * @param {*} type of the modal
     */
    showModal(text, type) {
        if (text.indexOf("'") === 0) {
            text = text.replace(/'/g, "");
        }
        switch (type) {
            case 'success':
                this.setState({ successMessage: text }, () => $('#btnSuccess').get(0).click());
                break;
            case 'warning':
                this.setState({ warningMessage: text }, () => $('#btnValidate').get(0).click());
                break;
            case 'error':
                this.setState({ errorMessage: text }, () => $('#btnError').get(0).click());
                break;
            default:
                break;
        }
    }

    render() {

        let modalSuccess = <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.successMessage} />;
        let modalWaring = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.warningMessage} />
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.errorMessage} />;

        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";

        return (
            <div className={outerClass}>

                <div className={outerClassContainer}>

                    <div className="row">

                        <div className="col-lg-12">
                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Aggiornamento libreria metodologica</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Aggiornamento libreria metodologica</span>
                        </div>
                    </div>
                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>
                                    In questa pagina sarà possibile procedere con l'aggiornamento dei dati presenti nella libreria metodologica utilizzata dagli algoritmi del TOOL
        per calcolare correttamente i valori di rischio, definire le analisi del rischio ed i piani di trattamento conseguenti.
<br />
                                    Con la procedura sarà possibile modificare elementi come :
<br />
                                    - Matrici di rischio (Probabilità di accadimento e Rischio Attuale)
<br />
                                    - Valori standard di Propensione al Rischio
<br />
                                    - Attributi delle Minacce
<br />
                                    - Attributi delle Misure di Sicurezza
<br />
                                    - Matrice di correlazione Controlli/Minacce
<br />
                                    - Matrice di correlazione Controlli/Caratteristiche
<br />
                                    - Matrice di correlazione Controlli/Azioni di Trattamento
<br />

                                    La libreria deve essere aggiornata tramite l'utilizzo di un documento EXCEL opportunamente strutturato, pertanto per eventuali modifiche si consiglia di
                                    scaricare la versione attuale tramite il pulsante "Download", apportare le modifiche desiderate all'interno del documento .xls
                                    e successivamente aggiornare le informazioni nel TOOL selezionando "Scegli file" e premendo il tasto "Aggiorna Library"
                                    
                            </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <br />
                    <EsportaLibrary showModal={this.showModal} />
                    <br />
                    <ImportaLibrary showModal={this.showModal} />
                </div>
                {modalSuccess}
                {modalWaring}
                {modalError}
                <br />
            </div>
        )
    }
}

//export default ImportaLibrary