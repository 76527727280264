import * as React from 'react';
import ICON from '../data/IconLevel';
import history from '../history';
import { Util } from "../data/Util";

/**
 * 
 * @param {*} path 
*/
function redirect(path, serviceId) {
    try {
        window.sessionStorage.setItem("serviceId", serviceId);
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}

function getClassNameAccordion(index) {
    let className = 'col-lg-1 card-header';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "col-lg-1 card-header bgVerde";
            break;
        case 2:
            className = "col-lg-1 card-header bgArancio";
            break;
        case 3:
            className = "col-lg-1 card-header bgRosso";
            break;
        case 4:
            className = "col-lg-1 card-header bgNero";
            break;
        default:
            className = "col-lg-1 card-header";
            break;
    }
    return className;
}

// component that renders a single service
export class ServiceRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category
        };
        this.toString = this.toString.bind(this);
        this.isHidden = this.isHidden.bind(this);
    }

    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            toString += list[index].Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    isHidden() {
        let statoCaratterizzazione = this.props.service.StatoCaratterizzazione;
        if (statoCaratterizzazione.toLocaleUpperCase().indexOf("PARZIALMENTE_CARATTERIZZATO") === 0 ||
            statoCaratterizzazione.toLocaleUpperCase().indexOf("CARATTERIZZATO") === 0) {
            return { eye: 'visible', pencil: 'modifica' };
        } else return { eye: 'hidden', pencil: 'compila' };
    }

    /**
             * 
             * @param {*} rischioAggregato 
             * @param {*} array 
             */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {
        let isHidden = this.isHidden().eye;

        let isServiceChanged = (new Date(this.props.service.DataUpdateProfiloDiCriticità).getTime() >= new Date(this.props.service.DataCompilazioneContesto).getTime());

        let profiloCriticità = <p><strong>Profilo di criticità del servizio</strong></p>;
        if (this.props.service.Criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità del servizio</strong>&nbsp;&nbsp;
                                    <img src={this.getRisk(this.props.service.Criticità, ICON).src} width="20" height="20" alt="" />

                <span className={getClassName(this.props.service.Criticità)}>
                    <strong>{" " + this.getRisk(this.props.service.Criticità, ICON).label}</strong></span>
            </p>;
        }

        var tooltip = this.props.service.Criticità === -2 ? "COMPILA" : "MODIFICA";

        return (
            this.props.riepilogo !== "true" ?
                <div>
                    <div className="row spazio10">
                        <div className="col-lg-8 card-headerT">
                            {/*1à colonna Nome servizio*/}
                            <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                                {(this.props.service.Nome) ? this.props.service.Nome : "-"}
                                <i className="iconRight fa fa-chevron-down"></i>
                            </a>&nbsp; &nbsp;
                                <i hidden={isServiceChanged} className="fa fa-warning testoRosso"></i>
                        </div>
                        {/*2a colonna Aggiornamento*/}
                        <div className="col-lg-1 card-header">{this.toStringDate(this.props.service.DataUpdateProfiloDiCriticità).split(" ")[0]}</div>
                        {/*3a colonna Criticità*/}
                        <div className={getClassNameAccordion(this.props.service.Criticità)}>
                            <strong>{this.getRisk(this.props.service.Criticità, ICON).label}</strong>
                        </div>
                        {/*4a colonna Stato*/}
                        <div className="col-lg-1 card-header">{this.props.stato}</div>

                        {this.props.riepilogo !== "true" && <div className="col-lg-1 card-header ">
                            <a style={{ visibility: isHidden }} onClick={() => redirect('AnalisiContestoVisualizza', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-eye" title="VEDI"></i>
                            </a>&nbsp;
                            <a onClick={() => redirect('AnalisiContestoModifica', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-pencil" title={tooltip}></i>
                            </a>
                        </div>}
                        {this.props.riepilogo === "true" && <div className="col-lg-1 card-header ">
                            <a onClick={() => redirect('AnalisiContestoVisualizza', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-eye" title="VEDI"></i>
                            </a>
                        </div>}
                    </div>
                    <hr />
                    <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                        <div className="row  card-headerLight">
                            <div className="col-lg-6">
                                <strong>Referente</strong><br />
                                {(this.props.service.Referente) ? this.props.service.Referente : "-"}<hr /></div>
                            <div className="col-lg-6">
                                <strong>Struttura</strong><br />
                                {(this.props.service.StrutturaReferente) ? this.props.service.StrutturaReferente : "-"}<hr /></div>
                            <div className="col-lg-6">
                                <strong>Categorie</strong><br />
                                {this.props.categoriesTostring}<hr /></div>
                            <div className="col-lg-6">
                                <strong>Tipologie</strong><br />
                                {this.props.typologiesTostring}<hr />
                            </div>
                            <div className="col-lg-12">
                                <p><strong>Descrizione</strong><br />
                                    {(this.props.service.Descrizione) ? this.props.service.Descrizione : "-"}</p><hr />
                            </div>
                            <div className="col-lg-12 ">
                                <div>
                                    {profiloCriticità}
                                    <hr />
                                </div>
                            </div>
                            <div className="col-lg-6"><strong>Data Aggiornato Profilo Criticità</strong><br />
                                {this.toStringDate(this.props.service.DataUpdateProfiloDiCriticità)}
                                <hr />
                            </div>
                            <div className="col-lg-6"><strong>Data ultima modifica dei dati</strong><br />
                                {this.toStringDate(this.props.service.DataCompilazioneContesto)}
                                <hr />
                            </div>
                        </div>
                    </div>
                </div> :
                <div>
                    <div className="row">
                        {/*1à colonna Nome servizio*/}
                        <div className="col-lg-9 card-headerT">
                            <a onClick={() => redirect('AnalisiContestoVisualizza', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                {this.props.service.Nome}
                            </a>
                        </div>
                        {/*2a colonna Aggiornamento*/}
                        <div className="col-lg-1 card-header">{this.toStringDate(this.props.service.DataUpdateProfiloDiCriticità).split(" ")[0]}</div>
                        {/*3a colonna Criticità*/}
                        <div className={getClassNameAccordion(this.props.service.Criticità)}>
                            <strong>{this.getRisk(this.props.service.Criticità, ICON).label}</strong>
                        </div>

                        <div className="col-lg-1 card-header ">
                            <a onClick={() => redirect('AnalisiContestoVisualizza', this.props.service.ServizioId)}
                                href="javascript:void(0)">
                                <i className="fa fa-eye" title="VEDI"></i>
                            </a>
                        </div>
                    </div>
                </div>
        );
    }
};