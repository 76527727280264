import React, { Component } from 'react';

export class ModalDialog extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            toggle: false
        }
        this.onClick = this.onClick.bind(this);
    }

    toggle(event) {
        this.setState(prevState => ({
            toggle: !prevState.toggle
        }));
    }

    onClick(event) {
        this.props.handle();
    }


    render() {
        var modal = [];
        let modalType = this.props.idTarget.toLowerCase();
        switch (modalType) {
            case 'success':
                modal.push(
                    <div key={"modal-dialog-success"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h2 className="modal-title" id="exampleModalLabel">{'Operazione completata con successo!'}</h2> */}
                                    <h2 className="modal-title" id="exampleModalLabel"><i className="fa fa-check testoVerde"></i></h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div className="modal-body" style={{fontSize: "large"}}>{this.props.textModal}</div> */}
                                <div className="modal-body" style={{ fontSize: "large" }}>
                                    {(this.props.textModal) ? this.props.textModal : "Operazione completata con successo!"}
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button type="button" className="btn btn-success btn-lg" data-bs-dismiss="modal">OK</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'successaction':
                modal.push(
                    <div key={"modal-dialog-successAction"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title" id="exampleModalLabel"><i className="fa fa-check testoVerde"></i></h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button type="button" className="btn btn-success btn-lg" data-bs-dismiss="modal" onClick={this.props.handleOK}>OK</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;

            case 'warningaction':

                modal.push(
                    <div key={"modal-dialog-warningAction"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title testoArancio" id="exampleModalLabel"><i className="fa fa-warning"></i>
                                        {" AVVISO"}</h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                         {this.props.notShowAnnulla ? "" :
                                             (<button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal">ANNULLA</button> + " ")
                                        }
                                        {(this.props.userCode) ?
                                            <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={() => this.props.handle(this.props.userCode)}>CONFERMA</button>
                                            :
                                            <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={this.props.handle}>CONFERMA</button>
                                        }

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'warningaction2':

                modal.push(
                    <div key={"modal-dialog-warningAction2"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title testoArancio" id="exampleModalLabel"><i className="fa fa-warning"></i>
                                        {" AVVISO"}</h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal">ANNULLA</button>{" "}
                                        <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={this.props.handle}>CONFERMA</button>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'choice':

                modal.push(
                    <div key={"modal-dialog-choice"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title testoArancio" id="exampleModalLabel"><i className="fa fa-warning"></i>
                                        {" AVVISO"}</h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal" onClick={() => this.props.handle("No")}>NO</button>{" "}
                                        <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={() => this.props.handle("Yes")}>SI</button>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'warningnoaction':

                modal.push(
                    <div key={"modal-dialog-warningNoAction"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title testoArancio" id="exampleModalLabel"><i className="fa fa-warning"></i>
                                        {" AVVISO"}</h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footer justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button key={64} type="button" className="btn btn-success btn-lg" data-bs-dismiss="modal">OK</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'danger':
                modal.push(
                    <div key={"modal-dialog-danger"} className="modal fade" id={this.props.idTarget} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-headerRed ">
                                    <h2 className="modal-title testoRosso" id="exampleModalLabel"><i className="fa fa-times"></i>
                                        {"ERRORE"}</h2>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ fontSize: "large" }}>{this.props.textModal}</div>
                                <div className="modal-footerRed justify-content-center">
                                    <a href="javascript:void(0);">
                                        <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal">OK</button>{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            default:
                break;
        }

        return (
            <div>
                <a>
                    <button type="button" hidden id={this.props.idBtn}
                        data-bs-toggle="modal" data-bs-target={"#" + this.props.idTarget}
                        className="btn btn-warning btn-lg btn-block"></button>
                </a>
                {modal}
            </div>
        );
    }
}

