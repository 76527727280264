import React from 'react';

export class GradoImplementationeOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      font: (this.props.font) ? this.props.font : ""
    };
    this.updateGrade = this.updateGrade.bind(this);
  }

  updateGrade(event) {
    this.setState({ grade: event.target.value });
    var grade = parseInt(event.target.value, 10);
    this.props.updateGrade(grade, this.props.action);
    //console.log('updateGrade',item);
  }

  render() {
    return (
      <select value={this.props.grade}
        className={'form100 '+ this.state.font}
        disabled={this.props.isDisabledGrade}
        onChange={this.updateGrade}>
        {/* <option value='-1'></option> */}
        <option value='-2'>Non so rispondere</option>
        {/* <option value='0'>Non Applicabile</option> */}
        {/* <option value='1'>Assente</option> */}
        <option value='2'>Migliorabile</option>
        <option value='3'>Adeguato</option>
        <option value='4'>Completo</option>
      </select>
    );
  }
}