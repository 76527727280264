import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { AzioniTrattamentoServizioModificaRow } from './AzioniTrattamentoServizioModificaRow';
import { MinacciaRow } from './MinacciaRow';
import { ModalDialog } from '../Common/ModalDialog';
import { ThreatSelect } from './ThreatSelect';
/*
import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "Trattamento", url: "/Trattamento" },
    { nome: "Trattamento del rischio", url: "/TrattamentoRischio" },
    { nome: "Azioni di trattamento per servizio", url: "#" }
];
*/
let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

export class AzioniTrattamentoServizioModifica extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listAssessmentTrattamento: [],
            listAssessmentFilterable: [],
            listAllMenace: [],
            minacceList: [],
            opzioni: [],
            serviceName: (this.props.PA) ? "" : props.location.state.serviceName,
            nomePA: (this.props.PA) ? this.getUsersFromAccessToken().NomePA : "",
            selectedMenace: "",
            isCheckedRow: [],
            emptyTest: "Loading...",
            messageWarning: '',
            messageSuccess: '',
            messageError: '',
            statoAssessment: "",
            assessmentId: 0,
        };
        this.updateMenace = this.updateMenace.bind(this);
        this.updateRows = this.updateRows.bind(this);
        //GET
        this.getAzioniTrattamentoServizioModificaRow = this.getAzioniTrattamentoServizioModificaRow.bind(this);
        this.getMinacce = this.getMinacce.bind(this);
        this.toString = this.toString.bind(this);
        //UPDATE
        this.updateStrutturaReferente = this.updateStrutturaReferente.bind(this);
        this.updateDataChiusura = this.updateDataChiusura.bind(this);
        this.updateEffectiveAction = this.updateEffectiveAction.bind(this);
        this.updateGrade = this.updateGrade.bind(this);
        this.updadateCheckedRow = this.updadateCheckedRow.bind(this);
        this.changeStateAssessment = this.changeStateAssessment.bind(this);
        this.callCambiaStato = this.callCambiaStato.bind(this);
        this.canChangeStateAssessment = this.canChangeStateAssessment.bind(this);
        this.opzioniComplete = this.opzioniComplete.bind(this);
        this.setGradoAttesoDefault = this.setGradoAttesoDefault.bind(this);
        this.callAzioni = this.callAzioni.bind(this);
        this.redirect = this.redirect.bind(this);
        //SET
        this.setNomePA = this.setNomePA.bind(this);
        //SAVE
        this.save = this.save.bind(this);
    }

    /**
     * 
     */
    opzioniComplete() {
        var lista = this.state.opzioni;
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if (
                ((element.OpzioneTrattamento !== null &&
                    element.OpzioneTrattamento !== element.OpzioneTrattamentoDefault) ||
                    element.RiskAppetite !== element.RiskAppetiteDefault) &&
                (element.PersonaIntervistato === null ||
                    element.Motivazione === null ||
                    element.Motivazione === "")
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * 
     */
    canChangeStateAssessment() {
        var lista = this.state.listAssessmentTrattamento;
        var pianificate = 0;

        var response = {};
        response.canChange = true;

        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];

            if (!(element.GradoDiImplementazioneAtteso !== null && element.AzioneEffettiva !== ""
                && (element.DataChiusura || element.AzioneEffettiva === "empty")))
                response.canChange = false;

            if (element.AzioneEffettiva !== "empty")
                pianificate = pianificate + 1;
        }

        response.pianificate = pianificate;
        console.log("canChangeStateAssessment", response);
        return response;
    }

    setGradoAttesoDefault() {
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateOpzioniTrattamento',
            url: API_CONFIG.calcoloGradoDiImplementazioneAtteso,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: JSON.stringify({ AssessmentId: this.state.assessmentId }),
            cache: false,

            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response) {
                if (response.status.localeCompare('success') === 0) {
                    $('#btnSuccess').get(0).click();
                    this.callAzioni();
                }
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
            }.bind(this),
        });

    }

    setGradoDialog() {
        $('#btnSetGrado').get(0).click();
    }

    callCambiaStato() {
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.cambiaStatoAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: "{ \"AssessmentId\": \"" + this.state.assessmentId + "\"," + " \"Stato\":\"6\" }",
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                        this.setState({ messageSuccess: "Pubblicazione effettuata con successo!" });
                        $('#btnSuccess').get(0).click();
                        this.setState({ statoAssessment: 6 });
                        //location.reload();
                        //history.push('/ListaServizi');
                        //alert(response.status);
                    }
                    else {
                        //alert(response.status);
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    /**
     * 
     */
    changeStateAssessment() {
        try {
            //let index = this.state.isCheckedRow.findIndex(function (obj) { return obj === false; });
            var response = this.canChangeStateAssessment();
            if (response.canChange && response.pianificate > 0) {
                if (this.opzioniComplete()) {
                    $('#btnPubblica').get(0).click();
                }
                else {
                    this.setState({ messageWarning: "Per proseguire è necessario completare il campo Motivazione delle Opzioni di Trattamento modificate" });
                    $('#btnValidate').get(0).click();
                }
            }
            else {
                if (response.pianificate > 0)
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario valorizzare il trimestre di chiusura previsto per tutte le azioni di trattamento da pianificare ed immettere il testo delle eventuali azioni personalizzate" });
                else
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario pianificare almeno un'azione di trattamento" });
                $('#btnValidate').get(0).click();
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} e 
     */
    updateMenace(e) {
        let val = e.target.value;
        sessionStorage.setItem('selectedMenace', val);
        this.setState({ selectedMenace: val }, () => this.updateRows());
    }

    /**
     * updateStrutturaReferente
     * @param {*} newValue 
     * @param {*} actionId 
     */
    updateStrutturaReferente(newValue, actionId) {
        let listAssessment = this.state.listAssessmentTrattamento;
        let findIndex = listAssessment.findIndex(l => l.AssessmentAzioniDiTrattamentoId === actionId);
        if (findIndex !== -1) {
            listAssessment[findIndex].StrutturaReferente = newValue;
            this.setState({ listAssessmentTrattamento: listAssessment }, () => this.save());
            //console.log('updateStrutturaReferente', this.state.listAssessmentTrattamento[findIndex]);
        } else return;
    }

    /**
      * 
    */
    updateRows() {
        let listAssessment = this.state.listAssessmentTrattamento;
        let selectedMenace = this.state.selectedMenace;
        var obj = { "length": listAssessment.length, "selectedMenace": selectedMenace }
        //alert(JSON.stringify(obj));
        try {

            var list = [];
            if (selectedMenace.localeCompare("-1") === 0) {
                //&& search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1'
                list = listAssessment;
                //debugger
            } else {

                for (let index = 0; index < listAssessment.length; index++) {
                    for (let j = 0; j < listAssessment[index].Minacce.length; j++) {
                        const action = listAssessment[index].Minacce[j];
                        if (action.NomeMinaccia.toUpperCase().localeCompare(selectedMenace.toUpperCase()) === 0) {
                            list.push(listAssessment[index]);
                        }
                    }
                }
            }
            this.setState({ listAssessmentFilterable: list });
            //alert(list.length);

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * 
     */
    componentDidMount() {
        console.log("props", this.props);
        this.setNomePA();
        this.callAzioni();
    }

    /**
     * 
     */
    callAzioni() {
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentAzioniDiTrattamentoByAssessmentId',
                    url: API_CONFIG.azioniDiTrattamentoByAssessmentId,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                    //data: "{ \"AssessmentId\": 51}",
                    cache: false,
                    success: function (response) {
                        //console.log('response:', response);
                        this.setState({ serviceName: response.data.NomeServizio });
                        if (response.data.listaAzioni.length > 0) {
                            if (response.data.StatoAssessmentId < 4) {
                                this.setState({ emptyTest: "Nessuna Analisi del Rischio Pubblicata oppure non è stata selezionata nessuna minaccia da mitigare" });
                            }
                            else {
                                this.setState({ listAssessmentTrattamento: response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }) });
                                console.log("listAssessmentTrattamento", response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }));
                                this.setState({ listAssessmentFilterable: response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }) },
                                    () => this.updateRows);
                                let unique_listMenace = this.getListAllMenace(response.data.listaAzioni.sort(function (a, b) { return a.GradoDiImplementazione - b.GradoDiImplementazione; }));
                                this.setState({ listAllMenace: unique_listMenace });
                                this.setState({ statoAssessment: response.data.StatoAssessmentId });
                                this.setState({ assessmentId: response.data.AssessmentId });

                                this.canChangeStateAssessment();

                                this.serverRequest =
                                    $.ajax({
                                        url: API_CONFIG.assessmentcompositeGetOpzioniTrattamento,
                                        type: "POST",
                                        contentType: 'application/json',
                                        data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                                        headers: { 'token': sessionStorage.getItem('token') },
                                        cache: false,
                                        success: function (response) {
                                            this.setState({ opzioni: response.data.OpzioniTrattamento.data });
                                        }.bind(this)
                                    });
                            }
                        }
                        else
                            this.setState({ emptyTest: "Nessuna Analisi del Rischio Pubblicata oppure non è stata selezionata nessuna minaccia da mitigare" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} actionList : response.data.ListaAzioni
     * return the list of all Minacce without duplicate
     */
    getListAllMenace(actionList) {
        let unique_listMenace = [];
        try {
            for (let index = 0; index < actionList.length; index++) {
                for (let j = 0; j < actionList[index].Minacce.length; j++) {
                    const element = actionList[index].Minacce[j].NomeMinaccia;
                    if (!unique_listMenace.includes(element)) {
                        unique_listMenace.push(element)
                    }
                }
            }
            return unique_listMenace;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    redirect(path) {
        try {
            //window.sessionStorage.setItem("assessmentId", this.state.assessmentId);
            //window.sessionStorage.setItem("serviceName", this.state.serviceName);
            let state = {};
            if (this.props.PA) {
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA: this.props.PA,
                    nomePA: this.state.nomePA
                };
            } else {
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA: this.props.PA,
                    serviceName: this.state.serviceName
                };
            }
            if (path) {
                if (path.indexOf('/') === 0)
                    history.push({
                        pathname: path,
                        state: state
                    }
                    );
                else if (path.indexOf('/') === -1) {
                    history.push({
                        pathname: '/' + path,
                        state: state
                    }
                    );
                }
            }
            console.log("state", state)
        } catch (error) {
            return console.error(error)
        }
    }

    /**
    * 
    */
    getMinacce(e) {
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getMinacce',
                    url: API_CONFIG.assessmentcompositeGetMinacce,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: "{ \"AssessmentId\":" + this.state.assessmentId + "}",
                    cache: false,
                    success: function (response) {
                        //console.log('assessmentcompositeGetMinacce:', response);
                        this.setState({ minacceList: response.data });
                        //this.setState({ emptyTest: "Nessuna minaccia presente" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} val 
     * @param {*} action 
     */
    //updateEffectiveAction(val, action) {
    updateEffectiveAction(val, action, index) {
        let listAssessmentTrattamento = this.state.listAssessmentTrattamento;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        //for (let index = 0; index < listAssessmentTrattamento.length; index++) {
        //if (listAssessmentTrattamento[index].AssessmentAzioniDiTrattamentoId === actionId) {
        listAssessmentTrattamento[index].AzioneEffettiva = val;
        //console.log(' listAssessmentTrattamento[' + actionId + '].AzioneEffettiva', listAssessmentTrattamento[index].AzioneEffettiva);
        //}
        //}
        this.setState({ listAssessmentTrattamento: listAssessmentTrattamento }, () => this.save());
        //console.log("listAssessmentTrattamento", listAssessmentTrattamento);
    }

    /**
     * 
     * @param {*} month 
     * @param {*} year 
     * @param {*} action 
     */
    updateDataChiusura(quarterDate, action) {
        var split = quarterDate.split("-");
        var year = split[1];
        var quarter = split[0];
        let month = (parseInt(quarter, 10) * 3) - 1;
        console.log("updateDataChiusura", { month: month, quarter: quarter, year: year });
        let listAssessmentTrattamento = this.state.listAssessmentTrattamento;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        var dataChiusura = this.getDataChiusura(quarter, year);
        try {
            if (dataChiusura !== -1) {

                for (let index = 0; index < listAssessmentTrattamento.length; index++) {
                    if (listAssessmentTrattamento[index].AssessmentAzioniDiTrattamentoId === actionId) {
                        listAssessmentTrattamento[index].DataChiusura = dataChiusura;
                    }
                }
                this.setState({ listAssessmentTrattamento: listAssessmentTrattamento }, () => this.save());
                console.log("listAssessmentTrattamento", listAssessmentTrattamento);
            } else {
                this.setState({ messageWarning: "Il trimestre selezionato è minore di quello corrente." });
                $('#btnValidate').get(0).click();
                return;
            }
        } catch (error) {
            return console.error(error);
        }

    }

    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * 
     */
    updateGrade(val, action, azioneEffettiva) {
        //console.log('azioneEffettiva', azioneEffettiva);
        let listAssessmentTrattamento = this.state.listAssessmentTrattamento;
        let actionId = action.AssessmentAzioniDiTrattamentoId;
        for (let index = 0; index < listAssessmentTrattamento.length; index++) {
            if (listAssessmentTrattamento[index].AssessmentAzioniDiTrattamentoId === actionId) {
                listAssessmentTrattamento[index].GradoDiImplementazioneAtteso = val;

                if (azioneEffettiva !== undefined)
                    listAssessmentTrattamento[index].AzioneEffettiva = azioneEffettiva;
                //else if (azioneEffettiva == null || azioneEffettiva)
                //    listAssessmentTrattamento[index].AzioneEffettiva = "";
                if (azioneEffettiva === "empty")
                    listAssessmentTrattamento[index].DataChiusura = null;
            }
        }
        this.setState({ listAssessmentTrattamento: listAssessmentTrattamento }, () => this.save());
        //console.log("listAssessmentTrattamento", listAssessmentTrattamento);
    }

    /**
     * 
     */
    updadateCheckedRow(rowId, isCheck) {
        console.log("rowId", rowId);
        console.log("isCheck", isCheck);
        try {
            let isCheckedRow = this.state.isCheckedRow;
            isCheckedRow[rowId] = isCheck;
            this.setState({ isCheckedRow: isCheckedRow });
        } catch (error) {
            return console.error(error);
        }

    }

    /**
    * 
    * @param {*} nomePA 
    */
    setNomePA(nomePA) {
        try {
            if (nomePA) {
                this.setState({ nomePA: nomePA }, () => sessionStorage.setItem('nomePA', this.state.nomePA));
            } else {
                let user = this.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA }, () => sessionStorage.setItem('nomePA', this.state.nomePA));
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getUsersFromAccessToken() {
        try {
            const accessToken = sessionStorage.getItem('userInfo');
            if (accessToken) {
                return JSON.parse(accessToken).user;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    save() {
        let data = { 'AzioniDiTrattamento': this.state.listAssessmentTrattamento }
        //console.log('save', data);
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateAzioniDiTrattamento',
                    url: API_CONFIG.saveOrUpdateAzioniDiTrattamento,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify(data),
                    cache: false,
                    success: function (response) {
                        if (response.status === 'success') 
                            console.log("saveOrUpdateAzioniDiTrattamento",response.status);
                        //location.reload();
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} quarter 
     * @param {*} year 
     */
    canUpdateDataChiusura(quarter, year) {
        let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
        return !((quarter < quarterNow) && (year === new Date().getFullYear()));
    }

    getDataChiusura(quarter, year) {
        var dataChiusura;


        if (quarter === -1 && year === -1) {//Non valorizzati entrambi
            dataChiusura = undefined;
        }
        else if (quarter === -1 && year !== -1) {//Quarter non valorizzato, metto quarter corrente se year=yearNow altrimenti primo quarter
            let _quarter = 1;
            let yearNow = new Date().getFullYear();
            if (year === yearNow)
                _quarter = Math.ceil((new Date().getMonth() + 1) / 3);

            let month = (parseInt(_quarter, 10) * 3) - 1;

            dataChiusura = new Date(Date.UTC(year, month, 2));
        }
        else if (quarter !== -1 && year === -1) {//Year non valorizzato, metto anno corrente se quarter>=quarterNow altrimenti anno successivo
            let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
            let month = (parseInt(quarter, 10) * 3) - 1;
            let _year = new Date().getFullYear();
            if (quarter < quarterNow)
                _year = _year + 1;

            dataChiusura = new Date(Date.UTC(_year, month, 2));
        }
        else {//Quarter e year valorizzati, eseguo la validazione
            let quarterNow = Math.ceil((new Date().getMonth() + 1) / 3);
            if (quarter < quarterNow && year === new Date().getFullYear())
                dataChiusura = -1;
            else {
                let month = (parseInt(quarter, 10) * 3) - 1;
                dataChiusura = new Date(Date.UTC(year, month, 2));
            }
        }
        return dataChiusura;
    }

    getAzioniTrattamentoServizioModificaRow() {
        let rows = [];
        if (!this.state.listAssessmentFilterable.length) {
            if(this.state.emptyTest === "Loading...")
                rows = <div className="row"> {this.state.emptyTest}</div>
            else
                rows = <div className="row"> 
                    <div className="col-lg-12">
                        <p>Dati non presenti. Possibili cause:
                            <br/><br/>
                            <ul > 
                                <li>• Nessuna Analisi del Rischio pubblicata</li>
                                <li>• Non è presente nessuna minaccia da poter mitigare</li>
                            </ul>
                        </p>
                    </div>                
                </div>
        } else {
            let rowsTemp = this.state.listAssessmentFilterable.map(function (action, i) {
                let radioGroup = {
                    isCheckConsigliata: false,
                    isCheckEffettiva: false,
                    isCheckNessuna: false,
                    isHiddenGrade: true,

                    isDisabledDataChiusura: false
                }
                let isFirtTime = (action.GradoDiImplementazioneAtteso === null);
                if (!isFirtTime) {
                    //caso di “Azione consigliata”
                    if (action.AzioneEffettiva === null) {
                        radioGroup.isCheckConsigliata = true;
                        //disabilitare la combobox del grado di implementazione
                        radioGroup.isHiddenGrade = true;
                        //abilitare la data di chiusura prevista
                        radioGroup.isDisabledDataChiusura = false;
                    }    //caso di “Nessuna azione” 
                    else if (action.AzioneEffettiva.localeCompare("empty") === 0) {
                        radioGroup.isCheckNessuna = true;
                        //disabilitare il grado di implementazione atteso
                        radioGroup.isHiddenGrade = true;
                        //imposta lo stesso valore del grado di implementazione originale
                        //action.GradoDiImplementazioneAtteso = action.GradoDiImplementazione;
                        //disabilitare la data di chiusura prevista
                        radioGroup.isDisabledDataChiusura = true;
                    } else {//Caso di “Azione effettiva”
                        radioGroup.isCheckEffettiva = true;
                        //abilitare il grado di implementazione atteso
                        radioGroup.isHiddenGrade = false;
                        //abilitare la data di chiusura prevista
                        radioGroup.isDisabledDataChiusura = false;
                    }
                }

                return (
                    <AzioniTrattamentoServizioModificaRow
                        key={i}
                        index={i}
                        id={action.AssessmentAzioniDiTrattamentoId}
                        //id={user.id}
                        action={action}
                        radioGroup={radioGroup}
                        updateGrade={this.updateGrade}
                        updateDataChiusura={this.updateDataChiusura}
                        updadateCheckedRow={this.updadateCheckedRow}
                        updateEffectiveAction={this.updateEffectiveAction}
                        updateAzioneConsigliata={this.updateAzioneConsigliata}
                        updateStrutturaReferente={this.updateStrutturaReferente}
                        save={this.save}
                    />
                );
            }.bind(this)
            );

            for (let index = 0; index < rowsTemp.length; index++) {
                const element = rowsTemp[index];

                rows.push(element);
                rows.push(<hr key={"hrAzioni" + index}></hr>);

            }
        }
        return rows;
    }

    render() {

        let minacceRows = [];
        if (this.state.minacceList.length === 0) {
            minacceRows = <tr><td>{this.state.emptyTest}</td></tr>
        } else {
            minacceRows = this.state.minacceList.map(function (minaccia, i) {
                return (
                    <MinacciaRow
                        key={i}
                        index={i}
                        minaccia={minaccia}
                    />
                );
            }.bind(this)
            );
        }

        var pathOpzioni = this.props.PA === "true" ? "/PAModificaTrattamento" : "/ServizioModificaTrattamento";

        var testoBreadcrumb = this.props.PA === "true" ? "Azioni di trattamento per PA" : "Azioni di trattamento per servizio";

        let name = (this.props.PA) ? this.state.nomePA : this.state.serviceName;

        return (
            <div>
                <div id="main">

                    <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                        <div className="container cnsContainer">


                            <div className="row">

                                <div className="col-lg-12">

                                    <nav className="" aria-label="sei qui:" role="navigation">
                                        <ul className="Breadcrumb">
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/TrattamentoRischio')}>Trattamento del rischio</a></li>
                                            <li className="Breadcrumb-item"><a className=" u-color-50" href="#">{testoBreadcrumb}</a></li>
                                        </ul>
                                    </nav>
                                    <span className="titoloPagina">Azioni di trattamento: {(this.props.PA) ? this.state.nomePA : this.state.serviceName}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-9 giustifica">
                                    <p>Elenco delle azioni di trattamento e delle relative misure di sicurezza. <br />
                                        E' necessario intervenire scegliendo una delle seguenti opzioni:<br />
                                        (1) Azione di trattamento consigliata dal sistema, (2) Azione personalizzata, (3) Nessuna azione di trattamento. <br />
                                        Ogni azione di trattamento si completa definendo la propria data di pianificazione. <br />
                                        Opzionalmente è possibile modificare il grado di implementazione "target" per verificare se il rischio residuo della minaccia si avvicina al livello della propensione al rischio. <br />
                                        Premendo il tasto "PUBBLICA" il piano di trattamento viene consolidato.
                                    </p>
                                    <span className="InfoAgg">* Le azioni di trattamento elencate sono riferite alle sole minacce definite da mitigare nelle opzioni di trattamento</span>
                                </div>

                                <div className="col-lg-3">
                                    <button onClick={this.setGradoDialog}
                                        type="button"
                                        className="btn btn-warning btn-lg btn-block"
                                        disabled={(this.state.statoAssessment === "") || (this.state.statoAssessment < 4)}
                                    >COMPILAZIONE AUTOMATICA</button>
                                </div>
                            </div>
                            <div className="linea0066cc"></div>
                            <div className="container cnsContainer">

                                <div className="row">
                                    <div className="col-lg-10"></div>

                                    <div className="col-lg-2">

                                    </div>

                                </div>
                            </div>

                            <div className="container cnsContainer">

                                <div className="row">
                                    <div className="col-lg-9 card-headerLeft nascondiCell"></div>
                                    <div className="col-lg-2 card-headerCenter nascondiCell"><strong> Grado di implementazione </strong></div>

                                    <div className="col-lg-1 card-headerCenter nascondiCell "><strong> Chiusura prevista</strong></div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-2 card-headerLeft nascondiCell"><strong> Azioni di trattamento</strong>
                                    </div>
                                    <div className="col-lg-2 card-headerLeft nascondiCell"><strong> Filtra azioni per minaccia:</strong>	</div>
                                    <ThreatSelect menaceList={this.state.listAllMenace} updateMenace={this.updateMenace} />
                                    <div className="col-lg-3 card-headerCenter nascondiCell"></div>
                                    <div className="col-lg-1 card-headerCenter nascondiCell"><strong> Attuale</strong></div>
                                    <div className="col-lg-1 card-headerCenter nascondiCell"><strong>Target</strong></div>

                                    <div className="col-lg-1 card-headerCenter nascondiCell "><strong> Trimestre/Anno</strong></div>

                                </div>

                                <span className="lineaGrigiaT"></span>

                                {this.getAzioniTrattamentoServizioModificaRow()}
                            </div>

                            <br />
                            <br />
                            <br />
                        </div>

                    </div>

                </div>
                <div style={{ position: 'fixed', bottom: '0', left: '0', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                    <div className="row spazio20">
                        <div className="col-lg-1"></div>

                        <div className="col-lg-3">
                            {/* <a href="javascript:void(0);" onClick={() =>  history.push(pathOpzioni)}> */}
                            <a href="javascript:void(0);" onClick={() => this.redirect("ServizioModificaTrattamento")}>
                                <button type="button"
                                    hidden={this.state.statoAssessment < 4}
                                    className="btn btn-warning btn-lg btn-block">
                                    <i className="fa fa-angle-double-left"></i> OPZIONI DI TRATTAMENTO
		                    </button>
                            </a>
                        </div>
                        <div className="col-lg-3" >
                            <button type="button"
                                onClick={this.changeStateAssessment}
                                hidden={this.state.statoAssessment < 4}
                                className="btn btn-success btn-lg btn-block">
                                PUBBLICA</button>
                        </div>
                        <div className="col-lg-3">
                            <button type="button"
                                //data-toggle="modal"
                                //data-target="#sidebar-left"
                                onClick={() => this.redirect("AzioniTrattamentoRischioResiduo")}
                                hidden={this.state.statoAssessment < 4}
                                className="btn btn-warning btn-lg btn-block">RISCHIO RESIDUO
                            </button>
                        </div>

                        <div className="col-lg-1"></div>

                    </div>
                </div>

                {/* <!-- Sidebar Left --> */}
                <div className="modal fade" id="sidebar-left" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header"><h1 className="testoBlu">Rischio residuo</h1>
                                <button type="button" className="close" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <table className="Tablejs-TableResponsive tablesaw tablesaw-stack">
                                <tbody>
                                    <tr >
                                        <td ><span className="card-headerLeft font16 "><strong>Minacce</strong></span></td>
                                        <td style={{ textAlign: "center" }} ><span className="card-headerLeft font16"><strong>Rischio attuale</strong></span></td>
                                        <td style={{ textAlign: "center" }} ><span className="card-headerLeft font16" ><strong>Propensione</strong></span></td>
                                        <td style={{ textAlign: "center" }}  ><span className="card-headerLeft font16"><strong>Rischio residuo</strong></span></td>
                                    </tr>
                                    {minacceRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.messageSuccess} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.messageWarning} />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnSetGrado'} handle={this.setGradoAttesoDefault} textModal={"L'operazione sovrascrive le eventuali modifiche apportate alle Azioni di Trattamento. Procedere?"} />
                <ModalDialog idTarget={'warningAction2'} idBtn={'btnPubblica'} handle={this.callCambiaStato} textModal={"L'operazione sovrascrive l'eventuale piano di trattamento già presente. Procedere?"} />
            </div>

        )
    }
}
