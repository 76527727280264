import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { API_CONFIG } from '../../public/js/apiServices';
import { MisuraDiSicurezzaRow } from './MisuraDiSicurezzaRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { toStringStatoAssessmentId } from "../data/StatoAssessmentId";
import { Util } from "../data/Util";
import { AccordionInfo } from './AccordionInfo';

/**
 * 
 * @param {*} dataCreazione 
 */
function toStringDate(dataCreazione) {
    try {
        let dataSplit = dataCreazione.split('T')[0].split('-');
        let year = dataSplit[0];
        let month = dataSplit[1];
        let day = dataSplit[2];
        return [day + '-' + month + '-' + year, dataCreazione.split('T')[1].split('.')[0]];

    } catch (error) {
        console.error(error);
        return null;
    }
}

const LINK = [
    { nome: "Compliance Self-Assessment", url: "/GapAnalysisModifica" },
    { nome: "Gap Analysis", url: "/ListaApplicazioniGapAnalysis" },
    { nome: "Visualizza Self-Assessment", url: "/GapAnalysisVisualizza" }
];

const DonutChart = createClass({
    propTypes: {
        value: PropTypes.number,        // value the chart should show
        valuelabel: PropTypes.string,
        indicator: PropTypes.string, // label for the chart
        size: PropTypes.number,         // diameter of chart
        strokewidth: PropTypes.number   // width of chart line
    },
    getDefaultProps() {
        return {
            value: 0,
            valuelabel: '',
            size: 116,
            strokewidth: 7
        };
    },
    render() {

        const halfsize = (this.props.size * 0.5);
        const radius = halfsize - (this.props.strokewidth * 0.5);
        const circumference = 2 * Math.PI * radius;
        const strokeval = ((this.props.value * circumference) / 100);
        const dashval = (strokeval + ' ' + circumference);

        const trackstyle = { strokeWidth: this.props.strokewidth };
        const indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval }
        const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
        var value = isNaN(this.props.value) ? 0 : this.props.value;

        return (
            <svg width={this.props.size} height={this.props.size} className={"donutchart"}>
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className={this.props.indicator} />
                <text className="donutchart-text" x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }} >
                    <tspan className="donutchart-text-val">{value}</tspan>
                    <tspan className="donutchart-text-percent">%</tspan>
                    <tspan className="donutchart-text-label" x={halfsize} y={halfsize + 10}>{this.props.valuelabel}</tspan>
                </text>
            </svg>
        );
    }
})

export class AnalisiRischioVisualizza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //serviceId:window.sessionStorage.getItem("serviceId"),
            criticità: ["", ""],
            dataCalcoloRischioAttuale: "",
            dataRischioAttuale: "",
            dataModifica: "",
            dataPubblicazione: "",
            impattoD: ["", ""],
            impattoI: ["", ""],
            impattoR: ["", ""],
            statoAssessmentId: "",
            listAssessment: [],
            selectedAssessment: undefined,
            assessmentQuestionario: [],
            assessmentQuestionarioPrev: [],
            messageError: '',
            serviceName: window.sessionStorage.getItem("serviceName"),
            singolaAnalisi : false

        }
        this.updateGrade = this.updateGrade.bind(this);
        this.getCountsOfAnswers = this.getCountsOfAnswers.bind(this);
        this.getSumCounterWithAnswer = this.getSumCounterWithAnswer.bind(this);
        this.updateAssessmentId = this.updateAssessmentId.bind(this);
        this.isValidate = this.isValidate.bind(this);
        this.isServiceUpdated = this.isServiceUpdated.bind(this);
        this.save = this.save.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.setInfo = this.setInfo.bind(this);
    }

    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        if (window.sessionStorage.getItem("serviceId")) {
            let serviceId = window.sessionStorage.getItem("serviceId");
            let dettaglioAssessmentId = window.sessionStorage.getItem("dettaglioAssessmentId");
            if(this.props.dettaglio)
                this.setState({ singolaAnalisi: true });
            
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.assessmentcompositeListAssessment,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: "{ \"ServizioId\": \"" + serviceId + "\"}",
                    cache: false,
                    success: function (response) {
                        console.log('listAssessment:', response);
                        this.setState({ listAssessment: response.data });
                      
                        if(dettaglioAssessmentId != undefined)
                        {
                           
                                for (let i = 0; i < response.data.length; i++) {
                                   
                                    if(response.data[i].AssessmentId == dettaglioAssessmentId)
                                    {
                                       
                                        this.setState({ selectedAssessment: response.data[i] });
                                        console.log("selectedAssessment", response.data[i]);
                                        break;
                                    }
                                }
                        }
                        else
                        {
                                this.setState({ selectedAssessment: this.state.listAssessment[0] });
                        }
                        
                        $.ajax({
                            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                            type: "POST",
                            contentType: 'application/json',
                            headers: { 'token': sessionStorage.getItem('token') },
                            data: "{ \"AssessmentId\":" + this.state.selectedAssessment.AssessmentId + "}",
                            cache: false,
                            success: function (response) {
                                console.log('AssessmentQuestionario:', response);
                                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza })
                                this.setInfo(response.data.Informazioni[0]);
                                if ($(".statoCompilazione").length > 0)
                                    $(".statoCompilazione").get(0).click();
                                console.log('dettaglioAssessmentId:', dettaglioAssessmentId);
                                if(dettaglioAssessmentId != undefined)
                                {
                                    let findI = this.state.listAssessment.findIndex(item => item.AssessmentId == dettaglioAssessmentId);
                                    console.log('findI:', findI);
                                    if(findI !== (this.state.listAssessment.length-1))
                                    {
                                        let prevAssessmentId = this.state.listAssessment[findI+1].AssessmentId;
                                        $.ajax({
                                            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                                            type: "POST",
                                            contentType: 'application/json',
                                            headers: { 'token': sessionStorage.getItem('token') },
                                            data: "{ \"AssessmentId\":" + prevAssessmentId + "}",
                                            cache: false,
                                            success: function (response) {
                                                console.log('AssessmentQuestionarioPrev:', response);
                                                this.setState({ assessmentQuestionarioPrev: response.data.CategoriaMisuraDiSicurezza })
                                            }.bind(this),                
                                            error: function (xhr, resp, text) {
                                                let messageError = "Si è verificato un errore ";
                                                console.error(xhr);
                                                //Token JWT Invalid
                                                if (xhr.responseJSON) {
                                                    if (xhr.responseJSON.data === -1) {
                                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                                        location.replace(API_CONFIG.HomePath);
                                                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                                                }
                                                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                                            }.bind(this),
                                        });
                                    }
                                }
                                
                            }.bind(this),

                            error: function (xhr, resp, text) {
                                let messageError = "Si è verificato un errore ";
                                console.error(xhr);
                                //Token JWT Invalid
                                if (xhr.responseJSON) {
                                    if (xhr.responseJSON.data === -1) {
                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                        location.replace(API_CONFIG.HomePath);
                                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                                }
                                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                            }.bind(this),
                        });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });
        } else {

        }
    }

    /**
     * 
     * @param {*} grade 
     * @param {*} categoriaId 
     * @param {*} misuraDiSicurezzaId 
     */
    updateGrade(grade, categoriaId, misuraDiSicurezzaId) {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        try {
            for (let i = 0; i < assessmentQuestionario.length; i++) {
                if (assessmentQuestionario[i].CategoriaMisuraDiSicurezzaId === categoriaId) {
                    for (let j = 0; j < assessmentQuestionario[i].items.length; j++) {
                        const misuraDiSicurezza = assessmentQuestionario[i].items[j];
                        if (misuraDiSicurezza.MisuraDiSicurezzaId === misuraDiSicurezzaId) {
                            misuraDiSicurezza.GradoDiImplementazione = grade;
                            this.setState({ assessmentQuestionario: assessmentQuestionario });
                            console.log('updateGarde', this.state.assessmentQuestionario);
                        }
                    }
                }
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getCountsOfAnswers() {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        let tot = 0;
        let totNonRisposto = [];
        let totAdeguato = [];
        let totAssente = [];
        let totCompleto = [];
        //countsOfAnswers['TotaleImplementate'] = 0;
        let totMigliorabile = [];
        let totNonApplicabile = [];
        let totNonSoRispondere = [];
        let counts = [];

        for (let index = 0; index < assessmentQuestionario.length; index++) {
            const element = assessmentQuestionario[index];
            tot += element.items.length;
            for (let j = 0; j < element.items.length; j++) {
                const item = element.items[j];

                switch (item.GradoDiImplementazione) {

                    case -1:
                        totNonRisposto.push(item);
                        break;
                    case 1://Non Applicabile
                        totNonApplicabile.push(item);
                        break;
                    case 2://Non so rispondere
                        totNonSoRispondere.push(item);
                        break;
                    case 3://Assente
                        totAssente.push(item);
                        break;
                    case 4://Migliorabile
                        totMigliorabile.push(item);
                        break;
                    case 5://Adeguato
                        totAdeguato.push(item);
                        break;
                    case 6://Completo
                        totCompleto.push(item);
                        break;
                    default://
                        break;
                }
            }
        }
        counts['tot'] = tot;
        counts['totNonRisposto'] = totNonRisposto.length;
        counts['totNonApplicabile'] = totNonApplicabile.length;
        counts['totAssente'] = totAssente.length;
        counts['totMigliorabile'] = totMigliorabile.length;
        counts['totAdeguato'] = totAdeguato.length;
        counts['totCompleto'] = totCompleto.length;
        counts['totNonSoRispondere'] = totNonSoRispondere.length;
        return counts;
    }

    /**
     * 
     */
    getSumCounterWithAnswer() {
        let counts = this.getCountsOfAnswers();
        let sumCounterWithAnswer = 0;
        try {
            for (const key in counts) {
                if (counts.hasOwnProperty(key)) {
                    if (key !== 'tot' && key !== 'totNonRisposto') {
                        sumCounterWithAnswer += counts[key];
                    }
                }
            }
            return sumCounterWithAnswer;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} event 
     */
    updateAssessmentId(event) {
        let index = parseInt(event.target.value, 10);
        let listAssessment = this.state.listAssessment;
        let assessment = {
            id: listAssessment[index].AssessmentId,
            dataCreazione: listAssessment[index].DataCreazione
        }
        console.log('assessment', assessment);
        this.setState({ selectedAssessment: listAssessment[index] });
        $.ajax({
            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: "{ \"AssessmentId\":" + listAssessment[index].AssessmentId + "}",
            cache: false,
            success: function (response) {
                console.log('AssessmentQuestionario:', response);
                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza })
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }


    isValidate() {
        try {
            let counter = [];
            let categorieCaratteristica = this.state.serviziComposite[0].CategorieCaratteristica;
            const categorieCaratteristicaLength = categorieCaratteristica.length;
            for (let index = 0; index < categorieCaratteristica.length; index++) {
                const caratteristiche = categorieCaratteristica[index].Caratteristica;
                for (let j = 0; j < caratteristiche.length; j++) {
                    const element = caratteristiche[j];
                    if (element.Checked) {
                        counter.push(element);
                        return;
                    }
                }

            }
            console.log('validate:', counter);
            return counter.length === categorieCaratteristicaLength;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
    * handle save button clicked
    */
    save(e) {
        // data in the form
        console.log('data', JSON.stringify(this.state.assessmentQuestionario));

        if (true) {
            // submit form data to api
            $.ajax({
                //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
                url: API_CONFIG.assessmentcompositeSaveOrUpdate,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                //data: JSON.stringify(form_data),
                data: JSON.stringify(this.state.assessmentQuestionario),
                cache: false,
                success: function (response) {
                    console.log(response);
                    if (response.status === 'success') {
                        location.reload();
                        //history.push('/ListaServizi');
                        //alert(response.status);
                    }
                    else {
                        alert(response.status);
                    }
                },

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
        } else {
            alert('Prima di Salvare compilare tutte le Categorie');
        }

        e.preventDefault();
    }

    saveDraft(e) {
        // data in the form
        let serviziComposite = this.state.serviziComposite;
        serviziComposite[0]['Bozza'] = 'string';
        this.setState({ serviziComposite: serviziComposite });
        //console.log('saveDraft', this.state.serviziComposite);
        // submit form data to api
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
            url: API_CONFIG.serviziSaveOrUpdateUrl,
            type: "POST",
            contentType: 'application/json',
            headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
            data: JSON.stringify(this.state.serviziComposite),
            cache: false,
            success: function (response) {
                console.log(response);
                if (response.status === 'success') {
                    history.push('/ListaServizi');
                    //alert(response.status);
                }
                else {
                    //TODO
                }
            },

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });

        e.preventDefault();
    }

    /**
     * 
     * @param {*} values 
     */
    calculate(values) {
        var sum = 0;
        var count = 0;
        var order = [];

        for (var i = 0; i < values.length; i++) {
            sum += parseInt(values[i], 10);
            //intVal.push (parseInt(values[i]));
            //decimalVal.push (values[i] - parseInt(values[i]));
            order[i] = i
        }
        var diff = 100 - sum;
        //decimalVal.sort(function(a, b){return b-a});
        order.sort(function (a, b) {
            var dVala = values[a] - parseInt(values[a], 10);
            var dValb = values[b] - parseInt(values[b], 10);
            return dValb - dVala
        });

        values.sort(function (a, b) {
            var dVala = a - parseInt(a, 10);
            var dValb = b - parseInt(b, 10);
            return dValb - dVala
        });
        for (var j = 0; j < values.length; j++) {
            count = j;
            if (count < diff) {
                values[j] = parseInt(values[j], 10) + 1;
            } else {
                values[j] = parseInt(values[j], 10);
            }
        }
        var unsorted = [];
        for (var k = 0; k < values.length; k++) {
            //values[order[i]] = data_x[i];
            unsorted[order[k]] = values[k];
        }
        return unsorted;
    }

    /**
      * 
      */
    isServiceUpdated() {
        try {
            let tsDataAggiornamento = new Date(this.state.selectedAssessment.DataModifica).getTime();
            let tsDataCalcoloCriticità = new Date(this.state.selectedAssessment.DataCalcoloRischioAttuale).getTime();

            return tsDataAggiornamento > tsDataCalcoloCriticità;
        } catch (error) {
            return false;
        }
    }

    /**
    * 
    * @param {*} dataInformazioni :response.data.Informazioni[0]
    */
    setInfo(dataInformazioni) {
        try {
            this.setState({ criticità: Util.toStringCriticità(dataInformazioni.Criticità) });
            //
            let dataCalcoloRischioAttuale = Util.toStringDate(dataInformazioni.DataCalcoloRischioAttuale);
            dataCalcoloRischioAttuale = dataCalcoloRischioAttuale["day"] + "/" + dataCalcoloRischioAttuale["month"] + "/" + dataCalcoloRischioAttuale["year"] + " " + dataCalcoloRischioAttuale["hours"] + ":" + dataCalcoloRischioAttuale["minutes"] + ":" + dataCalcoloRischioAttuale["seconds"];
            //
            this.setState({ dataRischioAttuale: dataCalcoloRischioAttuale });
            //
            let dataPubblicazione = Util.toStringDate(dataInformazioni.DataPubblicazione);
            dataPubblicazione = dataPubblicazione["day"] + "/" + dataPubblicazione["month"] + "/" + dataPubblicazione["year"] + " " + dataPubblicazione["hours"] + ":" + dataPubblicazione["minutes"] + ":" + dataPubblicazione["seconds"];
            this.setState({ dataPubblicazione: dataPubblicazione });
            //
            let dataModifica = Util.toStringDate(dataInformazioni.DataModifica)
            dataModifica = dataModifica["day"] + "/" + dataModifica["month"] + "/" + dataModifica["year"] + " " + dataModifica["hours"] + ":" + dataModifica["minutes"] + ":" + dataModifica["seconds"];
            this.setState({ dataModifica: dataModifica });
            //this.setState({ dataCalcoloRischioAttuale: dataInformazioni.DataCalcoloRischioAttuale });
            this.setState({ impattoD: Util.toStringCriticità(dataInformazioni.ImpattoD) });
            this.setState({ impattoI: Util.toStringCriticità(dataInformazioni.ImpattoI) });
            this.setState({ impattoR: Util.toStringCriticità(dataInformazioni.ImpattoR) });
            this.setState({ statoAssessmentId: toStringStatoAssessmentId(dataInformazioni.StatoAssessmentId) });
            //debugger
        } catch (error) {
            return console.error(error);
        }
    }

    render() {
        const COUNTS = this.getCountsOfAnswers();
        var values = [];
        //values[0] = COUNTS['Totale'] / COUNTS['Totale'] * 100;
        //values[3] = COUNTS['TotaleImplementate'] / COUNTS['Totale'] * 100;
        values[0] = COUNTS['totNonApplicabile'] / COUNTS['tot'] * 100;
        values[1] = COUNTS['totAssente'] / COUNTS['tot'] * 100;
        values[2] = COUNTS['totMigliorabile'] / COUNTS['tot'] * 100;
        values[3] = COUNTS['totAdeguato'] / COUNTS['tot'] * 100;
        values[4] = COUNTS['totCompleto'] / COUNTS['tot'] * 100;
        values[5] = COUNTS['totNonSoRispondere'] / COUNTS['tot'] * 100;
        values[6] = COUNTS['totNonRisposto'] / COUNTS['tot'] * 100;

        values[7] = ((
            COUNTS['tot'] -
            COUNTS['totAdeguato'] -
            COUNTS['totAssente'] -
            COUNTS['totCompleto'] -
            //COUNTS['TotaleImplementate'] -
            COUNTS['totMigliorabile'] -
            COUNTS['totNonApplicabile'] -
            COUNTS['totNonRisposto'] -
            COUNTS['totNonSoRispondere']) /
            COUNTS['tot']) * 100;

        const RISPOSTE = this.calculate(values);
        //const answer = RISPOSTE[0] + RISPOSTE[1] + RISPOSTE[2] + RISPOSTE[3] + RISPOSTE[4] + RISPOSTE[5];
        //const SENZA_RISPOSTA = 100 - answer;
        var rows = '';
        if (this.state.assessmentQuestionario.length === 0) {
            rows = <div className="row">Loading...</div>
        } else {
            rows = this.state.assessmentQuestionario.map(function (assessment, i) {

                let index = this.state.assessmentQuestionarioPrev.findIndex(item=> item.CategoriaMisuraDiSicurezzaNome == assessment.CategoriaMisuraDiSicurezzaNome)
                let itemsPrev= [];
                if(index > -1)
                    itemsPrev = this.state.assessmentQuestionarioPrev[index].items;

                return (
                    <MisuraDiSicurezzaRow
                        key={i}
                        rowId={i}
                        readOnly={true}
                        categoriaId={assessment.CategoriaMisuraDiSicurezzaId}
                        misuraDiSicurezzaNome={assessment.CategoriaMisuraDiSicurezzaNome}
                        items={assessment.items}
                        itemsPrev={itemsPrev}
                        updateGrade={this.updateGrade}
                        singolaAnalisi={this.state.singolaAnalisi}
                    />
                );
            }.bind(this)
            );
        }

        let container = '';
        let testo = '';

        let dataAggiornamentoContesto = '';
        if (this.state.selectedAssessment) {
            dataAggiornamentoContesto = toStringDate(this.state.selectedAssessment.DataAggiornamentoContesto);
            if (dataAggiornamentoContesto === null)
                dataAggiornamentoContesto = ["-", "-"];
        }
        if (this.state.selectedAssessment === undefined) {
            container = <div>Nessuna Gap Analisys Selezionata</div>
        } else if (this.state.selectedAssessment !== undefined) {
            testo = "Analisi del rischio effettuata a partire dall'Analisi del contesto e dalla Valutazione degli impatti del " + dataAggiornamentoContesto[0] + " " + dataAggiornamentoContesto[1] + ". Per effettuare il calcolo del rischio rispondere a tutte le domande del Questionario";
            container =
                <div>
                    <AccordionInfo
                        statoAssessmentId={this.state.statoAssessmentId}
                        criticità={this.state.criticità}
                        impattoR={this.state.impattoR}
                        impattoI={this.state.impattoI}
                        impattoD={this.state.impattoD}
                        dataCalcoloRischioAttuale={this.state.dataRischioAttuale}
                        dataPubblicazione={this.state.dataPubblicazione}
                        dataModifica={this.state.dataModifica}
                        iuc = {this.state.selectedAssessment.RischioAggregato}
                    />

                    <div className="row">
                        <div className="col-lg-6 card-headerInfo">
                            <a className="card-linkInfo marginLeft" data-bs-toggle="collapse" href="#collapse">
                                Stato compilazione</a>
                        </div>
                        <div className="col-lg-5 card-header text-right ">
                            {this.getSumCounterWithAnswer() + '/' + this.getCountsOfAnswers()['tot']}</div>
                        <div className="col-lg-1 card-headerInfo">
                            <a className="card-linkInfo marginLeft statoCompilazione" data-bs-toggle="collapse" href="#collapse">
                                <i className="iconRight fa fa-chevron-down"></i>
                            </a>
                        </div>

                    </div>
                    <div id="collapse" className="collapse marginLeft" data-parent="#accordion">
                        <div className="row spazio10">

                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	COMPLETO<br />
                                <DonutChart value={RISPOSTE[4]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">{RISPOSTE[4] + '% - ' + this.getCountsOfAnswers()['totCompleto'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ADEGUATO<br />
                                <DonutChart value={RISPOSTE[3]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[3] + '% - ' + this.getCountsOfAnswers()['totAdeguato'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	MIGLIORABILE<br />
                                <DonutChart value={RISPOSTE[2]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[2] + '% - ' + this.getCountsOfAnswers()['totMigliorabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ASSENTE<br />
                                <DonutChart value={RISPOSTE[1]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[1] + '% - ' + this.getCountsOfAnswers()['totAssente'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON APPLICABILE<br />
                                <DonutChart value={RISPOSTE[0]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[0] + '% - ' + this.getCountsOfAnswers()['totNonApplicabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON SO<br />
                                <DonutChart value={RISPOSTE[5]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[5] + '% - ' + this.getCountsOfAnswers()['totNonSoRispondere'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="spazio10"></div>
                    {this.state.selectedAssessment !== undefined && rows}
                    {this.state.selectedAssessment !== undefined
                        /* &&
                          <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                             <div className="row spazio20">
                                 <div className="col-lg-4 ">	</div>
 
                                 <div className="col-lg-4">
                                     <button type="button"
                                         onClick={this.save}
                                         disabled={this.state.assessmentQuestionario.length === 0}
                                         className="btn btn-success btn-lg btn-block">PUBBLICA</button>
                                 </div>
                                 <div className="col-lg-4">	</div>
 
                             </div>
                         </div> */
                    }

                </div>
        }

        let assessmentOption = this.state.listAssessment.map(function (item, i) {
            var data = toStringDate(item.DataCreazione);
            return (
                //<option key={i} value={item.AssessmentId}>{toStringDate(item.DataCreazione)}</option>
                <option key={i} value={i}>{data[0] + ' ' + data[1]}</option>
            );
        });



        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-8">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Self-Assessment Applicazione: {this.state.serviceName}</span>
                            &nbsp;<i hidden={!this.isServiceUpdated()} className="fa fa-warning testoRosso"></i>
                        </div>

                        <div className="col-lg-2">

                        </div>

                        {!this.state.singolaAnalisi && <div className="col-lg-2">
                            <span>STORICO ANALISI</span>
                            <select className="form100"
                                defaultValue={this.state.defaultValue}
                                onChange={this.updateAssessmentId}>
                                {assessmentOption}
                            </select>

                            {/* Data Creazione:<br />{dataCreazione[0]}<br />{dataCreazione[1]} */}

                            <div className="spazio10"></div>

                            <a>
                                {/* <button type="button" onClick={() => this.iniziaAssessment()} className="btn btn-warning btn-lg btn-block">NUOVA ANALISI</button> */}
                            </a>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                        <p>Elenco dei requisiti di sicurezza e privacy a cui l’utente deve dare un riscontro selezionando dall'elenco proposto dal sistema, lo stato di attuazione attuale.</p>
                            {/* <p>{testo}</p> */}
                            {!this.state.singolaAnalisi && <span className="paRight InfoAgg">
                                <i hidden={!this.isServiceUpdated()} className="fa fa-warning testoRosso"></i> 
                                {this.isServiceUpdated() && "Analisi modificata"} 
                                </span>}
                            {this.state.singolaAnalisi && <span className="paRight InfoAgg testoRosso">                          
                                i requisiti evidenziati in rosso sono stati modificati rispetto alla precedente analisi
                            </span>}
                        </div>
                    </div>

                    <div className="linea0066cc" style={{marginTop:"8px"}}></div>
                    {container}

                </div>
                <br />
                <br />
                <br />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
            </div>
        )
    }
}