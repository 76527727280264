import * as React from 'react';
import Months from '../data/Months';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { AssessmentMonitoraggioSelect } from './AssessmentMonitoraggioSelect';
import ASSESSMENT_STATE from "./AssessmentMonitoraggioState";
import EVENT_TYPE from "./EventMonitoringType";
import { isNullOrUndefined } from 'util';
import Popup from 'reactjs-popup'

// component that renders a single AzioniTrattamentoServizioModifica row
export class MonitoraggioServizioRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            assessmentMonitoraggioState: "",
            descriptionEvent: "",
            textareaValue: "",
            emptyText: '-'
        };

        this.createQuarterTables = this.createQuarterTables.bind(this);
        this.getIconIsCheck = this.getIconIsCheck.bind(this);
        this.getAccordion = this.getAccordion.bind(this);
        this.getQuarterTdElement = this.getQuarterTdElement.bind(this);
        //

        this.insertAssessmentMonitoraggioEvento = this.insertAssessmentMonitoraggioEvento.bind(this);
        //ToString
        this.toStringDate = this.toStringDate.bind(this);
        this.toStringActionTitle = this.toStringActionTitle.bind(this);

        //UPDATE
        this.updateCausale = this.updateCausale.bind(this);
        this.updateMonitoraggio = this.updateMonitoraggio.bind(this);
        this.updateDescriptionEvent = this.updateDescriptionEvent.bind(this);
        this.updateAssessmentMonitoraggioState = this.updateAssessmentMonitoraggioState.bind(this);
    }


    /**
     * 
     * @param {*} date : prende in input una stringa in formata data "2018-10-16T22:00:00.000Z" e restituisce un array con 4 stringhe
        "day" <= 09, "monthStr" <= "Agosto", "month" = "8", "year" <= "2018";
        dateArray["month"]
     */
    toStringDate(date) {
        let dateArray = {
            "year": "",
            "day": "",
            "monthStr": "",
            "month": "",
            "hours": "",
            "minutes": "",
            "seconds": ""
        };
        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let day = dateParse.getDate();
                dateArray.day = (day > 9) ? day : '0' + day;
                dateArray.monthStr = Months[dateParse.getMonth()];
                let month = dateParse.getMonth() + 1;
                dateArray.month = (month > 9) ? month : '0' + month;;
                dateArray.year = dateParse.getFullYear();
                dateArray.hours = (dateParse.getHours() > 9) ? dateParse.getHours() : "0" + dateParse.getHours();
                dateArray.minutes = (dateParse.getMinutes() > 9) ? dateParse.getMinutes() : "0" + dateParse.getMinutes();
                dateArray.seconds = (dateParse.getSeconds() > 9) ? dateParse.getSeconds() : "0" + dateParse.getSeconds();
            }
            return dateArray;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);

                    //var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(<span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);

            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
            }
        }
        return toString;
    }

    /**
     * 
     */
    insertAssessmentMonitoraggioEvento(event) {
        let dataModel = {
            "AssessmentMonitoraggioId": this.props.assessment.AssessmentMonitoraggioId,
            "Descrizione": this.state.descriptionEvent
        }

        try {
            if (this.state.descriptionEvent.length !== 0) {
                this.serverRequest =
                    $.ajax({
                        type: 'POST',
                        url: API_CONFIG.insertAssessmentMonitoraggioEvento,
                        contentType: 'application/json',
                        headers: { 'token': sessionStorage.getItem('token') },
                        data: JSON.stringify(dataModel),
                        cache: false,
                        success: function (response) {
                            if (response.status === 'success') {
                                this.setState({ descriptionEvent: "" });
                                console.log('updateMonitoraggio : ', response.status);
                                this.props.getMonitoraggioByAssessmentId();
                                //location.reload();
                            }
                            //alert(response.status);
                        }.bind(this),

                        error: function (xhr, resp, text) {
                            let messageError = "Si è verificato un errore ";
                            console.error(xhr);
                            //Token JWT Invalid
                            if (xhr.responseJSON) {
                                if (xhr.responseJSON.data === -1) {
                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                    location.replace('/Home');
                                } else {
                                    this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                    $('#btnError').get(0).click();
                                }
                            }
                            else {

                                this.setState({ messageError: messageError + "." });
                                $('#btnError').get(0).click();
                            }
                        }.bind(this),
                    });
            }
            else {
                this.props.setMessageWarning("Il campo 'Descrizione Evento' non può essere vuoto. Inserire la descrizione prima di salvare la modifica")
            }
        } catch (error) {
            return console.error(error);
        }
        event.preventDefault();
    }

    /**
     * 
     * @param {*} text 
     * @param {*} length 
     * @param {*} ending 
     */
    toStringActionTitle() {
        //var jobDesc = data.value[i].content;
        let jobDesc = this.props.action.Azione;
        let length = 100;
        //jobDesc = jobDesc.substring(0, jobDesc.substring(0, 500).lastIndexOf(' ') + 1);
        jobDesc = jobDesc.substring(0, jobDesc.substring(0, length).lastIndexOf(' ') + 1);
        if (this.props.action.Azione !== "" && jobDesc === "") {
            jobDesc = this.props.action.Azione.substring(0, length);
        }
        jobDesc = jobDesc.replace(/\n\s*\n/g, '\n');
        return jobDesc + "...";
    };

    /**
     getQuarterDate(date) {
         if (date) {
             var quarter = Math.ceil((new Date(date).getMonth() + 1) / 3);
             var year = new Date(date).getFullYear();
             //console.log("quarterDate", "Q" + quarter + " - " + year);
             return "Q" + quarter + " - " + year;
            }
            else
            return "-1";
        }
     */

    /**
    * @param {*} date <= "2019-01-17T11:10:53.740Z"
    * @returns "Q1 - 2019"
    */
    getQuarterDate(date) {
        if (date) {
            var quarter = Math.ceil((new Date(date).getMonth() + 1) / 3);
            var year = new Date(date).getFullYear();
            //console.log("quarterDate", "Q" + quarter + " - " + year);
            return "Q" + quarter + " - " + year;
        }
        else
            return "-1";
    }

    /**
     * 
     */
    getBackgroundColor(index) {
        let backgroundColor = (!isNullOrUndefined(index) && (index >= 0) && (index < ASSESSMENT_STATE.length)) ? ASSESSMENT_STATE[index].bgColor : "";
        return backgroundColor;
    }

    /**
     * 
     * @param {*} event 
     */
    getTextDescription(event) {
        let text = "";
        try {
            switch (event.TipoEventoMonitoraggioId) {
                case 1://
                    text = "Nuova azione di trattamento aggiunta da modifica del piano";
                    break;

                case 2://
                    text = "Azione di trattamento non più presente come da modifica del piano";
                    break;
                case 3://
                    text = "Effettuata una nuova pianificazione per l’azione di trattamento";
                    var dateParse = new Date(Date.parse(event.Descrizione));
                    if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                        this.getQuarterDate(dateParse);
                        text += " - Pianificazione per " + this.getQuarterDate(dateParse);
                    }
                    break;
                default:
                    text = event.Descrizione;
                    break;
            }
            return text;
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    /**
     * 
     */
    getEventRows(assessmentMonitoraggioEvento) {

        let rows = [];

        let dataTitle =
            <div className="col-lg-1 .card-headerLeft" key={"eventDate"}>
                <p><strong>Data</strong></p>
                {/* {rowFirst.date} */}
            </div>;

        let typeTitle =
            <div className="col-lg-2" key={"eventType"}>
                <p><strong>Tipologia</strong></p>
                {/* {rowFirst.eventTypeToString} */}
            </div>;

        let userTitle =
            <div className="col-lg-1" style={{ textAlign: "center" }} key={"eventUser"}>
                <p><strong>Utente</strong></p>
            </div>;
        let descrTitle =
            <div className="col-lg-8" key={"eventDescr"}><p><strong>Descrizione</strong></p>
                {/* {rowFirst.description} */}
            </div>
        rows.push(dataTitle, typeTitle, userTitle, descrTitle);
        if (assessmentMonitoraggioEvento.length === 0) {
            rows.push(<div className="col-lg-12">{"Nessun evento da visualizzare"}</div>)
            return rows;
        }
        try {

            for (let index = 0; index < assessmentMonitoraggioEvento.length; index++) {
                //let id = assessmentMonitoraggioEvento[index].AssessmentMonitoraggioEventoId;
                let event = assessmentMonitoraggioEvento[index];
                let day = this.toStringDate(event.Data).day;
                let monthStr = this.toStringDate(event.Data).month;
                let year = this.toStringDate(event.Data).year.toString().substring(2, 4);
                let data =
                    <div className="col-lg-1 .card-headerLeft" key={"eventDate" + assessmentMonitoraggioEvento[index].AssessmentMonitoraggioEventoId}>
                        {day + "/" + monthStr + "/" + year}
                    </div>
                let eventTypeId = event.TipoEventoMonitoraggioId;
                let eventTypeToString = (!isNullOrUndefined(eventTypeId) && (eventTypeId > 0) && (eventTypeId < EVENT_TYPE.length)) ? EVENT_TYPE[eventTypeId] : "-";

                let type = <div className="col-lg-2" key={"eventType" + assessmentMonitoraggioEvento[index].AssessmentMonitoraggioEventoId}
                >{eventTypeToString}</div>;

                let user =
                    <div className="col-lg-1" style={{ textAlign: "center" }} key={"eventUser" + assessmentMonitoraggioEvento[index].AssessmentMonitoraggioEventoId}>
                        {/* {event.Nome + " " + event.Cognome + " "}<i className="fa fa-user-o" data-bs-toggle="tooltip" data-placement="top" title="Tooltip on left"></i> */}
                        <Popup
                            trigger={<i className="fa fa-user-o" style={{ cursor: "pointer" }}></i>}
                            position="top center"
                            on="hover"
                        >
                            <div style={{ fontSize: "12px" }}>
                                <div style={{ width: "100%", borderBottom: "1px solid gray", textAlign: "center", fontSize: "14px", fontWeight: "bold" }}>Utente </div>
                                <div style={{ width: "100%", textAlign: "center", fontSize: "14px" }}>
                                    {event.Nome + " " + event.Cognome}
                                </div>
                            </div>
                        </Popup>
                    </div>;

                let description =
                    < div className="col-lg-8"
                        key={"eventDescr" + assessmentMonitoraggioEvento[index].AssessmentMonitoraggioEventoId} >
                        {this.getTextDescription(event)}
                        < br /> <br />
                    </div >
                rows.push(data, type, user, description);
            }
            return rows;
        } catch (error) {
            console.error(error);
            rows.push(<div className="col-lg-12">{error.toString()}</div>)
            return rows;
        }
    }

    /**
    * @param {*} q1 <= "Q1 - 2019"
    * @param {*} q2 <=  "Q4 - 2018"
            *
    * @returns if q1 > q2 return 1 otherwise q1 < q2 return -1, return 0 if q1 equauls q2
            */
    compareToQuarter(q1, q2) {
        try {
            let q1Split = q1.split("-");
            let quater1 = q1Split[0];
            let year1 = q1Split[1];
            let q2Split = q2.split("-");
            let quater2 = q2Split[0];
            let year2 = q2Split[1];
            if (year1 > year2)
                return 1;
            else if (year1 < year2) return -1
            else if (quater1 > quater2) return 1
            else if (quater1 < quater2) return -1
            else return 0;

        } catch (error) {
            console.error(error);
            return null;
        }
    }



    /**
     *
     */
    isDeleteAction(listEvents) {
        //2 Azione Eliminata
        if (listEvents.length > 0) {
            return (listEvents[listEvents.length - 1].TipoEventoMonitoraggioId === 2) ? true : false;
        }
        return false
    }

    /**
     * 
     * @param {*} quarter_i <= quarter for example "Q1 - 2019"
     * @param {*} assessment 
     * @param {*} i 
     */
    getQuarterTdElement(quarter_i, assessment, i) {
        try {
            //let quarterNow = this.props.quarterNow;
            let dataInizio = this.getQuarterDate(assessment.DataInizio);
            let dataChiusura = this.getQuarterDate(assessment.DataChiusura);
            //let isDeleteAction = false;
            //if (assessment.AssessmentMonitoraggioEvento) isDeleteAction = this.isDeleteAction(assessment.AssessmentMonitoraggioEvento);
            let isDeleteAction = (parseInt(assessment.Attiva, 10) === 0);
            let bgColor = "";
            let className = "";
            let compareTo = this.compareToQuarter(dataInizio, quarter_i);
            if (compareTo < 1) {
                //confonta la data di chiusura
                if (this.compareToQuarter(dataChiusura, quarter_i) === 0) {
                    bgColor = (isDeleteAction) ? "#999" : "#F79208";
                    className = "sep ";
                }
                else if (this.compareToQuarter(dataChiusura, quarter_i) < 0) {
                    if (this.compareToQuarter(this.props.quarterNow, quarter_i) >= 0) {
                        bgColor = ((!isDeleteAction) && assessment.Stato !== 3) ? "#cc0000" : "";
                        className = "sep bgTrasp ";
                    } else {
                        bgColor = "";
                        className = "sep bgTrasp ";
                    }
                }
                else {
                    bgColor = (isDeleteAction) ? "#999" : "#0066cc";
                    className = "sep ";
                }
            } else {
                className = "sep bgTrasp";
            }
            let tdElement = <td width="10%" style={{ textAlign: "center", backgroundColor: bgColor }}
                key={"tdQuarter" + i}
                className={className} />
            //tdElementsList.push(tdElement);

            return tdElement;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     *
     */
    getTdElements_() {
        let tdElementsList = [];
        try {
            let quarterList = this.props.quarterList;
            let assessment = this.props.assessment;
            let dataInizio = this.getQuarterDate(assessment.DataInizio);
            let dataChiusura = this.getQuarterDate(assessment.DataChiusura);
            let isDeleteAction = false;
            if (assessment.AssessmentMonitoraggioEvento)
                isDeleteAction = this.isDeleteAction(assessment.AssessmentMonitoraggioEvento);
            for (let index = 0; index < quarterList.length; index++) {
                let bgColor = "";
                let className = "";
                let compareTo = this.compareToQuarter(dataInizio, quarterList[index]);
                if (compareTo < 1) {
                    //confonta la data di chiusura
                    if (this.compareToQuarter(dataChiusura, quarterList[index]) === 0) {
                        bgColor = (isDeleteAction) ? "#999" : "#F79208";
                        className = "sep ";
                    }
                    else if (this.compareToQuarter(dataChiusura, quarterList[index]) < 0) {
                        if (this.compareToQuarter(this.props.quarterToString, quarterList[index]) >= 0) {

                            bgColor = ((!isDeleteAction) && assessment.Stato !== 3) ? "#cc0000" : "";
                            className = "sep bgTrasp ";
                        } else {
                            bgColor = "";
                            className = "sep bgTrasp ";
                        }
                    }
                    else {
                        bgColor = (isDeleteAction) ? "#999" : "#0066cc";
                        className = "sep ";
                    }
                } else {
                    className = "sep bgTrasp";
                }
                let tdElement = <td width="10%" style={{ textAlign: "center", backgroundColor: bgColor }} className={className}></td>
                tdElementsList.push(tdElement);
            }

            return tdElementsList;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * 
     * @param {*} quarter_i 
     * @param {*} assessment 
     * @param {*} i 
     */
    getEventSymbolTdElement(quarter_i, assessment, i) {
        let iconsList = [];
        let tdElement = "";
        try {

            for (let index = 0; index < assessment.AssessmentMonitoraggioEvento.length; index++) {
                const event = assessment.AssessmentMonitoraggioEvento[index];
                let eventQuarter = this.getQuarterDate(event.Data);
                //let compareTo = this.compareToQuarter(eventQuarter, quarter_i);
                if (this.compareToQuarter(eventQuarter, quarter_i) === 0) {
                    let eventTypeId = event.TipoEventoMonitoraggioId;
                    let descr = (event.Descrizione) ? event.Descrizione : "";
                    switch (eventTypeId) {
                        case 1://utente
                            //if (!iconsList.find((x) => function (x) { return x.id === "mapPin" }))
                            if (!iconsList.find((x) => this.isContained(x, "mapPin")))
                                iconsList.push({ id: "mapPin", iconClassName: "fa fa-map-pin testoBlu" });
                            break;
                        case 3://clock
                            //if (!iconsList.find((x) => function (x) { return x.id === "clock" }))
                            if (!iconsList.find((x) => this.isContained(x, "clock")))
                                iconsList.push({ id: "clock", iconClassName: "fa fa-clock-o testoBluScuro" });
                            break;
                        case 4://
                            if (descr.toLowerCase().indexOf("sospesa") > -1) {
                                //if (!iconsList.find((x) => function (x) { return x.id.localeCompare("ban") === 0 }))
                                if (!iconsList.find((x) => this.isContained(x, "ban")))
                                    iconsList.push({ id: "ban", iconClassName: "fa fa-ban testoRosso" });
                            }
                            //console.log("indexOf-conclusa", descr.toLowerCase().indexOf("conclusa"));
                            if (descr.toLowerCase().indexOf("conclusa") > -1) {
                                //if (!iconsList.find((x) => function (x) { return x.id.localeCompare("check") === 0 }))
                                if (!iconsList.find((x) => this.isContained(x, "check")))
                                    iconsList.push({ id: "check", iconClassName: "fa fa-check testoVerde" });
                            }
                            break;
                        case 5://map-pin

                            //if (!iconsList.find((x) => function (x) { return x.id.localeCompare("mapPin") === 0 }))
                            if (!iconsList.find((x) => this.isContained(x, "mapPin")))
                                iconsList.push({ id: "mapPin", iconClassName: "fa fa-map-pin testoBlu" });
                            break;
                        default://
                            break;
                    }
                }
            }

            //{ [...new Set(iconsList.map(x => x.iconElement))] }
            tdElement = (iconsList.length > 0) ?
                <td style={{ textAlign: "center" }} className="sep sepLeft " key={"tdElement" + i}>
                    {iconsList.map(function (icon, i) {
                        return (
                            <i className={icon.iconClassName} key={i} />
                        );
                    }
                    )
                    }
                </td>
                :
                <td style={{ textAlign: "center" }} key={"tdElement" + i} className="sep sepLeft "></td>

            return tdElement;
        } catch (error) {
            console.error(error);
            return <td style={{ textAlign: "center" }} className="sep sepLeft ">{error.toString()}</td>
        }
    }

    /**
     * check if the item <=@param item with key <= @param key is already present in the list
     */
    isContained(item, key) {
        return item.id === key;
    }

    /**
     * 
     */
    createQuarterTables() {
        let eventSymbolTdList = [];
        let quarterTdElementsList = [];
        try {
            let quarterList = this.props.quarterList;
            let assessment = this.props.assessment;
            //let dataInizio = this.getQuarterDate(assessment.DataInizio);
            //let dataChiusura = this.getQuarterDate(assessment.DataChiusura);
            //let isDeleteAction = false;
            //if (assessment.AssessmentMonitoraggioEvento)  isDeleteAction = this.isDeleteAction(assessment.AssessmentMonitoraggioEvento);
            for (let index = 0; index < quarterList.length; index++) {
                quarterTdElementsList.push(this.getQuarterTdElement(quarterList[index], assessment, index));
                eventSymbolTdList.push(this.getEventSymbolTdElement(quarterList[index], assessment, index));
            }
            //console.log("eventSymbolTdList", eventSymbolTdList);
            return { quarterTdList: quarterTdElementsList, eventSymbolTdList: eventSymbolTdList };
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * 
     */
    getIconIsCheck() {
        try {
            let assessment = this.props.assessment;
            let icon = ""
            switch (assessment.Stato) {
                case 2:
                    icon = "fa fa-ban testoRosso";
                    break;
                case 3:
                    icon = "testoVerde fa fa-check";
                    break;
                default:
                    break;
            }
            return icon;
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    /**
     * 
     */
    toStringSubCategory(text) {
        //var jobDesc = data.value[i].content;
        let jobDesc = text;
        let length = 500;
        //jobDesc = jobDesc.substring(0, jobDesc.substring(0, 500).lastIndexOf(' ') + 1);
        jobDesc = jobDesc.substring(0, jobDesc.substring(0, length).lastIndexOf(' ') + 1);
        if (text !== "" && jobDesc === "") {
            jobDesc = text.substring(0, length);
        }
        jobDesc = jobDesc.replace(/\n\s*\n/g, '\n');
        return jobDesc + "...";
    };

    /**
     * 
     */
    getAccordion() {
        try {
            let accordion = "";
            if (parseInt(this.props.assessment.Attiva, 10) === 0) {
                accordion = <div className="col-lg-4 card-headerT ">
                    <span className="card-link">
                        {this.props.assessment.SottoCategoria}
                        <a data-bs-toggle="collapse" href={"#collapse" + this.props.id}>
                            <i className="iconRight fa fa-chevron-down"></i> </a>
                    </span>
                </div>
            } else {
                accordion =
                    <div className="col-lg-4 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + this.props.id}>
                            <i className={this.getIconIsCheck()}></i>
                            {" " + this.props.assessment.SottoCategoria}
                            <i className="iconRight fa fa-chevron-down"></i>
                        </a>
                    </div>
            }

            return accordion
        } catch (error) {
            console.error(error);
            return <div class="col-lg-4 card-headerT ">
                <span class="card-link ">{"-"}</span>
                <a data-bs-toggle="collapse" href={"#collapse" + this.props.id}>
                    <i class="iconRight fa fa-eye"></i> </a>
            </div>
        }
    }

    /**
     * 
     */
    updateMonitoraggio() {

        let dataModel = {
            "AssessmentMonitoraggioId": this.props.assessment.AssessmentMonitoraggioId,
            //"Stato": parseInt(this.props.assessment.Stato, 10),
            "Stato": this.state.assessmentMonitoraggioState,
            "Descrizione": this.state.textareaValue
        }

        try {
            if (this.state.textareaValue.length !== 0) {
                this.serverRequest =
                    $.ajax({
                        type: 'POST',
                        url: API_CONFIG.updateMonitoraggio,
                        contentType: 'application/json',
                        headers: { 'token': sessionStorage.getItem('token') },
                        data: JSON.stringify(dataModel),
                        cache: false,
                        success: function (response) {
                            if (response.status === 'success') {
                                this.setState({ textareaValue: "" });
                                console.log('updateMonitoraggio : ', response.status);
                                this.props.getMonitoraggioByAssessmentId();
                                //alert(JSON.stringify(dataModel));
                                //location.reload();
                            }
                            //alert(response.status);
                        }.bind(this),

                        error: function (xhr, resp, text) {
                            let messageError = "Si è verificato un errore ";
                            console.error(xhr);
                            //Token JWT Invalid
                            if (xhr.responseJSON) {
                                if (xhr.responseJSON.data === -1) {
                                        sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                    location.replace('/Home');
                                } else {
                                    this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                    $('#btnError').get(0).click();
                                }
                            }
                            else {

                                this.setState({ messageError: messageError + "." });
                                $('#btnError').get(0).click();
                            }
                        }.bind(this),
                    });
            }
            else {
                this.props.setMessageWarning("Il campo 'Causale' non può essere vuoto. Inserire la causale prima di salvare la modifica");

            }
        } catch (error) {
            return console.error(error);
        }
    }


    /**
     * @param event
     */
    updateCausale(event) {
        this.setState({ textareaValue: event.target.value });
    }

    /**
     * 
     */
    updateDescriptionEvent(event) {
        this.setState({ descriptionEvent: event.target.value });
    }

    updateAssessmentMonitoraggioState(event) {
        this.setState({ assessmentMonitoraggioState: parseInt(event.target.value, 10) });
    }

    render() {
        //console.log("props", this.props)
        //let isReadOnly = this.isDeleteAction(this.props.assessment.AssessmentMonitoraggioEvento);
        let isReadOnly = (parseInt(this.props.assessment.Attiva, 10) === 0);

        let isBtnUpdateStateReadonly = !(this.state.assessmentMonitoraggioState !== "" &&
            this.state.assessmentMonitoraggioState != this.props.assessment.Stato &&
            this.state.textareaValue.length !== 0);

        let isBtnUpdateStateDisabled = (isReadOnly || isBtnUpdateStateReadonly) ? "btn btn-secondary btn-lg btn-block" : "btn btn-success btn-lg btn-block";

        let isBtnDisabled = (isReadOnly) ? "btn btn-secondary btn-lg btn-block" : "btn btn-success btn-lg btn-block";

        return (

            <div key={this.props.id}>
                <div className="row">
                    {this.getAccordion()}
                    <div className="col-lg-8 ">
                        <table width="100%" cellPadding="0" cellSpacing="0" className=" Tablejs-TableResponsive tablesaw tablesaw-stack" >
                            <tbody>
                                <tr>
                                    {this.createQuarterTables().eventSymbolTdList}
                                    <td></td>
                                </tr>
                                <tr>
                                    {this.createQuarterTables().quarterTdList}
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="lineaGrigiaT"></div>

                <div id={"collapse" + this.props.id} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row card-headerLeftSpazio">

                        <div className="col-lg-12 giustifica">
                            <p className="testoBlu"><strong>Azione</strong></p>
                            {this.props.assessment.Azione}
                        </div>
                        <div className="col-lg-12"><span className="lineaGrigiaT"></span></div>
                        <div className="col-lg-3 ">
                            <p className="testoBlu"><strong>Stato</strong></p>
                            <AssessmentMonitoraggioSelect
                                selected={this.props.assessment.Stato}
                                optionList={ASSESSMENT_STATE}
                                //updateAssessmentMonitoraggioState={(e) => this.props.updateAssessmentMonitoraggioState(e.target.value, this.props.assessment)}
                                updateAssessmentMonitoraggioState={this.updateAssessmentMonitoraggioState}
                                readOnly={isReadOnly}
                            />
                        </div>
                        <div className="col-lg-6"><p className="testoBlu"><strong>Causale della modifica</strong></p>
                            <textarea
                                value={this.state.textareaValue}
                                onChange={this.updateCausale}
                                disabled={isReadOnly}
                                rows="1"
                                placeholder="Inserire Causale"
                            />

                        </div>
                        <div className="col-lg-3"><br />
                            <button
                                type="button"
                                className={isBtnUpdateStateDisabled}
                                disabled={isReadOnly || isBtnUpdateStateReadonly}
                                onClick={this.updateMonitoraggio}>
                                MODIFICA STATO</button>
                        </div>
                        <div className="col-lg-12"><span className="lineaGrigiaT"></span></div>
                        <div className="col-lg-12">
                            <p className="testoBlu"><strong>Aggiungi Nota/Evento</strong></p>
                        </div>

                        <div className="col-lg-10">
                            <textarea
                                value={this.state.descriptionEvent}
                                onChange={this.updateDescriptionEvent}
                                placeholder={"Inserire descrizione dell'evento"}
                                disabled={isReadOnly}
                                rows="1" />
                            <br /><br />
                        </div>

                        <div className="col-lg-2">
                            <button type="button"
                                onClick={this.insertAssessmentMonitoraggioEvento}
                                //className="btn btn-success btn-lg btn-block">
                                className={isBtnDisabled}
                                disabled={isReadOnly}
                            >AGGIUNGI</button>
                        </div>



                        <div className="col-lg-12"><span className="lineaGrigiaT"></span></div>

                        <div className="col-lg-12 ">
                            <p className="testoBlu"><strong>Storico Note/Eventi</strong></p>
                        </div>

                        <div className="row card-headerLeft" style={{ width: "100%" }}>
                            {this.getEventRows(this.props.assessment.AssessmentMonitoraggioEvento)}

                            <div className="col-lg-12" style={{ textAlign: "center" }}>
                                <a data-bs-toggle="collapse" href={"#collapse" + this.props.id}>
                                    chiudi finestra  <i className=" fa fa-chevron-up"></i></a>
                            </div>

                        </div>

                        <div className="col-lg-12"><span className="lineaGrigiaT0"></span></div>

                    </div>

                    <div className="col-lg-12"><br /><hr /></div>
                </div>
            </div>
        )
    }
}