var Months = [];
Months[-1] = '';
Months[0] = 'Gennaio';
Months[1] = 'Febbraio';
Months[2] = 'Marzo';
Months[3] = 'Aprile';
Months[4] = 'Maggio';
Months[5] = 'Giungo';
Months[6] = 'Luglio';
Months[7] = 'Agosto';
Months[8] = 'Settembre';
Months[9] = 'Ottobre';
Months[10] = 'Novembre';
Months[11] = 'Dicembre';
module.exports = Months 