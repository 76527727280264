import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}


export class GestioneUtenti extends Component {



    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Gestione Utenti</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Gestione Utenti</span>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                            <p>Consente di abilitare gli utenti per poter accedere alla piattaforma Data Source & Regulation Assessment Tool, definendone l'accreditamento alle Pubbliche Amministrazioni ed i relativi ruoli tramite cui possono operare.
                                <br/>
                                L'operatore di back-office ha possibilità di: 
                                <br/>
                                - Censire una nuova utenza
                                <br/>
                                - Eliminare un'utenza censita
                                <br/>
                                - Accreditare un'utenza a una o più Pubbliche Amministrazioni
                                <br/>
                                - Assegnare i ruoli operativi ad un'utenza per ogni Pubblica Amministrazione accreditata
                                </p>
                            <hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/NuovoUtente')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">NUOVA UTENZA</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            <p>
                            Permette di censire una nuova utenza nella piattaforma.
                            </p>
                                <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/UtentiElenco')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ELENCO UTENZE</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            <p>
                            Visualizza la lista delle utenze censite nella piattaforma. Consente di eliminare o modificare un’utenza esistente.
                            </p>
                            <hr />
                            </div>

                        </div></div>



                    <br /><br /><br /><br /><br />

                </div>


            </div>
        )
    }
}