import Critical from '../../public/images/critico.png';
import High from '../../public/images/alto.png';
import Mid from '../../public/images/medio.png';
import Low from '../../public/images/basso.png';

var IconImpatti = [];
IconImpatti[''] = {};
IconImpatti[0] = {src:Low,label:'Basso'};
IconImpatti[1] = {src:Low,label:'Basso'};
IconImpatti[2] = {src:Mid,label:'Medio'};
IconImpatti[3] = {src:High,label:'Alto'};
IconImpatti[4] = {src:Critical,label:'Critico'};
export default IconImpatti;
