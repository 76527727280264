/**
 * Create a new user
 * @param {*} name 
 * @param {*} surname 
 * @param {*} fiscalCode 
 * @param {*} Azienda 
 * @param {*} isAdmin 
 */
export const User = function (fiscalCode = '',name = '', surname = '',  PA = [], isAdmin = false) {
    this.Nome = name;
    this.CodiceFiscale = fiscalCode;
    this.Cognome = surname;
    this.PA = PA;
    this.isAdmin = isAdmin;
    return this;
};