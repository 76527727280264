import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import ICON from '../data/IconLevel';
import history from '../history';
import LIVELLO from '../data/criticality';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';



function getRIDAccordion(r, i, d) {
    //console.log("impatto i", r);
    if (r < 1 || isNaN(r))
        return <p><strong>Impatto R-I-D:</strong><br /></p>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "testoVerde h2";
            nameR = "BASSO";
            break;
        case 2:
            classR = "testoArancio h2";
            nameR = "MEDIO";
            break;
        case 3:
            classR = "testoRosso h2";
            nameR = "ALTO";
            break;
        case 4:
            classR = "testoNero h2";
            nameR = "CRITICO";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "testoVerde h2";
            nameI = "BASSO";
            break;
        case 2:
            classI = "testoArancio h2";
            nameI = "MEDIO";
            break;
        case 3:
            classI = "testoRosso h2";
            nameI = "ALTO";
            break;
        case 4:
            classI = "testoNero h2";
            nameI = "CRITICO";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "testoVerde h2";
            nameD = "BASSO";
            break;
        case 2:
            classD = "testoArancio h2";
            nameD = "MEDIO";
            break;
        case 3:
            classD = "testoRosso h2";
            nameD = "ALTO";
            break;
        case 4:
            classD = "testoNero h2";
            nameD = "CRITICO";
            break;
        default:
            break;
    }

    return (
        <p>
            <strong>Impatto R-I-D:</strong><br />
            Riservatezza: <img src={IconImpatti[r].src} width="20" height="20" alt="" />
            <span className={classR}>
                <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Integrità: <img src={IconImpatti[i].src} width="20" height="20" alt="" />
            <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Disponibilità: <img src={IconImpatti[d].src} width="20" height="20" alt="" />
            <span className={classD}>    <strong> {nameD}</strong></span>
        </p>
    );
}

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}


// component that renders a single caratteristica row
export class ElencoGAPRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
        this.impattoToString = this.impattoToString.bind(this);
        this.getImpattoClassName = this.getImpattoClassName.bind(this);
        this.getMaxRischioAttualeMinaccie = this.getMaxRischioAttualeMinaccie.bind(this);
        this.getRischioAggregato = this.getRischioAggregato.bind(this);
    }

    /**
   * 
   */
    getMaxRischioAttualeMinaccie() {
        //console.log('rischioAttuale', this.props.remedy.RischioAttuale);
        try {
            let risk = this.props.remedy.RischioAggregato === null ? '' : LIVELLO[this.props.remedy.RischioAggregato].name;
            return risk;
        } catch (error) {
            //console.error(error);
            return '';
        }
    }

    /**
     * 
     */
    getRischioAggregato() {
        let risk = "";
        let className = "";
        try {
            let rischioAggregato = this.props.remedy.RischioAggregato;
            //risk =  LIVELLO[(this.props.remedy.RischioAggregato) ? this.props.remedy.RischioAggregato : ''].name;
            let iconSrc = IconImpatti[this.props.remedy.RischioAggregato].src;
            risk = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[(this.props.remedy.RischioAggregato)].name : "";
            iconSrc = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[(this.props.remedy.RischioAggregato)].src : "";

            switch (this.props.remedy.RischioAggregato) {
                case 1:
                    className = "testoVerde h2";
                    break;
                case 2:
                    className = "testoArancio h2";
                    break;
                case 3:
                    className = "testoRosso h2";
                    break;
                default:
                    className = 'testoNero h2';
                    break;
            }
            return <div><img src={iconSrc} width="20" height="20" alt="" />
                <span className={className}><strong> {risk}</strong></span></div>;
        } catch (error) {
            console.error(error);
            return <div className='testoNero h2'><strong></strong></div>;
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    impattoToString(indexOfImpact) {
        try {
            let index = parseInt(indexOfImpact, 10);
            return IconImpatti[index].label;

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    getImpattoClassName(indexOfImpact) {
        let className = '';
        //debugger
        try {
            let index = parseInt(indexOfImpact, 10);
            switch (index) {
                case 1:
                    className = 'testoVerde';
                    break;
                case 2:
                    className = 'testoArancio';
                    break;
                case 3:
                    className = 'testoRosso';
                    break;
                case 4:
                    className = 'testoNero';
                    break;
                default:
                    className = '';
                    break;
            }
            return className;
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }    /**
     * 
     * @param {*} rows 
     */
    addLineaGrigiaT(rows) {
        try {

            let listRowsNew = [];
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                listRowsNew.push(element);
                listRowsNew.push(<div className='lineaGrigiaT'></div>);
            }
            return listRowsNew;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    redirectReport() {
        window.sessionStorage.setItem("servizioId", window.sessionStorage.getItem("serviceId")); 
        window.sessionStorage.setItem("riepilogoAssessmentId", this.props.remedy.AssessmentId); 
        history.push('RiepilogoDatiApplicazione');
    }

    redirect(path) {
        try {
            window.sessionStorage.setItem("dettaglioAssessmentId", this.props.remedy.AssessmentId);
            //window.sessionStorage.setItem("serviceName", this.props.remedy.Nome);
            //window.sessionStorage.setItem("serviceCriticità", this.props.service.Criticità);
            //window.sessionStorage.setItem("serviceImpattoR", this.props.service.ImpattoR);
            //console.log('service', this.props.remedy.serviceId);
            //debugger
            if (path !== undefined) {
                if (path.indexOf('/') === 0)
                    history.push(path);
                else if (path.indexOf('/') === -1) {
                    history.push('/' + path);
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }
    

    render() {
        let path = "GapAnalysisVisualizzaDettaglio";
        return (
            <div>
                <div className="row">
                    {/* 1a colonna Creato*/}
                    <div className="col-lg-3 card-header">
                        {this.toStringDate(this.props.remedy.DataCreazione)}
                    </div>
                     {/* 1a colonna Creato*/}
                     <div className="col-lg-3 card-header">
                        {this.toStringDate(this.props.remedy.DataPubblicazione)}
                    </div>
                    {/* 2a colonna Stato*/}
                    <div className={"col-lg-3 card-header"}>
                        {this.props.remedy.RischioAggregato} %
                    </div>
                    {/* 3a colonna Azioni*/}
                    <div className="col-lg-3 card-header">
                        <a href="javascript:void(0);" onClick={() => this.redirect(path)}>
                        <i className="fa fa-eye" title="VEDI QUESTIONARIO"></i></a>
                        &nbsp;
                        <a href="javascript:void(0);" onClick={() => this.redirectReport()}>
                        <i className="fa fa-pie-chart" title="VEDI RISULTATI"></i></a>
                    </div>


                </div>
                <hr />                
            </div>
        )
    }
}