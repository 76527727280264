import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { TemaRow } from './TemaRow';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import infoIcon from '../../public/images/info.png';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

const DESTINATARI = [
    { Nome: "Cittadino/Impresa", id: "EXT", Descrizione: "In questa opzione (censita su servizi.gov.it) ricadono anche i servizi alle imprese" },
    { Nome: "Applicazione interna", id: "INT", Descrizione: "Servizi erogati internamente alla Azienda (e.g. servizio di erogazione delle buste paga ai dipendenti della PA)" },
    { Nome: "Altre PA", id: "PA2PA" }
];

const INTERAZIONI = [
    { Nome: "Non online", idlevel: "NONE", Descrizione: "Servizio erogato offline, per il quale non esiste nemmeno una pagina web informativa." },
    { Nome: "Informazioni", idlevel: "LEVEL 1", Descrizione: "Sono fornite all'utente informazioni sul procedimento amministrativo (es. finalità, termini di richiesta, ecc.) ed eventualmente sulle modalità di espletamento (es. sedi, orari di sportello)." },
    { Nome: "Interazione a una via", idlevel: "LEVEL 2", Descrizione: "Oltre alle informazioni, sono resi disponibili all'utente i moduli per la richiesta dell'atto/procedimento amministrativo di interesse che dovrà poi essere inoltrata attraverso canali tradizionali (es. modulo di variazione residenza o moduli di autocertificazione)." },
    { Nome: "Interazione a due vie", idlevel: "LEVEL 3", Descrizione: "L'utente può avviare l'atto/procedimento amministrativo di interesse (es. il modulo può essere compilato e inviato on line) e viene garantita on line solo la presa in carico dei dati immessi dall'utente e non la loro contestuale elaborazione." },
    { Nome: "Transazione", idlevel: "LEVEL 4", Descrizione: "L'utente può avviare l'atto/procedimento amministrativo di interesse fornendo i dati necessari ed eseguire la transazione corrispondente interamente on line, incluso l'eventuale pagamento dei costi previsti." },
    { Nome: "Personalizzazione", idlevel: "LEVEL 5", Descrizione: "L'utente, oltre ad eseguire on line l'intero ciclo del procedimento amministrativo di interesse riceve informazioni (sono ricordate le scadenze, è restituito l'esito del procedimento, ecc.), che gli sono inviate preventivamente, sulla base del profilo collegato (c.d. pro-attività)." },
];

function splitIntoSubArray(arr, count) {
    var newArray = [];
    while (arr.length > 0) {
        newArray.push(arr.splice(0, count));
    }
    return newArray;
}

const LINK = [
    { nome: "Censimento delle Applicazioni", url: "/CensimentoApplicazioni" },
    { nome: "Elenco Applicazioni", url: "/ListaApplicazioniCensimento" },
    { nome: "Modifica Applicazione", url: "/CensimentoApplicazioneModifica" }
];

/**
 * 
 */
export class CensimentoServizioModifica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serviceId: "",
            userId: "",
            codiceIpa: "",
            serviceName: "",
            nomedelservizio: "",
            descrizioneServizio: "",
            serviceType: [],//serviceType: "",
            outputProdotti: true,
            output: [],
            coperturaTemporale: true,
            temporalCoverage: [],
            temaCheck: [],
            settoreservizio_1: [""],
            settoreservizio_2: [""],
            paroleChiaveClassNclass: [],
            organizations: [],
            contacts: [],
            radioOptionLivIner: "",
            listaInterazioni: INTERAZIONI,
            canaliErogazione: true,
            channels: ['canale1', 'canale2', 'canale3'],
            //channels: {
            //"offlineChannels": [{
            offlineChannelsType: "",
            offlineChannelsSubType: "",
            //"locationName": {
            locationNameLanguage: "",
            locationNameDescr: "",
            //},
            streetType: "",
            streetName: "",
            number: "",
            city: "",
            postCode: "",
            //}],
            //"webApplications": [{
            webApplicationsType: "",
            url: "",
            //}],
            //"phones": [{
            phonesPype: "",
            phoneNumber: "",
            //}],
            //"emails": [{
            emailsType: "",
            email: "",
            //}],
            //otherElectronicChannels": [{
            otherChannelstype: "",
            otherChannelsSubtype: "",
            accessReferenceLanguage: "",
            accessReferenceDescr: "",
            checkAuth: [],
            tempoProcessamento: "",
            errors: [],
            sector: '',
            subsector: '',
            currentSubSectorList: [],
            channel: '',
            rowTableSectors: [],
            rowTableChannels: [],
            users: [],
            listaSettori: [],
            listaSottoSettori: [],
            listaSottoSettoriSelezionati: [],
            listaCanaliErogazione: [],
            listaCategorieCanali: [],
            messageError: '',
            messageWarning: '',
            referente: '',
            struttura: '',
            criticità: '',
            updateDate: false,
            dataAggiornamento: '',
            dataPubblicazione: ''
        };

        this.getIndexOfKey = this.getIndexOfKey.bind(this);

        this.addRowTableChannels = this.addRowTableChannels.bind(this);
        this.addRowTableSectors = this.addRowTableSectors.bind(this);
        //automatic Saving
        this.automaticSavingNewService = this.automaticSavingNewService.bind(this);
        //
        this.checkCriticità = this.checkCriticità.bind(this);
        //VALIDATE
        this.canBeSubmitted = this.canBeSubmitted.bind(this);
        //GET
        this.getAllAuthentications = this.getAllAuthentications.bind(this);
        this.getAllAuthRows = this.getAllAuthRows.bind(this);
        this.getAllChannels = this.getAllChannels.bind(this);
        this.getAllSectors = this.getAllSectors.bind(this);
        this.getCategorieCanali = this.getCategorieCanali.bind(this);
        this.getChannels = this.getChannels.bind(this);
        this.getTemaList = this.getTemaList.bind(this);
        this.getSettore1List = this.getSettore1List.bind(this);
        this.getSettore2List = this.getSettore2List.bind(this);
        this.getAuthList = this.getAuthList.bind(this);
        this.getSubAccordionElementList = this.getSubAccordionElementList.bind(this);
        //REMOVE
        this.removeRowTableSectors = this.removeRowTableSectors.bind(this);
        this.removeRowTableChannels = this.removeRowTableChannels.bind(this);
        //PUBBLICA
        this.save = this.save.bind(this);
        //UPDATE
        this.updateAuth = this.updateAuth.bind(this);
        this.updateServiceName = this.updateServiceName.bind(this);
        this.updateDescriptionService = this.updateDescriptionService.bind(this);
        this.updateTema = this.updateTema.bind(this);
        this.updateChannel = this.updateChannel.bind(this);
        this.updateSector = this.updateSector.bind(this);
        this.updateSottoSettore = this.updateSottoSettore.bind(this);
        this.updateSubsector = this.updateSubsector.bind(this);
        this.updateLevel = this.updateLevel.bind(this);
        this.updateServiceType = this.updateServiceType.bind(this);
        this.updateReferente = this.updateReferente.bind(this);
        this.updateStruttura = this.updateStruttura.bind(this);
        this.isCheckInfo = this.isCheckInfo.bind(this);
        this.isUpdated = this.isUpdated.bind(this);

    }

    componentDidMount() {
        var Autenticazione = [
            {
                lv0id: "NONE", description: "Nessuna",
                lv1child: [{ lv1id: "NONE", description: "Accesso libero", lv0id: "NONE", value: false }]
            },
            {
                lv0id: "SFA", description: "Autenticazione singolo fattore",
                lv1child: [{ lv1id: "IDPWD", description: "User ID e password", lv0id: "SFA", value: false },
                { lv1id: "SPIDL1", description: "Credenziale SPID Livello 1", lv0id: "SFA", value: false }]
            },

            {
                lv0id: "2FA", description: "Autenticazione a due fattori",
                lv1child: [{ lv1id: "SPIDL2", description: "SPID Livello 2", lv0id: "2FA", value: false },
                { lv1id: "ID OTP", description: "User ID e One Time Password", lv0id: "2FA", value: false }]
            },
            {
                lv0id: "2FAHW", description: "Autenticazione a due fattori hardware",
                lv1child: [{ lv1id: "CNS", description: "Carta nazionale dei servizi (CNS)", lv0id: "2FAHW", value: false },
                { lv1id: "SPIDL3", description: "SPID Livello 3", lv0id: "2FAHW", value: false },
                { lv1id: "CIE", description: "Carta d'identità  elettronica (CIE)", lv0id: "2FAHW", value: false }]
            },
            {
                lv0id: "MFA", description: "Autenticazione multi-fattore",
                lv1child: [{ lv1id: "USERID OTP BIO", description: "User ID, One Time Password e Biometria", lv0id: "MFA", value: false }]
            }];

        this.setState({ checkAuth: Autenticazione });
        this.setState({ serviceId: sessionStorage.getItem('serviceId') });
        let serviceId = sessionStorage.getItem('serviceId');
        //this.setState({ serviceId: 'd417433611ed401699d02712' });
        // submit form data to api
        if (serviceId) {
            // submit form data to api
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.servizicompositeByIdUrl,
                    type: 'POST',
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: "{ \"ServizioId\": \"" + serviceId + "\"}",
                    cache: false,
                    success: function (response) {
                        console.log('servizicompositeByIdUrl', response);
                        let jsonData = JSON.parse(response.data[0].JSONCreazione)
                        //debugger
                        if (response.data[0].JSONCreazione) {
                            this.setState({ listaSottoSettoriSelezionati: jsonData.settoreservizio_2 });
                        }
                        this.setService(response.data[0]);
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } else {
            this.setState({ messageError: 'Id del servzio non valido' });
            $('#btnError').get(0).click();
        }
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getnuovoservizioinfo,
                type: 'GET',
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    this.setState({ temaCheck: splitIntoSubArray(response.data.Categorie, 7) });
                    this.setState({ listaSettori: response.data.SottoCategorie });
                    this.setState({ listaSottoSettori: response.data.SottoCategorie2 });
                    this.setState({ listaCanaliErogazione: response.data.CanaliDiErogazione });
                    this.setState({ listaCategorieCanali: this.getCategorieCanali(response.data.CanaliDiErogazione) });

                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });

    }

    isUpdated() {
        try {
            let tsDataAggiornamento = new Date(this.state.dataAggiornamento).getTime();
            let tsDataPubblicazione = new Date(this.state.dataPubblicazione).getTime();

            return tsDataAggiornamento > tsDataPubblicazione;
        } catch (error) {
            return false;
        }
    }
    /**
     * 
     */
    setService(data) {
        try {
            let jsonData = JSON.parse(data.JSONCreazione)
            //console.log('jsonData', jsonData);
            this.setState({ serviceId: sessionStorage.getItem('serviceId') });
            this.setState({ serviceName: data.Nome });
            this.setState({ nomedelservizio: data.Nome });
            this.setState({ descrizioneServizio: data.Descrizione });
            this.setState({ referente: data.Referente });
            this.setState({ struttura: data.StrutturaReferente });
            this.setState({ criticità: data.Criticità });
            this.setState({ dataAggiornamento: data.DataAggiornamento });
            this.setState({ dataPubblicazione: data.DataPubblicazione });

            if (data.JSONCreazione) {

                this.setState({ userId: jsonData.userId ? jsonData.userId : '' });
                this.setState({ codiceIpa: jsonData.codiceIpa ? jsonData.codiceIpa : '' });
                this.setState({ templateRef: jsonData.templateRef ? jsonData.templateRef : '' });

                this.setState({ urlservizio: jsonData.urlservizio ? jsonData.urlservizio : '' });
                this.setState({ inputRichiesti: jsonData.inputRichiesti ? jsonData.inputRichiesti : '' });
                this.setState({ serviceType: jsonData.serviceType ? jsonData.serviceType : '' });
                this.setState({ input: jsonData.input ? jsonData.input : '' });
                this.setState({ outputProdotti: jsonData.outputProdotti ? jsonData.outputProdotti : '' });
                this.setState({ coperturaTemporale: jsonData.coperturaTemporale ? jsonData.coperturaTemporale : '' });
                this.setState({ temporalCoverage: jsonData.temporalCoverage ? jsonData.temporalCoverage : '' });
                this.setState({ coperturaGeografica: jsonData.coperturaGeografica ? jsonData.coperturaGeografica : '' });
                this.setState({ inputCoperturaGeog: jsonData.inputCoperturaGeog ? jsonData.inputCoperturaGeog : '' });

                this.setTemaList(jsonData.temaCheck);
                this.setSettore2List(jsonData.settoreservizio_2);
                this.setState({ settoreservizio_3: jsonData.settoreservizio_3 ? jsonData.settoreservizio_3 : [] });
                this.setState({ settoreservizio_4: jsonData.settoreservizio_4 ? jsonData.settoreservizio_4 : [] });
                this.setState({ paroleChiaveClassNclass: jsonData.paroleChiaveClassNclass });
                this.setState({ organizations: jsonData.organizations });
                this.setState({ contacts: jsonData.contacts });
                this.setState({ radioOptionLivIner: jsonData.radioOptionLivIner });
                this.setState({ canaliErogazione: jsonData.canaliErogazione });

                this.setChannels(jsonData.channels);
                this.setAuthList(jsonData.checkAuth);
                this.setState({ costo: jsonData.costo });
                this.setState({ tempoProcessamento: jsonData.tempoProcessamento });
            }
            return //console.log('this.state', this.state);
        } catch (error) {
            console.error(error);
            return;
        }
    }

    redirect() {
        let path = "/ListaApplicazioniCensimento";
        try {
            if (path !== undefined) {
                if (path.indexOf('/') === 0)
                    history.push(path);
                else if (path.indexOf('/') === -1) {
                    history.push('/' + path);
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }

    setTemaList(temaList) {

        let temaState = this.state.temaCheck;
        try {
            for (let x = 0; x < temaState.length; x++) {
                for (let y = 0; y < temaState[x].length; y++) {

                    for (var i = 0; i < temaList.length; i++) {
                        if (temaList[i] === temaState[x][y].id) {
                            temaState[x][y].value = true;
                            //debugger
                        }
                    }
                }
            }

            this.setState({ temaCheck: temaState });

        } catch (error) {
            return console.error(error);
        }
    }

    setSettore2List(settore2ListSelected) {

        var sectors2List = this.state.listaSottoSettori;

        for (let x = 0; x < sectors2List.length; x++) {
            for (var i = 0; i < settore2ListSelected.length; i++) {
                if (settore2ListSelected[i] === sectors2List[x].id) {
                    sectors2List[x]['value'] = true;
                }
            }
        }
        this.setState({ listaSottoSettori: sectors2List });
    }

    setChannels(channelList) {

        var listaCanaliErogazione = this.state.listaCanaliErogazione;

        for (let x = 0; x < listaCanaliErogazione.length; x++) {
            for (var i = 0; i < channelList.length; i++) {
                if (parseInt(channelList[i], 10) === listaCanaliErogazione[x].CaratteristicaId) {
                    listaCanaliErogazione[x].value = true;
                }
            }
        }

        this.setState({ listaCanaliErogazione: listaCanaliErogazione });
    }

    /**
     * 
     */
    setAuthList(authList) {
        console.log("auth", authList);
        let checkAuth = this.state.checkAuth;

        for (let x = 0; x < checkAuth.length; x++) {
            for (let y = 0; y < checkAuth[x].lv1child.length; y++) {
                for (let i = 0; i < authList.length; i++) {
                    if (authList[i] === checkAuth[x].lv1child[y].lv1id) {
                        checkAuth[x].lv1child[y].value = true;
                    }
                    else
                        checkAuth[x].lv1child[y].value = false;
                }
            }
        }
        this.setState({ checkAuth: checkAuth });
    }


    /**
     * retunr a index of array where 'key' is equal to prop (for example prop == 'codiceIPA' => index=0)
     */
    getIndexOfKey(prop) {
        let i = -1;
        try {
            let found = this.state.INFO.some((row, index) => {
                i = index;
                return row.key.localeCompare(prop) === 0
            });

            if (found) {
                return i;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    updateServiceName(event) {
        var newValue = event.target.value;
        this.setState({ serviceName: newValue })
    }

    updateDescriptionService(event) {
        var newValue = event.target.value;
        this.setState({ descrizioneServizio: newValue });
    }

    updateLevel(event) {
        var newValue = event.target.value;
        this.setState({ radioOptionLivIner: newValue }, () => this.automaticSavingNewService());
    }

    /**
     * 
     * @param {*} event 
     */
    updateServiceType(event) {
        var id = event.target.value;
        let checked = event.target.checked;
        let serviceTypeList = this.state.serviceType;
        if (checked) {
            serviceTypeList.push(id);
        } else {
            let findIndex = serviceTypeList.findIndex(item => item.toUpperCase() === id.toUpperCase());

            serviceTypeList.splice(findIndex, 1);
        }
        this.setState({ serviceType: serviceTypeList }, () => this.automaticSavingNewService());
    }

    /**
     * 
     * @param {*} sottoSettore 
     * @param {*} newValue 
     */
    updateSottoSettore(sottoSettore, newValue) {

        var lista = this.state.listaSottoSettori;
        let findIndex = lista.findIndex(s => s.id === sottoSettore.id);
        if (findIndex !== -1) {
            lista[findIndex].value = newValue;
            this.setState({ listaSottoSettori: lista }, () => this.automaticSavingNewService());
        } else {
            /*
            
            for (let x = 0; x < lista.length; x++) {
                if (lista[x].id === sottoSettore.id)
                lista[x].value = !lista[x].value;
            }
            this.setState({ listaSottoSettori: lista }, () => this.automaticSavingNewService());
       */
        }
    }

    /**
     * 
     * @param {*} rowId 
     * @param {*} colId 
     */
    updateTema(rowId, colId) {
        var tema = this.state.temaCheck;
        tema[rowId][colId].value = !tema[rowId][colId].value;
        this.setState({ temaCheck: tema }, () => this.automaticSavingNewService());
    }

    /**
     * 
     * @param {*} id 
     */
    updateAuth(id) {
        var auth = this.state.checkAuth;
        this.setState({ updateDate: true });

        for (let x = 0; x < auth.length; x++) {
            for (let y = 0; y < auth[x].lv1child.length; y++) {
                if (auth[x].lv1child[y].lv1id === id)
                    auth[x].lv1child[y].value = !auth[x].lv1child[y].value
            }
        }
        this.setState({ checkAuth: auth }, () => this.automaticSavingNewService());
        //console.log(this.state.updateDate);
    }

    getTemaList() {
        var temaList = [];
        var temaState = this.state.temaCheck;

        for (let x = 0; x < temaState.length; x++) {
            for (let y = 0; y < temaState[x].length; y++) {
                if (temaState[x][y].value)
                    temaList.push(temaState[x][y].id);
            }
        }

        return temaList;
    }

    getAuthList() {
        var authList = [];
        var checkAuth = this.state.checkAuth;

        for (let x = 0; x < checkAuth.length; x++) {
            for (let y = 0; y < checkAuth[x].lv1child.length; y++) {
                if (checkAuth[x].lv1child[y].value)
                    authList.push(checkAuth[x].lv1child[y].lv1id);
            }
        }

        return authList;
    }

    getSettore1List() {
        var list = [];
        var index;
        var sectorsList = this.state.listaSottoSettori;

        for (let x = 0; x < sectorsList.length; x++) {
            if (sectorsList[x].value) {
                index = list.findIndex(item => item === sectorsList[x].idParent);
                if (index === -1) {
                    list.push(sectorsList[x].idParent);
                }
            }
        }
        return list;
    }

    getSettore2List() {
        var list = [];
        var sectorsList = this.state.listaSottoSettori;

        for (let x = 0; x < sectorsList.length; x++) {
            if (sectorsList[x].value)
                list.push(sectorsList[x].id);
        }
        return list;
    }

    updateSector(event) {

        var o = JSON.parse(event.target.value);

        this.setState({ sector: o });

        var subList = [];
        console.log("listaSottoSettori", this.state.listaSottoSettori);
        for (let y = 0; y < this.state.listaSottoSettori.length; y++) {
            if (this.state.listaSottoSettori[y].idParent === o.id)
                subList.push(this.state.listaSottoSettori[y]);
        }
        console.log("sub", subList);
        this.setState({ currentSubSectorList: subList });

        this.setState({ subsector: { nome: subList[0].Nome, id: subList[0].id } });
    }

    updateSubsector(event) {
        var o = JSON.parse(event.target.value);
        this.setState({ subsector: o }, () => this.automaticSavingNewService());
    }

    updateChannel(id) {
        let listaCanaliErogazione = this.state.listaCanaliErogazione;
        this.setState({ updateDate: true });
        for (let x = 0; x < listaCanaliErogazione.length; x++) {
            if (listaCanaliErogazione[x].CaratteristicaId === id) {
                listaCanaliErogazione[x].value = !listaCanaliErogazione[x].value
                //console.log('listaCanaliErogazione['+x+'].value',listaCanaliErogazione[x].value);
                //debugger
            }
        }
        this.setState({ listaCanaliErogazione: listaCanaliErogazione }, () => this.automaticSavingNewService());
    }

    getChannels() {
        var channelList = [];
        var listaCanaliErogazione = this.state.listaCanaliErogazione;

        for (let x = 0; x < listaCanaliErogazione.length; x++) {
            if (listaCanaliErogazione[x].value) {
                channelList.push(listaCanaliErogazione[x].CaratteristicaId);
            }
        }

        return channelList;
    }

    /**
     * aggiunge una riga alla tabella dei settori
     */
    addRowTableSectors() {
        var rows = this.state.rowTableSectors;
        var found = false;
        for (let y = 0; y < rows.length; y++) {
            if (rows[y].sector.id === this.state.sector.id && rows[y].subsector.id === this.state.subsector.id) {
                found = true;
                break;
            }
        }
        if (!found) {
            rows.push({ sector: this.state.sector, subsector: this.state.subsector });
            this.setState({ rowTableSectors: rows });

        }
    }

    getCategorieCanali(canali) {
        var listaCategorie = [];
        for (let index = 0; index < canali.length; index++) {
            let el = canali[index].Descrizione;
            if (!listaCategorie.includes(el)) listaCategorie.push(el);
        }

        return listaCategorie;
    }

    /**
    * rimovi la riga rowId-esima alla tabella dei settori
    */
    removeRowTableSectors(rowId) {
        var rows = this.state.rowTableSectors;
        rows.splice(rowId, 1);
        this.setState({ rowTableSectors: rows });
    }

    /**
     * 
     */
    addRowTableChannels() {
        var rows = this.state.rowTableChannels;

        var found = false;
        for (let y = 0; y < rows.length; y++) {
            if (rows[y].channel.id === this.state.channel.id) {
                found = true;
                break;
            }
        }
        if (!found) {
            rows.push({ channel: this.state.channel });
            this.setState({ rowTableChannels: rows });
        }
    }

    /**
    * rimovi la riga rowId-esima alla tabella dei settori
    */
    removeRowTableChannels(rowId) {
        var rows = this.state.rowTableChannels;
        rows.splice(rowId, 1);
        this.setState({ rowTableChannels: rows });
    }

    updateReferente(event) {
        let value = event.target.value;
        this.setState({ referente: value });
    }

    /**
     * 
     * @param {*} event 
     */
    updateStruttura(event) {
        let value = event.target.value;
        this.setState({ struttura: value });
    }

    canBeSubmitted() {
        var errors = [];

        if (this.state.serviceName.length === 0)
            errors.push("Nome dell'applicazione");

        if (this.state.descrizioneServizio.length === 0)
            errors.push("Descrizione");

        if (this.state.serviceType.length === 0)
            errors.push("Destinatari dell'applicazione");

        return errors;
    }

    /**
     * 
     * @param {*} event 
     */
    automaticSavingNewService() {
        // handle save button clicked        
        // data in the form

        var form_data = {

            Referente: this.state.referente,
            StrutturaReferente: this.state.struttura,
            UpdateDataContesto: Number(this.state.updateDate).toString(),
            JSONCreazione: {

                serviceId: this.state.serviceId,
                userId: this.state.userId,
                codiceIpa: this.state.codiceIpa,
                templateRef: "",
                serviceName: this.state.serviceName,
                nomedelservizio: this.state.serviceName,
                descrizioneServizio: this.state.descrizioneServizio,
                urlservizio: "",
                inputRichiesti: true,
                serviceType: this.state.serviceType,
                input: [],
                outputProdotti: this.state.outputProdotti,
                output: this.state.output,
                coperturaTemporale: this.state.coperturaTemporale,
                temporalCoverage: this.state.temporalCoverage,
                coperturaGeografica: true,
                inputCoperturaGeog: {
                    regione: [],
                    provincia: [],
                    comune: []
                },
                temaCheck: ["AGRI"],
                settoreservizio_1: ["A"],
                settoreservizio_2: ["01"],
                settoreservizio_3: ["-"],
                settoreservizio_4: ["-"],
                paroleChiaveClassNclass: [],
                organizations: [],
                contacts: [],
                radioOptionLivIner: "NONE",
                canaliErogazione: this.state.canaliErogazione,
                channels: ["109"],
                checkAuth: ["NONE"],
                costo: true,
                costoEuro: {
                    value: [],
                    description: []
                },
                tempoProcessamento: this.state.tempoProcessamento,
            }
        };

        let serviceId = this.state.serviceId;
        console.log('automaticSaving', JSON.stringify(form_data));
        //alert(JSON.stringify(form_data));
        //debugger
        let serviceName = this.state.serviceName;
        if (serviceName.length === 0) {
            this.setState({ messageWarning: 'Inserire il nome del servizio!' });
            $('#btnWarning').get(0).click();
            return;
        }
        if (serviceId.length === 0) {
            //add a new service console.log('add a new service');
            $.ajax({
                url: API_CONFIG.creazioneservizio,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(form_data),
                cache: false,
                success: function (response) {
                    this.setState({ serviceId: response.data });
                }.bind(this),

                error: function (xhr, resp, text) {
                    $('#btnError').get(0).click();
                    // show error to console
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    /* else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }*/

                    else if (xhr.responseJSON !== undefined && xhr.responseJSON.message.indexOf("exist for PA") > -1) {
                        console.error(xhr, resp, text);
                        //alert("E' già presente un servizio con il nome specificato.");
                        this.setState({ messageWarning: "E' già presente un servizio con il nome specificato." });
                        $('#btnWarning').get(0).click();
                    }
                    //alert(xhr.statusText + ' : ' + xhr.status);
                }.bind(this)
            });

        } else {
            console.log('update new service');
            //update the new service
            $.ajax({
                url: API_CONFIG.modificaServizio,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                //data : "{ \"serviceId\": "+ this.state.serviceId+"}",
                data: JSON.stringify(form_data),
                cache: false,
                success: function (response) {
                    if (response.status === "success") {
                        console.log('update service', response.status);
                    } else {
                        console.error(response);
                        /*
                        this.setState({ messageWarning : response});
                        $('#btnWarning').get(0).click();
                      */
                    }
                }//.bind(this),
                ,
                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                        /*
                        else {
                            
                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }*/
                    } else if (xhr.responseJSON !== undefined && xhr.responseJSON.message.indexOf("exist for PA") > -1) {
                        console.error(xhr, resp, text);
                        //alert("E' già presente un servizio con il nome specificato.");
                        this.setState({ messageWarning: "E' già presente un servizio con il nome specificato." });
                        $('#btnWarning').get(0).click();
                    }
                    //alert(xhr.statusText + ' : ' + xhr.status);
                }.bind(this),
            });
            //event.preventDefault();
        }
        return;
    }

    /**
     * 
     */
    checkCriticità(event) {
        //scatta all'onclick del bottone 'PUBBLICA SERVIZIO';
        //nel caso di esistenza del profilo di criticità mostra il modaldialog di waring
        let profiloCriticità = this.state.criticità;//stato criticità del servizio
        if (profiloCriticità > 0) {
            console.log('profilo di criticità esistente', event);
            let msg = "Proseguendo con l'operazione si dovrà procedere di nuovo al calcolo del Profilo di Criticità nella fase di analisi del contesto";
            this.setState({ messageWarning: msg });
            $('#btnCheck').get(0).click();
            //controlla lo stato di criticità del servizio se è > 0 
            //allora mostra il modaldialog di Warning

        } else {
            //altrimenti chiama save(event);
            console.log('profilo di criticità inesistente', event);
            this.save(event);
        }
    }

    /**
     * 
     */
    save(event) {
        // handle save button clicked        
        // data in the form

        this.setState({ errors: errors });

        var errors = this.canBeSubmitted();
        if (errors.length === 0) {

            $.ajax({
                //url: API_CONFIG.pacompositeInsertCensimentoPAUrl,
                url: API_CONFIG.pubblicaServizio,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: "{ \"ServizioId\": \"" + this.state.serviceId + "\"}",
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    if (response.status.localeCompare('success') === 0) {
                        this.setState({ messageSuccess: "Pubblicazione del nuovo servizio effettuata con successo!" });
                        $('#btnSuccessAction').get(0).click();
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    /*else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                    }
                   */
                    else if (xhr.responseJSON !== undefined && xhr.responseJSON.message.indexOf("exist for PA") > -1) {
                        console.error(xhr, resp, text);
                        //alert("E' già presente un servizio con il nome specificato.");
                        this.setState({ messageWarning: "E' già presente un servizio con il nome specificato." });
                        $('#btnWarning').get(0).click();
                    }
                    //alert(xhr.statusText + ' : ' + xhr.status);
                }.bind(this),
            });

            event.preventDefault();
        }
        else {
            //alert("Compilare le seguenti Sezioni: " + errors.join(", "));
            this.setState({ messageWarning: "Compilare le seguenti Sezioni: " + errors.join(", ") });
            $('#btnWarning').get(0).click();
        }
        return;
    }

    getAllAuthRows(lv1child) {
        let lv0id = this.state.checkAuth;
        let allRows = [];
        for (let index = 0; index < lv0id.length; index++) {
            //debugger
            if (lv0id[index].lv1child) {
                let element = <div key={lv0id[index].lv0id} className="col-lg-3 font14">{lv0id[index].description}:</div>
                allRows.push(element);
                allRows.push(lv1child[index]);
            }
        }
        return allRows;
    }

    getAllAuthentications() {
        var accordionList = [];
        for (let index = 0; index < this.state.checkAuth.length; index++) {
            accordionList.push(
                <div className="row" key={"accordionCategoriaAuth" + index}>
                    <div className="card-body">
                        <div className="col-lg-12">
                            <span><a className="testoNero" data-bs-toggle="collapse" href={"#accordionCategoriaAuth" + index}><strong>{this.state.checkAuth[index].description}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            {index !== (this.state.checkAuth.length - 1) && <hr />}
                        </div>
                    </div>
                </div>
            );
            //accordionList.push(<hr key={"hrCategoriaAuth" + index} />);

            let rightList = [];
            let leftList = [];

            for (let x = 0; x < this.state.checkAuth[index].lv1child.length; x++) {
                if ((x + 1) % 2 === 0) {
                    rightList.push(<div key={x + "auth" + index}>
                        <input
                            type="checkbox"
                            checked={this.state.checkAuth[index].lv1child[x].value}
                            onChange={(e) => this.updateAuth(this.state.checkAuth[index].lv1child[x].lv1id)}
                        /> &nbsp;{this.state.checkAuth[index].lv1child[x].description}
                        <hr />
                    </div>);
                }
                else {
                    leftList.push(<div key={x + "auth" + index}>
                        <input
                            type="checkbox"
                            checked={this.state.checkAuth[index].lv1child[x].value}
                            onChange={(e) => this.updateAuth(this.state.checkAuth[index].lv1child[x].lv1id)}
                        /> &nbsp;{this.state.checkAuth[index].lv1child[x].description}
                        <hr />
                    </div>);
                }
            }

            accordionList.push(
                <div key={"collapseAuth" + index} className="card-bodyPlus">
                    <div id={"accordionCategoriaAuth" + index} className="collapse spazio10" data-parent="#accordion">
                        <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return accordionList;
    }

    getAllChannels() {
        var accordionList = [];
        for (let index = 0; index < this.state.listaCategorieCanali.length; index++) {
            if (this.state.listaCategorieCanali[index].indexOf('Altro telematico ->') !== 0) {

                accordionList.push(
                    <div className="row" key={"accordionCategoriaCanale" + index}>
                        <div className="card-body">
                            <div className="col-lg-12">
                                <span><a className="testoNero" data-bs-toggle="collapse" href={"#accordionCategoriaCanale" + index}><strong>{this.state.listaCategorieCanali[index]}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                                {index !== (this.state.listaCategorieCanali.length - 1) && <hr />}
                            </div>

                        </div>
                    </div>
                );
            }
            //console.log('listaCategorieCanali', this.state.listaCategorieCanali);
            let listaCanaliErogazione = this.state.listaCanaliErogazione;
            let canaliCategoria = [];
            let subAccordion = [];
            let subAccordionPAGOPA = [];

            let rightList = [];
            let leftList = [];

            for (let x = 0; x < listaCanaliErogazione.length; x++) {
                if (listaCanaliErogazione[x].Descrizione === this.state.listaCategorieCanali[index])
                    canaliCategoria.push(listaCanaliErogazione[x]);
                if (listaCanaliErogazione[x].Descrizione.indexOf('Altro telematico -> Piattaforma PAGOPA') > -1)
                    subAccordionPAGOPA.push(listaCanaliErogazione[x]);
                if (listaCanaliErogazione[x].Descrizione.indexOf('Altro telematico -> Pagamenti bancari automatici') > -1)
                    subAccordion.push(listaCanaliErogazione[x]);
            }

            for (let x = 0; x < canaliCategoria.length; x++) {
                if ((x + 1) % 2 === 0) {
                    rightList.push(<div key={x + "canale" + index}>
                        <input type="checkbox"
                            checked={canaliCategoria[x].value}
                            onChange={(e) => this.updateChannel(parseInt(canaliCategoria[x].CaratteristicaId, 10))}
                        /> &nbsp;{canaliCategoria[x].Nome}
                        <hr />
                    </div>);
                }
                else {
                    leftList.push(<div key={x + "canale" + index}>
                        <input type="checkbox"
                            checked={canaliCategoria[x].value}
                            onChange={(e) => this.updateChannel(parseInt(canaliCategoria[x].CaratteristicaId, 10))}
                        /> &nbsp;{canaliCategoria[x].Nome}
                        <hr />
                    </div>);
                }
            }

            let subAccordinList = this.getSubAccordionElementList(subAccordion, index);
            let subAccordionPAGOPAList = this.getSubAccordionElementList(subAccordionPAGOPA, index);

            if (this.state.listaCategorieCanali[index].indexOf('Altro telematico') === 0) {
                accordionList.push(
                    <div key={"collapseCanale" + index} className="card-bodyPlus">
                        <div id={"accordionCategoriaCanale" + index} className="spazio10 collapse" data-parent="#accordion">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 marginRight">
                                            {leftList}
                                        </div>
                                        <div className="col-lg-6 marginRight">
                                            {rightList}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <span><a href={"#subAccordion" + index} className="testoNero" data-bs-toggle="collapse" ><strong>{'Pagamenti bancari automatici'}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                                    <div className="card-body">
                                        <div className="row collapse" id={"subAccordion" + index} data-parent="#accordion">
                                            <div className="col-lg-6 marginRight">
                                                {subAccordinList[0]}{/*left*/}
                                            </div>
                                            <div className="col-lg-6 marginRight">
                                                {subAccordinList[1]}{/*right*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <span><a href={'#subAccordionPagpPA' + index} className="testoNero" data-bs-toggle="collapse" ><strong>{'Piattaforma PAGOPA'}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                                    <div className="card-body">
                                        <div className="row collapse" id={"subAccordionPagpPA" + index} data-parent="#accordion">
                                            <div className="col-lg-6 marginRight">
                                                {subAccordionPAGOPAList[0]}{/*left*/}
                                            </div>
                                            <div className="col-lg-6 marginRight">
                                                {subAccordionPAGOPAList[1]}{/*right*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                accordionList.push(
                    <div key={"collapseCanale" + index} className="card-bodyPlus">
                        <div id={"accordionCategoriaCanale" + index} className="collapse spazio10" data-parent="#accordion">
                            <div className="row">
                                <div className="col-lg-6 marginRight">
                                    {leftList}
                                </div>
                                <div className="col-lg-6 marginRight">
                                    {rightList}
                                </div>
                            </div>
                        </div>
                    </div>);
            }
        }
        return accordionList;
    }

    /**
     * 
     * @param {*} subAccordion 
     */
    getSubAccordionElementList(subAccordion, index) {
        let subAccordionRight = [];
        let subAccordionLeft = [];
        for (let x = 0; x < subAccordion.length; x++) {
            if ((x + 1) % 2 === 0) {
                subAccordionRight.push(<div key={x + "canale" + index}>
                    <input type="checkbox"
                        checked={subAccordion[x].value}
                        onChange={(e) => this.updateChannel(parseInt(subAccordion[x].CaratteristicaId, 10))}
                    /> &nbsp;{subAccordion[x].Nome}
                    <hr />
                </div>);
            }
            else {
                subAccordionLeft.push(<div key={x + "canale" + index}>
                    <input type="checkbox"
                        checked={subAccordion[x].value}
                        onChange={(e) => this.updateChannel(parseInt(subAccordion[x].CaratteristicaId, 10))}
                    /> &nbsp;{subAccordion[x].Nome}
                    <hr />
                </div>);
            }
        }

        return [subAccordionLeft, subAccordionRight];
    }

    getAllSectors() {
        var accordionList = [];
        for (let index = 0; index < this.state.listaSettori.length; index++) {
            accordionList.push(
                <div key={"accordionSettore" + index} className="row">
                    <div className="card-body">
                        <div className="col-lg-12">
                            <span><a className="testoNero" data-bs-toggle="collapse" href={"#accordionSettore" + index}><strong>{this.state.listaSettori[index].Nome}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            {index !== (this.state.listaSettori.length - 1) && <hr />}
                        </div>

                    </div>
                </div>
            );
            //accordionList.push(<hr key={"hrSettore" + index} />);

            let listaSottoSettori = this.state.listaSottoSettori;
            let settori = [];
            let rightList = [];
            let leftList = [];

            for (let x = 0; x < listaSottoSettori.length; x++) {
                if (listaSottoSettori[x].idParent === this.state.listaSettori[index].id)
                    settori.push(listaSottoSettori[x]);
            }

            for (let x = 0; x < settori.length; x++) {
                if ((x + 1) % 2 === 0) {
                    rightList.push(<div key={x + "sottosettore" + index}>
                        <input type="checkbox"
                            //checked={settori[x].value}
                            value={settori[x].value}
                            onChange={(e) => this.updateSottoSettore(settori[x], e.target.checked)}
                        /> &nbsp;{settori[x].Nome}
                        <hr />
                    </div>);
                }
                else {
                    leftList.push(<div key={x + "sottosettore" + index}>
                        <input
                            //checked={settori[x].value}
                            value={settori[x].value}
                            type="checkbox"
                            onChange={(e) => this.updateSottoSettore(settori[x], e.target.checked)}
                        /> &nbsp;{settori[x].Nome}
                        <hr />
                    </div>);
                }
            }

            accordionList.push(
                <div key={"collapseSettore" + index} className="card-bodyPlus">
                    <div id={"accordionSettore" + index} className="collapse spazio10" data-parent="#accordion">
                        <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return accordionList;
    }

    /**
     * 
     * @param {*} array 
     */
    isCheckInfo() {
        try {
            if (this.state.serviceName.length > 0 && this.state.descrizioneServizio.length > 0)
                return 'visible';

            return 'hidden';
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    isCheck(list) {
        if (list.length === 0) {
            return 'hidden';
        } else {
            return 'visible';
        }
    }

    render() {
        let rightList = [];
        let leftList = [];
        for (let x = 0; x < DESTINATARI.length; x++) {
            //var checkedS = this.state.serviceType === DESTINATARI[x].id;
            let checkedS = (this.state.serviceType.findIndex(item => item.toUpperCase() === DESTINATARI[x].id.toUpperCase()) === -1) ? false : true;
            console.log(DESTINATARI[x].id, checkedS);
            if ((x + 1) % 2 === 0)
                rightList.push(
                    <div key={"destinatario" + x}>
                        <label>
                            <input type="checkbox" value={DESTINATARI[x].id} checked={checkedS} onChange={this.updateServiceType} />
                            {' ' + DESTINATARI[x].Nome}
                        </label>
                        <a data-bs-toggle="collapse"
                            href={"#collapseInfo" + DESTINATARI[x].id + "destinatario" + x}
                            hidden={!(DESTINATARI[x].Descrizione)}>
                            <span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span>
                        </a>
                        <div id={"collapseInfo" + DESTINATARI[x].id + "destinatario" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato" >Informazioni aggiuntive:</div>
                            <hr className="linea0066ccStat" />
                            {(DESTINATARI[x].Descrizione) ? DESTINATARI[x].Descrizione : ""}
                        </div>
                        <hr />
                    </div>
                );
            else {
                leftList.push(
                    <div key={"destinatario" + x}>
                        <label>
                            <input type="checkbox" value={DESTINATARI[x].id} checked={checkedS} onChange={this.updateServiceType} />
                            {' ' + DESTINATARI[x].Nome}
                        </label>
                        <a data-bs-toggle="collapse"
                            href={"#collapseInfo" + DESTINATARI[x].id + "destinatario" + x}
                            hidden={!(DESTINATARI[x].Descrizione)}>
                            <span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span>
                        </a>
                        <div id={"collapseInfo" + DESTINATARI[x].id + "destinatario" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato" >Informazioni aggiuntive:</div>
                            <hr className="linea0066ccStat" />
                            {(DESTINATARI[x].Descrizione) ? DESTINATARI[x].Descrizione : ""}
                        </div>
                        <hr />
                    </div>
                );
            }
        }

        let destinatariRow =
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6">
                        {leftList}
                    </div>
                    <div className="col-lg-6">
                        {rightList}
                    </div>
                </div>
            </div>;

        let rightListInterazioni = [];
        let leftListInterazioni = [];
        for (let x = 0; x < this.state.listaInterazioni.length; x++) {
            var checkedL = this.state.radioOptionLivIner === this.state.listaInterazioni[x].idlevel;
            let interazioniNome = this.state.listaInterazioni[x].Nome.toUpperCase();
            let index = INTERAZIONI.findIndex(function (obj) { return obj.Nome.toUpperCase().indexOf(interazioniNome) === 0; });
            if ((x + 1) % 2 === 0)
                rightListInterazioni.push(
                    <div key={"interazione" + x}>
                        <label>
                            <input type="radio" value={this.state.listaInterazioni[x].idlevel} checked={checkedL} onChange={this.updateLevel} />
                            {' ' + this.state.listaInterazioni[x].Nome}</label>
                        <a data-bs-toggle="collapse" href={"#collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x}><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                        <div id={"collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                            {INTERAZIONI[index].Descrizione}
                        </div>
                        <hr />
                    </div>
                );
            else {
                leftListInterazioni.push(
                    <div key={"interazione" + x}>
                        <label>
                            <input type="radio" value={this.state.listaInterazioni[x].idlevel} checked={checkedL} onChange={this.updateLevel} />
                            {' ' + this.state.listaInterazioni[x].Nome}</label>
                        <a data-bs-toggle="collapse" href={"#collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x}><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                        <div id={"collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                            {INTERAZIONI[index].Descrizione}
                        </div>
                        <hr />
                    </div>
                );
            }
        }

        let interazioniRow =
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6">
                        {leftListInterazioni}
                    </div>
                    <div className="col-lg-6">
                        {rightListInterazioni}
                    </div>
                </div>
            </div>;

        let temiRows = this.state.temaCheck.map(function (field, i) {
            return (
                <TemaRow
                    key={i}
                    rowId={i}
                    field={field}
                    length={field.length}
                    updateTema={this.updateTema}
                />
            );
        }.bind(this));

        let modalSuccess = <ModalDialog idTarget={'successAction'} idBtn={'btnSuccessAction'} handleOK={this.redirect} textModal={this.state.messageSuccess} />;
        let modalWarning = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnWarning'} textModal={this.state.messageWarning} />;
        let modalWarningAction = <ModalDialog idTarget={'warningAction'} idBtn={'btnCheck'} handle={this.save} textModal={this.state.messageWarning} />;
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />;

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
                <div className="container cnsContainer">


                    <div className="row">
                        <div className="col-lg-12">

                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                        <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/ListaApplicazioniCensimento')}>Elenco Applicazioni</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Modifica Applicazione</a></li>
                                    </ul>
                            </nav>
                            <span className="titoloPagina">Modifica Censimento Applicazione: {this.state.serviceName}</span>&nbsp;
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>I dati inseriti nella presente pagina sono richiesti per la creazione di una nuova applicazione.
                    </p>

                            <span className="paRight InfoAgg">* selezionare almeno una caratteristica&nbsp; &nbsp; </span>
                        </div>

                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse1">
                                    <i style={{ visibility: this.isCheckInfo() }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Informazioni generali<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div id="collapse1" className="col-lg-12 collapse" data-parent="#accordion">

                            <div className="row card-headerLight">

                                <div className="col-lg-12"><strong>Nome applicazione *</strong>
                                    <input className="form100"
                                        id={'nomeServizio'}
                                        onChange={this.updateServiceName}
                                        onBlur={this.automaticSavingNewService}
                                        value={this.state.serviceName}
                                        placeholder={'Nome servizio'}
                                        type="text" />
                                </div>
                                <div className="col-lg-6">
                                    <strong>Referente</strong><br />
                                    <input type="text" className="form100"
                                        onChange={this.updateReferente}
                                        value={this.state.referente}
                                        onBlur={this.automaticSavingNewService}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <strong>Struttura</strong><br />
                                    <input type="text" className="form100"
                                        onChange={this.updateStruttura}
                                        value={this.state.struttura}
                                        onBlur={this.automaticSavingNewService}
                                    />
                                </div>


                                <div className="col-lg-12"><strong>Descrizione *</strong>
                                    <textarea className="form100"
                                        onChange={this.updateDescriptionService}
                                        onBlur={this.automaticSavingNewService}
                                        value={this.state.descrizioneServizio}
                                        placeholder={'Descrizione'}
                                    />
                                </div>

                            </div>
                        </div>



                        {/* <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse2">
                                    <i style={{ visibility: this.isCheck(this.getTemaList()) }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Temi *<i className="iconRight fa fa-chevron-down"></i></a>

                            </div>
                        </div>
                        <div id="collapse2" className="collapse" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row">
                                    {temiRows}
                                </div>
                            </div>
                        </div> */}
                        {/* <hr />

                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse3">
                                    <i style={{ visibility: this.isCheck(this.getSettore2List()) }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Settori *<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>
                        <div id="collapse3" className="collapse" data-parent="#accordion">
                            {this.getAllSectors()}
                        </div> */}

                        {/* <hr />

                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse4">
                                    <i style={{ visibility: this.isCheck(this.getAuthList()) }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Autenticazione *<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>
                        <div id="collapse4" className="collapse" data-parent="#accordion">

                            {this.getAllAuthentications()}

                        </div> */}

                        {/* <hr /> */}

                        {/* <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse5">
                                    <i style={{ visibility: this.state.radioOptionLivIner === "" ? "hidden" : "visible" }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Livelli di interazione *<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>
                        <div id="collapse5" className="collapse" data-parent="#accordion">

                            {interazioniRow}

                        </div>



                        <hr /> */}

                        {/* <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse6">
                                    <i style={{ visibility: this.isCheck(this.getChannels()) }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Modalità di erogazione *<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>
                        <div id="collapse6" className="collapse" data-parent="#accordion">

                            {this.getAllChannels()}

                        </div>

                        <hr /> */}

                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-link" data-bs-toggle="collapse" href="#collapse7">
                                    <i style={{ visibility: this.state.serviceType.length === 0 ? "hidden" : "visible" }} className="testoVerde fa fa-check"></i>
                                    &nbsp;Destinatari dell'applicazione *<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>

                        </div>
                        <div id="collapse7" className="collapse" data-parent="#accordion">
                            {destinatariRow}
                        </div>
                        <hr />


                        <div className="row spazio20">

                            <div className="col-lg-4 ">	</div>
                            <div className="col-lg-4 ">
                                <button 
                                onClick={this.save} 
                                className="btn btn-success btn-lg btn-block">PUBBLICA APPLICAZIONE</button>
                            </div>
                            <div className="col-lg-4 ">	</div>
                        </div>
                        {modalWarning}
                        {modalError}
                        {modalWarningAction}
                        {modalSuccess}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div >
            </div >
        );
    }
}