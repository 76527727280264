var PageStyle = `
@page { size: auto;  margin: 15mm; } @media print { body { -webkit-print-color-adjust: exact; } tr { page-break-inside:avoid; page-break-after:auto } 

.gaugeImpatti {
    position: relative;
}        
.gauge__containerImpatti {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    overflow: hidden;
    text-align: center;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
}        
.gauge__backgroundImpatti {
    z-index: 0;
    position: absolute;
    background-color: #C5CAE9;
    top: 0;
    border-radius: 200px 200px 0 0;
}        
.gauge__dataImpatti {
    z-index: 1;
    position: absolute;
    background-color: #0066cc;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
       -moz-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
         -o-transform-origin: center bottom;
            transform-origin: center bottom;
}        
.gauge__centerImpatti {
    z-index: 2;
    position: absolute;
    background-color: #fff;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
}        
.gauge__markerImpatti {
    z-index: 3;
    background-color: #fff;
    position: absolute;
    width: 1px;
}        
.gauge__needleImpatti {
    z-index: 4;
    background-color: #333;
    height: 3px;
    position: absolute;
    -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
        -ms-transform-origin: left center;
         -o-transform-origin: left center;
            transform-origin: left center;
}        
.gauge__labelsImpatti {
    display: table;
    margin: 0 auto;
    position: relative;
}        
.gauge__label--lowImpatti {
    display: table-cell;
    text-align: center;
}        
.gauge__label--spacerImpatti {
    display: table-cell;
    text-align: center;
    font-size: 16px;
}       
.gauge__label--highImpatti {
    display: table-cell;
    text-align: center;
}        

.gaugeImpatti { height: calc(100px + 2em); }
.gauge__containerImpatti { width: 200px; height: 100px; }
.gauge__markerImpatti { height: 100px; left: 100px; background-color: #fff !important;}
.gauge__backgroundImpatti { width: 200px; height: 100px; background-color: #C5CAE9 !important;}
.gauge__centerImpatti { width: 120px; height: 60px; top: 40px; margin-left: 40px; background-color: #fff !important;}
.gauge__dataImpatti{ width: 200px; height: 100px; background-color: #0066cc !important;}
.gauge__needleImpatti { left: 100px; top: 100px; width: 100px; background-color: #333 !important;}
.gauge__labelsImpatti { top: 100px; width: 200px; }
.gauge__label--lowImpatti { width: 40px; font-size: 12px}
.gauge__label--spacerImpatti { width: 120px; }
.gauge__label--highImpatti { width: 40px; font-size: 12px}
.gauge__label--centerImpatti { width: 136px; font-size: 12px}

}

.gauge--liveupdateImpatti .gauge__dataImpatti,
.gauge--liveupdateImpatti .gauge__needleImpatti {
    -webkit-transition: none !important;;
     -moz-transition: none !important;;
      -ms-transition: none !important;;
      -o-transition: none !important;;
           transition: none !important;;
    }

.gauge__dataImpatti {
    -webkit-transform: rotate(-.50turn);
    -moz-transform: rotate(-.50turn);
        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}
.gauge__needleImpatti{
    -webkit-transform: rotate(-.50turn);
    -moz-transform: rotate(-.50turn);
        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}

        .gauge4liv {   position: relative;}
.gauge__container4liv {	
margin: 0;	padding: 0;	
position: absolute;	left: 50%;	
overflow: hidden;	text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
            transform: translateX(-50%);
}

.gauge__background4liv {	
    z-index: 0;	
    position: absolute;	
    background-color: #C5CAE9;	
    top: 0;	
    border-radius: 200px 200px 0 0;
}

.gauge__data4liv {	
    z-index: 1;	
    position: absolute;	
    background-color: #000000;	
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
        -o-transform-origin: center bottom;
            transform-origin: center bottom;
}
.gauge__data4livB {
    z-index: 1;
    position: absolute;
    background-color: #cc0000;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
        -o-transform-origin: center bottom;
            transform-origin: center bottom;
}
.gauge__data4livC {
    z-index: 1;
    position: absolute;
    background-color: #ff7e00;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
        -o-transform-origin: center bottom;
            transform-origin: center bottom;
}
.gauge__data4livD {
    z-index: 1;
    position: absolute;
    background-color: #189602;
    margin-left: auto;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
        -o-transform-origin: center bottom;
            transform-origin: center bottom;
}

.gauge__center4liv {
    z-index: 2;
    position: absolute;
    background-color: #fff;
    margin-right: auto;
    border-radius: 300px 300px 0 0;
}

.gauge__marker4liv {
    z-index: 3;
    background-color: #fff;
    position: absolute;
    height: 1px;
}

.gauge__markerbis4liv {
    z-index: 3;
    background-color: #fff;
    position: absolute;
    height: 1px;
}

.gauge__markertris4liv {
    z-index: 3;
    background-color: #fff;
    position: absolute;
    height: 1px;
}

.gauge__needle4liv {
    z-index: 4;
    background-color: #333;
    height: 3px;
    position: absolute;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
            transform-origin: left center;
}

.gauge__labels4liv {
    display: table;
    margin: 0 auto;
    position: relative;
}

.gauge__label--low4liv {
    display: table-cell;
    text-align: center;
}

.gauge__label--spacer4liv {
    display: table-cell;
    text-align: center;
    font-size: 16px;
}

.gauge__label--high4liv {
    display: table-cell;
    text-align: center;
}
.gauge4liv { height: calc(100px + 2em); }
.gauge__container4liv { width: 200px; height: 100px; }
.gauge__marker4liv { width: 200px; left: 0px; top: 100px; background-color: #fff !important;}
.gauge__markerbis4liv { width: 200px; left: 0px; top: 100px; background-color: #fff !important;}
.gauge__markertris4liv { width: 200px; left: 0px; top: 100px; background-color: #fff !important;}
.gauge__background4liv { width: 200px; height: 100px; background-color: #C5CAE9 !important;	}
.gauge__center4liv { width: 120px; height: 60px; top: 40px; margin-left: 40px; background-color: #fff !important;}
.gauge__data4liv{ width: 200px; height: 100px; background-color: #000000 !important;}
.gauge__data4livB { width: 200px; height: 100px; background-color: #cc0000 !important;}
.gauge__data4livC { width: 200px; height: 100px; background-color: #ff7e00 !important;}
.gauge__data4livD { width: 200px; height: 100px; background-color: #189602 !important;}
.gauge__needle4liv { left: 100px; top: 100px; width: 100px; background-color: #333 !important;}
.gauge__labels4liv { top: 100px; width: 200px; }
.gauge__label--low4liv { width: 40px; font-size: 12px}
.gauge__label--spacer4liv { width: 120px; }
.gauge__label--high4liv { width: 40px; font-size: 12px}


.gauge__data4liv {    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}
.gauge__data4livB {    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}
.gauge__data4livC {    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}
.gauge__data4livD {    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);            transform: rotate(-.50turn);
}
.gauge__needle4liv{    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);
            transform: rotate(-.50turn);
}

.gauge__markertris4liv{    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);            transform: rotate(-.50turn);}

.gauge__markerbis4liv{    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);            transform: rotate(-.50turn);
}

.gauge__marker4liv{    -webkit-transform: rotate(-.50turn);       -moz-transform: rotate(-.50turn);        -ms-transform: rotate(-.50turn);
        -o-transform: rotate(-.50turn);            transform: rotate(-.50turn);
}

`;
module.exports = PageStyle 