import React from 'react';

export class GradoImplementationeOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.misuraDiSicurezza.MisuraDiSicurezzaId,
      grade: props.misuraDiSicurezza.GradoDiImplementazione,
      risposte: props.misuraDiSicurezza.Risposte
    };
    this.updateGrade = this.updateGrade.bind(this);
  }

  updateGrade(event) {
    this.setState({ grade: event.target.value });
    var grade = parseInt(event.target.value,10);
    this.props.updateGrade(grade,this.props.categoriaId, this.props.misuraDiSicurezza.MisuraDiSicurezzaId);
    //console.log('updateGrade',item);
  }

  render() {
    var select = null;
    if(this.props.misuraDiSicurezza.Risposte === 3)
    {
      select = <select value={this.props.misuraDiSicurezza.GradoDiImplementazione}
      className='form100'
      onChange={this.updateGrade}>
      <option value='-1'></option>
      <option value='1'>Non Applicabile</option>      
      <option value='3'>No</option>
      <option value='6'>Si</option>
    </select>;
    }
    else
    select = <select value={this.props.misuraDiSicurezza.GradoDiImplementazione}
      className='form100'
      onChange={this.updateGrade}>
      <option value='-1'></option>
      <option value='1'>Non Applicabile</option>
      <option value='2'>Non so rispondere</option>        
      <option value='3'>Assente</option>
      <option value='4'>Migliorabile</option>
      <option value='5'>Adeguato</option>
      <option value='6'>Completo</option>
    </select>;

    return select;
  }
}