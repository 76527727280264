import React, { Component } from 'react';
import logo from '../../public/images/agid-logo.png';
import history from '../history';
function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
  }

class Footer extends Component {
    render() {
        return (
          <div className="u-background-95 u-hiddenPrint">
          <div className="u-layout-wide u-layoutCenter u-layout-r-withGutter">
          <footer className="footerHome py-3">
          <div className="footerCont">
          
 

          {/* <img src="images/LOGO Leonardo White.png" alt=""  height="30" width="30" /> */}

         <ul className="pt-2 mt-3 social_list list-inline">          
           {/* <span>
          <p className="testoBianco mr-5" style={{ fontSize: '14px' }}>P.IVA 00881841001 © 2019 Leonardo S.p.A.
          Per la diffusione delle Informazioni Regolamentate Leonardo si avvale del sistema di diffusione eMarket SDIR gestito da Spafid Connect SpA con sede in Milano, Foro Buonaparte n. 10. Per lo stoccaggio e il deposito delle Informazioni Regolamentate Leonardo si avvale del meccanismo di stoccaggio eMarket STORAGE gestito da Spafid Connect SpA con sede in Milano, Foro Buonaparte n. 10</p>
          </span>	 */}
          </ul>
      


            {/* <tbody>
              <tr>
                <td> <a className="u-text-r-m">
                  <img  src="images/logo_eu.svg" alt="logo"/></a>
                </td>                
                <td> <a href="http://www.agenziacoesione.gov.it/it/index.html" className="u-text-r-m" title="Agenzia per la Coesione Territoriale">
                  <img  src="images/logo_agenzia_coesione.svg" alt="Agenzia per la Coesione Territoriale"/>
                  </a>
                </td>
                <td> 
                <a href="http://www.funzionepubblica.gov.it/dipartimento-della-funzione-pubblica" className="u-text-r-m" title="Dipartimento della funzione pubblica">
                  <img src="images/logo_funzione_pubblica.svg" alt="Dipartimento della funzione pubblica"/>
                  </a>
                </td>
                <td> 
                <a href="http://www.pongovernance1420.gov.it/it/" className="u-text-r-m" title="PON Governance e Capacità Istituzionale">
                  <img data-src  src="images/logo_pon_governance.svg" alt="PON Governance e Capacità Istituzionale"/>
                  </a>
                </td>
                <td> <a href="https://www.agid.gov.it" className="u-text-r-m" title="Agenzia per l'Italia Digitale">
                  <img src="images/agid-logo.png" alt="Agenzia per l'Italia Digitale"/>
                  </a>
                </td>
              </tr>
            </tbody> */
            
            
            /*<div className="row pt-4">
            <div className="pt-3 mr-4 footer-title small col-8 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="media footer-title">
                <img className="img-fluid mr-3 mt-2" data-src="holder.js/64x64" alt="" src="/images/logo_eu.svg" data-holder-rendered="true"/>
              </div>
            </div>
      
            <div className="pt-3 mr-4 col-8 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="media footer-title">
                <a href="http://www.agenziacoesione.gov.it/it/index.html" title="Visita il sito dell' agenzia per la coesione territoriale" aria-label="Collegamento a sito esterno - Agenzia per la coesione territoriale - nuova finestra" target="_blank">
                <img className="img-fluid mr-3" data-src="holder.js/64x64" alt="Agenzia per la coesione territoriale" src="images/logo_agenzia_coesione.svg" data-holder-rendered="true"/></a>
              </div>
            </div>
      
            <div className="pt-3 mr-4 col-8 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="media footer-title">
                <a href="http://www.funzionepubblica.gov.it/dipartimento-della-funzione-pubblica" title="Visita il sito del dipartimento della funzione pubblica" aria-label="Collegamento a sito esterno - Dipartimento della funzione pubblica - nuova finestra" target="_blank">
                <img className="img-fluid mr-3" data-src="holder.js/64x64" alt="Dipartimento della funzione pubblica" src="images/logo_funzione_pubblica.svg" data-holder-rendered="true"/></a>
              </div>
            </div>
      
            <div className="pt-3 mr-4 col-8 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="media footer-title">
                <a href="http://www.pongovernance1420.gov.it/it/" title="Visita il sito del PON Governance Capacità Istituzionale 2014-2020" aria-label="Collegamento a sito esterno - PON Governance Capacità Istituzionale 2014-2020 - nuova finestra" target="_blank">
                <img className="img-fluid mr-3" data-src="holder.js/64x64" alt="PON Governance Capacità Istituzionale 2014-2020" src="images/logo_pon_governance.svg" data-holder-rendered="true"/></a>
              </div>
            </div>
      
            <div className="pt-3 mr-4 footer-title small col-8 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="media footer-title">
                <a href="https://www.agid.gov.it/" title="Visita il sito dell' Agenzia per l'Italia digitale" aria-label="Collegamento a sito esterno - Agenzia per l'Italia digitale - nuova finestra" target="_blank">
                <img className="img-fluid mr-3" data-src="holder.js/64x64" alt="Agenzia per l'Italia digitale" src="images/agid-logo.png" data-holder-rendered="true"/></a>
              </div>
            </div>
      
          </div>*/
            }


     
  </div>
 
  </footer>    
  
            </div>
              
          </div>
        );
    }
}

export default Footer;