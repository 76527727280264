import React, { Component } from 'react';
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';
import $ from 'jquery';
import logoLeonardo from "../../public/images/LOGO LEONARDO_full white.png";
import spidIco from "../../public/images/Spid/spid-ico-circle-bb.png";
import ApiCalls from '../utils/apiCalls';

const MINUTES_UNTIL_AUTO_LOGOUT = 20 // in mins
const CHECK_INTERVAL = 120000 // 2 min
const VALID_RESIDUA = 300 // 5 min

export default class HeaderBanner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userLastname: '',
      nomePA: '',
      user: {},
      isVisible: 'hidden',
      PAList: []
    };
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);
    this.renderDropDownMenu = this.renderDropDownMenu.bind(this);
    this.changePA = this.changePA.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.checkInterval = this.checkInterval.bind(this);

    this.initListener();
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    //document.body.addEventListener('mouseover',()=> this.reset());
    //document.body.addEventListener('mouseout',() => this.reset());
    //document.body.addEventListener('keydown',() => this.reset());
    //document.body.addEventListener('keyup',() => this.reset());
    //document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    sessionStorage.setItem('lastActivityDate', new Date().getTime());
  }

  loginIam() {
    let iamToken = localStorage.getItem("iam.token.access_token");
    ApiCalls.loginIam(iamToken).then(res => {
      this.props.auth.setToken(res.token);
    }).catch(error => console.error(error));
  }

  componentDidMount() {
    try {
      //console.log(this.props);
      //if (sessionStorage.getItem("token") == null)
        //this.loginIam();

      let decoded = this.getAccessToken();
      if (decoded !== null) {
        let user = decoded.user;
        this.setState({ user: user });
        this.setState({ userName: user.name });
        this.setState({ userSurname: user.familyName });
        this.setState({ nomePA: user.NomePA });

        this.serverRequest =
          $.ajax({
            url: API_CONFIG.PAListUrl,
            type: 'POST',
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
              console.log("PAList", response.data);
              this.setState({ PAList: response.data });
            }.bind(this),
            error: function (xhr, resp, text) {
              console.error(xhr);
            }
          });

        this.checkInterval();

        this.interval = setInterval(() => {
            this.checkInterval();
        }, CHECK_INTERVAL);
      }
    } catch (error) {
      return console.error(error);
    }
  }

  checkInterval() {
    console.log("CHECK_INTERVAL");
    var lastActivity = sessionStorage.getItem('lastActivityDate');
    var now = new Date();

    if (lastActivity != null) {
      var lastActivityDate = new Date(parseInt(lastActivity, 10) + (MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000));
      console.log("IDLE TIME", lastActivityDate - now);
      if (lastActivityDate < now) {
        //Tempo di idle superato
        this.props.auth.logout();
      }
      else {
        this.serverRequest =
          $.ajax({
            url: API_CONFIG.residualTimeToken,
            type: 'POST',
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {

              let diff = Math.abs(response.data);
              console.log("VALIDITA' RESIDUA", diff);
              //Se la validità residua del token è minore di VALID_RESIDUA facciamo il renew
              if (diff < VALID_RESIDUA) {
                this.props.auth.renewToken();
                console.log("RENEW");
              }

            }.bind(this),
            error: function (xhr, resp, text) {
              //Token JWT Invalid
              if (xhr.responseJSON.data === -1) {
                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                location.replace(API_CONFIG.HomePath);
              }
            }
          });

      }

    }
    else
      this.reset();//Primo set di lastActivityDate
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAccessToken() {
    return this.props.auth.getAccessToken();
  }

  isAuthenticated() {
    return this.props.auth.isAuthenticated();
  }

  logout() {
    this.props.auth.logout();
  }

  changePA(codiceIPA) {
    //this.props.auth.changePA(codiceIPA);
    console.log(codiceIPA);
    $.ajax({
      url: API_CONFIG.changePAUrl,
      type: 'POST',
      contentType: 'application/json',
      headers: { 'token': sessionStorage.getItem('token') },
      data: JSON.stringify({ CodiceIPA: codiceIPA }),
      cache: false,
      success: function (response) {
        this.props.auth.setToken(response.data);
      }.bind(this),
      error: function (xhr, resp, text) {
        //Token JWT Invalid
        if (xhr.responseJSON.data === -1) {
          this.props.auth.logout();
        }
      }
    });

  }


  renderDropDownMenu() {
    var list = [];

    if (this.state.PAList.length > 1) {

      list.push(<li key="titoloCambiaContesto" className="u-padding-r-all" role="menuitem"><span lang="en"><strong>Seleziona  Azienda</strong></span></li>);

      var list2 = this.state.PAList.map(function (item, i) {
        if (item.CodiceIPA !== this.state.user.CodiceIPA)
          return (
            <li role="menuitem" key={"dropdown" + item.CodiceIPA}>
              <a href="javascript:void(0)" onClick={() => this.changePA(item.CodiceIPA)} className="u-padding-r-all">
                <span lang="en">{item.Nome}</span>
              </a></li>);

      }.bind(this));
      list.push(list2);
      list.push(<hr key="separazione" />);
    }
    else {
      //list.push(<li className="u-padding-r-all" role="menuitem"><span lang="en"><strong>CAMBIA CONTESTO</strong></span></li>);
      //list.push( <li role="menuitem"><a href="" className="u-padding-r-all"><span lang="en">Comune di Roma</span></a></li>);
      //list.push( <li role="menuitem"><a href="" className="u-padding-r-all"><span lang="en">Ulss 4 - Comune di Canicattì</span></a></li>);   
      //list.push( <hr/>);   
    }

    return list;
  }

  render() {
    //return (<div></div>);

    return (
      this.isAuthenticated() ?

        <div className="Header-banner ">
          <div className="Header-owner Headroom-hideme ">
          <a href="https://www.leonardocompany.com"><span className="agidTop">
            <img src="images/LOGO LEONARDO_full white.png" alt=""  height="24" width="150" />
            </span></a>
            <div className="Header-languages ">
              <a href="https://cybersecure.westeurope.cloudapp.azure.com/GuidaOperativa.pdf" target="_blank" className="Header-language">Guida operativa</a> |  <a href="#languages" data-menu-trigger="languages" className="Header-language u-border-none u-zindex-max u-inlineBlock">
                <span className="">{this.state.userName + ' ' + this.state.userSurname + ' - ' + this.state.nomePA + ' '}<i className="fa fa-angle-down"></i></span>
              </a>
              <div id="languages" data-menu="" className="Dropdown-menu Header-language-other u-jsVisibilityHidden u-nojsDisplayNone" style={{ minWidth: '150px' }}>
                <span className="Icon-drop-down Dropdown-arrow u-color-white"></span>
                <ul>
                  {/* {this.renderDropDownMenu()} */}

                  <li role="menuitem"><a href="javascript:void(0)" onClick={() => this.logout()} className="u-padding-r-all"><span lang="en">Esci</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="Header-banner ">
          <div className="Header-owner Headroom-hideme ">
          <a href="https://www.leonardocompany.com"><span className="agidTop">
            <img src="images/LOGO LEONARDO_full white.png" alt=""  height="24" width="150" />
            </span></a>            <div className="Header-languages">
              <div className="Spid">
                <a style={{backgroundColor:"#990000", borderColor:"#505050"}} href={API_CONFIG.loginSpidUrl+'ambientetest'} className="Button Spid-button u-text-r-xxs" aria-controls="spid-button-get-xxs" aria-haspopup="true" role="button">
                  <img src="images/Spid/spid-ico-circle-bb.png" alt="" />
                  <span style={{color:"#FFF"}}>Entra</span>
                </a>
                {/* <div id="spid-button-get-xxs" data-menu="" className="Spid-menu Dropdown-menu" x-placement="bottom" role="menu" aria-hidden="false" style={{ position: "absolute", transform: "translate3d(0px, 39px, 0px)", top: "0px", left: "0px", willChange: "transform" }}>
                  <ul className="Linklist js-randomize">
                    <li role="menuitem" className="">
                      <a href={API_CONFIG.loginSpidUrl+'ambientetest'} className="Spid-idp">
                        <span className="u-hiddenVisually">Prosegui con TIM ID</span>
                        <img src="images/Spid/spid-idp-timid.svg" alt="" className="u-text-r-xxs" />
                      </a>
                    </li>
                  </ul>
                  <ul className="Linklist"><li className="spid-idp-support-link" data-spidlink="info" role="menuitem">
                    <a className="Spid-idp" href="https://www.spid.gov.it">Maggiori informazioni</a></li>
                    <li className="spid-idp-support-link" data-spidlink="rich" role="menuitem">
                      <a className="Spid-idp" href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a></li>
                    <li className="spid-idp-support-link" data-spidlink="help" role="menuitem">
                      <a className="Spid-idp" href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a></li></ul>

                </div> */}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

