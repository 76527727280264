import React, { Component } from 'react';
import { API_CONFIG } from '../../public/js/apiServices';
import confImg from "../../public/images/conf.jpg";

function redirect(path) {
  try {
      if (path !== undefined) {
          if (path.indexOf('/') === 0)
              history.push(path);
          else if (path.indexOf('/') === -1) {
              history.push('/' + path);
          }
      }
  } catch (error) {
      return console.error(error)
  }
}

export default class Home extends Component {

  render() {
    return (

      <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

        <div className="container cnsContainer">	

        <div className="row">        
          <div className="col-lg-12"> 
           <nav className="" aria-label="sei qui:" role="navigation">
              <ul className="Breadcrumb">
                <li className="Breadcrumb-item"><a className="u-color-50" style={{cursor:'pointer'}} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
              </ul>
           </nav>	
          <span className="titoloPagina">Software security & privacy Compliance</span>
          </div>
          </div>	
    
        <div className="row">  
        <div className="col-lg-12">  
        <div className="linea0066cc"></div>
         <p style={{textAlign: "justify"}}>
         Il quadro normativo cui le imprese devono far fronte rimane in continua evoluzione facendosi sempre più articolato. In questo quadro, alle leggi primarie e secondarie si affiancano regolamenti, direttive, codici di condotta, standard e norme del contesto specifico, prescrizioni tecniche e linee guida.  <br/>
Per far fronte ad un tale reticolo di regolamenti e leggi, spesso collegati fra loro, che mancano di riferimenti specifici ai requisiti concreti è fondamentale che le aziende adottino <strong>un approccio innovativo e integrato, in grado di evitare ridondanze di processi, documenti, sistemi informativi e strutture</strong>. Tale approccio deve partire sicuramente <strong>da una visione a 360 gradi del proprio contesto</strong>.

        </p>
        </div> 
        </div> 

         <div className="row"> 
        <div className="col-lg-12"> 
        <div className="spazio10"></div>
            <h2><strong>Approccio alla conformità</strong></h2>
            <hr/>
        </div>  
        </div> 
        <br/>
        <div className="row"> 
          <div className="col-lg-12" style={{textAlign:"center"}}> 
          <img  src={confImg} />
          </div>   
        </div>      
          <br/>
          <br/>
          <br/>
          <br/>
  </div>
</div>)
  }
}

