import React from 'react';
import $ from 'jquery';

//
export class PropensionOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        };
        this.updateOption = this.updateOption.bind(this);
        this.getOption = this.getOption.bind(this);
    }

    /**
     * 
     * @param {*} event 
     */
    updateOption(event) {
        //let val = parseInt(event.target.value, 10);
        //console.log('updateRiskAppetite : ', parseInt(event.target.value, 10));
        this.props.setShowAccordion();
        var className = ".statoAccordionLink"+ this.props.rowId;
        var accordion = "#collapseStato"+ this.props.rowId;
        console.log("ACCORDION", $(accordion));
        console.log("rowId", this.props.rowId);
        if ($(accordion).attr("class") === "collapse")
        {
            if($(className).length > 0)
                $(className).get(0).click();
        }

        this.props.updateOption(this.props.item.MinacciaId, parseInt(event.target.value, 10));
    }

    /**
     * 
     */
    getOption() {
        let optionList = this.props.optionList.map(function (item, i) {
            return (
                <option key={i} value={i}>{item}</option>
            )
        })
        return optionList;
    }

    render() {
        return (
            <select value={this.props.value}
                //onChange={(event) => this.props.updateOption(this.props.item.MinacciaId, parseInt(event.target.value, 10))}
                onChange={this.updateOption}
                disabled={this.props.readOnly}
                className={this.props.className}
            > {this.getOption()}
            </select>
        );
    }
}