import React from 'react'
import $ from "jquery";
import { API_CONFIG } from '../../public/js/apiServices';

function getFileNameByResponse(response) {
    let disposition = response.headers.get('content-disposition');
    let fileName = "";
    if (disposition != null) {

        if (disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
        }
    }
    return fileName;
}

export class EsportaLibrary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
        }
        this.fileDownload = this.fileDownload.bind(this);
    }

    fileDownload() {
        $.ajax({
            //url: "http://cors.jejaju.com/Snickerdoodles.pdf",
            url: API_CONFIG.exportExcel,
            headers: { 'token': sessionStorage.getItem('token') },
            type: 'POST',
            xhrFields: {
                responseType: 'blob'
            },
            beforeSend: function () {
                $('#loader').show();
                $('#modal').show();
            },
            complete: function () {
                $('#loader').hide();
                $('#modal').hide();
            },
            success: function (response, textStatus, request) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(response);
                a.href = url;
                a.download = 'Library.xlsx';
                document.body.append(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                console.log(response);
            },
            error: function (jqxhr, status, errorMessage) {
                //handle error
                console.error(status + " : " + jqxhr.responseText);
                //this.setState({ errorMessage: 'Nessun file scaricato' }, () => $('#btnError').get(0).click());
                this.props.showModal('Nessun file scaricato','error');
            }.bind(this),
        });
    }

    render() {

        return (
            <div className="row card-headerInfo">
                <div className="col-3">Esportazione Libreria</div>
                <div className='col-1'></div>
                <div className='col-2'>
                    <a href="javascript:void(0);">
                        <button onClick={() => this.fileDownload()} type="button" className="btn btn-warning btn-lg btn-block">Download</button>
                    </a>
                </div>
            </div>
        )
    }
}

//export default SimpleReactFileUpload