import React, { Component } from 'react';
import { defaults } from 'react-chartjs-2';
import history from '../history';
import ApiCalls from '../utils/apiCalls';
import { ModalDialog } from '../Common/ModalDialog';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import infoIcon from '../../public/images/info.png';

defaults.global.defaultFontFamily = 'Titillium';
defaults.global.defaultFontSize = 14;

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userName: '',
            userSurname: '',

            listaAssessment: [],
            totali: 0,

            listaAssessmentTrattamento: [],
        };
        this.getFirstWord = this.getFirstWord.bind(this);
        this.getAccessToken = this.getAccessToken.bind(this);
        this.redirectElenco = this.redirectElenco.bind(this);
        this.redirect = this.redirect.bind(this);
        this.redirectPlanning = this.redirectPlanning.bind(this);
        this.onError = this.onError.bind(this);
    }


    setAggiornamentoLibreria(){
        ApiCalls.updateAlertAggiornamentoLibreriaUtente().catch(e => {
            console.error(e)
        });
    }

    onError(error) {
        console.error(error);
        if (error.response.data.data == -1) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userInfo");
            sessionStorage.removeItem("lastActivityDate");
            window.location.replace(API_CONFIG.HomePath);
        }
    }

    componentDidMount() {
        let decoded = this.getAccessToken();
        if (decoded !== null) {
            let user = decoded.user;
            this.setState({ user: user });
            this.setState({ userName: user.name });
            this.setState({ userSurname: user.familyName });

            ApiCalls.getUtenteByCodiceFiscale(user.CodiceFiscale).then(res => {
                res.AlertAggiornamentoLibreria ? $('#btnWarning').get(0).click() : {};
            }).catch(e => console.error(e));
        }


        ApiCalls.getAssessmentcompositeRiepilogoHome().then(data =>
            {
                this.setState({ listaAssessment: data.getAssessmentDaCompletare});
                this.setState({ indice: data.getIndiceTotaleImpatti[0].IndiceTotale });
                this.setState({ totali: data.getAssessmentCompletate[0].getAssessmentCompletate });
            }
        ).catch(error => this.onError(error));

        ApiCalls.getListAssessmentTrattamento().then(data =>
            {
                this.setState({ listaAssessmentTrattamento: data});
            }
        ).catch(error => this.onError(error));
        
    }

    getAccessToken() {
        return this.props.auth.getAccessToken();
    }

    redirectElenco(path){
        try {
    
            window.sessionStorage.removeItem("selectedCategory");
            window.sessionStorage.removeItem("selectedState");
            window.sessionStorage.removeItem("search");
            window.sessionStorage.removeItem("searchAllAR");
            window.sessionStorage.removeItem("selectedTypology");
            window.sessionStorage.removeItem("openfilterAR");
    
            history.push(path);
    
        } catch (error) {
            return console.error(error)
        } 
    }

    redirect(assessment, readonly) {
        try {
            window.sessionStorage.setItem("serviceId", assessment.ServizioId);
            window.sessionStorage.setItem("serviceName", assessment.Nome);
            window.sessionStorage.setItem("serviceCriticità", assessment.Criticità);
            window.sessionStorage.setItem("serviceImpattoR", assessment.ImpattoR);
            var path = readonly ? "/GapAnalysisVisualizza" : "/GapAnalysisModifica";
            history.push(path);
    
        } catch (error) {
            return console.error(error)
        }
    }

    redirectPlanning(assessment, readOnly) {
        try {
            history.push("/Planning", {
                "serviceName" : assessment.Nome,
                "statoAssessment":assessment.StatoAssessmentId,
                "assessmentId":assessment.AssessmentId
            });
    
        } catch (error) {
            return console.error(error)
        }
    }
    

    /**
     * 
     */
    getFirstWord() {
        let user = this.state.user;
        try {
            if (user) {
                var giorno = parseInt(user.CodiceFiscale.substring(9, 11), 10);

                return (giorno > 40) ? "Benvenuta" : "Benvenuto";
            }

        } catch (error) {
            console.error(error)
            return "Benvenuto";
        }
    }

    hasFunction(funzione) {
        //console.log("hasFunction", funzione, this.props.auth.hasFunction(funzione));
        return this.props.auth.hasFunction(funzione);
    }

    render() {
        // riepilogo assessment sotto Compliance self Assessment
        let assessmentRows = [];

        if(this.state.listaAssessment.length > 0){
            var totale = this.state.totali;
            var daCompletare = this.state.listaAssessment.length - totale;
            
            for (let index = 0; index < 6; index++) {
                if (this.state.listaAssessment[index] === undefined)
                    break;
                
                if (this.state.listaAssessment[index].TipoAssessment === 0) {
                }
                else{
                    let readOnly = (this.state.listaAssessment[index].StatoAssessmentId >= 4);
                    let totRisposto = this.state.listaAssessment[index].TotaliRisposte;
                    let tot = this.state.listaAssessment[index].Totali;
                    let assessmentTot = ((tot) || (totRisposto)) ? this.state.listaAssessment[index].TotaliRisposte + "/" + this.state.listaAssessment[index].Totali : "";
                    
                    assessmentRows.push(<div key={"Assessment" + index} className="col-lg-9">
                    <a href="javascript:void(0)" onClick={(e) => {e.preventDefault(); this.redirect(this.state.listaAssessment[index], true)}}>
                        {this.state.listaAssessment[index].Nome}</a>
                    </div>);

                    assessmentRows.push(<div key={"AssessmentTot" + index} className="col-lg-2">
                        {assessmentTot}
                    </div>);

                    assessmentRows.push(<div key={"ServizioLinkAR" + index} className="col-lg-1">
                        <a href="/" onClick={(e) =>{e.preventDefault(); this.redirect(this.state.listaAssessment[index], readOnly)}}>
                            <i className={(readOnly) ? "fa fa-eye float-right" : "fa fa-pencil float-right"} title={(readOnly) ? "VEDI" : "MODIFICA" }></i>
                        </a>
                    </div>);
                }
            }

            assessmentRows.push(<div key="vediTutti" className="col-lg-12 card-headerT">
            <hr /><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => this.redirectElenco("/ListaApplicazioniGapAnalysis")}>
            <strong>Vedi tutti</strong>  <i className="paRight fa fa-angle-right faBold"></i></a></div>
            );
        }
        else
            assessmentRows.push(<div key="empty" className="col-lg-12 card-headerT">Nessuna compliance self assessment da completare.</div>);

        // riepilogo assessment trattamento sotto Remediations Plans
        let assessmentTrattamentoRows = [];

        if(this.state.listaAssessmentTrattamento.length > 0){
            var completateTrattamento = 0;
            var daCompletareTrattamento = 0;
            
            for (let index = 0; index < 6; index++) {
                if (this.state.listaAssessmentTrattamento[index] === undefined)
                    break;
                
                if (this.state.listaAssessmentTrattamento[index].TipoAssessment === 0) {
                }
                else{
                    let readOnly = (this.state.listaAssessmentTrattamento[index].StatoAssessmentId >= 4);
                    let totRisposto = this.state.listaAssessmentTrattamento[index].TotaleConcluso;
                    let tot = this.state.listaAssessmentTrattamento[index].Totale;
                    let assessmentTot = ((tot) || (totRisposto)) ? totRisposto + "/" + tot : "";
                    
                    if(totRisposto == tot)
                        completateTrattamento++;
                    else
                        daCompletareTrattamento++;

                    assessmentTrattamentoRows.push(<div key={"Assessment" + index} className="col-lg-9">
                    <a href="javascript:void(0)" onClick={(e) => {e.preventDefault(); this.redirectPlanning(this.state.listaAssessmentTrattamento[index], true)}}>
                        {this.state.listaAssessmentTrattamento[index].Nome}</a>
                    </div>);

                    assessmentTrattamentoRows.push(<div key={"AssessmentTot" + index} className="col-lg-2">
                        {assessmentTot}
                    </div>);

                    assessmentTrattamentoRows.push(<div key={"ServizioLinkAR" + index} className="col-lg-1">
                        <a href="/" onClick={(e) =>{e.preventDefault(); this.redirectPlanning(this.state.listaAssessmentTrattamento[index], readOnly)}}>
                            <i className={(readOnly) ? "fa fa-eye float-right" : "fa fa-pencil float-right"} title={(readOnly) ? "VEDI" : "MODIFICA" }></i>
                        </a>
                    </div>);
                }
            }

            assessmentTrattamentoRows.push(<div key="vediTutti" className="col-lg-12 card-headerT">
            <hr /><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => this.redirectElenco("/ElencoApplicazioniPlanning")}>
            <strong>Vedi tutti</strong>  <i className="paRight fa fa-angle-right faBold"></i></a></div>
            );
        }
        else
            assessmentTrattamentoRows.push(<div key="empty" className="col-lg-12 card-headerT">Nessun remediations plan da completare.</div>);

        var classTab1 = "nav-item nav-link";
        var classTab2 = "nav-item nav-link";
        var classTab3 = "nav-item nav-link";
        var classTab4 = "nav-item nav-link";
        var classPane1 = "tab-pane fade";
        var classPane2 = "tab-pane fade";
        var classPane3 = "tab-pane fade";
        var classPane4 = "tab-pane fade";

        if(this.hasFunction("AC_Visualizza"))
        {
            classTab1 += " active"; 
            classPane1 += " active show"; 
        }
        else if(this.hasFunction("VI_Visualizza"))
        {
            classTab2 += " active";
            classPane2 += " active show"; 
        }
        else if(this.hasFunction("AR_Visualizza"))
        {
            classTab3 += " active";
            classPane3 += " active show"; 
        }
        else if(this.hasFunction("TR_Visualizza"))
        {
            classTab4 += " active";
            classPane4 += " active show"; 
        }


        return (
            
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
                <ModalDialog notShowAnnulla={true} idTarget={'warningAction'} handle={this.setAggiornamentoLibreria} idBtn={'btnWarning'} textModal={"La Libreria Metodologica è stata modificata. L'aggiornamento avrà impatto solo sui nuovi processi e non su quelli in corso."} />

                <div className="container cnsContainer">
                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    {/* <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li> */}
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }}>Home</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">{this.getFirstWord(this.state.user)} <strong>{this.state.userName + " " + this.state.userSurname}</strong></span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 style={{textAlign: 'justify'}}>
                            Il processo consente di analizzare il livello di maturità dei requisiti di sicurezza e privacy dell’Asset &lt;&lt;AS-IS&gt;&gt; rispetto al livello di conformità atteso &lt;&lt;TO-BE&gt;&gt; dal contesto normativo di riferimento e, nel caso risulti non adeguato, individuare le azioni più idonee per colmare il gap (partendo dalle azioni più prioritarie). Il processo deve essere eseguito su ogni Asset del contesto di analisi e ri-eseguito periodicamente per verificare e monitorare i risultati delle azioni di remediations intraprese e comunque (necessariamente) ogni volta che variano le condizioni del contesto di analisi
                        </h2>
                        </div>
                    </div>
                    <div className="row spazio30"></div>
                    <div className="bd-example bd-example-tabs">
                        <nav className="nav nav-tabs" id="nav-tab" role="tablist">
                            {this.hasFunction("AC_Visualizza") &&
                                <a className={classTab1} id="nav-contesto-tab" data-bs-toggle="tab" href="#nav-contesto" role="tab" aria-controls="contesto" aria-expanded="true">1 - Identificazione del contesto</a>
                            }
                            {this.hasFunction("AR_Visualizza") &&
                                <a className={classTab2}  id="nav-impatti-tab" data-bs-toggle="tab" href="#nav-impatti" role="tab" aria-controls="impatti" aria-expanded="false">2 - Compliance Self-Assessment</a>
                            }
                            {this.hasFunction("AR_Visualizza") &&

                                <a className={classTab3}  id="nav-rischio-tab" data-bs-toggle="tab" href="#nav-rischio" role="tab" aria-controls="rischio" aria-expanded="true">3 - Remediations Plan</a>
                            }
                            {this.hasFunction("AR_Visualizza") &&

                                <a className={classTab4}  id="nav-trattamento-tab" data-bs-toggle="tab" href="#nav-trattamento" role="tab" aria-controls="trattamento" aria-expanded="false">4 - Reporting & Monitoring</a>
                            }

                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {this.hasFunction("AC_Visualizza") &&
                                <div className={classPane1} id="nav-contesto" role="tabpanel" aria-labelledby="nav-contesto-tab" aria-expanded="true">
                                    <div className="row">

                                        <div className="col-lg-12 giustifica">
                                        Il Contesto di analisi è l’insieme dei Servizi (Catalogo Asset  definito in fase di censimento) erogati ed utilizzati dall’Ente e che devono essere sottoposti al processo di verifica del livello di compliance rispetto al complesso normativo di riferimento (Catalogo Regulations  pre-caricato nel sistema in fase di inizializzazione e configurazione). Il Catalogo Regulations identifica il contesto normativo  e le best practices rispetto al quale sarà misurato il gap di conformità.
                                    <br />
                                    Il processo deve essere avviato su ogni elemento (Asset) del contesto di analisi  (Catalogo degli Asset) utilizzando l’Elenco degli Asset proposto dal sistema nella fase successiva 2-Compliance Self-Assessment.
                                            {/* Per realizzare l’Analisi del Contesto con il calcolo del Profilo di Criticità di ciascun Servizio l’utente deve attivare la pagina <a onClick={() => history.push('/ListaServizi')} href="javascript:void(0)">Elenco servizi per analisi del contesto</a> e completare, per ciascun servizio, la definizione delle caratteristiche richieste ed obbligatorie. I servizi per i quali non è calcolato il Profilo di Criticità non rientrano nell’<a onClick={() => history.push('/AnalisiContesto')} href="javascript:void(0)">Analisi del Contesto</a> e nel Processo di Risk Management. */}
                                    </div>

                                    </div>
                                </div>
                            }
                            {this.hasFunction("AR_Visualizza") &&
                                <div className={classPane2}  id="nav-impatti" role="tabpanel" aria-labelledby="nav-impatti-tab" aria-expanded="false">
                                    <div className=" giustifica">
                                    
                                    La valutazione della Compliance è l’attività volta a verificare che l’asset implementi in maniera adeguata i requisiti di sicurezza e privacy imposti dal contesto normativo di riferimento al fine di garantire Riservatezza, Integrità e Disponibilità delle informazioni trattate dal servizio. A Tal fine è necessario censire correttamente e in modo strutturato tutte le informazioni del contesto che costituiranno quindi la baseline &lt;&lt;AS-IS&gt;&gt; rispetto alla quale verrà misurato il Gap &lt;&lt;TO-BE&gt;&gt;. L’utente dopo aver individuato il servizio applicativo da analizzare, dovrà quindi rispondere adeguatamente al questionario di Self-Assessment proposto dal sistema per l’auto-valutazione dello stato &lt;&lt;AS-IS&gt;&gt; di implementazione dei requisiti di sicurezza e privacy rispetto ai quali verrà misurato il gap di conformità.
                                    <br />
                                        
                                    Solo dopo aver completato il questionario, l’utente può avviare lo step di Analisi e misurazione del Gap di conformità e identificazione del Piano delle azioni per colmare il gap misurato: 3-Remediations Plan.
                                </div>

                                <div className="spazio20"></div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <strong>Analisi incomplete: </strong>{daCompletare}</div>
                                        <div className="col-lg-4">
                                            <strong>Analisi completate: </strong> {totale}</div>
                                        <div className="col-lg-4">
                                            <a data-bs-toggle="collapse" href="#collapseInfoOpzioni"><span className=" testoBlu paRight"><img src={infoIcon} alt="" /></span></a>
                                        </div>
                                    </div>
                                    <div id="collapseInfoOpzioni" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Indica se il numero di servizi per cui è stata effettuata l’analisi ...
                                    </div>
                                    <div className="spazio10"></div>

                                    <div className="row">
                                        <div className="col-lg-9 card-headerT">Asset</div>
                                        <div className="col-lg-2 card-headerT">Analisi</div>
                                        <div className="col-lg-1 card-headerT">Azioni</div>
                                        {assessmentRows}
                                    </div>
                                </div>
                            }
                            {this.hasFunction("AR_Visualizza") &&
                                <div className={classPane3}  id="nav-rischio" role="tabpanel" aria-labelledby="nav-rischio-tab" aria-expanded="false">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <strong>Analisi incomplete: </strong>{daCompletareTrattamento}</div>
                                        <div className="col-lg-4">
                                            <strong>Analisi completate: </strong> {completateTrattamento}</div>
                                        <div className="col-lg-4">
                                            <a data-bs-toggle="collapse" href="#collapseInfoRemediations"><span className=" testoBlu paRight"><img src="images/info.png" alt="" /></span></a>
                                        </div>
                                    </div>
                                    <div id="collapseInfoRemediations" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Indica se il numero di servizi per cui è stata effettuata l’analisi ...
                                    </div>
                                    <div className="spazio10"></div>

                                    <div className="row">
                                        <div className="col-lg-9 card-headerT">Asset</div>
                                        <div className="col-lg-2 card-headerT">Analisi</div>
                                        <div className="col-lg-1 card-headerT">Azioni</div>
                                        {assessmentTrattamentoRows}
                                    </div>
                                    
                                    <p>
                                    Il sistema evidenzia il grado complessivo di conformità rilevato ed elenca i requisiti di compliance analizzati partendo da quelli con livello di non conformità e rilevanza più elevato. 
                                    <br/>Per ciascun requisito si avrà:
                                    </p>
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • la <strong>'Rilevanza'</strong> che esprime l'importanza del requisito stesso rispetto alla totalità dei requisiti previsti sulla base della sanzione che la normativa prevede per gli articoli di legge ad esso associati. Tale indice può assumere i valori 'BASSA', 'MEDIA' e 'ALTA';
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" hidden>
                                        <div className="col-lg-4">
                                        </div>
                                        <div className="col-lg-4">
                                        <table width="100%" border="1" cellPadding="5">
                                                <tbody>
                                                    <tr>
                                                        <td rowSpan={3} align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>RILEVANZA DEL CONTROLLO</td>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>Sanzione GDPR 4%</td>
                                                        <td align="center" valign="middle"><strong className="testoRosso">ALTA</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>Sanzione GDPR 2%</td>
                                                        <td align="center" valign="middle"><strong className="testoArancio">MEDIA</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>Misure Minime ICT PA e altre Normative</td>
                                                        <td align="center" valign="middle"><strong className="testoVerde">BASSA</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • la <strong>'Conformità'</strong> che esprime l’aderenza al requisito specifico e viene determinato sulla base della risposta fornita al controllo, come descritto nella figura seguente;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-4">
                                        </div>
                                        <div className="col-lg-4">
                                        <table width="100%" border="1" cellPadding="5">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>
                                                            RISPOSTA
                                                        </td>
                                                        <td align="center" valign="middle" style={{backgroundColor:'#0066cc', color:'#FFF'}}>
                                                            LIVELLO DI CONFORMITA' DEL REQUISITO
                                                        </td>
                                                    </tr>
                                                    <tr>                                                        
                                                        <td align="center" valign="middle" >Non so rispondere</td>
                                                        <td align="center" valign="middle"><strong className="testoRosso">BASSO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Assente/NO</td>
                                                        <td align="center" valign="middle"><strong className="testoRosso">BASSO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Migliorabile</td>
                                                        <td align="center" valign="middle"><strong className="testoArancio">MEDIO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Adeguato</td>
                                                        <td align="center" valign="middle"><strong className="testoVerde">ALTO</strong></td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td align="center" valign="middle" >Completo</td>
                                                        <td align="center" valign="middle"><strong className="testoVerde">ALTO</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • la <strong>'Descrizione'</strong> del requisito;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • il <strong>'Riferimento normativo'</strong> al quale afferisce il requisito;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • i <strong>'Requisiti tecnologici'</strong> aggiuntivi ad esso associati;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • i <strong>'Requisiti aggiuntivi'</strong> per parziale/totale conformità;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • le <strong>'Azioni necessarie'</strong>;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • ulteriori <strong>'Azioni consigliate'</strong>;
                                        </div>
                                    </div> 
                                    <div className="spazio10"></div>
                                    <div className="row" >
                                        <div className="col-lg-12">
                                        • le <strong>'Sanzioni'</strong> previste in caso di violazione del requisito stesso;
                                        </div>
                                    </div> 
                                    
                                    {/* <div className="spazio20"></div>
                                     <div className="row">
                                        <div className="col-lg-4">
                                            <strong>Analisi incomplete: </strong>{daCompletareTrattamento}</div>
                                        <div className="col-lg-4">
                                            <strong>Analisi completate: </strong> {completateTrattamento}</div>
                                        <div className="col-lg-4">
                                            <a data-toggle="collapse" href="#collapseInfoRemediations"><span className=" testoBlu paRight"><img src={infoIcon} alt="" /></span></a>
                                        </div>
                                    </div>
                                    <div id="collapseInfoRemediations" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Indica se il numero di servizi per cui è stata effettuata l’analisi ...
                                    </div>
                                    <div className="spazio10"></div>

                                    <div className="row">
                                        <div className="col-lg-9 card-headerT">Servizio</div>
                                        <div className="col-lg-2 card-headerT">Analisi</div>
                                        <div className="col-lg-1 card-headerT">Azioni</div>
                                        {assessmentTrattamentoRows}
                                    </div> */}
                                </div>
                            }
                            {this.hasFunction("AR_Visualizza") &&
                                <div className={classPane4}  id="nav-trattamento" role="tabpanel" aria-labelledby="nav-trattamento-tab">
                                    <p>
                                    L’utente può monitorare lo stato &lt;&lt;AS-IS&gt;&gt; del livello di maturità/conformità del contesto di analisi e il piano delle azioni intraprese per migliorarlo. Quest’area fornisce altresì la possibilità di produrre documentazione che, in caso di audit, fornisce evidenze oggettive e referenziate circa le misure messe in atto dall’Amministrazione.  
                                    </p>
                                </div>
                            }
                        </div>
                    </div>

                </div>

                <div className="spazio10"></div><div className="spazio10"></div><div className="spazio10"></div>

            </div>
        )
    }
}

