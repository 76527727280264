import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { CaratteristicaRow } from './CaratteristicaRow';
import { ErogazioneRow } from './ErogazioneRow';
import { ServiziTrasversaliRow } from './ServiziTrasversaliRow';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { AuthenticationRow } from './AuthenticationRow';
/**
 * 
 * @param {*} array 
 */
function toString(array) {
    let toString = '';
    try {
        for (let index = 0; index < array.length; index++) {
            const element = array[index].Nome;
            toString += element + ' | ';
        }
        return toString.substring(0, toString.length - 2);
    } catch (error) {
        console.error(error);
        return null
    }
}

const LINK = [
    { nome: "Analisi del contesto", url: "/AnalisiContesto" },
    { nome: "Elenco servizi", url: "/ListaServizi" },
    { nome: "Modifica servizio", url: "/AnalisiContestoModifica" }
];


export class AnalisiContestoModifica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serviziComposite: [],
            referente: '',
            struttura: '',
            serviceId: window.sessionStorage.getItem('serviceId'),
            //serviceId: 'Test01',
            serviceName: '',
            descrizione: '',
            categorieToString: '',
            tipologieToString: '',
            messageWarning: '',
            messageError: '',
            impattoMin: '',
            updateDate: false,
            dataAggiornamento: '',
            dataCalcoloCriticità: ''
        }
        //CHECK
        this.checkImpatto = this.checkImpatto.bind(this);
        //
        this.getImpattoMin = this.getImpattoMin.bind(this);
        //VALIDATE
        this.isValidate = this.isValidate.bind(this);
        //UPDATE
        this.updateCaratteristica = this.updateCaratteristica.bind(this);
        this.updateNotaAggiuntiva = this.updateNotaAggiuntiva.bind(this);
        this.updateReferente = this.updateReferente.bind(this);
        this.updateStruttura = this.updateStruttura.bind(this);
        this.updateServizioTrasversale = this.updateServizioTrasversale.bind(this);
        this.updateNotaServizioTrasversale = this.updateNotaServizioTrasversale.bind(this);
        //SAVE
        this.save = this.save.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.isUpdated = this.isUpdated.bind(this);
    }

    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        // submit form data to api
        //curl -X POST "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \"ServizioId\": \"S2\"}"
        this.serverRequest =
            $.ajax({
                //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById',
                url: API_CONFIG.servizicompositeByIdUrl,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ ServizioId: this.state.serviceId }),//data:"{ \"ServizioId\": \"S2\"}",
                cache: false,
                success: function (response) {
                    this.setState({ serviziComposite: response.data });
                    this.setState({ referente: response.data[0].Referente === null ? '' : response.data[0].Referente });
                    this.setState({ struttura: response.data[0].StrutturaReferente == null ? '' : response.data[0].StrutturaReferente });
                    this.setState({ serviceName: response.data[0].Nome });
                    this.setState({ descrizione: response.data[0].Descrizione });
                    this.setState({ dataAggiornamento: response.data[0].DataCompilazioneContesto });
                    this.setState({ dataCalcoloCriticità: response.data[0].DataUpdateProfiloDiCriticità });
                    this.setState({ impattoMin: this.getImpattoMin(response.data[0].ImpattoR, response.data[0].ImpattoI, response.data[0].ImpattoD) });
                    this.setState({ categorieToString: toString(response.data[0].Categorie) });
                    this.setState({ tipologieToString: toString(response.data[0].Tipologie) });
                    console.log('servizicompositeByIdUrl: ', response.data[0]);
                    /*
                    this.setState({servicesPrimary:this.getServiziComposite(response.data)[1]});
                    this.setState({servicesSecondary:this.getServiziComposite(response.data)[0]});
                  */
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());

                }.bind(this),
            });
    }

    componentWillUnmount() {
        //TODO
    }

    /**
     * 
     */
    isUpdated() {
        try {

            let tsDataAggiornamento = new Date(this.state.dataAggiornamento).getTime();
            let tsDataCalcoloCriticità = new Date(this.state.dataCalcoloCriticità).getTime();

            return tsDataAggiornamento > tsDataCalcoloCriticità;
        } catch (error) {
            return false;
        }
    }

    /**
     * 
     * @param {*} rowId 
     * @param {*} IdCaratteristica 
     */
    updateCaratteristica(rowId, caratteristicaId) {
        //cambiare il valore Checked
        var _serviziComposite = this.state.serviziComposite;
        const element = _serviziComposite[0].CategorieCaratteristica;
        for (let i = 0; i < element.length; i++) {
            if (element[i].CategoriaCaratteristicaId === rowId) {
                for (let j = 0; j < element[i].Caratteristica.length; j++) {
                    const caratteristica = element[i].Caratteristica[j];

                    switch (element[i].CategoriaCaratteristicaId) {
                        case 2://Modalita' di erogazione del servizio
                            this.setState({ updateDate: true });
                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                                //this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());
                            }
                            break;
                        case 3://Destinatari del servizio
                            this.setState({ updateDate: true });
                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                            } else {
                                //caratteristica.Checked = false;//per rendeere la caratteristica di tipo Tipologia di Servizio mutualmente esclusiva
                            }
                            //this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());

                            break;
                        case 4://Autenticazione
                            this.setState({ updateDate: true });
                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                                //this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());
                            }
                            break;
                        case 9://"Utilizzo servizi trasversali nazionali (piattaforme abilitanti)"
                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                                if (caratteristicaId !== 59) {
                                    let findIndex = element[i].Caratteristica.findIndex(function (obj) { return obj.Id === 59; });
                                    element[i].Caratteristica[findIndex].Checked = false;
                                } else {
                                    let array = element[i].Caratteristica;
                                    for (let index = 0; index < array.length; index++) {
                                        if (array[index].Id !== 59)
                                            array[index].Checked = false;
                                    }
                                }
                            }

                            break;
                        case 11://Tipologia di Servizio

                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                            } else {
                                //caratteristica.Checked = false;//per rendeere la caratteristica di tipo Tipologia di Servizio mutualmente esclusiva
                            }
                            break;
                        default:
                            if (caratteristica.Id === caratteristicaId) {
                                caratteristica.Checked = !caratteristica.Checked;
                                //this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());
                            }
                            break;
                    }
                }
            }
        }
        //console.log('this.sate.serviziComposite:', this.state.serviziComposite);
        this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());
    }

    /**
     * 
     * @param {*} notaAggiuntiva value of input text
     * @param {*} categoryRow index of CategorieCaratteristica
     * @param {*} IdCaratteristica index of Caratteristica
     */
    updateNotaAggiuntiva(rowId, caratteristicaId, notaAggiuntiva) {
        var _serviziComposite = this.state.serviziComposite;
        const element = _serviziComposite[0].CategorieCaratteristica;
        for (let i = 0; i < element.length; i++) {
            if (element[i].CategoriaCaratteristicaId === rowId) {
                for (let j = 0; j < element[i].Caratteristica.length; j++) {
                    const caratteristica = element[i].Caratteristica[j];
                    if (caratteristica.Id === caratteristicaId) {
                        element[i].Caratteristica[j].NotaAggiuntiva = notaAggiuntiva;
                        this.setState({ serviziComposite: _serviziComposite });
                    }
                }

            }
        }
        //console.log('this.sate.serviziComposite:', this.state.serviziComposite);
    }

    /**
     * 
     * @param {*} event 
     */
    updateReferente(event) {
        let value = event.target.value;
        let serviziComposite = this.state.serviziComposite;
        serviziComposite[0].Referente = value;
        this.setState({ serviziComposite: serviziComposite });
        this.setState({ referente: value });
    }

    /**
     * 
     * @param {*} event 
     */
    updateStruttura(event) {
        let value = event.target.value;
        let serviziComposite = this.state.serviziComposite;
        serviziComposite[0].StrutturaReferente = value;
        this.setState({ serviziComposite: serviziComposite });
        this.setState({ struttura: value });
    }

    /**
     * 
     * @param {*} servizioTrasversaleId 
     */
    updateServizioTrasversale(servizioTrasversaleId) {
        var _serviziComposite = this.state.serviziComposite;
        const serviziTrasversali = _serviziComposite[0].ServiziTrasversaliPA;
        for (let i = 0; i < serviziTrasversali.length; i++) {
            if (serviziTrasversali[i].ServizioTrasversaleId === servizioTrasversaleId) {
                serviziTrasversali[i].Checked = !serviziTrasversali[i].Checked;
                this.setState({ serviziComposite: _serviziComposite }, () => this.saveDraft());
            }
        }
        //console.log('updateServizioTrasversale',this.state.serviziComposite)
    }

    /**
     * 
     * @param {*} servizioTrasversaleId 
     * @param {*} nota 
     */
    updateNotaServizioTrasversale(servizioTrasversaleId, nota) {
        var _serviziComposite = this.state.serviziComposite;
        const serviziTrasversali = _serviziComposite[0].ServiziTrasversaliPA;
        for (let i = 0; i < serviziTrasversali.length; i++) {
            if (serviziTrasversali[i].ServizioTrasversaleId === servizioTrasversaleId) {
                serviziTrasversali[i].NotaAggiuntiva = nota;
                this.setState({ serviziComposite: _serviziComposite });
            }
        }
    }

    isValidate() {
        try {
            let counter = [];
            let categorieCaratteristica = this.state.serviziComposite[0].CategorieCaratteristica;
            const categorieCaratteristicaLength = categorieCaratteristica.length;
            for (let index = 0; index < categorieCaratteristica.length; index++) {
                const caratteristiche = categorieCaratteristica[index].Caratteristica;
                for (let j = 0; j < caratteristiche.length; j++) {
                    const element = caratteristiche[j];
                    if (element.Checked) {
                        counter.push(element);
                        break;
                    }
                }

            }
            console.log('validate:', counter);
            return counter.length === categorieCaratteristicaLength;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
    * handle save button clicked
    */
    save(e) {
        const { serviziComposite } = this.state;
        serviziComposite[0]['Bozza'] = undefined;
        console.log('data', JSON.stringify(serviziComposite));
        if (this.isValidate()) {
            // submit form data to api
            $.ajax({
                //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
                url: API_CONFIG.serviziSaveOrUpdateUrl,
                type: "POST",
                contentType: 'application/json',
                headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(serviziComposite),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    if (response.status === 'success') {
                        history.push('/ListaServizi');
                    }
                    else {
                        //TODO
                    }
                },

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data },
                                () => $('#btnError').get(0).click()
                            );
                        }
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
        } else this.setState({ messageWarning: 'Prima di effettuare il Calcolo compilare tutte le Sezioni' }, () => $('#btnCalcolo').get(0).click());
        e.preventDefault();
    }

    saveDraft() {
        // data in the form
        const { serviziComposite } = this.state;
        serviziComposite[0]['Bozza'] = 'string';
        serviziComposite[0]['UpdateDataAggiornamento'] = Number(this.state.updateDate).toString();
        console.log('saveDraft', serviziComposite);
        // submit form data to api
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
            url: API_CONFIG.serviziSaveOrUpdateUrl,
            type: "POST",
            contentType: 'application/json',
            headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
            data: JSON.stringify(serviziComposite),
            cache: false,
            success: function (response) {
                //console.log(response);

                if (response.status === 'success') {
                    //console.log(response);
                }
                else this.setState({ messageError: 'Errore durante il salvataggio!' }, () => $('#btnError').get(0).click());
            },
            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
        //e.preventDefault();
    }

    /**
     * 
     * @param {*} e 
     */
    checkImpatto(e) {
        //nel caso di esistenza dell'impatto R-I-D

        let impatto = this.state.impattoMin;//impattoR-I-D > 0
        console.log('impatto', this.state.impattoMin);
        //alert(this.state.impattoMin);
        if (impatto > 0) {
            let msg = "Proseguendo con l'operazione si dovrà procedere di nuovo al calcolo dell'impatto RID nella fase di valutazione Impatti";
            this.setState({ messageWarning: msg }, () => $('#btnCheckImpatto').get(0).click());
        } else {
            this.save(e);
        }
    }

    /**
     * 
     */
    getImpattoMin(impattoR, impattoI, impattoD) {
        try {
            return Math.min(impattoR, impattoI, impattoD); // 1
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    getAllAuthentications() {
        var accordionList = [];
        for (let index = 0; index < this.state.checkAuth.length; index++) {
            accordionList.push(
                <div className="row" key={"accordionCategoriaAuth" + index}>
                    <div className="card-body">
                        <div className="col-lg-12">
                            <span><a className="testoNero" data-bs-toggle="collapse" href={"#accordionCategoriaAuth" + index}><strong>{this.state.checkAuth[index].description}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            {index !== (this.state.checkAuth.length - 1) && <hr />}
                        </div>
                    </div>
                </div>
            );
            //accordionList.push(<hr key={"hrCategoriaAuth" + index} />);

            let rightList = [];
            let leftList = [];

            for (let x = 0; x < this.state.checkAuth[index].lv1child.length; x++) {
                if ((x + 1) % 2 === 0) {
                    rightList.push(<div key={x + "auth" + index}>
                        <input
                            type="checkbox"
                            checked={this.state.checkAuth[index].lv1child[x].value}
                            onChange={(e) => this.updateAuth(this.state.checkAuth[index].lv1child[x].lv1id)}
                        /> &nbsp;{this.state.checkAuth[index].lv1child[x].description}
                        <hr />
                    </div>);
                }
                else {
                    leftList.push(<div key={x + "auth" + index}>
                        <input
                            type="checkbox"
                            checked={this.state.checkAuth[index].lv1child[x].value}
                            onChange={(e) => this.updateAuth(this.state.checkAuth[index].lv1child[x].lv1id)}
                        /> &nbsp;{this.state.checkAuth[index].lv1child[x].description}
                        <hr />
                    </div>);
                }
            }

            accordionList.push(
                <div key={"collapseAuth" + index} className="card-bodyPlus">
                    <div id={"accordionCategoriaAuth" + index} className="collapse spazio10" data-parent="#accordion">
                        <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                    </div>
                </div>);
        }

        return accordionList;
    }

    render() {

        var rows = '';
        if (!this.state.serviziComposite.length) {
            rows = <div className="row">Loading...</div>
        } else {
            //console.log('serviceComposite:',this.state.serviziComposite);
            rows = this.state.serviziComposite[0].CategorieCaratteristica.map(function (categorieCaratteristica, i) {
                return (

                    categorieCaratteristica.CategoriaCaratteristicaId === 2 ?
                        <ErogazioneRow
                            key={i}
                            rowId={i}
                            categoryName={categorieCaratteristica.Nome}
                            caratteristiche={categorieCaratteristica.Caratteristica}
                            categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                            updateCaratteristica={this.updateCaratteristica}
                            updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                            saveDraft={this.saveDraft}
                        />

                        :
                        categorieCaratteristica.CategoriaCaratteristicaId === 4 ?
                            <AuthenticationRow
                                key={i}
                                rowId={i}
                                categoryName={categorieCaratteristica.Nome}
                                caratteristiche={categorieCaratteristica.Caratteristica.sort(function (a, b) { return a.Id - b.Id })}
                                categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                                updateCaratteristica={this.updateCaratteristica}
                                updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                                saveDraft={this.saveDraft}
                            />

                            :

                            <CaratteristicaRow
                                key={i}
                                rowId={i}
                                categoryName={categorieCaratteristica.Nome}
                                caratteristiche={categorieCaratteristica.Caratteristica}
                                categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                                updateCaratteristica={this.updateCaratteristica}
                                updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                                saveDraft={this.saveDraft}
                            />
                );
            }.bind(this)
            );
        }

        let trasversalService = '';
        if (!this.state.serviziComposite.length) {
            //trasversalService = <div className="row">Loading...</div>
        } else {
            trasversalService = this.state.serviziComposite.map(function (serviziComposite, i) {
                //debugger
                return (
                    <ServiziTrasversaliRow
                        key={i}
                        rowId={this.state.serviziComposite[0].CategorieCaratteristica.length + 1}
                        trasversalServiceName={'Utilizzo Altri Servizi Trasversali Locali/Nazionali'}
                        trasversalServices={this.state.serviziComposite[0].ServiziTrasversaliPA}
                        updateServizioTrasversale={this.updateServizioTrasversale}
                        updateNotaServizioTrasversale={this.updateNotaServizioTrasversale}
                        saveDraft={this.saveDraft}
                    />
                );
            }.bind(this)
            );
        }




        let InfoIconHidden = (!((this.state.referente != null && this.state.referente.length > 0)
            && (this.state.struttura != null && this.state.struttura.length > 0))) ? "hidden" : "visible";

        let modalSuccess = <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Analisi del Rischio creata!'} />;
        let modalNoAction = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={this.state.messageWarning} />;
        let modalAction = <ModalDialog idTarget={'warningAction'} idBtn={'btnCheckImpatto'} handle={this.save} textModal={this.state.messageWarning} />;
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">
                        <div className="col-lg-12">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Modifica Caratteristiche del Servizio: {this.state.serviceName} </span>&nbsp;<i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco delle caratteristiche di sicurezza che l’utente è chiamato a compilare. <br />
                                Per salvare i dati e procedere con il calcolo del <strong>Profilo di Criticità </strong>è necessario premere il tasto “<strong>Calcolo Profilo di Criticità</strong>”.</p>


                            <span className="paRight InfoAgg">* selezionare almeno una caratteristica&nbsp; &nbsp;  <i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i> {this.isUpdated() && "servizio modificato"} </span>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-linkInfo" data-bs-toggle="collapse" href="#collapseInfo">
                                    <i style={{ visibility: InfoIconHidden }} className="testoVerde fa fa-check"></i> Informazioni generali<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>

                        <hr />

                        <div id="collapseInfo" className="col-lg-12 collapse" data-parent="#accordion">
                            <div className="row  card-headerLight">
                                <div className="col-lg-6">
                                    <strong>Referente</strong><br />
                                    <input type="text" className="form100"
                                        onChange={this.updateReferente}
                                        value={this.state.referente}
                                        onBlur={this.saveDraft}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <strong>Struttura</strong><br />
                                    <input type="text" className="form100"
                                        onChange={this.updateStruttura}
                                        value={this.state.struttura}
                                        onBlur={this.saveDraft}
                                    />
                                </div>
                                <div className="col-lg-6"><hr />
                                    <strong>Categorie</strong><br />
                                    {this.state.categorieToString}</div>
                                <div className="col-lg-6"><hr />
                                    <strong>Tipologie</strong><br />
                                    {this.state.tipologieToString}</div>
                                <div className="col-lg-12 ">
                                    <hr />  <p><strong>Descrizione</strong><br />
                                        {this.state.descrizione} </p>
                                </div>
                                <div className="col-lg-12 ">
                                    <div className="">
                                        <hr />

                                    </div></div>	</div>

                        </div>
                    </div>
                    <div className="container cnsContainer">

                        {rows}
                        {trasversalService}

                        {/* <div className="lineaGrigia"></div> */}

                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                            <div className="row spazio20">
                                <div className="col-lg-4 ">	</div>

                                <div className="col-lg-4">
                                    <button type="button" onClick={this.checkImpatto} className="btn btn-success btn-lg btn-block">Calcola Profilo di Criticità</button>
                                </div>
                                <div className="col-lg-4">	</div>

                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {/*idTarget = type modal */}
                {modalSuccess}
                {modalAction}
                {modalNoAction}
                {modalError}
            </div>
        )
    }
}