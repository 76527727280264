import * as React from 'react';
import history from '../history';
import { Util } from "../data/Util";

/**
 * 
 * @param {*} path 
*/
function redirect(path, userId) {
    try {
        let state = {
            some: 'state',
            path: path,
            userId: userId
        }
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push({
                    pathname: path,
                    state: state
                }
                );
            else if (path.indexOf('/') === -1) {
                history.push({
                    pathname: '/' + path,
                    state: state
                }
                );
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

// component that renders a single user
export class UserRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category
        };
        this.toString = this.toString.bind(this);
    }

    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            toString += list[index].Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    render() {
        let isUserLogged = (this.props.isUserLogged) ? 'hidden' : 'visible';
        return (
                <div>
                    <div className="row spazio20">
                        {/* 1a CodiceFiscale Utente */}
                        <div className="col-lg-2 font14">{this.props.user.CodiceFiscale}</div>
                        {/* 2a Cognome Utente */}
                        <div className="col-lg-2 font14 ">{this.props.user.Cognome}</div>
                        {/* 3a Nome Utente */}
                        <div className="col-lg-2 font14 ">{this.props.user.Nome}</div>
                        {/* 4a DataCreazione Utente */}
                        <div className="col-lg-2 font14" style={{textAlign:"center"}}>{this.toStringDate(this.props.user.DataCreazione).split(" ")[0]}</div>
                        {/* 5a DataUltimoAccesso Utente */}
                        <div className="col-lg-2 font14" style={{textAlign:"center"}}>{this.toStringDate(this.props.user.DataUltimoAccesso).split(" ")[0]}</div>
                        {/* 6a AZIONE Utente */}
                        <div className="col-lg-2 font14" style={{textAlign:"center"}}>
                            <a onClick={() => redirect('/ModificaUtente', this.props.user.CodiceFiscale)}
                                href="javascript:void(0)">
                                <i className="fa fa-pencil" title={"MODIFICA"}></i>
                            </a>&nbsp;
                            <a onClick={() => this.props.showModal(this.props.user)}
                                href="javascript:void(0);">
                                <i className="fa fa-trash" title={"RIMUOVI"} style={{ visibility: isUserLogged }}></i>
                            </a>
                        </div>
                    </div>
                    <hr style={{marginTop:0, marginBottom: 0}}/>                    
                </div> 
        );
    }
};