import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';
import { API_CONFIG } from '../../public/js/apiServices';
import { MisuraDiSicurezzaRow } from './MisuraDiSicurezzaRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { ChoiceModal } from '../Common/ChoiceModal';
import { LegendaTable } from '../Common/LegendaTable';
import { toStringStatoAssessmentId } from "../data/StatoAssessmentId";
import { Util } from "../data/Util";
import { AccordionInfo } from './AccordionInfo';

/**
 * 
 * @param {*} dataCreazione 
 */
function toStringDate(dataCreazione) {
    try {
        let dataSplit = dataCreazione.split('T')[0].split('-');
        let year = dataSplit[0];
        let month = dataSplit[1];
        let day = dataSplit[2];
        return [day + '-' + month + '-' + year, dataCreazione.split('T')[1].split('.')[0]];

    } catch (error) {
        return null;
    }
}

/**
 * @param {*} statoAssessment 
 */
function getStatoAssessmentId(statoAssessment) {
    let statoAssessmentInt = undefined;
    switch (statoAssessment) {
        case "CONSOLIDATO":
            statoAssessmentInt = 9;
            break;
        case "COMPLETATO":
            statoAssessmentInt = 3;
            break;
        case "DA_COMPLETARE":
            statoAssessmentInt = 2;
            break;
        default:
            break;
    }
    return statoAssessmentInt
}

const LINK = [
    { nome: "Compliance Self-Assessment", url: "/GapAnalysisModifica" },
    { nome: "Gap Analysis", url: "/ListaApplicazioniGapAnalysis" },
    { nome: "Self-Assessment", url: "/GapAnalysisModifica" }
];

const DonutChart = createClass({
    propTypes: {
        value: PropTypes.number,        // value the chart should show
        valuelabel: PropTypes.string,
        indicator: PropTypes.string, // label for the chart
        size: PropTypes.number,         // diameter of chart
        strokewidth: PropTypes.number   // width of chart line
    },
    getDefaultProps() {
        return {
            value: 0,
            valuelabel: '',
            size: 116,
            strokewidth: 7
        };
    },
    render() {

        const halfsize = (this.props.size * 0.5);
        const radius = halfsize - (this.props.strokewidth * 0.5);
        const circumference = 2 * Math.PI * radius;
        const strokeval = ((this.props.value * circumference) / 100);
        const dashval = (strokeval + ' ' + circumference);

        const trackstyle = { strokeWidth: this.props.strokewidth };
        const indicatorstyle = { strokeWidth: this.props.strokewidth, strokeDasharray: dashval }
        const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
        var value = isNaN(this.props.value) ? 0 : this.props.value;

        return (
            <svg width={this.props.size} height={this.props.size} className={"donutchart"}>
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className={this.props.indicator} />
                <text className="donutchart-text" x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }} >
                    <tspan className="donutchart-text-val">{value}</tspan>
                    <tspan className="donutchart-text-percent">%</tspan>
                    <tspan className="donutchart-text-label" x={halfsize} y={halfsize + 10}>{this.props.valuelabel}</tspan>
                </text>
            </svg>
        );
    }
})

export class AnalisiRischioModifica extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //serviceId:window.sessionStorage.getItem("serviceId"),
            criticità: ["", ""],
            dataCalcoloRischioAttuale: "",
            dataRischioAttuale: "",
            dataModifica: "",
            dataPubblicazione: "",
            impattoD: ["", ""],
            impattoI: ["", ""],
            impattoR: ["", ""],
            statoAssessmentId: "",
            serviceId: window.sessionStorage.getItem("serviceId"),
            serviceName: window.sessionStorage.getItem("serviceName"),
            serviceCriticità: window.sessionStorage.getItem("serviceCriticità"),
            serviceImpattoR: window.sessionStorage.getItem("serviceImpattoR"),
            listAssessment: [],
            selectedAssessment: undefined,
            assessmentQuestionario: [],
            readOnly: '',
            messageSuccess: '',
            messageWarning: '',
            messageError: '',
            /*import */
            isImport: false,
            assessmentImportList: [],
            domainsImportList: [],
            assessmentImportSelected: "",
            selectAlls: true
        }
        /*SAVE*/
        this.save = this.save.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        this.getAssessmentQuestionario = this.getAssessmentQuestionario.bind(this);
        /*UPDATE*/
        this.setInfo = this.setInfo.bind(this);
        this.updateNote = this.updateNote.bind(this);
        this.isValidate = this.isValidate.bind(this);
        this.updateGrade = this.updateGrade.bind(this);
        this.redirectReport = this.redirectReport.bind(this);
        this.isServiceUpdated = this.isServiceUpdated.bind(this);
        this.iniziaAssessment = this.iniziaAssessment.bind(this);
        this.updateAssessmentId = this.updateAssessmentId.bind(this);
        this.getCountsOfAnswers = this.getCountsOfAnswers.bind(this);
        this.dialogCopiaRisposte = this.dialogCopiaRisposte.bind(this);
        this.changeStateAssessment = this.changeStateAssessment.bind(this);
        this.getSumCounterWithAnswer = this.getSumCounterWithAnswer.bind(this);
        this.callChangeStateAssessment = this.callChangeStateAssessment.bind(this);
        /*IMPORT*/
        this.updateIsImport = this.updateIsImport.bind(this);
        this.getIndexOfHimself = this.getIndexOfHimself.bind(this);
        this.updateDomainImport = this.updateDomainImport.bind(this);
        this.domainsImportReset = this.domainsImportReset.bind(this);
        this.updateAssessmentImport = this.updateAssessmentImport.bind(this);
        this.getBodyRequestIniziaAssessment = this.getBodyRequestIniziaAssessment.bind(this);

    }

    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        this.getAssessmentQuestionario();
        this.getAssessmentServiziImport()

    }

    /**
     * 
     */
    getAssessmentQuestionario() {
        if (window.sessionStorage.getItem("serviceId")) {
            let serviceId = window.sessionStorage.getItem("serviceId");
            console.log('serviceId:', sessionStorage.getItem('serviceId'));
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.assessmentcompositeListAssessment,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: "{ \"ServizioId\": \"" + serviceId + "\"}",
                    cache: false,
                    success: function (response) {
                        console.log('listAssessment:', response);
                        if (response.data.length > 0) {
                            this.setState({ listAssessment: response.data });
                            this.setState({ selectedAssessment: this.state.listAssessment[0] });
                            let statoAssessmentInt = getStatoAssessmentId(this.state.listAssessment[0].StatoAssessmentId);
                            this.setState({ readOnly: (this.state.listAssessment[0].StatoAssessmentId > 3) });

                            $.ajax({
                                url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                                type: "POST",
                                contentType: 'application/json',
                                headers: { 'token': sessionStorage.getItem('token') },
                                data: "{ \"AssessmentId\":" + this.state.selectedAssessment.AssessmentId + "}",
                                cache: false,
                                success: function (response) {
                                    console.log('response:', response);
                                    this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                                    this.setInfo(response.data.Informazioni[0]);
                                    if ($(".statoCompilazione").length > 0)
                                        $(".statoCompilazione").get(0).click();
                                }.bind(this),

                                error: function (xhr, resp, text) {
                                    let messageError = "Si è verificato un errore ";
                                    console.error(xhr);
                                    //Token JWT Invalid
                                    if (xhr.responseJSON) {
                                        if (xhr.responseJSON.data === -1) {
                                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                            location.replace(API_CONFIG.HomePath);
                                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                                    }
                                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                                }.bind(this),
                            });
                        }

                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });
        }
    }

    /**
     * 
     * @param {*} grade 
     * @param {*} categoriaId 
     * @param {*} misuraDiSicurezzaId 
     */
    updateGrade(grade, categoriaId, misuraDiSicurezzaId) {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        try {
            for (let i = 0; i < assessmentQuestionario.length; i++) {
                if (assessmentQuestionario[i].CategoriaMisuraDiSicurezzaId === categoriaId) {
                    for (let j = 0; j < assessmentQuestionario[i].items.length; j++) {
                        const misuraDiSicurezza = assessmentQuestionario[i].items[j];
                        if (misuraDiSicurezza.MisuraDiSicurezzaId === misuraDiSicurezzaId) {
                            misuraDiSicurezza.GradoDiImplementazione = grade;
                            this.setState({ assessmentQuestionario: assessmentQuestionario });
                            this.setState({ assessmentQuestionario: assessmentQuestionario }, () => this.saveDraft());
                            //console.log('updateGarde', this.state.assessmentQuestionario);
                        }
                    }
                }
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     * @param {*} noteText 
     * @param {*} categoriaId 
     * @param {*} misuraDiSicurezzaId 
     */
    updateNote(noteText, categoriaId, misuraDiSicurezzaId) {
        //let par = { note: noteText, categoriaId: categoriaId, misuraDiSicurezzaId: misuraDiSicurezzaId };
        //console.log("updateNote", par);
        let assessmentQuestionario = this.state.assessmentQuestionario;
        try {
            for (let i = 0; i < assessmentQuestionario.length; i++) {
                if (assessmentQuestionario[i].CategoriaMisuraDiSicurezzaId === categoriaId) {
                    for (let j = 0; j < assessmentQuestionario[i].items.length; j++) {
                        const misuraDiSicurezza = assessmentQuestionario[i].items[j];
                        if (misuraDiSicurezza.MisuraDiSicurezzaId === misuraDiSicurezzaId) {
                            misuraDiSicurezza.Nota = noteText;
                            this.setState({ assessmentQuestionario: assessmentQuestionario });
                            //this.setState({ assessmentQuestionario: assessmentQuestionario }, () => this.saveDraft());
                            //console.log('misuraDiSicurezza', misuraDiSicurezza);
                        }
                    }
                }
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getCountsOfAnswers() {
        let assessmentQuestionario = this.state.assessmentQuestionario;
        let tot = 0;
        let totNonRisposto = [];
        let totAdeguato = [];
        let totAssente = [];
        let totCompleto = [];
        //countsOfAnswers['TotaleImplementate'] = 0;
        let totMigliorabile = [];
        let totNonApplicabile = [];
        let totNonSoRispondere = [];
        let counts = [];

        for (let index = 0; index < assessmentQuestionario.length; index++) {
            const element = assessmentQuestionario[index];
            tot += element.items.length;
            for (let j = 0; j < element.items.length; j++) {
                const item = element.items[j];

                switch (item.GradoDiImplementazione) {

                   
                    case -1:
                        totNonRisposto.push(item);
                        break;
                    case 1://Non Applicabile
                        totNonApplicabile.push(item);
                        break;
                    case 2://Non so rispondere
                        totNonSoRispondere.push(item);
                        break;
                    case 3://Assente
                        totAssente.push(item);
                        break;
                    case 4://Migliorabile
                        totMigliorabile.push(item);
                        break;
                    case 5://Adeguato
                        totAdeguato.push(item);
                        break;
                    case 6://Completo
                        totCompleto.push(item);
                        break;
                    default://
                        break;
                }
            }
        }
        counts['tot'] = tot;
        counts['totNonRisposto'] = totNonRisposto.length;
        counts['totNonApplicabile'] = totNonApplicabile.length;
        counts['totAssente'] = totAssente.length;
        counts['totMigliorabile'] = totMigliorabile.length;
        counts['totAdeguato'] = totAdeguato.length;
        counts['totCompleto'] = totCompleto.length;
        counts['totNonSoRispondere'] = totNonSoRispondere.length;
        return counts;
    }

    /**
     * 
     */
    getSumCounterWithAnswer() {
        let counts = this.getCountsOfAnswers();
        let sumCounterWithAnswer = 0;
        try {
            for (const key in counts) {
                if (counts.hasOwnProperty(key)) {
                    if (key !== 'tot' && key !== 'totNonRisposto') {
                        sumCounterWithAnswer += counts[key];
                    }
                }
            }
            return sumCounterWithAnswer;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    redirectReport() {
        window.sessionStorage.setItem("servizioId", window.sessionStorage.getItem("serviceId")); history.push('RiepilogoDatiApplicazione');
    }

    /**
     * 
     * @param {*} event 
     */
    updateAssessmentId(event) {
        let index = parseInt(event.target.value, 10);
        let listAssessment = this.state.listAssessment;
        let assessment = {
            id: listAssessment[index].AssessmentId,
            dataCreazione: listAssessment[index].DataCreazione
        }
        console.log('assessment', assessment);
        this.setState({ selectedAssessment: listAssessment[index] });
        $.ajax({
            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: "{ \"AssessmentId\":" + listAssessment[index].AssessmentId + "}",
            cache: false,
            success: function (response) {
                console.log('AssessmentQuestionario:', response);
                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                this.setInfo(response.data.Informazioni);
                let statoAssessmentInt = getStatoAssessmentId(listAssessment[index].StatoAssessmentId);
                this.setState({ readOnly: (listAssessment[index].StatoAssessmentId > 3) });
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }


    isValidate() {
        try {
            let counter = [];
            let categorieCaratteristica = this.state.serviziComposite[0].CategorieCaratteristica;
            const categorieCaratteristicaLength = categorieCaratteristica.length;
            for (let index = 0; index < categorieCaratteristica.length; index++) {
                const caratteristiche = categorieCaratteristica[index].Caratteristica;
                for (let j = 0; j < caratteristiche.length; j++) {
                    const element = caratteristiche[j];
                    if (element.Checked) {
                        counter.push(element);
                        break;
                    }
                }

            }
            console.log('validate:', counter);
            return counter.length === categorieCaratteristicaLength;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
    * handle save button clicked
    */
    save(e) {
        // data in the form
        if (this.getSumCounterWithAnswer() === this.getCountsOfAnswers()['tot']) {
            let assessmentQuestionario = this.state.assessmentQuestionario;
            var form_data = { 'Bozza': undefined, assessmentQuestionario };
            console.log('form_data', JSON.stringify(form_data));
            // submit form data to api
            $.ajax({
                //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
                url: API_CONFIG.assessmentcompositeSaveOrUpdate,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                //data: JSON.stringify(form_data),
                data: JSON.stringify(form_data),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    if (response.status === 'success') {
                        this.state.selectedAssessment.StatoAssessmentId = 3;
                        this.setState({ messageSuccess: 'Calcolo del GAP effettuato con successo!' });
                        $('#btnSuccessAction').get(0).click();
                        let dataCalcoloRischioAttuale = Util.toStringDate(new Date())
                        this.setState({ dataCalcoloRischioAttuale: dataCalcoloRischioAttuale["day"] + "/" + dataCalcoloRischioAttuale["month"] + "/" + dataCalcoloRischioAttuale["year"] });
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
        } else {
            this.setState({ messageWarning: 'Prima di effettuare il Calcolo compilare tutte le risposte!' }, () => $('#btnCalcolo').get(0).click());
        }

        e.preventDefault();
    }

    /**
     * 
     */
    saveDraft() {
        // data in the form
        let assessmentQuestionario = this.state.assessmentQuestionario;
        var form_data = { 'Bozza': 'string', assessmentQuestionario };
        console.log('saveDraft', JSON.stringify(form_data));
        // submit form data to api
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
            url: API_CONFIG.assessmentcompositeSaveOrUpdate,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            data: JSON.stringify(form_data),
            cache: false,
            success: function (response) {
                console.log(response);
                if (response.status === 'success') {
                    //console.log(response);
                    this.state.selectedAssessment.StatoAssessmentId = 2;
                    let dataModifica = Util.toStringDate(new Date())
                    this.setState({ dataModifica: dataModifica["day"] + "/" + dataModifica["month"] + "/" + dataModifica["year"] });
                }
                else {
                    this.setState({ messageError: 'Errore durante il salvataggio!' }, () => $('#btnError').get(0).click());
                }
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
        //e.preventDefault();
    }

    /**
     * 
     * @param {*} values 
     */
    calculate(values) {
        var sum = 0;
        var count = 0;
        var order = [];

        for (var i = 0; i < values.length; i++) {
            sum += parseInt(values[i], 10);
            //intVal.push (parseInt(values[i]));
            //decimalVal.push (values[i] - parseInt(values[i]));
            order[i] = i
        }
        var diff = 100 - sum;
        //decimalVal.sort(function(a, b){return b-a});
        order.sort(function (a, b) {
            var dVala = values[a] - parseInt(values[a], 10);
            var dValb = values[b] - parseInt(values[b], 10);
            return dValb - dVala
        });

        values.sort(function (a, b) {
            var dVala = a - parseInt(a, 10);
            var dValb = b - parseInt(b, 10);
            return dValb - dVala
        });
        for (var j = 0; j < values.length; j++) {
            count = j;
            if (count < diff) {
                values[j] = parseInt(values[j], 10) + 1;
            } else {
                values[j] = parseInt(values[j], 10);
            }
        }
        var unsorted = [];
        for (var k = 0; k < values.length; k++) {
            //values[order[i]] = data_x[i];
            unsorted[order[k]] = values[k];
        }
        return unsorted;
    }

    /**
     * 
     * @param {*} event 
     */
    dialogCopiaRisposte(event) {
        if (this.state.listAssessment.length > 0) {
            //this.setState({ messageChoice: "Importare i Gradi di Implementazione dall'analisi precedente?" })
            //$('#btnChoice').get(0).click();
            $('#btnHidden').get(0).click();
        } else {
            this.iniziaAssessment();
        }
    }

    /**
     * 
     * @param {*} copiaRisposte 
     */
    getBodyRequestIniziaAssessment(copiaRisposte) {
        var importRisposte = (copiaRisposte) ? '1' : '0';
        let serviceId = (window.sessionStorage.getItem("serviceId")) ? window.sessionStorage.getItem("serviceId") : "";
        //let bodyRequest = "{ \"ServizioId\": \"" + serviceId + "\"," + " \"ImportRisposte\":" + "\"" + importRisposte + "\" }";
        let bodyRequest = { ServizioId: serviceId, ImportRisposte: importRisposte };
        try {
            if (copiaRisposte) {
                if (this.state.assessmentImportSelected == this.state.assessmentImportList[this.getIndexOfHimself(this.state.assessmentImportList)].AssessmentId) {
                    bodyRequest = {
                        ServizioId: serviceId,
                        ImportRisposte: importRisposte,
                        DominiImport: this.getDomainsImportFilter()
                    }
                } else {
                    bodyRequest = {
                        ServizioId: serviceId,
                        ImportRisposte: importRisposte,
                        AssessmentIdImport: this.state.assessmentImportSelected,
                        DominiImport: this.getDomainsImportFilter()
                    }
                }
            }
            return JSON.stringify(bodyRequest);
        } catch (error) {
            console.error(error);
            return bodyRequest;
        }
    }

    /**
     * 
     * @param {*} copiaRisposte 
     */
    iniziaAssessment(copiaRisposte) {
        //var importRisposte = (copiaRisposte) ? '1' : '0';
        if (window.sessionStorage.getItem("serviceId")) {
            let serviceId = window.sessionStorage.getItem("serviceId");
            let bodyRequest = this.getBodyRequestIniziaAssessment(copiaRisposte);
            //console.log("iniziaAssessment", JSON.stringify(bodyRequest));
            this.serverRequest = $.ajax({
                url: API_CONFIG.assessmentcompositeIniziaAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: bodyRequest,
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    //console.log('AssessmentQuestionario:', response);
                    if (response.status === 'success') {
                        this.serverRequest =
                            $.ajax({
                                url: API_CONFIG.assessmentcompositeListAssessment,
                                type: "POST",
                                contentType: 'application/json',
                                headers: { 'token': sessionStorage.getItem('token') },
                                data: "{ \"ServizioId\": \"" + serviceId + "\"}",
                                cache: false,
                                success: function (response) {
                                    console.log('listAssessment:', response);
                                    if (response.data.length > 0) {
                                        this.setState({ listAssessment: response.data });
                                        this.setState({ selectedAssessment: this.state.listAssessment[0] });
                                        let statoAssessmentInt = getStatoAssessmentId(this.state.listAssessment[0].StatoAssessmentId);
                                        this.setState({ readOnly: (this.state.listAssessment[0].StatoAssessmentId > 3) });
                                        $.ajax({
                                            url: API_CONFIG.assessmentcompositeGetAssessmentQuestionario,
                                            type: "POST",
                                            contentType: 'application/json',
                                            headers: { 'token': sessionStorage.getItem('token') },
                                            data: "{ \"AssessmentId\":" + this.state.selectedAssessment.AssessmentId + "}",
                                            cache: false,
                                            success: function (response) {
                                                //console.log('AssessmentQuestionario:', response);
                                                this.setState({ assessmentQuestionario: response.data.CategoriaMisuraDiSicurezza });
                                                this.setInfo(response.data.Informazioni);
                                                this.setState({ messageSuccess: "Questionario di GAP Analisys creato" }, () => $('#btnSuccess').get(0).click());
                                                this.domainsImportReset();
                                            }.bind(this),

                                            error: function (xhr, resp, text) {
                                                let messageError = "Si è verificato un errore ";
                                                console.error(xhr);
                                                //Token JWT Invalid
                                                if (xhr.responseJSON) {
                                                    if (xhr.responseJSON.data === -1) {
                                                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                                        location.replace(API_CONFIG.HomePath);
                                                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                                                }
                                                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                                            }.bind(this),
                                        });
                                    }

                                }.bind(this),

                                error: function (xhr, resp, text) {
                                    let messageError = "Si è verificato un errore ";
                                    console.error(xhr);
                                    //Token JWT Invalid
                                    if (xhr.responseJSON) {
                                        if (xhr.responseJSON.data === -1) {
                                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                            location.replace(API_CONFIG.HomePath);
                                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                                    }
                                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                                }.bind(this),
                            });

                    } else {
                        //
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
        }
        this.setState({ isImport: false });
    }

    /**
     * 
     */
    callChangeStateAssessment() {
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.cambiaStatoAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: "{ \"AssessmentId\": \"" + this.state.selectedAssessment.AssessmentId + "\"," + " \"Stato\":" + "\"" + parseInt(this.state.selectedAssessment.StatoAssessmentId + 1) + "\" }",
                cache: false,
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                        this.setState({ messageSuccess: "Operazione effettuata con successo!" });
                        $('#btnSuccess').get(0).click();
                        this.setState({ readOnly: true });
                        this.getAssessmentQuestionario();
                    }
                    else {
                        //alert(response.status);
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
    }

    /**
     * 
     */
    changeStateAssessment() {
        console.log('selectedAssessment', this.state.selectedAssessment);
        try {
            if (this.state.selectedAssessment.StatoAssessmentId) {
                let statoAssessmentInt = this.state.selectedAssessment.StatoAssessmentId;
                if (statoAssessmentInt === 3) {
                    $('#btnPubblica').get(0).click();
                } else {
                    this.setState({ messageWarning: "Per procedere al consolidamento è necessario effettuare il Calcolo del GAP." }
                        , () => $('#btnCalcolo').get(0).click()
                    );
                    return;
                }
            } else {
                return console.error('this.state.selectedAssessment.StatoAssessmentId is null or undefined');
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    isServiceUpdated() {
        try {
            let tsDataAggiornamento = new Date(this.state.selectedAssessment.DataModifica).getTime();
            let tsDataCalcoloCriticità = new Date(this.state.selectedAssessment.DataCalcoloRischioAttuale).getTime();

            return tsDataAggiornamento > tsDataCalcoloCriticità;
        } catch (error) {
            return false;
        }
    }


    /**
     * 
     * @param {*} dataInformazioni :response.data.Informazioni[0]
     */
    setInfo(dataInformazioni) {
        try {
            this.setState({ criticità: Util.toStringCriticità(dataInformazioni.Criticità) });
            //
            let dataCalcoloRischioAttuale = Util.toStringDate(dataInformazioni.DataCalcoloRischioAttuale);
            dataCalcoloRischioAttuale = dataCalcoloRischioAttuale["day"] + "/" + dataCalcoloRischioAttuale["month"] + "/" + dataCalcoloRischioAttuale["year"] + " " + dataCalcoloRischioAttuale["hours"] + ":" + dataCalcoloRischioAttuale["minutes"] + ":" + dataCalcoloRischioAttuale["seconds"];
            //
            this.setState({ dataRischioAttuale: dataCalcoloRischioAttuale });
            //
            let dataPubblicazione = Util.toStringDate(dataInformazioni.DataPubblicazione);
            dataPubblicazione = dataPubblicazione["day"] + "/" + dataPubblicazione["month"] + "/" + dataPubblicazione["year"] + " " + dataPubblicazione["hours"] + ":" + dataPubblicazione["minutes"] + ":" + dataPubblicazione["seconds"];
            this.setState({ dataPubblicazione: dataPubblicazione });
            //
            let dataModifica = Util.toStringDate(dataInformazioni.DataModifica)
            dataModifica = dataModifica["day"] + "/" + dataModifica["month"] + "/" + dataModifica["year"] + " " + dataModifica["hours"] + ":" + dataModifica["minutes"] + ":" + dataModifica["seconds"];
            this.setState({ dataModifica: dataModifica });
            //this.setState({ dataCalcoloRischioAttuale: dataInformazioni.DataCalcoloRischioAttuale });
            this.setState({ impattoD: Util.toStringCriticità(dataInformazioni.ImpattoD) });
            this.setState({ impattoI: Util.toStringCriticità(dataInformazioni.ImpattoI) });
            this.setState({ impattoR: Util.toStringCriticità(dataInformazioni.ImpattoR) });
            this.setState({ statoAssessmentId: toStringStatoAssessmentId(dataInformazioni.StatoAssessmentId) });
            //debugger
        } catch (error) {
            return console.error(error);
        }
    }

    getAssessmentServiziImport() {
        this.serverRequest =
            $.ajax({
                //url: "https://jsonplaceholder.typicode.com/users",
                //type: 'GET',
                type: 'POST',
                url: API_CONFIG.getAssessmentServiziImport,
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                //data: "{ \"ServizioId\": \"Test01\"}",
                data: JSON.stringify({ ServizioId: this.state.serviceId }),
                cache: false,
                success: function (response) {
                    if (response.data) {
                        if (response.data.AssessmentServizi) {
                            this.setState({ assessmentImportList: response.data.AssessmentServizi.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); }) },
                                () => console.log("assessmentImportList", this.state.assessmentImportList)
                            );
                            let indexOfHimself = this.getIndexOfHimself(response.data.AssessmentServizi);
                            if (indexOfHimself != -1) {
                                this.setState({ assessmentImportSelected: response.data.AssessmentServizi[indexOfHimself].AssessmentId });
                            }
                            else this.setState({ assessmentImportSelected: response.data.AssessmentServizi[0].AssessmentId });
                        }
                        if (response.data.Domini) {
                            this.setState({ domainsImportList: response.data.Domini.map(d => ({ ...d, isChecked: true })) }
                                , () => console.log("domainsImportList", this.state.domainsImportList)
                            );
                        }
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    console.error(xhr);
                    //Token JWT Invalid
                }
            });
    }

    /**
         * 
         */
    _getDomainsImportList() {
        let domainsImportList = [];
        try {
            let index = this.state.assessmentImportList.findIndex(a => a.AssessmentId == this.state.assessmentImportSelected);
            if (index != -1) {
                domainsImportList = this.state.assessmentImportList[index].domini.map(obj => ({ ...obj, isChecked: true }));
            }
            return domainsImportList;

        } catch (error) {
            console.error(error);
            return [];
        }
    }

    /**
  * 
  * @param {*} assessmentId 
  */
    updateAssessmentImport(assessmentId) {
        try {
            let index = this.state.assessmentImportList.findIndex(a => a.AssessmentId == assessmentId);
            if (index != -1) {
                this.setState({ assessmentImportSelected: assessmentId });
                /*
                 let domainsImportList = this.state.assessmentImportList[index].domini.map(obj => ({ ...obj, isChecked: true }));
                 this.setState({ domainsImportList: domainsImportList });
                 this.setState({ selectAlls: true });
              */
            }
        } catch (error) {
            return console.error(error);
        }
    }


    /**
     * 
     * @param {*} domainId 
     * @param {*} isChecked 
     */
    updateDomainImport(domain, isChecked) {
        let parameters = {
            isChecked: isChecked,
            domain: domain,
            assessmentId: this.state.assessmentImportSelected
        }
        let domainsImportList = this.state.domainsImportList;
        try {
            if (domain == -1) {
                domainsImportList = domainsImportList.map(obj => ({ ...obj, isChecked: isChecked }));
                this.setState({ selectAlls: isChecked });
            } else {
                let index = domainsImportList.findIndex(d => d.CategoriaMisuraDiSicurezzaId == domain.CategoriaMisuraDiSicurezzaId)
                if (index != -1) {
                    domainsImportList[index].isChecked = isChecked;
                }
            }
            this.setState({ domainsImportList: domainsImportList }
                //, () => console.log("updateDomain", this.state.domainsImportList)
            );

        } catch (error) {
            console.error(error);
            return;
        }
    }

    /**
     * 
     */
    getDomainsImportFilter() {
        let domainsImportFilter = this.state.domainsImportList.filter(d => d.isChecked == true).map(d => d.CategoriaMisuraDiSicurezzaId);
        //console.log("filter", domainsImportFilter);
        return domainsImportFilter;
    }

    /**
    * 
    */
    choiceAssessment(event) {
        $('#btnHidden').get(0).click();
    }


    /**
     * 
     * @param {*} assessmentImportList 
     * @returns the index Of himself service
     */
    getIndexOfHimself(assessmentImportList) {
        try {
            if (assessmentImportList) {
                return assessmentImportList.findIndex(a => a.Nome.localeCompare(this.state.serviceName) == 0);
            }
        } catch (error) {
            console.error(error);
            return -1;
        }
    }

    /**
     * 
     * @param {*} value 
     */
    updateIsImport(value) {
        this.setState({ isImport: value });
        if (!value) {
            this.domainsImportReset();
        }
    }

    /**
     * 
     */
    domainsImportReset() {
        this.setState({
            domainsImportList: this.state.domainsImportList.map(d => { return { ...d, isChecked: true } })
            , selectAlls: true
        });
    }

    render() {
        const COUNTS = this.getCountsOfAnswers();
        var values = [];
        //values[0] = COUNTS['Totale'] / COUNTS['Totale'] * 100;
        //values[3] = COUNTS['TotaleImplementate'] / COUNTS['Totale'] * 100;
        values[0] = COUNTS['totNonApplicabile'] / COUNTS['tot'] * 100;
        values[1] = COUNTS['totAssente'] / COUNTS['tot'] * 100;
        values[2] = COUNTS['totMigliorabile'] / COUNTS['tot'] * 100;
        values[3] = COUNTS['totAdeguato'] / COUNTS['tot'] * 100;
        values[4] = COUNTS['totCompleto'] / COUNTS['tot'] * 100;
        values[5] = COUNTS['totNonSoRispondere'] / COUNTS['tot'] * 100;
        values[6] = COUNTS['totNonRisposto'] / COUNTS['tot'] * 100;

        values[7] = ((
            COUNTS['tot'] -
            COUNTS['totAdeguato'] -
            COUNTS['totAssente'] -
            COUNTS['totCompleto'] -
            //COUNTS['TotaleImplementate'] -
            COUNTS['totMigliorabile'] -
            COUNTS['totNonApplicabile'] -
            COUNTS['totNonRisposto'] -
            COUNTS['totNonSoRispondere']) /
            COUNTS['tot']) * 100;

        const RISPOSTE = this.calculate(values);
        const answer = RISPOSTE[0] + RISPOSTE[1] + RISPOSTE[2] + RISPOSTE[3] + RISPOSTE[4] + RISPOSTE[5];
        const SENZA_RISPOSTA = 100 - answer;
        //answer.reduce(getSum);

        var rows = '';
        if (this.state.assessmentQuestionario.length === 0) {
            rows = <div className="row">Loading...</div>
        } else {
            rows = this.state.assessmentQuestionario.map(function (assessment, i) {
                return (
                    <MisuraDiSicurezzaRow
                        key={i}
                        rowId={i}
                        items={assessment.items}
                        readOnly={this.state.readOnly}
                        saveDraft={this.saveDraft}
                        updateNote={this.updateNote}
                        updateGrade={this.updateGrade}
                        categoriaId={assessment.CategoriaMisuraDiSicurezzaId}
                        misuraDiSicurezzaNome={assessment.CategoriaMisuraDiSicurezzaNome}
                    />
                );
            }.bind(this)
            );
        }

        let container = '';
        let testo = '';
        let dataCreazione = '';
        let dataAggiornamentoContesto = '';
        if (this.state.selectedAssessment !== undefined) {
            dataCreazione = toStringDate(this.state.selectedAssessment.DataCreazione);
            dataAggiornamentoContesto = toStringDate(this.state.selectedAssessment.DataAggiornamentoContesto);
            if (dataAggiornamentoContesto === null)
                dataAggiornamentoContesto = ["-", "-"];
        }
        if (this.state.selectedAssessment === undefined) {
            container = <div>Nessuna Gap Analysis Selezionata</div>
        } else if (this.state.selectedAssessment !== undefined) {
            testo = "Analisi del rischio effettuata a partire dall'Analisi del contesto e dalla Valutazione degli impatti del " + dataAggiornamentoContesto[0] + ' ' + dataAggiornamentoContesto[1] + ". Per effettuare il calcolo del GAP rispondere a tutte le domande del Questionario";

            container =
                <div>
                    <AccordionInfo
                        statoAssessmentId={this.state.statoAssessmentId}
                        criticità={this.state.criticità}
                        impattoR={this.state.impattoR}
                        impattoI={this.state.impattoI}
                        impattoD={this.state.impattoD}
                        dataCalcoloRischioAttuale={this.state.dataRischioAttuale}
                        dataPubblicazione={this.state.dataPubblicazione}
                        dataModifica={this.state.dataModifica}
                        iuc = {this.state.selectedAssessment.RischioAggregato}
                    />
                    {/***/}
                    <div className="row">
                        <div className="col-lg-6 card-headerInfo">
                            <a className="card-linkInfo marginLeft" data-bs-toggle="collapse" href="#collapse">
                                Stato compilazione</a>
                        </div>
                        <div className="col-lg-5 card-header text-right ">
                            {this.getSumCounterWithAnswer() + '/' + this.getCountsOfAnswers()['tot']}</div>
                        <div className="col-lg-1 card-headerInfo">
                            <a className="card-linkInfo marginLeft statoCompilazione" data-bs-toggle="collapse" href="#collapse">
                                <i className="iconRight fa fa-chevron-down"></i>
                            </a>
                        </div>

                    </div>
                    <div id="collapse" className="collapse marginLeft" data-parent="#accordion">
                        <div className="row spazio10">

                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	COMPLETO<br />
                                <DonutChart value={RISPOSTE[4]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">{RISPOSTE[4] + '% - ' + this.getCountsOfAnswers()['totCompleto'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ADEGUATO<br />
                                <DonutChart value={RISPOSTE[3]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[3] + '% - ' + this.getCountsOfAnswers()['totAdeguato'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	MIGLIORABILE<br />
                                <DonutChart value={RISPOSTE[2]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[2] + '% - ' + this.getCountsOfAnswers()['totMigliorabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	ASSENTE<br />
                                <DonutChart value={RISPOSTE[1]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[1] + '% - ' + this.getCountsOfAnswers()['totAssente'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON APPLICABILE<br />
                                <DonutChart value={RISPOSTE[0]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent"> {RISPOSTE[0] + '% - ' + this.getCountsOfAnswers()['totNonApplicabile'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                            <div className="col-lg-2 testoBlu" style={{ textAlign: "center" }}>	NON SO<br />
                                <DonutChart value={RISPOSTE[5]} size={100} indicator="donutchart-indicator" />
                                {/* <br /><span className="percent">  {RISPOSTE[5] + '% - ' + this.getCountsOfAnswers()['totNonSoRispondere'] + '/' + this.getCountsOfAnswers()['tot']}	</span> */}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="spazio10"></div>
                    {this.state.selectedAssessment !== undefined && rows}
                    {this.state.selectedAssessment !== undefined &&
                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%' }}>
                            <div className="row spazio20">
                                <div className="col-lg-3">	</div>
                                <div className="col-lg-3">
                                    <button type="button"
                                        onClick={this.save}
                                        hidden={this.state.readOnly}
                                        className="btn btn-success btn-lg btn-block">Calcola GAP</button>
                                </div>
                                <div className="col-lg-3">
                                    <button type="button"
                                        onClick={this.changeStateAssessment}
                                        hidden={this.state.readOnly}
                                        className="btn btn-success btn-lg btn-block">PUBBLICA</button>
                                </div>

                                <div className="col-lg-3">	</div>

                            </div>
                        </div>
                    }
                </div>
        }

        let textCopiaRisposte = "Si desidera importare i dati da una precedente GAP analisi?";
        let textPubblica = "Dopo il consolidamento non si potrà più ricalcolare il GAP. Procedere?";
        let assessmentOption = this.state.listAssessment.map(function (item, i) {
            var data = toStringDate(item.DataCreazione);
            return (
                //<option key={i} value={item.AssessmentId}>{toStringDate(item.DataCreazione)}</option>
                <option key={i} value={i}>{data[0] + ' ' + data[1]}</option>
            );
        });

        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-8">

                            {/*  <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" onClick={() => history.push(API_CONFIG.HomePath)} href="javascript:void(0)">Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" onClick={() => history.push('/AnalisiRischio')} href="javascript:void(0)">Analisi del rischio</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" onClick={() => history.push('/ListaServiziAnalisiRischio')} href="javascript:void(0)">Questionario per servizio</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" href="javascript:void(0);">Compila Servizio</a></li>
                                </ul>
                            </nav>
                            */}
                            <Breadcrumb links={LINK} />

                            <span className="titoloPagina">Self-Assessment Applicazione: {this.state.serviceName}</span>
                            &nbsp;<i hidden={!this.isServiceUpdated()} className="fa fa-warning testoRosso"></i>
                        </div>

                        <div className="col-lg-2"></div>

                        <div className="col-lg-2">
                            <span>STORICO ANALISI</span>
                            <select className="form100"
                                defaultValue={-1}
                                onChange={this.updateAssessmentId}>
                                {assessmentOption}
                            </select>

                            {/* { this.state.selectedAssessment !== undefined && <span>
                            Data Creazione<br />{dataCreazione[0]}<br />{dataCreazione[1]}</span>} */}
                            <div className="spazio10"></div>
                            <a>
                                <button type="button"
                                    
                                    data-bs-toggle="modal" data-bs-target="#exampleModal"

                                    className="btn btn-warning btn-lg btn-block">NUOVA ANALISI</button>
                            </a>
                            {/* <!-- Modal --> */}
                            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false" >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header ">
                                            <h2 className="modal-title testoRosso" id="exampleModalLabel"><i className="fa fa-warning"></i>
                                                {" AVVISO"}</h2>
                                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            Stai creando una nuova analisi: l'eventuale analisi precedente verrà chiusa  </div>
                                        <div className="modal-footerRed justify-content-center">
                                            <a href="#">
                                                <button type="button" className="btn btn-danger btn-lg" data-bs-dismiss="modal">ANNULLA</button>{" "}
                                                <button type="button" className="btn btn-successOR btn-lg" data-bs-dismiss="modal" onClick={this.dialogCopiaRisposte}>CONFERMA</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco dei requisiti di sicurezza e privacy a cui l’utente deve dare un riscontro selezionando dall'elenco proposto dal sistema, lo stato di attuazione attuale.</p>
                            {/* <p>{testo}</p> */}
                            
                            <span className="paRight InfoAgg"><a data-bs-toggle="collapse" href="#collapseLegenda"><i className="fa fa-question testoBlu"></i> Legenda</a></span>

                            <div id="collapseLegenda" className="collapse font14bis" data-parent="#accordion">
                                <LegendaTable widthCol1={"12%"} widthCol2={"84%"} bordercolor="#ccc" cellPadding="5" cellSpacing="5" />
                            </div>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>
                    {container}

                </div>
                <ModalDialog idTarget={'successAction'} idBtn={'btnSuccessAction'} textModal={this.state.messageSuccess} handleOK={this.redirectReport} />
                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.messageSuccess} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={this.state.messageWarning} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnPubblica'} handle={this.callChangeStateAssessment} textModal={textPubblica} />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                <ModalDialog idTarget={'choice'} idBtn={'btnChoice'} handle={this.iniziaAssessment} textModal={textCopiaRisposte} />
                <br />
                <ChoiceModal idTarget={'warningAction'} idBtn={'btnHidden'}
                    assessmentImportList={this.state.assessmentImportList}
                    domainsImportList={this.state.domainsImportList}
                    assessmentImportSelected={this.state.assessmentImportSelected}
                    updateDomainImport={this.updateDomainImport}
                    updateAssessmentImport={this.updateAssessmentImport}
                    selectAlls={this.state.selectAlls}
                    indexOfHimself={this.getIndexOfHimself(this.state.assessmentImportList)}
                    isImport={this.state.isImport}
                    iniziaAssessment={this.iniziaAssessment}
                    updateIsImport={this.updateIsImport}
                    domainsImportReset={this.domainsImportReset}
                />
                <br />
                <br />
            </div>

        )
    }
}