import High from '../../public/images/alto.png';
import Mid from '../../public/images/medio.png';
import Low from '../../public/images/basso.png';

var ICON = [];
ICON[-2] = { src: High, label: '', name: "" };
ICON[-1] = { src: Mid, label: '', name: "" };
ICON[0] = { src: '', label: '', name: "" };
ICON[1] = { src: Low, label: 'Basso', name: "" };
ICON[2] = { src: Mid, label: 'Medio', name: "" };
ICON[3] = { src: High, label: 'Alto', name: "" };
export default ICON;

