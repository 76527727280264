import React from 'react'
import $ from "jquery";
import { ModalDialog } from '../Common/ModalDialog';
import { API_CONFIG } from '../../public/js/apiServices';
import { ImportaDatiComponent } from './ImportaDatiComponent';

const LINK = [
    { nome: "Importazione Dati", url: "/ImportaDati" },
];

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

export class ImportaDati extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            successMessage: "",
            warningMessage: "",
            errorMessage: ""
        }
        this.showModal = this.showModal.bind(this);
    }

    /**
     * 
     * @param {*} text of the message into the modal
     * @param {*} type of the modal
     */
    showModal(text, type) {
        if (text.indexOf("'") === 0) {
            text = text.replace(/'/g, "");
        }
        switch (type) {
            case 'success':
                this.setState({ successMessage: text }, () => $('#btnSuccess').get(0).click());
                break;
            case 'warning':
                this.setState({ warningMessage: text }, () => $('#btnValidate').get(0).click());
                break;
            case 'error':
                this.setState({ errorMessage: text }, () => $('#btnError').get(0).click());
                break;
            default:
                break;
        }
    }

    render() {

        let modalSuccess = <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.successMessage} />;
        let modalWaring = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.warningMessage} />
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.errorMessage} />;

        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";

        return (
            <div className={outerClass}>

                <div className={outerClassContainer}>

                    <div className="row">

                        <div className="col-lg-12">
                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Importazione Dati</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">Importazione Dati</span>
                        </div>
                    </div>
                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>
                                    La pagina consente di selezionare il tipo e il file da importare sul sistema.   
                                </p>
                                <hr />
                            </div>
                        </div>
                    </div>
                    <br />
                    <ImportaDatiComponent showModal={this.showModal} />
                </div>
                {modalSuccess}
                {modalWaring}
                {modalError}
                <br />
            </div>
        )
    }
}