import * as React from 'react';
import GRADE from '../data/GradoDiImplementazione';
import LIVELLO from "../data/criticality";
import { isNullOrUndefined } from "util"

// component that renders a single caratteristica row
export class GradoImplementazioneDominiRow extends React.Component {

    constructor(props) {
        super(props);

        //this.toStringItem=this.toStringItem.bind(this);
    }

    /**
     * 
     * @param {*} indexVulnerabilità 
     */
    getVulnerabilità(indexVulnerabilità) {
        //let indexVulnerabilità = this.props.distribuzioneDominio[index].Vulnerabilità;
        return (!isNullOrUndefined(indexVulnerabilità) && (indexVulnerabilità >= 0) && (indexVulnerabilità < LIVELLO.length)) ? LIVELLO[indexVulnerabilità].name : "";
    }

    /**
     * 
     * @param {*} indexImplementazione 
     */
    getImplementazione(indexImplementazione) {
        indexImplementazione = Math.round(indexImplementazione);
        try {
            let grade = (!isNullOrUndefined(indexImplementazione) && (indexImplementazione >= 0) && (indexImplementazione < GRADE.length)) ? GRADE[indexImplementazione] : "";
            //console.log("implementazione", grade);
            return grade;
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    render() {
        let rows = [];
        let collapsedRows = [];
        for (let index = 0; index < this.props.distribuzioneDominio.length; index++) {
            //console.log("key", "MisuraDiSicurezzaId" + this.props.distribuzioneDominio[index].CategoriaMisuraDiSicurezzaId + this.props.index + index);

            const element =
                <div className="row" id={"MisuraDiSicurezzaId" + this.props.distribuzioneDominio[index].CategoriaMisuraDiSicurezzaId + index} key={"MisuraDiSicurezzaId" + this.props.distribuzioneDominio[index].CategoriaMisuraDiSicurezzaId + index}>
                    {/*Colonna Dominio*/}
                    <div className="col-lg-4">{this.props.distribuzioneDominio[index].Nome}</div>
                    {/*Colonna Misure*/}
                    <div className="col-lg-2 text-center">{this.props.distribuzioneDominio[index].TotaleRisposto}</div>
                    {/*Colonna Implementazione*/}
                    <div className="col-lg-4 text-center">
                        {this.getImplementazione(this.props.distribuzioneDominio[index].Implementazione)}
                    </div>
                    {/*Colonna Vulnerabilità*/}
                    <div className="col-lg-2 text-center">
                        {this.getVulnerabilità(this.props.distribuzioneDominio[index].Vulnerabilità)}
                    </div>
                </div>
            if (index < 5) {
                rows.push(element);
            } else {

                collapsedRows.push(element);
            }
        }
        let collapsedElement =
            <div className="row " key={"collapsed" + this.props.distribuzioneDominio.CategoriaMisuraDiSicurezzaId}>
                <div className="col-lg-12 card-headerT">
                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse333" aria-expanded="false">
                        <span className="if-collapsed">Vedi tutti <i className="paRight fa fa-angle-down faBold"></i></span>
                        <span className="if-not-collapsed">Riduci <i className="paRight fa fa-angle-up faBold"></i></span>
                    </a>
                </div>
            </div>
        rows.push(
            <div id="collapse333" data-parent="#accordion" className="giustifica collapse" key={"accordion" + this.props.distribuzioneDominio.CategoriaMisuraDiSicurezzaId}>
                {collapsedRows}
            </div>
        );
        rows.push(collapsedElement);
        return (
            <div>
                {rows}
            </div>
        );
    }
};