import * as React from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';
/*
import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "", url: "/" }
];
*/

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

// component that renders a single caratteristica row
export class Monitoraggio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        //this.getCounterAnsewers = this.getCounterAnsewers.bind(this);
        //this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
    }

    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/Monitoraggio')}>Monitoraggio</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Monitoraggio</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>La fase di Monitoraggio del Piano di Trattamento del Rischio ha lo scopo di  tenere sotto controllo lo stato di avanzamento dell’applicazione delle azioni di trattamento legate alle minacce da mitigare identificate in fase di Risk Assessment, al fine  garantire un presidio costante sulle modifiche al contesto ed eventualmente controllare le variazioni nella impostazione della data di termine delle attività pianificate.</p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ElencoServiziMonitoraggio')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">MONIT. PER SERVIZIO</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            Accesso alla lista dei piani di trattamento per i servizi della  Azienda. Selezionando l’elemento di interesse sarà possibile accedere alla sezione di “Monitoraggio PTR.
                            <hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/MonitoraggioPA')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">MONIT. PER  Azienda</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            Sarà possibile accedere alla sezione di “Monitoraggio PTR” relative alla Azienda corrente.
		                    <hr />
                            </div>   

                        </div></div>







                </div>







            </div>

        )
    }
}

