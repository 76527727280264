import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { ModalDialog } from '../Common/ModalDialog';
import { Util } from "../data/Util";

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}

function getDate(date){
    if (date) {
        try {
            let toStringDateCreazione = Util.toStringDate(date);
            return toStringDateCreazione.year
            + "-" + toStringDateCreazione.month+
            "-" + toStringDateCreazione.day
        } catch (error) {
            console.error(error);
            return "";
        }
    } else return "";
}

export class Planning extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listaRemediation: [],
            serviceName: props.location.state.serviceName,
            nomePA: (this.props.PA) ? this.getUsersFromAccessToken().NomePA : "",
            emptyTest: "Loading...",
            messageWarning: '',
            messageSuccess: '',
            messageError: '',
            statoAssessment: props.location.state.statoAssessment,
            assessmentId: props.location.state.assessmentId,
            users: [],
            roadmap: []
        };

        this.toString = this.toString.bind(this);
        //UPDATE
        this.updateData = this.updateData.bind(this);
        this.updateRemediation = this.updateRemediation.bind(this);

        this.updadateCheckedRow = this.updadateCheckedRow.bind(this);
        this.changeStateAssessment = this.changeStateAssessment.bind(this);
        this.callCambiaStato = this.callCambiaStato.bind(this);
        this.canChangeStateAssessment = this.canChangeStateAssessment.bind(this);
        this.opzioniComplete = this.opzioniComplete.bind(this);
        this.callAzioni = this.callAzioni.bind(this);
        this.getAllUsers = this.getAllUsers.bind(this);
        this.getRoadmap = this.getRoadmap.bind(this);
        this.redirect = this.redirect.bind(this);
        //SET
        this.setNomePA = this.setNomePA.bind(this);
        //SAVE
        this.save = this.save.bind(this);
        this.execute = this.execute.bind(this);
        this.saveRoadmap = this.saveRoadmap.bind(this);
    }

    /**
     * 
     */
    opzioniComplete() {
        var lista = this.state.opzioni;
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if (
                ((element.OpzioneTrattamento !== null &&
                    element.OpzioneTrattamento !== element.OpzioneTrattamentoDefault) ||
                    element.RiskAppetite !== element.RiskAppetiteDefault) &&
                (element.PersonaIntervistato === null ||
                    element.Motivazione === null ||
                    element.Motivazione === "")
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * 
     */
    canChangeStateAssessment() {
        var lista = this.state.listaRemediation;
       
        var response = true;
       
        for (let index = 0; index < lista.length; index++) {
            var element = lista[index];
            if(element.Periodo === null || element.CodiceFiscale === null)
            {
                response = false;
                break
            }
            
        }

        return response;
    }

    callCambiaStato(stato) {
        let data = { 'AssessmentId': this.state.assessmentId, Stato: stato }
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.cambiaStatoAssessment,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(data),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    //console.log(response);
                    if (response.status === 'success') {
                      
                        this.setState({ statoAssessment: stato });
                        if(stato === 6)
                        {
                            this.setState({ messageSuccess: "Operazione effettuata con successo!" });
                            $('#btnSuccess').get(0).click();
                        }
                    }
                    else {
                        //alert(response.status);
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    /**
     * 
     */
    changeStateAssessment() {
        try {
            //let index = this.state.isCheckedRow.findIndex(function (obj) { return obj === false; });
            var response = this.canChangeStateAssessment();
            if (response.canChange && response.pianificate > 0) {
                if (this.opzioniComplete()) {
                    $('#btnPubblica').get(0).click();
                }
                else {
                    this.setState({ messageWarning: "Per proseguire è necessario completare il campo Motivazione delle Opzioni di Trattamento modificate" });
                    $('#btnValidate').get(0).click();
                }
            }
            else {
                if (response.pianificate > 0)
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario valorizzare il trimestre di chiusura previsto per tutte le azioni di trattamento da pianificare ed immettere il testo delle eventuali azioni personalizzate" });
                else
                    this.setState({ messageWarning: "Per effettuare la pubblicazione è necessario pianificare almeno un'azione di trattamento" });
                $('#btnValidate').get(0).click();
            }
        } catch (error) {
            return console.error(error);
        }
    }

    componentDidMount() {
        console.log("props", this.props);
        
        this.callAzioni();
        this.getAllUsers();
        this.getRoadmap();
    }

    getAllUsers() {

        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getAllUsers,
                type: "GET",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {

                        this.setState({ users: response.data }, () => console.log(this.state.users));                      
                    
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    getRoadmap() {
        let data = { 'AssessmentId': this.state.assessmentId }
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getAssessmentRoadmap,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(data),
                cache: false,
                success: function (response) {

                        this.setState({ roadmap: response.data }, () => console.log(this.state.roadmap));                      
                    
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    /**
     * 
     */
    callAzioni() {
        let data = { 'AssessmentId': this.state.assessmentId }
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getAssessmentAzioniDiTrattamentoByAssessmentId',
                    url: API_CONFIG.azioniDiTrattamentoByAssessmentId,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    //data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                    data: JSON.stringify(data),
                    cache: false,
                    success: function (response) {
                        //console.log('response:', response);
                        this.setState({ serviceName: response.data.NomeServizio });
                        if (response.data.listaAzioni.length > 0) {
                            if (response.data.StatoAssessmentId < 4) {
                                this.setState({ emptyTest: "" });
                            }
                            else {
                                this.setState({ listaRemediation: response.data.listaAzioni });
                            }
                        }
                        else
                            this.setState({ emptyTest: "" });
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    redirect(path) {
        try {
            //window.sessionStorage.setItem("assessmentId", this.state.assessmentId);
            //window.sessionStorage.setItem("serviceName", this.state.serviceName);
            let state = {};
            if (this.props.PA) {
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA: this.props.PA,
                    nomePA: this.state.nomePA
                };
            } else {
                state = {
                    assessmentId: this.state.assessmentId,
                    isPA: this.props.PA,
                    serviceName: this.state.serviceName
                };
            }
            if (path) {
                if (path.indexOf('/') === 0)
                    history.push({
                        pathname: path,
                        state: state
                    }
                    );
                else if (path.indexOf('/') === -1) {
                    history.push({
                        pathname: '/' + path,
                        state: state
                    }
                    );
                }
            }
            console.log("state", state)
        } catch (error) {
            return console.error(error)
        }
    }




    updateData(e, periodo, tipo) {
       
        var roadmap = this.state.roadmap;
        var periodString = "";
        switch (periodo) {
            case 1:
                periodString = "Basso";  
                break;
            case 2:
                periodString = "Medio";  
                break;
            case 3:
                periodString = "Lungo Termine";  
                break;
        
            default:
                break;
        }
        let index = roadmap.findIndex(r=> r.Periodo === periodString);
        console.log("index", index)
        if(index > -1)
        {
            if(tipo==="Inizio")
                roadmap[index].DataInizio = e.target.value;
            else
                roadmap[index].DataFine = e.target.value;
        }
        else
        {
            periodo = {
                AssessmentId : this.state.assessmentId,
                Periodo : periodString,
                DataInizio : tipo === "Inizio" ? e.target.value : null,
                DataFine : tipo !== "Inizio" ? e.target.value : null,
            }
            roadmap.push(periodo);
        }

        this.setState({ roadmap: roadmap }, () => console.log("roadmap", this.state.roadmap));
    }

 
    updateRemediation(e, id, tipo) {        
        var remediations = this.state.listaRemediation;
        
        let index = remediations.findIndex(r=> r.AssessmentAzioniDiTrattamentoId === id);
        console.log("id", id)
        if(index > -1)
        {
            if(tipo==="Periodo")
                remediations[index].Periodo = e.target.value;
            else
                remediations[index].CodiceFiscale = e.target.value;
        }

        this.setState({ listaRemediation: remediations }, () => console.log("remediations", this.state.listaRemediation));
    }

    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    

    /**
     * 
     */
    updadateCheckedRow(rowId, isCheck) {
        console.log("rowId", rowId);
        console.log("isCheck", isCheck);
        try {
            let isCheckedRow = this.state.isCheckedRow;
            isCheckedRow[rowId] = isCheck;
            this.setState({ isCheckedRow: isCheckedRow });
        } catch (error) {
            return console.error(error);
        }

    }

    /**
    * 
    * @param {*} nomePA 
    */
    setNomePA(nomePA) {
        try {
            if (nomePA) {
                this.setState({ nomePA: nomePA }, () => sessionStorage.setItem('nomePA', this.state.nomePA));
            } else {
                let user = this.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA }, () => sessionStorage.setItem('nomePA', this.state.nomePA));
            }
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getUsersFromAccessToken() {
        try {
            const accessToken = sessionStorage.getItem('userInfo');
            if (accessToken) {
                return JSON.parse(accessToken).user;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    save(calcolaIUCTeorico) {
        let data = { 'AzioniDiTrattamento': this.state.listaRemediation, 'CalcoloIUCTeorico': calcolaIUCTeorico }
        try {
            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    url: API_CONFIG.saveOrUpdateAzioniDiTrattamento,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify(data),
                    cache: false,
                    beforeSend: function () {
                        $('#loader').show();
                        $('#modal').show();
                    },
                    complete: function () {
                        $('#loader').hide();
                        $('#modal').hide();
                    },
                    success: function (response) {
                        if (response.status === 'success')    
                        {                        
                            this.getRoadmap();
                            if(calcolaIUCTeorico)
                            {
                                this.setState({ messageSuccess: "Pianificazione salvata con successo!" });
                                $('#btnSuccess').get(0).click();
                            }
                        }
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    execute()
    {
        if(this.canChangeStateAssessment())
        {
            this.save(false);
            this.callCambiaStato(6);
        }
        else
        {
            this.setState({ messageWarning: "E' necessario assegnare a tutte le Azioni il periodo e l'utente incaricato." });
            $('#btnValidate').get(0).click();
        }
    }

    saveRoadmap() {
        try {

            if(this.state.statoAssessment === 4)
                this.callCambiaStato(5);


            this.serverRequest =
                $.ajax({
                    type: 'POST',
                    url: API_CONFIG.saveOrUpdateAssessmentRoadmap,
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify(this.state.roadmap),
                    cache: false,
                    success: function (response) {
                        if (response.status === 'success') 
                        {
                            this.setState({ messageSuccess: "Roadmap salvata con successo!" });
                            $('#btnSuccess').get(0).click();
                        }
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        } catch (error) {
            return console.error(error);
        }
    }

    
    getConformità(gdi) {
        var res = 0;
        try {
            switch (gdi) {
                case 1:
                    res = 1;
                    break;
                case 2:
                    res = 3;
                    break;
                case 3:
                    res = 3;
                    break;
                case 4:
                    res = 2;
                    break;
                case 5:
                    res = 1;
                    break;
                case 6:
                    res = 1;
                    break;

                default:
                    res = 0;
                    break;
            }

        } catch (error) {
            console.error(error);
            res= 0;
        }
        return res;
    }

    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];

        if(infoAggiuntive != null && infoAggiuntive != undefined)
        {
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
    }
        return toString;
    }
    

  

    render() {

        let roadmapBasso= {};
        let roadmapMedio= {};
        let roadmapLungo= {};

        if(this.state.roadmap.length > 0)
        {
            let index = this.state.roadmap.findIndex(r=> r.Periodo === 'Basso');
            if(index > -1)
                roadmapBasso = this.state.roadmap[index];

            index = this.state.roadmap.findIndex(r=> r.Periodo === 'Medio');
            if(index > -1)
                roadmapMedio = this.state.roadmap[index];

            index = this.state.roadmap.findIndex(r=> r.Periodo === 'Lungo Termine');
            if(index > -1)
                roadmapLungo = this.state.roadmap[index];          
        }
        

        let users = [];
        for (let index = 0; index < this.state.users.length; index++) {      
            users.push(
            <option key={"user" + index} value={this.state.users[index].CodiceFiscale}>                
                {this.state.users[index].Cognome + " " + this.state.users[index].Nome}
                </option>);
        }
        let remediationRows = [];
        let arrayRemediation = this.state.listaRemediation;
        let tot = arrayRemediation.length;
        for (let index = 0; index < arrayRemediation.length; index++) {
            if (!arrayRemediation[index]) {
                remediationRows[index] = <div className="col-lg-4">Loading...</div>
            } else if(arrayRemediation[index].GradoDiImplementazione > 1 && arrayRemediation[index].GradoDiImplementazione < 6){
                
                var rilevanza = arrayRemediation[index].Rilevanza;
                var conformità = this.getConformità(arrayRemediation[index].GradoDiImplementazione);
                let id = arrayRemediation[index].AssessmentAzioniDiTrattamentoId;
                var testoRilevanza = rilevanza ==1 ? "BASSA" : rilevanza == 2  ? "MEDIA" : "ALTA";
                var testoConformità = conformità ==1 ? "ALTA" : conformità == 2  ? "MEDIA" : "BASSA";
                var periodo = arrayRemediation[index].Periodo === null ? -1 : arrayRemediation[index].Periodo;
                var cf = arrayRemediation[index].CodiceFiscale === null ? -1 : arrayRemediation[index].CodiceFiscale;
                var presaInCarico = arrayRemediation[index].Stato > 1;
                remediationRows.push(<div className="row" key={arrayRemediation[index].AssessmentAzioniDiTrattamentoId + "remediation1" + (index)}>

                <div className="col-lg-2"  style={{marginTop:"5px"}}><strong>Requisito N° {index+1}/{tot}</strong></div>
                <div className="col-lg-2" style={{marginTop:"5px"}}>
                    <strong>Rilevanza </strong>
                     <span className={getClassName(arrayRemediation[index].Rilevanza)}>
                         &nbsp;&nbsp;{testoRilevanza}</span>
                </div>
                <div className="col-lg-2" style={{marginTop:"5px"}}>
                    <strong>Conformità </strong>
                        <span className={getClassName(conformità)}>
                            &nbsp;&nbsp;{testoConformità}</span>
                    </div>
                <div className="col-lg-3" >
                    <select disabled={presaInCarico} className='form100' style={{marginTop:"5px", marginBottom:"5px"}}
                      onChange={(e) => this.updateRemediation(e, id, 'Periodo')} defaultValue={periodo}
                    >
                        <option value='-1' disabled></option>
                        <option value='1'>Basso</option>      
                        <option value='2'>Medio</option>
                        <option value='3'>Lungo Termine</option>
                    </select>
                </div>
                <div className="col-lg-3">
                    <select disabled={presaInCarico} className='form100' style={{marginTop:"5px", marginBottom:"5px"}}
                    onChange={(e) => this.updateRemediation(e, id, 'Utente')} defaultValue={cf}
                    >
                        <option value='-1' disabled></option>
                        {users}
                    </select>
                </div>
                <div className="col-lg-12"><hr/></div>
                <div className="col-lg-12">
                    <p style={{color: '#0066cc'}}>
                        {arrayRemediation[index].SottoCategoria}
                        <br />
                    </p>
                </div>
                <div className="col-lg-12">
                    <p style={{textAlign: 'justify'}}>
                        {this.toStringInfoAggiuntive(arrayRemediation[index].Azione)}
                    </p>
                </div>
                <div className="col-lg-12">
                    <p style={{color: '#0066cc'}}>
                       Riferimento Normativo
                        <br />
                    </p>
                </div>
                <div className="col-lg-12">
                    <p style={{textAlign: 'justify'}}>
                        {this.toStringInfoAggiuntive(arrayRemediation[index].RifNormativo)}
                    </p>
                </div>
                <div className="col-lg-12"><br></br></div>                
                </div>);
            }
        }

        var testoBreadcrumb = this.props.PA === "true" ? "Azioni di trattamento per PA" : "Azioni di trattamento per servizio";

        let name = (this.props.PA) ? this.state.nomePA : this.state.serviceName;


        return (
            <div>
                <div id="main">

                    <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                        <div className="container cnsContainer">


                            <div className="row">

                                <div className="col-lg-12">

                                    <nav className="" aria-label="sei qui:" role="navigation">
                                        <ul className="Breadcrumb">
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                            <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Remediation Plans</a></li>
                                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/ElencoApplicazioniPlanning')}>Elenco Applicazioni</a></li>
                                            <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Planning</a></li>
                                        </ul>
                                    </nav>
                                        <span className="titoloPagina">Planning: {name}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 giustifica">
                                <p>
                                Il sistema consente di definire le 'FASI' temporali ('Basso', 'Medio', 'Lungo' termine) della Roadmap, di associare ogni remediation ad una specifica fase della Roadmap; di assegnare ciascuna remediation (task) ad una specifica risorsa; di simulare diversi scenari.
                            </p>                                 
                                </div>

                                
                            </div>
                            <div className="linea0066cc"></div>
                            <div className="container cnsContainer">

                                <div className="row">
                                    <div className="col-lg-12"> <span className="titoloPagina">Roadmap</span></div>
                                </div>
                               
                                <br/>
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className="col-lg-2"><strong>Periodo</strong></div>   
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                     <strong>Inizio</strong>
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                     <strong>Fine</strong>
                                    </div>                                
                                    <div className="col-lg-2" style={{textAlign:"center"}}>
                                     <strong>IUC Atteso</strong>
                                    </div>
                                    <div className="col-lg-2"></div>     
                                </div>
                                <div className="row">
                                    <div className="col-lg-2" ><span>Basso</span></div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}} 
                                    defaultValue={getDate(roadmapBasso.DataInizio)}
                                    onChange={(e) => this.updateData(e, 1, 'Inizio')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}} 
                                    defaultValue={getDate(roadmapBasso.DataFine)}
                                    onChange={(e) => this.updateData(e, 1, 'Fine')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-2" style={{textAlign:"center"}}>
                                        <span>{(roadmapBasso.IUCTeorico === null || roadmapBasso.IUCTeorico === undefined) ? '-': roadmapBasso.IUCTeorico + '%'}</span>
                                    </div>
                                    <div className="col-lg-2"></div>        
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-2" ><span>Medio</span></div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}}
                                     defaultValue={getDate(roadmapMedio.DataInizio)}
                                     onChange={(e) => this.updateData(e, 2, 'Inizio')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}}
                                     defaultValue={getDate(roadmapMedio.DataFine)}
                                     onChange={(e) => this.updateData(e, 2, 'Fine')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-2" style={{textAlign:"center"}}>
                                    <span>{(roadmapMedio.IUCTeorico === null || roadmapMedio.IUCTeorico === undefined) ? '-': roadmapMedio.IUCTeorico + '%'}</span>
                                    </div>
                                    <div className="col-lg-2"></div>        
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-lg-2" ><span>Lungo termine</span></div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}}
                                      defaultValue={getDate(roadmapLungo.DataInizio)}
                                      onChange={(e) => this.updateData(e, 3, 'Inizio')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-3" style={{textAlign:"center"}}>
                                    <input type="date" style={{background:"#eee", padding:"0px"}}
                                     defaultValue={getDate(roadmapLungo.DataFine)}
                                     onChange={(e) => this.updateData(e, 3, 'Fine')}
                                    ></input>
                                    </div>
                                    <div className="col-lg-2" style={{textAlign:"center"}}>
                                    <span>{(roadmapLungo.IUCTeorico === null || roadmapLungo.IUCTeorico === undefined) ? '-': roadmapLungo.IUCTeorico + '%'}</span>
                                    </div>
                                    <div className="col-lg-2"></div>        
                                </div>
                                <br/>
                                <br/>
                                <div className="row spazio20">

                                    <div className="col-lg-5 ">	</div>
                                    <div className="col-lg-2 ">
                                        <button onClick={() => this.saveRoadmap()} className="btn btn-success btn-lg btn-block">Salva Roadmap</button>
                                    </div>
                                    <div className="col-lg-5 ">	</div>
                                    </div>
                            </div>

                            <div className="spazio10"></div>
                       
                        {this.state.statoAssessment > 4 && <div className="row">

                            <div className="col-lg-6">
                                <h1 className="testoBlu">Azioni di rientro</h1>  
                            </div>
                            <div className="col-lg-3" style={{textAlign:"center", paddingTop:"20px"}}>
                                <span><strong>Periodo</strong></span>
                            </div>
                            <div className="col-lg-3" style={{textAlign:"center", paddingTop:"20px"}}>
                                <strong>Utente Incaricato</strong>
                            </div>
                            
                            <div className="col-lg-12 giustifica">	                           
                             <hr />
                            </div>
                   
                        </div>} 
                        {this.state.statoAssessment > 4 && <div className="spazio10"></div>}
                        {this.state.statoAssessment > 4 && remediationRows}
                        {this.state.statoAssessment > 4 && <div className="row spazio20">

                        <div className="col-lg-4 ">	</div>
                        <div className="col-lg-2 ">
                            <button onClick={() => this.save(true)} className="btn btn-success btn-lg btn-block">Salva Pianificazione</button>
                        </div>
                        <div className="col-lg-2 ">
                            <button onClick={() => this.execute()} className="btn btn-success btn-lg btn-block">Execute</button>
                        </div>
                        <div className="col-lg-4 ">	</div>
                        </div>}
                            <br />
                            <br />
                            <br />
                        </div>

                    </div>

                </div>
                


                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={this.state.messageSuccess} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.messageWarning} />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnSetGrado'} handle={this.setGradoAttesoDefault} textModal={"L'operazione sovrascrive le eventuali modifiche apportate alle Azioni di Trattamento. Procedere?"} />
                <ModalDialog idTarget={'warningAction2'} idBtn={'btnPubblica'} handle={this.callCambiaStato} textModal={"L'operazione sovrascrive l'eventuale piano di trattamento già presente. Procedere?"} />
            </div>

        )
    }
}
