import React, { Component } from 'react';
import infoIcon from '../../public/images/info.png';

let SELECT = []
//SELECT[-1] = 'Non selezionato';
SELECT[1] = 'Basso';
SELECT[2] = 'Medio';
SELECT[3] = 'Alto';
SELECT[4] = 'Critico';

export class ValutazioneImpattiRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.updateImpattoR = this.updateImpattoR.bind(this);
        this.updateImpattoI = this.updateImpattoI.bind(this);
        this.updateImpattoD = this.updateImpattoD.bind(this);
        this.isCheck = this.isCheck.bind(this);
    }

    updateImpattoR(impatto, event) {
        let val = event.target.value;
        //console.log('updateImpattoR', impatto);
        this.props.updateImpattoR(impatto.TipologiaImpattoId, parseInt(val, 10), impatto);
    }

    updateImpattoI(impatto, event) {
        let val = event.target.value;
        //console.log('updateImpattoI', impatto);
        this.props.updateImpattoI(impatto.TipologiaImpattoId, parseInt(val, 10), impatto);

    }

    updateImpattoD(impatto, event) {
        let val = event.target.value;
        //console.log('updateImpattoD', impatto);
        this.props.updateImpattoD(impatto.TipologiaImpattoId, parseInt(val, 10), impatto);
    }

    isCheck() {
        let item = this.props.impatto;
        if (item.ImpattoR === -1 || item.ImpattoI === -1 || item.ImpattoD === -1)
            return 'hidden';
        else return 'visible';
    }

    render() {

        /**
         * 
         */
        var options = SELECT.map(function (item, i) {
            return (
                <option key={i} value={i}>{item}</option>
            );
        });

        let item = this.props.impatto;

        let selectRiservatezza =
            this.props.readOnly ?
                <div className="col-lg-2 spazio10" style={{ display: "inline" }}>{(item.ImpattoR > 0 && item.ImpattoR < 5) ? SELECT[item.ImpattoR] : "Non selezionato"}</div>
                :
                <select className="form100"
                    defaultValue={item.ImpattoR}
                    onChange={(e) => this.updateImpattoR(item, e)}>
                    <option key={-1} value={-1} disabled >seleziona</option>
                    {options}
                </select>
            ;

        var selectIntegrita =
            this.props.readOnly ?
                <div className="col-lg-2 spazio10" style={{ display: "inline" }}>{(item.ImpattoI > 0 && item.ImpattoI < 5) ? SELECT[item.ImpattoI] : "Non selezionato"}</div>
                :
                <select className="form100"
                    defaultValue={item.ImpattoI}
                    onChange={(e) => this.updateImpattoI(item, e)}>
                    <option key={-1} value={-1} disabled >seleziona</option>
                    {options}
                </select>

        var selectDisponibilita =

            this.props.readOnly ?
                <div className="col-lg-2 spazio10" style={{ display: "inline" }}>{(item.ImpattoD > 0 && item.ImpattoD < 5) ? SELECT[item.ImpattoD] : "Non selezionato"}</div>
                :
                <select className="form100"
                    defaultValue={item.ImpattoD}
                    onChange={(e) => this.updateImpattoD(item, e)}>
                    <option key={-1} value={-1} disabled >seleziona</option>
                    {options}
                </select>

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i style={{ visibility: this.isCheck() }} className="testoVerde fa fa-check"></i>{' Impatto ' + this.props.impatto.NomeTipologiaImpatto}<i className="iconRight fa fa-chevron-down"></i></a>
                    </div>
                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="collapse marginLeft" data-parent="#accordion">
                    <div className="row">
                        <div className="col-lg-10 testoNero giustifica marginRight">
                            <table width="100%" border="0" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td align="left" valign="middle">
                                            <a data-bs-toggle="collapse" href={"#collapseAA" + parseInt(this.props.rowId, 10)}>
                                                <span className=" testoBlu paRight">
                                                    <img src={infoIcon} alt="imgInfo" />
                                                </span>
                                            </a>
                                        </td>
                                        <td align="left" valign="top">
                                            <a data-bs-toggle="collapse" href={"#collapseAA" + parseInt(this.props.rowId, 10)}>
                                                <strong>Riservatezza {(this.props.readOnly ? "" : "*")}</strong>
                                            </a>
                                            <br />Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>divulgazione accidentale o malevola</strong> di dati e informazioni riservate trattate nell'ambito del servizio? (considerare il caso peggiore).
                                </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*
                            <a data-bs-toggle="collapse" href={"#collapseAA" + parseInt(this.props.rowId, 10)}>
                                <img style={{ marginBottom: "3px" }} src={infoIcon} alt="iconInfo" />
                                {" "}<strong>Riservatezza {(this.props.readOnly ? "" : "*")}</strong>
                            </a>
                            <br />
                            Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>divulgazione</strong> accidentale o malevola di dati e informazioni riservate trattate nell'ambito del servizio? (considerare il caso peggiore).
                        */}
                            <div id={"collapseAA" + parseInt(this.props.rowId, 10)} className="collapse spazio10 InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                <strong>Critico</strong>: {this.props.impatto.DescrizioneCritico}<br />
                                <strong>Alto</strong>: {this.props.impatto.DescrizioneAlto}<br />
                                <strong>Medio</strong>: {this.props.impatto.DescrizioneMedio}<br />
                                <strong>Basso</strong>: {this.props.impatto.DescrizioneBasso}
                            </div>
                            <hr />

                        </div>
                        <div className="col-lg-2 spazio10">
                            {selectRiservatezza}
                        </div>
                        <div className="col-lg-10 testoNero giustifica marginRight">
                            <div className="spazio10"></div>

                            {/**/}
                            <table width="100%" border="0" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td align="left" valign="middle">
                                            <a data-bs-toggle="collapse" href={"#collapseAB" + parseInt(this.props.rowId, 10)}>
                                                <span className=" testoBlu paRight"><img src={infoIcon} alt="imgInfo"/></span></a></td>
                                        <td align="left" valign="top">
                                            <a data-bs-toggle="collapse" href={"#collapseAB" + parseInt(this.props.rowId, 10)}>
                                                <strong>Integrità {(this.props.readOnly ? "" : "*")}</strong>
                                            </a>
                                            <br />Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>modifica accidentale o malevola</strong> dei dati e delle informazioni riservate trattate nell'ambito del servizio? (considerare il caso peggiore).

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*
                                <a data-bs-toggle="collapse" href={"#collapseAB" + parseInt(this.props.rowId, 10)}>
                                    <img style={{ marginBottom: "3px" }} src={infoIcon} alt="iconInfo" />
                                    {" "}
                                    <strong>Integrità {(this.props.readOnly ? "" : "*")}</strong>
                                </a>
                                <br />
                                Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>modifica</strong> accidentale o malevola dei dati e delle informazioni riservate trattate nell'ambito del servizio? (considerare il caso peggiore).
                            */}

                            <div id={"collapseAB" + parseInt(this.props.rowId, 10)} className="collapse spazio10 InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                <strong>Critico</strong>: {this.props.impatto.DescrizioneCritico}<br />
                                <strong>Alto</strong>: {this.props.impatto.DescrizioneAlto}<br />
                                <strong>Medio</strong>: {this.props.impatto.DescrizioneMedio}<br />
                                <strong>Basso</strong>: {this.props.impatto.DescrizioneBasso}
                            </div>
                            <hr />

                        </div>
                        <div className="col-lg-2 spazio10"><div className="spazio10"></div>
                            {selectIntegrita}
                        </div>

                        <div className="col-lg-10 testoNero giustifica marginRight">
                            <div className="spazio10"></div>
                            <table width="100%" border="0" cellPadding="5">
                                <tbody>
                                    <tr>
                                        <td align="left" valign="middle">
                                            <a data-bs-toggle="collapse" href={"#collapseAC" + parseInt(this.props.rowId, 10)}>
                                                <span className=" testoBlu paRight">
                                                    <img src={infoIcon} alt="imgInfo"/></span></a></td>
                                        <td align="left" valign="top">
                                            <a data-bs-toggle="collapse" href={"#collapseAC" + parseInt(this.props.rowId, 10)}>
                                                <strong>Disponibilità {(this.props.readOnly ? "" : "*")}</strong>
                                            </a>
                                            <br />Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>prolungata indisponibilità</strong> del servizio o di informazioni e dati da esso trattati? (considerare il caso peggiore).

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/*
                            <a data-bs-toggle="collapse" href={"#collapseAC" + parseInt(this.props.rowId, 10)}>
                                <img style={{ marginBottom: "3px" }} src={infoIcon} alt="iconInfo" />
                                {" "}<strong>Disponibilità {(this.props.readOnly ? "" : "*")}</strong>
                            </a><br />
                            Quali sono i possibili impatti, e il relativo livello di criticità, sull'organizzazione nel caso in cui si verifichi una <strong>prolungata indisponibilità</strong> del servizio o di informazioni e dati da esso trattati? (considerare il caso peggiore).
                        */}

                            <div id={"collapseAC" + parseInt(this.props.rowId, 10)} className="collapse spazio10 InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                <strong>Critico</strong>: {this.props.impatto.DescrizioneCritico}<br />
                                <strong>Alto</strong>: {this.props.impatto.DescrizioneAlto}<br />
                                <strong>Medio</strong>: {this.props.impatto.DescrizioneMedio}<br />
                                <strong>Basso</strong>: {this.props.impatto.DescrizioneBasso}
                            </div>
                            <hr />

                        </div>
                        <div className="col-lg-2 spazio10"><div className="spazio10"></div>
                            {selectDisponibilita}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
