import React from 'react';

import bassoIcon from "../../public/images/basso.png";
import medioIcon from "../../public/images/medio.png";
import altoIcon from "../../public/images/alto.png";
import criticoIcon from "../../public/images/critico.png";


export class AccordionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 1,
            grade: 0,
            icons: {
                "basso" : bassoIcon,
                "medio" : medioIcon,
                "alto" : altoIcon,
                "critico" : criticoIcon
            }
        };
        //this.updateGrade = this.updateGrade.bind(this);
    }

    updateGrade(event) {
        this.setState({ grade: event.target.value });
        var grade = parseInt(event.target.value, 10);
        this.props.updateGrade(grade, this.props.categoriaId, this.props.misuraDiSicurezza.MisuraDiSicurezzaId);
        //console.log('updateGrade',item);
    }

    render() {
        let criticitàLabel = (this.props.criticitàLabel) ? this.props.criticitàLabel : "Profilo di criticità rilevato";
        let impattoLabel = (this.props.impattoLabel) ? this.props.impattoLabel : "Impatto R-I-D";

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12 card-headerInfo">
                        <a className="card-linkInfo testoNero marginLeft" data-bs-toggle="collapse" href="#collapseInfo" aria-expanded="true">
                            Informazioni </a><i className="iconRight fa fa-chevron-down"></i>
                    </div>

                </div>
                <hr />
                <div id="collapseInfo" className="col-lg-12 collapse show" data-parent="#accordion">
                    <div className="row  card-headerLight">
                        <div className="col-lg-12"><br />
                            <strong>Stato dell'analisi</strong> : {this.props.statoAssessmentId}
                            </div>

                    <div className="col-lg-12">
                            <hr />
                        </div>
                    <div className="col-lg-6 "><br />
                            <p><strong>Indice Unico Conformità</strong>&nbsp;&nbsp;<br />
                                <span className={"testoRosso h2"}>
                                    <strong>{this.props.iuc} %</strong></span></p>
                        </div>
                        <div className="col-lg-6 "><br />
                            <p><strong>{criticitàLabel}:</strong>&nbsp;&nbsp;<br />
                    <img src={this.state.icons[this.props.criticità[0].toLowerCase()]}
                                    style={{ width: "20px", height: "20px" }} alt="" />
                                <span className={this.props.criticità[1] + " h2"}>
                                    <strong>{this.props.criticità[0]}</strong></span></p>
                        </div>
                        <div className="col-lg-12">
                            <hr />
                        </div>
                        <div className="col-lg-4"><br />
                            <strong>Data Self-Assessment </strong><br />
                            {this.props.dataCalcoloRischioAttuale}
                        </div>
                        <div className="col-lg-4"><br />
                            <strong>Data di pubblicazione<br /></strong>
                            {this.props.dataPubblicazione}
                        </div>

                        <div className="col-lg-4"><br />
                            <strong>Data di ultima modifica dei dati<br /></strong>
                            {this.props.dataModifica}
                        </div>
                        <div className="col-lg-12">
                            <hr />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}