import * as React from 'react';
import { GradoImplementationeOption } from '../Common/GradoImplementationeOption';
import GRADE from '../data/GradoDiImplementazione';
import { DatePicker } from '../Common/DatePicker';
import infoIcon from '../../public/images/info.png';

// component that renders a single AzioniTrattamentoServizioModifica row
export class AzioniTrattamentoServizioModificaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //months : ["", "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre","Dicembre"],
            emptyText: '',
            isHiddenGrade: this.props.radioGroup.isHiddenGrade,
            isDisabledDataChiusura: this.props.radioGroup.isDisabledDataChiusura,
            isCheckRadio: (this.props.radioGroup.isCheckConsigliata || this.props.radioGroup.isCheckEffettiva || this.props.radioGroup.isCheckNessuna),
            isCheckConsigliata: this.props.radioGroup.isCheckConsigliata,
            isCheckEffettiva: this.props.radioGroup.isCheckEffettiva,
            isCheckNessuna: this.props.radioGroup.isCheckNessuna
        };
        //ToString
        this.toStringYearAndMonth = this.toStringYearAndMonth.bind(this);
        //UPDATE
        this.updateStrutturaReferente = this.updateStrutturaReferente.bind(this);
        this.updateAzioneConsigliata = this.updateAzioneConsigliata.bind(this);
        this.updateAzioneEffettiva = this.updateAzioneEffettiva.bind(this);
        this.updateDataChiusura = this.updateDataChiusura.bind(this);
        this.updateMonth = this.updateMonth.bind(this);
        this.updateEffectiveAction = this.updateEffectiveAction.bind(this);
        this.updateNessunaAzione = this.updateNessunaAzione.bind(this);
        this.getEfficacia = this.getEfficacia.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.radioGroup.isHiddenGrade !== state.isHiddenGrade ||
            props.radioGroup.isDisabledDataChiusura !== state.isDisabledDataChiusura ||
            props.radioGroup.isCheckConsigliata !== state.isCheckConsigliata ||
            props.radioGroup.isCheckEffettiva !== state.isCheckEffettiva ||
            props.radioGroup.isCheckNessuna !== state.isCheckNessuna ||
            (props.radioGroup.isCheckConsigliata || props.radioGroup.isCheckEffettiva || props.radioGroup.isCheckNessuna) !== state.isCheckRadio
        ) {
            return {

                isHiddenGrade: props.radioGroup.isHiddenGrade,
                isDisabledDataChiusura: props.radioGroup.isDisabledDataChiusura,
                isCheckConsigliata: props.radioGroup.isCheckConsigliata,
                isCheckEffettiva: props.radioGroup.isCheckEffettiva,
                isCheckNessuna: props.radioGroup.isCheckNessuna,
                isCheckRadio: (props.radioGroup.isCheckConsigliata || props.radioGroup.isCheckEffettiva || props.radioGroup.isCheckNessuna)
            };
        }
        return null;
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return '';
            }
        } catch (error) {
            console.log(error);
            return '';
        }
    }

    /**
     * for example this.props.action.DataChiusura = "2018-10-10T12:13:57.326Z" return "2018-10"
     */
    toStringYearAndMonth(type) {
        let dateStr = '';
        try {
            var dateParse = new Date(Date.parse(this.props.action.DataChiusura));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                //let day = dateParse.getDate();
                //let dayStr = (day > 9) ? day : '0' + day;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                switch (type) {
                    case "text":
                        dateStr = monthStr + '/' + year;
                        break;
                    case "month":
                        dateStr = year + '-' + monthStr;
                        break;
                    default://
                        break;
                }
            }
            return dateStr;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} month 
     * @param {*} year 
     */
    updateDataChiusura(selectedDate) {
        //debugger
        this.props.updateDataChiusura(selectedDate, this.props.action);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }

    /**
     * 
     * @param {*} event 
     */
    updateMonth(value) {
        console.log('yearMonth', value);
        //debugger
        this.props.updateDataChiusura(value, this.props.action);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }
    /**
     * 
     * @param {*} event 
     */
    updateEffectiveAction(event) {
        //var grade = parseInt(event.target.value, 10);
        //this.props.updateEffectiveAction(event.target.value, this.props.action);
        this.props.updateEffectiveAction(event.target.value, this.props.action, this.props.index);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }

    /**
     * Nel caso di “Azione consigliata” il sistema deve disabilitare la combobox del grado di 
     * implementazione atteso e impostare il valore a “completo”.
     */
    updateAzioneConsigliata(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: checked });
        this.setState({ isCheckEffettiva: !checked });
        this.setState({ isCheckNessuna: !checked });
        //disabilitare la combobox del grado di implementazione atteso
        this.setState({ isHiddenGrade: true });
        //abilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: false });
        //imposta il valore atteso a “completo”
        this.props.updateGrade(4, this.props.action, null);
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    /**
     * Nel caso di “Azione effettiva” il sistema deve abilitare la combobox del grado di 
     * implementazione atteso
     */
    updateAzioneEffettiva(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: !checked });
        this.setState({ isCheckEffettiva: checked });
        this.setState({ isCheckNessuna: !checked });
        //abilitare il grado di implementazione atteso
        this.setState({ isHiddenGrade: false });
        this.props.updateGrade(this.props.action.GradoDiImplementazione, this.props.action, "");
        //abilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: false });
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    /**
     * Nel caso di “Nessuna azione” il sistema deve disabilitare la combobox del grado di implementazione 
     * atteso impostandolo allo stesso valore del grado di implementazione originale  e disabilitare la 
     * data di chiusura prevista.
     */
    updateNessunaAzione(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: !checked });
        this.setState({ isCheckEffettiva: !checked });
        this.setState({ isCheckNessuna: checked });
        //disabilitare il grado di implementazione atteso
        this.setState({ isHiddenGrade: true });

        //imposta lo stesso valore del grado di implementazione originale
        this.props.updateGrade(this.props.action.GradoDiImplementazione, this.props.action, "empty");
        //disabilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: true });
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    /**
     * 
     * @param {*} event 
     */
    updateStrutturaReferente(event) {
        this.props.updateStrutturaReferente(event.target.value, this.props.id);
    }

    getEfficacia(grado) {
        var res = "";
        if (!isNaN(grado)) {

            switch (grado) {
                case 1:
                    res = "BASSO"
                    break;
                case 2:
                    res = "MEDIO"
                    break;
                case 3:
                    res = "ELEVATO"
                    break;

                default:
                    res = "";
                    break;
            }
        }
        return res;
    }

    /**
     * 
     */
    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
        return toString;
    }

    getQuarterDate(date) {
        if (date) {
            var quarter = Math.ceil((new Date(date).getMonth() + 1) / 3);
            var year = new Date(date).getFullYear();
            //console.log("quarterDate", "Q" + quarter + " - " + year);
            return quarter + "-" + year;
        }
        else
            return "-1";
    }

    render() {

        let minaccieRows = [];
        if (this.props.action.Minacce.length === 0) {
            minaccieRows = <div className="row"> {this.state.emptyTest}</div>
        } else {
            for (let index = 0; index < this.props.action.Minacce.length; index++) {
                const minaccia = this.props.action.Minacce[index];
                let nomeMinacciaCol =
                    <div className="col-lg-8 " key={"nome_minaccia" + index + minaccia.MisuraDiSicurezzaId}>
                        <label>{minaccia.NomeMinaccia}</label>
                        <a data-bs-toggle="collapse" href={"#collapseInfo" + minaccia.MisuraDiSicurezzaId + "minaccia" + index}><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                        <div id={"collapseInfo" + minaccia.MisuraDiSicurezzaId + "minaccia" + index} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                            <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                            {(minaccia.DescrizioneEvento) ? this.toStringInfoAggiuntive(minaccia.DescrizioneEvento) : ""}
                        </div>
                        <hr />
                    </div>

                let gradoMinacciaCol = <div className="col-lg-4 text-center"
                    key={"grado_minaccia" + index + minaccia.MisuraDiSicurezzaId}>
                    {this.getEfficacia(minaccia.GradoDiEfficacia)}
                </div>
                minaccieRows.push(nomeMinacciaCol, gradoMinacciaCol);
            }
        }//end else

        let isCheckHidden =
            (this.props.action.GradoDiImplementazioneAtteso !== null && this.props.action.AzioneEffettiva !== "") &&
                (this.props.action.DataChiusura || this.props.action.AzioneEffettiva === "empty") &&
                (this.state.isCheckRadio)
                ? "visible" : "hidden";

        let textareaVal = "";

        if (this.props.action.AzioneEffettiva) {
            textareaVal = (this.props.action.AzioneEffettiva.localeCompare("empty") === 0) ? '' : this.props.action.AzioneEffettiva;
        }

        let strutturaRefText = (this.props.action.StrutturaReferente) ? this.props.action.StrutturaReferente : "";

        return (
            <div className="row" key={"accordion" + this.props.id}>

                <div className="col-lg-9 card-headerT truncate">
                    <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + this.props.id}>
                        <i style={{ visibility: isCheckHidden }} className="testoVerde fa fa-check"></i>{this.props.action.SottoCategoria}<i className="iconRight fa fa-chevron-down"></i> </a>
                </div>


                {/*colonna GradoDiImplementazione*/}
                <div className="col-lg-1 card-header">{GRADE[(this.props.action.GradoDiImplementazione.toString()) ? this.props.action.GradoDiImplementazione : '-1']}</div>
                {/*colonna GradoDiImplementazioneAtteso
                {<div className="col-lg-1 card-header">{GRADE[this.props.action.GradoDiImplementazioneAtteso !== null ? this.props.action.GradoDiImplementazioneAtteso : '-1']}</div>}
                */}
                {
                    this.state.isHiddenGrade ?
                        <div className="col-lg-1 card-header">{GRADE[this.props.action.GradoDiImplementazioneAtteso !== null ? this.props.action.GradoDiImplementazioneAtteso : '-1']}</div>
                        :
                        <div className="col-lg-1 card-header">
                            <GradoImplementationeOption
                                grade={this.props.action.GradoDiImplementazioneAtteso === null ? -1 : this.props.action.GradoDiImplementazioneAtteso}
                                updateGrade={this.props.updateGrade}
                                action={this.props.action}
                                isDisabledGrade={this.props.readOnly}
                                font={"font16"}
                            />


                        </div>
                }
                {/*colonna Chiusura prevista*/}
                <div className="col-lg-1 card-header">
                    <DatePicker className="testoBlu"
                        selectedDate={this.getQuarterDate(this.props.action.DataChiusura)}
                        updateDataChiusura={this.updateDataChiusura}
                        id={this.props.id}
                        key={"datePicker" + this.props.id}
                        isDisabled={this.state.isDisabledDataChiusura || this.props.readOnly}
                    />
                </div>

                <div id={"collapse" + this.props.id} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row card-headerLight ">


                        <table width="100%" border="0" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td align="left" valign="middle"><a data-bs-toggle="collapse" href={"#collapseAA" + this.props.id}><br />
                                        <span className=" testoBlu paRight"><img src={infoIcon} /></span></a></td>
                                    <td align="left" valign="top"><br />{(this.props.action.Nome) ? this.props.action.Nome : ''}

                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td valign="top"><div id={"collapseAA" + this.props.id} className="collapse  InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                        {(this.props.action.InformazioniAggiuntive) ? this.toStringInfoAggiuntive(this.props.action.InformazioniAggiuntive) : ''}                                        </div> </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="col-lg-12"><hr /></div>

                        <div className="col-lg-12">
                            <a className="" data-bs-toggle="collapse" href={"#collapseAzioni" + this.props.id} aria-expanded="true">
                                <p className="testoBlu "><strong>  Azioni di trattamento</strong><i className="iconRight fa fa-chevron-down"></i></p>		</a>
                        </div>

                        <div id={"collapseAzioni" + this.props.id} className="col-lg-12 collapse " data-parent="#accordion" >
                            <div className="row">
                                <div className="col-lg-4">
                                    <p>
                                        <strong>
                                            <input type="radio" //id="RadioGroup1_0"
                                                onChange={(e) => this.updateAzioneConsigliata(e, this.props.action)}
                                                //onChange={this.updateAzioneConsigliata}
                                                checked={this.state.isCheckConsigliata}
                                                disabled={this.props.readOnly}
                                            />
                                            &nbsp;&nbsp;&nbsp;Azione di trattamento consigliata</strong>
                                        <br />
                                        {(this.props.action.Azione) ? this.toStringInfoAggiuntive(this.props.action.Azione) : ''}
                                        <br />
                                    </p>
                                </div>

                                <div className="col-lg-4">
                                    <p>
                                        <strong>
                                            <input type="radio" //id="RadioGroup1_1"
                                                onChange={(e) => this.updateAzioneEffettiva(e)}
                                                checked={this.state.isCheckEffettiva}
                                                disabled={this.props.readOnly}
                                            />
                                            &nbsp;&nbsp;&nbsp;Azione di trattamento personalizzata</strong>
                                        <br />
                                        <textarea //cols="100%"
                                            style={{ width: "100%" }}
                                            id={"azionepersonalizzata" + this.props.id}
                                            defaultValue={textareaVal}
                                            //onChange={this.updateEffectiveAction}
                                            onBlur={this.updateEffectiveAction}
                                            placeholder={''}
                                            disabled={this.props.readOnly || this.state.isHiddenGrade}
                                        ></textarea>
                                    </p>
                                </div>
                                {/*
                                <div className="col-lg-12" hidden={this.state.isHiddenGrade}>
                                    <strong> Grado di implementazione target</strong>
                                    <GradoImplementationeOption
                                        grade={this.props.action.GradoDiImplementazioneAtteso === null ? -1 : this.props.action.GradoDiImplementazioneAtteso}
                                        updateGrade={this.props.updateGrade}
                                        action={this.props.action}
                                        isDisabledGrade={this.props.readOnly}
                                    />
                                    <br></br>
                                    <br></br>
                                </div>
                                */}

                                <div className="col-lg-4">
                                    <strong>
                                        <input type="radio" //id="RadioGroup1_2"
                                            onChange={(e) => this.updateNessunaAzione(e)}
                                            checked={this.state.isCheckNessuna}
                                            value="pulsante di scelta"
                                            disabled={this.props.readOnly}
                                        />
                                        &nbsp;&nbsp;&nbsp;Nessuna azione di trattamento</strong>
                                    <br></br>
                                    <br></br>
                                </div>

                            </div>
                            {/*Struttura referente*/}
                            {
                                this.props.readOnly ?
                                    <div className="row">
                                        <div className="col-lg-4"><p><strong>Struttura referente</strong></p></div>
                                        <div className="col-lg-8"><p>
                                            {strutturaRefText}
                                        </p></div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-lg-4"><p><strong>Struttura referente</strong></p></div>
                                        <div className="col-lg-8"><p>
                                            <input type="text" className="form100"
                                                defaultValue={strutturaRefText}
                                                onBlur={this.updateStrutturaReferente}
                                            ></input>
                                        </p></div>
                                    </div>
                            }
                        </div>

                        <div className="col-lg-12"><hr /></div>
                        <div className="col-lg-12">
                            <a className="collapsed" data-bs-toggle="collapse" href={"#collapseMinacce" + this.props.id} aria-expanded="true">
                                <p className="testoBlu "><strong>  Minacce coinvolte</strong><i className="iconRight fa fa-chevron-down"></i></p>		</a> </div>

                        <div id={"collapseMinacce" + this.props.id} className="col-lg-12 collapse" data-parent="#accordion">

                            <div className="row">
                                <div className="col-lg-8">
                                    <strong> Elenco minacce</strong> </div>
                                <div className="col-lg-4 text-center">
                                    <strong> Grado di efficacia del controllo</strong>
                                </div>
                                {minaccieRows}
                            </div>
                        </div>

                        <div className="col-lg-12"><hr /></div>

                        <div className="col-lg-12">
                            <a className="" data-bs-toggle="collapse" href={"#collapseConvenzione" + this.props.id} aria-expanded="true">
                                <p className="testoBlu "><strong>Convenzione</strong><i className="iconRight fa fa-chevron-down"></i></p>		</a>

                            <div id={"collapseConvenzione" + this.props.id} className="col-lg-12 collapse " data-parent="#accordion">     <div className="row">
                                <div className="col-lg-3"><strong>{this.props.action.ConvenzioneNome}</strong></div>
                                <div className="col-lg-3"><strong>Tipologia:</strong> {this.props.action.ConvenzioneTipologia}</div>
                                <div className="col-lg-3"><strong>Codice:</strong> {this.props.action.ConvenzioneCodice}</div>
                                <div className="col-lg-3"><strong>Note:</strong> {this.props.action.ConvenzioneNote}</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            /** */
        )
    }
}