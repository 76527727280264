import React, { Component } from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

export class AnalisiRischio extends Component {

    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/AnalisiRischio')}>Analisi del rischio</a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Analisi del rischio</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>



                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>Consente la valutazione “qualitativa” del rischio, che prende in considerazione le minacce identificate precedentemente e ne determina gli effetti su processi, servizi risorse e informazioni. <br />
                                    I principali obiettivi di questa fase sono:<br />
                                    1)	Calcolo del Rischio Attuale di un Servizio.<br />
                                    2)	Calcolo del Rischio Attuale caratteristico di ogni singola PA, risultante dall’analisi dei servizi erogati direttamente dalla  Azienda. </p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ListaApplicazioniGapAnalysis')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ANALISI PER  SERVIZIO</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Accesso alla lista dei servizi della Azienda per i quali si è provveduto alla compilazione del relativo questionario di valutazione impatti R-I-D. Selezionando il servizio di interesse sarà possibile procedere con la compilazione del questionario di analisi del rischio attuale rispondendo a tutte le misure di sicurezza proposte dinamicamente in base al contesto di sicurezza del servizio analizzato.
  <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo">

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/QuestionarioPA')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">ANALISI PER  Azienda</button>
                                </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo">

                                Accesso al questionario specifico per la Azienda corrente. Sarà possibile procedere con la compilazione del questionario di analisi del rischio attuale rispondendo a tutte le misure di sicurezza proposte dinamicamente in base al contesto di sicurezza dei servizi valutati.
  <hr />

                            </div>
                            <div className="col-lg-2 card-headerInfo"> 
	
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ElencoApplicazioniReport')}>
                            <button type="button" className="btn btn-warning btn-lg btn-block"> DATI PER  SERVIZIO</button>
                            </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo"> 

                            Accesso alla lista dei servizi della Azienda per i quali si è provveduto alla compilazione del questionario di analisi del rischio. Selezionando il servizio di interesse sarà possibile accedere alla pagina dei risultati dell'analisi del rischio per ottenere le informazioni 
relative al calcolo del rischio attuale tramite la visualizzazione di specifici indicatori riepilogativi.
                            <hr/>

                            </div>
                            <div className="col-lg-2 card-headerInfo"> 

                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/RiepilogoDatiAnalisiRischio')}>
                            <button type="button" className="btn btn-warning btn-lg btn-block">DATI PER  Azienda</button>
                            </a>

                            </div>
                            <div className="col-lg-10 card-headerInfo"> 

                            Accesso alla pagina dei risultati per  Azienda. Sarà possibile accedere alla pagina dei risultati dell'analisi del rischio per la Azienda selezionata, ottenendo le informazioni relative al calcolo del rischio attuale tramite la visualizzazione di specifici indicatori riepilogativi.
                            <hr/>

                            </div>


                        </div></div>



                </div>







            </div>
        )
    }
}