import * as React from 'react';

// component that renders a single caratteristica row
export class ErogazioneRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.updateCaratteristica = this.updateCaratteristica.bind(this);
        this.updateNotaAggiuntiva = this.updateNotaAggiuntiva.bind(this);
        this.isIconHidden = this.isIconHidden.bind(this);
        this.getCategorieCanali = this.getCategorieCanali.bind(this);
        this.getSubAccordionElementList = this.getSubAccordionElementList.bind(this);
    }

    /**
     * 
     */
    updateCaratteristica(item) {
        this.props.updateCaratteristica(this.props.categoriaCaratteristicaId, item.Id);
        //this.props.updateCaratteristica(this.props.rowId, item.Id);
        console.log('itemId', item.Id, 'categoriaCaratteristicaId', this.props.categoriaCaratteristicaId);
    }

    /**
     * 
     */
    updateNotaAggiuntiva(item, e) {
        //console.log("value", e.target.value);
        //console.log("item", item);
        let notaAggiuntiva = e.target.value;
        let categoriaCaratteristicaId = this.props.categoriaCaratteristicaId
        this.props.updateNotaAggiuntiva(categoriaCaratteristicaId, item.Id, notaAggiuntiva);
    }

    isIconHidden() {
        var response = "hidden";

        for (let x = 0; x < this.props.caratteristiche.length; x++) {
            if (this.props.caratteristiche[x].Checked) {
                response = "visible";
                break;
            }
        }

        return response;

    }

    getCategorieCanali() {
        var listaCategorie = [];
        // for (let index = 0; index < this.props.caratteristiche.length; index++) {
        //     let el = this.props.caratteristiche[index].Descrizione;
        //     if (!listaCategorie.includes(el)) listaCategorie.push(el);
        // }
        listaCategorie.push("Email");
        listaCategorie.push("Applicativo");
        listaCategorie.push("Telefono");
        listaCategorie.push("Altro telematico");
        //listaCategorie.push("Altro telematico -> Pagamenti bancari automatici");
        //listaCategorie.push("Altro telematico -> Piattaforma PAGOPA");
        listaCategorie.push("Non telematico indiretto");
        listaCategorie.push("Non telematico diretto");

        return listaCategorie;
    }

    /**
     * 
     */
    getSubAccordionElementList(subAccordion, index, isCheck) {
        let subAccordionRight = [];
        let subAccordionLeft = [];
        if (this.props.readOnly) {
            for (let x = 0; x < subAccordion.length; x++) {
                if ((x + 1) % 2 === 0) {
                    var element = '';
                    if (subAccordion[x].Checked) {
                        isCheck = true;
                        element = <div key={index + "canale" + x}>
                            <i className=" fa fa-check-square-o"></i> &nbsp;<strong>{subAccordion[x].Nome}</strong>

                            <br />
                            {subAccordion[x].NotaAggiuntiva !== undefined && subAccordion[x].NotaAggiuntiva}

                            <hr />
                        </div>;
                    } else {
                        element = <div key={this.props.categoryName + "canale" + x}>
                            <span className="testoGrigio">{subAccordion[x].Nome}</span><hr />
                        </div>;
                    }
                    subAccordionRight.push(element);
                } else {
                    if (subAccordion[x].Checked) {
                        isCheck = true;
                        element = <div key={index + "canale" + x}>
                            <i className=" fa fa-check-square-o"></i> &nbsp;<strong>{subAccordion[x].Nome}</strong>

                            <br />
                            {subAccordion[x].NotaAggiuntiva !== undefined && subAccordion[x].NotaAggiuntiva}

                            <hr />
                        </div>;
                    } else {
                        element = <div key={this.props.categoryName + "canale" + x}>
                            <span className="testoGrigio">{subAccordion[x].Nome}</span><hr />
                        </div>;
                    }
                    subAccordionLeft.push(element);
                }
            }
        } else {

            for (let x = 0; x < subAccordion.length; x++) {
                if ((x + 1) % 2 === 0) {
                    //console.log('subAccordion',subAccordion[x]);
                    let item = <div key={this.props.categoryName + "canale" + x}>
                        <input type="checkbox"
                            onChange={() => this.updateCaratteristica(subAccordion[x], this.props.categoriaCaratteristicaId)}
                            //defaultChecked={this.props.caratteristiche[i].Checked}
                            checked={subAccordion[x].Checked}
                        /> &nbsp;{subAccordion[x].Nome}
                        <br />
                        <input type="text"
                            hidden={!subAccordion[x].Checked}
                            value={(subAccordion[x].NotaAggiuntiva !== undefined && subAccordion[x].NotaAggiuntiva !== null) ? subAccordion[x].NotaAggiuntiva : ''}
                            onChange={(e) => this.updateNotaAggiuntiva(subAccordion[x], e)}
                            placeholder="Inserisci nota"
                            onBlur={this.props.saveDraft}
                            style={{ width: '100%' }} />

                        <hr />
                    </div>;

                    subAccordionRight.push(item);
                }
                else {
                    //console.log('subAccordion',subAccordion[x]);
                    let item = <div key={this.props.categoryName + "canale" + x}>
                        <input type="checkbox"
                            onChange={() => this.updateCaratteristica(subAccordion[x], this.props.categoriaCaratteristicaId)}
                            //defaultChecked={this.props.caratteristiche[i].Checked}
                            checked={subAccordion[x].Checked}
                        /> &nbsp;{subAccordion[x].Nome}
                        <br />
                        <input type="text"
                            hidden={!subAccordion[x].Checked}
                            value={(subAccordion[x].NotaAggiuntiva !== undefined && subAccordion[x].NotaAggiuntiva !== null) ? subAccordion[x].NotaAggiuntiva : ''}
                            onChange={(e) => this.updateNotaAggiuntiva(subAccordion[x], e)}
                            placeholder="Inserisci nota"
                            onBlur={this.props.saveDraft}
                            style={{ width: '100%' }} />

                        <hr />
                    </div>;
                    subAccordionLeft.push(item);
                }
            }
        }

        return [subAccordionLeft, subAccordionRight];
    }

    render() {

        var accordionList = [];
        if (this.props.caratteristiche.length) {
            let listaCategorieCanali = this.getCategorieCanali();
            for (let index = 0; index < listaCategorieCanali.length; index++) {
                let listaCanaliErogazione = this.props.caratteristiche;
                let canaliCategoria = [];
                let rightList = [];
                let leftList = [];
                let subAccordion = [];
                let subAccordionPAGOPA = [];
                let hasChecked = false;
                for (let x = 0; x < listaCanaliErogazione.length; x++) {
                    if (listaCanaliErogazione[x].Descrizione === listaCategorieCanali[index]) {
                        canaliCategoria.push(listaCanaliErogazione[x]);
                        hasChecked = hasChecked || listaCanaliErogazione[x].Checked;
                    }
                    if (listaCanaliErogazione[x].Descrizione.indexOf('Altro telematico -> Piattaforma PAGOPA') > -1)
                        subAccordionPAGOPA.push(listaCanaliErogazione[x]);
                    if (listaCanaliErogazione[x].Descrizione.indexOf('Altro telematico -> Pagamenti bancari automatici') > -1)
                        subAccordion.push(listaCanaliErogazione[x]);
                }

                for (let x = 0; x < canaliCategoria.length; x++) {

                    var item;

                    if (this.props.readOnly) {
                        if (canaliCategoria[x].Checked) {

                            item = <div key={index + "canale" + x}>
                                <i className=" fa fa-check-square-o"></i> &nbsp;<strong>{canaliCategoria[x].Nome}</strong>

                                <br />
                                {canaliCategoria[x].NotaAggiuntiva !== undefined && canaliCategoria[x].NotaAggiuntiva}

                                <hr />
                            </div>;
                        } else {
                            item = <div key={this.props.categoryName + "canale" + x}>
                                <span className="testoGrigio">{canaliCategoria[x].Nome}</span><hr />
                            </div>;
                        }
                    }
                    else
                        item = <div key={this.props.categoryName + "canale" + x}>
                            <input type="checkbox"
                                onChange={() => this.updateCaratteristica(canaliCategoria[x], this.props.categoriaCaratteristicaId)}
                                //defaultChecked={this.props.caratteristiche[i].Checked}
                                checked={canaliCategoria[x].Checked}
                            /> &nbsp;{canaliCategoria[x].Nome}
                            <br />
                            <input type="text"
                                hidden={!canaliCategoria[x].Checked}
                                value={(canaliCategoria[x].NotaAggiuntiva !== undefined && canaliCategoria[x].NotaAggiuntiva !== null) ? canaliCategoria[x].NotaAggiuntiva : ''}
                                onChange={(e) => this.updateNotaAggiuntiva(canaliCategoria[x], e)}
                                placeholder="Inserisci nota"
                                onBlur={this.props.saveDraft}
                                style={{ width: '100%' }} />

                            <hr />
                        </div>;


                    if ((x + 1) % 2 === 0)
                        rightList.push(item);
                    else
                        leftList.push(item);
                }

                let subAccordinList = this.getSubAccordionElementList(subAccordion, index, hasChecked);
                let subAccordionPAGOPAList = this.getSubAccordionElementList(subAccordionPAGOPA, index, hasChecked);
                let findI = subAccordion.findIndex(item => item.Checked === true);//
                let findJ = subAccordionPAGOPA.findIndex(item => item.Checked === true);

                let classTesto = findI > -1 ? 'testoNero' : 'testoGrigio0';
                let classTestoPAGOPA = findJ > -1 ? 'testoNero' : 'testoGrigio0';
                let classTestoAccordion = (hasChecked) ? 'testoNero' : 'testoGrigio0';
                //console.log('find', find, 'canaliCategoria', canaliCategoria);
                let accordionElement = '';
                if (this.getCategorieCanali()[index].indexOf('Altro telematico') === 0) {
                    classTestoAccordion = (hasChecked || findI > -1 || findJ > -1) ? 'testoNero' : 'testoGrigio0';
                    accordionElement = 
                    <div className="row" key={"accordionCategoriaCanale" + index}>                 
                    <div  className="card-body">
                        <div className="col-lg-12">
                            <span><a className={classTestoAccordion} data-bs-toggle="collapse" href={"#accordionCategoriaCanale" + index}><strong>{listaCategorieCanali[index]}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            <hr/>
                        </div>
                        </div>
                    </div>

                } else {
                    accordionElement = 
                    <div className="row" key={"accordionCategoriaCanale" + index}>                 
                        <div  className="card-body">
                            <div className="col-lg-12">
                                <span><a className={classTestoAccordion} data-bs-toggle="collapse" href={"#accordionCategoriaCanale" + index}><strong>{listaCategorieCanali[index]}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            <hr/>
                            </div>
                        </div>
                     </div>
                }

                accordionList.push(accordionElement);

                //accordionList.push(<hr key={"hrCategoriaCanale" + index} />);


                if (this.getCategorieCanali()[index].indexOf('Altro telematico') === 0) {
                    accordionList.push(
                <div key={"collapseCanale" + index} className="card-bodyPlus">
                    <div id={"accordionCategoriaCanale" + index} className="collapse spazio10" data-parent="#accordion">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                        </div>
                        
                        <div className="col-lg-12">
                            <span><a href={"#subAccordion" + index} className={classTesto} data-bs-toggle="collapse" ><strong>{'Pagamenti bancari automatici'}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                            <div className="card-body"> 
                                <div className="row collapse" id={"subAccordion" + index} data-parent="#accordion">
                                    <div className="col-lg-6 marginRight">
                                        {subAccordinList[0]}{/*left*/}
                                    </div>
                                    <div className="col-lg-6 marginRight">
                                        {subAccordinList[1]}{/*right*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-12">
                            <span><a href={'#subAccordionPagpPA' + index} className={classTestoPAGOPA} data-bs-toggle="collapse" ><strong>{'Piattaforma PAGOPA'}</strong> <i className="iconRightBlack fa fa-chevron-down"></i></a></span>
                                    <div className="card-body"> 
                                    <div className="row collapse" id={"subAccordionPagpPA" + index} data-parent="#accordion">
                                    <div className="col-lg-6 marginRight">
                                        {subAccordionPAGOPAList[0]}{/*left*/}
                                    </div>
                                    <div className="col-lg-6 marginRight">
                                        {subAccordionPAGOPAList[1]}{/*right*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                       
                        </div>
                    </div>
                    </div>
                    );
                } else {
                    accordionList.push(
                    <div key={"collapseCanale" + index} className="card-bodyPlus">
                        <div  id={"accordionCategoriaCanale" + index} className="collapse spazio10" data-parent="#accordion">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 marginRight">
                                            {leftList}
                                        </div>
                                        <div className="col-lg-6 marginRight">
                                            {rightList}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
                }
            }
        }

        return (


            <div>
                <div className="row">
                    <div className="col-lg-12 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i style={{ visibility: this.isIconHidden() }} className="testoVerde fa fa-check"></i> {this.props.categoryName + (this.props.readOnly ? "" : " *")}<i className="iconRight fa fa-chevron-down"></i></a>
                    </div>
                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="collapse" data-parent="#accordion">
                    
                     {accordionList}
                    
                </div>
            </div>
        );
    }
};