import * as React from 'react';

// component that renders a single minaccia row
export class ThreatSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emptyTest: '',
            isDisabledGradoImplementatione: false,
            isDisabledDataChiusura: false,
            //caratteristiche: this.props.caratteristiche
        };
        this.getOptionList = this.getOptionList.bind(this);
    }

    /**
     * 
     */
    getOptionList() {
        let menaceList = [];
        try {
            let array = this.props.menaceList;
            for (let index = 0; index < array.length; index++) {
                let optionElement = <option value={array[index]} key={"threat" + index}>{array[index]}</option>;
                menaceList.push(optionElement);
            }
            return menaceList

        } catch (error) {
            console.error(error);
            return [];
        }
    }


    render() {
        return (
            <div className="col-lg-2 card-headerLeft nascondiCell">
                <select className="form100 font16"
                    id="select7"
                    onChange={this.props.updateMenace}>
                    <option value="-1">Tutte</option>
                    {this.getOptionList()}
                    {/* <option value="Conduzione di un attacco DoS">Conduzione di un attacco DoS</option> */}
                </select>
            </div>
        )
    }
}