import React, { Component } from 'react';
import $ from 'jquery';
import { ElencoExecutiveSummaryRow } from './ElencoExecutiveSummaryRow';
import { API_CONFIG } from '../../public/js/apiServices';
import { Breadcrumb } from '../Common/Breadcrumb';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import { SearchInput } from '../Common/SearchInput';

let messageError = '';

var STATI = [];
/*
STATI[-1] = { option: "TUTTE", statoCaratterizzazione: "-1" };
STATI[0] = { option: "COMPLETATO", statoCaratterizzazione: "CARATTERIZZATO" };
STATI[1] = { option: "INCOMPLETO", statoCaratterizzazione: "PARZIALMENTE_CARATTERIZZATO" };
STATI[2] = { option: "DA COMPILARE", statoCaratterizzazione: "DA_CARATTERIZZARE" };
*/
STATI["-1"] = "Tutti";
STATI["1"] = "incompleto";
STATI["2"] = "pubblicato";

const LINK = [
    { nome: "Executive Summary Servizi", url: "/ElencoExecutiveSummary" },
];

export class ElencoExecutiveSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: [],
            rowsTable: [],
            selectedColumn: "",
            selectedCategory: sessionStorage.getItem('selectedCategoryCS') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedCategoryCS') : '-1',
            selectedState: sessionStorage.getItem('selectedStateCS') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedStateCS') : this.props.riepilogo === "true" ? "CARATTERIZZATO" : '-1',
            selectedTypology: sessionStorage.getItem('selectedTypologyCS') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedTypologyCS') : '-1',
            search: sessionStorage.getItem('searchCS') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchCS') : '',
            searchAll: sessionStorage.getItem('searchAllCS') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchAllCS') : '',
            categories: [],
            typologies: [],
            isAscName: true,
            activePage: 1,
            currentPageListServices: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...'
            /*
             selectedCategory: '-1',
             selectedState: '-1',
             selectedTypology: '-1',
             search: '',
             */
        }
        this.areThereModifiedServices = this.areThereModifiedServices.bind(this);
        this.setCategoriesTypologies = this.setCategoriesTypologies.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.updateState = this.updateState.bind(this);
        this.updateTypology = this.updateTypology.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.toString = this.toString.bind(this);
        this.updateRowsTable = this.updateRowsTable.bind(this);
        this.orderServicesList = this.orderServicesList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);

        if (this.props.riepilogo === "true")
            this.setState({ selectedState: "CARATTERIZZATO" });
    }

    componentDidMount() {

        console.log('token:', sessionStorage.getItem('token'))
        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getServiziAnagrafica,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    console.log('response:', response);
                    this.setState({ services: response.data });
                    this.setState({ rowsTable: response.data }, this.updateRowsTable);
                    this.handlePageChange(1);
                    //this.setState({ categories: getCategoriesList(response.data) });
                    //this.setState({ typologies: getTypologiesList(response.data) });
                    this.setCategoriesTypologies();
                    this.setState({ emptyTest: "Nessun Servizio con i criteri specificati" });
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });

        if ((sessionStorage.getItem('selectedCategoryCS') !== null ||
            sessionStorage.getItem('selectedStateCS') !== null ||
            sessionStorage.getItem('selectedTypologyCS') !== null ||
            sessionStorage.getItem('searchAllCS') !== null) &&
            this.props.riepilogo !== "true")
            $("#ricerca").get(0).click();
    }

    /**
     * 
     */
    setCategoriesTypologies() {
        $.ajax({
            //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie',
            url: API_CONFIG.getCategorieSottoCategorie,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
                //console.log('response:', response);
                this.setState({ typologies: response.data.SottoCategoriaServizio });
                this.setState({ categories: response.data.CategoriaServizio });
                console.log('SottoCategoriaServizio:', this.state.typologies);
                console.log('CategoriaServizio:', this.state.categories);
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });
    }

    updateCategory(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedCategoryCS', val);
        this.setState({ selectedCategory: val }, this.updateRowsTable);
        //this.setState({ selectedCategory: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateState(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedStateCS', val);
        this.setState({ selectedState: val }, this.updateRowsTable);
        //this.setState({ selectedState: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateTypology(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('selectedTypologyCS', val);
        this.setState({ selectedTypology: val }, this.updateRowsTable);
        //this.setState({ selectedTypology: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchCS', val);
        this.setState({ search: val }, this.updateRowsTable);
        //this.setState({ search: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateSearchAll(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllCS', val);
        this.setState({ searchAll: val }, this.updateRowsTable);
        //this.setState({ searchAll: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }


    /**
     * 
     */
    toString(list) {
        var toString = '';
        if ((list.length) || list.length !== 0) {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2);
        }
        return "-";
    }

    /**
      * 
      * @param {*} val 
      * @param {*} field 
    */
    updateRowsTable() {
        let services = this.state.services;
        //let rowsTable = this.state.rowsTable;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedCategory = this.state.selectedCategory;
        let selectedState = this.state.selectedState;
        let selectedTypology = this.state.selectedTypology;

        try {
            var list = [];
            if (searchAll === '' && search === '' && selectedCategory === '-1' && selectedState === '-1' && selectedTypology === '-1') {
                list = services;
            } else {

                for (let index = 0; index < services.length; index++) {
                    const element = services[index];
                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente !== null && element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente !== null && element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.Descrizione !== null && element.Descrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {

                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                            if (selectedState === '-1' || element.Stato === parseInt(selectedState, 10)) {

                                if (selectedCategory === '-1' || this.toString(element.Categorie).toUpperCase().indexOf(selectedCategory.toUpperCase()) > -1) {

                                    if (selectedTypology === '-1' || this.toString(element.Tipologie).toUpperCase().indexOf(selectedTypology.toUpperCase()) > -1) {
                                        list.push(element);
                                    }
                                }
                            }
                        }
                    }

                }
            }
            //console.log('list:', list);
            //this.setState({ rowsTable: list });
            this.setState({ rowsTable: list }, () => { this.handlePageChange(1) });

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * 
     */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let currentPageListServices = this.state.rowsTable;
        key = key.toLowerCase();
        this.setState({ selectedColumn: key });
        switch (key) {
            case 'nome':
                if (asc) {
                    list = currentPageListServices.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });

                } else list = currentPageListServices.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                this.setState({ isAscName: !asc });
                break;
            case 'data':
                if (asc) {
                    list = currentPageListServices.sort(function (a, b) { return new Date(a.DataPubblicazione).getTime() - new Date(b.DataPubblicazione).getTime(); });

                } else list = currentPageListServices.sort(function (a, b) { return new Date(b.DataPubblicazione).getTime() - new Date(a.DataPubblicazione).getTime(); });
                this.setState({ isAscName: !asc });
                break;
            case 'dataupdatecriticità':
                if (asc) {
                    list = currentPageListServices.sort(function (a, b) { return new Date(a.DataUpdateProfiloDiCriticità).getTime() - new Date(b.DataUpdateProfiloDiCriticità).getTime(); });

                } else list = currentPageListServices.sort(function (a, b) { return new Date(b.DataUpdateProfiloDiCriticità).getTime() - new Date(a.DataUpdateProfiloDiCriticità).getTime(); });
                this.setState({ isAscName: !asc });
                break;
            case 'criticità':
                if (asc) {
                    list = currentPageListServices.sort(function (a, b) { return a.Criticità - b.Criticità; });


                } else list = currentPageListServices.sort(function (a, b) { return b.Criticità - a.Criticità; });
                this.setState({ isAscName: !asc });
                break;
            case 'stato':
                if (asc) {
                    list = currentPageListServices.sort(function (a, b) { return a.Stato - b.Stato; });

                } else list = currentPageListServices.sort(function (a, b) { return b.Stato - a.Stato; });
                this.setState({ isAscName: !asc });
                break;
            default:
                list = currentPageListServices;
                break;
        }

        this.setState({ rowsTable: list }, () => { this.handlePageChange(this.state.activePage) });
    }

    /**
     * 
     */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let rowsTable = this.state.rowsTable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (rowsTable[i])
                listing_table.push(rowsTable[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListServices: listing_table });
    }

    /**
     * 
     */
    numPages() {
        return Math.ceil(this.state.rowsTable.length / this.state.itemsCountPerPage);
    }

    /**
     * 
     */
    areThereModifiedServices() {
        let servicesList = this.state.services;
        let areThereModifiedServices = false;
        try {

            for (let index = 0; index < servicesList.length; index++) {
                let tsDataAggiornamento = new Date(servicesList[index].DataAggiornamento).getTime();
                let tsDataPubblicazione = new Date(servicesList[index].DataPubblicazione).getTime();
                if (tsDataAggiornamento > tsDataPubblicazione) {
                    return true;
                }

            }
            //console.log('areThereModifiedServices? ', areThereModifiedServices);
            return areThereModifiedServices;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    render() {

        var rows = '';
        if (!this.state.currentPageListServices.length) {
            rows = <div className="row">
                {this.state.emptyTest}
            </div>
        } else {
            rows = this.state.currentPageListServices.map(function (field, i) {
                return (
                    <ElencoExecutiveSummaryRow
                        key={i}
                        rowId={i}
                        service={field}
                        categoriesTostring={this.toString(field.Categorie)}
                        typologiesTostring={this.toString(field.Tipologie)}
                        stato={STATI[field.Stato]}
                        riepilogo={this.props.riepilogo}
                        PA={this.props.PA}
                    />
                );
            }.bind(this)
            );
        }
        /**
        * 
        var stateOptions = Object.keys(STATI).map(function (key, i) {
            return (
                <option key={i} value={key}>{STATI[key]}</option>
                );
            });
        */

        /**
        * 
        */
        var typeOption = this.state.typologies.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        var categoryOption = this.state.categories.map(function (item, i) {
            return (
                <option key={i} value={item.Nome}>{item.Nome}</option>
            );
        });

        var outerClass = this.props.riepilogo === "true" ? "" : "u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top";
        var outerClassContainer = this.props.riepilogo === "true" ? "" : "container cnsContainer";
        var headerClass = this.props.riepilogo === "true" ? "col-lg-10 card-headerLeft nascondiCell" : "col-lg-10 card-headerLeft nascondiCell";
        var classSpazio = this.props.riepilogo === "true" ? "font16 spazio10" : "";

        return (

            <div className={outerClass}>

                <div className={outerClassContainer}>

                    <div hidden={this.props.riepilogo === "true"} className="row">

                        <div className="col-lg-12">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Elenco servizi</span>
                        </div>
                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="row">
                        <div className="col-lg-12 giustifica">
                            <p>La pagina espone l’elenco dei Servizi della Azienda per i quali è possibile visualizzare una sintesi dei risultati dell’Analisi del Rischio e del trattamento del Rischio. Se si seleziona un Servizio si visualizza il dettaglio anagrafico. Selezionando le azioni si accede al riepilogo dei risultati dell’analisi del rischio sul servizio selezionato.
                            </p>
                        </div>
                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="linea0066cc"></div>
                    <div hidden={this.props.riepilogo === "true"} className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-10"></div>

                            <div className="col-lg-2">

                            </div>

                        </div>
                    </div>


                    <div className="container cnsContainer">
                        <div className="row">

                            <div className="col-lg-8 spazio30"></div>
                            {/*
                            <div className="col-lg-2">
                                <div className="input-group">
                                    <input className="form-control py-2 border-right-0 border font16 testoBlu"
                                        type="search"
                                        id="example-search-input"
                                        value={this.state.search}
                                        onChange={this.updateSearch}
                                        placeholder={'CERCA SERVIZIO...'}
                                    />
                                    <span className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border font16 testoBlu" type="button">
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            */}
                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} />

                            <div className="col-lg-2">
                                <div className="marginTop5">
                                    <a id='ricerca' className="font16" data-bs-toggle="collapse" href="#collapseRicerca"><i className="fa fa-filter"></i>{" RICERCA AVANZATA"}</a>	</div>
                            </div>

                            {this.props.riepilogo === "true" && <div className="col-lg-8"><div><strong>Elenco servizi caratterizzati:</strong>
                                <a data-bs-toggle="collapse" href="#collapse10"><span class=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                                <div id="collapse10" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Caratterizzati rispetto al
                            totale dei servizi censiti sul sistema.</div>
                            </div>	</div>}
                            {this.props.riepilogo !== "true" && <div className="col-lg-8"></div>}

                            <div id="collapseRicerca" className="col-lg-12 collapse font16" data-parent="#accordion">
                                <div className="spazio10">	</div>

                                <div className="row">

                                    <div className="col-lg-6">
                                        <strong> Ricerca libera:</strong> <br />
                                        <input id="search"
                                            value={this.state.searchAll}
                                            type="text"
                                            onChange={this.updateSearchAll}
                                            className="form300"
                                        />
                                        <div className="spazio10"></div>

                                    </div>

                                    <div className="col-lg-6">
                                        <strong> Stato dell'analisi:</strong><br />
                                        <select name="select30" id="select30" className="select300"
                                            value={this.state.selectedState}
                                            onChange={this.updateState}>
                                            {/* {stateOptions} */}
                                            <option value='-1'>Tutte</option>
                                            <option value='1'>incompleto</option>
                                            <option value='2'>pubblicato</option>
                                        </select>
                                    </div>


                                    <div className="col-lg-6">
                                        <strong> Tipologia:</strong><br />
                                        <select name="select4" id="select4" className="select300"
                                            value={this.state.selectedTypology}
                                            onChange={this.updateTypology}>
                                            <option value='-1'>Tutte</option>
                                            {typeOption}
                                        </select>
                                    </div>

                                    <div className="col-lg-6">
                                        <strong> Categoria:</strong><br />
                                        <select name="select5" id="select5" className="form300"
                                            value={this.state.selectedCategory}
                                            onChange={this.updateCategory}>
                                            <option value='-1'>Tutte</option>
                                            {categoryOption}
                                        </select>
                                    </div>
                                </div>

                                <div className="spazio10"></div>
                                <hr />

                            </div>

                            <div className={headerClass}><strong onClick={() => this.orderServicesList('nome')} style={{ cursor: 'pointer' }}> Servizio
                            <i className={(this.state.selectedColumn.localeCompare("nome") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </strong></div>
                            {/*
                            <div className="col-pixel-width-100 card-headerCenter nascondiCell">
                                <strong onClick={() => this.orderServicesList('data')} style={{ cursor: 'pointer' }}>Aggiornato
                            <i className={(this.state.selectedColumn.localeCompare("data") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>

                            this.props.riepilogo !== "true" && <div className="col-lg-1 card-headerCenter nascondiCell">
                                <strong onClick={() => this.orderServicesList('stato')} style={{ cursor: 'pointer' }}> Stato
                                <i className={(this.state.selectedColumn.localeCompare("stato") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong>
                            </div>*/}

                            <div className="col-lg-2 card-headerCenter nascondiCell"><strong> Azioni</strong></div>

                        </div>


                        <span className="lineaGrigiaT0"></span>
                        <div className={classSpazio}>
                            {rows}
                        </div>

                        <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.rowsTable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                    <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Analisi del Rischio creata!'} />
                    <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={'Prima di effettuare il Calcolo compilare tutte le risposte!'} />
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
                    <br />
                    <br />
                    <br />
                </div>
            </div>

        )
    }
}