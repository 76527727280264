import React from 'react'
import $ from "jquery";
import { API_CONFIG } from '../../public/js/apiServices';

export class ImportaLibrary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            isFileSelect: false,

        }
        this.updateFile = this.updateFile.bind(this);
        this.doUpload = this.doUpload.bind(this);
    }

    updateFile(event) {
        let files = event.target.files;

        if (files && files.length > 0) {
            this.setState({ file: files[0], isFileSelect: true }, () => { console.log('file', this.state.file); });
        } else {
            this.setState({ file: null });
        }
    }

    /**
     * 
     * @param {*} filename 
     * @returns true if the file Extension is xlsx othewise return false
     */
    isExtensionValid(file) {
        try {
            if (file.name) {
                let fileExtension = file.name.split('.').pop().toLowerCase();
                if (fileExtension.length > 0) {
                    //console.log('fileExtension : ' + fileExtension);
                    return (fileExtension.localeCompare('xlsx') === 0) ? true : false;
                }
            } return false
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
     * 
     */
    doUpload() {
        let file = this.state.file;
        let formData = new FormData();
        formData.append("upfile", file);
        if (this.isExtensionValid(file) && file.size > 0) {
            $.ajax({
                url: API_CONFIG.importExcel,
                type: "POST",
                headers: { 'token': sessionStorage.getItem('token') },
                data: formData, //add the FormData object to the data parameter
                dataType: 'text',  // what to expect back from the PHP script, if anything
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },

                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },

                success: function (response, status, jqxhr) {
                    //handle success
                    this.fileInput.value = '';
                    //this.setState({ successMessage: "Caricamento del file effettuato con successo", file: null }, () => $('#btnSuccess').get(0).click());
                    this.setState({ file: null });
                    this.props.showModal("Caricamento del file effettuato con successo",'success');
                }.bind(this),
                error: function (jqxhr, status, errorMessage) {
                    //handle error
                    errorMessage = 'Nessun file caricato';
                    console.error(status + " : " + jqxhr.responseText);
                    let responseJson = JSON.parse(jqxhr.responseText);
                    if (responseJson.message && responseJson.message.indexOf('SyntaxError') < 0) {
                        errorMessage = responseJson.message;
                    }
                    //this.setState({ errorMessage: errorMessage }, () => $('#btnImportError').get(0).click());
                    this.props.showModal(errorMessage,'error');
                }.bind(this),
            });
            //} else return this.setState({ warningMessage: "L'estensione del file non è corretta" }, () => $('#btnValidate').get(0).click());
        } else return this.props.showModal("L'estensione del file non è corretta",'warning');
    }

    /**
     * 
     * @param {*} responseJson 
     */
    toStrnigError(responseJson) {
        return responseJson.message.split('api/').pop().split('/')[0];
    }

    render() {

        return (
            <div className="row card-headerInfo">
                <div className="col-3">
                    <input id='fileSelect' type="file" onChange={this.updateFile} ref={ref => this.fileInput = ref} />
                </div>
                <div className='col-1'></div>
                <div className='col-2'>
                    <a href="javascript:void(0);">
                        <button onClick={() => this.doUpload()} type="button" disabled={!this.state.file} className="btn btn-warning btn-lg btn-block" style={{ paddingLeft: '5px', paddingRight: '5px' }} >Aggiorna Library</button>
                    </a>
                </div>
            </div>
        )
    }
}

//export default ImportaLibrary