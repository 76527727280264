import * as React from 'react';
import history from '../history';
import LIVELLO from '../data/criticality';
import ICON from '../data/IconLevel';
import IconImpatti from '../data/IconLevelImpatti';
import { Util } from '../data/Util';

/**
 * 
 * @param {*} index 
 */
function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}


// component that renders a single user
export class ListaServiziAnalisiRischioRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            name: props.name,
            price: props.price,
            category: props.category
        };
        this.changeAppMode = this.changeAppMode.bind(this);
        this.toString = this.toString.bind(this);
        //this.getImpatto = this.getImpatto.bind(this);
        this.isServiceUpdated = this.isServiceUpdated.bind(this);
        this.isVisible = this.isVisible.bind(this);
        this.redirect = this.redirect.bind(this);
        this.redirectReport = this.redirectReport.bind(this);
        this.getRIDAccordion = this.getRIDAccordion.bind(this);
        this.getRischioAggregatoMinacce = this.getRischioAggregatoMinacce.bind(this);
        this.getRischioAggregato = this.getRischioAggregato.bind(this);
        this.getIUC = this.getIUC.bind(this);

    }

    changeAppMode(event) {
        console.log('value', event.target.value);
        this.setState({ value: parseInt(event.target.value, 10) });
        //alert('this.props.value',this.state.value);
        /*
        */
    }


    /**
         * 
         * @param {*} index 
         * @param {*} array 
         */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    getRischioAggregato() {
        //console.log('rischioAttuale', this.props.service.RischioAttuale);
        let risk = '';
        //let iconSrc = IconImpatti[this.props.service.RischioAggregato].src;
        let iconSrc = this.getRisk(this.props.service.RischioAggregato, IconImpatti).src;
        let className = '';
        try {
            //let risk = LIVELLO[(this.props.service.RischioAggregato) ? this.props.service.RischioAggregato : ''].name;
            risk = this.getRisk(this.props.service.RischioAggregato, LIVELLO).name;

            switch (this.props.service.RischioAggregato) {
                case 1:
                    className = "testoVerde h2";
                    break;
                case 2:
                    className = "testoArancio h2";
                    break;
                case 3:
                    className = "testoRosso h2";
                    break;
                default:
                    className = 'testoNero h2';
                    break;
            }
            return <div>
                <img src={iconSrc} width="20" height="20" alt="" />
                <span className={className}><strong> {risk}</strong></span></div>;
        } catch (error) {
            console.error(error);
            return <div className='testoNero h2'><strong></strong></div>;
        }
    }

    /**
     * 
     */
    toString(list) {
        var toString = '';
        for (let index = 0; index < list.length; index++) {
            toString += list[index].Nome + ' | ';
        }
        return toString.substring(0, toString.length - 2)
    }

    getRIDAccordion() {

        var r = this.props.service.ImpattoRAssessment === undefined ? this.props.service.ImpattoR : this.props.service.ImpattoRAssessment;
        var i = this.props.service.ImpattoIAssessment === undefined ? this.props.service.ImpattoI : this.props.service.ImpattoIAssessment;
        var d = this.props.service.ImpattoDAssessment === undefined ? this.props.service.ImpattoD : this.props.service.ImpattoDAssessment;

        if (d < 1 || isNaN(d))
            return <p><strong>Impatto R-I-D:</strong><br /></p>;

        var nameR = "";
        var classR = "";
        switch (r) {
            case 1:
                classR = "testoVerde h2";
                nameR = "BASSO";
                break;
            case 2:
                classR = "testoArancio h2";
                nameR = "MEDIO";
                break;
            case 3:
                classR = "testoRosso h2";
                nameR = "ALTO";
                break;
            case 4:
                classR = "testoNero h2";
                nameR = "CRITICO";
                break;
            default://
                break;
        }

        var nameI = "";
        var classI = "";
        switch (i) {
            case 1:
                classI = "testoVerde h2";
                nameI = "BASSO";
                break;
            case 2:
                classI = "testoArancio h2";
                nameI = "MEDIO";
                break;
            case 3:
                classI = "testoRosso h2";
                nameI = "ALTO";
                break;
            case 4:
                classI = "testoNero h2";
                nameI = "CRITICO";
                break;
            default://
                break;
        }

        var nameD = "";
        var classD = "";
        switch (d) {
            case 1:
                classD = "testoVerde h2";
                nameD = "BASSO";
                break;
            case 2:
                classD = "testoArancio h2";
                nameD = "MEDIO";
                break;
            case 3:
                classD = "testoRosso h2";
                nameD = "ALTO";
                break;
            case 4:
                classD = "testoNero h2";
                nameD = "CRITICO";
                break;
            default://
                break;
        }

        return (
            <p>
                <strong>Impatto R-I-D:</strong><br />
                Riservatezza: <img src={this.getRisk(r, IconImpatti).src} width="20" height="20" alt="" />
                <span className={classR}>
                    <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    Integrità: <img src={this.getRisk(i, IconImpatti).src} width="20" height="20" alt="" />
                <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                    Disponibilità: <img src={this.getRisk(d, IconImpatti).src} width="20" height="20" alt="" />
                <span className={classD}>    <strong> {nameD}</strong></span>
            </p>
        );
    }

    getIUC()
    {
        var iuc = this.props.service.RischioAggregato;
        var text = "";
        if(iuc != undefined && iuc != null && iuc > 0)
            text= iuc +"%";

        return <div className="col-lg-1 card-header"><strong>{text}</strong></div>;
    }

    /**
     * 
     */
    getRischioAggregatoMinacce() {
        //console.log('rischioAttuale', this.props.service.RischioAttuale);
        let className = '';
        let risk = "";
        try {
            //risk = LIVELLO[(this.props.service.RischioAggregato) ? this.props.service.RischioAggregato : ''].name;
            risk = this.getRisk(this.props.service.Criticità, LIVELLO).name;
            switch (this.props.service.Criticità) {
                case 1:
                    className = "col-lg-1 card-header bgVerde";
                    break;
                case 2:
                    className = "col-lg-1 card-header bgArancio";
                    break;
                case 3:
                    className = "col-lg-1 card-header bgRosso";
                    break;
                default:
                    className = 'col-lg-1 card-header';
                    break;
            }
            return <div className={className}><strong>{risk}</strong></div>;
        } catch (error) {
            console.error(error);
            return <div className='col-lg-1 card-header'><strong></strong></div>;
        }
    }



    /**
     * 
     */
    isVisible() {
        try {

            let stato = this.props.stato;
            if (stato) {
                if (stato.toLocaleUpperCase().indexOf("COMPILARE") > 0)
                    return 'hidden';
            }
            return 'visible';
        } catch (error) {
            console.error(error);
            //debugger
            return '';
        }
    }

    redirect(path) {
        try {
            window.sessionStorage.setItem("serviceId", this.props.service.ServizioId);
            window.sessionStorage.setItem("serviceName", this.props.service.Nome);
            window.sessionStorage.setItem("serviceCriticità", this.props.service.Criticità);
            window.sessionStorage.setItem("serviceImpattoR", this.props.service.ImpattoR);
            console.log('service', this.props.service);
            //debugger
            if (path !== undefined) {
                if (path.indexOf('/') === 0)
                    history.push(path);
                else if (path.indexOf('/') === -1) {
                    history.push('/' + path);
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }

    redirectReport() {
        window.sessionStorage.setItem("servizioId", this.props.service.ServizioId); 
        window.sessionStorage.setItem("serviceName", this.props.service.Nome); 
        history.push('RiepilogoDatiAnalisiRischioServizio');
    }


    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    /**
     * 
     */
    isServiceUpdated() {
        try {
            let tsDataAggiornamento = new Date(this.props.service.DataModifica).getTime();
            let tsDataCalcoloCriticità = new Date(this.props.service.DataCalcoloRischioAttuale).getTime();

            return tsDataAggiornamento > tsDataCalcoloCriticità;
        } catch (error) {
            return false;
        }
    }

    render() {
        var tooltip = this.isVisible() === "visible" ? "MODIFICA" : "COMPILA";
        var criticità = this.props.service.CriticitàAssessment;
        var iuc = this.props.service.RischioAggregato;
        //let iconSrc = ICON[criticità].src;
        //let iconLabel = ICON[criticità].label;
        let iconSrc = this.getRisk(criticità, ICON).src;
        let iconLabel = this.getRisk(criticità, ICON).label;
        let profiloCriticità = <p><strong>Profilo di criticità dell'applicazione</strong></p>;
        if (criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità dell'applicazione</strong>&nbsp;&nbsp;
                                    <img src={iconSrc} width="20" height="20" alt="" />

                <span className={getClassName(criticità)}>
                    <strong>{" " + iconLabel}</strong></span>
            </p>;
        }

        let divIUC = <p><strong>IUC</strong></p>;
        if (iuc > 0) {
            divIUC = <p>
                <strong>IUC</strong>&nbsp;&nbsp;
                {iuc +"%"}
            </p>;
        }

        //let iconVisibility = (this.props.service.StatoAssessmentId > 3) ? "hidden" : "visible";

        return (

            <div>
                <div className="row">
                    {/*1a colonna Nome Servizio */}
                    <div className="col-lg-7 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            {this.props.service.Nome}<i className="iconRight fa fa-chevron-down"></i>
                        </a>&nbsp; &nbsp;
                        <i hidden={!this.isServiceUpdated()} className="fa fa-warning testoRosso"></i>
                    </div>
                    {/*2a colonna Aggiornato */}
                    <div className="col-lg-2 card-header">{this.toStringDate(this.props.service.DataCalcoloRischioAttuale).split(" ")[0]}</div>
                    {/*3a colonna Rischio */}
                    {this.getIUC()}
                    {/*4a colonna Analisi */}
                    <div className="col-lg-1 card-header">{this.props.stato}</div>
                    {/*5a colonna Azioni */}
                    <div className="col-lg-1 card-header ">
                        <a onClick={() => this.redirect('GapAnalysisVisualizza', this.props.service.ServizioId, this.props.service.Nome)}
                            href="javascript:void(0)"><i className="fa fa-eye" title="VEDI"></i>
                        </a>&nbsp;
                        <a onClick={() => this.redirect('GapAnalysisModifica', this.props.service.ServizioId, this.props.service.Nome)}
                             href="javascript:void(0)">
                            <i className="fa fa-pencil" title={tooltip}></i>
                        </a>
                        {/* &nbsp;
                        <a onClick={() => this.redirectReport()}
                            hidden={this.props.service.StatoAssessmentId < 4}
                            href="javascript:void(0)">
                            <i className="fa fa-pie-chart" title={tooltip}></i>
                        </a> */}
                    </div>

                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row  card-headerLight">
                        <div className="col-lg-6"><br />
                            <strong>Referente</strong><br />
                            {(this.props.service.Referente) ? this.props.service.Referente : "-"}<hr />
                        </div>
                        <div className="col-lg-6"><br />
                            <strong>Struttura</strong><br />
                            {(this.props.service.StrutturaReferente) ? this.props.service.StrutturaReferente : "-"}<hr />
                        </div>

                        <div className="col-lg-12">
                            <strong>Descrizione</strong><br />
                            {(this.props.service.Descrizione) ? this.props.service.Descrizione : "-"}<hr />
                        </div>
                        <div className="col-lg-12">
                            {profiloCriticità}<hr />
                        </div>
                        <div className="col-lg-12">
                            {divIUC}<hr />
                        </div>
                        <div className="col-lg-6">
                            <strong>Data ultima Gap Analysis</strong><br />
                            {this.toStringDate(this.props.service.DataCalcoloRischioAttuale)}
                            <hr />
                        </div>
                        <div className="col-lg-6">
                            <strong>Data ultima modifica dei dati</strong><br />
                            {this.toStringDate(this.props.service.DataModifica)}
                            <hr />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
};