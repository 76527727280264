import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';

// component that renders a single riskAppetite row
export class RiskAppetiteRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.updateRiskAppetite = this.updateRiskAppetite.bind(this);
        this.getRiskappetiteNome = this.getRiskappetiteNome.bind(this);
    }

    updateRiskAppetite(tipologiaId, categoriaId, event) {
        let val = event.target.value;
        this.props.updateRiskAppetite(tipologiaId, categoriaId, parseInt(val, 10));
    }

    /**
     * @param {*} indexOfImpact 
     */
    impattoToString(indexOfImpact) {
        try {
            let index = parseInt(indexOfImpact, 10);
            return IconImpatti[index].label;

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    getComboBox(tipologiaId, categoriaId, RiskAppetite) {
        var comboBox =
            <select className="form100" value={RiskAppetite} onChange={(e) => this.updateRiskAppetite(tipologiaId, categoriaId, e)}>
                <option value='1'> Basso</option>
                <option value="2"> Medio</option>
                <option value='3'> Alto</option>
                <option value="4"> Critico</option>
            </select>;

        return comboBox;
    }

    getRiskappetiteNome() {
        try {
            return this.props.riskappetite1.Nome;

        } catch (error) {
            return '';
        }
    }

    render() {

        return (
            <div key={this.props.rowId}>
                <div className="row">
                    <div className="col-lg-4">
                        {this.getRiskappetiteNome()}
                    </div>
                    <div className="col-lg-2">
                        {this.getComboBox(this.props.riskappetite1.TipologiaImpattoId,
                            this.props.riskappetite1.CategoriaServizioId,
                            this.props.riskappetite1.RiskAppetite,
                        )}
                    </div>
                    <div className="col-lg-2">
                        {this.getComboBox(this.props.riskappetite3.TipologiaImpattoId,
                            this.props.riskappetite3.CategoriaServizioId,
                            this.props.riskappetite3.RiskAppetite,
                        )}
                    </div>
                    <div className="col-lg-2">
                        {this.getComboBox(this.props.riskappetite4.TipologiaImpattoId,
                            this.props.riskappetite4.CategoriaServizioId,
                            this.props.riskappetite4.RiskAppetite,
                        )}
                    </div>
                    <div className="col-lg-2">
                        {this.getComboBox(this.props.riskappetite2.TipologiaImpattoId,
                            this.props.riskappetite2.CategoriaServizioId,
                            this.props.riskappetite2.RiskAppetite,
                        )}
                    </div>

                </div>
                <hr />
            </div>
        )
    }
}