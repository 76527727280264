import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import ICON from '../data/IconLevel';
import history from '../history';
import LIVELLO from '../data/criticality';
import { Util } from '../data/Util';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

function redirect(path, serviceId, nome) {
    try {
        window.sessionStorage.setItem("servizioId", serviceId);
        window.sessionStorage.setItem("serviceName", nome);
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

function getClassNameAccordion(index) {
    let className = 'col-lg-1 card-header';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "col-lg-1 card-header bgVerde";
            break;
        case 2:
            className = "col-lg-1 card-header bgArancio";
            break;
        case 3:
            className = "col-lg-1 card-header bgRosso";
            break;
        case 4:
            className = "col-lg-1 card-header bgNero";
            break;
        default:
            className = "col-lg-1 card-header";
            break;
    }
    return className;
}



function getRIDAccordion(r, i, d) {
    //console.log("impatto i", r);
    if (r < 1 || isNaN(r))
        return <p><strong>Impatto R-I-D:</strong><br /></p>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "testoVerde h2";
            nameR = "BASSO";
            break;
        case 2:
            classR = "testoArancio h2";
            nameR = "MEDIO";
            break;
        case 3:
            classR = "testoRosso h2";
            nameR = "ALTO";
            break;
        case 4:
            classR = "testoNero h2";
            nameR = "CRITICO";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "testoVerde h2";
            nameI = "BASSO";
            break;
        case 2:
            classI = "testoArancio h2";
            nameI = "MEDIO";
            break;
        case 3:
            classI = "testoRosso h2";
            nameI = "ALTO";
            break;
        case 4:
            classI = "testoNero h2";
            nameI = "CRITICO";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "testoVerde h2";
            nameD = "BASSO";
            break;
        case 2:
            classD = "testoArancio h2";
            nameD = "MEDIO";
            break;
        case 3:
            classD = "testoRosso h2";
            nameD = "ALTO";
            break;
        case 4:
            classD = "testoNero h2";
            nameD = "CRITICO";
            break;
        default:
            break;
    }

    return (
        <p>
            <strong>Impatto R-I-D:</strong><br />
            Riservatezza: <img src={IconImpatti[r].src} width="20" height="20" alt="" />
            <span className={classR}>
                <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Integrità: <img src={IconImpatti[i].src} width="20" height="20" alt="" />
            <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Disponibilità: <img src={IconImpatti[d].src} width="20" height="20" alt="" />
            <span className={classD}>    <strong> {nameD}</strong></span>
        </p>
    );
}

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}

// component that renders a single caratteristica row
export class ServizioRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
        this.impattoToString = this.impattoToString.bind(this);
        this.getImpattoClassName = this.getImpattoClassName.bind(this);
        this.getRischioAggregatoMinaccie = this.getRischioAggregatoMinaccie.bind(this);
        this.getRischioAggregato = this.getRischioAggregato.bind(this);
    }

    getRischioAggregato() {
        //console.log('rischioAttuale', this.props.service.RischioAttuale);
        let risk = '';
        let iconSrc = "";
        let className = '';
        try {
            //risk = LIVELLO[(this.props.remedy.RischioAggregato) ? this.props.remedy.RischioAggregato : ''].name;
            //iconSrc = IconImpatti[this.props.remedy.RischioAggregato].src;
            risk = this.getRisk(this.props.remedy.RischioAggregato, LIVELLO).name;
            iconSrc = this.getRisk(this.props.remedy.RischioAggregato, IconImpatti).src;
            switch (this.props.remedy.RischioAggregato) {
                case 1:
                    className = "testoVerde h2";
                    break;
                case 2:
                    className = "testoArancio h2";
                    break;
                case 3:
                    className = "testoRosso h2";
                    break;
                default:
                    className = 'testoNero h2';
                    break;
            }
            return <div><img src={iconSrc} width="20" height="20" alt="" />
                <span className={className}><strong> {risk}</strong></span></div>;
        } catch (error) {
            console.error(error);
            return <div className='testoNero h2'><strong></strong></div>;
        }
    }
    /**
   * 
   */
    getRischioAggregatoMinaccie() {
        //console.log('rischioAttuale', this.props.remedy.RischioAttuale);
        try {
            let risk = this.props.remedy.RischioAggregato === null ? '' : this.props.remedy.RischioAggregato;
            return risk;
        } catch (error) {
            //console.error(error);
            return '';
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    impattoToString(indexOfImpact) {
        try {
            let index = parseInt(indexOfImpact, 10);
            return IconImpatti[index].label;

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    getImpattoClassName(indexOfImpact) {
        let className = '';
        //debugger
        try {
            let index = parseInt(indexOfImpact, 10);
            switch (index) {
                case 1:
                    className = 'testoVerde';
                    break;
                case 2:
                    className = 'testoArancio';
                    break;
                case 3:
                    className = 'testoRosso';
                    break;
                case 4:
                    className = 'testoNero';
                    break;
                default:
                    className = '';
                    break;
            }
            return className;
        } catch (error) {
            console.error(error);
            return '';
        }
    }



    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    } 

    toStringDateTime(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    } 


    addLineaGrigiaT(rows) {
        try {

            let listRowsNew = [];
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                listRowsNew.push(element);
                listRowsNew.push(<div className='lineaGrigiaT'></div>);
            }
            return listRowsNew;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
         * 
         * @param {*} index 
         * @param {*} array 
         */
    getRisk(index, array) {
        try {
            //let rischioAggregato=this.props.service.RischioAggregato;
            let label = ((index) && (index >= 0) && (index < array.length)) ? array[index].label : "";
            let name = ((index) && (index >= 0) && (index < array.length)) ? array[index].name : "";
            let src = ((index) && (index >= 0) && (index < array.length)) ? array[index].src : "";
            return {
                label: label,
                name: name,
                src: src
            }
        } catch (error) {
            return console.error(error);
        }
    }

    render() {

        let risk = this.getRischioAggregatoMinaccie();
        let criticità = this.props.remedy.Criticità;
        
        //let iconSrc=ICON[this.props.remedy.Criticità].src;
        //let iconLabel =ICON[this.props.remedy.Criticità].label
        let iconSrc = this.getRisk(criticità, ICON).src;
        let iconLabel = this.getRisk(criticità, ICON).label;
        let profiloCriticità = <p><strong>Profilo di criticità dell'applicazione</strong></p>;
        if (this.props.remedy.Criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità dell'applicazione</strong>&nbsp;&nbsp;
                                    <img src={iconSrc} width="20" height="20" alt="" />

                <span className={getClassName(this.props.remedy.Criticità)}>
                    <strong>{" " + iconLabel}</strong></span>
            </p>;
        }
        var iuc = this.props.remedy.RischioAggregato;
        let divIUC = <p><strong>IUC</strong></p>;
        if (iuc > 0) {
            divIUC = <p>
                <strong>IUC</strong>&nbsp;&nbsp;
                {iuc +"%"}
            </p>;
        }
        return (
            <div>
                <div className="row">
                    <div className="col-lg-8 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            {this.props.remedy.Nome}<i className="iconRight fa fa-chevron-down"></i> </a>
                    </div>


                    <div className="col-lg-2 card-header">
                        {this.toStringDate(this.props.remedy.DataCreazione)}
                    </div>
                    
                    <div className="col-lg-1 card-header">
                        {this.props.remedy.RischioAggregato > 0 && this.props.remedy.RischioAggregato+"%"}
                    </div>
                    

                    {/* <div className={getClassNameAccordion(risk)}>
                        <strong>{risk > -1 && IconImpatti[risk].label}</strong>
                    </div> */}

                    <div className="col-lg-1 card-header ">
                        <a href="javascript:void(0);" onClick={() => redirect('RiepilogoDatiApplicazione', this.props.remedy.ServizioId, this.props.remedy.Nome)}><i className="fa fa-eye" title="VEDI"></i></a>

                    </div>

                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row  card-headerLight">
                        <div className="col-lg-6"><br />
                            <strong>Referente</strong><br />
                            {(this.props.remedy.Referente) ? this.props.remedy.Referente : "-"}<hr />
                        </div>
                        <div className="col-lg-6"><br />
                            <strong>Struttura</strong><br />
                            {(this.props.remedy.StrutturaReferente) ? this.props.remedy.StrutturaReferente : "-"}<hr />
                        </div>
                        {/*
                        <div className="col-lg-6"><hr />
                            <strong>Categorie</strong><br />
                            {this.props.categoriesTostring}
                        </div>
                        <div className="col-lg-6"><hr />
                            <strong>Tipologie</strong><br />
                            {this.props.typologiesTostring}
                        </div>
                        */}
                        <div className="col-lg-12">
                            <strong>Descrizione</strong><br />
                            {(this.props.remedy.ServizioDescrizione) ? this.props.remedy.ServizioDescrizione : "-"}<hr />
                        </div>
                        <div className="col-lg-12 ">
                            {profiloCriticità}
                            <hr />
                        </div>
                        <div className="col-lg-12">
                            {divIUC}<hr />
                        </div>
                        <div className="col-lg-6">
                            <strong>Data creazione Gap Analysis</strong><br />
                            {this.toStringDateTime(this.props.remedy.DataCreazione)}
                            <hr />
                        </div>
                        <div className="col-lg-6">
                            <strong>Data pubblicazione</strong><br />
                            {this.toStringDateTime(this.props.remedy.DataPubblicazione)}
                            <hr />
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}