import React from 'react';
import history from '../history';
import { API_CONFIG } from '../../public/js/apiServices';

export class Breadcrumb extends React.Component {
    render() {
        var liElement = '';
        if (this.props.links) {
            if (this.props.links.length) {
                liElement = this.props.links.map(function (item, i) {
                    let path = item.url.indexOf("/") === 0 ? item.url : "/" + item.url;
                    return (
                        <li key={i} className="Breadcrumb-item"><a className="u-color-50" onClick={() => history.push(path)} href="javascript:void(0)">{item.nome}</a></li>
                    );
                }
                );
            }
        }
        return (
            <nav className="" aria-label="sei qui:" role="navigation">
                <ul className="Breadcrumb">
                    <li className="Breadcrumb-item"><a className="u-color-50" onClick={() => history.push(API_CONFIG.HomePath)} href="javascript:void(0)">Home</a></li>
                    {liElement}
                </ul>
            </nav>
        )

    };
}