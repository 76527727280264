import * as React from 'react';
import IconImpatti from '../data/IconLevelImpatti';
import ICON from '../data/IconLevel';
import history from '../history';
import LIVELLO from '../data/criticality';
import { Util } from '../data/Util';

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';

function redirect(path, assessmentId, nome, statoAssessment) {
    try {
        
        window.sessionStorage.setItem("serviceName", nome);
        let state = {
            some: 'state',
            serviceName: nome,
            assessmentId: assessmentId,
            statoAssessment: statoAssessment
        }
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push({
                    pathname: path,
                    state: state
                }
                );
            else if (path.indexOf('/') === -1) {
                history.push({
                    pathname: '/' + path,
                    state: state
                }
                );
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

function getRIDAccordion(r, i, d) {
    //console.log("impatto i", r);
    if (r < 1 || isNaN(r))
        return <p><strong>Impatto R-I-D:</strong><br /></p>;

    var nameR = "";
    var classR = "";
    switch (r) {
        case 1:
            classR = "testoVerde h2";
            nameR = "BASSO";
            break;
        case 2:
            classR = "testoArancio h2";
            nameR = "MEDIO";
            break;
        case 3:
            classR = "testoRosso h2";
            nameR = "ALTO";
            break;
        case 4:
            classR = "testoNero h2";
            nameR = "CRITICO";
            break;
        default:
            break;
    }

    var nameI = "";
    var classI = "";
    switch (i) {
        case 1:
            classI = "testoVerde h2";
            nameI = "BASSO";
            break;
        case 2:
            classI = "testoArancio h2";
            nameI = "MEDIO";
            break;
        case 3:
            classI = "testoRosso h2";
            nameI = "ALTO";
            break;
        case 4:
            classI = "testoNero h2";
            nameI = "CRITICO";
            break;
        default:
            break;
    }

    var nameD = "";
    var classD = "";
    switch (d) {
        case 1:
            classD = "testoVerde h2";
            nameD = "BASSO";
            break;
        case 2:
            classD = "testoArancio h2";
            nameD = "MEDIO";
            break;
        case 3:
            classD = "testoRosso h2";
            nameD = "ALTO";
            break;
        case 4:
            classD = "testoNero h2";
            nameD = "CRITICO";
            break;
        default:
            break;
    }

    return (
        <p>
            <strong>Impatto R-I-D:</strong><br />
            Riservatezza: <img src={IconImpatti[r].src} width="20" height="20" alt="" />
            <span className={classR}>
                <strong> {nameR}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Integrità: <img src={IconImpatti[i].src} width="20" height="20" alt="" />
            <span className={classI}><strong> {nameI}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                Disponibilità: <img src={IconImpatti[d].src} width="20" height="20" alt="" />
            <span className={classD}>    <strong> {nameD}</strong></span>
        </p>
    );
}

function getClassName(index) {
    let className = 'h2';
    let indexInt = parseInt(index, 10);
    switch (indexInt) {
        case 1:
            className = "testoVerde h2";
            break;
        case 2:
            className = "testoArancio h2";
            break;
        case 3:
            className = "testoRosso h2";
            break;
        case 4:
            className = "testoNero h2";
            break;
        default:
            className = "h2";
            break;
    }
    return className;
}


// component that renders a single caratteristica row
export class ApplicazioneRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
        this.impattoToString = this.impattoToString.bind(this);
        this.getImpattoClassName = this.getImpattoClassName.bind(this);
        this.getMaxRischioAttualeMinaccie = this.getMaxRischioAttualeMinaccie.bind(this);
        this.getRischioAggregato = this.getRischioAggregato.bind(this);
    }

    /**
   * 
   */
    getMaxRischioAttualeMinaccie() {
        //console.log('rischioAttuale', this.props.remedy.RischioAttuale);
        try {
            let risk = this.props.remedy.RischioAggregato === null ? '' : LIVELLO[this.props.remedy.RischioAggregato].name;
            return risk;
        } catch (error) {
            //console.error(error);
            return '';
        }
    }

    /**
     * 
     */
    getRischioAggregato() {
        let risk = "";
        let className = "";
        try {
            let rischioAggregato = this.props.remedy.RischioAggregato;
            //risk =  LIVELLO[(this.props.remedy.RischioAggregato) ? this.props.remedy.RischioAggregato : ''].name;
            let iconSrc = IconImpatti[this.props.remedy.RischioAggregato].src;
            risk = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[(this.props.remedy.RischioAggregato)].name : "";
            iconSrc = ((rischioAggregato) && (rischioAggregato >= 0) && (rischioAggregato < LIVELLO.length)) ? LIVELLO[(this.props.remedy.RischioAggregato)].src : "";

            switch (this.props.remedy.RischioAggregato) {
                case 1:
                    className = "testoVerde h2";
                    break;
                case 2:
                    className = "testoArancio h2";
                    break;
                case 3:
                    className = "testoRosso h2";
                    break;
                default:
                    className = 'testoNero h2';
                    break;
            }
            return <div><img src={iconSrc} width="20" height="20" alt="" />
                <span className={className}><strong> {risk}</strong></span></div>;
        } catch (error) {
            console.error(error);
            return <div className='testoNero h2'><strong></strong></div>;
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    impattoToString(indexOfImpact) {
        try {
            let index = parseInt(indexOfImpact, 10);
            return IconImpatti[index].label;

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * @param {*} indexOfImpact 
     */
    getImpattoClassName(indexOfImpact) {
        let className = '';
        //debugger
        try {
            let index = parseInt(indexOfImpact, 10);
            switch (index) {
                case 1:
                    className = 'testoVerde';
                    break;
                case 2:
                    className = 'testoArancio';
                    break;
                case 3:
                    className = 'testoRosso';
                    break;
                case 4:
                    className = 'testoNero';
                    break;
                default:
                    className = '';
                    break;
            }
            return className;
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }    /**
     * 
     * @param {*} rows 
     */
    addLineaGrigiaT(rows) {
        try {

            let listRowsNew = [];
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                listRowsNew.push(element);
                listRowsNew.push(<div className='lineaGrigiaT'></div>);
            }
            return listRowsNew;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    toStringDateTime(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    render() {
        let path = this.props.path;
        let profiloCriticità = <p><strong>Profilo di criticità dell'applicazione</strong></p>;
        if (this.props.remedy.Criticità > 0) {
            profiloCriticità = <p>
                <strong>Profilo di criticità dell'applicazione</strong>&nbsp;&nbsp;
                                    <img src={ICON[this.props.remedy.Criticità].src} width="20" height="20" alt="" />

                <span className={getClassName(this.props.remedy.Criticità)}>
                    <strong>{" " + ICON[this.props.remedy.Criticità].label}</strong></span>
            </p>;
        }

        let iconClass = (this.props.stateAssessment.toLowerCase().localeCompare("completo") === 0) ? "fa fa-eye" : "fa fa-pencil"
        var iuc = this.props.remedy.RischioAggregato;
        let divIUC = <p><strong>IUC</strong></p>;
       
        if (iuc > 0) {
            divIUC = <p>
                <strong>IUC</strong>&nbsp;&nbsp;
                {iuc +"%"}
            </p>;
        }
        var stato = this.props.remedy.StatoAssessmentId > 5 ? "Execute" : "Planning";
        let divStato =<p>
                        <strong>Stato</strong>&nbsp;&nbsp;
                        {stato}
                    </p>;
        return (
            <div>
                <div className="row">
                    <div className="col-lg-9 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            {this.props.remedy.Nome}<i className="iconRight fa fa-chevron-down"></i> </a>
                    </div>

                    {/* 1a colonna Creato*/}
                    <div className="col-lg-2 card-header">
                        {this.toStringDate(this.props.remedy.DataPubblicazione)}
                    </div>

                    {/*
                    <div className={getClassNameAccordion(this.props.remedy.Criticità)}>
                        <strong>{this.props.remedy.Criticità > -1 &&
                            IconImpatti[this.props.remedy.Criticità].label}</strong>
                    </div>
                    */}
                    {/* 2a colonna Stato*/}
                    {/* <div className={"col-lg-1 card-header"}>
                        {this.props.stateAssessment}
                    </div> */}
                    {/* 3a colonna Azioni*/}
                    <div className="col-lg-1 card-header">
                        <a href="javascript:void(0);" onClick={() => redirect(path, this.props.remedy.AssessmentId, this.props.remedy.Nome, this.props.remedy.StatoAssessmentId)}>
                        <i className="fa fa-pencil" title="MODIFICA"></i></a>

                    </div>


                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="col-lg-12 collapse" data-parent="#accordion">
                    <div className="row  card-headerLight">
                        <div className="col-lg-6"><br />
                            <strong>Referente</strong><br />
                            {(this.props.remedy.Referente) ? this.props.remedy.Referente : "-"}<hr />
                        </div>
                        <div className="col-lg-6"><br />
                            <strong>Struttura</strong><br />
                            {(this.props.remedy.StrutturaReferente) ? this.props.remedy.StrutturaReferente : "-"}<hr />
                        </div>
                        <div className="col-lg-12">
                            <strong>Descrizione</strong><br />
                            {(this.props.remedy.ServizioDescrizione) ? this.props.remedy.ServizioDescrizione : "-"}<hr />
                        </div>
                        <div className="col-lg-12">
                            {divStato}<hr />
                        </div> 
                        <div className="col-lg-12 ">
                            {profiloCriticità}<hr />
                        </div>  
                        <div className="col-lg-12">
                            {divIUC}<hr />
                        </div>                      
                        <div className="col-lg-6">
                            <strong>Data Creazione Analisi</strong><br />
                            {this.toStringDateTime(this.props.remedy.DataCreazione)}
                            <hr />
                        </div>
                        <div className="col-lg-6">
                            <strong>Data Pubblicazione</strong><br />
                            {this.toStringDateTime(this.props.remedy.DataPubblicazione)}
                            <hr />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}