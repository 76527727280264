import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import { API_CONFIG } from '../../public/js/apiServices';
import { ServizioModificaTrattamentoRow } from './ServizioModificaTrattamentoRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';

const LINK = [
    { nome: "Trattamento del Rischio", url: "/TrattamentoRischio" },
    { nome: "Opzioni di trattamento", url: "#" }
];

export class ServizioModificaTrattamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nomePA: "",
            user: "",
            opzioni: [],
            statoAssessmentId: "",
            messageError: "",
            emptyText: "Loading...",
            messageWarning: '',
        };
        this.updateOpzione = this.updateOpzione.bind(this);
        this.updateRiskAppetite = this.updateRiskAppetite.bind(this);
        this.updatePersona = this.updatePersona.bind(this);
        this.updateStruttura = this.updateStruttura.bind(this);
        this.updateMotivazione = this.updateMotivazione.bind(this);
        this.redirect = this.redirect.bind(this);
        this.setNomePA = this.setNomePA.bind(this);
        this.save = this.save.bind(this);
        this.opzioniComplete = this.opzioniComplete.bind(this);
        this.getUserName = this.getUserName.bind(this);
    }

    getUserName() {

        let decoded = this.props.auth.getAccessToken();
        let response = "";
        if (decoded !== null) {
            let user = decoded.user;
            response = user.name + " " + user.familyName;
        }

        return response;
    }

    componentDidMount() {
        this.setState({ user: this.getUsersFromAccessToken() });
        this.serverRequest =
            $.ajax({
                //url: 'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/getOpzioniTrattamento',
                url: API_CONFIG.assessmentcompositeGetOpzioniTrattamento,
                type: "POST",
                contentType: 'application/json',
                data: JSON.stringify({ AssessmentId: this.props.PA === "true" ? "" : sessionStorage.getItem('assessmentId') }),
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    console.log('response:', response);

                    sessionStorage.setItem('assessmentId', response.data.AssessmentId);
                    this.setNomePA(response.data.Nome);
                    if (response.data.StatoAssessmentId) {
                        this.setState({ statoAssessmentId: response.data.StatoAssessmentId });
                    }
                    if (!(response.data.StatoAssessmentId) || response.data.StatoAssessmentId < 4) {
                        this.setState({ emptyText: "Nessun dato da visualizzare. Eseguire la pubblicazione dell'Analisi del Rischio per PA" })
                    }
                    else {
                        this.setState({ opzioni: response.data.OpzioniTrattamento.data });
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
    }

    updateOpzione(minacciaId, value) {
        let opzioni = this.state.opzioni;
        for (let index = 0; index < opzioni.length; index++) {
            if (opzioni[index].MinacciaId === minacciaId) {
                opzioni[index].OpzioneTrattamento = value;
                opzioni[index].PersonaIntervistato = this.getUserName();
            }
        }
        this.setState({ opzioni: opzioni }, () => this.save());
        console.log("Opzioni", this.state.opzioni);
    }

    /**
     * 
     * @param {*} minacciaId 
     * @param {*} value 
     */
    updateRiskAppetite(minacciaId, value) {
        console.log("updateRiskAppetite:", value);
        let opzioni = this.state.opzioni;
        for (let index = 0; index < opzioni.length; index++) {
            if (opzioni[index].MinacciaId === minacciaId) {
                opzioni[index].RiskAppetite = value;
                opzioni[index].PersonaIntervistato = this.getUserName();

                if (opzioni[index].RischioAttuale <= opzioni[index].RiskAppetite)
                    opzioni[index].OpzioneTrattamento = 2;
                else
                    opzioni[index].OpzioneTrattamento = 1;
                //console.log("updateRiskAppetite", value);
            }
        }
        this.setState({ opzioni: opzioni }, () => this.save());
    }

    updatePersona(minacciaId, event) {
        let opzioni = this.state.opzioni;
        let val = event.target.value;
        for (let index = 0; index < opzioni.length; index++) {
            if (opzioni[index].MinacciaId === minacciaId) {
                opzioni[index].PersonaIntervistato = val;
            }
        }
        this.setState({ opzioni: opzioni });
        console.log("Opzioni", this.state.opzioni);
    }

    updateStruttura(minacciaId, event) {
        let opzioni = this.state.opzioni;
        let val = event.target.value;
        for (let index = 0; index < opzioni.length; index++) {
            if (opzioni[index].MinacciaId === minacciaId) {
                opzioni[index].StrutturaIntervistato = val;
            }
        }
        this.setState({ opzioni: opzioni });
        console.log("Opzioni", this.state.opzioni);
    }

    /**
     * 
     * @param {*} minacciaId 
     * @param {*} event 
     */
    updateMotivazione(minacciaId, event) {
        let opzioni = this.state.opzioni;
        let val = event.target.value;
        for (let index = 0; index < opzioni.length; index++) {
            if (opzioni[index].MinacciaId === minacciaId) {
                opzioni[index].Motivazione = val;
            }
        }
        this.setState({ opzioni: opzioni });
        console.log("Opzioni", this.state.opzioni);
    }

    opzioniComplete() {
        var lista = this.state.opzioni;
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if (
                ((element.OpzioneTrattamento !== null &&
                    element.OpzioneTrattamento !== element.OpzioneTrattamentoDefault) ||
                    element.RiskAppetite !== element.RiskAppetiteDefault) &&
                (element.PersonaIntervistato === null ||
                    element.Motivazione === null ||
                    element.Motivazione === "")) {
                return false;
            }
        }

        return true;
    }

    /**
     * 
     *  
     */
    redirect() {
        let path = this.props.PA === "true" ? '/AzioniTrattamentoPA' : '/AzioniTrattamentoServizioModifica';
        let state = {};
        try {
            if (this.opzioniComplete()) {
                if (this.props.PA) {
                    state = {
                        assessmentId: this.state.assessmentId,
                        isPA: this.props.PA,
                        nomePA: this.state.nomePA
                    };
                } else {
                    state = {
                        assessmentId: this.state.assessmentId,
                        isPA: this.props.PA,
                        serviceName: this.state.serviceName
                    };
                }
                if (path) {
                    if (path.indexOf('/') === 0)
                        history.push({
                            pathname: path,
                            state: state
                        }
                        );
                    else if (path.indexOf('/') === -1) {
                        history.push({
                            pathname: '/' + path,
                            state: state
                        }
                        );
                    }
                }
                //console.log("state", state)
            }
            else {
                this.setState({ messageWarning: "Per proseguire è necessario completare il campo Motivazione delle Opzioni di Trattamento modificate" });
                $('#btnValidate').get(0).click();
            }
        } catch (error) {
            return console.error(error)
        }
    }


    /**
     * 
     * @param {*} nomePA 
     */
    setNomePA(nomePA) {
        try {
            if (nomePA) {

                this.setState({ nomePA: nomePA });
            } else {
                let user = this.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA });
            }
            sessionStorage.setItem('nomePA', this.state.nomePA);
            //return console.log('nomePA',this.state.nomePA);
        } catch (error) {
            return console.error(error)
        }
    }



    save(e) {
        // data in the form
        console.log('data', this.state.opzioni);
        //alert(sessionStorage.getItem('assessmentId'));
        if (true) {
            // submit form data to api
            $.ajax({
                //url:'https://cns.westeurope.cloudapp.azure.com/assessmentcompositeserv/api/assessmentcomposite/saveOrUpdateOpzioniTrattamento',
                url: API_CONFIG.assessmentcompositesaveOrUpdateOpzioniTrattamento,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ AssessmentId: sessionStorage.getItem('assessmentId'), OpzioniTrattamento: this.state.opzioni }),
                cache: false,
                /*
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                */
                success: function (response) {
                    console.log(response);
                    if (response.status === 'success') {
                        //alert(response.status);
                        //location.reload();
                        //history.push('/ElencoServiziTrattamento');
                        console.log('save:', response.status);
                    }
                    else {
                        //TODO
                    }
                },

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
        } else {
            alert('Prima di Salvare compilare tutte le Categorie');
        }

        //e.preventDefault();
    }

    /**
     * ottieni le inforzioni dell'utente dalla decodifica del token
     */
    getUsersFromAccessToken() {
        try {
            const accessToken = sessionStorage.getItem('token');
            if (accessToken) {
                let decoded = jwtDecode(accessToken);
                console.log("user", decoded.user);
                return decoded.user;
            }

            return null;

        } catch (error) {
            //location.replace(API_CONFIG.HomePath);
            return console.error(error);
        }
    }

    render() {
        var rows = '';
        if (this.state.opzioni.length === 0) {
            rows = <div className="row">{this.state.emptyText}</div>
        } else {

            rows = this.state.opzioni.map(function (opzione, i) {
                return (
                    <ServizioModificaTrattamentoRow
                        key={i}
                        rowId={i}
                        opzione={opzione}
                        save={this.save}
                        readOnly={false}
                        updateOpzione={this.updateOpzione}
                        updatePersona={this.updatePersona}
                        updateStruttura={this.updateStruttura}
                        updateMotivazione={this.updateMotivazione}
                        updateRiskAppetite={this.updateRiskAppetite}
                    />
                );
            }.bind(this)
            );
        }
        let path = this.props.PA === "true" ? '/PAModificaImpostazioni' : '/ServizioModificaImpostazioni';
        console.log("PA", path);
        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-8">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Opzioni di trattamento -  {this.state.nomePA} </span>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco delle minacce con il relativo grado di rischio attuale, messo a confronto con il dato di propensione al rischio configurato. E’ possibile modificare le opzioni di trattamento proposte, calcolate in base ai dati di rischio attuale e propensione al rischio. In caso di modifica è necessario indicare il responsabile della modifica e della sua struttura organizzativa.</p>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-4"><strong>	Minaccia </strong></div>
                            <div className="col-lg-2"><strong>	Rischio attuale</strong></div>
                            <div className="col-lg-2"><strong>	Propensione</strong></div>
                            <div className="col-lg-2"><strong>	Trattamento</strong></div>
                            <div className="col-lg-2"><strong>	Stato</strong></div>
                        </div>
                        <hr />

                        {rows}

                        <div style={{ position: 'fixed', bottom: '0px', left: '0px', width: '100%', background: 'rgba(255,255,255,.7)' }}>
                            <div className="row spazio20">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                    <button type="button"
                                        onClick={this.redirect}
                                        hidden={(this.state.statoAssessmentId === "") || this.state.statoAssessmentId < 4}
                                        className="btn btn-warning btn-lg btn-block">AZIONI DI TRATTAMENTO <i className="fa fa-angle-double-right"></i></button>
                                    {/* className="btn btn-warning btn-lg btn-block">AZIONI DI TRATTAMENTO</button> */}
                                </div>
                                <div className="col-lg-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={"Operazione effettuata con successo."} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnSetGrado'} handle={this.setGradoAttesoDefault} textModal={"L'operazione resetta i campi compilati per le Azioni di Trattamento. Procedere?"} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnValidate'} textModal={this.state.messageWarning} />
            </div>
        )
    }
}
