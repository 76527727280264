import React, { Component } from 'react';
import { API_CONFIG } from '../../public/js/apiServices';
import $ from 'jquery';
import { Doughnut, HorizontalBar } from 'react-chartjs-2';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ListaServizi } from './ListaServizi';
import { ModalDialog } from '../Common/ModalDialog';
import infoIcon from '../../public/images/info.png';

let messageError = '';
// import Highcharts from 'highcharts/highstock'
// import HighchartsReact from 'highcharts-react-official'

const LINK = [
    { nome: "Analisi del contesto", url: "/AnalisiContesto" },
    { nome: "Riepilogo dati", url: "/RiepilogoAnalisiContesto" }
];

export class RiepilogoDati extends Component {

    constructor(props) {
        super(props);
        this.state = {
            TipologieList: [],
            TotaliServizi: {}
        };
        this.createTipologieList = this.createTipologieList.bind(this);
        this.createTipologieListTop = this.createTipologieListTop.bind(this);
        this.createChartCriticità = this.createChartCriticità.bind(this);
        this.getWidth = this.getWidth.bind(this);
        this.getCriticitàPA = this.getCriticitàPA.bind(this);
        this.getCriticitàValue = this.getCriticitàValue.bind(this);
        this.getIndice = this.getIndice.bind(this);
        this.getTotServizi = this.getTotServizi.bind(this);
        this.getDistribuzioneCriticità = this.getDistribuzioneCriticità.bind(this);
        this.getChartDistribuzione = this.getChartDistribuzione.bind(this);
    }

    componentDidMount() {
        try {
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.servizioCompositeRiepilogo,
                    type: 'POST',
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (response) {
                        console.log(response.data);
                        this.setState({ TotaliServizi: response.data.getTotaleServiziCensitiCaratterizzati[0] });
                        this.setState({ TipologieList: response.data.getTotaleTipologieCaratterizzate });
                        window.setGauge("gauge3", this.getIndice());
                        //window.setGauge3liv(this.getCriticitàValue());
                        window.setPyramidCriticità(this.state.TotaliServizi.CriticitàPA);
                        //window.setGauge("gaugeB", distrib.bassa === 1 ? 0.99 : distrib.bassa === 0 ? 0.01 : distrib.bassa / 100);
                        //window.setGauge("gaugeM", distrib.media === 1 ? 0.99 : distrib.media === 0 ? 0.01 : distrib.media / 100);
                        //window.setGauge("gaugeA", distrib.alta === 1 ? 0.99 : distrib.alta === 0 ? 0.01 : distrib.alta / 100);
                        var totali = this.getTotServizi();
                        window.setGaugeImpatti(totali.perc === 1 ? 0.99 : totali.perc === 0 ? 0.01 : totali.perc);
                    }.bind(this),
                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });

        } catch (error) {
            return console.error(error);
        }
    }

    getIndice() {
        var indice = 1;
        var value = 0.1;
        if (this.state.TipologieList.length > 0) {
            indice = 3;
            for (let index = 0; index < this.state.TipologieList.length; index++) {
                if (this.state.TipologieList[index].TotaleCaratterizzate >=
                    this.state.TipologieList[index].SogliaBassa) {
                    if (this.state.TipologieList[index].TotaleCaratterizzate <
                        this.state.TipologieList[index].SogliaMedia) {
                        indice = 2;
                    }
                }
                else {
                    indice = 1;
                    break;
                }
            }
        }


        switch (indice) {
            case 1:
                value = 0.1;
                break;
            case 2:
                value = 0.5;
                break;
            case 3:
                value = 0.9;
                break;
            default:
                break;
        }
        return value;
    }

    getCriticitàValue() {
        var value = 0;

        switch (this.state.TotaliServizi.CriticitàPA) {
            case 1:
                value = 0.33;
                break;
            case 2:
                value = 0.66;
                break;
            case 3:
                value = 0.99;
                break;
            default:
                break;
        }
        return value;
    }

    getTotServizi() {
        var response = {};

        var perc = this.state.TotaliServizi.TotaleCaratterizzati / this.state.TotaliServizi.Totale;
        if (isNaN(perc)) {
            perc = 0;
        }
        response.perc = perc.toFixed(2);
        response.tot = parseInt(this.state.TotaliServizi.Totale, 10);
        response.caratterizzati = parseInt(this.state.TotaliServizi.TotaleCaratterizzati, 10);
        if (isNaN(response.caratterizzati)) {
            response.caratterizzati = 0;
        }
        if (isNaN(response.tot)) {
            response.tot = 0;
        }
        return response;

    }

    getDistribuzioneCriticità() {
        var response = {};



        var alta = Math.round((this.state.TotaliServizi.TotaleAltaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100);
        var media = Math.round((this.state.TotaliServizi.TotaleMediaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100);
        var bassa = 100 - alta - media;

        response.alta = isNaN(alta) ? 0 : alta;
        response.media = isNaN(media) ? 0 : media;
        response.bassa = isNaN(bassa) ? 0 : bassa;

        return response;
    }

    getChartDistribuzione() {
        var response = {};

        var alta = Math.round((this.state.TotaliServizi.TotaleAltaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100);
        var media = Math.round((this.state.TotaliServizi.TotaleMediaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100);
        var bassa = 100 - alta - media;
        var tot = isNaN(this.state.TotaliServizi.TotaleCaratterizzati) ? 0 : this.state.TotaliServizi.TotaleCaratterizzati;
        var totAlti = isNaN(this.state.TotaliServizi.TotaleAltaCriticità) ? 0 : this.state.TotaliServizi.TotaleAltaCriticità;
        var totMedi = isNaN(this.state.TotaliServizi.TotaleMediaCriticità) ? 0 : this.state.TotaliServizi.TotaleMediaCriticità;
        var totBassi = tot - totAlti - totMedi;

        response.alta = isNaN(alta) ? 0 : alta;
        response.media = isNaN(media) ? 0 : media;
        response.bassa = isNaN(bassa) ? 0 : bassa;

        var data = {
            labels: ["ALTO " + totAlti + "/" + tot, "MEDIO " + totMedi + "/" + tot, "BASSO " + totBassi + "/" + tot],
            datasets: [
                {
                    label: "",
                    backgroundColor: ["#cc0000", "#FF7E00", "#189602"],
                    data: [response.alta, response.media, response.bassa],
                    fontStyle: "bold",
                }
            ]
        };
        var opt = {
            legend: { display: false },
            title: { display: false },
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        callback: function (value) { return value + "%" }
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return " " + data.datasets[0].data[tooltipItem.index] + "%";
                    }
                }
            }
        };
        var chartDistribuzione = <HorizontalBar height={200} width={800} data={data} options={opt} />;

        return chartDistribuzione;

    }

    getCriticitàPA() {
        var criticità = this.state.TotaliServizi.CriticitàPA;
        var criticitàRow = ''

        if (criticità !== null)
            switch (criticità) {
                case 1:
                    criticitàRow = <span className="testoVerde"> BASSO</span>
                    break;
                case 2:
                    criticitàRow = <span className="testoArancio"> MEDIO</span>
                    break;
                case 3:
                    criticitàRow = <span className="testoRosso"> ALTO</span>
                    break;
                default:
                    criticitàRow = "";
                    break;
            }

        return criticitàRow;
    }

    createChartCriticità() {
        var alta = this.getWidth(Math.round((this.state.TotaliServizi.TotaleAltaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100));
        var media = this.getWidth(Math.round((this.state.TotaliServizi.TotaleMediaCriticità / this.state.TotaliServizi.TotaleCaratterizzati) * 100));
        var bassa = 100 - alta - media;

        var data = {
            labels: [
                "ALTO " + alta + "%",
                "MEDIO " + media + "%",
                "BASSO " + bassa + "%"

            ],
            datasets: [{
                data: [this.state.TotaliServizi.TotaleAltaCriticità,
                this.state.TotaliServizi.TotaleMediaCriticità,
                this.state.TotaliServizi.TotaleBassaCriticità],
                backgroundColor: [
                    "#CC0000",
                    "#FF7E00",
                    "#189602"
                ]
            }]
        };
        var opt = { legend: { display: true, position: 'right' } }
        var chartServizi = <Doughnut data={data} options={opt} />;//<HighchartsReact  highcharts={Highcharts} options={options} />;

        return chartServizi;
    }

    createTipologieListTop() {
        let listRows = [];
        var tipologieRows = [];
        var tipologieRowsHide = [];
        for (let index = 0; index < this.state.TipologieList.length; index++) {

            var testo = '';
            var classe = '';
            if (this.state.TipologieList[index].TotaleCaratterizzate < this.state.TipologieList[index].SogliaBassa) {
                testo = ' BASSO';
                classe = 'testoRosso';
            }
            else if (this.state.TipologieList[index].TotaleCaratterizzate < this.state.TipologieList[index].SogliaMedia) {
                testo = ' MEDIO';
                classe = 'testoArancio';
            }
            else {
                testo = ' ALTO';
                classe = 'testoVerde';
            }
            var divNome = <div key={"tipologiaNome" + index} className="col-lg-10 card-headerT">
                {this.state.TipologieList[index].Nome}
            </div>;
            var divTot = <div key={"tipologiaTot" + index} className="col-lg-1 card-headerTR">
                {this.state.TipologieList[index].TotaleCaratterizzate}/{this.state.TipologieList[index].SogliaMedia}	</div>;
            var divLink = <div key={"tipologiaLivello" + index} className="col-lg-1 card-headerT">
                <span className={classe}>{testo}</span>
            </div>;

            if (tipologieRows.length < 12) {
                tipologieRows.push(divNome);
                tipologieRows.push(divTot);
                tipologieRows.push(divLink);
            }
            else {
                tipologieRowsHide.push(divNome);
                tipologieRowsHide.push(divTot);
                tipologieRowsHide.push(divLink);
            }
        }
        listRows.push(<div className="row font16" key={"listOfRows"}>{tipologieRows}</div>)
        if (tipologieRows.length > 0) {
            if (tipologieRowsHide.length > 0) {
                listRows.push(<div key="hide" id="collapse1" className="InfoAgg giustifica collapse " data-parent="#accordion">
                    <div className="row font16">{tipologieRowsHide}</div>
                </div>);

                listRows.push(
                    <div className="row font16"  key="vediTuttiRow">
                        <div key="vediTutti" className="col-lg-12 card-headerT">
                            <a className="collapsed" data-bs-toggle="collapse" href="#collapse1">
                                <span className="if-collapsed">Vedi tutte <i className="paRight fa fa-angle-down faBold"></i></span>
                                <span className="if-not-collapsed">Riduci <i className="paRight fa fa-angle-up faBold"></i></span>
                            </a>
                        </div>
                    </div>
                );

            }
        }

        return listRows;
    }



    createTipologieList() {
        var tipologieRows = [];
        var tipologieRowsHide = [];
        for (let index = 0; index < this.state.TipologieList.length; index++) {

            var divNome = <div key={"tipologiaNome" + index} className="col-lg-11 card-headerT">
                {this.state.TipologieList[index].Nome}
            </div>;
            var divTot = <div key={"tipologiaTot" + index} className="col-lg-1 card-headerTR">
                {this.state.TipologieList[index].TotaleCaratterizzate}/{this.state.TipologieList[index].TotaleTipologie}	</div>;

            if (tipologieRows.length < 8) {
                tipologieRows.push(divNome);
                tipologieRows.push(divTot);
            }
            else {
                tipologieRowsHide.push(divNome);
                tipologieRowsHide.push(divTot);
            }
        }

        if (tipologieRows.length > 0) {
            if (tipologieRowsHide.length > 0) {
                tipologieRows.push(<div key="hide" id="collapse2" className="collapse col-lg-12 row" data-parent="#accordion">
                    {tipologieRowsHide}
                </div>);

                tipologieRows.push(<div key="vediTutti" className="col-lg-12 card-headerT">
                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse2">
                        <span className="if-collapsed">Vedi tutte <i className="paRight fa fa-angle-down faBold"></i></span>
                        <span className="if-not-collapsed">Riduci <i className="paRight fa fa-angle-up faBold"></i></span>	</a></div>);

            }
        }

        return tipologieRows;
    }

    getWidth(perc) {
        if (isNaN(perc)) {
            perc = 0;
        }

        return perc;
    }

    getPAName() {
        let decoded = this.props.auth.getAccessToken();
        var paName = "";
        if (decoded !== null) {
            let user = decoded.user;
            paName = user.NomePA;
        }
        return paName;
    }

    render() {
        var totali = this.getTotServizi();
        var testoIndice = "BASSO";
        var coloreIndice = "testoRosso";
        var indice = this.getIndice();

        switch (indice) {
            case 0.5:
                //gaugeColor = "#ff9900";
                testoIndice = "MEDIO";
                coloreIndice = "testoArancio";
                break;
            case 0.9:
                testoIndice = "ALTO";
                //gaugeColor = "#0CEA04";
                coloreIndice = "testoVerde";
                break;
            default:
                break;
        }
        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
                <div className="container cnsContainer">
                    <div className="row">
                        <div className="col-lg-12">

                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Riepilogo dati per analisi del contesto</span>

                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12 giustifica">
                            <p>La pagina espone un riepilogo dell’Analisi del Contesto a partire dal Censimento dei Servizi e della valutazione del profilo di criticità attraverso i seguenti indicatori: indice di completezza dell’analisi, profilo di criticità della Pubblica Amministrazione, Servizi Caratterizzati rispetto ai servizi che sono stati censiti. L’indice di completezza è una stima qualitativa che stabilisce quanto è significativa l’analisi del contesto attualmente realizzata rispetto all’intera  Azienda. Il Profilo di Criticità fornisce la valutazione della criticità della Azienda rispetto ai servizi erogati ed utilizzati e come tale criticità è distribuita sui servizi analizzati. I Servizi Caratterizzati sono invece l’elenco dei Servizi Censiti per i quali è stato determinato il profilo di Criticità.</p>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>


                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Completezza</h1><hr />
                        </div>
                        <div className="col-lg-4">
                            <div><strong>Indice completezza</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoCompl">
                                    <span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span>
                                </a>

                                <div id="collapseInfoCompl" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    L’indice di completezza indica se il numero di servizi caratterizzati è sufficiente ad elaborare una analisi del rischio della Azienda sufficientemente accurata.
                                </div>
                            </div>

                            <div className="spazio20"></div>
                            <div className="gauge gauge--liveupdate" id="gauge3">
                                <div className="gauge__container">
                                    <div className="gauge__marker"></div>
                                    <div className="gauge__markerbis"></div>
                                    <div className="gauge__background"></div>
                                    <div className="gauge__center"></div>
                                    <div className="gauge__data"></div>
                                    <div className="gauge__dataB"></div>
                                    <div className="gauge__dataC"></div>
                                    <div className="gauge__needle"></div>
                                </div>
                                <div className="gauge__labels mdl-typography__headline">
                                    <span className="gauge__label--low">BASSO</span>
                                    <span className="gauge__label--spacer">
                                        <span className={coloreIndice}> {testoIndice}</span>
                                    </span>
                                    <span className="gauge__label--high">ALTO</span>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-8">
                            <div><strong>Indice di completezza per tipologia di servizio</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoTip"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>


                                <div id="collapseInfoTip" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Per ogni tipologia di servizio viene indicato il numero di servizi caratterizzati rispetto al numero atteso per raggiungere un indice di completezza ALTO. Viene inoltre visualizzato l'indice di completezza per ogni tipologia. L'indice di completezza totale coincide con il peggior indice di completezza delle tipologie.
                    </div>
                            </div>
                            <div className="spazio10"></div>

                            {this.createTipologieListTop()}
                        </div>

                    </div>
                    <div className="spazio30"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Criticità</h1><hr />

                        </div>
                        <div className="col-lg-4">
                            <div><strong>Profilo di criticità complessivo</strong>: {this.getCriticitàPA()}
                                <a data-bs-toggle="collapse" href="#collapseInfoProfilo"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                                <div id="collapseInfoProfilo" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Profilo di criticità complessivo della  Azienda.
                        </div>

                            </div>

                            <div className="spazio20"></div>

                            <div id="piramide" style={{ minWidth: "210px", maxWidth: "210px", height: "150px", margin: "0 auto" }}>
                            </div>

                        </div>



                        <div className="col-lg-8">
                            <div><strong>Distribuzione del profilo di criticità dei singoli servizi:</strong>
                                <a data-bs-toggle="collapse" href="#collapseInfoDistr"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>

                                <div id="collapseInfoDistr" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Caratterizzati rispetto ai servizi censiti per ciascuna tipologia
                </div>  	</div>

                            <div className="spazio10"></div>
                            {this.getChartDistribuzione()}

                        </div>
                    </div>



                    <div className="spazio20"></div>

                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="testoBlu">Servizi caratterizzati</h1><hr />

                        </div>
                        <div className="col-lg-4">
                            <div><strong>Riepilogo servizi Caratterizzati</strong>
                                <a data-bs-toggle="collapse" href="#collapse6"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                                <div id="collapse6" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Caratterizzati rispetto al
                    totale dei servizi censiti sul sistema.</div>
                            </div>


                            <div className="spazio20"></div>
                            <div className="gaugeImpatti gauge--liveupdateImpatti" id="gaugeImpatti">
                                <div className="gauge__containerImpatti">
                                    <div className="gauge__backgroundImpatti"></div>
                                    <div className="gauge__centerImpatti"></div>
                                    <div className="gauge__dataImpatti"></div>
                                    <div className="gauge__dataImpattiB"></div>
                                    <div className="gauge__needleImpatti"></div>
                                </div>
                                <div className="gauge__labelsImpatti mdl-typography__headlineImpatti">
                                    <span className="gauge__label--lowImpatti">0</span>
                                    <span className="gauge__label--spacerImpatti">{totali.caratterizzati} ({totali.perc * 100}%)</span>
                                    <span className="gauge__label--highImpatti">{totali.tot}</span>
                                </div>

                            </div>




                        </div>

                        <div className="col-lg-8">
                            <div><strong>Riepilogo dei servizi caratterizzati per Tipologia di servizio</strong>
                                <a data-bs-toggle="collapse" href="#collapse7"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                                <div id="collapse7" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Caratterizzati rispetto ai servizi censiti per ciascuna tipologia
                    </div>  	</div>

                            <div className="spazio10"></div>
                            <div className="row font16">
                                {this.createTipologieList()}
                            </div>
                        </div>



                    </div>
                    <div className="spazio10"></div>
                    <div className="spazio20"></div>
                    <div className="row">


                        <div className="col-lg-12">
                            <ListaServizi riepilogo="true" />
                        </div>
                    </div>
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={messageError} />
                </div>
            </div>
        )
    }
}