import React, { Component } from 'react';

class PrivacyPolicy extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    return (

      <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
          <div className="container cnsContainer">

            <section className="Grid" style={{textAlign: 'justify'}}>
            <h2 className="titoloPagina">Informativa sul trattamento dei dati personali ai sensi degli artt. 13-14 del Regolamento (UE) 2016/679</h2>
<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>Titolare del Trattamento dei Dati</h2>

<p>AgID – Agenzia per l’Italia Digitale Indirizzo: Via Liszt 21 - 00144 Roma Indirizzo e-mail/PEC: <a href="mailto:protocollo@pec.agid.gov.it">protocollo@pec.agid.gov.it</a>.</p>


<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Responsabile della Protezione dei Dati
</h2>
<p>Contatti:
  <br/>
  <br/>
  <ul style={{paddingLeft: '40px'}}>
  <li>Indirizzo: Via Liszt 21 - 00144 Roma</li>
	<li>Indirizzo e-mail: <a href="mailto:responsabileprotezionedati@agid.gov.it">responsabileprotezionedati@agid.gov.it</a></li>
  <li>Indirizzo PEC: <a href="mailto:protocollo@pec.agid.gov.it">protocollo@pec.agid.gov.it</a></li>
</ul>
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Autorità di controllo
</h2>
<p>Autorità di controllo alla quale inviare segnalazioni e/o reclami:
  <br/>
  <br/>
  <ul style={{paddingLeft: '40px'}}>
  <li>Autorità Garante per la protezione dei dati personali (<a href="https://www.garanteprivacy.it/">www.garanteprivacy.it/</a>)</li>
	<li>Indirizzo e-mail: <a href="mailto:garante@gpdp.it">garante@gpdp.it</a></li>
  <li>Indirizzo PEC: <a href="mailto:protocollo@pec.gpdp.it">protocollo@pec.gpdp.it</a></li>
</ul>
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Tipologie di dati raccolti
</h2>
<p>I dati personali raccolti dal Titolare sono costituiti esclusivamente da dati tecnici e analitici, raccolti automaticamente mediante l’uso di cookies tecnici o di log automatici.
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Finalità del trattamento
</h2>
<p>I dati degli Utenti sono raccolti esclusivamente per le seguenti finalità:
  <br/>
  <br/>
    <ul style={{paddingLeft: '40px'}}> 
      <li>Gestione tecnica sito web</li>
      <li>Gestione dati analitici e di traffico sito web</li>
    </ul>
  <br/>
  Gli Utenti possono ottenere chiarimenti sulla finalità della raccolta dei dati o su quali dati siano effettivamente acquisiti, contattando il Titolare. Ove gli Utenti ritengano eccessivi i dati trattati rispetto alle finalità sopra indicate, possono rivolgersi al Responsabile della Protezione dei Dati.
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Categorie di destinatari dei dati
</h2>
<p>Il Titolare, nello svolgimento delle proprie attività e per erogare i propri servizi, potrebbe trasmettere i dati alle seguenti categorie di Destinatari:
  <br/>
  <br/>
    <ul style={{paddingLeft: '40px'}}> 
      <li>Pubbliche Amministrazioni;</li>
      <li>Fornitori di servizi tecnici e hosting provider.</li>
    </ul>
  <br/>
  I Destinatari sono nominati, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento. I Fornitori, in base al contratto di servizio stipulato, sono tenuti a utilizzare i dati personali esclusivamente per le finalità indicate dal Titolare, a non conservarli oltre la durata indicata né a trasmetterli a terzi senza l’esplicita autorizzazione del Titolare.
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Trasferimento dei dati in Paesi terzi o a organizzazioni internazionali
</h2>
<p>
Il Titolare non intende trasferire i dati personali a Paesi terzi o a organizzazioni internazionali.
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Modalità del trattamento dei dati raccolti
</h2>
<p>
<strong style={{color: '#0066cc'}}>Modalità di trattamento</strong>
<br/>
<br/>
Il Titolare tratta i dati personali degli utenti adottando le misure di sicurezza adeguate a impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzata dei dati personali. Il trattamento viene effettuato anche mediante strumenti informatici e/o telematici, con modalità organizzative e misure tecniche adeguate a garantire la sicurezza dei dati.
<br/>
<br/>
<strong style={{color: '#0066cc'}}>Luogo di trattamento</strong>
<br/>
<br/>
I dati sono trattati presso la sede del Titolare e presso le sedi dei Responsabili ovunque siano localizzate (sempre all’interno della Unione Europea).
<br/>
<br/>
<strong style={{color: '#0066cc'}}>Tempi di conservazione dei dati</strong>
<br/>
<br/>
I dati sono trattati per il tempo necessario allo svolgimento del servizio richiesto dall’Utente o necessario per le finalità descritte nel presente documento ovvero indicate dalla normativa vigente.
</p>
<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Diritti degli Utenti
</h2>
<p>
Gli Utenti, ai quali i dati personali sono riferiti, hanno il diritto in qualunque momento di ottenere la conferma dell'esistenza o meno degli stessi presso il Titolare del Trattamento, di conoscerne il contenuto e l'origine, di verificarne l'esattezza o chiederne l’integrazione, la cancellazione, l'aggiornamento, la rettifica e la limitazione, nonché di opporsi al loro trattamento. Nei casi in cui l’Utente abbia rilasciato il consenso al trattamento, la relativa revoca non pregiudica la liceità del trattamento svolto precedentemente. E’ inoltre facoltà dell’Utente richiedere copia dei dati gestiti. Le richieste vanno rivolte al Titolare del Trattamento. Eventuali reclami possono essere inviati al Responsabile della Protezione dei Dati. E’ diritto dell’Utente proporre reclamo presso l’Autorità Garante per la protezione dei dati (<a href="https://www.garanteprivacy.it">www.garanteprivacy.it</a>) Il Titolare garantisce che non è prevista alcuna forma di processo decisionale automatizzato che comporti effetti giuridici sull’Utente.
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Ulteriori informazioni sul trattamento
</h2>
<p>
<strong style={{color: '#0066cc'}}>Difesa in giudizio</strong>
<br/>
<br/>
I dati personali dell’Utente possono essere utilizzati, da parte del Titolare, in giudizio o nelle fasi propedeutiche alla sua eventuale instaurazione per la difesa da abusi da parte dell’Utente. L’Utente è consapevole che al Titolare potrebbe essere richiesto di comunicare i dati su richiesta delle pubbliche autorità.
<br/>
<br/>
<strong style={{color: '#0066cc'}}>Informative specifiche</strong>
<br/>
<br/>
Su richiesta dell’Utente, in aggiunta alle informazioni contenute nella presente informativa, il Titolare potrebbe fornire all'Utente informative aggiuntive riguardanti servizi specifici o la raccolta ed il trattamento di dati personali.
<br/>
<br/>

</p>
<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Cookie Policy
</h2>
<p>
I cookie sono piccoli file di testo che i siti visitati inviano al terminale dell'Utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
<br/>
I cookie utilizzati dal sito non consentono la raccolta di informazioni personali relative all’utente e si cancellano automaticamente con la chiusura del browser di navigazione.
<br/>
I dati di navigazione sono cancellati subito dopo la relativa elaborazione statistica e in ogni caso non vengono conservati per oltre 7 giorni dal momento della raccolta, salvo che la loro ulteriore conservazione non si renda necessaria per l’accertamento di reati.
<br/>
I cookie non sono utilizzati per attività di profilazione dell’utente.
<br/>
L’utente può scegliere di abilitare o disabilitare i cookie intervenendo sulle impostazioni del proprio browser di navigazione secondo le istruzioni rese disponibili dai relativi fornitori ai link di seguito indicati. 
<br/>
<br/>
<ul style={{paddingLeft: '40px'}}><li><a href="https://support.google.com/chrome/answer/95647?hl=eng" target="_blank">Chrome</a></li>
	<li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie#w_impostazioni-dei-cookie" target="_blank">Firefox</a></li>
	<li><a href="https://support.apple.com/it-it/HT201265" target="_blank">Safari</a></li>
	<li><a href="https://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" target="_blank">Internet Explorer</a></li>
	<li><a href="https://www.opera.com/help/tutorials/security/cookies/" target="_blank">Opera</a></li>
</ul>
</p>

<h2 style={{fontSize: '20px', color: '#0066cc', borderBottom: '2px #0066cc solid', paddingBottom: '10px', width: '100%', marginTop: '15px'}}>
Modifiche alla presente informativa
</h2>
<p>
Il Titolare si riserva il diritto di apportare modifiche alla presente informativa, dandone pubblicità agli Utenti sulla presente pagina. Si prega dunque di tornare a consultare questa pagina, prendendo come riferimento la data di ultima modifica indicata a conclusione del testo. Nel caso di mancata accettazione delle modifiche apportate alla presente informativa, l’Utente può richiedere al Titolare di rimuovere i propri dati personali. Salvo quanto diversamente specificato, la precedente privacy policy continuerà ad applicarsi ai dati personali sino a quel momento raccolti.
</p>
<br/>
<br/>
<p style={{textAlign: "right "}}>Data di aggiornamento dell'informativa : 30/11/2018</p>
            </section>
			
        </div>
        </div>
       
    );
  }
}

export default PrivacyPolicy;
