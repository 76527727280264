import * as React from 'react';

// component that renders a single caratteristica row
export class ServiziTrasversaliRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.updateServizioTrasversale = this.updateServizioTrasversale.bind(this);
        this.updateNotaServizioTrasversale = this.updateNotaServizioTrasversale.bind(this);
        this.isIconHidden = this.isIconHidden.bind(this);
    }

    /**
     * 
     * @param {*} servizioTrasversale 
     * @param {*} servizioTrasversaleId 
     */
    updateServizioTrasversale(servizioTrasversale) {
        let servizioTrasversaleId = servizioTrasversale.ServizioTrasversaleId
        this.props.updateServizioTrasversale(servizioTrasversaleId);
    }

    isIconHidden() {
        var response = "hidden";

        for (let x = 0; x < this.props.trasversalServices.length; x++) {
            if (this.props.trasversalServices[x].Checked) {
                response = "visible";
                break;
            }
        }
        return response;
    }

    /**
    * 
    */
    updateNotaServizioTrasversale(servizioTrasversale, e) {
        //console.log(e.target.value);
        let notaAggiuntiva = e.target.value;
        let servizioTrasversaleId = servizioTrasversale.ServizioTrasversaleId
        this.props.updateNotaServizioTrasversale(servizioTrasversaleId, notaAggiuntiva);
    }

    render() {
        //console.log('this.props:', this.props);
        let rightList = [];
        let leftList = [];
        var item;
        if (!this.props.trasversalServices.length) {
            item = <div className="row">
                Loading...
        </div>
        } else {
            for (let x = 0; x < this.props.trasversalServices.length; x++) {

                if (this.props.readOnly) {
                    if (this.props.trasversalServices[x].Checked)
                        item = <div key={"canale" + x}>
                            <i className=" fa fa-check-square-o"></i> &nbsp;<strong>{this.props.trasversalServices[x].NomeServizioTrasversale}</strong>

                            <br />
                            {this.props.trasversalServices[x].NotaAggiuntiva !== undefined && this.props.trasversalServices[x].NotaAggiuntiva}

                            <hr />
                        </div>;
                    else
                        item = <div key={this.props.categoryName + "canale" + x}>
                            <span className="testoGrigio">{this.props.trasversalServices[x].NomeServizioTrasversale}</span><hr />
                        </div>;

                }
                else {
                    item = <div key={this.props.trasversalServices[x].NomeServizioTrasversale + x}>
                        <input type="checkbox"
                            onChange={() => this.updateServizioTrasversale(this.props.trasversalServices[x])}
                            //defaultChecked={this.props.caratteristiche[i].Checked}
                            checked={this.props.trasversalServices[x].Checked}
                        /> &nbsp;{this.props.trasversalServices[x].NomeServizioTrasversale}
                        <br />

                        <input type="text"
                            hidden={!this.props.trasversalServices[x].Checked}
                            value={this.props.trasversalServices[x].NotaAggiuntiva !== undefined ? this.props.trasversalServices[x].NotaAggiuntiva : ''}
                            onChange={(e) => this.updateNotaServizioTrasversale(this.props.trasversalServices[x], e)}
                            placeholder="Inserisci nota"
                            onBlur={this.props.saveDraft}
                            style={{ width: '100%' }} />
                        <hr />
                    </div>;
                }

                if ((x + 1) % 2 === 0)
                    rightList.push(item);
                else
                    leftList.push(item);
            }

        }

        return (

            <div>
                <div className="row">
                    <div className="col-lg-12 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i style={{ visibility: this.isIconHidden() }} className="testoVerde fa fa-check"></i> {this.props.trasversalServiceName}<i className="iconRight fa fa-chevron-down"></i></a>
                    </div>
                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="collapse" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};