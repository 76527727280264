import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { CaratteristicaRow } from './CaratteristicaRow';
import { ErogazioneRow } from './ErogazioneRow';
import { ServiziTrasversaliRow } from './ServiziTrasversaliRow';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { AuthenticationRow } from './AuthenticationRow';


/**
 * 
 * @param {*} array 
 */
function toString(array) {
    let toString = '';
    try {
        for (let index = 0; index < array.length; index++) {
            const element = array[index].Nome;
            toString += element + ' | ';
        }
        return toString.substring(0, toString.length - 2);
    } catch (error) {
        console.error(error);
        return null
    }
}

const LINK = [
    { nome: "Analisi del contesto", url: "/AnalisiContesto" },
    { nome: "Elenco servizi", url: "/ListaServizi" },
    { nome: "Visualizza servizio", url: "/AnalisiContestoVisualizza" }
];

export class AnalisiContestoVisualizza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            serviziComposite: [],
            referente: '',
            struttura: '',
            serviceId: window.sessionStorage.getItem('serviceId'),
            //serviceId: 'Test01',
            serviceName: '',
            descrizione: '',
            categorieToString: '',
            tipologieToString: '',
            messageError: '',
            readOnly: true
        }
        this.updateCaratteristica = this.updateCaratteristica.bind(this);
        this.updateNotaAggiuntiva = this.updateNotaAggiuntiva.bind(this);
        this.updateReferente = this.updateReferente.bind(this);
        this.updateStruttura = this.updateStruttura.bind(this);
        this.onSave = this.onSave.bind(this);
        this.isUpdated = this.isUpdated.bind(this);
    }

    // on mount, get all categories and store them in this component's state
    componentDidMount() {
        //console.log(sessionStorage.getItem('token'));
        // submit form data to api
        //curl -X POST "https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById" -H "accept: application/json" -H "Content-Type: application/json" -d "{ \"ServizioId\": \"S2\"}"
        this.serverRequest =
            $.ajax({
                //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getById',
                url: API_CONFIG.servizicompositeByIdUrl,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ ServizioId: this.state.serviceId }),//data:"{ \"ServizioId\": \"S2\"}",
                cache: false,
                success: function (response) {
                    this.setState({ serviziComposite: response.data });
                    this.setState({ referente: response.data[0].Referente });
                    this.setState({ struttura: response.data[0].StrutturaReferente });
                    this.setState({ serviceName: response.data[0].Nome });
                    this.setState({ descrizione: response.data[0].Descrizione });
                    this.setState({ dataAggiornamento: response.data[0].DataCompilazioneContesto });
                    this.setState({ dataCalcoloCriticità: response.data[0].DataUpdateProfiloDiCriticità });
                    this.setState({ categorieToString: toString(response.data[0].Categorie) });
                    this.setState({ tipologieToString: toString(response.data[0].Tipologie) });
                    console.log('serviziComposite: ', response.data);
                    /*
                    this.setState({servicesPrimary:this.getServiziComposite(response.data)[1]});
                    this.setState({servicesSecondary:this.getServiziComposite(response.data)[0]});
                  */
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                    }
                    else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                }.bind(this),
            });
    }

    isUpdated() {
        try {

            let tsDataAggiornamento = new Date(this.state.dataAggiornamento).getTime();
            let tsDataCalcoloCriticità = new Date(this.state.dataCalcoloCriticità).getTime();

            return tsDataAggiornamento > tsDataCalcoloCriticità;
        }
        catch (error) {
            return false;
        }
    }

    /**
     * 
     * @param {*} rowId 
     * @param {*} IdCaratteristica 
     */
    updateCaratteristica(rowId, caratteristicaId) {
        //cambiare il valore Checked
        var _serviziComposite = this.state.serviziComposite;
        const element = _serviziComposite[0].CategorieCaratteristica;
        for (let i = 0; i < element.length; i++) {
            if (element[i].CategoriaCaratteristicaId === rowId) {
                for (let j = 0; j < element[i].Caratteristica.length; j++) {
                    const caratteristica = element[i].Caratteristica[j];
                    if (caratteristica.Id === caratteristicaId) {
                        //alert(JSON.stringify(caratteristica))
                        element[i].Caratteristica[j].Checked = !element[i].Caratteristica[j].Checked;
                        this.setState({ serviziComposite: _serviziComposite });
                    }
                }

            }
        }
        //console.log('this.sate.serviziComposite:', this.state.serviziComposite);
    }

    /**
     * 
     * @param {*} notaAggiuntiva value of input text
     * @param {*} categoryRow index of CategorieCaratteristica
     * @param {*} IdCaratteristica index of Caratteristica
     */
    updateNotaAggiuntiva(rowId, caratteristicaId, notaAggiuntiva) {
        var _serviziComposite = this.state.serviziComposite;
        const element = _serviziComposite[0].CategorieCaratteristica;
        for (let i = 0; i < element.length; i++) {
            if (element[i].CategoriaCaratteristicaId === rowId) {
                for (let j = 0; j < element[i].Caratteristica.length; j++) {
                    const caratteristica = element[i].Caratteristica[j];
                    if (caratteristica.Id === caratteristicaId) {
                        //alert(JSON.stringify(caratteristica))
                        element[i].Caratteristica[j].NotaAggiuntiva = notaAggiuntiva;
                        this.setState({ serviziComposite: _serviziComposite });
                    }
                }

            }
        }
        //console.log('this.sate.serviziComposite:', this.state.serviziComposite);
    }

    updateReferente(event) {
        let value = event.target.value;
        let serviziComposite = this.state.serviziComposite;
        serviziComposite[0].Referente = value;
        this.setState({ serviziComposite: serviziComposite });
        this.setState({ referente: value });
    }

    updateStruttura(event) {
        let value = event.target.value;
        let serviziComposite = this.state.serviziComposite;
        serviziComposite[0].StrutturaReferente = value;
        this.setState({ serviziComposite: serviziComposite });
        this.setState({ struttura: value });
    }

    /**
    * handle save button clicked
    */
    onSave(e) {
        // data in the form
        console.log('data', this.state.serviziComposite);
        // submit form data to api
        $.ajax({
            //url:'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/saveOrUpdate',
            url: API_CONFIG.serviziSaveOrUpdateUrl,
            type: "POST",
            contentType: 'application/json',
            headers: { 'ServizioId': this.state.serviceId, 'token': sessionStorage.getItem('token') },
            data: JSON.stringify(this.state.serviziComposite),
            cache: false,
            success: function (response) {
                console.log(response);
                if (response.status === 'success') {
                    alert(response.status);
                    //location.reload();
                }
                else {
                    //TODO
                }
            },

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                }
                else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
            }.bind(this),
        });

        e.preventDefault();
    }

    render() {

        var rows = '';
        if (!this.state.serviziComposite.length) {
            rows = <div className="row">
                Loading...
        </div>
        } else {
            //console.log('serviceComposite:',this.state.serviziComposite);
            rows = this.state.serviziComposite[0].CategorieCaratteristica.map(function (categorieCaratteristica, i) {
                return (
                    categorieCaratteristica.CategoriaCaratteristicaId === 2 ?
                        <ErogazioneRow
                            key={i}
                            rowId={i}
                            readOnly={true}
                            categoryName={categorieCaratteristica.Nome}
                            caratteristiche={categorieCaratteristica.Caratteristica}
                            categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                            updateCaratteristica={this.updateCaratteristica}
                            updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                        />

                        :
                        categorieCaratteristica.CategoriaCaratteristicaId === 4 ?
                            <AuthenticationRow
                                key={i}
                                rowId={i}
                                readOnly={true}
                                categoryName={categorieCaratteristica.Nome}
                                caratteristiche={categorieCaratteristica.Caratteristica.sort(function (a, b) { return a.Id - b.Id })}
                                categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                                updateCaratteristica={this.updateCaratteristica}
                                updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                                saveDraft={this.saveDraft}
                            />

                            :

                            <CaratteristicaRow
                                key={i}
                                rowId={i}
                                readOnly={true}
                                categoryName={categorieCaratteristica.Nome}
                                caratteristiche={categorieCaratteristica.Caratteristica}
                                categoriaCaratteristicaId={categorieCaratteristica.CategoriaCaratteristicaId}
                                updateCaratteristica={this.updateCaratteristica}
                                updateNotaAggiuntiva={this.updateNotaAggiuntiva}
                            />
                );
            }.bind(this)
            );
        }

        let trasversalService = '';
        if (this.state.serviziComposite.length) {
            trasversalService = this.state.serviziComposite.map(function (serviziComposite, i) {
                //debugger
                return (
                    <ServiziTrasversaliRow
                        key={i}
                        readOnly={true}
                        rowId={this.state.serviziComposite[0].CategorieCaratteristica.length + 1}
                        trasversalServiceName={'Utilizzo Altri Servizi Trasversali Locali/Nazionali'}
                        trasversalServices={this.state.serviziComposite[0].ServiziTrasversaliPA}
                        updateServizioTrasversale={this.updateServizioTrasversale}
                        updateNotaServizioTrasversale={this.updateNotaServizioTrasversale}
                    />
                );
            }.bind(this)
            );
        }

        let InfoIconHidden = (!((this.state.referente != null && this.state.referente.length > 0)
            && (this.state.struttura != null && this.state.struttura.length > 0))) ? "hidden" : "visible";

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">
                        <div className="col-lg-12">
                            <Breadcrumb links={LINK} />
                            <span className="titoloPagina">Caratteristiche del Servizio: {this.state.serviceName} </span>&nbsp;<i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>Elenco delle caratteristiche di sicurezza compilate dall’utente. </p>
                            <span className="paRight InfoAgg"><i hidden={!this.isUpdated()} className="fa fa-warning testoRosso"></i> {this.isUpdated() && "servizio modificato"} </span>
                        </div>
                    </div>

                    <div className="linea0066cc"></div>

                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <a className="card-linkInfo" data-bs-toggle="collapse" href="#collapseInfoGenerali">
                                    <i style={{ visibility: InfoIconHidden }} className="testoVerde fa fa-check"></i> Informazioni generali<i className="iconRight fa fa-chevron-down"></i></a>
                            </div>
                        </div>

                        <hr />

                        <div id="collapseInfoGenerali" className="col-lg-12 collapse" data-parent="#accordion">
                            <div className="row  card-headerLight">
                                <div className="col-lg-6">
                                    <strong>Referente</strong><br />
                                    {this.state.referente}
                                </div>
                                <div className="col-lg-6">
                                    <strong>Struttura</strong><br />
                                    {this.state.struttura}
                                </div>
                                <div className="col-lg-6"><hr />
                                    <strong>Categorie</strong><br />
                                    {this.state.categorieToString}</div>
                                <div className="col-lg-6"><hr />
                                    <strong>Tipologie</strong><br />
                                    {this.state.tipologieToString}</div>
                                <div className="col-lg-12 ">
                                    <hr />  <p><strong>Descrizione</strong><br />
                                        {this.state.descrizione} </p>
                                </div>
                                <div className="col-lg-12 ">
                                    <div className="">
                                        <hr />
                                    </div></div>	</div>
                        </div>
                    </div>

                    <div className="container cnsContainer">
                        {rows}
                        {trasversalService}
                        <br />
                        <br />
                        <div className="Form-field Grid-cell u-textCenter">
                        </div>
                        <br />
                    </div>
                </div>
                <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={'Analisi del Rischio creata!'} />
                <ModalDialog idTarget={'warningAction'} idBtn={'btnIniziaAssessment'} handle={this.iniziaAssessment} textModal={'Waring'} />
                <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={'Prima di effettuare il Calcolo compilare tutte le risposte!'} />
                <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
            </div>
        )
    }
}