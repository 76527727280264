import React, { Component } from 'react';

export class LegendaTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //array: this.props.columnsList
            rowsTable: [
                { Answer: "Non Applicabile", Description: "Il requisito non è applicabile al perimetro in esame (ad esempio ‘Livello protezione dati personali’ non è applicabile se l’applicazione non tratta questa tipologia di dati)." },
                { Answer: "Non so Rispondere", Description: "" },
                { Answer: "Assente", Description: "Il requisito non è stato implementato." },
                { Answer: "Migliorabile", Description: "L'organizzazione ha implementato parzialmente le procedure necessarie per garantire il soddisfacimento del requisito (utilizzare come linee guida quanto riportato nelle informazioni aggiuntive relative al controllo), tuttavia esistono ancora dei gap significativi da dover colmare per considerare il requisito soddisfatto." },
                { Answer: "Adeguato", Description: "L'organizzazione ha implementato in maniera adeguata le procedure necessarie per garantire il soddisfacimento del requisito (utilizzare come linee guida quanto riportato nelle informazioni aggiuntive relative al controllo), tuttavia esistono dei piccoli gap da dover colmare al fine di considerare il requisito pienamente soddisfatto." },
                { Answer: "Completo", Description: "L'organizzazione ha implementato tutte le procedure necessarie a garantire il pieno soddisfacimento del requisito (utilizzare come linee guida quanto riportato nelle informazioni aggiuntive relative al controllo)." }
            ]
        }
        //this.onClick = this.onClick.bind(this);
    }

    render() {

        let rows = [];

        for (let index = 0; index < this.state.rowsTable.length; index++) {
            let item = this.state.rowsTable[index];
            let widthCol1 = (this.props.widthCol1) ? this.props.widthCol1 : "";
            let widthCol2 = (this.props.widthCol2) ? this.props.widthCol2 : "";
            let bgcolor = (this.props.bgcolor) ? this.props.bgcolor : "";

            let trElement =
                <tr key={"row" + index}>
                    <td width={widthCol1} bgcolor={bgcolor}><strong>{item.Answer}</strong></td>
                    <td width={widthCol2} bgcolor={bgcolor}>{item.Description}</td>
                </tr>;
            rows.push(trElement);
        }

        return (
            <table width="100%" border="1"
                bordercolor={(this.props.bordercolor) ? this.props.bordercolor : ""}
                cellPadding={(this.props.cellPadding) ? this.props.cellPadding : ""}
                cellSpacing={(this.props.cellSpacing) ? this.props.cellSpacing : ""}>
                <tbody>
                    <tr>
                        <td width={(this.props.widthCol1) ? this.props.widthCol1 : ""}>
                            <strong>RISPOSTA</strong>
                        </td>
                        <td width={(this.props.widthCol2) ? this.props.widthCol2 : ""}>
                            <strong>DESCRIZIONE</strong>
                        </td>
                    </tr>
                    {rows}
                </tbody>
            </table >
        );
    }
}