import * as React from 'react';
import { GradoImplementationeOption } from './GradoImplentazioneOption'
import infoIcon from '../../public/images/info.png';

let SELECT = []
SELECT[-1] = '';
SELECT[1] = 'Non Applicabile';
SELECT[2] = 'Non so rispondere';
SELECT[3] = 'Assente';
SELECT[4] = 'Migliorabile';
SELECT[5] = 'Adeguato';
SELECT[6] = 'Completo';

// component that renders a single caratteristica row
export class MisuraDiSicurezzaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.getCounterAnsewers = this.getCounterAnsewers.bind(this);
        this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
        this.getEdited = this.getEdited.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.toStringInfoAggiuntive = this.toStringInfoAggiuntive.bind(this);
    }



    /**
     * retunr conter of total ansewers not equals to Non so rispondere
     */
    getCounterAnsewers() {

        let count = 0;
        let listItems = this.props.items;
        for (let index = 0; index < listItems.length; index++) {
            const misuraDiSicurezza = listItems[index];
            if (misuraDiSicurezza.GradoDiImplementazione !== -1)
                count += 1;
        }
        return count;
    }

    /**
     * 
     * @param {*} rows 
     */
    addLineaGrigiaT(rows) {
        try {

            let listRowsNew = [];
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                listRowsNew.push(element);
                listRowsNew.push(<div className='lineaGrigiaT'></div>);
            }
            return listRowsNew;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    isSelected() {
        let assassments = this.props.items;
        for (let index = 0; index < assassments.length; index++) {
            if (assassments[index].GradoDiImplementazione === -1) {
                return 'hidden';
            }
        }
        return 'visible';
    }

    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
        return toString;
    }

    updateNote(event, misuraDiSicurezzaId) {
        var note = event.target.value;
        misuraDiSicurezzaId = parseInt(misuraDiSicurezzaId, 10);
        this.props.updateNote(note, this.props.categoriaId, misuraDiSicurezzaId);
        //console.log('updateGrade',item);
    }

    getEdited()
    {
        var res = false;
        let items = this.props.items;
        let itemsPrev = this.props.itemsPrev;
        if(itemsPrev.length > 0)
            for (let key = 0; key < items.length; key++) {
                let codice = items[key].MisureDiSicurezzaCodice;
                let prevItem= null;
                for (let i = 0; i < itemsPrev.length; i++) {
                    if(itemsPrev[i].MisureDiSicurezzaCodice === codice)
                    {
                        prevItem = itemsPrev[i];
                        break;
                    }
                }
                if(prevItem !== null && items[key].GradoDiImplementazione !== prevItem.GradoDiImplementazione)
                {
                   res = true;
                   break;
                }
            }

        return res;
    }

    render() {
        //console.log("this.props.items",this.props.itemsPrev);
        var rows = '';
        if (!this.props.items.length) {
            rows = <div className="row">
                Loading...
        </div>
        } else {
            rows = this.props.items.map(function (item, i) {
                let index = -1;
                let codice = this.props.items[i].MisureDiSicurezzaCodice;
                if(this.props.singolaAnalisi)
                    index = this.props.itemsPrev.findIndex(i=> i.MisureDiSicurezzaCodice === codice);
                
                    let modified = false;
                if(index > -1)
                {
                    if(this.props.items[i].GradoDiImplementazione !== this.props.itemsPrev[index].GradoDiImplementazione)
                    modified = true;                    
                }

                let style = modified ? {border:"1px solid red", marginTop:"2px"} : {};

                return (
                    this.props.readOnly ?
                        <div key={i} className="row" style={style}>

                            <div className="col-lg-10 testoNero giustifica marginRight">
                                <table width="100%" border="0" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td align="left" valign="middle">
                                                <a data-bs-toggle="collapse" href={"#collapseAA" + parseInt(this.props.items[i].MisuraDiSicurezzaId, 10)}>
                                                    <span className=" testoBlu paLeft">
                                                        <img src={infoIcon} alt="iconInfo" />
                                                    </span>
                                                </a>
                                            </td>
                                            <td align="left" valign="middle">{this.props.items[i].MisureDiSicurezzaNome}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id={"collapseAA" + parseInt(this.props.items[i].MisuraDiSicurezzaId, 10)} className="collapse spazio10 InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                    {this.toStringInfoAggiuntive(this.props.items[i].InformazioniAggiuntive)}
                                </div>
                            </div>
                            <div className="col-lg-2 spazio10">
                                {/* <input name="textfield" type="text" value={SELECT[this.props.items[i].GradoDiImplementazione]} disabled /> */}
                                {SELECT[this.props.items[i].GradoDiImplementazione]}
                                <a data-bs-toggle="collapse" href={"#collapseNOTA" + this.props.categoriaId + this.props.items[i].MisuraDiSicurezzaId}>
                                    <div hidden={!(this.props.items[i].Nota)} className=" testoBlu font14bis text-center">Leggi nota</div>
                                </a>

                                <div id={"collapseNOTA" + this.props.categoriaId + this.props.items[i].MisuraDiSicurezzaId}
                                    className="collapse spazio10 InfoAgg text-justify " data-parent="#accordion">
                                    {this.props.items[i].Nota}
                                </div>
                            </div>
                        </div>
                        :
                        <div key={i} className="row">

                            <div className="col-lg-10 testoNero giustifica marginRight">
                                <table width="100%" border="0" cellPadding="5">
                                    <tbody>
                                        <tr>
                                            <td align="left" valign="middle">
                                                <a data-bs-toggle="collapse" href={"#collapseAA" + parseInt(this.props.items[i].MisuraDiSicurezzaId, 10)}>
                                                    {/* <i className=" fa fa-info-circle fa20 testoBlu"></i> */}
                                                    <span className=" testoBlu paLeft">
                                                        <img src={infoIcon} alt="iconInfo" />
                                                    </span>
                                                </a>
                                            </td>
                                            <td align="left" valign="middle">{this.props.items[i].MisureDiSicurezzaNome}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id={"collapseAA" + parseInt(this.props.items[i].MisuraDiSicurezzaId, 10)} className="collapse spazio10 InfoAgg  " data-parent="#accordion"><div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                    {this.toStringInfoAggiuntive(this.props.items[i].InformazioniAggiuntive)}
                                </div>
                            </div>
                            <div className="col-lg-2 spazio10"><div className="spazio10"></div>
                                <GradoImplementationeOption misuraDiSicurezza={this.props.items[i]} categoriaId={this.props.categoriaId} updateGrade={this.props.updateGrade} />
                                <a data-bs-toggle="collapse" href={"#collapseNOTA" + this.props.categoriaId + this.props.items[i].MisuraDiSicurezzaId}><span className=" testoBlu font14bis text-center">Inserisci nota</span></a>

                                <div id={"collapseNOTA" + this.props.categoriaId + this.props.items[i].MisuraDiSicurezzaId} className="collapse spazio10 InfoAgg  " data-parent="#accordion">
                                    <textarea className="form-control"
                                        value={(this.props.items[i].Nota) ? this.props.items[i].Nota : ""}
                                        onChange={(e) => this.updateNote(e, this.props.items[i].MisuraDiSicurezzaId)}
                                        onBlur={this.props.saveDraft}
                                        placeholder={'Inserisci Nota'}
                                        style={{ minWidth: "100%" }}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                );
            }.bind(this)
            );
        }



        let faCheck = this.props.singolaAnalisi && this.getEdited()  ? "testoRosso fa fa-check" : "testoVerde fa fa-check";

        return (

            <div>
                <div className="row">
                    <div className="col-lg-10">
                        <a className="card-link " data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i style={{ visibility: this.isSelected() }} className={faCheck}></i>{this.props.misuraDiSicurezzaNome}</a>
                    </div>
                    <div className="col-lg-1 card-header text-right">{this.getCounterAnsewers() + '/' + this.props.items.length}
                    </div>
                    <div className="col-lg-1 card-headerInfo">
                        <a className="card-link " data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i className="iconRight fa fa-chevron-down"></i>
                        </a>
                    </div>
                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="collapse marginLeft" data-parent="#accordion">
                    {rows}
                    <hr />
                </div>
            </div>
        );
    }
};