import * as React from 'react';
import { Breadcrumb } from "../Common/Breadcrumb";
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import LIVELLO from '../data/criticality';
import { Util } from '../data/Util';
import ACTION_STATE from "../data/ActionState";
import { isNullOrUndefined } from 'util';
import infoIcon from '../../public/images/info.png';

const LINK_PA = [
    { nome: "Executive summary", url: "/ESriepilogoPA" }
];
const LINK = [
    { nome: "Executive Summary Servizi", url: "/ElencoExecutiveSummary" },
    { nome: "Executive summary", url: "/ESriepilogoServizio" }
];
// component that renders a single this.props.minaccia row
export class ExecutiveSummaryRiepilogo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPA: this.props.PA,
            serviceId: (this.props.PA) ? "" : props.location.state.serviceId,
            serviceName: (this.props.PA) ? "" : props.location.state.serviceName,
            nomePA: "",
            criticità: "",
            impattoD: "",
            impattoI: "",
            impattoR: "",
            rischioAssessment: "",
            indiceTotale: "",
            indiceTotaleImpatti: [{ IndiceTotale: "" }],//[0].IndiceTotale: 1
            nomeCategoria: "",
            rischioCategorieMinaccia: { "Utilizzo improprio e/o errori": 0, "Attacchi Logici e/o Fisici": 0, "Minacce Ambientali": 0, "Minacce Legali": 0 },
            totEfficacia: "",
            totMitigate: "",
            livelloDiEfficacia: "",
            statoAssessmentId: "",
            azioniDiTrattamentoList: [{ stato: "" }, { stato: ACTION_STATE[1], totaleAzioni: 0 }, { stato: ACTION_STATE[2], totaleAzioni: 0 }, { stato: ACTION_STATE[3], totaleAzioni: 0 }, { stato: ACTION_STATE[4], totaleAzioni: 0 }],
        };
        this.setStateFromExecutiveSummary = this.setStateFromExecutiveSummary.bind(this);
        this.setRischioCategorieMinaccia = this.setRischioCategorieMinaccia.bind(this);
        this.getImpattoRIDcomplessivo = this.getImpattoRIDcomplessivo.bind(this);
        this.setTotaleAzioniDiTrattamento = this.setTotaleAzioniDiTrattamento.bind(this);
        this.setNomePA = this.setNomePA.bind(this);
    }

    componentDidMount() {
        //window.setGauge("gauge3", 0.99/*this.getIndice()*/);
        //window.setGauge4liv("gauge11", 0.2/*RISERVATEZZA*/);
        //window.setGauge4liv("gauge12", 0.4/*INTEGRITA*/);
        //window.setGauge4liv("gauge13", 0.6/*DISPONIBILITA*/);
        //window.setPyramidCriticità(2);
        //window.setPieRischio(1, 2, 3, 4, 2);//getRischioCategorieMinaccia
        //window.setTest(25, 50, 75, 100);
        //window.setGaugeEfficacia("gauge9", 0.9/*this.getIndice()*/);
        this.setNomePA();
        this.serverRequest =
            $.ajax({
                //url: "http://192.168.1.17:4003/api/assessmentcomposite/executiveSummary",
                url: API_CONFIG.assessmentcompositeExecutiveSummary,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify({ ServizioId: (this.props.PA) ? "" : this.state.serviceId }),
                cache: false,
                success: function (response) {
                    console.log('assessmentcompositeExecutiveSummary: ', response.data);
                    this.setStateFromExecutiveSummary(response.data);
                    //console.log("state", this.state);
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });
    }

    /**
     * 
     */
    setStateFromExecutiveSummary(responseData) {
        if (this.props.PA) {
            if (!isNullOrUndefined(responseData.AssessmentPA)) {
                //set Profilo di criticità intrinseca
                var criticitàPA = responseData.AssessmentPA.Criticità < 0 ? 0 : responseData.AssessmentPA.Criticità;
                this.setState({ criticità: criticitàPA }, () => window.setPyramidCriticità(this.state.criticità));
                //set Impatto R-I-D
                let impattoR = responseData.AssessmentPA.ImpattoR < 0 ? 0.01 : responseData.AssessmentPA.ImpattoR / 4;
                let impattoI = responseData.AssessmentPA.ImpattoI < 0 ? 0.01 : responseData.AssessmentPA.ImpattoI / 4;
                let impattoD = responseData.AssessmentPA.ImpattoD < 0 ? 0.01 : responseData.AssessmentPA.ImpattoD / 4;
                this.setState({ impattoD: responseData.AssessmentPA.ImpattoD }, () => window.setGauge4liv("gauge13", impattoD));
                this.setState({ impattoI: responseData.AssessmentPA.ImpattoI }, () => window.setGauge4liv("gauge12", impattoI));
                this.setState({ impattoR: responseData.AssessmentPA.ImpattoR }, () => window.setGauge4liv("gauge11", impattoR));
                //
                this.setState({ rischioAssessment: responseData.AssessmentPA.RischioComplessivo });
                //set Indice di qualità dell'analisi del rischio
                this.setState({ indiceTotaleImpatti: responseData.getIndiceTotaleImpatti }, () => window.setGauge("gauge3", this.getIndice(this.state.indiceTotaleImpatti[0].IndiceTotale)));
            }
            else {
                window.setGauge4liv("gauge13", this.state.impattoD);
                window.setGauge4liv("gauge12", this.state.impattoI);
                window.setGauge4liv("gauge11", this.state.impattoR);
                window.setGauge("gauge3", this.getIndice(this.state.indiceTotaleImpatti[0].IndiceTotale));
                window.setPyramidCriticità(this.state.criticità);
            }
        } else {
            if (!isNullOrUndefined(responseData.AssessmentServizio)) {
                //set Profilo di criticità intrinseca
                var criticitàServizio = responseData.AssessmentServizio.Criticità < 0 ? 0 : responseData.AssessmentServizio.Criticità;
                this.setState({ criticità: criticitàServizio }, () => window.setPyramidCriticità(this.state.criticità));
                //set Impatto R-I-D
                let impattoR = responseData.AssessmentServizio.ImpattoR < 0 ? 0.01 : responseData.AssessmentServizio.ImpattoR / 4;

                let impattoI = responseData.AssessmentServizio.ImpattoI < 0 ? 0.01 : responseData.AssessmentServizio.ImpattoI / 4;
                let impattoD = responseData.AssessmentServizio.ImpattoD < 0 ? 0.01 : responseData.AssessmentServizio.ImpattoD / 4;
                this.setState({ impattoD: responseData.AssessmentServizio.ImpattoD }, () => window.setGauge4liv("gauge13", impattoD));
                this.setState({ impattoI: responseData.AssessmentServizio.ImpattoI }, () => window.setGauge4liv("gauge12", impattoI));
                this.setState({ impattoR: responseData.AssessmentServizio.ImpattoR }, () => window.setGauge4liv("gauge11", impattoR));
                //
                this.setState({ rischioAssessment: responseData.AssessmentServizio.RischioComplessivo });
                this.setState({ statoAssessmentId: responseData.StatoAssessmentId });
            }
            else {
                window.setGauge4liv("gauge13", this.state.impattoD);
                window.setGauge4liv("gauge12", this.state.impattoI);
                window.setGauge4liv("gauge11", this.state.impattoR);
                window.setGauge("gauge3", this.getIndice(this.state.indiceTotaleImpatti[0].IndiceTotale));
                window.setPyramidCriticità(this.state.criticità);

            }
        }
        if (!isNullOrUndefined(responseData.getRischioCategorieMinaccia)) {

            //set Rischio cyber complessivo a fronte dell'analisi del rischio
            this.setState({ rischioCategorieMinaccia: this.setRischioCategorieMinaccia(responseData.getRischioCategorieMinaccia) },
                () =>
                    window.setPieRischio(
                        this.state.rischioCategorieMinaccia["Utilizzo improprio e/o errori"],
                        this.state.rischioCategorieMinaccia["Attacchi Logici e/o Fisici"],
                        this.state.rischioCategorieMinaccia["Minacce Ambientali"],
                        this.state.rischioCategorieMinaccia["Minacce Legali"],
                        this.state.rischioAssessment
                    )
            );
            //set Livello di efficacia            
            this.setState({ rischioCategorieMinaccia: responseData.getRischioCategorieMinaccia.RischioComplessivo });
        }
        else {
            window.setPieRischio(
                this.state.rischioCategorieMinaccia["Utilizzo improprio e/o errori"],
                this.state.rischioCategorieMinaccia["Attacchi Logici e/o Fisici"],
                this.state.rischioCategorieMinaccia["Minacce Ambientali"],
                this.state.rischioCategorieMinaccia["Minacce Legali"],
                this.state.rischioAssessment
            );
        }
        if (!isNullOrUndefined(responseData.getLivelloEfficaciaByAssessmentId)) {
            this.setState({ totEfficacia: responseData.getLivelloEfficaciaByAssessmentId.TotaleEfficacia });
            this.setState({ totMitigate: responseData.getLivelloEfficaciaByAssessmentId.TotaleMitigate });
            this.setState({
                livelloDiEfficacia: this.setLivelloDiEfficacia(responseData.getLivelloEfficaciaByAssessmentId.TotaleEfficacia, responseData.getLivelloEfficaciaByAssessmentId.TotaleMitigate)
            });
            window.setGaugeEfficacia("gauge9", this.getLivelloDiEfficacia(responseData.getLivelloEfficaciaByAssessmentId.TotaleEfficacia, responseData.getLivelloEfficaciaByAssessmentId.TotaleMitigate));
        }
        else {
            window.setGaugeEfficacia("gauge9", 0.01);
        }
        //console.log('state: ', this.state);
        //set Stato delle azioni definite nel Piano di trattamento del rischio
        //window.setTest(Azioni completate,Azioni in corso,Azioni bloccate,Azioni ri-pianificate)
        if (!isNullOrUndefined(responseData.getStatoAzioniDiTrattamentoByAssessmentId)) {
            this.setState({ azioniDiTrattamentoList: this.setTotaleAzioniDiTrattamento(responseData.getStatoAzioniDiTrattamentoByAssessmentId) },
                () => window.setAzioni(
                    this.state.azioniDiTrattamentoList[3].totaleAzioni/*Azioni completate */,
                    this.state.azioniDiTrattamentoList[1].totaleAzioni/*Azioni in corso */,
                    this.state.azioniDiTrattamentoList[2].totaleAzioni/*Azioni bloccate */,
                    this.state.azioniDiTrattamentoList[4].totaleAzioni/*Azioni  ri-pianificate */,
                )
            );
        }
        else {
            window.setAzioni(
                this.state.azioniDiTrattamentoList[3].totaleAzioni/*Azioni completate */,
                this.state.azioniDiTrattamentoList[1].totaleAzioni/*Azioni in corso */,
                this.state.azioniDiTrattamentoList[2].totaleAzioni/*Azioni bloccate */,
                this.state.azioniDiTrattamentoList[4].totaleAzioni/*Azioni  ri-pianificate */,
            )
        }
    }

    componentWillUnmount() {
        //TODO
    }

    /**
        * 
        * @param {*} nomePA 
        */
    setNomePA(nomePA) {
        try {
            if (nomePA) {

                this.setState({ nomePA: nomePA });
            } else {
                let user = Util.getUsersFromAccessToken();
                this.setState({ nomePA: user.NomePA });
            }
            sessionStorage.setItem('nomePA', this.state.nomePA);
            //return console.log('nomePA',this.state.nomePA);
        } catch (error) {
            return console.error(error)
        }
    }

    /**
        * 
        * @param {*} listStatoAzioniByAssessmentId 
        * return 
        */
    getTotaleAzioniDiTrattamento(listStatoAzioniByAssessmentId) {
        let list = listStatoAzioniByAssessmentId;
        let tot = 0;
        try {
            for (let index = 0; index < list.length; index++) {
                tot += parseInt(list[index].TotaleAzioni, 10);
            }
            //console.log("getTotaleAzioniDiTrattamento", tot);
            return tot;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    /**
     * 
     * @param {*} listStatoAzioniByAssessmentId 
     */
    setTotaleAzioniDiTrattamento(listStatoAzioniByAssessmentId) {
        let list = listStatoAzioniByAssessmentId;
        let azioniDiTrattamento = this.state.azioniDiTrattamentoList;
        let tot = this.getTotaleAzioniDiTrattamento(listStatoAzioniByAssessmentId);
        let filteredList = listStatoAzioniByAssessmentId.filter(function (x) { return x.Stato !== 4 });
        let tot123 = this.getTotaleAzioniDiTrattamento(filteredList);
        /*function checkAdult(age) {
            return age.Stato < 4;
        }*/

        try {
            if (tot > 0) {
                for (let index = 0; index < list.length; index++) {
                    if (list[index].Stato === 4) {
                        azioniDiTrattamento[list[index].Stato].totaleAzioni = Math.round((parseInt(list[index].TotaleAzioni, 10) / tot123) * 100);
                    } else {
                        azioniDiTrattamento[list[index].Stato].totaleAzioni = Math.round((parseInt(list[index].TotaleAzioni, 10) / tot) * 100);
                    }
                }
            }
            //console.log("setTotaleAzioniDiTrattamento", azioniDiTrattamento);
            return azioniDiTrattamento;
        } catch (error) {
            console.error(error);
            return azioniDiTrattamento;
        }
    }

    /**
     * 
     * @param {*} totaleEfficacia 
     * @param {*} totaleMitigate 
     */
    getLivelloDiEfficacia(totaleEfficacia, totaleMitigate) {
        try {
            if (isNaN(totaleMitigate / totaleEfficacia)) {
                return 0.01;
            }
            return (totaleMitigate / totaleEfficacia);
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    /**
     * 
     * @param {*} totaleEfficacia 
     * @param {*} totaleMitigate 
     */
    setLivelloDiEfficacia(totaleEfficacia, totaleMitigate) {
        try {
            if (isNaN(totaleMitigate / totaleEfficacia)) {
                return "0%"
            }
            return (Math.round((totaleMitigate / totaleEfficacia) * 100)) + "%";
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }


    /**
     * 
     * @param {*} listRischioCategorieMinaccia 
     */
    setRischioCategorieMinaccia(listRischioCategorieMinaccia) {
        let rischioCategorieMinaccia = this.state.rischioCategorieMinaccia;
        try {
            for (let index = 0; index < listRischioCategorieMinaccia.length; index++) {
                const nomeCategoria = listRischioCategorieMinaccia[index].NomeCategoria;
                for (const key in rischioCategorieMinaccia) {
                    if (key.toLowerCase().indexOf(nomeCategoria.toLowerCase() === 0)) {
                        if (!isNullOrUndefined(listRischioCategorieMinaccia[index].RischioComplessivo)) {
                            rischioCategorieMinaccia[nomeCategoria] = listRischioCategorieMinaccia[index].RischioComplessivo;
                        }

                    }
                }
            }
            return rischioCategorieMinaccia;
        } catch (error) {
            console.error(error);
            return this.state.rischioCategorieMinaccia;
        }
    }

    /**
     * 
     * @param {*} indice 
     */
    getIndice(indice) {
        let value = 0.1;

        switch (indice) {
            case 1:
                value = 0.1;
                break;
            case 2:
                value = 0.5;
                break;
            case 3:
                value = 0.9;
                break;
            default: value = 0
                break;
        }
        return value;
    }

    /**
     * 
     */
    getIndiceQualitàAnalisiDelRischio(index) {
        let text = "";
        let testoIndice = "BASSO";
        let coloreIndice = "testoRosso";
        var indice = this.getIndice(index);

        switch (indice) {
            case 0.1:
                testoIndice = "BASSO";
                coloreIndice = "testoRosso";
                text = "Il perimetro dei servizi analizzati non è sufficiente a garantire la veridicità dei risultati ottenuti. Al fine di ottenere dei risultati consistenti è necessario valutare l’intero perimetro dei servizi erogati dalla PA, in particolare i servizi più critici (servizi che trattano dati personali sensibili, giudiziari, rischiosi).";
                break;
            case 0.5:
                //gaugeColor = "#ff9900";
                testoIndice = "MEDIO";
                coloreIndice = "testoArancione";
                text = "Il perimetro dei servizi analizzati fornisce una prima consistente fotografia dello stato di rischio della  Azienda. Tuttavia, al fine di ottenere un’analisi del rischio completa ed efficace, è necessario valutare l’intero perimetro di servizi erogati, in particolare i servizi più critici (servizi che trattano dati personali sensibili, giudiziari, rischiosi).";
                break;
            case 0.9:
                testoIndice = "ALTO";
                //gaugeColor = "#0CEA04";
                coloreIndice = "testoVerde";
                text = " Il perimetro dei servizi analizzati è sufficiente a garantire la veridicità dei risultati ottenuti. Al fine di ottenere un’analisi del rischio completa ed efficace, assicurarsi che l’intero perimetro di servizi erogati dalla Azienda sia valutato, ponendo particolare attenzione a non tralasciare quelli più critici (servizi che trattano dati personali sensibili, giudiziari, rischiosi).";
                break;
            default:
                //
                break;
        }
        return { labelIndex: testoIndice, text: text, className: coloreIndice }
    }

    /**
    * 
    */
    getImpattoRIDcomplessivo() {
        let average = 0
        try {
            let sumImpatti = this.state.impattoR + this.state.impattoI + this.state.impattoD;
            if (sumImpatti > 0) {
                average = Math.round((this.state.impattoR + this.state.impattoI + this.state.impattoD) / 3);
                return isNaN(average) ? 0 : average;
            }
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    /**
     * 
     */
    getAzioniDiTrattamentoText(actionText, length) {
        try {
            return "Azioni " + actionText.substring(0, actionText.length - 1) + "e";

        } catch (error) {
            console.error(error)
            return error.toString();
        }
    }

    /**
     * 
     */
    getSrcImg(value, type) {
        let src = "";
        try {
            switch (type) {
                case "criticità":
                    src = (value > 0) ? "images/001-checked.png" : "images/002-alert.png";
                    break;
                case "impatti":
                    src = (value > 0) ? "images/001-checked.png" : "images/002-alert.png";
                    break;
                case "questionario":
                    src = (value > 3) ? "images/001-checked.png" : "images/002-alert.png";
                    break;

                default://
                    break;
            }
            return src
        } catch (error) {
            console.error(error)
            return "";
        }
    }

    render() {

        return (
            <div id="main">

                <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                    <div className="container cnsContainer">

                        <div className="row">

                            <div className="col-lg-12">
                                <nav className="" aria-label="sei qui:" role="navigation">
                                    <ul className="Breadcrumb">
                                        {(this.props.PA) ?
                                            <Breadcrumb links={LINK_PA} />
                                            :
                                            <Breadcrumb links={LINK} />
                                        }
                                    </ul>
                                </nav>
                                {this.props.PA === true ?
                                    <span className="titoloPagina">Executive summary - Riepilogo risultati analisi del rischio per Azienda - {this.state.nomePA}</span>
                                    :
                                    <span className="titoloPagina">Executive summary - Riepilogo risultati analisi del rischio per Servizio - {this.state.serviceName}</span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 giustifica">
                            {this.props.PA === true && <p>La pagina espone il riepilogo complessivo dei risultati dell’analisi del rischio della Azienda attraverso gli indicatori: 1) Rischio cyber complessivo; 2) indice di qualità dell’analisi del rischio; 3) Profilo di criticità; 4) Impatto RID; 5) Stato delle azioni del Piano di trattamento; 6) Livello di efficacia delle azioni di trattamento.</p>}
                            {this.props.PA !== true && <p>La pagina espone il riepilogo complessivo dei risultati dell’analisi del rischio sul servizio attraverso gli indicatori: 1) Rischio cyber complessivo; 2) stato dell’analisi del rischio; 3) Impatto RID; 4) Profilo di criticità; 5) Stato delle azioni del Piano di trattamento; 6) Livello di efficacia delle azioni di trattamento.</p>}
                            </div>
                        </div>
                        <div className="linea0066cc"></div>

                        {this.props.PA === true ?
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1 className="testoBlu">Rischio cyber complessivo a fronte dell'analisi del rischio
		                            {/* <a data-bs-toggle="collapse" href="#collapse1"> */}
                                        <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png" /></span>
                                    {/* </a> */}
                                    </h1>
                                    <hr />

                                    {/* <div id="collapse1" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                                </div> */}

                                </div> <div className="col-lg-2"> </div>

                                <div className="col-lg-6">
                                    <div id="pieRischio" style={{ minWidth: "310px", height: "300px", maxWidth: "600px", margin: "0 auto" }}></div>

                                </div>

                                <div className="col-lg-4">

                                    <br /><br />
                                    Legenda:<br />
                                    <i className="fa fa-square" style={{color: "#d5d5d5"}}></i> Rischio nullo<br />
                                    <i className="testoVerde fa fa-square "></i> Rischio basso<br />
                                    <i className="testoArancio fa fa-square "></i> Rischio medio<br />
                                    <i className="testoRosso fa fa-square"></i> Rischio alto<br />
                                    <i className="testoNero fa fa-square"></i> Rischio critico
	                        </div>

                            </div>

                            :
                            // Inizio service
                            <div className="row">
                                <div className="col-lg-8">
                                    <h1 className="testoBlu">Rischio cyber complessivo a fronte dell'analisi del rischio
		                            {/* <a data-bs-toggle="collapse" href="#collapse1"> */}
                                        <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                        {/* </a> */}
                                    </h1>
                                    <hr />


                                    {/* <div id="collapse1" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-8">

                                            <div id="pieRischio" style={{ minWidth: "310px", height: "300px", maxWidth: "600px", margin: "0 auto" }}></div>

                                        </div>

                                        <div className="col-lg-4">

                                            <br /><br />
                                            Legenda:<br />
                                            <i className="fa fa-square" style={{color: "#d5d5d5"}}></i> Rischio nullo<br />
                                            <i className="testoVerde fa fa-square "></i> Rischio basso<br />
                                            <i className="testoArancio fa fa-square "></i> Rischio medio<br />
                                            <i className="testoRosso fa fa-square"></i> Rischio alto<br />
                                            <i className="testoNero fa fa-square"></i> Rischio critico
                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-4">	  <h1 className="testoBlu">Stato dell'analisi del rischio
                                {/* <a data-bs-toggle="collapse" href="#collapse8"> */}
                                <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                    {/* </a> */}
                                </h1><hr />
                                    {/* <div id="collapse8" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                                    </div> */}
                                    <br /><br />
                                    <div className="row">
                                        <div className="spazio30"></div>
                                        <div className="col-lg-4" style={{ textAlign: "center" }}>
                                            <img src={this.getSrcImg(this.state.criticità, "criticità".toLowerCase())} width="60" height="59" alt="" /> <span className="font14"><strong>Analisi del contesto </strong></span>
                                        </div>
                                        <div className="col-lg-4" style={{ textAlign: "center" }}>
                                            <img src={this.getSrcImg(Math.min(this.state.impattoR, this.state.impattoI, this.state.impattoD), "impatti".toLowerCase())} width="60" height="59" alt="" /> <span className="font14"><strong>Valutazione degli impatti </strong></span>
                                        </div>
                                        <div className="col-lg-4" style={{ textAlign: "center" }}>
                                            <img src={this.getSrcImg(this.state.statoAssessmentId, "questionario".toLowerCase())} width="60" height="59" alt="" />  <span className="font14"><strong>Questionario analisi del rischio</strong></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    {/* FINE service */}
                    <div className="spazio30"></div>
                    {/* inizio service */}

                    {!this.props.PA === true ?
                        <div className="row">
                             <div className="col-lg-4">
                                <h1 className="testoBlu">Profilo di criticità: <span className={Util.getRisk(this.state.criticità, LIVELLO).className}>{/*ALTO*/Util.getRisk(this.state.criticità, LIVELLO).name.toUpperCase()}</span>                                		
                                {/* <a data-bs-toggle="collapse" href="#collapse3"> */}
                                    <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                    {/* </a>  */}
                                    </h1>
                                <hr />
                                {/* <div id="collapse3" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                                </div> */}
                                <div className="col-lg-12">
                                    <div className="spazio20"></div>

                                    <div id="piramide" style={{ minWidth: "210px", maxWidth: "210px", height: "150px", margin: "0 auto" }}></div><br />

                                </div>
                            </div>

                            <div className="col-lg-8">
                                <h1 className="testoBlu">Impatto R-I-D		
                                {/* <a data-bs-toggle="collapse" href="#collapse4"> */}
                                    <span className=" testoBlu paRight">
                                    <img src={infoIcon} alt="images/info.png"/></span>
                                    {/* </a>  */}
                                    </h1>		<hr />		
                                    {/* <div id="collapse4" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                                </div> */}
                                <div className="spazio30"></div>

                                <div className="row">

                                    <div className="col-lg-4">

                                        <div className="gauge4liv gauge--liveupdate2" id="gauge11">
                                            <div className="gauge__container4liv">
                                                <div className="gauge__marker4liv"></div>
                                                <div className="gauge__markerbis4liv"></div>
                                                <div className="gauge__markertris4liv"></div>
                                                <div className="gauge__background4liv"></div>
                                                <div className="gauge__center4liv"></div>
                                                <div className="gauge__data4liv"></div>
                                                <div className="gauge__data4livB"></div>
                                                <div className="gauge__data4livC"></div>
                                                <div className="gauge__data4livD"></div>
                                                <div className="gauge__needle4liv"></div>
                                            </div>
                                            <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                <span className="gauge__label--low4liv">BASSO</span>
                                                <span className="gauge__label--spacer4liv">
                                                    <span className={Util.getRisk(this.state.impattoR, LIVELLO).className}>{Util.getRisk(this.state.impattoR, LIVELLO).name.toUpperCase()}</span>
                                                    <br />RISERVATEZZA</span>
                                                <span className="gauge__label--high4liv">CRITICO</span>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-4">
                                        <div className="gauge4liv gauge--liveupdate2" id="gauge12">
                                            <div className="gauge__container4liv">
                                                <div className="gauge__marker4liv"></div>
                                                <div className="gauge__markerbis4liv"></div>
                                                <div className="gauge__markertris4liv"></div>
                                                <div className="gauge__background4liv"></div>
                                                <div className="gauge__center4liv"></div>
                                                <div className="gauge__data4liv"></div>
                                                <div className="gauge__data4livB"></div>
                                                <div className="gauge__data4livC"></div>
                                                <div className="gauge__data4livD"></div>
                                                <div className="gauge__needle4liv"></div>
                                            </div>
                                            <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                <span className="gauge__label--low4liv">BASSO</span>
                                                <span className="gauge__label--spacer4liv">
                                                    <span className={Util.getRisk(this.state.impattoI, LIVELLO).className}>{Util.getRisk(this.state.impattoI, LIVELLO).name.toUpperCase()}</span>
                                                    <br />INTEGRITA'</span>
                                                <span className="gauge__label--high4liv">CRITICO</span>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="gauge4liv gauge--liveupdate2" id="gauge13">
                                            <div className="gauge__container4liv">
                                                <div className="gauge__marker4liv"></div>
                                                <div className="gauge__markerbis4liv"></div>
                                                <div className="gauge__markertris4liv"></div>
                                                <div className="gauge__background4liv"></div>
                                                <div className="gauge__center4liv"></div>
                                                <div className="gauge__data4liv"></div>
                                                <div className="gauge__data4livB"></div>
                                                <div className="gauge__data4livC"></div>
                                                <div className="gauge__data4livD"></div>
                                                <div className="gauge__needle4liv"></div>
                                            </div>
                                            <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                <span className="gauge__label--low4liv">BASSO</span>
                                                <span className="gauge__label--spacer4liv">
                                                    <span className={Util.getRisk(this.state.impattoD, LIVELLO).className}>{Util.getRisk(this.state.impattoD, LIVELLO).name.toUpperCase()}</span>
                                                    <br />DISPONIBILITA'</span>
                                                <span className="gauge__label--high4liv">CRITICO</span>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 text-center"> 	<div className="spazio30"></div>
                                        Impatto R-I-D complessivo: <span className={Util.getRisk(this.getImpattoRIDcomplessivo(), LIVELLO).className}>
                                            {Util.getRisk(this.getImpattoRIDcomplessivo(), LIVELLO).name.toUpperCase()}
                                        </span>
                                    </div>

                                </div>
                            </div>


                           

                        </div>/* fine service */
                        :
                        /* Inizio Azienda */
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h1 className="testoBlu">Indice di completezza dell'analisi del rischio	
                                    {/* <a data-bs-toggle="collapse" href="#collapse2"> */}
                                        <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                        {/* </a>  */}
                                        </h1>		<hr />
                                    {/* <div id="collapse2" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                            </div> */}
                                </div>
                                <div className="col-lg-4">
                                    <div className="spazio20"></div>

                                    <div className="gauge gauge--liveupdate" id="gauge3">
                                        <div className="gauge__container">
                                            <div className="gauge__marker"></div>
                                            <div className="gauge__markerbis"></div>
                                            <div className="gauge__background"></div>
                                            <div className="gauge__center"></div>
                                            <div className="gauge__data"></div>
                                            <div className="gauge__dataB"></div>
                                            <div className="gauge__dataC"></div>
                                            <div className="gauge__needle"></div>
                                        </div>
                                        <div className="gauge__labels mdl-typography__headline">
                                            <span className="gauge__label--low">BASSO</span>
                                            <span className={"gauge__label--spacer " + this.getIndiceQualitàAnalisiDelRischio(this.state.indiceTotaleImpatti[0].IndiceTotale).className}>{/*BASSO*/this.getIndiceQualitàAnalisiDelRischio(this.state.indiceTotaleImpatti[0].IndiceTotale).labelIndex}</span>
                                            <span className="gauge__label--high">ALTO</span>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-lg-8">
                                    <div className="spazio30"></div>

                                    <p>{this.getIndiceQualitàAnalisiDelRischio(this.state.indiceTotaleImpatti[0].IndiceTotale).text}</p>
                                </div>
                            </div>


                            <div className="spazio30"></div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <h1 className="testoBlu">Profilo di criticità: <span className={Util.getRisk(this.state.criticità, LIVELLO).className}>{/*ALTO*/Util.getRisk(this.state.criticità, LIVELLO).name.toUpperCase()}</span>
                                        {/* <a data-bs-toggle="collapse" href="#collapse3"> */}
                                            <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                            {/* </a>  */}
                                            </h1>		<hr />		
                                            {/* <div id="collapse3" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                                    </div> */}
                                    <div className="col-lg-12">
                                        <div className="spazio20"></div>

                                        <div id="piramide" style={{ minWidth: "210px", maxWidth: "210px", height: "150px", margin: "0 auto" }}></div><br />
                                        <span hidden={true} className="font14"><strong>80%</strong> dei servizi trattano dati personali sensibili, giudiziari, rischiosi<br />
                                            <strong>10%</strong> dei servizi trattano dati personali comuni o confidenziali<br />
                                            <strong>10%</strong> dei servizi trattano dati aggregati o non soggettio a vincoli normativi</span>
                                    </div>
                                </div>


                                <div className="col-lg-8">
                                    <h1 className="testoBlu">Impatto R-I-D		
                                    {/* <a data-bs-toggle="collapse" href="#collapse4"> */}
                                        <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                        {/* </a>  */}
                                        </h1>		<hr />		
                                        {/* <div id="collapse4" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                                        </div> */}
                                    <div className="spazio30"></div>

                                    <div className="row">

                                        <div className="col-lg-4">

                                            <div className="gauge4liv gauge--liveupdate2" id="gauge11">
                                                <div className="gauge__container4liv">
                                                    <div className="gauge__marker4liv"></div>
                                                    <div className="gauge__markerbis4liv"></div>
                                                    <div className="gauge__markertris4liv"></div>
                                                    <div className="gauge__background4liv"></div>
                                                    <div className="gauge__center4liv"></div>
                                                    <div className="gauge__data4liv"></div>
                                                    <div className="gauge__data4livB"></div>
                                                    <div className="gauge__data4livC"></div>
                                                    <div className="gauge__data4livD"></div>
                                                    <div className="gauge__needle4liv"></div>
                                                </div>
                                                <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                    <span className="gauge__label--low4liv">BASSO</span>
                                                    <span className="gauge__label--spacer4liv">
                                                        <span className={Util.getRisk(this.state.impattoR, LIVELLO).className}>{/*BASSO*/Util.getRisk(this.state.impattoR, LIVELLO).name.toUpperCase()}</span>
                                                        <br />
                                                        RISERVATEZZA
                                                </span>
                                                    <span className="gauge__label--high4liv">CRITICO</span>

                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-lg-4">
                                            <div className="gauge4liv gauge--liveupdate2" id="gauge12">
                                                <div className="gauge__container4liv">
                                                    <div className="gauge__marker4liv"></div>
                                                    <div className="gauge__markerbis4liv"></div>
                                                    <div className="gauge__markertris4liv"></div>
                                                    <div className="gauge__background4liv"></div>
                                                    <div className="gauge__center4liv"></div>
                                                    <div className="gauge__data4liv"></div>
                                                    <div className="gauge__data4livB"></div>
                                                    <div className="gauge__data4livC"></div>
                                                    <div className="gauge__data4livD"></div>
                                                    <div className="gauge__needle4liv"></div>
                                                </div>
                                                <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                    <span className="gauge__label--low4liv">BASSO</span>
                                                    <span className="gauge__label--spacer4liv">
                                                        <span className={Util.getRisk(this.state.impattoI, LIVELLO).className}>{Util.getRisk(this.state.impattoI, LIVELLO).name.toUpperCase()}</span>
                                                        <br />INTEGRITA'</span>
                                                    <span className="gauge__label--high4liv">CRITICO</span>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="gauge4liv gauge--liveupdate2" id="gauge13">
                                                <div className="gauge__container4liv">
                                                    <div className="gauge__marker4liv"></div>
                                                    <div className="gauge__markerbis4liv"></div>
                                                    <div className="gauge__markertris4liv"></div>
                                                    <div className="gauge__background4liv"></div>
                                                    <div className="gauge__center4liv"></div>
                                                    <div className="gauge__data4liv"></div>
                                                    <div className="gauge__data4livB"></div>
                                                    <div className="gauge__data4livC"></div>
                                                    <div className="gauge__data4livD"></div>
                                                    <div className="gauge__needle4liv"></div>
                                                </div>
                                                <div className="gauge__labels4liv mdl-typography__headline4liv">
                                                    <span className="gauge__label--low4liv">BASSO</span>
                                                    <span className="gauge__label--spacer4liv">
                                                        <span className={Util.getRisk(this.state.impattoD, LIVELLO).className}>{Util.getRisk(this.state.impattoD, LIVELLO).name.toUpperCase()}</span>
                                                        <br />DISPONIBILITA'</span>
                                                    <span className="gauge__label--high4liv">CRITICO</span>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-12 text-center"> 	<div className="spazio30"></div>
                                            Impatto R-I-D complessivo: 	<span className={Util.getRisk(this.getImpattoRIDcomplessivo(), LIVELLO).className}>
                                                {Util.getRisk(this.getImpattoRIDcomplessivo(), LIVELLO).name.toUpperCase()}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        /* finePA */
                    }
                    <div className="spazio30"></div>

                    <div className="row">
                        <div className="col-lg-7">
                            <h1 className="testoBlu">Stato delle azioni definite nel Piano di tratt. del rischio		
                            {/* <a data-bs-toggle="collapse" href="#collapse5"> */}
                                <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                {/* </a>  */}
                                </h1>		<hr />		
                                        {/* <div id="collapse5" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                            </div> */}
                            <div className="row">

                                <div className="col-lg-4 text-center"> <div className="spazio20"></div>
                                    <canvas id="myChart1" width="150" height="150"></canvas><br />
                                    Azioni
                                </div>

                                <div className="col-lg-4"> 
                                <br /><br />
                                    Legenda:<br />                                   
                                    <i className="testoVerde fa fa-square "></i> Azioni Completate <br />
                                    <i className="testoBlu fa fa-square"></i> Azioni In Corso <br />
                                    <i className="testoRosso fa fa-square"></i> Azioni Sospese
                                </div>


                                <div className="col-lg-4 text-center"> <div className="spazio20"></div>
                                    <canvas id="myChart4" width="150" height="150"></canvas><br />
                                    {this.getAzioniDiTrattamentoText(this.state.azioniDiTrattamentoList[4].stato)}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5">
                            <h1 className="testoBlu">Livello di efficacia del piano di tratt.
                            {/* <a data-bs-toggle="collapse" href="#collapse6"> */}
                                <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png"/></span>
                                {/* </a>  */}
                                </h1>		<hr />
                            {/* <div id="collapse6" className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                            </div> */}
                            <div className="spazio20"></div><div className="col-lg-12">
                                <div className="gaugeEfficacia gauge--liveupdateEfficacia" id="gauge9">
                                    <div className="gauge__containerEfficacia">
                                        <div className="gauge__markerEfficacia"></div>
                                        <div className="gauge__markerbisEfficacia"></div>
                                        <div className="gauge__backgroundEfficacia"></div>
                                        <div className="gauge__centerEfficacia"></div>
                                        <div className="gauge__dataEfficacia"></div>
                                        <div className="gauge__dataBEfficacia"></div>
                                        <div className="gauge__dataCEfficacia"></div>
                                        <div className="gauge__needleEfficacia"></div>
                                    </div>
                                    <div className="gauge__labels mdl-typography__headline">
                                        <span className="gauge__label--low">BASSO</span>
                                        <span className="gauge__label--spacer">{this.state.livelloDiEfficacia}</span>
                                        <span className="gauge__label--high">ALTO</span>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div >
        )
    }
}