import React, { Component } from 'react';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { ModalDialog } from '../Common/ModalDialog';
import { UserComponent } from './UserComponent';
import { User } from '../Common/Models/UserModel';

let ROLES = [];
ROLES[2] = "Application Owner";
ROLES[3] = "Analysis Manager";
ROLES[1] = "Amministratore";

/**
 * 
 * @param {*} fiscalCode 
 */
function isValidateFiscalCode(fiscalCode) {
    //var TAX_CODE_LENGTH = 16;
    var REGEXP_STRING_FOR_LASTNAME = "[A-Za-z]{3}";
    var REGEXP_STRING_FOR_FIRSTNAME = "[A-Za-z]{3}";
    var REGEXP_STRING_FOR_BIRTHDATE_YEAR = "[0-9LlMmNnPpQqRrSsTtUuVv]{2}";
    var REGEXP_STRING_FOR_BIRTHDATE_MONTH = "[AaBbCcDdEeHhLlMmPpRrSsTt]{1}";
    var REGEXP_STRING_FOR_BIRTHDATE_DAY_GENDER_PART_1 = "[0-7LlMmNnPpQqRrSsTtUuVv]{1}";
    var REGEXP_STRING_FOR_BIRTHDATE_DAY_GENDER_PART_2 = "[0-9LlMmNnPpQqRrSsTtUuVv]{1}";
    var REGEXP_STRING_FOR_BIRTHTOWN_PART_1 = "[A-Za-z]{1}";
    var REGEXP_STRING_FOR_BIRTHTOWN_PART_2 = "[0-9LlMmNnPpQqRrSsTtUuVv]{3}";
    var REGEXP_STRING_FOR_CIN = "[A-Za-z]{1}";
    var REGEXP = new RegExp("^" + REGEXP_STRING_FOR_LASTNAME + REGEXP_STRING_FOR_FIRSTNAME + REGEXP_STRING_FOR_BIRTHDATE_YEAR + REGEXP_STRING_FOR_BIRTHDATE_MONTH + REGEXP_STRING_FOR_BIRTHDATE_DAY_GENDER_PART_1 + REGEXP_STRING_FOR_BIRTHDATE_DAY_GENDER_PART_2 + REGEXP_STRING_FOR_BIRTHTOWN_PART_1 + REGEXP_STRING_FOR_BIRTHTOWN_PART_2 + REGEXP_STRING_FOR_CIN + "$");

    if (fiscalCode && fiscalCode.length === 16 && REGEXP.test(fiscalCode)) {
        return true;
    }
    else return false;
}

/**
 * 
 * @param {*} listPA 
 * @param {*} fiscalCode 
 * @param {*} name 
 * @param {*} surname  
 */
function isEmpty(fiscalCode, name, surname) {
    // true means invalid, so our conditions got reversed
    return {
        fiscalCode: fiscalCode.length === 0,
        name: name.length === 0,
        surname: surname.length === 0,
    };
}

function isValidateRolePA(listPA) {
    for (let index = 0; index < listPA.length; index++) {
        const pa = listPA[index];
        if (pa.visible) {
            let j = pa.Ruoli.findIndex(r => r === 2 || r === 3 || r === 5 || r === 1)
            if (j === -1)
                return false
        }
    }
    return true;
}

/**
 * 
 * @param {*} fiscalCode 
 * @param {*} name 
 * @param {*} surname 
 * @param {*} listPA 
 */
function validate(fiscalCode, name, surname, listPA) {

    return {
        Ruoli: isValidateRolePA(listPA),
        Nome: name.length !== 0,
        Cognome: surname.length !== 0,
        CodiceFiscale: isValidateFiscalCode(fiscalCode) && fiscalCode.length !== 0,
    };
    /*
    
    return {
        codiceIPA: isValidateCodiceIPA(codiceIPA),
        fiscalCode: isValidateFiscalCode(fiscalCode),
        email: isValidateEmail(email) && email.localeCompare(confirmationEmail),
        phoneNumber: phoneNumber.length === 0 || isValidatePhonenumber(phoneNumber),
    };
       */
}

function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

// component that renders a form for isert or update user
export class GestioneUtente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: new User((this.props.mode.localeCompare("update") === 0) ? this.getUserCode() : ""),
            errors: [],
            messageError: "",
            messageWarning: "",
            codiceIPA: "",
            namePA: "",
            saveBtnText: (this.props.mode.localeCompare("update") === 0) ? "SALVA MODIFICHE" : "CREA UTENTE",
            mode: this.props.mode,
            edited: false
        };
        //GET
        this.getIndexByRole = this.getIndexByRole.bind(this);
        this.getPAByPatternNome = this.getPAByPatternNome.bind(this);
        this.getRolePA = this.getRolePA.bind(this);
        //VALIDATE
        this.canBeSubmitted = this.canBeSubmitted.bind(this);
        //REMOVE
        this.removeRowRolePA = this.removeRowRolePA.bind(this);
        //SAVE
        this.saveChanges = this.saveChanges.bind(this);
        //UPDATE
        this.updateUserInfo = this.updateUserInfo.bind(this);
    }

    componentDidMount() {       

        if (this.props.mode.localeCompare("update") === 0) {
            // submit form data to api
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.getUserById,
                    type: 'POST',
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: JSON.stringify({ CodiceFiscale: this.state.user.CodiceFiscale }),
                    //data:"{ \"CodiceFiscale\": \"BRMVTR80H14D600W\"}",
                    cache: false,
                    success: function (response) {
                        if (response.data) {
                            let user = Object.assign({}, this.state.user);
                            user.CodiceFiscale = response.data.CodiceFiscale;
                            user.Nome = response.data.Nome;
                            user.Cognome = response.data.Cognome;
                            user.PA = this.getRolePA(response.data.PA);
                            user.isAdmin = this.isAdminUser(response.data.PA);
                            console.log("listPA", response.data.PA);
                            this.setState({ user: user }, () => console.log("user", this.state.user));
                        }
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                                $('#btnError').get(0).click();
                            }
                        }
                        else {

                            this.setState({ messageError: messageError + "." });
                            $('#btnError').get(0).click();
                        }
                    }.bind(this),
                });
        }

    }

    componentWillMount() { /*TODO */ }

    /**
     * 
     */
    getUserCode() {
        try {
            return this.props.location.state.userId;
        } catch (error) {
            console.error(error)
            return error.toString();
        }
    }

    /**
     * 
     * @param {*} listPA 
     */
    isAdminUser(listPA) {
        //let index = listPA.findIndex(p => p.CodiceIPA.toLowerCase().localeCompare("agid") === 0);
        let index = listPA[0];
        if (index > -1) {
            let j = listPA[index].Ruoli.findIndex(r => r === 1);
            return (j > -1) ? true : false;
        }
    }

    redirect() {
        let path = "/UtentiElenco";
        try {
            if (path !== undefined) {
                if (path.indexOf('/') === 0)
                    history.push(path);
                else if (path.indexOf('/') === -1) {
                    history.push('/' + path);
                }
            }
        } catch (error) {
            return console.error(error)
        }
    }

    /**
     * @param role 
     * @returns a index of array where 'role' is equal to prop (for example prop == 'Service' => index=2)
     */
    getIndexByRole(role) {
        try {
            return ROLES.findIndex(r => r && r.indexOf(role) === 0);

        } catch (error) {
            console.error(error);
            return -1;
        }
    }

    /**
     * 
     * @param {*} newValue 
     * @param {*} info 
     */
    updateUserInfo(newValue, info) {
        let user = Object.assign({}, this.state.user);
        switch (info) {
            case 'code':
                user.CodiceFiscale = newValue;
                break;
            case 'name':
                user.Nome = newValue;
                break;
            case 'surname':
                user.Cognome = newValue;
                break;
            default:
                break;
        }
        this.setState({ user: user }
            //, () => console.log("user", this.state.user)
        );
        this.setState({ edited: true });
    }

    /**
     * 
     * @param {*} isChecked 
     */
    updateAdminUser(isChecked) {
        try {
            let user = Object.assign({}, this.state.user);
            user.isAdmin = isChecked;
            let listPA = user.PA;
            let index = listPA[0];
            if (isChecked) {               
                listPA[0].Ruoli.push(1);               
            } else {
                let j = listPA[0].Ruoli.findIndex(r => r === 1);
                listPA[index].Ruoli.splice(j, 1)
            }
            this.setState({ user: user });
            this.setState({ edited: true });
        } catch (error) {
            return console.error(error);
        }
    }

    

    /**
    * rimovi la riga rowId-esima alla tabella dei Ruoli PA
    */
    removeRowRolePA(codiceIPA) {
        let user = Object.assign({}, this.state.user);
        var listPA = user.PA;
        let rowId = listPA.findIndex(p => p.CodiceIPA.localeCompare(codiceIPA) === 0);
        if (rowId > -1) {
            if (codiceIPA.toLowerCase().localeCompare("agid") === 0) {
                let j = listPA[rowId].Ruoli.findIndex(r => r === 1);
                if (j > -1) {
                    listPA[rowId].Ruoli = [];
                    listPA[rowId].Ruoli.push(1);
                    listPA[rowId].visible = false;
                } else {
                    listPA.splice(rowId, 1);
                }
            } else {
                listPA.splice(rowId, 1);
            }
        }
        console.log('rimovi', user)
        this.setState({ user: user });
        this.setState({ edited: true });
    }

    /**
      * @param {*} fiscalCode 
      * @param {*} name 
      * @param {*} surname 
      * @param {*} listPA
      */
    canBeSubmitted(fiscalCode, name, surname, listPA) {
        const errors = validate(fiscalCode, name, surname, listPA);
        //alert(JSON.stringify(errors));
        const isDisabled = Object.keys(errors).every(x => errors[x]);
        //const isDisabled = Object.keys(errors).some(x => errors[x]);
        return isDisabled;
    }

    /**
     * 
     */
    getPAByPatternNome() {
        var inputValue = document.getElementById("myInput").value;
        this.setState({ namePA: inputValue });
        //this.serverRequest =
        $.ajax({
            url: API_CONFIG.paByPatternName,
            type: 'POST',
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            //data: JSON.stringify({ Pattern: inputValue, Limit: 20 }),
            data: "{ \"Pattern\": \"" + inputValue + "\", \"Limit\": 20}",
            cache: false,
            success: function (data) {
                //debugger
                this.setState({ users: data.data.getPAByPatternNome.data });
                this.autocomplete(data.data.getPAByPatternNome.data, this);
            }.bind(this),

            error: function (xhr, resp, text) {
                // show error to console
                console.error(xhr, resp, text);
                alert(xhr.statusText + ' : ' + xhr.status);
            }
        });

    }

    

    /**
     * 
     * @param {*} event 
     * @param {*} codiceIPA 
     * @param {*} indexOfRole 
     */
    updateRolePA(event, codiceIPA, indexOfRole) {
        let isChecked = event.target.checked;
        let user = Object.assign({}, this.state.user);
        let listPA = user.PA;
        
       
        if (isChecked) {
                listPA[0].Ruoli.push(indexOfRole);
        } else
        {
            let findIndex = listPA[0].Ruoli.findIndex(r => r === indexOfRole);
            if (findIndex !== -1) {
                listPA[0].Ruoli.splice(findIndex, 1);
            }
        }
        this.setState({ user: user } );
        this.setState({ edited: true });        
    }

    /**
     * 
     * @param {*} listPA 
     */
    getRolePA(listPA) {
        let rolePAList = [];
        try {
            if (listPA && listPA.length > 0)
                for (let index = 0; index < listPA.length; index++) {
                    let visible = true
                    let findIndex = listPA[index].Ruoli.findIndex(r => r === 1);
                    rolePAList.push({
                        CodiceIPA: listPA[index].CodiceIPA,
                        Nome: listPA[index].Nome,
                        Ruoli: listPA[index].Ruoli,
                        visible: visible
                    });
            }
            else
            {
                let decoded = this.props.auth.getAccessToken();
                let user = decoded.user;
               
                rolePAList.push({
                    CodiceIPA: user.CodiceIPA,
                    Nome: user.NomePA,
                    Ruoli: [],
                    visible: true
                });
            }
            return rolePAList;
        } catch (error) {
            console.error(error);
            return rolePAList;
        }
    }

    /**
     * 
     * @param {*} listRoles lista dei ruoli
     * @param {*} role ruolo
     * @returns true if the role is contained in the list of roles otherwise false
     */
    isRolePA(listRoles, role) {
        try {
            if (listRoles) {
                let index = listRoles.findIndex(r => r === role);
                return (index === -1) ? false : true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error)
            return;
        }
    }

    saveChanges() {
        //var nomePA = document.getElementById('myInput').value; var codiceIPA = document.getElementById('myInput').name;
        const areValidate = validate(
            this.state.user.CodiceFiscale,
            this.state.user.Nome,
            this.state.user.Cognome,
            this.state.user.PA
        );
        let errors = Object.keys(areValidate).filter(function (k) {
            if (!areValidate[k]) {
                return k + ' , ';
            }
        });

        const canBeSubmitted = this.canBeSubmitted(this.state.user.CodiceFiscale, this.state.user.Nome, this.state.user.Cognome, this.state.user.PA);
        if (canBeSubmitted) {
            this.setState({ errors: [] });
            let utenteCompositeUrl = this.props.mode.localeCompare("update") === 0 ? API_CONFIG.updateUtente : API_CONFIG.insertUtente;
            console.log("save", JSON.stringify(this.state.user));
            $.ajax({
                url: utenteCompositeUrl,
                type: "POST",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                data: JSON.stringify(this.state.user),
                cache: false,
                beforeSend: function () {
                    $('#loader').show();
                    $('#modal').show();
                },
                complete: function () {
                    $('#loader').hide();
                    $('#modal').hide();
                },
                success: function (response) {
                    if (response.status.localeCompare('success') === 0) {
                        if (this.props.mode.localeCompare("update") === 0) {
                            this.setState({ messageSuccess: "L'aggiornamento dell'utente effettuato con successo!" }, () => $('#btnSuccessAction').get(0).click());
                        }
                        else this.setState({ messageSuccess: "Registrazione del nuovo utente effettuata con successo!" }, () => $('#btnSuccessAction').get(0).click());

                        //$('#btnSuccessAction').get(0).click();
                        this.setState({ edited: false });
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr, resp, text)
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                            $('#btnError').get(0).click();
                        }
                    }

                    else if (xhr.responseText.indexOf("User exist") > -1 && xhr.status === 500) {
                        console.error(xhr, resp, text);
                        this.setState({ messageWarning: "E' già presente un utente con il codice specificato." }
                            , () => $('#btnWarning').get(0).click());
                    }
                }.bind(this),
            });
        } else {
            //alert(errors.toString());
            let messageWarning = errors.length > 1 ?
                "I campi " + errors.toString() + " non sono validi" :
                "Il campo " + errors.toString() + " non è valido";
            this.setState({ messageWarning: messageWarning }, () => $('#btnWarning').get(0).click());
        }
    }

    render() {
        const errors = isEmpty(
            this.state.user.CodiceFiscale,
            this.state.user.Nome,
            this.state.user.Cognome
        );
        const isDisabled = !(!Object.keys(errors).some(x => errors[x]) && (this.state.edited || this.props.mode != "update"));
        let modalSuccess = <ModalDialog idTarget={'successAction'} idBtn={'btnSuccessAction'} handleOK={this.redirect} textModal={this.state.messageSuccess} />;
        let modalWarning = <ModalDialog idTarget={'warningNoAction'} idBtn={'btnWarning'} textModal={this.state.messageWarning} />;
        let modalWarningAction = <ModalDialog idTarget={'warningAction'} idBtn={'btnCheck'} handle={this.save} textModal={this.state.messageWarning} />;
        let modalError = <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />;

        let rolePARow = [];
        const elementPA = this.state.user.PA[0];

       
        let decoded = this.props.auth.getAccessToken();
        let user = decoded.user;
        var isUserLogged = decoded.user.CodiceFiscale == this.state.user.CodiceFiscale;

        if(elementPA != undefined)
        {
            let row = <div className="row" key={"rolePARow" + 0}>   
                <div className="col-lg-3 card-link">
                            Ruoli
                        </div>               
                <div className="col-lg-3">
                    {/*is Service Owner */}
                    <input type="checkbox" style={{ marginLeft: "45%" }}
                        checked={this.isRolePA(elementPA.Ruoli, 2)}
                        onChange={(e) => this.updateRolePA(e, elementPA.CodiceIPA, 2)}
                    />
                </div>
                <div className="col-lg-3">
                    {/*is Risk Owner */}
                    <input type="checkbox" style={{ marginLeft: "45%" }}
                        checked={this.isRolePA(elementPA.Ruoli, 3)}
                        onChange={(e) => this.updateRolePA(e, elementPA.CodiceIPA, 3)}
                    />
                </div>
                <div className="col-lg-3">
                    {/*Amministratore */}
                    <input type="checkbox" style={{ marginLeft: "45%" }}
                        checked={this.isRolePA(elementPA.Ruoli, 1)}
                        disabled={isUserLogged}
                        onChange={(e) => this.updateRolePA(e, elementPA.CodiceIPA, 1)}
                    />
                </div>

            </div>
            rolePARow.push(row);
        }


        const LINK = [
            { nome: "Configurazione e inizializzazione", url: "/GestioneUtenti" },
            { nome: "Elenco Utenti", url: "/UtentiElenco" },
            { nome: (this.props.mode === "update") ? "Modifica Utente" : "Nuovo Utente", url: (this.props.mode === "update") ? "/ModificaUtente" : "/NuovoUtente" }
        ];

        var testo = (this.props.mode === "update") ? "Modifica Utente" : "Nuovo Utente";

        var titolo = this.props.mode === "update" ? "Modifica utente" : "Creazione nuovo utente";

        var descrizione = this.props.mode === "update" ? "Permette la modifica dei dati relativi alla utenza selezionata. Compilare i campi obbligatori e procedere con il salvataggio utente attraverso la pressione del tasto \"Salva Modifiche\". E' possibile procedere con l'accreditamento alle Pubbliche Amministrazioni attraverso il pulsante \"Aggiungi PA\" oppure selezionando l'opzione di cancellazione associazione Utente<->PA per gli accreditamenti esistenti."
            : "Permette l'inserimento dei dati relativi alla nuova utenza da censire nella piattaforma. Compilare i campi obbligatori e procedere con il censimento utente attraverso la pressione del tasto \"Crea Utente\". E' possibile procedere con l'accreditamento alle Pubbliche Amministrazioni attraverso il pulsante \"Aggiungi PA\" e definirne contestualmente i rispettivi ruoli operativi.";

        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">
                <div className="container cnsContainer">

                    <div className="row">
                        <div className="col-lg-12">

                        <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/UtentiElenco')}>Elenco Utenti</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="#">{testo}</a></li>
                                </ul>
                            </nav>
                            <span className="titoloPagina">{titolo}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 giustifica">
                            <p>{descrizione}</p>
                        </div>

                    </div>

                    <div className="linea0066cc"></div>
                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-link">
                                &nbsp;Informazioni generali
                            </div>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <UserComponent
                            isUpdate={(this.props.mode.localeCompare("update") === 0) ? true : false}
                            name={this.state.user.Nome}
                            surname={this.state.user.Cognome}
                            userCode={this.state.user.CodiceFiscale}
                            isAdmin={this.state.user.isAdmin}
                            updateUserInfo={this.updateUserInfo}
                            updateAdminUser={this.updateAdminUser}
                        />
                        <br />
                        <hr />
                        <br />
                        <div className="row" hidden={rolePARow.length === 0}>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-3 text-center">{ROLES[2]}</div>
                            <div className="col-lg-3 text-center">Analysis Manager</div>
                            <div className="col-lg-3 text-center">{ROLES[1]}</div>
                        </div>
                        {rolePARow}

                        <br />
                        {modalWarning}
                        {modalError}
                        {modalWarningAction}
                        {modalSuccess}
                    </div>
                </div>
                <div className="row spazio20"><div className="col-lg-5 "> </div>
                    <div className="col-lg-2 ">
                        <button onClick={() => this.saveChanges()} disabled={isDisabled}
                            className="btn btn-success btn-lg btn-block">{this.state.saveBtnText}
                        </button>
                    </div><div className="col-lg-5 "> </div></div>
            </div>
        );
    }
}