import React, { Component } from "react";
import Pagination from "react-js-pagination";
require("bootstrap/less/bootstrap.less");

export class PaginationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //activePage: 15
    };
    this.handlePageChange = this.handlePageChange.bind(this);

  }

  handlePageChange(pageNumber) {
    //console.log(`active page is ${pageNumber}`);
    this.props.handlePageChange(pageNumber);
  }

  render() {
    return (
      <nav>
        <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsCountPerPage}
          totalItemsCount={this.props.totalItemsCount}
          pageRangeDisplayed={this.props.numPages}
          onChange={this.handlePageChange}
          prevPageText={'Precedente'}
          innerClass={'pagination justify-content-center'}
          activeClass={'page-item active'}
          activeLinkClass={'page-link-new'}
          itemClass={'page-item'}
          //firstPageText={'1'}
          lastPageText={this.props.numPages}
          nextPageText={'Seguente'}
          //getPageUrl	Function		Generate href attribute for page
          itemClassFirst={'page-item'}
          itemClassPrev={'page-item'}
          itemClassNext={'page-item'}
          itemClassLast={'page-item'}
          linkClass={'page-link-new'}
          linkClassFirst={'page-link-new'}
          linkClassPrev={'page-link-new'}
          linkClassNext={'page-link-new'}
          linkClassLast={'page-link-new'} 
          hideFirstLastPages={true}
          hideDisabled={true}
          />
      </nav>
    );
  }
}