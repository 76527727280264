import * as React from 'react';
import history from '../history';
import { Breadcrumb } from '../Common/Breadcrumb';
import { API_CONFIG } from '../../public/js/apiServices';

const LINK = [
    { nome: "", url: "/" }
];
function redirect(path) {
    try {
        if (path !== undefined) {
            if (path.indexOf('/') === 0)
                history.push(path);
            else if (path.indexOf('/') === -1) {
                history.push('/' + path);
            }
        }
    } catch (error) {
        return console.error(error)
    }
}

// component that renders a single caratteristica row
export class TrattamentoRischio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        //this.getCounterAnsewers = this.getCounterAnsewers.bind(this);
        //this.addLineaGrigiaT = this.addLineaGrigiaT.bind(this);
    }

    render() {
        return (
            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">


                    <div className="row">

                        <div className="col-lg-12">
                            <nav className="" aria-label="sei qui:" role="navigation">
                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => redirect('/TrattamentoRischio')}>Trattamento del rischio </a></li>
                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">Trattamento del rischio</span>
                        </div>
                    </div>


                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">
                        <div className="row">
                            <div className="col-lg-12 card-headerInfo">
                                <p>La fase di Trattamento del Rischio ha lo scopo di determinare la modalità di trattamento (opzioni di trattamento) dei rischi calcolati, per ciascun evento di minaccia, nella fase di Risk Assessment, e identificare le attività necessarie (azioni di trattamento) al fine di ridurre ulteriormente il valore del rischio attuale ad un livello ritenuto accettabile (Propensione al Rischio).</p><hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/MonitoraggioGAPAnalysis')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">OPZIONI TRATT. SERVIZIO</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                                Accesso alla lista delle analisi del rischio per i servizi della Azienda per i quali si è provveduto al calcolo del rischio attuale. Selezionando l’elemento di interesse sarà possibile accedere alla sezione di “Opzioni di Trattamento”, eseguendo le modifiche opportune.
                            <hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/PAModificaTrattamento')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">OPZIONI TRATT.  Azienda</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                                Sarà possibile accedere alla sezione di “Opzioni di Trattamento” relative alla Azienda corrente, eseguendo le modifiche opportune.
		                    <hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/ElencoServiziAzioni')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">AZIONI TRATT. SERVIZIO</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            Accesso alla lista delle analisi del rischio per i servizi della Azienda per i quali si è provveduto al calcolo del rischio attuale. Selezionando l’elemento di interesse sarà possibile accedere alla sezione di Azioni di Trattamento”, eseguendo le modifiche opportune.
                            <hr />
                            </div>
                            <div className="col-lg-2 card-headerInfo">
                                <a style={{ cursor: 'pointer' }} onClick={() => redirect('/AzioniTrattamentoPA')}>
                                    <button type="button" className="btn btn-warning btn-lg btn-block">AZIONI TRATT.  Azienda</button>
                                </a>
                            </div>
                            <div className="col-lg-10 card-headerInfo">
                            Sarà possibile accedere alla sezione di “Azioni di Trattamento” relative alla Azienda corrente, eseguendo le modifiche opportune.
		                    <hr />
                            </div>



                        </div></div>







                </div>







            </div>

        )
    }
}

