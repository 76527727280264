import * as React from 'react';
import infoIcon from '../../public/images/info.png';

// component that renders a single caratteristica row
export class CaratteristicaRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //caratteristiche: this.props.caratteristiche
        };
        this.updateCaratteristica = this.updateCaratteristica.bind(this);
        this.updateNotaAggiuntiva = this.updateNotaAggiuntiva.bind(this);
        this.isIconHidden = this.isIconHidden.bind(this);
    }

    /**
     * 
     */
    updateCaratteristica(item, categoriaCaratteristicaId) {
        this.props.updateCaratteristica(categoriaCaratteristicaId, item.Id);
        //this.props.updateCaratteristica(this.props.rowId, item.Id);
    }

    /**
     * 
     */
    updateNotaAggiuntiva(item, e) {
        //console.log(e.target.value);
        let notaAggiuntiva = e.target.value;
        let categoriaCaratteristicaId = this.props.categoriaCaratteristicaId
        this.props.updateNotaAggiuntiva(categoriaCaratteristicaId, item.Id, notaAggiuntiva);
    }

    isIconHidden() {
        var response = "hidden";

        for (let x = 0; x < this.props.caratteristiche.length; x++) {
            if (this.props.caratteristiche[x].Checked) {
                response = "visible";
                break;
            }
        }

        return response;

    }

    render() {
        //console.log('this.props:', this.props);
        let rightList = [];
        let leftList = [];
        var rows = "";
        if (!this.props.caratteristiche.length) {
            rows = <div className="row">
                Loading...
        </div>
        } else {

            for (let x = 0; x < this.props.caratteristiche.length; x++) {
                var item;

                if (this.props.readOnly) {
                    if (this.props.caratteristiche[x].Checked)
                        item = <div key={this.props.categoryName + "canale" + x}>
                            <i className=" fa fa-check-square-o"></i> &nbsp;<strong>{this.props.caratteristiche[x].Nome}</strong>

                            {this.props.caratteristiche[x].Descrizione.length > 0 &&
                                <a data-bs-toggle="collapse" href={"#collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x}><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                            }
                            <br />
                            {this.props.caratteristiche[x].NotaAggiuntiva !== undefined && this.props.caratteristiche[x].NotaAggiuntiva}

                            {this.props.caratteristiche[x].Descrizione.length > 0 &&
                                <div id={"collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                    <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                    {this.props.caratteristiche[x].Descrizione}
                                </div>
                            }
                            <hr />
                        </div>;
                    else
                        item = <div key={this.props.categoryName + "canale" + x}>
                            <span className="testoGrigio">{this.props.caratteristiche[x].Nome}</span><hr />
                        </div>;
                }
                else
                    item = <div key={this.props.categoryName + "canale" + x}>
                        <input type="checkbox"
                            onChange={() => this.updateCaratteristica(this.props.caratteristiche[x], this.props.categoriaCaratteristicaId)}
                            //defaultChecked={this.props.caratteristiche[i].Checked}
                            checked={this.props.caratteristiche[x].Checked}
                        /> &nbsp;{this.props.caratteristiche[x].Nome}
                        {this.props.caratteristiche[x].Descrizione.length > 0 &&
                            <a data-bs-toggle="collapse" href={"#collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x}><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                        }

                        <input type="text"
                            hidden={!this.props.caratteristiche[x].Checked}
                            value={(this.props.caratteristiche[x].NotaAggiuntiva) ? this.props.caratteristiche[x].NotaAggiuntiva : ''}
                            onChange={(e) => this.updateNotaAggiuntiva(this.props.caratteristiche[x], e)}
                            placeholder="Inserisci nota"
                            onBlur={this.props.saveDraft}
                            style={{ width: '100%' }} />
                        {this.props.caratteristiche[x].Descrizione.length > 0 &&
                            <div id={"collapseInfo" + this.props.categoriaCaratteristicaId + "canale" + x} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                {this.props.caratteristiche[x].Descrizione}
                            </div>
                        }
                        <hr />
                    </div>;


                if ((x + 1) % 2 === 0)
                    rightList.push(item);
                else
                    leftList.push(item);
            }

            rows = this.props.caratteristiche.map(function (item, i) {

                return (
                    this.props.readOnly ?
                        <div key={i}>

                            <input type="checkbox"
                                disabled={true}
                                defaultChecked={this.props.caratteristiche[i].Checked}
                            /> &nbsp;{item.Nome}
                            <br />
                            <div
                                hidden={!this.props.caratteristiche[i].Checked}
                                style={{ width: '100%' }}>
                                {this.props.caratteristiche[i].NotaAggiuntiva}
                            </div>
                            <hr />
                        </div>
                        :
                        <div key={i}>

                            <input type="checkbox"
                                onChange={() => this.updateCaratteristica(this.props.caratteristiche[i], this.props.categoriaCaratteristicaId)}
                                //defaultChecked={this.props.caratteristiche[i].Checked}
                                checked={this.props.caratteristiche[i].Checked}
                            /> &nbsp;{item.Nome}
                            <br />
                            <input type="text"
                                hidden={!this.props.caratteristiche[i].Checked}
                                value={this.props.caratteristiche[i].NotaAggiuntiva}
                                onChange={(e) => this.updateNotaAggiuntiva(this.props.caratteristiche[i], e)}
                                placeholder="Inserisci nota"
                                //autocomplete="off"
                                onBlur={this.props.saveDraft}
                                style={{ width: '100%' }} />
                            <hr />
                        </div>
                );
            }.bind(this)
            );
        }

        return (

            <div>
                <div className="row">
                    <div className="col-lg-12 card-headerT">
                        <a className="card-link" data-bs-toggle="collapse" href={"#collapse" + parseInt(this.props.rowId, 10)}>
                            <i style={{ visibility: this.isIconHidden() }} className="testoVerde fa fa-check"></i> {this.props.categoryName + (this.props.readOnly ? "" : " *")}<i className="iconRight fa fa-chevron-down"></i></a>
                    </div>
                </div>
                <hr />
                <div id={"collapse" + parseInt(this.props.rowId, 10)} className="collapse" data-parent="#accordion">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                {leftList}
                            </div>
                            <div className="col-lg-6">
                                {rightList}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};