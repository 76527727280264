const STATI = ["Incompleto", "Completo", "Pubblicato"];

export var toStringStatoAssessmentId = function (index) {
    let stato = "";
    try {
        if (index !== -1) {
            if (index < 3) {
                stato = STATI[0];
            } else if (index > 3) {
                stato = STATI[2];
            } else stato = STATI[1]
        }
        return stato;

    } catch (error) {
        console.error(error);
        return "";
    }
}
