import * as React from 'react';
import { Util } from '../data/Util';
import LIVELLO from '../data/criticality';
import EFFICACY_GRADE from "./GradoEfficacia";
import infoIcon from '../../public/images/info.png';

// component that renders a single AzioniTrattamentoRischioResiduo row
export class AzioniTrattamentoRischioResiduoRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            emptyText: '',
            /**
             * 
             isHiddenGrade: this.props.radioGroup.isHiddenGrade,
             isDisabledDataChiusura: this.props.radioGroup.isDisabledDataChiusura,
             isCheckRadio: (this.props.radioGroup.isCheckConsigliata || this.props.radioGroup.isCheckEffettiva || this.props.radioGroup.isCheckNessuna),
             isCheckConsigliata: this.props.radioGroup.isCheckConsigliata,
             isCheckEffettiva: this.props.radioGroup.isCheckEffettiva,
            isCheckNessuna: this.props.radioGroup.isCheckNessuna
        */
        };
        this.getEfficacia = this.getEfficacia.bind(this);
        this.getGrade = this.getGrade.bind(this);
        //ToString
        this.toStringYearAndMonth = this.toStringYearAndMonth.bind(this);
        //UPDATE
        this.updateMonth = this.updateMonth.bind(this);
        this.updateDataChiusura = this.updateDataChiusura.bind(this);
        this.updateNessunaAzione = this.updateNessunaAzione.bind(this);
        this.updateEffectiveAction = this.updateEffectiveAction.bind(this);
        this.updateAzioneEffettiva = this.updateAzioneEffettiva.bind(this);
        this.updateAzioneConsigliata = this.updateAzioneConsigliata.bind(this);
        this.getActionsRows = this.getActionsRows.bind(this);
    }

    /**
     * 
     static getDerivedStateFromProps(props, state) {
         if (props.radioGroup.isHiddenGrade !== state.isHiddenGrade ||
            props.radioGroup.isDisabledDataChiusura !== state.isDisabledDataChiusura ||
            props.radioGroup.isCheckConsigliata !== state.isCheckConsigliata ||
            props.radioGroup.isCheckEffettiva !== state.isCheckEffettiva ||
            props.radioGroup.isCheckNessuna !== state.isCheckNessuna ||
            (props.radioGroup.isCheckConsigliata || props.radioGroup.isCheckEffettiva || props.radioGroup.isCheckNessuna) !== state.isCheckRadio
        ) {
            return {
                
                isHiddenGrade: props.radioGroup.isHiddenGrade,
                isDisabledDataChiusura: props.radioGroup.isDisabledDataChiusura,
                isCheckConsigliata: props.radioGroup.isCheckConsigliata,
                isCheckEffettiva: props.radioGroup.isCheckEffettiva,
                isCheckNessuna: props.radioGroup.isCheckNessuna,
                isCheckRadio: (props.radioGroup.isCheckConsigliata || props.radioGroup.isCheckEffettiva || props.radioGroup.isCheckNessuna)
            };
        }
        return null;
    }
    */

    componentDidMount() {
        for (let index = 0; index < this.props.action.length; index++) {
            const action = this.props.action[index];
            /** */
            let radioGroup = {
                isCheckConsigliata: false,
                isCheckEffettiva: false,
                isCheckNessuna: false,
                isHiddenGrade: true,
                isDisabledDataChiusura: false
            }

            let isFirtTime = (action.GradoDiImplementazioneAtteso === null);
            if (!isFirtTime) {
                //caso di “Azione consigliata”
                if (action.AzioneEffettiva === null) {
                    radioGroup.isCheckConsigliata = true;
                    //disabilitare la combobox del grado di implementazione
                    radioGroup.isHiddenGrade = true;
                    //abilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = false;
                }    //caso di “Nessuna azione” 
                else if (action.AzioneEffettiva.localeCompare("empty") === 0) {
                    radioGroup.isCheckNessuna = true;
                    //disabilitare il grado di implementazione atteso
                    radioGroup.isHiddenGrade = true;
                    //imposta lo stesso valore del grado di implementazione originale
                    //action.GradoDiImplementazioneAtteso = action.GradoDiImplementazione;
                    //disabilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = true;
                } else {//Caso di “Azione effettiva”
                    radioGroup.isCheckEffettiva = true;
                    //abilitare il grado di implementazione atteso
                    radioGroup.isHiddenGrade = false;
                    //abilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = false;
                }
            }
        }
    }

    /**
     * 
     * @param {*} date 
     */
    toStringDate(date) {

        try {
            var dateParse = new Date(Date.parse(date));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                let date = dateParse.getDate();
                let dateStr = (date > 9) ? date : '0' + date;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                //return date + '/' + monthStr + '/' + year + ' ' + dateParse.toString().split(' ')[4];
                return dateStr + '/' + monthStr + '/' + year;
            } else {
                return '';
            }
        } catch (error) {
            console.log(error);
            return '';
        }
    }

    /**
     * for example this.props.action.DataChiusura = "2018-10-10T12:13:57.326Z" return "2018-10"
     */
    toStringYearAndMonth(type) {
        let dateStr = '';
        try {
            var dateParse = new Date(Date.parse(this.props.action.DataChiusura));
            if (dateParse instanceof Date && !isNaN(dateParse.valueOf())) {
                //let day = dateParse.getDate();
                //let dayStr = (day > 9) ? day : '0' + day;
                let year = dateParse.getFullYear();
                let month = dateParse.getMonth() + 1;
                let monthStr = (month > 9) ? month : '0' + month;
                switch (type) {
                    case "text":
                        dateStr = monthStr + '/' + year;
                        break;
                    case "month":
                        dateStr = year + '-' + monthStr;
                        break;
                    default://
                        break;
                }
            }
            return dateStr;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     * @param {*} month 
     * @param {*} year 
     */
    updateDataChiusura(selectedDate) {
        //debugger
        this.props.updateDataChiusura(selectedDate, this.props.action);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }

    /**
     * 
     * @param {*} event 
     */
    updateMonth(value) {
        console.log('yearMonth', value);
        //debugger
        this.props.updateDataChiusura(value, this.props.action);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }
    /**
     * 
     * @param {*} event 
     */
    updateEffectiveAction(event) {
        //var grade = parseInt(event.target.value, 10);
        this.props.updateEffectiveAction(event.target.value, this.props.action);
        this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio);
    }

    /**
     * Nel caso di “Azione consigliata” il sistema deve disabilitare la combobox del grado di 
     * implementazione atteso e impostare il valore a “completo”.
     */
    updateAzioneConsigliata(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: checked });
        this.setState({ isCheckEffettiva: !checked });
        this.setState({ isCheckNessuna: !checked });
        //disabilitare la combobox del grado di implementazione atteso
        this.setState({ isHiddenGrade: true });
        //abilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: false });
        //imposta il valore atteso a “completo”
        this.props.updateGrade(4, this.props.action, null);
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    /**
     * Nel caso di “Azione effettiva” il sistema deve abilitare la combobox del grado di 
     * implementazione atteso
     */
    updateAzioneEffettiva(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: !checked });
        this.setState({ isCheckEffettiva: checked });
        this.setState({ isCheckNessuna: !checked });
        //abilitare il grado di implementazione atteso
        this.setState({ isHiddenGrade: false });
        this.props.updateGrade(this.props.action.GradoDiImplementazione, this.props.action, "");
        //abilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: false });
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    /**
     * Nel caso di “Nessuna azione” il sistema deve disabilitare la combobox del grado di implementazione 
     * atteso impostandolo allo stesso valore del grado di implementazione originale  e disabilitare la 
     * data di chiusura prevista.
     */
    updateNessunaAzione(event) {
        let checked = event.target.checked;
        this.setState({ isCheckConsigliata: !checked });
        this.setState({ isCheckEffettiva: !checked });
        this.setState({ isCheckNessuna: checked });
        //disabilitare il grado di implementazione atteso
        this.setState({ isHiddenGrade: true });

        //imposta lo stesso valore del grado di implementazione originale
        this.props.updateGrade(this.props.action.GradoDiImplementazione, this.props.action, "empty");
        //disabilitare la data di chiusura prevista
        this.setState({ isDisabledDataChiusura: true });
        this.setState({ isCheckRadio: true }, () => this.props.updadateCheckedRow(this.props.index, this.state.isCheckRadio));

    }

    getEfficacia(grado) {
        var res = "";
        if (!isNaN(grado)) {

            switch (grado) {
                case 1:
                    res = "BASSO"
                    break;
                case 2:
                    res = "MEDIO"
                    break;
                case 3:
                    res = "ELEVATO"
                    break;

                default:
                    res = "";
                    break;
            }
        }
        return res;
    }

    /**
     * 
     */
    toStringInfoAggiuntive(infoAggiuntive) {
        let toString = [];
        //infoAggiuntive.split('\n').map((item, key) => {
        let array = infoAggiuntive.split('\n');
        for (let key = 0; key < array.length; key++) {
            const item = array[key];


            var indices = [];
            var element = '<b>';
            var idx = item.indexOf(element);
            while (idx != -1) {
                indices.push(idx);
                idx = item.indexOf(element, idx + 1);
            }

            var indexStart = item.indexOf('<b>');
            var indexEnd = item.indexOf('</b>');

            if (indices.length > 0) {
                var stringList = [];

                for (var x = 0; x < indices.length; x++) {

                    var indexStart = indices[x];
                    var indexEnd = item.indexOf('</b>', indices[x]);
                    var testoPrima = '';

                    if (x === 0) {
                        testoPrima = item.substring(0, indexStart);
                    }

                    var indiceTestoDopo = item.length;
                    if (indices[x + 1] !== undefined) {
                        indiceTestoDopo = indices[x + 1];
                    }

                    var testoBold = item.substring(indices[x] + 3, indexEnd);
                    var testoDopo = item.substring(indexEnd + 4, indiceTestoDopo);


                    var element = <span key={x}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
                    stringList.push(element);
                }

                toString.push(<span key={key}>{stringList}<br /></span>);
                //return <span key={key}>{stringList}<br /></span>;

                //return <span key={key}>{testoPrima}<strong>{testoBold}</strong>{testoDopo}</span>;
            }
            else {
                toString.push(<span key={key}>{item}<br /></span>);
                //return <span key={key}>{item}<br /></span>;
            }
        }
        return toString;
    }

    getQuarterDate(date) {
        if (date) {
            var quarter = Math.ceil((new Date(date).getMonth() + 1) / 3);
            var year = new Date(date).getFullYear();
            //console.log("quarterDate", "Q" + quarter + " - " + year);
            return quarter + "-" + year;
        }
        else
            return "-1";
    }

    /**
     * 
     */
    getActionsRows() {
        let rows = [];
        let threatRow =
            <div className="row " key={"row" + this.props.index}>
                {/*#1 colonna Minaccia */}
                <div className="col-lg-9 card-headerT"><i className="testoVerde fa fa-check"></i>
                    <a className="card-link" data-bs-toggle="collapse" href={"#collapsePhishing" + this.props.index}>
                        {this.props.threat.Nome}</a>
                </div>
                {/*#2 colonna Rischio attuale */}
                <div className="col-lg-1 card-header"><span className={Util.getRisk(this.props.threat.RischioAttuale, LIVELLO).className + " font16"}>
                    {Util.getRisk(this.props.threat.RischioAttuale, LIVELLO).name.toUpperCase()}</span></div>
                {/*#3 colonna  Propensione */}
                <div className="col-lg-1 card-header"><span className={Util.getRisk(this.props.threat.RiskAppetite, LIVELLO).className + " font16"}>
                    {Util.getRisk(this.props.threat.RiskAppetite, LIVELLO).name.toUpperCase()}</span></div>
                {/*#4 colonna Rischio Residuo */}
                <div className="col-lg-1 card-header"><span className={Util.getRisk(this.props.threat.RischioResiduo, LIVELLO).className + " font16"}>
                    {Util.getRisk(this.props.threat.RischioResiduo, LIVELLO).name.toUpperCase()}</span></div>
            </div>
        let actionsRows = [];
        actionsRows.push(<div className="col-lg-8"><strong>Elenco Azioni di Trattamento</strong></div>)
        for (let index = 0; index < this.props.action.length; index++) {
            const act = this.props.action[index];
            /** */
            let radioGroup = {
                isCheckConsigliata: false,
                isCheckEffettiva: false,
                isCheckNessuna: false,
                isHiddenGrade: true,
                isDisabledDataChiusura: false
            }

            let isFirtTime = (act.GradoDiImplementazioneAtteso === null);
            if (!isFirtTime) {
                //caso di “Azione consigliata”
                if (act.AzioneEffettiva === null) {
                    radioGroup.isCheckConsigliata = true;
                    //disabilitare la combobox del grado di implementazione
                    radioGroup.isHiddenGrade = true;
                    //abilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = false;
                }    //caso di “Nessuna azione” 
                else if (act.AzioneEffettiva.localeCompare("empty") === 0) {
                    radioGroup.isCheckNessuna = true;
                    //disabilitare il grado di implementazione atteso
                    radioGroup.isHiddenGrade = true;
                    //imposta lo stesso valore del grado di implementazione originale
                    //action.GradoDiImplementazioneAtteso = action.GradoDiImplementazione;
                    //disabilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = true;
                } else {//Caso di “Azione effettiva”
                    radioGroup.isCheckEffettiva = true;
                    //abilitare il grado di implementazione atteso
                    radioGroup.isHiddenGrade = false;
                    //abilitare la data di chiusura prevista
                    radioGroup.isDisabledDataChiusura = false;
                }
            }
            let minacceRows = [];

            for (let j = 0; j < act.Minacce.length; j++) {
                const threat = act.Minacce[j];
                let threatNameElement = <div className="col-lg-8 " key={"threatName" + j}><p>{threat.NomeMinaccia}</p></div>
                let threatGradeElement =
                    <div className="col-lg-4 text-center" key={"threatGrade" + j}>{this.getGrade(threat.GradoDiEfficacia, EFFICACY_GRADE)}</div>
                minacceRows.push(threatNameElement, threatGradeElement);
            }

            let isCheckHidden =
                (act.GradoDiImplementazioneAtteso !== null && act.AzioneEffettiva !== "") &&
                    (act.DataChiusura || act.AzioneEffettiva === "empty") &&
                    (this.state.isCheckRadio)
                    ? "visible" : "hidden";
            let textareaVal = '';
            if (act.AzioneEffettiva) {
                textareaVal = (act.AzioneEffettiva.localeCompare("empty") === 0) ? '' : act.AzioneEffettiva;
            }
            /* <!-- INIZIO ACCORDION --> */
            const rowElement =

                <div className="row" key={"ACCORDION" + this.props.index + act.AssessmentAzioniDiTrattamentoId}>

                    {/* #1 colonna azione */}
                    <div className="col-lg-12 card-headerT truncate">
                        <a data-bs-toggle="collapse" href={"#collapse" + this.props.index + act.AssessmentAzioniDiTrattamentoId}>
                            <i style={{ visibility: isCheckHidden }} className="testoVerde fa fa-check"></i>{act.SottoCategoria}<i className="iconRight fa fa-chevron-down"></i>
                        </a>
                    </div>
                    {/*
                    
                        <div className="col-lg-1 card-header">{GRADE[(act.GradoDiImplementazione.toString()) ? act.GradoDiImplementazione : '-1']}</div>
                        <div className="col-lg-1 card-header">
                        <GradoImplementationeOption
                            grade={act.GradoDiImplementazioneAtteso === null ? -1 : act.GradoDiImplementazioneAtteso}
                            updateGrade={this.props.updateGrade}
                            action={act}
                            isDisabledGrade={this.props.readOnly}
                            />
                    </div>
                            
                    <div className="col-lg-1 card-header testoBlu">
                        <DatePicker className="testoBlu"
                            selectedDate={this.getQuarterDate(act.DataChiusura)}
                            updateDataChiusura={this.updateDataChiusura}
                            id={act.AssessmentAzioniDiTrattamentoId}
                            key={"datePicker" + act.AssessmentAzioniDiTrattamentoId}
                            isDisabled={this.state.isDisabledDataChiusura || this.props.readOnly}
                        />
                    </div>
                    */}
                    <div id={"collapse" + this.props.index + act.AssessmentAzioniDiTrattamentoId} className="col-lg-12 collapse" data-parent="#accordion" >
                        <div className="row card-headerLight ">
                            <table width="100%" border="0" cellPadding="5">
                                <tbody>
                                    <tr><td align="left" valign="middle">
                                        <a data-bs-toggle="collapse" href={"#collapseAA" + this.props.index}><br />
                                            <span className=" testoBlu paRight"><img src={infoIcon} alt="images/info.png" /></span>
                                        </a></td>
                                        <td align="left" valign="top"><br />{(act.Nome) ? act.Nome : ''}</td></tr>
                                    <tr><td></td><td valign="top"><div id={"collapseAA" + this.props.index} className="collapse  InfoAgg  " data-parent="#accordion">
                                        <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                        {(act.InformazioniAggiuntive) ? this.toStringInfoAggiuntive(act.InformazioniAggiuntive) : ''}
                                    </div> </td></tr>
                                </tbody>
                            </table>
                            <div className="col-lg-12"><hr /></div>
                            {/*
                            <div className="col-lg-12"><a data-bs-toggle="collapse" href={"#collapseAction" + this.props.index + act.AssessmentAzioniDiTrattamentoId}><p className="testoBlu "><strong>  Azioni di trattamento</strong><i className="iconRight fa fa-chevron-down"></i></p>  </a> </div>
                            <div id={"collapseAction" + this.props.index + act.AssessmentAzioniDiTrattamentoId} className="col-lg-12 collapse" data-parent="#accordion">
                                <AssessmentAzioneTrattamentoRow
                                    key={index}
                                    index={index}
                                    id={act.AssessmentAzioniDiTrattamentoId}
                                    action={act}
                                    radioGroup={radioGroup}
                                    updateGrade={this.updateGrade}
                                    updateDataChiusura={this.updateDataChiusura}
                                    updadateCheckedRow={this.updadateCheckedRow}
                                    updateEffectiveAction={this.updateEffectiveAction}
                                    updateAzioneConsigliata={this.updateAzioneConsigliata}
                                    toStringInfoAggiuntive={(act.InformazioniAggiuntive) ? this.toStringInfoAggiuntive(act.InformazioniAggiuntive) : ''}
                                    textareaVal={textareaVal}
                                    save={this.save}
                                    readOnly={true}
                                />
                            </div>
                            <div className="col-lg-12"><hr />
                            </div>
                            <div className="col-lg-12"><a data-bs-toggle="collapse" href={"#collapseElencoMinacce" + this.props.index + act.AssessmentAzioniDiTrattamentoId}><p className="testoBlu "><strong>  Minacce coinvolte</strong><i className="iconRight fa fa-chevron-down"></i></p>  </a> </div>
                            <div id={"collapseElencoMinacce" + this.props.index + act.AssessmentAzioniDiTrattamentoId} className="col-lg-12 collapse" data-parent="#accordion">
                                <div className="row">
                                    <div className="col-lg-8"><strong>  Elenco minacce</strong></div>
                                    <div className="col-lg-4 text-center"><strong> Grado di efficacia del controllo</strong></div>
                                    {minacceRows}
                                    <br /><br /><br /></div>
                            </div>
                            <div className="col-lg-12"><hr />
                            </div>
                            <div className="col-lg-12"><a data-bs-toggle="collapse" href={"#collapseConvenzione" + this.props.index + act.AssessmentAzioniDiTrattamentoId}><p className="testoBlu "><strong>Convenzione</strong><i className="iconRight fa fa-chevron-down"></i></p>  </a>
                                <div id={"collapseConvenzione" + this.props.index + act.AssessmentAzioniDiTrattamentoId} className="col-lg-12 collapse" data-parent="#accordion">
                                    <div className="row">
                                        <div className="col-lg-3"><strong>{act.ConvenzioneNome}</strong></div>
                                        <div className="col-lg-3"><strong>Tipologia:</strong> {act.ConvenzioneTipologia} </div>
                                        <div className="col-lg-3"><strong>Codice:</strong> {act.ConvenzioneCodice} </div>
                                        <div className="col-lg-3"><strong>Note:</strong> {act.ConvenzioneNote} </div>
                                    </div>
                                </div>
                            </div>
                        */}
                        </div>
                    </div>
                </div>
            actionsRows.push(rowElement);
        }
        rows.push(threatRow);
        rows.push(<div key={"collapsePhishing" + this.props.index} id={"collapsePhishing" + this.props.index} className="col-lg-12 collapse" data-parent="#accordion">
            {actionsRows}
        </div>
        );
        return rows;
    }

    /**
     * 
     */
    getGrade(index, array) {
        try {
            let grade = ((index) && (index >= 0) && (index < array.length)) ? array[index] : "";
            return grade;

        } catch (error) {
            console.error(error);
            return <div className="testoRosso">{error.toString()}</div>
        }
    }

    render() {
        //console.log("props", this.props);
        let minaccieRows = [];
        if (this.props.action.length === 0) {
            minaccieRows = <div className="row" key={"emptyRow"}> {this.state.emptyTest}</div>
        } else {
            for (let index = 0; index < this.props.action.length; index++) {
                for (let j = 0; j < this.props.action[index].Minacce.length; j++) {
                    const minaccia = this.props.action[index].Minacce[j];
                    let nomeMinacciaCol =
                        <div className="col-lg-8 " key={"nome_minaccia" + index + minaccia.MisuraDiSicurezzaId}>
                            <label>{minaccia.NomeMinaccia}</label>
                            <a data-bs-toggle="collapse" href={"#collapseInfo" + minaccia.MisuraDiSicurezzaId + "minaccia" + index}><span className=" testoBlu paRight">
                                <img src={infoIcon} alt="info.png" /></span></a>
                            <div id={"collapseInfo" + minaccia.MisuraDiSicurezzaId + "minaccia" + index} className="collapse spazio10 InfoAgg giustifica " data-parent="#accordion">
                                <div className="testoCentrato">Informazioni aggiuntive:</div><hr className="linea0066ccStat" />
                                {(minaccia.DescrizioneEvento) ? this.toStringInfoAggiuntive(minaccia.DescrizioneEvento) : ""}
                            </div>
                            <hr />
                        </div>

                    let gradoMinacciaCol = <div className="col-lg-4 text-center"
                        key={"grado_minaccia" + index + minaccia.MisuraDiSicurezzaId}>
                        {this.getEfficacia(minaccia.GradoDiEfficacia)}
                    </div>
                    minaccieRows.push(nomeMinacciaCol, gradoMinacciaCol);
                }
            }
        }//end else

        let isCheckHidden =
            (this.props.action.GradoDiImplementazioneAtteso !== null && this.props.action.AzioneEffettiva !== "") &&
                (this.props.action.DataChiusura || this.props.action.AzioneEffettiva === "empty") &&
                (this.state.isCheckRadio)
                ? "visible" : "hidden";
        let textareaVal = '';
        if (this.props.action.AzioneEffettiva) {
            textareaVal = (this.props.action.AzioneEffettiva.localeCompare("empty") === 0) ? '' : this.props.action.AzioneEffettiva;
        }

        /*
        this.props.updadateCheckedRow(this.props.index, (GRADE[this.props.action.GradoDiImplementazioneAtteso].length > 0) &&
        (this.props.action.DataChiusura) && (this.state.isCheckRadio));
        console.log("month:", Month[new Date(this.props.action.DataChiusura).getMonth()]);
        */

        return (
            <div>
                {this.getActionsRows()}
            </div>
        )
    }
}