import React, { Component } from 'react';
import $ from 'jquery';
import history from '../history';
import { UserRow } from './UserRow';
import { API_CONFIG } from '../../public/js/apiServices';
import { Breadcrumb } from '../Common/Breadcrumb';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import { SearchInput } from '../Common/SearchInput';
import infoIcon from '../../public/images/info.png';

const LINK = [
    { nome: "Gestione Utenti", url: "/GestioneUtenti" },
    { nome: "Elenco Utenti", url: "/UtentiElenco" },
];

export class UtentiElenco extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            rowsTable: [],
            selectedName: sessionStorage.getItem('selectedName') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedName') : '-1',
            selectedSurname: sessionStorage.getItem('selectedSurname') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedSurname') : '-1',
            selectedFiscalcode: sessionStorage.getItem('selectedFiscalcode') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('selectedFiscalcode') : '-1',
            search: sessionStorage.getItem('searchUsers') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchUsers') : '',
            searchAll: sessionStorage.getItem('searchAllUsers') !== null && this.props.riepilogo !== "true" ? sessionStorage.getItem('searchAllUsers') : '',
            isAscName: true,
            activePage: 1,
            currentPageListusers: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...',
            selectedColumn: "",
            messageError: "",
            messageDelete: "",
            usercodeDeleted: "",
            userLogged: JSON.parse(sessionStorage.getItem('userInfo')).user
            /*
             selectedCategory: '-1',
             selectedState: '-1',
             selectedTypology: '-1',
             search: '',
             */
        }
        this.areThereModifiedusers = this.areThereModifiedusers.bind(this);
        this.getAllUsers = this.getAllUsers.bind(this);
        this.isUserLogged = this.isUserLogged.bind(this);

        /**UPDATE */

        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.toString = this.toString.bind(this);
        this.updateRowsTable = this.updateRowsTable.bind(this);
        this.orderUsersList = this.orderUsersList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);
        this.showModal = this.showModal.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {
        this.getAllUsers();
    }

    getAllUsers() {

        this.serverRequest =
            $.ajax({
                url: API_CONFIG.getAllUsers,
                //url: 'http://jsonplaceholder.typicode.com/users',
                type: "GET",
                contentType: 'application/json',
                headers: { 'token': sessionStorage.getItem('token') },
                cache: false,
                success: function (response) {
                    if (response.data) {
                        //this.setState({ users: this.getUser(response) }, () => console.log(this.state.users));
                        //this.setState({ rowsTable: this.getUser(response) }, this.updateRowsTable);
                        this.setState({ users: response.data }, () => console.log(this.state.users));
                        this.setState({ rowsTable: response.data }, this.updateRowsTable);
                        this.handlePageChange(1);
                        this.setState({ emptyTest: "Nessun utente con i criteri specificati" });
                    } else {
                        this.setState({ emptyTest: "Nessun utente con i criteri specificati" });
                    }
                }.bind(this),

                error: function (xhr, resp, text) {
                    let messageError = "Si è verificato un errore ";
                    console.error(xhr);
                    //Token JWT Invalid
                    if (xhr.responseJSON) {
                        if (xhr.responseJSON.data === -1) {
                                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                            location.replace(API_CONFIG.HomePath);
                        } else {
                            this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                            $('#btnError').get(0).click();
                        }
                    }
                    else {

                        this.setState({ messageError: messageError + "." });
                        $('#btnError').get(0).click();
                    }
                }.bind(this),
            });

        if ((sessionStorage.getItem('selectedCategoryAC') !== null ||
            sessionStorage.getItem('selectedStateAC') !== null ||
            sessionStorage.getItem('selectedTypologyAC') !== null ||
            sessionStorage.getItem('searchAllAC') !== null) &&
            this.props.riepilogo !== "true")
            $("#ricerca").get(0).click();
    }

    /**
     * 
     * @param {*} array 
     */
    getUser(array) {
        let usersList = [];
        for (let index = 0; index < array.length; index++) {
            var dayc = 18 + index;
            var dayl = 15 + index;
            let user = {
                id: array[index].id,
                CodiceFiscale: array[index].email,
                Nome: array[index].name.split(" ")[0],
                Cognome: array[index].name.split(" ")[1],
                DataCreazione: "2018-06-" + dayc + "T11:45:30.079Z",
                DataUltimoAccesso: "2019-01-" + dayl + "T09:19:07.678Z",
                DefaultPA: "c_f205",
            }
            usersList.push(user);
        }
        return usersList;
    }

    /**
     * 
     */
    setCategoriesTypologies() {
        $.ajax({
            //url: 'https://cns.westeurope.cloudapp.azure.com/serviziocompositeserv/api/serviziocomposite/getCategorieSottoCategorie',
            url: API_CONFIG.getCategorieSottoCategorie,
            type: "POST",
            contentType: 'application/json',
            headers: { 'token': sessionStorage.getItem('token') },
            cache: false,
            success: function (response) {
                //console.log('response:', response);
                this.setState({ typologies: response.data.SottoCategoriaServizio });
                this.setState({ categories: response.data.CategoriaServizio });
                //console.log('SottoCategoriaServizio:', this.state.typologies);
                //console.log('CategoriaServizio:', this.state.categories);
            }.bind(this),

            error: function (xhr, resp, text) {
                let messageError = "Si è verificato un errore ";
                console.error(xhr);
                //Token JWT Invalid
                if (xhr.responseJSON) {
                    if (xhr.responseJSON.data === -1) {
                            sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                        location.replace(API_CONFIG.HomePath);
                    } else {
                        this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data });
                        $('#btnError').get(0).click();
                    }
                }
                else {

                    this.setState({ messageError: messageError + "." });
                    $('#btnError').get(0).click();
                }
            }.bind(this),
        });
    }
    /**
     * 
     * @param {*} e 
     */
    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchUsers', val);
        this.setState({ search: val }, this.updateRowsTable);
        //this.setState({ search: val }, () => { this.updateRowsTable(); this.handlePageChange(1) });
    }

    updateSearchAll(e) {
        let val = e.target.value;
        sessionStorage.setItem('searchAllUsers', val);
        this.setState({ searchAll: val }, this.updateRowsTable);
    }

    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        var toString = '';
        try {
            if ((list.length) || list.length !== 0) {
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];
                    toString += element.Nome + ' | ';
                }
                return toString.substring(0, toString.length - 2)
            } else return "-";
        } catch (error) {
            console.error(error)
            return error.toString();
        }
    }

    updateRowsTable() {
        let services = this.state.users;
        //let rowsTable = this.state.rowsTable;
        let search = this.state.search;
        let searchAll = this.state.searchAll;

        try {
            var list = [];
            if (searchAll === '' && search === '') {
                list = services;
            } else {

                for (let index = 0; index < services.length; index++) {
                    const element = services[index];

                    if (element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1
                        || (element.Cognome.toUpperCase().indexOf(search.toUpperCase()) > -1)
                        || (element.CodiceFiscale.toUpperCase().indexOf(search.toUpperCase()) > -1)) {

                        list.push(element);
                    }
                }
            }
            console.log('list:', list);
            //this.setState({ rowsTable: list });
            this.setState({ rowsTable: list }, () => { this.handlePageChange(1) });
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    orderUsersList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let rowsTable = this.state.rowsTable;
        key = key.toLowerCase();
        this.setState({ selectedColumn: key });
        switch (key) {
            case 'nome':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Nome.localeCompare(b.Nome); });

                } else list = rowsTable.sort(function (a, b) { return b.Nome.localeCompare(a.Nome); });
                this.setState({ isAscName: !asc });
                break;
            case 'cognome':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.Cognome.localeCompare(b.Cognome); });

                } else list = rowsTable.sort(function (a, b) { return b.Cognome.localeCompare(a.Cognome); });
                this.setState({ isAscName: !asc });
                break;
            case 'createdate':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return new Date(a.DataCreazione).getTime() - new Date(b.DataCreazione).getTime(); });

                } else list = rowsTable.sort(function (a, b) { return new Date(b.DataCreazione).getTime() - new Date(a.DataCreazione).getTime(); });
                this.setState({ isAscName: !asc });
                break;
            case 'lastaccess':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return new Date(a.DataUltimoAccesso).getTime() - new Date(b.DataUltimoAccesso).getTime(); });

                } else list = rowsTable.sort(function (a, b) { return new Date(b.DataUltimoAccesso).getTime() - new Date(a.DataUltimoAccesso).getTime(); });
                this.setState({ isAscName: !asc });
                break;

            case 'codice':
                if (asc) {
                    list = rowsTable.sort(function (a, b) { return a.CodiceFiscale.localeCompare(b.CodiceFiscale); });

                } else list = rowsTable.sort(function (a, b) { return b.CodiceFiscale.localeCompare(a.CodiceFiscale); });
                this.setState({ isAscName: !asc });
                break;
            default:
                list = rowsTable;
                break;
        }
        this.setState({ rowsTable: list }, () => { this.handlePageChange(this.state.activePage) });
    }

    /**
     * 
     */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let rowsTable = this.state.rowsTable;
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (rowsTable[i])
                listing_table.push(rowsTable[i]);
        }
        //console.log(`active page is ${page}`);
        this.setState({ activePage: page });
        this.setState({ currentPageListusers: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.rowsTable.length / this.state.itemsCountPerPage);
    }

    areThereModifiedusers() {
        let usersList = this.state.users;
        let areThereModifiedusers = false;
        try {

            for (let index = 0; index < usersList.length; index++) {
                let tsCompilazioneImpatto = new Date(usersList[index].DataCompilazioneContesto).getTime();
                let tsCalcoloImpatto = new Date(usersList[index].DataUpdateProfiloDiCriticità).getTime();
                if (tsCompilazioneImpatto > tsCalcoloImpatto) {
                    //debugger
                    return true;
                }

            }
            //console.log('areThereModifiedusers? ', areThereModifiedusers);
            return areThereModifiedusers;
        } catch (error) {
            console.error(error);
            return error.toString();
        }
    }

    showModal(user) {
        let text = "Sei sicuro di voler eliminare l'utente " + user.Nome + " " + user.Cognome + "?";
        this.setState({ usercodeDeleted: user.CodiceFiscale });
        this.setState({ messageDelete: text }, () => $("#btnDelete").click());
    }

    deleteUser(codice) {
        //alert(this.state.usercodeDeleted)
        if (this.state.usercodeDeleted.length == 16) {
            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.deleteUtente,
                    type: "POST",
                    contentType: 'application/json',
                    data: JSON.stringify({ id: this.state.usercodeDeleted }),
                    headers: { 'token': sessionStorage.getItem('token') },
                    cache: false,
                    success: function (data) {
                        if (data.status == "success") {
                            this.setState({ usercodeDeleted: "" }, () => $("#btnSuccess").click())
                            this.getAllUsers();
                            console.log("success", data.data.rows_count_utente)
                        } else {
                            this.setState({ messageError: "Nessun utente rimosso" }, () => $('#btnError').get(0).click());
                        }
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else {
                                this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                            }
                        }
                        else {
                            this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                        }
                    }.bind(this),
                });
        } else {

        }
    }

    /**
     * 
     * @param {*} user 
     */
    isUserLogged(user){
        try {
            let userLogged = this.state.userLogged;
            return userLogged.CodiceFiscale === user.CodiceFiscale;  
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    render() {

        var rows = '';
        if (!this.state.currentPageListusers.length) {
            rows = <div className="row">
                {this.state.emptyTest}
            </div>
        } else {
            rows = this.state.currentPageListusers.map(function (user, i) {

                return (
                    <UserRow
                        key={i}
                        rowId={user.id}
                        user={user}
                        categoriesTostring={""}
                        typologiesTostring={""}
                        showModal={this.showModal}
                        riepilogo={this.props.riepilogo}
                        isUserLogged={this.isUserLogged(user)}
                    />
                );
            }.bind(this)
            );
        }

        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">


                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-12">
                        <nav className="" aria-label="sei qui:" role="navigation">

                        <ul className="Breadcrumb">
                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                            <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} href="#">Configurazione e inizializzazione</a></li>
                            <li className="Breadcrumb-item"><a className=" u-color-50" href="#">Elenco Utenti</a></li>

                        </ul>
                        </nav>
                            <span className="titoloPagina">Elenco Utenti</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 giustifica">
                            <p>Lista delle utenze censite nella piattaforma di Cyber Risk Management. Tramite questa pagina l'utente amministratore può gestire le utenze modificandone gli attributi di operatività. Per ogni utenza, selezionando l'opzione di modifica si accede alla relativa pagina di variazione dati utente. Selezionando l'opzione di cancellazione la stessa utenza verrà eliminata dalla piattaforma. E' possibile eseguire il censimento di una nuova utenza premendo il pulsante "Nuova Utenza", mentre è possibile ricercare un'utenza nella lista attraverso il campo "Cerca Utente". La ricerca avviene analizzando i campi relativi al codice fiscale, nome e cognome.</p>
                        </div>
                        <div className="col-lg-2 card-headerInfo">
                            <a onClick={() => history.push('/NuovoUtente')} href="javascript:void(0);">
                                <button type="button" className="btn btn-warning btn-lg btn-block">NUOVA UTENZA</button>
                            </a>                     </div>
                    </div>

                    <div hidden={this.props.riepilogo === "true"} className="linea0066cc"></div>
                    <div hidden={this.props.riepilogo === "true"} className="container cnsContainer">

                        <div className="row">
                            <div className="col-lg-10"></div>

                            <div className="col-lg-2">

                            </div>
                        </div>
                    </div>


                    <div className="container cnsContainer">
                        <div className="row">
                            {this.props.riepilogo === "true" && <div className="col-lg-10 spazio30"><div><strong>Elenco servizi caratterizzati:</strong>
                                <a data-bs-toggle="collapse" href="#collapse10"><span className=" testoBlu paRight"><img src={infoIcon} alt="iconInfo" /></span></a>
                                <div id="collapse10" data-parent="#accordion" className="InfoAgg giustifica collapse" >
                                    Riepilogo dei servizi Caratterizzati rispetto al
                            totale dei servizi censiti sul sistema.</div>
                            </div>	</div>}
                            <div className="col-lg-9 spazio30"></div>

                            <SearchInput value={this.state.search} updateSearch={this.updateSearch} placeholder={"CERCA UTENTE..."} />

                            <div className="col-lg-2 font14 nascondiCell">
                                <strong onClick={() => this.orderUsersList('codice')} style={{ cursor: 'pointer' }}>Codice fiscale
                            <i className={(this.state.selectedColumn.localeCompare("codice") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>

                            <div className="col-lg-2 font14  nascondiCell">
                                <strong onClick={() => this.orderUsersList('cognome')} style={{ cursor: 'pointer' }}>Cognome
                            <i className={(this.state.selectedColumn.localeCompare("cognome".toLowerCase()) === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>

                            <div className={"col-lg-2 font14 "}>
                                <strong onClick={() => this.orderUsersList('nome')} style={{ cursor: 'pointer' }}> Nome
                            <i className={(this.state.selectedColumn.localeCompare("nome") === 0 && this.state.isAscName) ?
                                        "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                    </i>
                                </strong></div>

                            <div className="col-lg-2 font14 nascondiCell" style={{ textAlign: 'center' }}>
                                <strong onClick={() => this.orderUsersList('createdate')} style={{ cursor: 'pointer' }}>Data creazione</strong>
                                <i className={(this.state.selectedColumn.localeCompare("createdate") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </div>

                            <div className="col-lg-2 font14 nascondiCell" style={{ textAlign: 'center' }}>
                                <strong onClick={() => this.orderUsersList('lastaccess')} style={{ cursor: 'pointer' }}>Data ultimo accesso</strong>
                                <i className={(this.state.selectedColumn.localeCompare("lastaccess") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </div>
                            <div className="col-lg-2 font14 nascondiCell" style={{ textAlign: 'center' }}><strong> Azione</strong></div>

                        </div>


                        <span className="lineaGrigiaT0"></span>
                        <div>
                            {rows}
                        </div>

                        <div className="row spazio10"> </div>
                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.rowsTable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                    <ModalDialog idTarget={'success'} idBtn={'btnSuccess'} textModal={"L'utente è stato rimosso con successo"} />
                    <ModalDialog idTarget={'warningAction'} idBtn={'btnDelete'} handle={this.deleteUser} textModal={this.state.messageDelete} />
                    <ModalDialog idTarget={'warningNoAction'} idBtn={'btnCalcolo'} textModal={'Prima di effettuare il Calcolo compilare tutte le risposte!'} />
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                    <br />
                    <br />
                    <br />
                </div>
            </div >

        )
    }
}