import * as React from 'react';
import history from '../history';
import $ from 'jquery';
import { API_CONFIG } from '../../public/js/apiServices';
import { ElencoGAPRow } from './ElencoGAPRow';
import { PaginationComponent } from '../Common/PaginationComponent';
import { ModalDialog } from '../Common/ModalDialog';
import LIVELLO from '../data/criticality';
import { SearchInput } from '../Common/SearchInput';
import { isNullOrUndefined } from 'util';
import { Bar } from 'react-chartjs-2';
import { Util } from '../data/Util';
/*
import { Breadcrumb } from '../Common/Breadcrumb';

const LINK = [
    { nome: "Trattamento del Rischio", url: "/TrattamentoRischio" },
    { nome: "Opzioni di trattamento", url: "#" }
];
*/

let ASSESSMENT_TYPE = [];
ASSESSMENT_TYPE[0] = 'PA';
ASSESSMENT_TYPE[1] = 'Servizio';


export class ElencoGAP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            listAssessmentTrattamento: [],
            listAssessmentFilterable: [],
            selectedTypeAssessment: 'Servizio',
            search: sessionStorage.getItem('searchTR') !== null ? sessionStorage.getItem('searchTR') : '',
            searchAll: sessionStorage.getItem('searchAllTR') !== null ? sessionStorage.getItem('searchAllTR') : '',
            selectedDateStart: '',
            selectedDateEnd: '',
            selectedColumn: "",
            //selectedDateStart: sessionStorage.getItem('dateStart') !== null ? sessionStorage.getItem('dateStart') : '',
            //selectedDateEnd: sessionStorage.getItem('dateEnd') !== null ? sessionStorage.getItem('dateEnd') : '',
            isAscName: false,
            activePage: 1,
            currentPageListAssessment: [],
            itemsCountPerPage: 5,
            records_per_page: 5,
            emptyTest: 'Loading...',
            messageError: "",
            serviceName: props.location.state.serviceName,
            serviceId: sessionStorage.getItem('serviceId'),
        };
        this.getlistAssessmentTrattamento = this.getlistAssessmentTrattamento.bind(this);
        this.updateListAssessmentTrattamento = this.updateListAssessmentTrattamento.bind(this);
        this.updateTypeAssessment = this.updateTypeAssessment.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.updateSearchAll = this.updateSearchAll.bind(this);
        this.updateDateStart = this.updateDateStart.bind(this);
        this.updateDateEnd = this.updateDateEnd.bind(this);
        this.orderServicesList = this.orderServicesList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.numPages = this.numPages.bind(this);
        this.toString = this.toString.bind(this);
    }

    componentDidMount() {
        try {
                            this.serverRequest =
                $.ajax({
                    url: API_CONFIG.assessmentcompositeListAssessment,
                    type: "POST",
                    contentType: 'application/json',
                    headers: { 'token': sessionStorage.getItem('token') },
                    data: "{ \"ServizioId\": \"" + this.state.serviceId + "\"}",
                    cache: false,
                    success: function (response) {
                        console.log('listAssessment:', response.data); 
                        var lista = this.getlistAssessmentTrattamento(response.data);
                        console.log('lista:', lista); 
                        this.setState({ listAssessmentTrattamento: lista });
                        this.setState({ listAssessmentFilterable: lista }, this.updateListAssessmentTrattamento);
                        this.setState({ emptyTest: "Nessuna analisi trovata" });
                        this.handlePageChange(1);                       
                    }.bind(this),

                    error: function (xhr, resp, text) {
                        let messageError = "Si è verificato un errore ";
                        console.error(xhr);
                        //Token JWT Invalid
                        if (xhr.responseJSON) {
                            if (xhr.responseJSON.data === -1) {
                                    sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("lastActivityDate");

                                location.replace(API_CONFIG.HomePath);
                            } else this.setState({ messageError: messageError + " codice: " + xhr.responseJSON.data }, () => $('#btnError').get(0).click());
                        }
                        else this.setState({ messageError: messageError + "." }, () => $('#btnError').get(0).click());
                    }.bind(this),
                });


            if (sessionStorage.getItem('typeAssessment') !== null ||
                sessionStorage.getItem('dateStart') !== null ||
                sessionStorage.getItem('dateEnd') !== null ||
                sessionStorage.getItem('searchTR') !== null ||
                sessionStorage.getItem('searchAllTR') !== null
            )
                $("#ricerca").get(0).click();
        } catch (error) {
            return console.error(error);
        }
    }

    /**
     * 
     */
    getlistAssessmentTrattamento(listAssessmentTrattamento) {
        
        let newListAssessmentTrattamento = []
        try {

            for (let index = 0; index < listAssessmentTrattamento.length; index++) {
                const element = listAssessmentTrattamento[index];
                if (element.StatoAssessmentId > 3 && element.DataPubblicazione != undefined) {
                    newListAssessmentTrattamento.push(element);
                    //console.log("AssessmentId",element.AssessmentId);
                }
            }
            
            return newListAssessmentTrattamento.reverse();
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    /**
     * 
     */
    updateListAssessmentTrattamento() {
        
        let listAssessment = this.state.listAssessmentTrattamento;
        let search = this.state.search;
        let searchAll = this.state.searchAll;
        let selectedTypeAssessment = this.state.selectedTypeAssessment;
        let selectedDateStart = this.state.selectedDateStart;
        let selectedDateEnd = this.state.selectedDateEnd;
        const dateStart = new Date(selectedDateStart).getTime();
        const dateEnd = new Date(selectedDateEnd).getTime();
        try {
            var list = [];
            if (search === '' && selectedTypeAssessment === '-1' && selectedDateStart === '' && selectedDateEnd === '') {
                list = listAssessment;
            } else {

                for (let index = 0; index < listAssessment.length; index++) {
                    const element = listAssessment[index];

                    element.Referente = element.Referente === null ? '' : element.Referente;
                    element.StrutturaReferente = element.StrutturaReferente === null ? '' : element.StrutturaReferente;
                    element.ServizioDescrizione = element.ServizioDescrizione === null ? '' : element.ServizioDescrizione;

                    let dataCreazione = new Date(element.DataCreazione.split('T')[0]);
                    //console.log('assessment', element);
                    if (searchAll === '' ||
                        element.Nome.toUpperCase().indexOf(searchAll.toUpperCase()) > -1 ||
                        (element.Referente !== null && element.Referente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.StrutturaReferente !== null && element.StrutturaReferente.toUpperCase().indexOf(searchAll.toUpperCase()) > -1) ||
                        (element.ServizioDescrizione !== null && element.ServizioDescrizione.toUpperCase().indexOf(searchAll.toUpperCase()) > -1)) {

                        if (search === '' || element.Nome.toUpperCase().indexOf(search.toUpperCase()) > -1) {

                            if (selectedDateStart === '' || selectedDateEnd === '' || dateStart <= dataCreazione.getTime() && dataCreazione.getTime() <= dateEnd) {
                                //if (selectedTypeAssessment === '-1' || ASSESSMENT_TYPE[element.TipoAssessment].toUpperCase().indexOf(selectedTypeAssessment.toUpperCase()) > -1) {
                                    list.push(element);
                                //}
                            }
                        }
                    }
                }
            }
            
            this.setState({ listAssessmentFilterable: list }, () => { this.handlePageChange(1) });

        } catch (error) {
            //alert(error)
            return console.error(error);
        }
    }

    /**
     * @param {*} event 
     */
    updateTypeAssessment(event) {
        let type = event.target.value;
        sessionStorage.setItem('typeAssessment', type);
        this.setState({ selectedTypeAssessment: type }, this.updateListAssessmentTrattamento);
    }

    /**
     * 
     * @param {*} e 
     */
    updateSearch(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchTR', val);
        this.setState({ search: val }, this.updateListAssessmentTrattamento);
    }

    /**
     * 
     */
    updateSearchAll(e) {
        //console.log(e.target.value);
        let val = e.target.value;
        sessionStorage.setItem('searchAllTR', val);
        this.setState({ searchAll: val }, this.updateListAssessmentTrattamento);
    }

    updateDateStart(e) {
        //console.log(e.target.value);//2018-08-01
        let date = e.target.value;
        var dateParse = new Date(date);
        sessionStorage.setItem('dateStart', date);
        this.setState({ selectedDateStart: date }, this.updateListAssessmentTrattamento);
    }

    updateDateEnd(e) {
        //console.log(e.target.value);//2018-08-31
        let date = e.target.value;
        var dateParse = new Date(date);
        sessionStorage.setItem('dateEnd', date);
        this.setState({ selectedDateEnd: date }, this.updateListAssessmentTrattamento);
    }

    /**
     * 
     * @param {*} list 
     */
    toString(list) {
        //console.log("toString", list);
        let toString = '';
        try {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                toString += element.Nome + ' | ';
            }
            return toString.substring(0, toString.length - 2)

        } catch (error) {
            console.error(error);
            return '';
        }
    }

    /**
    * 
    */
    orderServicesList(key) {
        let list = [];
        let asc = this.state.isAscName;
        let listAssessmentFilterable = this.state.listAssessmentFilterable;
        console.log("orderServicesList");
        this.setState({ selectedColumn: key });
        switch (key) {
            case "dataC":
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) {
                        return new Date(a.DataCreazione).getTime() - new Date(b.DataCreazione).getTime();
                    });

                } else
                    list = listAssessmentFilterable.sort(function (a, b) {
                        return new Date(b.DataCreazione).getTime() - new Date(a.DataCreazione).getTime();
                    });
                this.setState({ isAscName: !asc });
                break;
            case "dataP":
                    if (asc) {
                        list = listAssessmentFilterable.sort(function (a, b) {
                            return new Date(a.DataPubblicazione).getTime() - new Date(b.DataPubblicazione).getTime();
                        });
    
                    } else
                        list = listAssessmentFilterable.sort(function (a, b) {
                            return new Date(b.DataPubblicazione ).getTime() - new Date(a.DataPubblicazione).getTime();
                        });
                    this.setState({ isAscName: !asc });
                    break;
            case 'iuc':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) { return a.RischioAggregato - b.RischioAggregato; });
                }
                else list = listAssessmentFilterable.sort(function (a, b) { return b.RischioAggregato - a.RischioAggregato; });
                this.setState({ isAscName: !asc });
                break;
            case 'statoa':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) {
                        let aStatoAssessmentId = (a.StatoAssessmentId < 6) ? "incompleto" : "pubblicato";
                        let bStatoAssessmentId = (b.StatoAssessmentId < 6) ? "incompleto" : "pubblicato";
                        return aStatoAssessmentId.localeCompare(bStatoAssessmentId);
                    });
                }
                else list = listAssessmentFilterable.sort(function (a, b) {
                    let aStatoAssessmentId = (a.StatoAssessmentId < 6) ? "incompleto" : "pubblicato";
                    let bStatoAssessmentId = (b.StatoAssessmentId < 6) ? "incompleto" : "pubblicato";
                    return bStatoAssessmentId.localeCompare(aStatoAssessmentId);
                });
                this.setState({ isAscName: !asc });
                break;
            case 'rischio':
                if (asc) {
                    list = listAssessmentFilterable.sort(function (a, b) {
                        let levelA = (!isNullOrUndefined(a.RischioAttuale) && (a.RischioAttuale >= 0) && (a.RischioAttuale < LIVELLO.length)) ? LIVELLO[a.RischioAttuale].name : "";
                        let levelB = (!isNullOrUndefined(b.RischioAttuale) && (b.RischioAttuale >= 0) && (b.RischioAttuale < LIVELLO.length)) ? LIVELLO[b.RischioAttuale].name : "";
                        return levelA.localeCompare(levelB);
                    });
                }
                else list = listAssessmentFilterable.sort(function (a, b) {
                    let levelA = (!isNullOrUndefined(a.RischioAttuale) && (a.RischioAttuale >= 0) && (a.RischioAttuale < LIVELLO.length)) ? LIVELLO[a.RischioAttuale].name : "";
                    let levelB = (!isNullOrUndefined(b.RischioAttuale) && (b.RischioAttuale >= 0) && (b.RischioAttuale < LIVELLO.length)) ? LIVELLO[b.RischioAttuale].name : "";
                    return levelB.localeCompare(levelA);
                });
                this.setState({ isAscName: !asc });
                break;
            default:
                list = listAssessmentFilterable;
                break;
        }
        this.setState({ listAssessmentFilterable: list }, () => { this.handlePageChange(this.state.activePage) });
    }

    /**
       * 
       */
    handlePageChange(page) {
        //debugger
        var listing_table = [];
        let records_per_page = this.state.records_per_page;
        let listAssessment = this.state.listAssessmentFilterable;
       
        // Validate page
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();

        for (var i = (page - 1) * records_per_page; i < (page * records_per_page); i++) {
            if (listAssessment[i])
                listing_table.push(listAssessment[i]);
        }
        //console.log(`active page is ${page}`);
       
        this.setState({ activePage: page });
        this.setState({ currentPageListAssessment: listing_table });
    }

    /**
    * 
    */
    numPages() {
        return Math.ceil(this.state.listAssessmentTrattamento.length / this.state.itemsCountPerPage);
    }

    toStringDate(date) {
        if (date) {
            try {
                let toStringDateCreazione = Util.toStringDate(date);
                return toStringDateCreazione.day +
                    "/" + toStringDateCreazione.month +
                    "/" + toStringDateCreazione.year +
                    " " + toStringDateCreazione.hours +
                    ":" + toStringDateCreazione.minutes +
                    ":" + toStringDateCreazione.seconds;

            } catch (error) {
                console.error(error);
                return "--/--/----";
            }
        } else return "--/--/----";
    }

    render() {

        let rows = '';
        if (!this.state.currentPageListAssessment.length) {
            rows = <div className="row"> {this.state.emptyTest}</div>
        } else {
            rows = this.state.currentPageListAssessment.map(function (remedy, i) {
                return (
                    <ElencoGAPRow
                        key={i}
                        rowId={i}
                        readOnly={true}
                        remedy={remedy}
                        stateAssessment={(remedy.StatoAssessmentId < 6) ? "incompleto" : "pubblicato"}
                        
                        Azioni={this.props.Azioni}                       
                    />
                );
            }.bind(this)
            );
        }

        var dateAnalisi = [];
        var iucAnalisi = [];
        var listaAnalisi = this.state.listAssessmentTrattamento.sort(function (a, b) {
            return new Date(a.DataPubblicazione).getTime() - new Date(b.DataPubblicazione).getTime();
        });
        //console.log("listaXgrafico", listaAnalisi);
        for (let index = 0; index < listaAnalisi.length; index++) {
            const element = listaAnalisi[index];
            if (element.StatoAssessmentId > 3) {
                
                iucAnalisi.push(element.RischioAggregato);
                dateAnalisi.push(this.toStringDate(element.DataPubblicazione));
            }
        }


        var dataLine = {            
            datasets: [{
                backgroundColor: '#0066cc',
                borderColor: '#0066cc',
                data: iucAnalisi
            }],            
            labels: dateAnalisi
        }

        var optLine ={
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 100
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'IUC',
                        fontStyle: "bold"
                      }
                }],
                xAxes: [{
                    type: 'category'
                }]
            }
        }

        return (

            <div className="u-layout-wide u-layoutCenter u-text-r-xl u-layout-r-withGutter u-padding-r-top">

                <div className="container cnsContainer">

                    <div className="row">

                        <div className="col-lg-12">
                            {/* <Breadcrumb links={LINK} /> */}
                            <nav className="" aria-label="sei qui:" role="navigation">

                                <ul className="Breadcrumb">
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push(API_CONFIG.HomePath)}>Home</a></li>
                                    <li className="Breadcrumb-item"><a className="u-color-50" style={{ cursor: 'pointer' }} onClick={() => history.push('/MonitoraggioGAPAnalysis')}>Monitoraggio GAP Analysis</a></li>
                                    <li className="Breadcrumb-item"><a className=" u-color-50" href="javascript:void(0)">Elenco GAP Analysis</a></li>

                                </ul>
                            </nav>
                            {/* <Breadcrumb links={LINK} /> */}
                            <span className="titoloPagina">{this.props.title +": " + this.state.serviceName}</span>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12 giustifica">	                  
                        <p>La pagina espone lo storico delle Gap Analysis “pubblicate” per l’applicazione</p>
                    </div>
                    </div>

                    <div className="linea0066cc"></div>


                    <div className="container cnsContainer">

                        <div className="row">
                        <div className="col-lg-12"><h1 className="testoBlu">Storico analisi</h1><hr />
                            </div>
                            <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                        <Bar id="line1" data={dataLine} height={90} options={optLine} />
                                </div>
                            <div className="col-lg-2"></div>
                        </div>
                        <div className="row spazio20"> </div>

                        <div className="row">

                            <div className="col-lg-3 card-header nascondiCell"><strong onClick={() => this.orderServicesList("dataC")} style={{ cursor: 'pointer' }}> Creata
                            <i className={(this.state.selectedColumn.localeCompare("dataC") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </strong></div>
                            <div className="col-lg-3 card-header nascondiCell"><strong onClick={() => this.orderServicesList("dataP")} style={{ cursor: 'pointer' }}> Pubblicata
                            <i className={(this.state.selectedColumn.localeCompare("dataP") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </strong></div>

                            <div className="col-lg-3 card-header nascondiCell"><strong onClick={() => this.orderServicesList("iuc")} style={{ cursor: 'pointer' }}> IUC
                            <i className={(this.state.selectedColumn.localeCompare("iuc") === 0 && this.state.isAscName) ?
                                    "fa fa-angle-up align-baseline" : "fa fa-angle-down align-baseline"}>
                                </i>
                            </strong></div>

                            <div className="col-lg-3 card-header nascondiCell"><strong> Azioni</strong></div>

                        </div>

                        {rows}

                        <div className="row spazio10"> </div>

                        <PaginationComponent
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.listAssessmentFilterable.length}
                            pageRangeDisplayed={10}
                            handlePageChange={this.handlePageChange}
                        />
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <ModalDialog idTarget={'danger'} idBtn={'btnError'} textModal={this.state.messageError} />
                </div>
            </div>
        )
    }
}
