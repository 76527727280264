import React from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import Home from './Home/Home';
import HomeLogged from './Home/HomeLogged';
import Auth from './AuthSpid/Auth';
import history from './history';
import Errore from '../src/Errori/Errore';
import ErroreLogin from '../src/Errori/ErroreLogin';
import { RiepilogoDati } from './AnalisiContesto/RiepilogoDati';
import { RiepilogoDatiImpatti } from './ValutazioneImpatti/RiepilogoDatiImpatti';
import { ValutazioneImpatti } from './ValutazioneImpatti/ValutazioneImpatti';
import Header from '../src/Common/Header';
import Footer from '../src/Common/Footer';
import PrivacyPolicy from './Info/PrivacyPolicy';
import { ListaServizi } from './AnalisiContesto/ListaServizi';
import { ListaApplicazioni } from './RegistroApplicazioni/ListaApplicazioni';
import { RegistroApplicazioniModifica } from './RegistroApplicazioni/RegistroApplicazioniModifica';
import { ListaServiziCensimento } from './CensimentoServizi/ListaServiziCensimento';
import Callback from './Callback/Callback';
import { AnalisiContestoModifica } from './AnalisiContesto/AnalisiContestoModifica';
import { AnalisiContestoVisualizza } from './AnalisiContesto/AnalisiContestoVisualizza';
import { AnalisiContesto } from './AnalisiContesto/AnalisiContesto';
import { CensimentoServizi } from './CensimentoServizi/CensimentoServizi';
import { ListaServiziImpatti } from './ValutazioneImpatti/ListaServiziImpatti';
import { ValutazioneImpattiModifica } from './ValutazioneImpatti/ValutazioneImpattiModifica';
import { ValutazioneImpattiVisualizza } from './ValutazioneImpatti/ValutazioneImpattiVisualizza';
import { NuovoServizio } from './CensimentoServizi/NuovoServizio';
import { CensimentoServizioVisualizza } from './CensimentoServizi/CensimentoServizioVisualizza';
import { CensimentoServizioModifica } from './CensimentoServizi/CensimentoServizioModifica';
import { ListaServiziAnalisiRischio } from './AnalisiRischio/ListaServiziAnalisiRischio';
import { AnalisiRischio } from './AnalisiRischio/AnalisiRischio';
import { QuestionarioPA } from './AnalisiRischio/QuestionarioPA';
import { AnalisiRischioModifica } from './AnalisiRischio/AnalisiRischioModifica';
import { AnalisiRischioVisualizza } from './AnalisiRischio/AnalisiRischioVisualizza';
import { RiepilogoDatiAnalisiRischio } from './Report/RiepilogoDatiAnalisiRischio';
import { ElencoServiziTrattamento } from './TrattamentoRischio/ElencoServiziTrattamento';
import { ElencoApplicazioniPlanning} from './TrattamentoRischio/ElencoApplicazioniPlanning';
import { ElencoApplicazioniExecute} from './TrattamentoRischio/ElencoApplicazioniExecute';
import { ElencoApplicazioniMonitoring} from './Monitoraggio/ElencoApplicazioniMonitoring';
import { ElencoGAP } from './TrattamentoRischio/ElencoGAP';
import { ServizioModificaTrattamento } from './TrattamentoRischio/ServizioModificaTrattamento';
import { RiskAppetite } from './TrattamentoRischio/RiskAppetite';
import { TrattamentoRischio } from './TrattamentoRischio/TrattamentoRischio';
import { AzioniTrattamentoServizioModifica } from './TrattamentoRischio/AzioniTrattamentoServizioModifica';
import { AzioniTrattamentoRischioResiduo } from './TrattamentoRischio/AzioniTrattamentoRischioResiduo';
import { Planning } from './TrattamentoRischio/Planning';
import { ExecutePlan } from './TrattamentoRischio/ExecutePlan';
import { ElencoServiziReport } from './Report/ElencoServiziReport';
import { MonitoraggioServizio } from './Monitoraggio/MonitoraggioServizio';
import { ElencoServiziMonitoraggio } from './Monitoraggio/ElencoServiziMonitoraggio';
import { Monitoraggio } from './Monitoraggio/Monitoraggio';
import { MonitoraggioPlan } from './Monitoraggio/MonitoraggioPlan';
import { ExecutiveSummaryRiepilogo } from "./Report/ExecutiveSummaryRiepilogo";
import { ElencoExecutiveSummary } from "./Report/ElencoExecutiveSummary";
import { UtentiElenco } from "./Utenti/UtentiElenco";
import { GestioneUtente } from "./Utenti/GestioneUtente";
import { GestioneUtenti } from "./Utenti/GestioneUtenti";
import { ContestoNormativo } from "./Library/ContestoNormativo";
import { AggiornaLibrary } from "./Library/AggiornaLibrary";
import { API_CONFIG } from '../public/js/apiServices';
import { ImportaDati } from './Library/ImportaDati';

const auth = new Auth();

const handleAuthentication = () => {
  auth.setAccessToken();
}

export function AddLibrary(urlOfTheLibrary, defer, integrity, crossorigin) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.defer = defer;
  script.integrity= integrity;
  script.crossOrigin = crossorigin;
  script.async = true;
  document.body.appendChild(script);  
}

export const makeMainRoutes = () => {
  return (
    <Router history={history}>
      <div>
        {AddLibrary(
        'https://code.jquery.com/jquery-3.6.0.min.js', false, '', '')}

        <Route path="/" render={(props) => <Header auth={auth} {...props} />} />
        <Route path="/callback" render={(props) => {
          handleAuthentication();
          return <Callback {...props} />
        }} />
        <Route path={API_CONFIG.HomePath} render={(props) => (
          !auth.isAuthenticated() ? (
            <Home auth={auth} {...props} />
          ) : (
              <HomeLogged auth={auth} {...props} />
            )
        )} />
        {/* CENSIMENTO SERVIZI */}
        <Route path='/CensimentoApplicazioni' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <CensimentoServizi auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ListaApplicazioniCensimento' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <ListaServiziCensimento auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/NuovaApplicazione' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_NuovoServizio") ? (
            <NuovoServizio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/CensimentoApplicazioneVisualizza' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <CensimentoServizioVisualizza auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/CensimentoApplicazioneModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <CensimentoServizioModifica auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* ANALISI CONTESTO */}
        <Route path='/AnalisiContesto' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <AnalisiContesto auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ListaServizi' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <ListaServizi auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/AnalisiContestoModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Modifica") ? (
            <AnalisiContestoModifica auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/AnalisiContestoVisualizza' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <AnalisiContestoVisualizza auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/RiepilogoAnalisiContesto' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Riepilogo") ? (
            <RiepilogoDati auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* REGISTRO APPICAZIONI */}
        <Route path='/RegistroApplicazioniElenco' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Visualizza") ? (
            <ListaApplicazioni auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
         <Route path='/RegistroApplicazioniModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AC_Modifica") ? (
            <RegistroApplicazioniModifica auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* VALUTAZIONE IMPATTI */}
        <Route path='/ValutazioneImpatti' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("VI_Visualizza") ? (
            <ValutazioneImpatti auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ListaServiziImpatti' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("VI_Visualizza") ? (
            <ListaServiziImpatti auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ValutazioneImpattiModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("VI_Modifica") ? (
            <ValutazioneImpattiModifica auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ValutazioneImpattiVisualizza' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("VI_Visualizza") ? (
            <ValutazioneImpattiVisualizza auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/RiepilogoImpatti' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("VI_Riepilogo") ? (
            <RiepilogoDatiImpatti auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* ANALISI RISCHIO */}
        <Route path='/AnalisiRischio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <AnalisiRischio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ListaApplicazioniGapAnalysis' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <ListaServiziAnalisiRischio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/QuestionarioPA' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <QuestionarioPA auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/GapAnalysisModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <AnalisiRischioModifica auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/GapAnalysisVisualizza' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <AnalisiRischioVisualizza auth={auth} {...props} dettaglio = {false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/GapAnalysisVisualizzaDettaglio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Visualizza") ? (
            <AnalisiRischioVisualizza auth={auth} {...props} dettaglio={true} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* TRATTAMENTO RISCHIO */}
        <Route path='/TrattamentoRischio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <TrattamentoRischio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/MonitoraggioGAPAnalysis' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoServiziTrattamento auth={auth} {...props} title={"Opzioni di trattamento del rischio per servizio"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ElencoGAP' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoGAP auth={auth} {...props} title={"Elenco GAP Analysis Applicazione"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ElencoServiziAzioni' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoServiziTrattamento auth={auth} {...props} Azioni="true" title={"Azioni di trattamento del rischio per servizio"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ServizioModificaTrattamento' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <ServizioModificaTrattamento auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/PAModificaTrattamento' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <ServizioModificaTrattamento auth={auth} {...props} PA="true" />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ServizioModificaImpostazioni' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <RiskAppetite auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/PAModificaImpostazioni' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <RiskAppetite auth={auth} {...props} PA="true" />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/AzioniTrattamentoServizioModifica' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <AzioniTrattamentoServizioModifica auth={auth} {...props} PA={false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/AzioniTrattamentoPA' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <AzioniTrattamentoServizioModifica auth={auth} {...props} PA={true} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/AzioniTrattamentoRischioResiduo' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <AzioniTrattamentoRischioResiduo auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/Planning' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <Planning auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
         <Route path='/ElencoApplicazioniPlanning' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoApplicazioniPlanning auth={auth} {...props} Planning={true} title={"Elenco Applicazioni Planning"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ElencoApplicazioniMonitoring' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoApplicazioniMonitoring auth={auth} {...props} title={"Elenco Applicazioni Monitoring"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/ElencoApplicazioniExecute' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ElencoApplicazioniExecute auth={auth} {...props} title={"Elenco Applicazioni Monitoring"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/MonitoraggioPlan' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <MonitoraggioPlan auth={auth} {...props} Planning={true} title={"Elenco Applicazioni Planning"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ExecutePlan' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Visualizza") ? (
            <ExecutePlan auth={auth} {...props} title={"Execute"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* REPORT */}
        <Route path='/ElencoApplicazioniReport' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Riepilogo") ? (
            <ElencoServiziReport auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/RiepilogoDatiAnalisiRischio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Riepilogo") ? (
            <RiepilogoDatiAnalisiRischio auth={auth} {...props} PA="true" />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
        <Route path='/RiepilogoDatiApplicazione' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("AR_Riepilogo") ? (
            <RiepilogoDatiAnalisiRischio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ESriepilogoPA' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("ES_Visualizza") ? (
            <ExecutiveSummaryRiepilogo auth={auth} {...props} PA={true} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ESriepilogoServizio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("ES_Visualizza") ? (
            <ExecutiveSummaryRiepilogo auth={auth} {...props} PA={false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ElencoExecutiveSummary' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("ES_Visualizza") ? (
            <ElencoExecutiveSummary auth={auth} {...props} PA={false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* MONITORAGGIO */}
        <Route path='/Monitoraggio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <Monitoraggio auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/MonitoraggioServizio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <MonitoraggioServizio auth={auth} {...props} PA={false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/MonitoraggioPA' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <MonitoraggioServizio auth={auth} {...props} PA={true} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ElencoServiziMonitoraggio' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("TR_Modifica") ? (
            <ElencoServiziMonitoraggio auth={auth} {...props} PA={false} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        {/* GESTIONE UTENTI */}
        <Route path='/GestioneUtenti' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GU_Modifica") ? (
            <GestioneUtenti auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

         <Route path='/UtentiElenco' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GU_Modifica") ? (
            <UtentiElenco auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} /> 

        <Route path='/NuovoUtente' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GU_Modifica") ? (
            <GestioneUtente auth={auth} {...props} mode={"insert"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ModificaUtente' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GU_Modifica") ? (
            <GestioneUtente auth={auth} {...props} mode={"update"} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />
		
		    <Route path='/AggiornaLibrary' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GL_Modifica") ? (
            <AggiornaLibrary auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ImportaDati' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("GL_Modifica") ? (
            <ImportaDati auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />

        <Route path='/ContestoNormativo' render={(props) => (
          auth.isAuthenticated() && auth.hasFunction("CN_Modifica") ? (
            <ContestoNormativo auth={auth} {...props} />
          ) : (<Redirect to={API_CONFIG.HomePath} />)
        )} />


        <Route path='/ErroreLogin' render={(props) => <ErroreLogin auth={auth} {...props} />} />
        <Route path='/Errore' render={(props) => <Errore auth={auth} {...props} />} />
        <Route path='/PrivacyPolicy' render={(props) => <PrivacyPolicy auth={auth} {...props} />} />
        {/* <Route path='/IlProcesso' render={(props) => <IlProcesso auth={auth} {...props} />} />
        <Route path='/RischiPA' render={(props) => <RischiPA auth={auth} {...props} />} />
        <Route path='/AgidPA' render={(props) => <AgidPA auth={auth} {...props} />} /> */}

        <Route path='/' render={(props) => <Footer auth={auth} {...props} />} />
      </div>
    </Router>
  );
}
