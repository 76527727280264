import React from 'react';

//
export class AssessmentMonitoraggioSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        };
        this.updateOption = this.updateOption.bind(this);
        this.getOption = this.getOption.bind(this);
    }

    /**
     * 
     * @param {*} event 
     */
    updateOption(event) {
        //let val = parseInt(event.target.value, 10);
        //console.log('updateRiskAppetite : ', parseInt(event.target.value, 10));
        this.props.updateOption(this.props.item.MinacciaId, parseInt(event.target.value, 10));
    }

    /**
     * 
     */
    getOption() {
        let optionList = this.props.optionList.map(function (item, i) {
            return (
                <option key={i} value={i}>{item}</option>
            )
        })
        return optionList;
    }

    render() {
        return (
            <select
                defaultValue={this.props.selected}
                onChange={this.props.updateAssessmentMonitoraggioState}
                disabled={this.props.readOnly}
                className={this.props.className}>
                {this.getOption()}
            </select>
        );
    }
}